import { faBook, faCommenting, faHome, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { WithTranslation, withTranslation } from 'react-i18next';
import Typist from 'react-typist';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectUserName } from "../../../redux/Reducer/authSlice";
interface HomeProps extends WithTranslation {
  username: string;
}
class Home extends React.Component<HomeProps> {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    const username = useSelector(selectUserName);
  };
  public render(): React.ReactNode {
    const { t } = this.props;
    return (
      <div className="">
        <div className="bg-gray-100">
          <div className="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto justify-items-center text-center lg:max-w-2xl md:mb-12">
              <div>
                <div className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-black uppercase rounded-full bg-teal-accent-400">
                  <Typist cursor={{ show: false }}>
                    <div>
                      <h1 className="font-bold flex lg:flex-row flex-col gap-2">
                        <div className="relative overflow-hidden">
                          <span className="relative z-10 border-0 text-black rounded-0  font-normal">
                            {t("Welcome back")}{" "}
                            <span className="bg-blue-300 font-bold text-greslernorange text-lg px-2 rounded-sm">
                              {this.props.username}
                            </span>
                          </span>
                        </div>
                      </h1>
                    </div>
                  </Typist>
                </div>
              </div>
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                <span className="relative inline-block rounded-lg border border-solid border-greslernorange p-2">
                  <svg viewBox="0 0 52 24" fill="currentColor"
                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-greslernorange lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                    <defs>
                      <pattern id="dc223fcc-6d72-4ebc-b4ef-abe121034d6e"
                        x="0" y="0" width=".135" height=".30">
                        <circle cx="1" cy="1" r=".7"
                          className="motion-safe:animate-pulse"
                        />
                      </pattern>
                    </defs>
                    <rect
                      fill="url(#dc223fcc-6d72-4ebc-b4ef-abe121034d6e)"
                      width="52" height="24"
                    />
                  </svg>
                  <span className="animate-pulse relative rounded-lg border border-solid border-greslernorange p-2 text-greslernorange">
                    {t("GreSLERN")}
                  </span>
                </span>{" "}
                {t("HomeMotoHead")}
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                {t("HomeHeroMoto")}
              </p>
            </div>
          </div>
        </div>
        <div className="relative px-4 sm:px-0">
          <div className="absolute inset-0 bg-gray-100 h-1/2" />
          <div className="relative grid justify-items-center  mx-auto divide-y gap-3 rounded sm:divide-y-0 sm:divide-x sm:max-w-screen-sm sm:grid-cols-4 lg:max-w-screen-lg  2xl:max-w-screen-lg">
            {/* shortcut */}
            {[
              ["animate-slideIn1s", "/home/dashboard", "text-green-600", "text-green-900", "border-t-green-500", "border-b-green-500", faHome, "Dashboard", "Your working area to create and manage resources."],
              ["animate-slideIn2s", "/home/dashboard/bieresources", "text-yellow", "text-yellow", "border-t-yellow", "border-b-yellow", faInfo, "HomeShortcutInfo", "HomeShortcutInfoDec"],
              ["animate-slideIn3s", "/home/dashboard/myresources", "text-amber-600", "text-amber-900", "border-t-amber-500", "border-b-amber-500", faBook, "HomeShortcutLearningResources", "HomeShortcutLearningResourcesDec"],
              ["animate-slideIn3s", "/home/dashboard/mycomments", "text-rose-600", "text-rose-900", "border-t-rose-500", "border-b-rose-500", faCommenting, "HomeShortcutComments", "HomeShortcutCommentsDec"]
            ].map(([anim, url, tc, tc2, btc, bbc, icon, title, description], index) => (
              <a className="h-full border-none shadow-card" href={url.toString()} key={index}>
                <div
                  className={`h-full ${anim} hover:scale-110 hover:z-10 transition duration-500  
                  fade-bottom border-4 ${btc} ${bbc} block max-w-[18rem] rounded-lg bg-white ${tc} text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 `}>
                  <div className="p-6">
                    <FontAwesomeIcon icon={icon} className=" h-20" />
                    <h5 className="pt-2 mb-2 text-xl font-medium leading-tight ">
                      {t(title.toString())}
                    </h5>
                    <p className={`mb-4 text-base text-${tc2}-900 `}>
                      {t(description.toString())}
                    </p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    );


  }
}

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
  };
};

export default connect(mapStateToProps)(withTranslation()(Home));