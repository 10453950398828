import React, { FC, ReactNode, useEffect, useState } from "react";

interface Props {
    children: ReactNode;
    tooltip?: string;
    bgColor?: string;
    textColor?: string;
}

const ToolTip: FC<Props> = ({
  tooltip,
  children,
  bgColor = "bg-gray-800",
  textColor = "text-white",
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {}, []);

  return (
    <>
      <div
        className="w-full flex cursor-pointer items-center"
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}>
        {children}
      </div>
      <div className="relative">
        {showTooltip && (
          <div
            className={`absolute bottom-full left-1/2 transform -translate-x-1/2 ${bgColor} ${textColor} rounded-md py-1 px-2 mb-1 pointer-events-none transition-all duration-300 z-50`}>
            {tooltip}
          </div>
        )}
      </div>
    </>
  );
};

export default ToolTip;