import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useEffect, useState } from "react";
import { CountAllComment, GetCommentReport, GetDataReport, GetMetaResource } from "../../services";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
const ContentAnalysys = (): JSX.Element => {

  const [allresource, setAllresource] = useState();
  const [xValues] = useState([1]);
  const [numberOfresource] = useState([1]);
  const numberOfcomment: number[] = [];
  const [commentcounted, setCommentcounted] = useState(0);
  const [duration] = useState("monthly");
  const [totalPublic, setTotalPublic] = useState(0);
  const [totalPrivate, setTotalPrivate] = useState(0);
  const [totalShared, setTotalShared] = useState(0);

  const fetchByResource = async (duration) => {
    let data = await GetDataReport.getresourceReport(duration)
    if (data) {
      data.map((item, i) =>
      (
        xValues.push(item._id)
      ));
      data.map((item, i) =>
      (
        numberOfresource.push(item.count)
      ));
    }

  };
  const fetchByComment = async (duration) => {
    let data = await GetCommentReport.getcommentReport(duration);
    if (data) {
      data.map((item: any, i: number) =>
      (
        numberOfcomment.push(item.count)
      ));
    }
  };
  const fetchallResource = async () => {
    try {
      const data = await GetMetaResource.getmetaresource();
      if (data) {
              setAllresource(data.length);
        const filteredPublic = data.filter(item => {
          return item.status.toLowerCase().includes("public".toLowerCase());
        });
        const filteredPrivate = data.filter(item => {
          return item.status.toLowerCase().includes("private".toLowerCase());
        });
        const filteredShared = data.filter(item => {
          return item.status.toLowerCase().includes("shared".toLowerCase());
        });
        setTotalPublic(filteredPublic.length);
        setTotalPrivate(filteredPrivate.length);
        setTotalShared(filteredShared.length);
      }
      else {
        console.log('error when fetch');
      }
    } catch (err) {
      console.log(err);
    }
  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Track your progress',
      },
    },
    scales: {
      y: {
        min: 0,
        max: 15,
        ticks: {
          stepSize: 1
        }
      }
    },
  };
  const data1 = {
    labels: ['Feedbacks', 'Resource'],
    datasets: [
      {
        label: '# of data',
        data: [commentcounted, allresource],
        backgroundColor: [
          'rgba(255, 99, 71, 0.5)',
          'rgba(106, 90, 205, 0.5)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const reportStatusRes = {
    labels: ['Shared', 'Private', 'Public'],
    datasets: [
      {
        label: '# of data',
        data: [totalShared, totalPrivate, totalPublic],
        backgroundColor: [
          'rgba(255, 99, 71, 0.5)',
          'rgba(3, 252, 115, 0.5)',
          'rgba(106, 50, 105, 0.5)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 62, 135, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const data = {
    barPercentage: 1,
    barThickness: 6,
    maxBarThickness: 8,
    minBarLength: 2,
    labels: xValues,
    datasets: [
      {
        label: 'Resources',
        data: numberOfresource,
        backgroundColor: 'rgba(106, 90, 205, 0.5)',
      },
    ],
  };
  const fetchCommentCounter = async () => {
    let resource = await CountAllComment.getallcommentcount();
    let sumComment = 0;
    if (resource) {
      resource?.map((items, i) => {
        setCommentcounted(resource[i].countComment);
        sumComment = sumComment + resource[i].countComment;
        setCommentcounted(sumComment);
      });
    }
  }
  useEffect(() => {
    fetchByResource(duration);
    fetchByComment(duration);
    fetchCommentCounter();
    fetchallResource();
  }, []);
  const { t } = useTranslation();
  return (
    <div className="container shadow p-3 mb-5 bg-blue-900 rounded">
      <h3 className="text-3xl  text-white py-2">
        {t("Monthly")}  {t("analysis")}
      </h3>
      <div className="grid grid-cols-2 gap-2">
        <div className="container shadow p-1 mb-1 bg-white rounded w-full row-span-2">
          <Pie style={{ width: "100%", height: "100%" }}
            options={{
              maintainAspectRatio: false
            }}
            data={reportStatusRes} />
        </div>
        <div className="container shadow p-1 mb-1 bg-white rounded w-full">
          <Bar width={100} height={50}
            options={options} data={data} />
        </div>
        <div className="container shadow p-1 mb-1 bg-gray-50 rounded w-full">
          <Pie style={{ width: "100%", height: "100%" }}
            options={{
              maintainAspectRatio: false
            }}
            data={data1} />
        </div>
      </div>
    </div>
  );
};
export default ContentAnalysys;