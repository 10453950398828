import React, { useState, Fragment } from "react";
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next'
import { faChevronDown, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";

// import NewOffcanvas from '../../../utils/NewOffcanvas';
import CircleNav from "../../circleNav";
import Languages from '../../../constances/languages.json'
import "./style.css";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const NavbarAnimation = ({ children }) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [isActive, setIsActive] = useState(false);
    const [isCircleActive, setIsCircleActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    const handleToggleNav = () => {
        setIsCircleActive(prevState => !prevState);
    };

    const handleChangeLanguage = (lng: string, name: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("language", lng)
        localStorage.setItem("languageName", name)
    };

    return (
        <div className={`nav-container ${isActive ? "active" : ""} mt-0`}>
            <div className="navbar  ">
                <div className="grid grid-cols-12 w-full">
                    <div className=" col-span-4 flex justify-start items-center ">
                        <div className="hidden md:block">
                            <div className="  hamburger-menu" onClick={toggleMenu}>
                                <div className="bar"></div>
                            </div>
                        </div>
                        <div className=" block md:hidden">
                            <Menu as="div" className=" h-full w-auto">
                                <Menu.Button className="w-full h-full  flex justify-start  items-center">
                                    <div className="  hamburger-menu" >
                                        <div className="w-[1.9rem] h-[1.5px] rounded bg-greslernorange transform duration-500 relative before:content before:absolute before:w-[1.9rem] before:h-[1.5px] before:bg-greslernorange before:transform before:duration-500 before:translate-y-[-9px] before:translate-x-[-15px] after:content after:absolute after:w-[1.9rem] after:h-[1.5px] after:bg-greslernorange after:transform after:duration-500 after:translate-y-[9px] after:translate-x-[-15px]"></div>
                                    </div>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (<>
                                                    <a
                                                        href="/home"
                                                        style={{ "--i": "0.1s" } as any}
                                                        className={classNames(
                                                            active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-left text-sm'
                                                        )}
                                                    >
                                                        {t('home')}
                                                    </a>
                                                    <a
                                                        href="/home/dashboard"
                                                        style={{ "--i": "0.15s" } as any}
                                                        className={classNames(
                                                            active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-left text-sm'
                                                        )}
                                                    >
                                                        {t('Workspace')}
                                                    </a>
                                                    <a
                                                        href="/resources"
                                                        style={{ "--i": "0.1s" } as any}
                                                        className={classNames(
                                                            active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-left text-sm'
                                                        )}
                                                    >
                                                        {t('Resources')}
                                                    </a>
                                                    <a
                                                        style={{ "--i": "0.2s" } as any}
                                                        className={classNames(
                                                            active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-left text-sm'
                                                        )}
                                                    >
                                                        {/* <NewOffcanvas /> */}
                                                    </a>
                                                </>

                                                )}
                                            </Menu.Item>

                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                    <div className="flex justify-center items-center col-span-4" onClick={() => navigate('/')}>
                        <img
                            alt="GreSELERN Logo"
                            src="/GreSLERN-logo.svg"

                            className="max-w-xs w-[150px] md:w-[200px]"
                        />{' '}
                    </div>
                    <div className="flex justify-end items-center col-span-4 space-x-2 mr-2 md:mr-0">
                        <Menu as="div" className=" h-full w-auto">
                            <Menu.Button className="w-full h-full text-white flex  items-center hidden md:block">
                                {localStorage.getItem('languageName') ? localStorage.getItem('languageName') : 'English'}
                                <FontAwesomeIcon icon={faChevronDown} className="-mr-1 h-5 w-5 text-white pl-1" aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        {Languages?.map((item) => (
                                            <Menu.Item key={item.id}>
                                                {({ active }) => (
                                                    <button

                                                        type="button"
                                                        className={classNames(
                                                            active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-left text-sm'
                                                        )}
                                                        onClick={() => handleChangeLanguage(item.value, item.name)}
                                                    >
                                                        {item.name}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        <CircleNav
                            isActive={isCircleActive}
                            toggleNav={handleToggleNav}
                        />
                    </div>
                </div>
            </div>
            <div>
            </div>
            <div className="" >
                <div className="main">
                    {children}
                </div>
                <div className="nav-shadow one bg-[#8f9dc4]"></div>
                <div className="nav-shadow two bg-[#8f9dc4]"></div>
            </div>

            <div className="links">
                <ul>
                    {/* <li key={0}>
                        <a href="/admin" style={{ "--i": "0.05s" } as any}>
                            {t('admin')}
                        </a>
                    </li> */}
                    <li key={1}>
                        <a href="/home" style={{ "--i": "0.1s" } as any} className="text-white hover:!text-greslernorange">
                            {t('home')}
                        </a>
                    </li>
                    <li key={2}>
                        <a href="/home/dashboard" style={{ "--i": "0.15s" } as any} className="text-white hover:!text-greslernorange">
                            {t('Workspace')}
                        </a>
                    </li>
                    <li key={3}>
                        <a href="/resources" style={{ "--i": "0.1s" } as any} className="text-white hover:!text-greslernorange">
                            {t('Resources')}
                        </a>
                    </li>
                    <li key={3}>
                        <a style={{ "--i": "0.20s" } as any}>
                            {/* <NewOffcanvas /> */}
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    );
};

export default NavbarAnimation;