import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getAll = async () => {
    try {
        let result = await api.get(Apis.Notification + "/all")
        // alert(JSON.stringify(result))
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getLikeNo = async (contentId) => {
    try {
        let result = await api.get(Apis.Notification + "/getLikeNo/" + contentId.toString())
        // alert(JSON.stringify(result))
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getUserLikeStatus = async (contentId) => {
    try {
        let result = await api.get(Apis.Notification + "/getUserLikeStatus/" + contentId.toString())
        // alert(JSON.stringify(result))
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

const saveNotification = async (contentId, type, sender, receiver, message) => {
    try {
        let body = {}
        if (['like', 'comment'].includes(type)) {
            body = {
                contentId: contentId,
                type: type,
                message: message,
                // sender:sender,
                // receiver:receiver
            }
        } else if (type == 'share' || type == 'tag') {
            body = {
                contentId: contentId,
                type: type,
                message: message,
                // sender:sender,
                receiver: receiver
            }
        }
        let result = await api.post(Apis.Notification + "/", body)

        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
}

const deleteLikeNotification = async (contentId) => {
    try {

        const result = await api.delete(`${Apis.Notification}/like/${contentId}`);

        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
}

const getUnseenLikes = async (email) => {
    try {
        let result = await api.get(Apis.getUnseenLikes + email)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

const getUnseenComments = async (Id) => {
    try {
        let result = await api.get(Apis.getUnseenComments + Id)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getUnseenTags = async (Id) => {
    try {
        let result = await api.get(Apis.getUnseenTags + Id)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getUnseenShares = async (Id) => {
    try {
        let result = await api.get(Apis.getUnseenShares + Id)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const clearAll = async () => {
    try {
        let result = await api.patch(Apis.Notification + '/clearAll')
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const markAllSeen = async () => {
    try {
        let result = await api.patch(Apis.Notification + '/markAllSeen')
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const markSeenById = async (notificationId) => {
    try {
        let result = await api.patch(Apis.Notification + "/seen/" + notificationId)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

export default {
    getUnseenComments,
    getUnseenLikes,
    getUnseenTags,
    getUnseenShares,
    getAll,
    saveNotification,
    deleteLikeNotification,
    clearAll,
    markAllSeen,
    markSeenById,
    getLikeNo,
    getUserLikeStatus
}