import { useTranslation } from 'react-i18next'

interface CardContainerProps {
  icon: any,
  title: string,
  subTitle: string,
  statistics: any,
  iconBgColor: string,
  shadowColor: string,

}
const CardContainer = ({
  icon,
  title,
  subTitle,
  statistics,
  iconBgColor = "bg-green",
  shadowColor = "shadow-greslernorange"
}: CardContainerProps) => {
  const { t } = useTranslation();
  return (
    <div className={` ${shadowColor} + " relative flex flex-col gap-3 items-center rounded-[10px] border-[1px] border-gray-200 w-auto mx-auto p-2 bg-greslernblue bg-clip-border shadow-md transition-transform transform hover:scale-105 "`}>
      <div className="relative flex h-10 w-full justify-center rounded-xl bg-cover">
        <div className="absolute flex h-10 w-full justify-center rounded-xl bg-cover  bg-gradient-to-r from-greslernblue via-white to-greslernblue" />
        <div
          className={`${iconBgColor} +" bg-green absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 "`}
        >
          {icon}
        </div>
      </div>
      <div className="mt-10 flex flex-col items-center">
        <h4 className="text-xl font-bold text-white uppercase">
          {t(title)}
        </h4>
        <p className="text-xs font-normal text-white/50 text-center">
          {t(subTitle)}
        </p>
      </div>
      <div className=" mb-3 flex justify-evenly w-full">
        {statistics}
      </div>
    </div>
  );
};

export default CardContainer