import React, { useState } from 'react';
import ModalComponent from '../../ModalComponent';
import { InstituteService } from '../../../services';
import { useSelector } from 'react-redux';
import { selectUserName } from '../../../redux/Reducer/authSlice';
import { useTranslation } from "react-i18next";

export default function Congrat() {
  const [congraModal, setCongraModal] = useState(true);
  const username = useSelector(selectUserName)
  const { t } = useTranslation();

  const userdataupdate = async () => {
    try {
      let data = await InstituteService.updateInstituteLoged()
      setCongraModal(!congraModal);

    } catch (error) {
      console.error('Error:', error);
      setCongraModal(!congraModal);
    }
  }
  const handleClose = async () => {
    userdataupdate()
    setCongraModal(false)
  }
  return (
    <ModalComponent isOpen={congraModal} title={false} onClose={handleClose}>
      <div className="modal-box bg-white rounded-lg p-2 text-center ">
        <h3 className="font-bold uppercase text-greslernorange text-4xl">{t("Congrats")}!</h3>
        <p className=""><b>{username}</b> {t("Welcome to GreSLERN")}.</p>
        <p className="py-4">{t("click the button below to close")}</p>
        <div className="modal-action">
          <button className="bg-black/20 p-2 rounded-md" onClick={userdataupdate}>{t("Close")}</button>
        </div>
      </div>
    </ModalComponent>
  );
}
