import React, { useState, useEffect } from 'react';

import { GetUserLogin } from '../../services';
import ReactivateForm from '../../components/form/reactivate';
import BackgroudL from '../../components/background/backgroundL';

function reactivate() {

  return (
    <div id="wrapper">
      <div className="flex min-h-screen">
        <div className="hidden md:block md:w-1/2 bg-art bg-cover bg-center relative overflow-hidden justify-center">
          <BackgroudL />
        </div>
        <div className="w-full md:w-1/2 bg-right animate-gradient ">
          <div className='w-full h-full bg-line-pattern bg-cover flex items-center justify-center'>
            <ReactivateForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default reactivate;
