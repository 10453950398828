import React from "react"
import { Col, Container, Card } from 'react-bootstrap';
import './styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faComment, faFolder, faGears, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { withTranslation, WithTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import { Outlet } from 'react-router-dom';
import { GetUserLogin } from "../../../services";
import AdminDashboard from "../../AdminComponents/Dashboard";

const Admin = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStudentList(); // Fetch student list using the token
  }, []);

  const fetchStudentList = async () => {
    try {
      const result = await GetUserLogin.fetchUsers();
      if (result) {
        setStudentList(result); // Set the fetched student list
        setLoading(false); // Data fetching completed
      } else {
        setLoading(false); // Data fetching completed with error
      }
    } catch (error) {
      console.error('Error fetching student list:', error);
      setLoading(false); // Data fetching completed with error
    }
  };


  return (

    <div className="dark:bg-boxdark-2 dark:text-bodydark">

      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              <Outlet />
            </div>
            <AdminDashboard />

          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};


export default withTranslation()(Admin)