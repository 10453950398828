import React from "react";
import { useNavigate } from "react-router-dom";
import LogoAnimationComments from "../LogoAnimationComment";
function RedirectPopup({ onClose }) {
    const navigate = useNavigate();
   

    return (
        <div className="absolute inset-0 flex justify-center items-center bg-blue-200 bg-opacity-75">
            <LogoAnimationComments  />
            <div className="w-[450px] bg-blue-900 rounded-lg p-8 text-white text-center relative">
                <h1 className="text-4xl mb-4"></h1>
                <p className="text-lg mb-8">Please login or Register to write feedback.</p>
                <button onClick={()=>navigate('/login')} className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2">
                    Go to Login
                </button>  &nbsp;&nbsp;&nbsp;
                <button onClick={onClose} className="bg-red-500 hover.bg-red-700 text-white font-bold py-2 px-4 rounded">
                    Close
                </button>
            </div>
        </div>
    );
}

export default RedirectPopup;
function handleRedirect() {
    throw new Error("Function not implemented.");
}

