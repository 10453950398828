import { useEffect, useState } from "react";
import { ContentService, FrequentService, GetMetaResource, GetResource, GetUsers } from "../../../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPodcast } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import ToolTip from "../../../tooltip";
import ContentLoader from "../../../loader/ContentLoader";
import GetUserList from "../../../../services/GetUserList";
import { selectEmail, selectUserId,selectInstitute } from "../../../../redux/Reducer/authSlice";
import { useSelector } from "react-redux";
import ContentPlay from "../../../ContentFileLandingPage/ContentPlay";
import { ResourceUsage } from "../ReportAnalisis/charts/ResourceUsage";
import ResourceUsageReport from "../ReportAnalisis/ResourceUsageReport";
import TableComponent from "../../../TableComponent";
import { formatDateToLocaleDateAndTimeString } from "../../../../utils/functions";
import { toast } from "react-toastify";
import { toastOpt } from "../../../../config";
import { IContentItem } from "../../../../../../server/src/Constants/interfaces";
const ResourcesTable = ({ status }) => {

  let resources = []

  type resourceProps = {
    username: string;
    creationDate: string;
    title: string;
    forcePublic: any;
    contentId: string;
    status: string;
  };

  const [filteredResources, setFilteredResources] = useState<resourceProps[]>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [isResource, setIsResource] = useState(false)
  const [showTable, setShowTable] = useState(true)
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredResources.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredResources.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const userId = useSelector(selectUserId)
  const instituteId = useSelector(selectInstitute)


  // const handlePostOnLandingPage = async (contentId) => {
  //   const contentService = new ContentService();
  //   const response = await contentService.setContentForcePublic(contentId)
  //   if (response) {
  //     NotificationManager.success("Successfully posted", "Resource ");
  //     fetchResourcesList()
  //   }
  // }
  // const handleRemovePostOnLandingPage = async (contentId) => {
  //   const contentService = new ContentService();
  //   const response = await contentService.setRemoveContentForcePublic(contentId)

  //   if (response) {
  //     NotificationManager.success("Successfully remove from post", "Resource ")
  //     fetchResourcesList()
  //   }
  // }
  // const [instituteId, setInstituteId] = useState()
  const instEmail = useSelector(selectEmail)
  const fetchResourcesList = async (instituteId) => {
    setIsResource(false)
    try {
      const result = await GetResource.getresource();
      const users = await GetUserList.getmembersList(instituteId)
      const members = []
      if (status === 0) {
        const resource = await GetMetaResource.getmetaresource();
        if (!resource) {
          if (resource.length <= 0) {
            setIsResource(false)
          }
          else {
            setFilteredResources(resource);
            setIsResource(true)
          }
        }
        else {
          setIsResource(false)
        }
      }
      else if (status === 1) {
        if (users.length <= 0) {
          setIsResource(false)
        }
        else {
          users.forEach((user) => {
            if (user.institute == instituteId) {
              members.push(user._id)
            }
          })
          result.forEach((r) => {
            if (members.includes(r?.userId?.toString())) {
              resources.push(r)
            }
          })
          setFilteredResources(resources);
          setIsResource(true)
        }

      }
      else if (status === 2) {
        if (users.length <= 0) {
          setIsResource(false)
        }
        else {
          users.forEach((user) => {
            if (user.institute == instituteId) {
              members.push(user._id)
            }
          })
          result.forEach((r) => {
            if (members.includes(r?.userId?.toString())) {
              resources.push(r)
            }
          })
          sortByViews(resources);
          setIsResource(true)
        }

      }
      else {
        if (users.length <= 0) {
          setIsResource(false)
        }
        else {
          setShowTable(false);
          users.forEach((user) => {
            if (user.institute == instituteId) {
              members.push(user._id)
            }
          })
          result.forEach((r) => {
            if (members.includes(r?.userId?.toString())) {
              resources.push(r)
            }
          })
          setFilteredResources(resources);
          setIsResource(true)
        }

      }
    } catch (error) {
      setIsResource(false)
      console.error("Error fetching resources list:", error);
    }
  };

  const sortByViews = (resource) => {
    const sortedData = [...resource].sort((a, b) => b.views - a.views); // Sort by age (ascending)
    setFilteredResources(sortedData);
  };
  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
    else
      return;
  }

  const addtousedlink = async () => {
    const useddata = {
      usedlink: "resource"
    }
    try {
      await FrequentService.addusedlink(useddata);
    } catch (error) { }
  };
  useEffect(() => {
    addtousedlink();
    resources.length > 0 ? setFilteredResources(resources) : fetchResourcesList(instituteId);

  }, [])

  const [playContentId, setPlayContentId] = useState()
  const [playModal, setPlayModal] = useState(false)
  const handlePlay = (contentId) => {
    setPlayContentId(contentId)
    setPlayModal(true)
  }
  const ResourcesTableActions = (resources) => {
    const contentService = new ContentService();

    const handlePostOnLandingPage = async (contentId) => {
      const response = await contentService.setContentForcePublic(contentId)
      if (response) {
        toast.success("Successfully posted", toastOpt);
        fetchResourcesList(instituteId)
      }
    }
    const handleRemovePostOnLandingPage = async (contentId) => {
      const response = await contentService.setRemoveContentForcePublic(contentId)

      if (response) {
        toast.success("Successfully remove from post", toastOpt)
        fetchResourcesList(instituteId)
      }
    }
    return (
      <div className="flex gap-3">

        <div>
          <ToolTip tooltip={t("play")}>
            <a href={"play/" + resources.id.toString()} >
              <FontAwesomeIcon
                icon={faPlay}
                className=" cursor-pointer hover:scale-150 duration-200 ease-in-out transition text-primary"
              /> {t("")}
            </a>
          </ToolTip>
        </div>

        {resources.status == "public" ?
          resources.forcePublic ?
            <div>
              <ToolTip tooltip={t("show on landing page")}>
                <button
                  onClick={() => handleRemovePostOnLandingPage(resources.id)}>
                  <FontAwesomeIcon
                    icon={faPodcast}
                    className=" cursor-pointer hover:scale-150 duration-200 ease-in-out transition text-primary"
                  /> {t("remove post")}
                </button>
              </ToolTip>
            </div>
            :
            <div>
              <ToolTip tooltip={t("show on landing page")}>
                <button
                  onClick={() => handlePostOnLandingPage(resources.id)}>
                  <FontAwesomeIcon
                    icon={faPodcast}
                    className=" cursor-pointer hover:scale-150 duration-200 ease-in-out transition text-primary"
                  /> {t("post")}
                </button>
              </ToolTip>
            </div>
          : undefined
        }
      </div>
    )
  }

  function formatContentData(data: any[]): IContentItem[] {
    return data.map(item => ({
      id: item.contentId,
      profilePhoto: undefined,
      user: item.username,
      resource: item.title,
      date: formatDateToLocaleDateAndTimeString(item.creationDate),
      status: item.status,
      isDeletedPermanently: item.isDeletedPermanently,
      forcePublic: item.forcePublic
    }));
  }

  return (
    <div className="">

      {showTable &&
        <>
          {playModal && <ContentPlay contentId={playContentId} onClose={setPlayModal} />}
          <div className="">
            {isResource ?
              <TableComponent data={formatContentData(filteredResources)} actionComponent={ResourcesTableActions} />
              :
              <ContentLoader />}
          </div>
        </>
      }
      {status === 3 && <>
        <ResourceUsage resourceList={filteredResources} />
        <ResourceUsageReport resourceList={filteredResources} />
      </>
      }

    </div>
  );
}
export default ResourcesTable;
