import React, { useState, Fragment } from "react";
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next'
import { faChevronDown, faBars, faUserAlt, faLanguage, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../../components/assets/GreSLERN-logo.svg'
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

import Languages from '../../../constances/languages.json';
import CircleNav from "../../../components/circleNav";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Header({ setLanTrigger, isAuthenticated=false }) {
    const { t, i18n } = useTranslation()
    
    const navigate = useNavigate()
    
    const [isCircleActive, setIsCircleActive] = useState(false);const 
    handleToggleNav = () => {
        setIsCircleActive(prevState => !prevState);
    };

    const handleChangeLanguage = (lng: string, name: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("language", lng)
        localStorage.setItem("languageName", name)
        setLanTrigger(name)
    };

    return (
        <div className="fixed justify-between top-0 h-[5rem] w-full z-[100] bg-white" style={{ boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)' }}>
            <div className="flex justify-between items-center h-full max-w-screen-xl mx-auto ">
                <div className="w-1/3">
                    <ul className="flex text-slate-600 hidden lg:flex">
                        <li className="px-2 border-r-2 cursor-pointer group hover:text-greslernorange transition duration-300" >
                            <Link to="/" reloadDocument={true}><FontAwesomeIcon icon={faHome} /></Link>
                            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-greslernorange"></span>
                        </li>
                        <li className="px-2 border-r-2 cursor-pointer group hover:text-greslernorange transition duration-300" >
                            <ScrollLink to="featureSection" smooth={true} duration={500}>{t("Features")}</ScrollLink>
                            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-greslernorange"></span>
                        </li>
                        <li className="px-2 border-r-2 cursor-pointer group hover:text-greslernorange transition duration-300">
                            <ScrollLink to="aboutSection" smooth={true} duration={500}>{t("About")}</ScrollLink>
                            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-greslernorange"></span>
                        </li>
                        <li className={`${isAuthenticated && "border-r-2 truncate" } + " px-2 cursor-pointer group hover:!text-greslernorange transition duration-300"`}>
                            <Link to="/contactus" className=" !text-[#475569] hover:!text-greslernorange">{t("Contact")}</Link>
                            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-greslernorange"></span>
                        </li>
                       
                        { isAuthenticated &&
                        <>
                         <li className="px-2 border-r-2 cursor-pointer group hover:text-greslernorange transition duration-300">
                            <ScrollLink to="resourceSection" smooth={true} duration={500}>{t("Resource")}</ScrollLink>
                            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-greslernorange"></span>
                        </li>
                        <li className="px-2 cursor-pointer group hover:text-greslernorange transition duration-300" >
                            <a href="/home/dashboard" className=" text-greslernorange font-semibold hover:text-greslernorange" >{t("Dashboard")}</a>
                            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-greslernorange"></span>
                        </li>
                        </>
                        }
                    </ul>
                    <Menu as="div" className="relative inline-block text-left h-full w-auto lg:hidden">
                        <Menu.Button className="w-auto h-full text-slate-600  flex justify-center items-center border-0 px-4 py-2">
                            <FontAwesomeIcon icon={faBars} className="-mr-1 h-8 w-8 text-slate-600 pl-4 block lg:hidden" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                className={classNames(
                                                    active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                    'block w-full px-4 py-2 text-left text-sm'
                                                )}
                                            >
                                                <ScrollLink to="features" smooth={true} duration={500}>{t("Features")}</ScrollLink>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                className={classNames(
                                                    active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                    'block w-full px-4 py-2 text-left text-sm'
                                                )}
                                            >
                                                <ScrollLink to="about" smooth={true} duration={500}>{t("About")}</ScrollLink>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                className={classNames(
                                                    active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                    'block w-full px-4 py-2 text-left text-sm'
                                                )}
                                            >
                                                <ScrollLink to="footer" smooth={true} duration={500}>{t("FAQ")}</ScrollLink>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                className={classNames(
                                                    active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                    'block w-full px-4 py-2 text-left text-sm'
                                                )}
                                            >
                                                <ScrollLink to="resources" smooth={true} duration={500}>{t("Resource")}</ScrollLink>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                className={classNames(
                                                    active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                    'block w-full px-4 py-2 text-left text-sm'
                                                )}
                                            >
                                                <a href="/home/dashboard" >{t("Dashboard")}</a>
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
                <div className="flex justify-center place-item-center w-1/3"  onClick={() => navigate('/')}>
                    <img
                        alt="GreSELERN Logo"
                        src={logo}
                        className="max-w-xs w-[150px] md:w-[200px]"
                    />
                </div>
                <div className="flex justify-end items-center pr-4 w-1/3">
                    {
                    isAuthenticated ? 
                    <CircleNav 
                            isActive={isCircleActive}
                            toggleNav={handleToggleNav} />
                    :
                    <>
                        <Link to='/login' className="mr-4 text-white border-2 border-[#007BFF] bg-[#007BFF] px-4 py-2 rounded hover:!text-[#007BFF] hover:bg-white hidden lg:block">{t("Login")}</Link>
                        <Link to='/login' className="mr-4 text-white border-2 border-[#007BFF] bg-[#007BFF] p-2  rounded-full hover:!text-[#007BFF] hover:bg-white block lg:hidden">
                            <FontAwesomeIcon icon={faUserAlt} className=" h-5 w-5 text-white " aria-hidden="true" />

                        </Link>
                        <Menu as="div" className="relative inline-block text-left h-full ">
                            <Menu.Button className="w-full h-full text-slate-600 border-slate-600 flex justify-center items-center border px-4 py-2 rounded hover:!border-[#007BFF] hidden lg:flex">
                                {localStorage.getItem('languageName') ? localStorage.getItem('languageName') : 'English'}
                                <FontAwesomeIcon icon={faChevronDown} className="-mr-1 h-5 w-5 text-slate-600 pl-1" aria-hidden="true" />
                            </Menu.Button>
                            <Menu.Button className="w-14 h-14 text-slate-600 border-slate-600 flex justify-center items-center border rounded-full hover:!border-[#007BFF] lg:hidden">
                                <FontAwesomeIcon icon={faLanguage} className=" h-8 w-8 text-slate-600 pl-1" aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        {Languages?.map((item) => (
                                            <Menu.Item key={item.id}>
                                                {({ active }) => (
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-left text-sm'
                                                        )}

                                                        onClick={() => handleChangeLanguage(item.value, item.name)}
                                                    >
                                                        {item.name}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </>

                    }
                </div>
            </div>
        </div>
    )
}

export default Header
