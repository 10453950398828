import React, { useEffect, useState } from 'react';
import { ContentService, GetResource, GetResourceShared } from "../../../services"
import SharedUsersList from "../../SharedUsersList"
import SearchUserModal from "../../tagUserModal/TagUserModal"
import GetUserList from '../../../services/GetUserList';
import { NotificationManager } from 'react-notifications'

import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookBookmark, faStar } from '@fortawesome/free-solid-svg-icons';
import NotificationSettings from '../../../services/NotificationSettings';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { selectAuthStatus } from '../../../redux/Reducer/authSlice';
const BookmarkButton = ({setLogin, resourceId, email }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchUserModal, setSearchUserModal] = useState(false);
  const [bookMarked, setBookmarked] = useState(false);
  const [totalBookmarks, setTotalBookmarks] = useState(0);

  const isAuthenticated = useSelector(selectAuthStatus)

  const handleShareClick = () => {
    if(isAuthenticated){
      bookmarkResource();
      countBookmarks();
    }else{
      setLogin(true)
    }
    


  };
  const bookmarkResource =async () => {
    let data = await GetUserList.bookmarkResource(email, resourceId);
    if (data.bookmarked){
      NotificationManager.success(t("bookmarked"));
    }
    else {
      NotificationManager.success(t("unbookmarked"));

    }
    getbookMarkstatus();

  }
  const countBookmarks = async () => {
    let counts = await NotificationSettings.getBookmarkedCounts(resourceId);
    if (counts) {
      setTotalBookmarks(counts.totalBookmarked);
    } else {
    }
  };
  const getbookMarkstatus = async () => {
    let data = await GetUserList.getBookmarkStatus(email, resourceId);
    if(data){
      setBookmarked(data.success);

    }
  }

  useEffect(() => {
    countBookmarks();
    getbookMarkstatus();

  });
  return (
    <div>
      <button
        className={`flex items-center space-x-1 text-gray-600 hover:text-orange focus:outline-none ${bookMarked ? 'text-orange' : ''
          }`}
        onClick={handleShareClick}>
        <FontAwesomeIcon
          className="text-2xl "
          icon={faStar}
        />
        <h2 className="text-sm  text-gray-900">
          {" "}
          {totalBookmarks} {t("Bookmark")}
        </h2>
      </button>

    </div>
  );
};

export default BookmarkButton;
