import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from 'react-notifications';

import { GetNotification } from "./services";
import { selectAuthStatus, selectEmail } from "./redux/Reducer/authSlice";
import { updateLikes, updateComments, updateTags, updateShares, updateReview, updateSessionID } from './redux/Reducer/socketSlice';
import { socket } from './apiConfig';
import notificationSound from "./components/assets/sounds/archivo.mp3"
import NotificationSettings from './services/NotificationSettings';



export function setCookie(name, value, minute) {
    let expires = "";
    if (minute) {
        let date = new Date();
        expires = "; expires=" + new Date(date.setMinutes(date.getMinutes() + minute)).toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (const element of ca) {
        let c = element;
        while (c.startsWith(' ')) c = c.substring(1, c.length);
        if (c.startsWith(nameEQ)) return c.substring(nameEQ.length, c.length);
    }
    return null;
}


export function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function SocketSession() {

    const dispatch = useDispatch()
    const isAuthenticated = useSelector(selectAuthStatus)
    const useremail = useSelector(selectEmail)

    const [settings, setSettings] = useState<any>(null);

    let commentNo = 0
    let sharedNo = 0
    let tagNo = 0
    let likeNo = 0
    let reviewNo = 0
    const getnotificationList = async () => {
        commentNo = 0;
        sharedNo = 0;
        tagNo = 0;
        likeNo = 0;
        reviewNo = 0;
        let data = await GetNotification.getAll();
        if (data) {
            data.map(notification => {
                if (notification.clear) {
                    return; // Skip cleared notifications
                }
                switch (notification.type) {
                    case 'comment':
                        commentNo++;
                        break;
                    case 'share':
                        sharedNo++;
                        break;
                    case 'tag':
                        tagNo++;
                        break;
                    case 'like':
                        likeNo++;
                        break;
                    case 'review':
                        reviewNo++;
                        break;
                    default:
                        break;
                }
            });

            updateCounters();
        }
    };

    const updateCounters = () => {
        dispatch(updateComments(commentNo))
        dispatch(updateShares(sharedNo))
        dispatch(updateTags(tagNo))
        dispatch(updateLikes(likeNo))
        dispatch(updateReview(reviewNo))
    }
    useEffect(() => {
        // Use the fetched data here or perform some other action with it
        if (isAuthenticated) {

            if (settings) {
                // like
                socket.on(`emitstatus${useremail}`, (emitstatus) => {
                    const sound = new Audio(notificationSound);
                    const [email, title, username, isliked, sessionID] = emitstatus;
                    const useremailData = useremail?.trim().toLowerCase();
                    dispatch(updateSessionID(sessionID))
                    if (!isliked) {
                        if (useremailData === email.trim().toLowerCase()) {
                            const notificationMessage = `GresLern: Resource ${title} was liked by ${username}`;
                            getnotificationList()

                            if (settings.tag.sound) {
                                sound.play();
                            }
                            if (settings.tag.popups) {
                                NotificationManager.success(notificationMessage)
                            }
                        }
                    }
                    else {
                        getnotificationList()
                        NotificationManager.success('Like Removed');
                    }
                });
                //comment
                socket.on(`emitcomments${useremail}`, (emitcomments) => {
                    const sound = new Audio(notificationSound);
                    const [email, title, username, sessionID] = emitcomments;
                    dispatch(updateSessionID(sessionID))
                    // Assuming getCookie function is defined
                    if (useremail == email.trim().toLowerCase()) {
                        const notificationMessage = `GresLern: Resource ${title} new feedback by ${username}`;
                        getnotificationList()
                        if (settings.comment.sound) {
                            sound.play();
                        }
                        if (settings.comment.popups) {
                            NotificationManager.success(notificationMessage);
                            showNotification('New Feedback!', notificationMessage);
                        }
                    }
                });
                //tag
                socket.on(`emittags${useremail}`, (emittags) => {
                    const sound = new Audio(notificationSound);
                    const [title, username, taggedusers, sessionID] = emittags;
                    dispatch(updateSessionID(sessionID))
                    // Assuming getCookie function is defined
                    taggedusers.map((users) => {
                        const tagUser = users.emails[0].email
                        if (tagUser === useremail) {
                            const notificationMessage = `GresLern: Resource ${title} new Tagged by ${username}`;
                            getnotificationList()
                            if (settings.like.sound) {
                                sound.play();
                            }
                            if (settings.like.popups) {
                                NotificationManager.success(notificationMessage);
                                showNotification('New Tags!', notificationMessage);
                            }
                        }
                    })
                });
                //share
                socket.on(`emitshares${useremail}`, (emitshare) => {
                    const sound = new Audio(notificationSound);
                    const [title, username, sharesusers, sessionID] = emitshare;
                    dispatch(updateSessionID(sessionID))
                    // Assuming getCookie function is defined
                    sharesusers.map((users) => {
                        const shareUser = users.email
                        if (shareUser === useremail) {
                            const notificationMessage = `GresLern: Resource ${title} new Shared by ${username}`;
                            getnotificationList()
                            if (settings.like.sound) {
                                sound.play();
                            }
                            if (settings.like.popups) {
                                NotificationManager.success(notificationMessage);
                                showNotification('New Shares!', notificationMessage);
                            }
                        }
                    })
                })

            }
        }
    }, [settings, dispatch, isAuthenticated]);

    const requestNotificationPermission = () => {
        if ('Notification' in window) {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Notification Granted')
                }
            });
        }
    };

    const showNotification = (title, body) => {
        if (Notification.permission === 'granted') {
            const notification = new Notification(title, { body });
            notification.onclick = () => {
                // Handle notification click event if needed
            };
        }
    };

    const fetchInitialSettings = async () => {
        try {
            const initialSettings = await NotificationSettings.getNotificationSettings(useremail);
            if (initialSettings) {
                setSettings(initialSettings);
            }
        } catch (error) {
            console.error('Error fetching initial settings:', error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            requestNotificationPermission()
            fetchInitialSettings()
            getnotificationList();
        }
    }, [isAuthenticated]);
}

export const getDateValues = (countDown: any) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

