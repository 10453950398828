import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noResult from "../assets/no-result.svg";
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="col-span-full flex flex-col text-[16px] justify-center items-center gap-2 my-2">
      <img src={noResult} alt="" className="w-[30%]" />
      <p className="text-warning flex gap-1 items-center">
        <FontAwesomeIcon
          icon={faFrown}
          className="me-1"
        />
        {t("cannotResource")}
      </p>
    </div>
  );
};

export default NotFound;
