import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { AnnouncementService } from '../../../services';
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { selectInstitute } from '../../../redux/Reducer/authSlice';
export default function Announcement({ announcementList, onUpdate }) {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const instituteId = useSelector(selectInstitute)

    const handleDeleteAnnouncement = async (announcementid: any) => {
        try {
            const instituteId1 = { instituteId: instituteId }
            const data = await AnnouncementService.deleteAnnouncement(announcementid, instituteId1);
            if (data) {
                NotificationManager.success("Successfull removed", "Announcement");
                onUpdate();
            }
            else {
                onUpdate()
                NotificationManager.warning("not removed", "Announcement")
            }
        } catch (error) {
            onUpdate();
        }
    }
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % announcementList.length);
    };
    return (
        <div className="flex flex-row p-2 h-10 text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 justify-between">
            <div className='flex flex-row align-middle space-x-3'>
                <div className='flex flex-row space-x-1 align-middle'>
                    <FontAwesomeIcon icon={faArrowCircleUp} className='w-3 h-3 pt-1' />
                    <span className="font-bold">&nbsp; {announcementList[currentIndex]?.title}</span>
                </div>
                <div className="text-sm font-medium">
                    {announcementList[currentIndex]?.message}
                </div>
            </div>
            <div className='flex flex-col align-start justify-center'>
                <button onClick={(e) => {
                    e.preventDefault()
                    handleDeleteAnnouncement(announcementList[currentIndex]._id)
                }
                } type="button"
                    className="bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 hover:bg-yellow-200 inline-flex items-center justify-center h-4 w-4 mb-1 dark:bg-gray-800 dark:text-yellow-300 dark:hover:bg-gray-700"
                >
                    <span className="sr-only">{t("Delete")}</span>
                    <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                </button>
                <button onClick={handleNext} type="button"
                    className="bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 hover:bg-yellow-200 inline-flex items-center justify-center h-4 w-4 mb-1 dark:bg-gray-800 dark:text-yellow-300 dark:hover:bg-gray-700"
                >
                    <FontAwesomeIcon icon={faArrowAltCircleRight} className='w-3 h-3' />
                </button>
            </div>
        </div>
    );
}