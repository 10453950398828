import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import ContentList from '../../../../ContentFile/ContentList';
import NoRecordFound from '../../../../NotRecoredFound';

const ContainerComponent = ({resources}) => {
  const [isListMode, setIsListMode] = useState(true)
  const [searchKey, setSearchKey] = useState("")
  const [sortOption, setSortOption] = useState('Most Popular');
  const [searchedData, setSearchedData] = useState<any>(resources)

  const handleSearchKey = () => {
    setSearchedData(
        resources.filter(option =>
            option.title.toLowerCase().includes(searchKey.toLowerCase()
            )
        )
      )
  }

  useEffect(() => {
      setSearchedData(resources)
      setSearchKey('')
  }, [sortOption])

  useEffect(() => {
      handleSearchKey()
  }, [searchKey])

  return (
    <div className='flex flex-col w-full'>
      <div className='flex justify-center'>
          <div className="relative mt-2">
              <input
                  className="appearance-none border-2 pl-10 border-greslernorange/50 hover:border-greslernorange transition-colors rounded-md w-full py-2 px-5 pr-16  text-gray-800 leading-tight focus:outline-none focus:ring-purple-600 focus:border-purple-600 focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Search..."
                  value={searchKey}
                  onChange={e => setSearchKey(e.target.value)}
              />
              <div className="absolute right-0 inset-y-0 flex items-center">
                  {searchKey == '' ? <></> :
                      <FontAwesomeIcon
                          icon={faClose}
                          className="-ml-1 mr-3 h-5 w-5 text-red/60 hover:text-red cursor-pointer"
                          onClick={() => setSearchKey('')}
                      />}
              </div>

              <div className="absolute left-0 inset-y-0 flex items-center">
                  <FontAwesomeIcon icon={faSearch} className='h-6 w-6 ml-3 text-greslernorange/70' />
              </div>
          </div>
      </div>
      <ul className={` p-2 grid ${isListMode ? 'grid-cols-1' : 'grid-cols-2'} gap-2`}>

          {searchedData.length ?
              searchedData.map((content: any, index: number) => (
                  <ContentList
                  key={index}
                  content={content}
                  contentType={'public'}
                  />
              )) :
              <NoRecordFound />
          }
      </ul>
  </div>
  )
}

export default ContainerComponent