import { useEffect, useState } from "react";
import moment from 'moment';
import { useSelector } from "react-redux";

import { selectEmail, selectUserName } from "../../../redux/Reducer/authSlice";
import { GetCommentbyResource, CommentCounter, CreateComment, GetResourceById } from '../../../services';
import { NotificationManager } from 'react-notifications';
import RedirectPopup from '../../RedirectPopup';
import LikeButton from "../../Buttons/LikeButton";
import { useTranslation } from "react-i18next";

type resultProps = {
  message: string;
  isPublic: string;
  name: String;
  _id: String;
  resourceId: any;
  createdAt: undefined;
};
const ContentPlayerUI = (props) => {
  const [databyresource, setDatabyresource] = useState<resultProps[]>([]);
  const [resourceId] = useState(props.resourceId);
  const { t } = useTranslation();
  const [resourceStatus, setResourceStatus] = useState();
  const [showcommentlist] = useState(props.showcommentlist);
  const [message, setMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState<boolean>();
  const accessTokenUsername = useSelector(selectUserName); // Retrieve the token from the cookie
  const useremail = useSelector(selectEmail);
  const [countComment, setCountComment] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = databyresource.slice(firstIndex, lastIndex);
  const npage = Math.ceil(databyresource.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);


  const fetchCommentCounter = async (resourceId) => {
    let data = await CommentCounter.getcommentcount(resourceId)
    if (data) {
      setCountComment(data);
    }
  }

  const fetchByResource = async (resourceId) => { // update resource function update rule public,private , shared  -- by muluken 
    let data = await GetResourceById.getresourceById(resourceId);
    setResourceStatus(data[0].status);
    // else { }
  }
  const fetchcommentByResource = async (resourceId) => {
    let data = await GetCommentbyResource.getcommentbyresource(resourceId)
    if (data) {
      setDatabyresource(data);
    }
    else { }
  }
  const submitComment = async (e) => {
    if (accessTokenUsername) {
      e.preventDefault();
      if (message === '') {
        NotificationManager.warning("All filed Required", "Feedback")
        return;
      }
      else {
        const commentData = {
          name: accessTokenUsername,
          resourceId: resourceId,
          message: message,
          isPublic: false,
          userId: 'newuser',
        }
        try {
          let data = await CreateComment.createcomment(commentData)
          if (!data.ok) {
            setAlertMessage(false);
            setMessage('');
          }
          else {
            setAlertMessage(true);
            setMessage('');
          }
        } catch (error) {
          console.error('Error during feedback:', error);
        }
      }
    }
    else {
      setShowPopup(true); // Show the redirect popup when the user is not found

    }

  }
  useEffect(() => {
    fetchCommentCounter(resourceId);
    fetchByResource(resourceId);
    fetchcommentByResource(resourceId);
    if (alertMessage === true) {
      const timer = setInterval(() => {
        setAlertMessage(false)
      }, 10000)
      return () => clearInterval(timer)
    }
  }, [alertMessage]);
  const handleChange = (event) => {
    const value = event.target.value;
    setMessage(value);
  };
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };


  return (
    <>
      {(resourceStatus === 'private' ? (
        <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
          <p className="font-bold">{t("Feedback off")}</p>
          <p className="text-sm font-light">{t("You can't accept feedback on this private resource.")}</p>
        </div>
      ) : (
        <section className="bg-white py-1 mb-12 w-full">
          <div className="max-w-2xl mx-5">

            <div className="flex justify-between items-center mb-6 space-x-2">
              <LikeButton resourceId={resourceId} username={useremail} />

              {/* <svg className="w-4 h-4 text-white mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
              <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
              <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
            </svg> */}
              <div className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-8 w-8">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>


                {(countComment === undefined ? (
                  undefined
                ) : (

                  <h2 className="text-lg lg:text-2xl font-bold text-gray-900">{countComment}</h2>
                ))}
              </div>
            </div>
            <form className="mb-6 w-full">
              <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200">
                <label className="sr-only">{t("Your feedback")}</label>
                <textarea
                  value={message}
                  required
                  onChange={handleChange}
                  rows={6}
                  className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
                  placeholder="Write a feedback..."

                >
                </textarea>
              </div>
              <button
                onClick={submitComment}
                className='text-[#fc2803] border-[#fc2803] hover:!text-[#fff] hover:bg-[#fc2803] hover:border-[#fc2803]'>
                {t("Post feedback")}
              </button>
              {showPopup && <RedirectPopup onClose={togglePopup} />}
            </form>
            {(showcommentlist ? (
              <>
                {(databyresource.length === 0 ? (
                  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">{t("No feedback!")}</strong>
                  </div>
                ) : (
                  undefined
                ))}
                {(alertMessage ? (
                  <div className="bg-green-100 border-t border-b border-green-500 text-blue-700 px-4 py-3" role="alert">
                    <p className="font-bold">{t("Feedback status")}</p>
                    <p className="text-sm">{t("Your Feedback is successfully added.")}</p>
                  </div>
                ) : (undefined
                ))}
                {records && records.map((data, i) => {
                  return (
                    <section className="p-3 mb-1 text-base bg-white rounded-lg" key={i}>
                      <footer className="flex justify-between items-center mb-1">
                        <div className="flex items-center">
                          <img className="mr-2 w-6 h-6 rounded-full"
                            src="https://as1.ftcdn.net/v2/jpg/02/59/39/46/1000_F_259394679_GGA8JJAEkukYJL9XXFH2JoC3nMguBPNH.jpg"
                          />
                          {(data.name === accessTokenUsername ? (
                            <p className="inline-flex items-center mr-3 text-sm text-gray-900">{t("You")}</p>

                          ) : (
                            <p className="inline-flex items-center mr-3 text-sm text-gray-900"> {data.name}</p>
                          ))}

                          <p className="text-sm text-gray-600">
                            <p className="text-xs text-meta-5">
                              {moment(data.createdAt).fromNow()}</p></p>
                        </div>
                      </footer>
                      <p className="text-gray-500">{data.message} </p>
                    </section>
                  );
                })}
              </>
            ) : (
              undefined
            ))}
          </div>
          {(countComment === undefined ? (
            undefined
          ) : (
            <nav aria-label="Page navigation example">
              <ul className="list-style-none flex ml-12">
                <li className='page-item'>
                  <a
                    className="pointer-events-none relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300"
                    onClick={prePage}
                    href={`!#/home/dashboard/play/${resourceId}`}
                  >{t("Prev")}
                  </a>
                </li>
                {
                  numbers.map((n, i) => (
                    <li className={`${currentPage === n ? 'whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]' : 'page-item'}`} key={i}>
                      <a className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                        href={`!#/home/dashboard/play/${resourceId}`} onClick={() => changeCPage(n)}>{n}</a>
                    </li>
                  ))
                }
                <li>
                  <a
                    className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                    href={`!#/home/dashboard/play/${resourceId}`} onClick={nextPage}>{t("Next")}</a>
                </li>
              </ul>
            </nav>
          ))}
        </section>
      ))}
    </>
  );
  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
  }
}

export default ContentPlayerUI