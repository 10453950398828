import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserProfileAvatarComponent from '../UserProfileAvatarComponent';
import NoRecordFound from '../NotRecoredFound';

type TableComponentProps = {
  data: any;
  actionComponent?: any;
  setting?: any;
  title?: string;
};

const TableComponent: React.FC<TableComponentProps> = ({ data, actionComponent, setting = {}, title = '' }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(setting.rowPerPage != undefined ? setting.rowPerPage : 10);
  const [searchTerm, setSearchTerm] = useState('');
  const [tableSetting, setTableSetting] = useState({
    searchable: setting.searchable != undefined ? setting.searchable : true,
    tableAction: setting.tableAction != undefined ? setting.tableAction : true,
  })

  const { t } = useTranslation()

  const filteredData = data.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filterId = (data: any) => {
    const { id, profilePhoto, forcePublic, isDeletedPermanently, ...rest } = data;
    return rest;
  }

  // useEffect(() => {
  //   setTableSetting({
  //     setting.searchable && searchable:setting.searchable,
  //   })

  // }, [setting])

  return (
    currentRows.length > 0 ?
      <div>
        <div className="flex items-center mb-4 space-x-4">
          <div className='w-1/2 self-end text-xs text-greslernorange/80 font-bold capitalize '>{title}</div>
          {tableSetting.searchable ?
            <div className='w-1/2 flex justify-end'>
              <input
                type="text"
                placeholder={t("Search")}
                value={searchTerm}
                onChange={handleSearchChange}
                className="px-3 py-1 text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-greslernorange dark:text-gray-400"
              />
            </div> : <></>
          }
        </div>
        <div className='w-full inline-block rounded-lg border shadow-2xl overflow-clip'>

          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-greslernorange uppercase bg-gray-100 dark:bg-greslernorange dark:text-white/50 border-b-2 border-greslernorange/30 ">
              <tr>

                <th>
                  {/* place holder for profile image  */}
                </th>


                {Object.keys(filterId(data[0])).map((key, index) => (
                  key !== 'id' && key !== 'profilePhoto' &&
                  <th key={index} scope="col" className="px-2 py-3">
                    {t(key)}
                  </th>
                ))}
                {tableSetting.tableAction ?
                  <th scope='col' className='px-2 py-3'>
                    {t("action")}
                  </th> : undefined
                }
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <tr key={index} className="bg-white border-b dark:bg-greslernorange dark:border-greslernorange/30">
                  <td >
                    {/* // TODO test on production if image show   */}
                    <UserProfileAvatarComponent profilePhotoUrl={row['profilePhoto']} userName={row['user']} />
                  </td>

                  {Object.values(filterId(row)).map((value: any, valueIndex: number) => (

                    <td key={valueIndex} className={`${value == 'public' || value == 'active' ? " font-bold text-green " : ""} px-2 py-2`}>
                      {value}
                    </td>
                  ))}
                  {tableSetting.tableAction ?
                    <td className="px-6 py-2">
                      {actionComponent ? actionComponent(row) : undefined}
                    </td>
                    : undefined
                  }
                </tr>
              ))}


            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4 text-sm">
          <nav aria-label="Table navigation">
            <ul className="inline-flex items-center -space-x-px">
              <li>
                <button
                  onClick={handlePrevPage}
                  className={`px-3 py-1 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-greslernorange dark:bg-gray-800 dark:border-greslernorange dark:text-gray-400 dark:hover:bg-greslernorange dark:hover:text-white ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                >
                  {t("Prev")}
                </button>
              </li>
              {Array.from({ length: Math.ceil(filteredData.length / rowsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
                <li key={pageNumber}>
                  <button
                    onClick={() => paginate(pageNumber)}
                    className={`px-3 py-1 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-greslernorange dark:bg-gray-800 dark:border-greslernorange dark:text-gray-400 dark:hover:bg-greslernorange dark:hover:text-white ${currentPage === pageNumber ? 'z-10 text-white !bg-greslernorange/70 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-blue-500 dark:bg-blue-900 dark:text-white dark:hover:bg-greslernorange/70' : ''
                      }`}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}
              <li>
                <button
                  onClick={handleNextPage}
                  className={`px-3 py-1 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-greslernorange dark:bg-gray-800 dark:border-greslernorange dark:text-gray-400 dark:hover:bg-greslernorange dark:hover:text-white ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                >
                  {t("Next")}
                </button>
              </li>
            </ul>
          </nav>

          <div className="flex items-center space-x-4">
            <label htmlFor="rows-per-page" className="text-gray-500 dark:text-gray-500 ">
              {t("Rows per page")}:
            </label>
            <select
              id="rows-per-page"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="px-2 py-1 text-center text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-greslernorange dark:text-gray-400"
            >
              <option value={5}>{t("5")}</option>
              <option value={10}>{t("10")}</option>
              <option value={20}>{t("20")}</option>
              <option value={50}>{t("50")}</option>
              <option value={100}>{t("100")}</option>
            </select>
          </div>
        </div>
      </div>
      : <NoRecordFound />
  );
};

export default TableComponent;


