import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { socket } from "../apiConfig";

import ModalComponent from "./ModalComponent";
import { selectEmail } from "../redux/Reducer/authSlice";
import { GetNotification, GetResourceById, GetUsers } from "../services";
import GetUserList from "../services/GetUserList";
import MultipleSelectWithSearch from "./MultipleSelectWithSearch";


const SearchUserModal = (props) => {
  const { t } = useTranslation()
  const [resourceId] = useState(props.resourceId);
  const [show, setShow] = useState(true);
  const [result, setResult] = useState([]);
  const [sharedTo, setSharedTo] = useState([]); // Explicitly define the type
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [sharedListArrayEmail, setSharedListArrayEmail] = useState<any[]>([]);

  const accessTokenUsername = useSelector(selectEmail);


  const closeModal = () => {
    handleClose()
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setShow(false);
    props.closeModal()
  };

  const handlesharealert = () => {
    submitSharedResource(resourceId);
    handleClose();
  };

  const fetchByResourceById = async (resourceId) => {
    let data = await GetResourceById.getresourceById(resourceId);
    data.forEach((res) => {
      setSharedListArrayEmail(res.sharedTo.map((item: { email: any; }) => item.email));
    });

  }

  const saveShareNotifications = async (resId, receiver) => {
    await GetNotification.saveNotification(resId, "share", null, receiver, "Shared content");
  }
  const submitSharedResource = async (resourceId) => {
    try {
      const data = await GetUserList.shareResource(
        resourceId,
        sharedTo
      );
      sharedTo.forEach(async (element: any) => {
        saveShareNotifications(resourceId, element.id);
      });
      socket.emit('sharestatus', { resourceId, accessTokenUsername }, (error) => {
        if (error) {
          console.error('Error emitting feedback status:', error);
        }
        else {

          data && toast.success("Successfully shared");
        }
      });

    } catch (error) {
      console.error("Error sharing resource:", error);
      toast.error("Error sharing resource");
    }
  };

  const fetchUserList = async () => {
    let resource = await GetUsers.getuserlistAll();
    // alert(JSON.stringify(resource))

    if (resource) {
      // Filter out the current user from the options
      let filteredOptions = resource.filter(user => user.email !== accessTokenUsername);
      // Filter out admins from the options
      filteredOptions = filteredOptions.filter(user => user.type !== "admin");
      filteredOptions = filteredOptions.filter(user => user.email !== process.env.REACT_APP_BIE_MAIL);
      // filteredOptions = filteredOptions.filter(user => user.status === null || user.status === '1');

      filteredOptions = filteredOptions.filter(user => !(sharedListArrayEmail.includes(user.email)));

      const options = filteredOptions.map((user) => ({
        id: user._id,
        name: user.name,
        email: user.email,
        gender: user.gender,
        country: user.country,
        type: user.type,
        profilephoto: user.profilephoto
      }));
      setResult(options);
    }
  };

  useEffect(() => {
    fetchByResourceById(resourceId);
    fetchUserList();
  }, []);

  return <ModalComponent isOpen={isModalOpen} onClose={closeModal} title={false}>
    <div className="flex-row w-full h-full bg-[#0e1c44a7]">
      <div className=" row-start-1">
        <MultipleSelectWithSearch options={result} shareToList={setSharedTo} resourceId={resourceId} />
      </div>

      <div className="mt-3 flex sm:flex-row-reverse  sm:px-6 row-start-2">
        <button type="button" className=" mx-2 inline-flex w-full justify-center rounded-md bg-blue-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={handlesharealert}>
          {t("Update Share")}
        </button>
        <button type="button" className=" inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={handleClose}>
          {t("Cancel")}
        </button>
      </div>
    </div>
  </ModalComponent>;
};

export default SearchUserModal;
