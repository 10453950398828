import { useEffect, useState } from "react";
import { GetUsers } from "../../../../services";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../root/dashboard/addon/contentHeader";
import ContentLoader from "../../../loader/ContentLoader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faShareFromSquare, faTrash, faUserCheck, faUserSlash, faUsers, faWarning } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ToolTip from "../../../tooltip";
import { NotificationManager } from 'react-notifications';
import ModalComponent from "../../../ModalComponent";
import { Spin } from "react-cssfx-loading";
import CreateGroupInstitute from "../../../../services/CreateGroupInstitute";
import { useSelector } from 'react-redux';
import { selectUserId, selectInstitute } from '../../../../redux/Reducer/authSlice';
import TableComponent from "../../../TableComponent";
import { IUserItem } from "../../../../../../server/src/Constants/interfaces";
import { formatDateToLocaleDateAndTimeString } from "../../../../utils/functions";
import NoRecordFound from "../../../NotRecoredFound";

type userProps = {
  name: string;
  profilephoto: string;
  country: string;
  createdAt: any;
  email: string;
  generatedTokenExpiration: Date;
  status: string;
  type: string;
  generatedToken: string;
};

type profileprops = {
  profilephoto: string;
  name: string;
  email: string;
  bio: string;
  type: string;
  gender: string;
  country: string;
  username: string;
  phone: string;
  createdAt: Date;
  status: string;
  institute: string;
  id: string;
  generatedToken: string;
};

const UsesTable = ({ users = [], status = 0, report = false }) => {
  const [membersList, setMembersList] = useState<userProps[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isUsers, setIsUsers] = useState(false)
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const [result, setResult] = useState<profileprops[]>([]);
  const { t } = useTranslation();
  const [groupId, setGroupId] = useState('');
  const [loading, setLoading] = useState(false);
  const [groupResult, setGroupResult] = useState<profileprops[]>([]);
  const [subgroupId, setSubGroupId] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [addTogroup, setAddTogroup] = useState(false);
  const [deleteComfirmation, setDeleteComfirmation] = useState(false);
  const [deleteComfirmationPending, setDeleteComfirmationPending] = useState(false);
  const [resetNormal, setResetNormal] = useState(false);
  const [banComfirmation, setBanComfirmation] = useState(false);
  const [userEmail, setUserEmail] = useState()
  const userId = useSelector(selectUserId)
  const instituteId = useSelector(selectInstitute)
  const [contentHeaderTitle, setContentHeaderTitle] = useState<string>()
  const fetchlistofusers = async () => {
    setIsUsers(false)
    const data = await GetUsers.getuserlist(instituteId);
    if (data) {
      setMembersList(data);
      setIsUsers(true)
    }
    else {
      setMembersList([]);
      setIsUsers(false)
    }
  }
  const fetchlistofactiveusers = async () => {
    setIsUsers(false)
    const data = await GetUsers.getuserlistactive(instituteId);
    if (data) {
      setMembersList(data);
      setIsUsers(true)
    }
    else {
      setMembersList([]);
      setIsUsers(false)
    }
  }
  const fetchlistofpendingusers = async (instid) => {
    setIsUsers(false)
    const data = await GetUsers.getuserlistpending(instituteId);
    if (data) {
      setMembersList(data);
      setIsUsers(true)
    }
    else {
      setMembersList([]);
      setIsUsers(false)
    }
  }
  const fetchlistofbannedusers = async () => {
    setIsUsers(false)
    const data = await GetUsers.getuserlistbanned(instituteId);
    if (data) {
      setMembersList(data);
      setIsUsers(true)
    }
    else {
      setMembersList([]);
      setIsUsers(false)
    }
  }
  const fetchlistofdeactivateusers = async () => {
    setIsUsers(false)
    const data = await GetUsers.getuserlistdeactivate(instituteId);
    if (data) {
      setMembersList(data);
      setIsUsers(true)
    }
    else {
      setMembersList([]);
      setIsUsers(false)
    }
  }
  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
    else
      return;
  }
  const handleSearch = (e) => {
    const filteredData = membersList?.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? fetchlistofusers()
      : setMembersList(filteredData);
  };
  const exportPdf = async () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#usertable",
    });

    doc.save(`userlist.pdf`);
  };

  const openDelete = () => {
    setShowForm(false);
    setDeleteComfirmation(false);
    setBanComfirmation(false);
    setResetNormal(false);
    setAddTogroup(false);
  };
  const openPendingDelete = () => {
    setShowForm(false);
    setDeleteComfirmation(false);
    setBanComfirmation(false);
    setResetNormal(false);
    setAddTogroup(false);
    setDeleteComfirmationPending(false);
  };

  const handleAddToGroup = (email) => {
    setUserEmail(email)
    fetchSubGroupList();
    setAddTogroup(!addTogroup)
  }
  const fetchSubGroupList = async () => {
    let resource = await CreateGroupInstitute.getMainGroupList(instituteId);
    if (resource) {
      const options = resource.map((user) => ({
        id: user._id,
        name: user.groupname,
        description: user.description,
      }));
      setResult(options);
    }
  };
  const updateGroup = async (e) => {
    e.preventDefault();

    const updatedata = {
      subgroupId: subgroupId,
    }
    try {
      let data = await CreateGroupInstitute.updateUserGroup(userEmail ? userEmail : '', updatedata)
      if (!data.ok) {
        NotificationManager.success("Successfully Added", "Add to group");
        setAddTogroup(false);
      }
      else {
        NotificationManager.warning("User not Added", "Add to group")
        setAddTogroup(false);

      }
    } catch (error) {
      console.error('Error during add:', error);
      NotificationManager.warning("User not Added", "Add to group")
      setAddTogroup(false);
    }
  }
  const fetchGroupList = async (groupidforsearch) => {
    let resource = await CreateGroupInstitute.getGroupListSsub(groupidforsearch);
    if (resource) {
      const options = resource.map((detail) => ({
        id: detail._id,
        name: detail.name,
      }));
      setGroupResult(options);
    }
  };

  const handleResetNormal = (email) => {
    setUserEmail(email)
    setResetNormal(true);
  }
  const handleBanUser = (email) => {
    setUserEmail(email)
    setBanComfirmation(true);
  }
  const openResetNormal = (email) => {
    setUserEmail(email)
    setResetNormal(!resetNormal);
  }
  const fetchInstitutesList = async () => {
    let resource = await GetUsers.getinstitutelist();
    if (resource) {
      resource.forEach((user) => {
        if (user.user[0] === userId) {
          fetchUsers(user._id);
        }
      })
    }
  };
  const ResetAgain = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.resetAgain(userEmail ? userEmail : '');
      if (data) {
        setLoading(!loading);
        NotificationManager.success("Successful deleted", "User");
        fetchUsers(instituteId)
      }
      else {
        NotificationManager.warning("Not deleted", "User")
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "User")
    }
    setResetNormal(false);
  }
  const openBan = () => {
    setBanComfirmation(!banComfirmation);
  };
  const banInstitute = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.banUser(userEmail || '');
      if (data) {
        setLoading(!loading);
        NotificationManager.success("Successfull Banned", "user");
        fetchUsers(instituteId)
      }
      else {
        NotificationManager.warning("Not Banned", "user")
      }
    } catch (error) {
      NotificationManager.warning("Not Banned", "user")
    }
    setBanComfirmation(false);
  }
  const handleDeleteUser = (email) => {
    setUserEmail(email)
    setDeleteComfirmation(true);
  }
  const handleDeleteUserPending = (email) => {
    setUserEmail(email)
    setDeleteComfirmationPending(!deleteComfirmationPending);
  }
  const deleteInstitute = async () => {
    try {
      const data = await GetUsers.deleteUser(userEmail ? userEmail : '');
      if (data) {
        setLoading(!loading);
        NotificationManager.success("Successfull deleted", "User");
      }
      else {
        NotificationManager.warning("Not deleted", "User")
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "User", error)
    }
    setDeleteComfirmation(false);
  }
  const deletePendingUser = async () => {
    try {
      const data = await GetUsers.deleteUserPending(userEmail ? userEmail : '');
      if (data) {
        setLoading(!loading);
        NotificationManager.success("Successfull deleted", "User");
      }
      else {
        NotificationManager.warning("Not deleted", "User")
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "User", error)
    }
    setDeleteComfirmationPending(false);
  }
  const fetchUsers = (instId) => {

    if (status === 0) {
      setMembersList([]);
      fetchlistofactiveusers()
      setContentHeaderTitle('Active users');
    }
    else if (status === 1) {
      setMembersList([]);
      fetchlistofbannedusers()
      setContentHeaderTitle('Banned Users')
    }
    else if (status === 2) {
      setMembersList([]);
      fetchlistofdeactivateusers()
      setContentHeaderTitle('Deactivated Users')
    }
    else if (status === 3) {
      setMembersList([]);
      fetchlistofusers()
      setContentHeaderTitle('All Users')
    }
    else if (status === 5) {
      setMembersList([]);
      fetchlistofpendingusers(instituteId)
      setContentHeaderTitle('Pending Users')
    }
    else {
      setMembersList([]);
      fetchlistofusers()
      setContentHeaderTitle('All Users')
    }
  }

  useEffect(() => {
    fetchInstitutesList();
  }, [status, loading]);

  const UserTableActionComponent = (user) => {
    return (
      <div className="flex gap-2 justify-center items-center w-full">
        {user.id !== userId && <>
          {
            status === 2 ?
              <>
                <button onClick={
                  (e) => {
                    e.preventDefault()
                    handleBanUser(user.id)
                  }}
                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                  <ToolTip tooltip={t("ban user")}>
                    <FontAwesomeIcon icon={faUserSlash} color="orange" />
                  </ToolTip>
                </button>
                <button onClick={() => handleResetNormal(user.id)} className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                  <ToolTip tooltip={t("activate user")}>
                    <FontAwesomeIcon icon={faUserCheck} color="green" />
                  </ToolTip>
                </button>
              </>
              : ""

          }
          {
            status === 1 ?
              <>
                <button onClick={() => handleResetNormal(user.id)} className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                  <ToolTip tooltip={t("activate user")}>
                    <FontAwesomeIcon icon={faUserCheck} color="green" />
                  </ToolTip>
                </button>
                <button onClick={() => handleDeleteUser(user.id)} className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                  <ToolTip tooltip={t("delete")}>
                    <FontAwesomeIcon icon={faTrash} color="red" />
                  </ToolTip>
                </button>
              </>
              : ""

          }
          {
            status === 0 ?
              <>
                <button onClick={() => handleBanUser(user.id)} className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                  <ToolTip tooltip={t("ban user")}>
                    <FontAwesomeIcon icon={faUserSlash} color="orange" />
                  </ToolTip>
                </button>
                <button onClick={() => handleDeleteUser(user.id)} className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                  <ToolTip tooltip={t("delete")}>
                    <FontAwesomeIcon icon={faTrash} color="red" />
                  </ToolTip>
                </button>
              </>
              : ""

          }
          {
            status === 5 ?
              <button onClick={() => handleDeleteUserPending(user.id)} className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                <ToolTip tooltip={t("delete")}>
                  <FontAwesomeIcon icon={faUserSlash} color="red" />
                </ToolTip>
              </button>

              : ""

          }
          {
            status !== 5 ?
              <>
                <button onClick={() => handleAddToGroup(user.id)} className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                  <ToolTip tooltip={t("add to group")}>
                    <FontAwesomeIcon icon={faUsers} color="brown" />
                  </ToolTip>
                </button>
                <button className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                  <ToolTip tooltip={t("see detail")}>
                    <Link to={`/institute/userdetail/${user.id}`} className='hover:!text-greslernorange'>
                      <FontAwesomeIcon icon={faShareFromSquare} color="blue" />
                    </Link>
                  </ToolTip>
                </button>
              </>
              : ""
          }
        </>}

      </div>
    )
  }
  function formatUserData(data: any[]): IUserItem[] {

    return data.map(item => ({
      id: item._id,
      user: item.name,
      profilePhoto: item.profilephoto,
      country: item.country,
      date: formatDateToLocaleDateAndTimeString(item.createdAt),
      email: item.email,
      status: item.status == '0' ? 'active' : 'inactive',
      type: item.type
    }));

  }
  return (
    <>

      {/* Active users */}
      {
        <div className="">
          <ContentHeader title={t(contentHeaderTitle || " ")} showIcon="false" />
          <div className="">
            <div className="my-2 flex place-content-end sm:flex-row flex-col">
              {report &&
                <button
                  onClick={() => exportPdf()}
                  type="button"
                  className="relative mr-20 inline-block rounded border-1 border-primary-200 px-3 pb-[2px] text-xs text-primary-700 transition duration-150 ease-in-out"
                >
                  <FontAwesomeIcon icon={faDownload} className="w-5 h-5 mr-1"
                  />
                  {t("Download")}
                </button>
              }

            </div>
            {isUsers ?
              membersList.length > 0 ?
                <TableComponent data={formatUserData(membersList)} actionComponent={UserTableActionComponent} title=" users that are member of this institution " />
                : <NoRecordFound />
              : <ContentLoader />
            }
          </div>
        </div>
      }

      {
        addTogroup &&
        <ModalComponent isOpen={true} onClose={handleAddToGroup} title={t("Add user")}>
          <form
            onSubmit={updateGroup}
          >
            <div className="mb-1">
              <label
                htmlFor="country"
                className="block text-gray-700 text-sm font-semibold mb-2"
              >{t("Sub group")}
                <span className="text-red-600">*</span>
              </label>
              <select
                id="groupId"
                name="groupId"
                value={groupId}
                onChange={(e) => {
                  const selectedOptionValue = e.target.value;
                  setGroupId(selectedOptionValue)
                  fetchGroupList(selectedOptionValue);
                }}
                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
              >
                <option hidden>
                  {t("Select Main Group")}
                </option>
                {result.map((group) => (
                  <option
                    className="text-[#292929]"
                    key={`m${group.id}`}
                    value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-20">
              <label
                htmlFor="country"
                className="block text-white text-sm font-semibold mb-2"
              >{t("Sub group")}
                <span className="text-red-600">*</span>
              </label>
              <select
                id="subgroupId"
                name="subgroupId"
                value={subgroupId}
                onChange={(e) => {
                  const selectedOptionValue = e.target.value;
                  setSubGroupId(selectedOptionValue)
                }}
                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
              >
                <option hidden>
                  {t("Select Group")}
                </option>
                {groupResult.map((group) => (
                  <option
                    className="text-[#292929]"
                    key={group.id}
                    value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              className={
                `w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                ${loading
                  ? "bg-[#2d2d39] text-white pointer-events-none"
                  : "w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                }
              `}
              type="submit"
              disabled={loading}>
              {loading ? (
                <Spin color="#ffffff" width="30px" height="30px" duration="1s" />
              ) : (
                t("Add")
              )}
            </button>
          </form>
        </ModalComponent>
      }
      {
        deleteComfirmation &&
        <ModalComponent isOpen={true} onClose={openDelete} title={<p><FontAwesomeIcon icon={faWarning} className=" text-warning " />{t("Delete user")}</p>}>
          <label className="text-white">{t("Do you want to delete?")}</label>
          <button className="btn bg-green-200 mx-2 hover:bg-green-200/30 hover:text-white ring-1 ring-green-200" onClick={openDelete}>{t("Cancel")}</button>
          <button className="btn text-white bg-greslernorange mx-2 hover:bg-greslernorange/30 hover:text-white ring-1 ring-greslernorange" onClick={deleteInstitute}>{t("OK")}</button>
        </ModalComponent>
      }
      {
        deleteComfirmationPending &&
        <ModalComponent isOpen={true} onClose={openPendingDelete} title={t("Delete user")}>
          <label className="text-white">{t("Do you want to delete?")}</label>
          <button className="btn bg-green-200 mx-2 hover:bg-green-200/30 hover:text-white ring-1 ring-green-200" onClick={openDelete}>{t("Cancel")}</button>
          <button className="btn text-white bg-greslernorange mx-2 hover:bg-greslernorange/30 hover:text-white ring-1 ring-greslernorange" onClick={deletePendingUser}>{t("OK")}</button>
        </ModalComponent>
      }
      {
        banComfirmation &&
        <ModalComponent isOpen={true} onClose={openBan} title={t("Ban user")}>
          <label className="text-white">{t("Do you want to ban?")}</label>
          <button className="btn bg-green-200 mx-2 hover:bg-green-200/30 hover:text-white ring-1 ring-green-200" onClick={openBan}>{t("Cancel")}</button>
          <button className="btn text-white bg-greslernorange mx-2 hover:bg-greslernorange/30 hover:text-white ring-1 ring-greslernorange" onClick={banInstitute}>{t("OK")}</button>
        </ModalComponent>
      }
      {
        resetNormal &&
        <ModalComponent isOpen={true} onClose={openResetNormal} title={t("Change to Active")}>

          <label className="text-white">{t("Do you want to activate?")}</label>
          <button className="btn btn-default" onClick={openResetNormal}>{t("Cancel")}</button>
          <button className="btn btn-primary" onClick={ResetAgain}>{t("OK")}</button>
        </ModalComponent>
      }
    </>
  )
}
export default UsesTable;