import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({ animationData, width, height }) => {
    const animationContainer = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // Initialize the Lottie animation
        if (animationContainer.current) {
            // Initialize the Lottie animation
            const anim = lottie.loadAnimation({
                container: animationContainer.current,
                animationData, // The imported animation JSON
                renderer: 'svg', // You can choose 'svg', 'canvas', or 'html' based on your preference
                loop: true, // Set to true if you want the animation to loop
                autoplay: true, // Set to true if you want the animation to play automatically

            });
            animationContainer.current.style.width = width; // Set the width
            animationContainer.current.style.height = height; // Set the height
            // Optionally, you can control the animation using the 'anim' object
            // Example: anim.play(), anim.pause(), anim.setSpeed(2), etc.

            return () => {
                // Cleanup: stop and remove the animation when the component unmounts
                anim.destroy();
            };
        }
    }, []);

    return <div ref={animationContainer} />;
};

export default LottieAnimation;