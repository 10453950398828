import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SubscriberService from '../../../services/SubscriberService';

const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || email === "" || emailRegex.test(email)) {
      toast.error('Invalid Email Address.');
      return;
    }

    try {
      await SubscriberService.RegisterSubscriber(email)
      setEmail('')
    } catch (error) {
      setMessage('Something went wrong. Please try again.');
    }
  };

  return (
    <div className='flex flex-col'>
      <div className="relative  rounded-md shadow-sm">
        <form onSubmit={handleSubmit}>
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-greslernorange/70 sm:text-sm"><FontAwesomeIcon icon={faEnvelope} /></span>
          </div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="subscriptionEmail"
            id="subscriptionEmail"
            className=" !text-sm text-center block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={t("newsletter")}
            required
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            <button
              id="subscribe"
              name="subscribe"
              type='submit'
              className="h-full !rounded-l-none bg-greslernorange hover:bg-white rounded-md border-0 py-0 pl-2 pr-7 text-white hover:!text-greslernorange hover:ring-2 hover:ring-inset focus:ring-indigo-600 sm:text-sm"
            >
              {t("Subscribe")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionForm;
