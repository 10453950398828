import React from 'react'
import DefaultLandingPageLayout from '../DefaultLandingPageLayout'
import GreslernBrochure1 from '../../../components/assets/img/GreslernBrochure/en/Greslern-brochure_page-0001.jpg'
import GreslernBrochure2 from '../../../components/assets/img/GreslernBrochure/en/Greslern-brochure_page-0002.jpg'
import GreslernBrochure3 from '../../../components/assets/img/GreslernBrochure/en/Greslern-brochure_page-0003.jpg'
import GreslernBrochure4 from '../../../components/assets/img/GreslernBrochure/en/Greslern-brochure_page-0004.jpg'
import GreslernBrochure5 from '../../../components/assets/img/GreslernBrochure/en/Greslern-brochure_page-0005.jpg'
import GreslernBrochure6 from '../../../components/assets/img/GreslernBrochure/en/Greslern-brochure_page-0006.jpg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import NoRecordFound from '../../../components/NotRecoredFound'

const GreslernBrochure = ({language='en'}) => {
  return (
    <DefaultLandingPageLayout>

      {
        language == 'en' ?
          <div className='flex flex-col justify-center'>
            <div className=" text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto ">
                {/* <h1 id='privacy' className="mb-2 text-xl font-bold md:text-3xl text-greslernorange">WEBSITE GENERAL</h1> */}
                <h1 id='privacy' className=" whitespace-nowrap w-ful mb-2 text-2xl font-bold md:text-6xl text-center text-greslernorange">GreSLERN Brochure english <br /> </h1>
                {/* <p className="text-gray-700 ">Last updated: May 16, 2024 </p> */}
                <Link to={'https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/documents/pdf/Greslern-brochure.pdf'} target='_blank' download={true} className=' text-greslernorange text-sm hover:text-greslernorange/50 hover:underline' >download <FontAwesomeIcon icon={faFilePdf} /></Link>
            </div>
            {/* <div className='grow max-w-[10em] bg-greslernorange'>download</div> */}
            <div className='w-auto max-w-[65em] snap-mandatory snap-x mx-auto '>
              <div className='snap-center '>
                <img src={GreslernBrochure1} className='shadow-md shadow-greslernorange mb-2' alt='greslern brochure' />
              </div>
              <div className='snap-center'>
                <img src={GreslernBrochure2} className='shadow-md shadow-greslernorange mb-2' alt='greslern brochure' />
              </div>
              <div className='snap-center'>
                <img src={GreslernBrochure3} className='shadow-md shadow-greslernorange mb-2' alt='greslern brochure' />
              </div>
              <div className='snap-center'>
                <img src={GreslernBrochure4} className='shadow-md shadow-greslernorange mb-2' alt='greslern brochure' />
              </div>
              <div className='snap-center'>
                <img src={GreslernBrochure5} className='shadow-md shadow-greslernorange mb-2' alt='greslern brochure'/>
              </div>
              <div className='snap-center'>
                <img src={GreslernBrochure6} className='shadow-md shadow-greslernorange mb-2' alt='greslern brochure'/>
              </div>
            </div>
          </div>
        :language == 'fr' ?
          <div className='flex flex-col justify-center'>
            <div className=" text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto ">
                {/* <h1 id='privacy' className="mb-2 text-xl font-bold md:text-3xl text-greslernorange">WEBSITE GENERAL</h1> */}
                <h1 id='privacy' className=" whitespace-nowrap w-ful mb-2 text-2xl font-bold md:text-6xl text-center text-greslernorange">GreSLERN Brochure Français <br /> </h1>
                {/* <p className="text-gray-700 ">Last updated: May 16, 2024 </p> */}
                <Link to={'https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/documents/pdf/Greslern-brochure-fr.pdf'} target='_blank' download={true} className=' text-greslernorange text-sm hover:text-greslernorange/50 hover:underline' >download <FontAwesomeIcon icon={faFilePdf} /></Link>
            </div>
            {/* <div className='grow max-w-[10em] bg-greslernorange'>download</div> */}
            <div className='w-auto max-w-[65em] snap-mandatory snap-x mx-auto '>

              <div className='bg-greslernorange'>
                <NoRecordFound />
              </div>
            </div>
          </div>
        :language == 'ar' ?
          <div className='flex flex-col justify-center'>
            <div className=" text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto ">
                {/* <h1 id='privacy' className="mb-2 text-xl font-bold md:text-3xl text-greslernorange">WEBSITE GENERAL</h1> */}
                <h1 id='privacy' className=" whitespace-nowrap w-ful mb-2 text-2xl font-bold md:text-6xl text-center text-greslernorange">GreSLERN Brochure العربية<br /> </h1>
                {/* <p className="text-gray-700 ">Last updated: May 16, 2024 </p> */}
                <Link to={'https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/documents/pdf/Greslern-brochure.pdf-ar'} target='_blank' download={true} className=' text-greslernorange text-sm hover:text-greslernorange/50 hover:underline' >download <FontAwesomeIcon icon={faFilePdf} /></Link>
            </div>
            {/* <div className='grow max-w-[10em] bg-greslernorange'>download</div> */}
            <div className='w-auto max-w-[65em] snap-mandatory snap-x mx-auto '>
              <div className='bg-greslernorange'>
                <NoRecordFound />
              </div>
            </div>
          </div>
        :language == 'es' ?
          <div className='flex flex-col justify-center'>
            <div className=" text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto ">
                {/* <h1 id='privacy' className="mb-2 text-xl font-bold md:text-3xl text-greslernorange">WEBSITE GENERAL</h1> */}
                <h1 id='privacy' className=" whitespace-nowrap w-ful mb-2 text-2xl font-bold md:text-6xl text-center text-greslernorange">GreSLERN Brochure Español<br /> </h1>
                {/* <p className="text-gray-700 ">Last updated: May 16, 2024 </p> */}
                <Link to={'https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/documents/pdf/Greslern-brochure-es.pdf'} target='_blank' download={true} className=' text-greslernorange text-sm hover:text-greslernorange/50 hover:underline' >download <FontAwesomeIcon icon={faFilePdf} /></Link>
            </div>
            {/* <div className='grow max-w-[10em] bg-greslernorange'>download</div> */}
            <div className='w-auto max-w-[65em] snap-mandatory snap-x mx-auto '>
              <div className='bg-greslernorange'>
                <NoRecordFound />
              </div>
            </div>
          </div>
        : undefined
      }

    </DefaultLandingPageLayout>
  )
}

export default GreslernBrochure