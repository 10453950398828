import React, { useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faFacebook, faYoutube, faLinkedin, faThreads } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import world_map from '../../../components/assets/img/footer/world_map.png'
import OSC_watermark from '../../../components/assets/img/footer/OEC-LOGO-watermark.png'
import logo_white from '../../../components/assets/img/footer/greslern-white-logo.png'
import osc_log from '../../../components/assets/img/footer/osc-ocs-logo.png'
import SectionWrapper from '../../../hoc';
import ModalComponent from '../../../components/ModalComponent';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SubscriptionForm from '../../../components/form/SubscriptionForm';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

function Footer() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [playWalkThrough, setPlayWalkThrough] = useState(false)

    const handleRoute = () => {
        navigate('/comingsoon')
    }
    const handleRouteToGuide = () => {
        window.open('/landingPageUserGuide', '_blank', 'rel=noopener noreferrer')
    }

    const handlePlayWalkThrough = () => {
        setPlayWalkThrough(!playWalkThrough)
    }

    const links = [
        { href: '/GreslernBrochure', label: 'english' },
        { href: '/GreslernBrochure/fr', label: 'Français' },
        { href: '/GreslernBrochure/ar', label: 'العربية' },
        { href: '/GreslernBrochure/es', label: 'Español' },
    ]

    return (
        <div className="bg-[#1D5391] sm:h-auto md:h-[22.75rem] w-full"
            style={{
                backgroundImage: `url(${world_map}), url(${OSC_watermark})`,
                backgroundRepeat: 'no-repeat, no-repeat',
                backgroundPosition: 'left bottom, right',
                boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.5)'
            }}
        >
            <div className="grid grid-cols-12 flex justify-between items-center h-full max-w-screen-xl mx-auto w-full">
                <div className='border-r-0 md:border-r-2 col-span-12 md:col-span-4 px-14 flex flex-col justify-center items-center'>
                    <img className='py-3' alt="greslern-logo" src={logo_white} />
                    <img className='py-3' alt='osc-logo ' src={osc_log} />
                    <p className='text-white text-center'>{t("footerLocation")}</p>
                </div>
                <div className='col-span-12 md:col-span-8 px-20 flex flex-col justify-between h-full py-16'>
                    <div>
                        <ul className='flex flex-col md:flex-row justify-center items-center text-white'>
                            <li className='px-3 border-white/30 md:border-r-2 md:border-b-0 border-b-2 mt-2 cursor-pointer hover:text-greslernorange'><a href='#about'>{t("About")} GreSLERN </a></li>
                            <li className='px-3 md:border-r-2 md:border-b-0 border-b-2 md:mt-0 mt-2 cursor-pointer hover:text-greslernorange' ><a href='https://osc-ocs.org/' className='hover:text-greslernorange' target="_blank" rel="noopener noreferrer">{t("AbouttheOSC")}</a></li>
                            <li className='px-3 border-white/30 md:border-r-2 md:border-b-0 border-b-2 md:mt-0 mt-2 cursor-pointer hover:text-greslernorange' onClick={() => navigate('/login')}>{t("Getinvolved")}</li>
                            <li className='px-3 border-white/30 md:border-r-2 md:border-b-0 border-b-2 md:mt-0 mt-2 cursor-pointer hover:text-greslernorange' onClick={handleRouteToGuide}>{t("User Guide")}</li>
                            <li className='px-3 border-white/30 md:border-b-0 border-b-2 mt-2 cursor-pointer hover:text-greslernorange' onClick={() => navigate('/contactus')}>{t("Contactus")}</li>
                        </ul>
                        <ul className='flex flex-col md:flex-row justify-center items-center text-white'>
                            {/* <li className='px-3 md:border-r-2 md:border-b-0 border-b-2 mt-2 cursor-pointer hover:text-greslernorange'><a href='#about'>{t("About")} GreSLERN </a></li> */}
                            {/* <li className='px-3 border-white/30 md:border-r-2 md:border-b-0 border-b-2 md:mt-0 mt-2 cursor-pointer hover:text-greslernorange'>
                                <Link to={'/privacy'} reloadDocument={true} className=' hover:!text-greslernorange'>{t("Privacy Policy")}</Link>
                            </li> */}
                            <li className='hover:!text-greslernorange cursor-pointer ' >
                                <Menu as="div" className="relative inline-block  px-3 border-white/30 md:border-r-2 md:border-b-0 border-b-2 md:mt-0 mt-2 '">
                                    <Menu.Button className=""> {t("GreSLERN Brochure")}  </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items className=" absolute right-0 z-10 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                            {links.map((link) => (
                                                <Menu.Item key={link.href} as={Fragment}>
                                                    <a className="text-greslernorange hover:bg-greslernorange hover:text-white block w-full px-4 py-2 text-left text-sm capitalize"
                                                        href={link.href}>
                                                        {link.label}
                                                    </a>

                                                </Menu.Item>
                                            ))}

                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </li>
                            <li className='px-3 border-white/30 md:border-r-2 md:border-b-0 border-b-2 md:mt-0 mt-2 cursor-pointer hover:text-greslernorange' onClick={handlePlayWalkThrough} >{t("Walk Through")}</li>
                            <li className='px-3 border-white/30 md:border-b-0 border-b-2 mt-2 cursor-pointer'>
                                <Link to={'/terms'} reloadDocument={true} className=' hover:!text-greslernorange'>{t("Terms & Conditions")}</Link>
                            </li>
                            {/* <li className='px-3 md:border-r-2 md:border-b-0 border-b-2 md:mt-0 mt-2 cursor-pointer hover:text-greslernorange' onClick={() => navigate('/login')}>{t("Getinvolved")}</li> */}
                            {/* <li className='px-3 md:border-r-2 md:border-b-0 border-b-2 md:mt-0 mt-2 cursor-pointer hover:text-greslernorange' onClick={handleRouteToGuide}>{t("User Guide")}</li> */}
                            {/* <li className='px-3 md:border-b-0 border-b-2 mt-2 cursor-pointer hover:text-greslernorange' onClick={() => navigate('/contactus')}>{t("Contactus")}</li> */}
                        </ul>
                    </div>
                    <div className='flex md:flex-row flex-col md:mt-0 mt-4 justify-between items-center text-white'>
                        <div>
                            <a className='px-2 hover:text-greslernorange' href='https://www.facebook.com/osc.ocs.org' target='_blank'> <FontAwesomeIcon className=' text-2xl' icon={faFacebook} /></a>
                            <a className='px-2 hover:text-greslernorange' href='https://twitter.com/OSC_OCS' target='_blank'><FontAwesomeIcon className='px-2 text-2xl' icon={faXTwitter} /></a>
                            <a className='px-2 hover:text-greslernorange' href='https://www.youtube.com/@OSC-OCS' target='_blank'><FontAwesomeIcon className='px-2 text-2xl' icon={faYoutube} /></a>
                            <a className='px-2 hover:text-greslernorange' href='https://www.linkedin.com/company/osc-ocs/' target='_blank'><FontAwesomeIcon className='px-2 text-2xl' icon={faLinkedin} /></a>
                            <a className='px-2 hover:text-greslernorange' href='https://www.threads.net/@osc.ocs' target='_blank'><FontAwesomeIcon className='px-2 text-2xl' icon={faThreads} /></a>
                        </div>
                        {/* <div className='flex justify-center items-center text-white md:mt-0 mt-4'>
                            <input type='email' className=' py-1 px-2 border-r-0 rounded-r-0 rounded border-2 border-[#007BFF] ' placeholder={t("newsletter")}></input>
                            <button onClick={handleRoute} className='bg-greslernorange py-1 px-2 border-l-0 rounded-l-none rounded border-2 border-[#007BFF] hover:bg-transparent hover:border-white'>{t("Subscribe")}</button>
                        </div> */}
                        <SubscriptionForm />

                    </div>
                    <p className='text-white md:mt-0 mt-4'>{t("copyright")}</p>
                </div>
            </div>
            <ModalComponent isOpen={playWalkThrough} onClose={handlePlayWalkThrough} title={t("GreSLERN Walk Through video")}>
                <video className=" h-auto w-auto rounded-lg" controls>
                    <source src="https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/videos/the-greater-south-learning%20enrichment-resource-nexus.mp4" type="video/mp4" />
                    {t("Your browser does not support the video tag.")}
                </video>
            </ModalComponent>
        </div>
    )
}

export default SectionWrapper(Footer, "footer")