import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CardContainer from "./CardContainer";
import { Link } from "react-router-dom";

const UsersStatisticsCard = ({ users = [] }) => {
  const { t } = useTranslation();
  const [userStat, setUserStat] = useState()

  // Use the reduce method to count the items based on type
  const getStat = (array) => {
    const count = array.reduce((acc, item) => {
      const type = item.type;
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});
    return count
  }
  useEffect(() => {
    setUserStat(getStat(users))
  }, [users])

  const getIcon = () => {
    return <FontAwesomeIcon
      icon={faUsers}
      className=" w-1/2 h-1/2  rounded-full m-1 text-white"
    />
  }

  const getStatistics = () => {
    return <>
      <div className="flex flex-col items-center justify-center">
        <p className="text-2xl font-bold text-white ">
          {userStat?.teacher ? userStat?.teacher : "0"}
        </p>
        <p className="text-sm font-normal text-white/50">Teachers</p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <p className="text-2xl font-bold text-white ">
          {userStat?.admin ? userStat.admin : "0"}
        </p>
        <p className="text-sm font-normal text-white/50">Admins</p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <p className="text-2xl font-bold text-white ">
          {userStat?.student ? userStat.student : "0"}
        </p>
        <p className="text-sm font-normal text-white/50">{t("Students")}</p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <p className="text-2xl font-bold text-white ">
          {users?.length ? users.length : 0}
        </p>
        <p className="text-sm font-normal text-white/50">{t("total")}</p>
      </div>
    </>
  }


  return (
    <Link to="/admin/users">
      <CardContainer icon={getIcon()} title="Users" subTitle="Users Statistics" statistics={getStatistics()} iconBgColor='bg-yellow-600' shadowColor="shadow-yellow-600" />
    </Link>
  );
};

export default UsersStatisticsCard;
