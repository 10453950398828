import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SubscribersGraphComponent from '../SubscribersGraphComponent'
import { t } from 'i18next'
import TableComponent from '../TableComponent'
import SubscriberService from '../../services/SubscriberService'
import { formatDateToLocaleDateAndTimeString } from '../../utils/functions'
import NoRecordFound from '../NotRecoredFound'
interface ISubscribersItem {

}
const SubscribersComponent = props => {

    const [showTable, setShowTable] = useState(false)
    const [data, setData] = useState([])

    const handleShowTable = async () => {
        const data = await SubscriberService.GetAllSubscribers()
        setData(data)
        setShowTable(true)
    }
    const handleShowGraph = () => {
        setShowTable(false)
    }
    // {
    //     "0": {
    //         "_id": "66766a0729dcba1c8bbabee3",
    //         "subscriberEmail": "tesfasebsibacew@gmail.com",
    //         "subscriberRegistered": true,
    //         "confirmationToken": null,
    //         "createdAt": "2024-06-22T06:07:03.365Z",
    //         "updatedAt": "2024-06-22T06:35:30.647Z",
    //         "__v": 0
    //     }
    // }
    function formatContentData(data: any[]): ISubscribersItem[] {
        return data.map(item => ({
          id: item._id,
          user: item.subscriberEmail,
          isActive: item.subscriberRegistered ? 'active' : 'inactive',
          date: formatDateToLocaleDateAndTimeString(item.createdAt)
        }));
      }
  return (
    <div className='w-full'>
        <div className='flex flex-row-reverse p-2'>
            <button onClick={showTable ? handleShowGraph : handleShowTable} className='p-2 rounded-2xl bg-white text-greslernorange hover:text-white hover:!bg-greslernorange'>
                {showTable ? t("subscribers graph") : t("subscribers table")}
                </button>
        </div>
       { showTable ?
        data ? <TableComponent data={formatContentData(data)} setting={{ searchable:false, rowPerPage:5, tableAction:false }} />:<NoRecordFound />
        :
        <SubscribersGraphComponent />
       } 

    </div>
  )
}

SubscribersComponent.propTypes = {}

export default SubscribersComponent