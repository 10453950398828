import api from "../../apiConfig"; // Import your Axios instance
import { Apis } from "../../config";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getNotificationSettings = async (email) => {
  try {
    const response = await api.get(Apis.getNotificationSetting, {
      params: {
        email, // Pass the email as a query parameter if required
      },
    });

    return response.data;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getLikes = async (contentId: any) => {
  try {
    let result = await api.get(Apis.getLikes + contentId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }

};
const getTagsList = async (contentId: any) => {
  try {
    let result = await api.get(Apis.GetTagsList + contentId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }

};
const getViews = async (contentId: any) => {
  try {
    let result = await api.get(Apis.getViews + contentId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getBookmarkedCounts = async (contentId: any) => {
  try {
    let result = await api.get(Apis.getBookmarked + contentId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const updateViews = async (contentId: any) => {
  try {
    let response = await api.patch(Apis.IncrementViews + contentId)
    return response.data;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const updateDownloadsCount = async (contentId: any) => {
  try {
    let response = await api.patch(Apis.IncrementDownloads + contentId)
    return response.data;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};

const getTotalDownloads = async (contentId: any) => {
  try {
    let result = await api.get(Apis.GetDownloads + contentId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }

};

const getcommentcount = async (resourceId: any) => {
  try {
    let result = await api.get(Apis.CommentCounter + resourceId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (e) {
    console.log(e)
    return null;
  }
};
const saveNotificationSettings = async (email, updatedSettings) => {
  try {
    const response = await api.post(Apis.saveNotificationSetting, {
      email, // Include email in the query parameters
      updatedSettings, // Include updatedSettings in the query parameters
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Server returned an error status:', response.status);
      // Handle the error, e.g., show a message to the user
      return null
    }
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};

const listNotifications = async (email) => {
  try {
    const response = await api.get(Apis.ListNotifications + email);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Unexpected status code:', response.status);
      return null;
    }
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};


const clearNotification = async (type = 'all') => {
  try {
    if (type == 'all') {
      const response = await api.get(Apis.ClearNotification)

      if (response.status === 200) {
        return response.data;
      } else {
        return null
      }
    }
  }
  catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
}


const markReadNotification = async (type = 'all') => {
  try {
    if (type == 'all') {
      const response = await api.get(Apis.MarkReadNotification)

      if (response.status === 200) {
        return response.data;
      } else {
        return null
      }
    }
  }
  catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
}

const updateSeenNotification = async (Id: string, type: string, notificationId: string = ''): Promise<any> => {
  try {
    const response = await api.get(Apis.getUpdateSeen + type + "/" + Id);
    if (response.status === 200) {
      notificationId !== '' && await api.patch(Apis.Notification + "/seen/" + notificationId)
      return response.data;
    } else {
      throw new Error('Failed to update seen notification');
    }
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};

export default {
  getNotificationSettings,
  saveNotificationSettings,
  getLikes,
  updateViews,
  getViews,
  getTagsList,
  updateDownloadsCount,
  getTotalDownloads,
  listNotifications,
  getBookmarkedCounts,
  clearNotification,
  markReadNotification,
  updateSeenNotification
};
