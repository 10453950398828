import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getcommentbyresource = async (resourceId: any) => {
    try {
        let result = await api.get(Apis.GetCommentbyResource + resourceId)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

export default {
    getcommentbyresource
}