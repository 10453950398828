import { Apis } from "../../config";
import api from "../../apiConfig";
import axios, { AxiosError } from 'axios';
import { NotificationManager } from "react-notifications";

import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getusersReport = async (duration: string) => {
  try {
    let response = await api.get(Apis.GetInstituteUsersReport + duration)
    if (response.status == 200) {
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Generate")
        throw error
      } else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const updateInstituteLoged = async () => {
  try {
    let result = await api.patch(Apis.UpdateInstituteLogged)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
export default {
  getusersReport,
  updateInstituteLoged
}