import { Apis } from "../../config";
import api, { axiosFile } from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getuserlist = async (instituteId: string) => {
  try {
    let result = await api.get(Apis.GetUsers + instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getuserlistAll = async () => {
  try {
    let result = await api.get(Apis.GetUsersAll)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (e) {
    console.log(e)
    return null;
  }
};
const getuserlistactive = async (instituteId: string) => {
  try {
    let result = await api.get(Apis.GetUsersActive + instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getuserlistpending = async (instituteId: any) => {
  try {
    let result = await api.get(Apis.GetUsersPending + instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getuserlistbanned = async (instituteId: string) => {
  try {
    let result = await api.get(Apis.GetUsersBanned + instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getuserlistdeactivate = async (instituteId: any) => {
  try {
    let result = await api.get(Apis.GetUsersDeactivate + instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getmembersList = async (instituteId: any) => {
  try {
    let result = await api.get(Apis.GetMembersList + instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getinvitedindividualList = async () => {
  try {
    let result = await api.get(Apis.GetInvitedIndividualsList)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};

const getinstitutelist = async () => {
  try {
    let result = await api.get(Apis.GetInstitutes)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getinvitationsentlist = async () => {
  try {
    let result = await api.get(Apis.GetinvitationsentList)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
// Client-side code (e.g., React component)

const shareResource = async (resourceId, sharedTo) => {
  try {
    const response = await api.patch(Apis.ResourceSharedTo, {
      resourceId: resourceId,
      sharedTo: sharedTo,
    });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const tagResource = async (resourceId, taggedTo) => {
  try {
    const response = await api.patch(Apis.ResourceTaggedTo, {
      resourceId: resourceId,
      taggedTo: taggedTo,
    });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const bookmarkResource = async (email, resourceId) => {
  try {
    const response = await api.put('/createBookmarks/', {
      email: email,
      resourceId: resourceId,
    });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const remooveBookmark = async (email, resourceId) => {
  try {
    const response = await api.put('/removeBookmark/', {
      email: email,
      resourceId: resourceId,
    });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getBookmarkStatus = async (email, resourceId) => {
  try {
    const response = await api.put('/bookMarkStatus/', {
      email: email,
      resourceId: resourceId,
    });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getUserLikeStatus = async (username, resourceId) => {
  try {
    const response = await api.put('/isUserLikedResource/', {
      username: username,
      resourceId: resourceId,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }

};

const getuserdata = async (useremail: string) => {
  try {
    let result = await api.get(Apis.GetUserData + useremail)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getuserdataById = async (useremail: string) => {
  try {
    let result = await api.get(Apis.GetUserDataI + useremail)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getuserdataforinst = async (id: string) => {
  try {
    let result = await api.get(Apis.GetUserDataInst + id)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};

const getinstitutedetail = async (useremail: string) => {
  try {
    let result = await api.get(Apis.GetinstituteDetail + useremail)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getinstitutedetailRegistration = async (useremail: string) => {
  try {
    let result = await api.get(Apis.GetinstituteDetailNew + useremail)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getinstitutesingledetail = async (useremail: string) => {
  try {
    let result = await api.get(Apis.GetinstituteDetail + useremail)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const userdataupdate = async (email: string, formData: any) => {
  try {
    let result = await api.patch(Apis.GetUserDataUpdate + email, formData)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const institutedataupdate = async (instituteId: string, updatedata: any) => {
  try {
    let result = await api.patch(Apis.GetInstituteDataUpdate + instituteId, updatedata)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const institutedataupdateContact = async (email: string, updatedata: any) => {
  try {
    let result = await api.patch(Apis.GetInstituteContact + email, updatedata)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (e) {
    return null;
  }
};
const updateinstitutebrand = async (instituteId: string, updatedata: any) => {
  try {
    let result = await axiosFile.patch(Apis.GetInstituteBrandUpdate + instituteId, updatedata)
    // let result = await axiosFile.patch(Apis.GetInstituteBrandUpdate, updatedata)

    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const updateuserpassword = async (passwordData: any) => {
  try {
    let result = await api.patch(Apis.UpdateUserPassword, passwordData)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const updateinstitutepassword = async (passwordData: any) => {
  try {
    let result = await api.patch(Apis.UpdateInstitutePassword, passwordData)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const removeProfilePicture = async (useremail: string) => {
  try {
    let result = await api.patch(Apis.RemoveProfilePicture + useremail)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const updateprofilePicture = async (useremail: string, formData: any) => {
  try {
    let result = await axiosFile.patch(Apis.UpdateProfilePicture + useremail, formData)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const deleteUser = async (email: string) => {
  try {
    let result = await api.patch(Apis.DeleteUser + email);
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const deleteUserPending = async (email: string) => {
  try {
    let result = await api.patch(Apis.DeleteUserPending + email);
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const deleteInstitute = async (email: string) => {
  try {
    let result = await api.patch(Apis.DeleteInstitute + email);
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const resetAgain = async (email: string) => {
  try {
    let result = await api.patch(Apis.ResetAgain + email);
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const resetAgainInstitute = async (email: string) => {
  try {
    let result = await api.patch(Apis.ResetAgainInstitute + email);
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const deleteInvitedInstitute = async (email: string) => {
  try {
    let result = await api.patch(Apis.DeleteInvitedInstitute + email);
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};

const banUser = async (email: string) => {
  try {
    let result = await api.patch(Apis.BanUser + email);
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const baninstitute = async (email: string) => {
  try {
    let result = await api.patch(Apis.BanInstitute + email);
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getInvitedUserbyEmail = async (useremail: string) => {
  try {
    let result = await api.get(Apis.GetInvitedUserbyEmail + useremail)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getindividualsinInst = async (email: string) => {
  try {
    let result = await api.get(Apis.GetIndividualsForInst + email)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getInvitedUserbyToken = async (token: string) => {
  try {
    let result = await api.get(Apis.GetInvitedUserbyToken + token)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getUserbyemailPublic = async (email: string) => {
  try {
    let result = await api.get(Apis.GetUserbyEmailPublic + email)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getInstitutebyNamePublic = async (name: string) => {
  try {
    let result = await api.get(Apis.GetInstitutebyNamePublic + name)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const removeExpireTokenInvited = async () => {
  try {
    let result = await api.get(Apis.GetinvitationExpiredList)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getinvitationsentExpired = async () => {
  try {
    let result = await api.get(Apis.GetinvitationExpired)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
// const getuserlistAll = async () => {
//   try {
//     let result = await api.get(Apis.GetUsersAll)
//     if (result.status !== 200) {
//       return null;
//     }
//     return result.data
//   } catch (e) {
//     console.log(e)
//     return null;
//   }
// };
export default {
  getuserlistAll,
  getuserlist,
  shareResource,
  getuserdata,
  userdataupdate,
  removeProfilePicture,
  updateuserpassword,
  tagResource,
  deleteUser,
  updateprofilePicture,
  bookmarkResource,
  remooveBookmark,
  getBookmarkStatus,
  getUserLikeStatus,
  getinstitutelist,
  getinvitationsentlist,
  banUser,
  deleteInstitute,
  getmembersList,
  getinvitedindividualList,
  institutedataupdate,
  institutedataupdateContact,
  getInvitedUserbyEmail,
  getInvitedUserbyToken,
  removeExpireTokenInvited,
  deleteInvitedInstitute,
  getinstitutedetail,
  baninstitute,
  getinstitutesingledetail,
  updateinstitutepassword,
  resetAgain,
  resetAgainInstitute,
  getinstitutedetailRegistration,
  getindividualsinInst,
  getinvitationsentExpired,
  updateinstitutebrand,
  getuserlistactive,
  getuserlistpending,
  getuserlistdeactivate,
  getuserlistbanned,
  getuserdataforinst,
  deleteUserPending,
  getUserbyemailPublic,
  getInstitutebyNamePublic,
  getuserdataById

}