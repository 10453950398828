import React, { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import NoData from '../../NoData';
import NoRecordFound from '../../../../NotRecoredFound';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export function ResourceUsage({ resourceList }) {
    let xValues = [""];
    const [numberOfviews, setNumberOfviews] = useState([1]);
    const [numberOfcomments, setNumberOfcomments] = useState([1]);
    const [numberOflikes, setNumberOflikes] = useState([1]);
    if (resourceList) {
        let resourceListArray = resourceList.sort((a, b) => b.createdAt - a.createdAt).slice(0, 6);
        resourceListArray.map((item, i) =>
        (
            xValues.push(item.title)
        ));
        resourceListArray.map((item, i) =>
        (
            numberOfviews.push(item.views)
        ));
        resourceListArray.map((item, i) =>
        (
            numberOfcomments.push(item.comments?.length)
        ));
        resourceListArray.map((item, i) =>
        (
            numberOflikes.push(item.likes?.length)
        ));
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Analisis for resource usage',
            },
        },
    };
    const labels = xValues;
    const data = {
        labels,
        datasets: [
            {
                label: 'Views',
                data: numberOfviews,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Comments',
                data: numberOfcomments,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Likes',
                data: numberOflikes,
                borderColor: 'rgb(53, 120, 152)',
                backgroundColor: 'rgba(53, 120, 152, 0.5)',
            },
        ],
    };

    return <>{resourceList.length <= 0 ? <NoRecordFound /> : <Line options={options} data={data} />}</>
}
