import { Fragment, useEffect, useState } from "react";
import { AnnouncementService } from "../../services";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ContentHeader from "../root/dashboard/addon/contentHeader";
import { NotificationManager } from "react-notifications";
import { Tab } from '@headlessui/react'
import SubsctiptionAnnouncement from "../form/SubscriptionAnnouncement";

type announcementprops = {
  title: string;
  link: string;
  message: string;
  createdAt: Date;
  _id: string;
};
const BulkAnnouncement = () => {
  const [announcementList, setAnnouncementList] = useState<announcementprops[]>([]);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [currentPagei, setCurrentPagei] = useState(1);
  const recordsPerPagei = 10;
  const lastIndexi = currentPagei * recordsPerPagei;
  const firstIndexi = lastIndexi - recordsPerPagei;
  const recordsi = announcementList.slice(firstIndexi, lastIndexi);
  const npagei = Math.ceil(announcementList.length / recordsPerPagei);
  const { t } = useTranslation();
  const numbersi = [...Array(npagei + 1).keys()].slice(1);
  function prePagei() {
    if (currentPagei !== firstIndexi) {
      setCurrentPagei(currentPagei - 1);
    }
  }
  function changeCPagei(id) {
    setCurrentPagei(id);
  }
  function nextPagei() {
    if (currentPagei !== lastIndexi) {
      setCurrentPagei(currentPagei + 1);
    }
    else
      return;
  }
  const fetchAnnaouncementList = async () => {
    let resource = await AnnouncementService.getAllAnnouncement();
    if (resource) {
      setAnnouncementList(resource);
    }
  };
  const handleSearch = (e) => {
    const filteredData = announcementList?.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? fetchAnnaouncementList()
      : setAnnouncementList(filteredData);
  };
  const handleAddAnnaouncement = async () => {
    try {
      if (!title) {
        NotificationManager.warning("All filed Required", "Announcement");
        return;
      }
      const announcementData = { title, message }
      let data = await AnnouncementService.addAnnouncement(announcementData);
      if (data) {
        NotificationManager.success("Sent", "Announcement");
      }
      else {
        setTitle('');
        setMessage('');
      }

    } catch (error) {
      NotificationManager.error("You can't Sent, Try Again", "Announcement");
    }
  };
  useEffect(() => {
    fetchAnnaouncementList();
  }, [])
  return (
    <div className="">
      <ContentHeader title={t("Announcement")} showIcon={false} />
      <Tab.Group vertical={true} >
        <Tab.List className='  mt-2 rounded-md'>
          <Tab as={Fragment}  >
            {({ selected }) => (
              <button className={`${selected ? ' !text-white bg-greslernorange ' : ''} capitalize font-bold rounded-t-md border-2 border-b-0 border-greslernorange p-1 px-3 mr-2 hover:text-greslernorange underline-offset-8 text-greslernorange/60`}>
                {t("institution")}
              </button>
            )}
          </Tab>
          <Tab as={Fragment}  >
            {({ selected }) => (
              <button className={`${selected ? ' !text-white bg-greslernorange ' : ''} capitalize font-bold rounded-t-md border-2 border-b-0 border-greslernorange p-1 px-3  hover:text-greslernorange underline-offset-8 text-greslernorange/60`}>
                {t("subscription")}
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="">
              <div className="mx-auto p-8 bg-greslernorange rounded-md shadow-md form-container">
                <h2 className="text-2xl font-semibold text-white mb-6">{t("Bulk announcemet Institute!")} </h2>
                <form>
                  <div className="flex flex-row space-x-5">
                    <div className="mb-4 w-full">
                      <label className="block text-gray-100 text-sm font-bold mb-2">{t("title")}</label>
                      <input
                        type="text" id="name" name="title"
                        placeholder="Enter title"
                        required
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 bg-gray-400 text-white"
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-300 text-sm font-bold mb-2">{t("Message")}</label>
                    <textarea id="message" name="message" rows={4}
                      placeholder="Type your message"
                      required
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 bg-gray-400 text-white"></textarea>
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddAnnaouncement()
                    }}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue">
                    {t("Boost")}
                  </button>
                </form>
              </div>
              <div className="my-2 flex place-content-end sm:flex-row flex-col">
                <div className="block relative">
                  <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                      <path d="M10 4a6 6 0 300 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
                    </svg>
                  </span>
                  <input onChange={handleSearch} placeholder={t("Search")} className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-1 w-full bg-white text-sm placeholder-gray-400 text-gray-300 focus:bg-white focus:placeholder-gray-600 focus:text-gray-300 focus:outline-none" />
                </div>
              </div>
              <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                  <table className="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-300 text-center text-xs font-semibold text-gray-600 uppercase tracking-wide">
                          {t("#No")}
                        </th>
                        <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                          {t("Title")}
                        </th>
                        <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                          {t("message")}
                        </th>
                        <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                          {t("RDate")}
                        </th>
                        <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                          {t("Status")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordsi?.map((users: any, i) =>
                        <tr
                          key={users._id}
                          className=""
                        // key={`adminUsertable${users.email}`}
                        >
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-center font-bold">
                            {i + 1}
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm font-semibold">
                            {users.title}
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            {users.message}
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            {moment(users.createdAt).format('LL')}
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-danger">
                            {t("Sent")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <nav className="items-center">
                    <ul className="list-style-none flex ml-12">
                      <li className="page-item">
                        <button className="pointer-events-none relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300" onClick={prePagei}>
                          {t("Prev")}
                        </button>
                      </li>
                      {numbersi.map((n, i) =>
                        <li
                          className={`${currentPagei === n
                            ? "whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                            : "page-item"}`}
                          key={`adminUsertable${n.toString()}`}
                        >
                          <button
                            className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-300"
                            onClick={() => changeCPagei(n)}
                          >
                            {n}
                          </button>
                        </li>
                      )}
                      <li>
                        <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-300" onClick={nextPagei}>
                          {t("Next")}
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <SubsctiptionAnnouncement />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

    </div>

  );
}
export default BulkAnnouncement;