import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentService } from "../../../../services";
import ContentCard from "../../../ContentFile/ContentCard";
import NotFound from "../../../NotFound";
import ContentHeader from "../addon/contentHeader";
import ToolTip from "../../../tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashArrowUp, faTrashCanArrowUp } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import FolderExplorerService from "../../../../services/FolderExplorerService";

interface IContentListEntry {
  contentId: string;
  mainLibrary: string | undefined;
  title: string;
  originalNewKey?: string;
  userId: any;
}
const TrashResources = () => {
  const { t } = useTranslation();

  const [publicContents, setPublicContents] = useState<IContentListEntry[]>();
  const [currentContentId, setCurrentContentId] = useState("");
  const [displayToggle, setDisplayToggle] = useState(false);
  const contentService = new ContentService();

  const getAllDeleteResources = async () => {
    const response = await contentService.listTrash();
    response && setPublicContents(response);
  };
  const updateFolder =  () => {
    setTimeout(() => {
      getAllDeleteResources()
    }, 1000);
  };

  const handleDelete = async () => {
    const response = await FolderExplorerService.emptyTrash()
    response && updateFolder()
  };
  const handleCancelDelete = () => {
    toast.dismiss("delete-warning-toast");
  };

  const emptyTrash = () => {
    toast.warning(
      <div>
        <p>
          {t("All items in the Trash will be permanently deleted")} ?
        </p>
        <button
          className="bg-red-500 hover:bg-red-400 text-white font-bold p-1 mx-2 border-b-4 border-red-700 hover:border-red-500 rounded"
          onClick={() => handleDelete()}>
          {t("Confirm")}
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-400 text-white font-bold  p-1 mx-2 border-b-4 border-blue-700 hover:border-blue-500 rounded"
          onClick={handleCancelDelete}>
          {t("Cancel")}
        </button>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        draggable: false,
        closeButton: false,
        closeOnClick: false,
        className: "delete-warning",
        bodyClassName: "delete-warning-body",
        progressClassName: "delete-warning-progress",
        toastId: "delete-warning-toast",
        theme: "colored",
      }
    );
  }
  const handleSearch = (e) => {
    const filteredData = publicContents?.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? getAllDeleteResources()
      : setPublicContents(filteredData);
  };

  useEffect(() => {
    getAllDeleteResources()
  }, []);


  return (
    <div className="flex-1 px-2 sm:px-0 bg-red/10 p-2 rounded-md">
      <div className="flex flex-wrap justify-between items-center">
        <ContentHeader title={t("Trash Resources")} />
        <div className="inline-flex items-center space-x-2">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              onChange={handleSearch}
              type="search"
              id="default-search"
              placeholder={t("Search Contents")}
              className="block w-full pl-10 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
            />
          </div>
          <button
            className="bg-red text-white/70 p-2 rounded-md hover:bg-red/50 hover:text-white smooth-hover w-10 h-10 "
            onClick={() =>
              emptyTrash()
            }>
            <ToolTip tooltip="empty trash">
              {/* <FontAwesomeIcon icon={faTrashCanArrowUp} /> */}
              <svg className="w-auto h-auto !text-white " viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 12L14 16M14 12L10 16M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </ToolTip>
          </button>
        </div>
      </div>
      <ul className="mb-10 sm:mb-0 mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 3xl:grid-cols-10">
        {publicContents?.map((content, k) => (
          
            <ContentCard
              key={`${k}trashContents`}
              content={content}
              displayToggle={displayToggle}
              setCurrentContentId={setCurrentContentId}
              updateFolder={updateFolder}
              contentType="trash"
            />
        ))}
        {publicContents?.length == 0 && <NotFound />}
      </ul>
    </div>
  );
};
export default TrashResources;
