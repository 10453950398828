import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeLowVision, faEye } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'react-cssfx-loading'

import { GetUserLogin } from '../../../services';

function ReactivateResetForm() {
    const { token } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [resetPassword, setResetPassword] = useState({
        password: "",
        confirmPassword: ""
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowPConfirmmassword] = useState(false);
    const [isPassValid, setPassIsValid] = useState(true);

    const minLength = 8

    const handleChange = (e) => {
        setResetPassword({ ...resetPassword, [e.target.name]: e.target.value })
        setPassIsValid(e.target.value.length >= minLength);
    }
    // Function to send the verification code to the server
    const sendPasswordResert = async () => {
        if (token) {
            if (resetPassword.password !== resetPassword.confirmPassword) {
                NotificationManager.error("Password didn't match", "password Reset")
            }
            else {
                setLoading(true);
                const passwordReset = {
                    newPassword: resetPassword.password,
                    token: token
                }
                GetUserLogin.reactivatepasswordReset(passwordReset).then((verify) => {
                    if (!verify) {
                        NotificationManager.error('Invalid or expired token', 'password Reset');
                        setLoading(false);
                    } else {
                        navigate('/login')
                    }
                }).catch((error) => {
                    NotificationManager.error('Invalid or expired token', 'password Reset');
                    setLoading(false);
                });
            }
        } else {
            NotificationManager.error('Use Correct token', "password Reser")
        }
    };

    return (
        <div className='relative w-[400px] flex justify-center items-center rounded-[20px] bg-box animate-animate3 box-form h-auto py-[20px] before:content before:absolute before:w-full before:h-full before:bg-box-before before:animate-animate3 before:rounded-[20px] before:animation-delay-1000 after:content after:absolute after:inset-[8px] after:bg-greslernorange after:bg-noise  after:rounded-[15px] after:border-4 after:border-transparent'>
            <form
                className='relative w-full flex justify-center items-center flex-col gap-[25px] py-0 px-[40px] z-[10]'
                onSubmit={(e) => {
                    e.preventDefault();
                    sendPasswordResert();
                }}>
                <div className="mb-1">
                    <h2 className='text-white text-[32px] text-center font-semibold'>{t("PasswordReset")}</h2>
                    <label className="mb-2.5 block font-medium text-gray-400 text-center dark:text-white">
                        {t("resetPasswordP")}
                    </label>
                    <div className="relative">
                        <input
                            id="password"
                            name='password'
                            type={showPassword ? "text" : "password"}
                            placeholder={t("password")}
                            onChange={(e) => handleChange(e)}
                            className={`password relative w-full py-[10px] px-[15px] border outline-none rounded-[5px] text-white text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]   ${isPassValid ? '!border-g-blue' : '!border-red-500'}`}
                        />
                        <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowPassword(!showPassword) }}>
                            <FontAwesomeIcon icon={!showPassword ? faEyeLowVision : faEye} />
                        </button>
                    </div>
                    {!isPassValid && (
                        <div className='text-red-500 text-sm'>
                            {t("passwordlength")} {minLength} {t("characterlong")}
                        </div>
                    )}
                    <div className="relative mt-4">
                        <input
                            id="confirmPassword"
                            name='confirmPassword'
                            type={showConfirmPassword ? "text" : 'password'}
                            placeholder={t("confirmPassword")}
                            onChange={(e) => handleChange(e)}
                            className="password relative w-full py-[10px] px-[15px] border !border-g-blue outline-none rounded-[5px] text-white text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]"
                        />
                        <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowPConfirmmassword(!showConfirmPassword) }}>
                            <FontAwesomeIcon icon={!showConfirmPassword ? faEyeLowVision : faEye} />
                        </button>
                    </div>
                </div>
                <div className="mb-2 w-full flex justify-center">
                    <button
                        disabled={loading}
                        type="submit"
                        className={` relative flex justify-center  mt-1 w-[85%] py-[10px] px-[15px] rounded-[5px] text-base tracking-wider transition-[0.5s] cursor-pointer
                ${loading
                                ? 'bg-[#2d2d39] text-white pointer-events-none'
                                : 'bg-white text-[#2d2d39] hover:!bg-[#243946] hover:text-white'}
              `}
                    >
                        {loading ? (<Spin color="#ffffff" width="30px" height="30px" duration="1s" />) : t("ResetPassword")}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ReactivateResetForm;
