import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useEffect, useState } from "react";
import { Bar, Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { selectInstitute } from '../../../../redux/Reducer/authSlice';
import { GetResource, GetUsers, InstituteService } from "../../../../services";
import GetUserList from '../../../../services/GetUserList';
import NoData from '../NoData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

interface UserResourceDataT {
  labels: any;
  datasets: {
    axis: string;
    label: string;
    data: any;
    fill: boolean;
    borderColor: string;
    backgroundColor: string;
  }[];
}

const Analisis = () => {
  const [xUsers] = useState<any>([]);
  const [numberOfusers] = useState<any>([]);
  const [countActive, setCountActive] = useState(0);
  const [countDeactivated, setCountDeactivated] = useState(0);
  const [counterPending, setCounterPending] = useState(0);
  const [userResourceData, setUserResourceData] = useState<UserResourceDataT>()
  const instituteId = useSelector(selectInstitute)
  useEffect(() => {
    fetchinvited(instituteId);
    fetchResourcesList(instituteId)
    fetchUsers(instituteId);
  }, []);
  const fetchUsers = async (instituteId) => {
    let data = await InstituteService.getusersReport("monthly")
    if (data) {

      data.map((item: any) =>
      (
        xUsers.push(item._id)
      ));
      data.map((item: any) =>
      (
        numberOfusers.push(item.count)
      ));
    }

  };
  const fetchResourcesList = async (instituteId) => {
    try {

      const result = await GetResource.getresource();
      const users = await GetUserList.getmembersList(instituteId)
      const members: any = []
      let labels: any = []
      let data: any = []

      users.forEach((user: any) => {
        if (user.institute == instituteId) {
          members.push(user._id)
          if (user.status === '2') {
            setCountDeactivated(countDeactivated + 1);
          } else if (user.status === '5') {
            setCounterPending(counterPending + 1);
          } else if (user.status === null) {
            setCountActive(countActive + 1);
          }
        }
      })

      result.forEach((r) => {
        if (members.includes(r?.userId.toString())) {
          if (!labels.includes(r?.username)) {
            labels.push(r?.username || 'NaN')
            const count = result.filter((obj) => obj.username === r.username).length
            data.push(count)
          }
        }
      }
      )
      const userResourceDataT = {
        labels: labels,
        datasets: [
          {
            axis: 'y',
            label: 'resource',
            data: data,
            fill: false,
            borderColor: 'rgb(181, 94, 46)',
            backgroundColor: 'rgba(181, 94, 46, 0.5)'
          }
        ],
      };
      setUserResourceData(userResourceDataT)


    } catch (error) {
      console.error("Error fetching resources list:", error);
    }
  };
  const plugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = options.color || '#d2691e80';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };
  const options = {
    indexAxis: 'y' as const,

    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      x: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        ticks: {
          precision: 0,
          color: 'white',
        },
        grid: {
          color: 'rgba(255,255,255,0.1)',
          borderColor: 'blue'  // <-- this line is answer to initial question
        }
      },
      y: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        ticks: {

          color: 'white',
        },
        grid: {
          color: 'rgba(255,255,255,0.1)',
          borderColor: 'blue'  // <-- this line is answer to initial question
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      // x:{

      //   labels:{color:'white'},
      // },
      title: {
        display: true,
        text: 'User Resources',
        color: 'white'
      },
      customCanvasBackgroundColor: {
        color: 'rgb(29, 83, 145,0.9)',
      }
    },
  };
  const doughnutOpt = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: { color: "white" }
      },
      customCanvasBackgroundColor: {
        color: 'rgb(29, 83, 145,0.9)',
      }
    },
  };
  const data11 = {
    labels: ['Pending', 'Active', 'Deactivated'],
    datasets: [
      {
        label: 'users',
        data: [counterPending, countActive, countDeactivated],
        backgroundColor: [
          'rgba(253, 224, 71, 0.5)',
          'rgba(22, 163, 74, 0.5)',
          'rgba(239, 68, 68, 0.5)',
        ],
        borderColor: [
          'rgb(253, 224, 71)',
          'rgb(22, 163, 74)',
          'rgb(239, 68, 68)',
        ],
        hoverOffset: 3,
      },
    ],
  };

  const fetchinvited = async (instituteId) => {
    const data = await GetUsers.getuserlistpending(instituteId);
    if (data) {
      setCounterPending(data.length);
    }

  }
  return (

    <div className='grid grid-cols-2 gap-2 ml-2'>
      <div className="container shadow p-1 mb-1 bg-white rounded w-full">
        {userResourceData ? <Bar width={100} height={100} data={userResourceData} options={options} plugins={[plugin]} />
          : <div className='items-center mt-20'> <NoData /> </div>}
      </div>
      <div className="container shadow p-1 mb-1 bg-white rounded w-full">
        <Doughnut data={data11} options={doughnutOpt} plugins={[plugin]} />
      </div>
    </div>
  )
}

export default Analisis