import { faFreebsd } from '@fortawesome/free-brands-svg-icons'
import { faSave, faCancel, faGear, faChevronDown, faInfoCircle, faWalking } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu,Transition } from '@headlessui/react'
import { Spin } from 'react-cssfx-loading'
import { useTranslation } from 'react-i18next'
import FolderSelectElement from '../../../folderSelect'
import { useNavigate } from 'react-router-dom'
import { selectEmail } from '../../../../redux/Reducer/authSlice'
import { useSelector } from 'react-redux'


function EditorMenu({canSave, folderId, setFolder, activityIndicator, templates, guide, walkthrough, handleTemplate,save,beginner,chatMode,setChatMode, setEditorMode,saveExit,actvitySaveExit}) {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const BIEmail =useSelector(selectEmail)
  
    return (
                <div className=" flex flex-wrap items-center space-x-2  justify-center rounded-lg  border-gray-100 bg-gray-100 p-1 z-30">
              {canSave && (<>
                <div>
                  <FolderSelectElement
                    setfoldertosave={setFolder}
                    folderId={folderId ? folderId : false}
                  />

                </div>
                <div className='gap-2 rounded-md px-2 py-1 text-sm text-gray-500 border border-transparent '>
                  <button
                    disabled={activityIndicator || templates}
                    onClick={() => save()}
                    className={templates ? "disabled:opacity-20 " : "" +" hover:!border-greslernorange hover:text-greslernorange  "}>
                    {activityIndicator ? (
                      <Spin
                        color="#111111"
                        width="30px"
                        height="30px"
                        duration="1s"
                      />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faSave} /> {t("Save")}{" "}
                      </>
                    )}
                  </button>
                  <Menu>
                    <Menu.Button className="ml-2 h-full hover:animate-bounce ease-in-out text-greslernorange">
                      <FontAwesomeIcon icon={ faChevronDown} />
                    </Menu.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className="z-100"
                    >
                      <Menu.Items className="absolute z-10 mt-2 py-1 rounded-md text-black/50 bg-white/90 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              disabled={actvitySaveExit}
                              onClick={() => saveExit()}
                              className={templates ? "disabled:opacity-20 " : "" +" w-full min-w-max text-left   px-2 py-1 text-sm text-gray-500 border border-transparent hover:!border-greslernorange hover:text-greslernorange focus:relative"}>
                              {actvitySaveExit ? <Spin color="#111111" width="30px" height="30px" duration="1s" /> : <><FontAwesomeIcon icon={faSave} /> {t("Save And Exit")}{" "}</>}
                            </button>
                            
                          )}
                        </Menu.Item>
                        {  BIEmail === process.env.REACT_APP_BIE_MAIL &&(<>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                disabled={activityIndicator || templates}
                                onClick={() => save('template')}
                                className={templates ? "disabled:opacity-20 " : "" +" w-full min-w-max text-left  px-2 py-1 text-sm text-gray-500 border border-transparent hover:!border-greslernorange hover:text-greslernorange focus:relative"}>
                                {activityIndicator ? (
                                  <Spin
                                    color="#111111"
                                    width="30px"
                                    height="30px"
                                    duration="1s"
                                  />
                                ) : (
                                  <>
                                    <FontAwesomeIcon icon={faFreebsd} /> {t("Save as Template")}{" "}
                                  </>
                                )}
                              </button>
                              
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                disabled={activityIndicator || guide}
                                onClick={() => save('guide')}
                                className={guide ? "disabled:opacity-20 " : "" +" w-full min-w-max text-left  px-2 py-1 text-sm text-gray-500 border border-transparent hover:!border-greslernorange hover:text-greslernorange focus:relative"}>
                                {activityIndicator ? (
                                  <Spin
                                    color="#111111"
                                    width="30px"
                                    height="30px"
                                    duration="1s"
                                  />
                                ) : (
                                  <>
                                    <FontAwesomeIcon icon={faInfoCircle} /> {t("Save as Guide")}{" "}
                                  </>
                                )}
                              </button>
                              
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                disabled={activityIndicator || walkthrough}
                                onClick={() => save('walkthrough')}
                                className={walkthrough ? "disabled:opacity-20 " : "" +" w-full min-w-max text-left  px-2 py-1 text-sm text-gray-500 border border-transparent hover:!border-greslernorange hover:text-greslernorange focus:relative"}>
                                {activityIndicator ? (
                                  <Spin
                                    color="#111111"
                                    width="30px"
                                    height="30px"
                                    duration="1s"
                                  />
                                ) : (
                                  <>
                                    <FontAwesomeIcon icon={faWalking} /> {t("Save as Walkthrough")}{" "}
                                  </>
                                )}
                              </button>
                              
                            )}
                          </Menu.Item>
                        </>
                        )
                        }
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                

                <button
                  onClick={() => navigate(-2)}
                  disabled={templates}
                  className={templates ? "disabled:opacity-20 " : "" +"gap-2 rounded-md px-2 py-1 text-sm text-gray-500 border border-transparent hover:!border-greslernorange hover:text-greslernorange focus:relative "}>
                  <FontAwesomeIcon icon={faCancel} /> Discard{" "}
                </button>
              </>
              )}
                {/* <button
                  onClick={()=>handleTemplate()}
                  className={
                      templates ? "inline-flex items-center gap-2 rounded-md px-2 py-1 text-sm bg-greslernorange text-white hover:text-greslernorange/50 focus:relative" 
                          :  "inline-flex items-center gap-2 rounded-md px-2 py-1 text-sm text-gray-500  border border-transparent hover:!border-greslernorange hover:text-greslernorange focus:relative"
                          }
                  >
                  <FontAwesomeIcon icon={faFreebsd}  /> {t("Templates")}
                </button> */}
                { canSave && (
                  <div className="w-5">
                    <Menu>
                      <Menu.Button className=" h-full hover:animate-spin ease-in-out text-greslernorange">
                        <FontAwesomeIcon icon={faGear} />
                      </Menu.Button>
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                        className="z-100"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 p-3 origin-top-right rounded-md text-black/50 bg-white/90 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <>
                            <p className=" font-light border-b border-greslernorange">{t("editor")}</p>
                                  <div className="flex items-center justify-end p-1 pb-2 space-x-1 rtl:space-x-reverse text-sm bg-gray-500/5 rounded-xl dark:bg-gray-500/5">
                                    <button className={
                                                (templates ? "disabled:opacity-20 " : "" )+
                                              ( beginner ? 
                                                    "flex whitespace-nowrap items-center px-2 font-medium rounded-lg outline-none border border-transparent hover:!border-greslernorange focus:ring-2 focus:ring-greslernorange  focus:ring-inset hover:text-greslernorange  focus:text-greslernorange  "
                                                    : "flex whitespace-nowrap items-center px-2 font-medium rounded-lg outline-none border border-transparent hover:!border-greslernorange focus:ring-2 focus:ring-greslernorange  focus:ring-inset text-white  shadow bg-greslernorange   " ) }
                                            onClick={setEditorMode} 
                                            disabled={templates}>
                                        {t("beginner")}
                                    </button>
                                    <button
                                        className={
                                                (templates ? "disabled:opacity-20 " : "") +
                                                (beginner ? 
                                                        "flex whitespace-nowrap items-center px-2 font-medium rounded-lg outline-none focus:ring-2 focus:ring-greslernorange border border- hover:!border-greslernorange   focus:ring-inset text-white  shadow bg-greslernorange   " 
                                                        : "flex whitespace-nowrap items-center px-2 font-medium rounded-lg outline-none focus:ring-2 focus:ring-greslernorange  border border- hover:!border-greslernorange  focus:ring-inset hover:text-greslernorange  focus:text-greslernorange  ") }
                                        onClick={setEditorMode}
                                            disabled={templates}>
                                        {t("advance")}
                                    </button>
                                  </div>
                              
                            </>
                            
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <>
                            {
                            canSave && (<>
                            <p className=" font-light border-b border-greslernorange">{t("chat")}</p>
                                  <div className="flex w-auto items-center justify-end p-1 space-x-1 rtl:space-x-reverse text-sm bg-gray-500/5 rounded-xl dark:bg-gray-500/5">
                                    <button className={
                                                (templates ? "disabled:opacity-20 " : "" )+
                                              ( chatMode ? 
                                                    "flex whitespace-nowrap items-center px-2 font-medium rounded-lg outline-none border border-transparent hover:!border-greslernorange focus:ring-2 focus:ring-greslernorange  focus:ring-inset hover:text-greslernorange  focus:text-greslernorange  "
                                                    : "flex whitespace-nowrap items-center px-2 font-medium rounded-lg outline-none border border-transparent hover:!border-greslernorange focus:ring-2 focus:ring-greslernorange  focus:ring-inset text-white  shadow bg-greslernorange   " ) }
                                            onClick={()=>setChatMode(false)} 
                                            disabled={templates}>
                                        {t("off")}
                                    </button>
                                    <button
                                        className={
                                                (templates ? "disabled:opacity-20 " : "") +
                                                (chatMode ? 
                                                        "flex whitespace-nowrap items-center px-2 font-medium rounded-lg outline-none border border- hover:!border-greslernorange focus:ring-2 focus:ring-greslernorange  focus:ring-inset text-white  shadow bg-greslernorange   " 
                                                        : "flex whitespace-nowrap items-center px-2 font-medium rounded-lg outline-none border border-transparent hover:!border-greslernorange focus:ring-2 focus:ring-greslernorange  focus:ring-inset hover:text-greslernorange  focus:text-greslernorange  ") }
                                        onClick={()=>setChatMode(true)}
                                            disabled={templates}>
                                        {t("on")}
                                    </button>
                                  </div>
                              </>)}
                            </>
                            
                          )}
                        </Menu.Item>
                        {/* <Menu.Item disabled>
                          <span className="opacity-75">Invite a friend (coming soon!)</span>
                        </Menu.Item> */}
                      </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
                
            </div>
  )
}

export default EditorMenu