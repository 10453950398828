
const Apis = {
  //Authentication api
  GetUserLogin: `/login`,
  GetUserRegsiter: `/signup`,
  GetInstituteRegsiter: `/signupInstitute`,
  GetUserList: `/api/admin`,
  GetUserLogout: `/logout`,
  GetUserByEmail: "/userbyemail",
  GetUserByToken: "/userbytoken",
  GetUserSession: "/checkSession",
  GetResendVerification: "/resendverification",
  GetForgetPassword: "/forgetpassowrd",
  GetReactivateAccount: "/reactivateaccount",
  GetPasswordReset: "/passwordreset",
  GetreactivatePasswordReset: "/reactivatepasswordreset",
  GetEmailVerificaton: "/verify",
  GetInstituteEmailVerificaton: "/verifyinstitute",
  CreateInstituteInvitation: `/instituteinvitation`,
  CreateInstituteInvitationexistuser: `/instituteinvitation/exist`,
  CreateExistInstituteInvitationexistuser: `/instituteinvitation/existinstituteexistuser`,
  CreateExistInstituteInvitationnewuser: `/instituteinvitation/existinstitutenewuser`,
  CreateIndividualsInvitation: `/instituteinvitation/individuals`,
  CreateIndividualsInvitationCsv: `/instituteinvitation/individualscsv`,
  CreateInstituteInvitationCsv: `/instituteinvitation/institutescsv`,
  createGroupInstitute: "/groupinstitute",
  createMainGroupInstitute: "/groupinstitute/main",
  GetResourceShare: `/h5p/resourcename/`,
  GetResourceSharedRes: `/h5p/sharedresource/`,
  GetResource: `/h5p/getresource`,

  GetAllCommentList: `/comments/allList`,
  GetCommentList: `/comments/resourcename/`,
  GetCommentbyResource: `/comments/resourcename/`,
  CreateCommentReply: `/comments/addReplycomment/`,
  GetResourceShared: `/h5p/getresourceshared/`, //shareresourcesTo
  ResourceSharedTo: `/shareresourcesTo/`, //shareresourcesTo
  ResourceTaggedTo: `/tagresourcesTo/`, //tagresourcesTo
  BookmarkResources: `/createBookmarks/`,
  RemoveBookmark: `/removeBookmark/`,
  ListBookmarks: `/h5p/listbookmarks/`,
  IncrementViews: `/comments/incrementViews/`,
  IncrementDownloads: `/comments/incrementDownloads/`,
  GetDownloads: `/comments/getDownloads/`,
  GetUserData: `/userslist/user/`,
  GetUserDataI: `/userslist/useri/`,
  GetUserDataInst: `/userslist/userforinst/`,
  GetInvitedUserbyEmail: `/userininvitation/`,
  GetIndividualsForInst: `/userslist/individualsininst/`,
  getGroupList: `/groupinstitute/`,
  getMainGroupList: `/groupinstitute/maingrouplist/`,

  getGroupListSsub: `/groupinstitute/grouplistssub/`,
  getListUsersingroup: `/groupinstitute/listusersingroup/`,
  updateUserGroup: `/groupinstitute/updateusergroup/`,
  RemoveUserGroup: `/groupinstitute/removeusergroup/`,
  RemoveResourceGroup: `/groupinstitute/removeresourcegroup/`,
  DeleteGroupId: `/groupinstitute/deletegroupbyid/`,
  DeleteMainGroupId: `/groupinstitute/deletemaingroupbyid/`,
  EditGroupId: `/groupinstitute/editgroupbyid/`,
  EditMainGroupId: `/groupinstitute/editmaingroupbyid/`,
  FetchGroupId: `/groupinstitute/fetchgroupbyid/`,
  FetchMainGroupId: `/groupinstitute/fetchmaingroupbyid/`,
  AddResourceToGroup: `/groupinstitute/updateresourcelist/`,

  GetInvitedUserbyToken: `/userininvitationToken/`,
  GetUserDataUpdate: `/userslist/updateuser/`,
  GetUserbyEmailPublic: `/userslist/userdetailpublic/`,
  GetInstitutebyNamePublic: `/institute/institutedetailpublic/`,
  GetInstituteDataUpdate: `/institute/updateinstitutebasic/`,
  GetInstituteContact: `/institute/updateinstitute`,
  GetInstituteBrandUpdate: `/institute/updateinstitutebrand/`,
  GetinstituteDetail: `/institute/detail/`,
  GetinstitutesingleDetail: `/institute/singleinstdetail/`,
  GetinstituteDetailNew: `/institute/detailinstitute/`,
  GetActivity: `/activity/`,
  GetFrequent: `/frequent/usedlink`,
  GetAnnouncement: `/institute/getannouncement/`,
  GetAllAnnouncement: `/institute/getallannouncement`,
  DeleteAnnouncement: `/institute/deleteannouncement/`,
  CreateAnnouncement: `/institute/addAnnouncement/`,
  CreateFrequent: `/frequent`,
  UpdateUserPassword: `/userslist/updatepassword`,
  UpdateInstitutePassword: `/institute/updatepassword`,
  RemoveProfilePicture: `/userslist/removeprofilepicture/`,
  UpdateProfilePicture: `/userslist/updateprofilepicture/`,
  DeleteUser: `/userslist/deleteuser/`,
  DeleteUserPending: `/instituteinvitation/deleteinviteduser/`,
  ResetAgain: `/userslist/resettonormal/`,
  ResetAgainInstitute: `/institute/resettonormalinstitute/`,
  BanUser: `/userslist/banuser/`,
  BanInstitute: `/institute/baninstitute/`,
  DeleteInstitute: `/institute/deleteinstitute/`,
  DeleteInvitedInstitute: `/instituteinvitation/deleteinvitedinstitute/`,
  GuidelineAccepted: "/userslist/guidelineAccepted",
  GetGuidelineAccepted: "/userslist/getGuidelineAccepted",
  CommentCounter: `/comments/countresource/`,
  CreateComment: `/comments`,
  GetResourceById: `/h5p/getresourcebyId/`,
  GetResourceByEmail: `/h5p/getresourcebyemail/`,
  GetresourceSortByLikes: `/h5p/sortresourcewithlike`,
  shareResourceCreate: `/resources/share/`,

  GetUsers: `/userslist/`,
  GetUsersAll: `/userslist/list`,
  // GetUsersAll: `/userslist/list`,
  GetUsersActive: `/userslist/active/`,
  GetUsersPending: `/userslist/pending/`,
  GetUsersDeactivate: `/userslist/deactivate/`,
  GetUsersBanned: `/userslist/banned/`,
  GetMembersList: `/institute/memberslist/`,

  GetInvitedIndividualsList: `/instituteinvitation/invitedindividuallist`,
  GetInstitutes: `/institute/instituteslist`,
  GetinvitationsentList: "/instituteinvitation",
  GetinvitationExpiredList: "/instituteinvitation/expiredinvitation",
  GetinvitationExpired: "/instituteinvitation/expiredlist",

  CountAllComment: `/h5p/countresourceall/countcommentall`,
  Folder: `/folder`,
  BieAssessment: `/bieAssessment`,
  //H5P
  GetH5P: `/h5p`,
  GetMetaResource: `/h5p/getmetaresource`,
  GetDataReport: `/h5p/getresourcereport/`,
  GetInstituteUsersReport: `/institute/getinstituteuserreport/`,
  UpdateInstituteLogged: `/institute/updatefirstlogged`,
  GetTagsList: `/h5p/countListtags/`,
  SetAsResource: `/h5p/setAs`,
  SetAsTemplateResource: `/h5p/setAsTemplate`,
  GetAllTemplateResource: `/h5p/getAllAsTemplate`,
  ListNotifications: `/h5p/notificationsList/`,

  ClearNotification: `/clearNotification/`,
  MarkReadNotification: `/markReadNotification/`,

  SetResourceChatSetting: `/setResourceChatSetting`,

  GetCommentReport: `/comments/getcommentreport/`,
  // Notification Settings
  getNotifiction: "/notifications",
  saveNotificationSetting: `/comments/saveNotificationSetting`,
  getNotificationSetting: `/comments/getNotificationSetting`,
  getLikes: `/comments/countLikes/`,
  getViews: `/comments/totalViews/`,
  getBookmarked: `/comments/totalBookmarked/`,

  //feedback
  sendFeedback: "/feedback/send",

  //Review
  getReview: '/review/get',
  addReview: '/review/add',
  updateReview: '/review/update',
  deleteReview: '/review/delete/',
  deleteContentReview: '/review/deletecontent',

  //notification
  getUnseenLikes: 'socket/getUnseenLikes/',
  getUnseenComments: 'socket/getUnseenComments/',
  getUnseenTags: 'socket/getUnseenTags/',
  getUnseenShares: 'socket/getUnseenShares/',
  getUpdateSeen: '/updateseenNotification/',

  Notification: `/notification`,

  // Subscribers
  Subscriber: '/subscriber',
  // tags
  addTags: '/tag/addTags',
  getResourceTags: '/tag/getTagsByRsourceID',
  getAllTags: '/tag/getTags',
  removeTags:'/tag/removeTag',
  filterByTagName:'/tag/filterByTagName'
};

const toastOpt: {} = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored"
};

export { Apis, toastOpt };