import React, { useEffect, useState, useRef } from 'react'
import { Parallax } from 'react-parallax';
import { motion } from 'framer-motion';
import { Transition } from '@headlessui/react'

import { useTranslation } from 'react-i18next';
import about from '../../../components/assets/img/About/aboutGreslern.png';
import logo from '../../../components/assets/img/About/logo_about.png';
import { fadeIn, textVariant } from '../../../utils/motion';
import SectionWrapper from '../../../hoc';
import About_Pattern from '../../../components/assets/img/About/about_pattern.png'

import LineComponent from '../../../components/lineComponent/idex';

function About({ id, id2 }) {

    const { t } = useTranslation();
    const [tab, setTab] = useState<number>(1)
    const tabs = useRef<HTMLDivElement>(null)

    const heightFix = () => {
        if (tabs.current && tabs.current.parentElement) tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`
    }

    useEffect(() => {
        heightFix()
    }, [])

    const switchTab = () => {
        setTab((prevTab) => (prevTab % 5) + 1); // Assuming 3 tabs, change as needed
    };

    useEffect(() => {
        const interval = setInterval(switchTab, 5000); // Switch tabs every 5 seconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    return (
        < div id='aboutSection' className="bg-white pb-4 md:pb-0 md:h-auto lg:h-[calc(100vh-5rem)] w-full"
            style={{
                backgroundImage: `url(${About_Pattern})`,
                backgroundRepeat: 'repeat',
                backgroundSize: "contain",
                backgroundPosition: 'bottom',
            }}
        >
            <div className="flex justify-between items-center h-full max-w-screen-xl mx-auto">
                <div className='grid grid-cols-12 w-full'>
                    {/* <div
                        className='absolute top-[356%]  left-[45.5%]  z-0 rotate-[75deg] 2xl:rotate-[60deg] hidden lg:block'
                    >
                        <Parallax
                            strength={200}
                            style={{ overflow: 'inherit' }}
                            renderLayer={percentage => (
                                <div className="relative">
                                    <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}
                                        style={{
                                            width: `8px`,
                                            height: `${percentage <= 1 ? percentage * 70 : 70}vh`,
                                        }}
                                    ></div>
                                </div>
                            )}
                        >
                        </Parallax>
                    </div> */}
                    <div className='col-span-12'>
                        <motion.div variants={textVariant()}
                            className='flex items-center '
                        >
                            <Parallax
                                strength={200}
                                renderLayer={percentage => (
                                    <div className="relative h-[170px] w-[170px]">
                                        <div id={id} className={` border-[#82368C]  bg-[#82368C] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `${percentage <= 0.5 ? percentage * 340 : 170}px`,
                                                height: `${percentage <= 0.5 ? percentage * 340 : 170}px`,
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        ></div>
                                        <span id='targetElement4' className="relative left-1 top-8 text-6xl leading-normal border-0 text-white p-0 ml-auto ">{t("Learn")}</span>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </motion.div>
                        <motion.h2 variants={textVariant()} className='relative bottom-12 left-36 text-3xl sm:text-5xl md:text-6xl font-extrabold !w-[10rem] sm:!w-[15rem] md:!w-[35rem]'>
                            {t("About")} GreSLERN
                        </motion.h2>
                        <div className="max-w-3xl mx-auto text-center pb-2 h-[100px]">
                            <div className="group absolute -left-4 w-full flex items-center justify-center flex-wrap px-[30px]">
                                <div className=" absolute max-w-[320px] sm:max-w-[375px] md:max-w-[720px] h-[15px] bg-white my-[30px] mx-[10px] py-[20px] px-[15px] flex flex-col shadow-md transition-all duration-[0.3s] ease-in-out transform translate-y-0 border rounded-lg group-hover:h-[300px] group-hover:sm:h-[280px] group-hover:md:h-[180px] group-hover:z-[20]">
                                    <div className="relative w-[300px] sm:w-[350px] md:w-[680px] h-[100px] top-[-20%] bg-gray-200 flex items-center justify-center rounded left-[1px] shadow-md z-10">
                                        <h3 className="h3 !mb-0 ">{t("BIE")}</h3>
                                    </div>
                                    <div className="relative -top-[40px] py-[10px] px-[15px] text-black text-center   opacity-[0] transition-all duration-[0.3s] ease-in-out group-hover:mt-[30px] group-hover:visible group-hover:opacity-[1] group-hover:delay-[0.2s]">
                                        <p className="text-md text-gray-600">{t("BIEparagraph")} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='max-w-xl md:max-w-none md:w-full mx-auto col-span-12 lg:col-span-6'>
                        <div className="transition-all">
                            <div className="relative flex flex-col text-center lg:text-right" data-aos="zoom-y-out" ref={tabs}>
                                {/* Item 1 */}
                                <Transition
                                    show={tab === 1}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterFrom="opacity-0 translate-y-16"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 -translate-y-16"
                                    beforeEnter={() => heightFix()}
                                    unmount={false}
                                >
                                    <div className="relative inline-flex flex-col h-[292px] w-full">
                                        <motion.div variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 text-gray-600'>
                                            <img className="md:max-w-none mx-auto rounded" alt='computer' src={about} onClick={(e) => { e.preventDefault(); setTab(1); }} />

                                        </motion.div>
                                    </div>
                                </Transition>
                                {/* Item 2 */}
                                <Transition
                                    show={tab === 2}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterFrom="opacity-0 translate-y-16"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 -translate-y-16"
                                    beforeEnter={() => heightFix()}
                                    unmount={false}
                                >
                                    <div className="relative inline-flex flex-col justify-center h-[292px]">
                                        <motion.h3 variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 py-3 font-bold font-[2rem] text-[1.5rem]'>
                                            {t("Intraculturalism")}
                                        </motion.h3>
                                        <motion.p variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 text-gray-600'>
                                            {t("Intraculturalismparagraph")}
                                        </motion.p>
                                    </div>
                                </Transition>
                                {/* Item 3 */}
                                <Transition
                                    show={tab === 3}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterFrom="opacity-0 translate-y-16"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 -translate-y-16"
                                    beforeEnter={() => heightFix()}
                                    unmount={false}
                                >
                                    <div className="relative inline-flex flex-col justify-center h-[292px]">
                                        <motion.h3 variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 py-3 font-bold font-[2rem] text-[1.5rem]'>
                                            {t("Transdisciplinarity")}
                                        </motion.h3>
                                        <motion.p variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 text-gray-600'>
                                            {t("Transdisciplinarityparagraph")}
                                        </motion.p>
                                    </div>
                                </Transition>
                                {/* Item 4 */}
                                <Transition
                                    show={tab === 4}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterFrom="opacity-0 translate-y-16"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 -translate-y-16"
                                    beforeEnter={() => heightFix()}
                                    unmount={false}
                                >
                                    <div className="relative inline-flex flex-col justify-center h-[292px]">
                                        <motion.h3 variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 py-3 font-bold font-[2rem] text-[1.5rem]'>
                                            {t("Dialecticism")}
                                        </motion.h3>
                                        <motion.p variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 text-gray-600'>
                                            {t("Dialecticismpragraph")}
                                        </motion.p>
                                    </div>
                                </Transition>
                                {/* Item 5 */}
                                <Transition
                                    show={tab === 5}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterFrom="opacity-0 translate-y-16"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 -translate-y-16"
                                    beforeEnter={() => heightFix()}
                                    unmount={false}
                                >
                                    <div className="relative inline-flex flex-col justify-center h-[292px]">
                                        <motion.h3 variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 py-3 font-bold font-[2rem] text-[1.5rem]'>
                                            {t("Contextuality")}
                                        </motion.h3>
                                        <motion.p variants={fadeIn("", "", 0.1, 1)}
                                            className='px-5 text-gray-600'>
                                            {t("Contextualityparagraph")}
                                        </motion.p>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-6 flex flex-col lg:flex-row justify-evenly items-center'>

                        <motion.div
                            variants={fadeIn("left", "spring", 0.5 * 3, 0.75)}
                            className='flex flex-col items-center'
                        >
                            <img alt='computer' src={logo} onClick={(e) => { e.preventDefault(); setTab(1); }} />
                            <small className={`relative hidden md:block -left-48 -top-24 cursor-pointer ${tab === 2 ? '!text-[#009FE3]' : '!text-black'}`} onClick={(e) => { e.preventDefault(); setTab(2); }}>{t("Intraculturalism")}</small>
                            <small className={`relative hidden md:block -left-44 -top-6 cursor-pointer ${tab === 3 ? '!text-[#E30613]' : '!text-black'}`} onClick={(e) => { e.preventDefault(); setTab(3); }}>{t("Transdisciplinarity")}</small>
                            <small className={`relative hidden md:block -left-12 -top-16 cursor-pointer ${tab === 4 ? '!text-[#00983A]' : '!text-black'}`} onClick={(e) => { e.preventDefault(); setTab(4); }}>{t("Dialecticism")}</small>
                            <small className={`relative  hidden md:block left-48 -top-20 cursor-pointer ${tab === 5 ? '!text-[#82368C]' : '!text-black'}`} onClick={(e) => { e.preventDefault(); setTab(5); }}>{t("Contextuality")}</small>
                            <div className="flex relative hover:!border-[#111111]">
                                <a
                                    href='https://osc.int/declaration/'
                                    target="_blank"
                                    className="text-center align-middle  border-2 border-[#111111] text-[#111111] px-1 py-2 rounded-lg hover:text-white hover:bg-[#111111] transition-all duration-350 ease-in-out w-28 hover:w-36">
                                    {t("ReadMore")}
                                </a>
                                <div className="icon_secondary w-12 h-12 border-2 border-[#111111] bg-[#111111] rotate-45 relative top-1 z-[-1] transition-all duration-350 ease-in-out ">
                                    <svg className=" w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] fill-white transition-all duration-350 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                        <path stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 16.5c0-1-8-2.7-9-2V1.8c1-1 9 .707 9 1.706M10 16.5V3.506M10 16.5c0-1 8-2.7 9-2V1.8c-1-1-9 .707-9 1.706" />
                                    </svg>
                                </div>
                            </div>
                        </motion.div>
                        <div
                            className='absolute top-[420%] left-[45%] z-10 w-12  hidden lg:block'
                        >
                            <Parallax
                                strength={200}
                                style={{ overflow: 'inherit' }}
                                renderLayer={percentage => (
                                    <div className="relative  h-[50px] w-[50px] ">
                                        <div id='targetElementAboutBlue' className={` border-[#007BFF]  bg-[#007BFF] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `${percentage <= 1 ? percentage * 50 : 50}px`,
                                                height: `${percentage <= 1 ? percentage * 50 : 50}px`,
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        ></div>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </div>
                        {/* <div
                            className='absolute top-[424%] left-[46.5%] z-0 rotate-[3deg]  hidden lg:block'
                        >
                            <Parallax
                                strength={200}
                                style={{ overflow: 'inherit' }}
                                renderLayer={percentage => (
                                    <div className="relative">
                                        <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `8px`,
                                                height: `${percentage <= 1 ? percentage * 30 : 30}vh`,
                                            }}
                                        ></div>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </div> */}
                        <LineComponent startPointId='targetElementAboutBlue' endPointId='targetElementAboutYellow' />

                        <div
                            className='absolute top-[450%] left-[44%] z-[1] w-24  hidden lg:block'
                        >
                            <Parallax
                                strength={200}
                                style={{ overflow: 'inherit' }}
                                renderLayer={percentage => (
                                    <div className="relative  h-[50px] w-[50px] ">
                                        <div id={id2} className={` border-[#F9B233]  bg-[#F9B233] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `${percentage <= 1 ? percentage * 50 : 50}px`,
                                                height: `${percentage <= 1 ? percentage * 50 : 50}px`,
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        ></div>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About