import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import countries from 'countries-list';
import { GetUsers } from '../../../services';
export default function UpdateUser({ email }) {

    const { t } = useTranslation();
    const [bio, setBio] = useState<any>();
    const [gender, setGender] = useState<any>();
    const [country, setSelectedCountry] = useState('');
    const countryList = Object.values(countries.countries);
    const usernameData = '';
    const [name, setName] = useState('');
    const [type, setType] = useState();
    const getInstituteDetail = async () => {
        const data = await GetUsers.getuserdataById(email);
        if (data) {
            setName(data.name);
            setGender(data.gender);
            setSelectedCountry(data.country);
            setBio(data.bio);
            setType(data.type);
        }
    }
    const handleType = (e) => {
        setType(e.target.value);
    };
    const handleCountry = (e) => {
        setSelectedCountry(e.target.value);
    }
    const userdataupdate = async (e) => {
        e.preventDefault();

        const updatedata = {
            username: usernameData,
            bio: bio,
            name: name,
            gender: gender,
            country: country,
            type: type,
        }
        try {
            let data = await GetUsers.userdataupdate(email ?? '', updatedata)
            if (!data.ok) {
                NotificationManager.success("Successfully updated", "User profile");
            }
            else {
                NotificationManager.warning("Profile not updated", "User profile")
            }
        } catch (error) {
            console.error('Error during feedback:', error);
        }
    }

    useEffect(() => {
        getInstituteDetail();
    }, []);
    return (
        <div className="bg-white p-2 rounded-lg shadow-lg w-full">
            <div className="flex justify-center mb-6">
                <span className="inline-block bg-gray-200 rounded-full p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4" /></svg>
                </span>
            </div>
            <h2 className="text-2xl font-semibold text-center mb-4">{t("Update User Info")}</h2>
            <form encType="multipart/form-data">
                <div className="mb-1">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Bio")} *</label>
                    <textarea
                        rows={2}
                        cols={4}
                        onChange={(e) => { setBio(e.target.value) }}
                        value={bio}
                        name="bio"
                        className="col-span-9 border-solid border-2 py-2 px-3 rounded-[0.375rem] form-input w-full text-gray-700 focus:ring-blue-500"
                        placeholder={t("Enter bio")}
                    ></textarea>
                    <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Name")} *</label>
                    <input
                        type="text"
                        onChange={(e) => { setName(e.target.value) }}
                        value={name}
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                        placeholder={t("Enter name")}
                        required
                    />
                </div>
                <div className="mb-1">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Gender")} *</label>
                    <select
                        required
                        onChange={(e) => { setGender(e.target.value) }}
                        value={gender}
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                    >
                        <option
                            className="text-[#292929]"
                            key={1}
                            value="female">
                            {t("Female")}
                        </option>
                        <option
                            className="text-[#292929]"
                            key={2}
                            value="male">
                            {t("Male")}
                        </option>
                        <option
                            className="text-[#292929]"
                            key={2}
                            value="I rather not to say">
                            {t("I rather not to say")}
                        </option>
                    </select>
                </div>
                <div className="mb-1">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Country")} *</label>
                    <select
                        required
                        id="country"
                        name="country"
                        value={country}
                        onChange={handleCountry}
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                    >
                        {countryList.map((country) => (
                            <option
                                className="text-[#292929]"
                                key={country.name}
                                value={country.name}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-1">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Type")} *</label>
                    <select
                        required
                        onChange={handleType}
                        value={type}
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                    >
                        <option
                            className="text-[#292929]"
                            key={1}
                            value="teacher">
                            {t("teacher")}
                        </option>
                        <option
                            className="text-[#292929]"
                            key={2}
                            value="admin">
                            {t("admin")}
                        </option>
                    </select>
                </div>
                <br />
                <button type="submit"
                    onClick={userdataupdate}
                    className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                    {t("Update")}</button>
            </form>
        </div>
    );
}
