import React from 'react'
import axios, { AxiosError } from 'axios';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from '../redux/store';
import Loader from '../components/loader';
import SessionModal from '../components/session';

interface ErrorResponse {
    message: string;
}

export default function AxiosErrorHandling(error: any) {

    if (axios.isAxiosError(error)) {

        const modalContainer = document.createElement('div');
        document.body.appendChild(modalContainer);

        const axiosError = error as AxiosError<ErrorResponse>;
        if (axiosError.response?.status === 401 && axiosError.response?.data?.message === 'Session expires') {
            // alert('Session Expired')
            ReactDOM.render(
                <Provider store={store}>
                    <PersistGate loading={<Loader />} persistor={persistor}>
                        <SessionModal />
                    </PersistGate>
                </Provider>,
                modalContainer
            );

        } else {
            // Handle other errors
            console.error('Error fetching shared content list:', error);
            throw error;
        }
    } else {
        console.error('An unexpected error occurred:', error);
        throw error;
    }
}