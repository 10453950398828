import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import ContentHeader from "../contentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { selectGuidelineAccepted, setGuidelineAccepted } from "../../../../../redux/Reducer/authSlice";
import { GetUserLogin } from "../../../../../services";

function Guidelines() {
  //   const { folderId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

      const checkGuideline = async () => {
        const response = await GetUserLogin.getGuidelineAccepted()
        dispatch(setGuidelineAccepted(response))
    }
    
    useEffect(() => {
      checkGuideline()

    }, [])
    
  
  const guidelineAccepted = useSelector(selectGuidelineAccepted)
  // alert(guidelineAccepted)

  const [step, setStep] = useState(1);
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  const handleFinalStep = () => {
    GetUserLogin.guidelineAccepted()
    dispatch(setGuidelineAccepted(true));
    navigate("create/");
  };

  return (
    <>
      <div className="flex-1 px-2 sm:px-0">
        <div className="flex justify-between items-center text-center">
          <ContentHeader
            title={t(
              "Guidelines to produce digital learning resources for GresLERN platform"
            )}
          ></ContentHeader>
        </div>

        <div className=" ">
          {/* ====================================== onClick={handleNextStep}   =========================================================== */}
          <div className="mt-4">
            <div className="bg-white mx-auto px-4 py-10 rounded-md shadow-card">
              {
              // step === "complete" ? (
              //   <div className="bg-white rounded-lg p-10 flex items-center shadow justify-between">
              //     {/* 
              //     <div>
              //       <svg
              //         className="mb-4 h-20 w-20 text-green-500 mx-auto"
              //         viewBox="0 0 20 20"
              //         fill="currentColor"
              //       >
              //         <path
              //           fillRule="evenodd"
              //           d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              //           clipRule="evenodd"
              //         />
              //       </svg>

              //       <h2 className="text-2xl mb-4 text-gray-800 text-center font-bold">
              //         Registration Success
              //       </h2>

              //       <div className="text-gray-600 mb-8">
              //         Thank you. We have sent you an email to demo@demo.test.
              //         Please click the link in the message to activate your
              //         account.
              //       </div>

              //       <button
              //         onClick={handlePrevStep}
              //         className="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border"
              //       >
              //         Back to home
              //       </button>
              //     </div> 
              //     */}
              //   </div>
              // ) : 
              (
                <div>
                  <div className="border-b-2 py-4">
                    {/* <div className="uppercase tracking-wide text-xs font-bold text-gray-500 mb-1 leading-tight">
                      Step: {step} of 5
                    </div> */}
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between text-title-lg">
                      <div className="flex-1">
                        {step === 1 && (
                          <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                            {t("Introduction")}
                          </div>
                        )}

                        {step === 2 && (
                          <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                            {t("Transdisciplinarity")}
                          </div>
                        )}

                        {step === 3 && (
                          <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                            {"Intraculturalism"}
                          </div>
                        )}

                        {step === 4 && (
                          <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                            {t("Dialecticism")}
                          </div>
                        )}

                        {step === 5 && (
                          <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                            {t("Contextuality")}
                          </div>
                        )}

                        {step === 6 && (
                          <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                            {t("")}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center md:w-64">
                        <div className="w-full bg-white rounded-full mr-2">
                          <div
                            className="rounded-full bg-green-500 text-xs leading-none h-2 text-center text-white"
                            style={{ width: `${(step / 6) * 100}%` }}
                          ></div>
                        </div>
                        {/* <div className="text-xs w-10 text-gray-600">
                          {`${(step / 3) * 100}%`}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* /Top Navigation */}

                  {/* Step Content */}
                  <div className="py-10">
                    {step === 1 && (
                      <div>
                        {/* <div className="mb-5 text-center">
                    <div className="mx-auto w-32 h-32 mb-2 border rounded-full relative bg-gray-100 mb-4 shadow-inset">
                      <img
                        id="image"
                        className="object-cover w-full h-32 rounded-full"
                        src={image}
                        alt="Profile"
                      />
                    </div>

                    <label
                      htmlFor="fileInput"
                      className="cursor-pointer inine-flex justify-between mt-5 cursor-pointer inline-flex justify-between mt-5 items-center px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-greslernorange hover:bg-gray-50 focus:outline-none focus:border-blue-300 focus:ring-blue-500" >
                      <span>Upload Image</span>
                      <input
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        className="sr-only"
                        onChange={handleImageChange}
                      />
                    </label>
                  </div> */}
                        <div className="font-satoshi indent-8 text-lg text-justify ">
                          <p className="leading-relaxed first-letter:text-3xl">
                            Education is being provided in various formats and
                            with various purposes and outcomes. The education to
                            be provided through GresLERN platform must serve the
                            purpose and the need to adhere to the principles of
                            BIE so as to achieve the aspirations stated in the
                            UDBIE. Therefore, any of the resources in the
                            platform should reflect the BIE pillars of{" "}
                            <a
                              href="#"
                              className="underline underline-offset-8 decoration-greslernblue font-bold  text-greslernorange hover:!bg-greslernorange hover:!text-white"
                            >
                              Transdisciplinarity
                            </a>{" "}
                            ,
                            <a
                              href="#"
                              className="underline underline-offset-8 decoration-greslernblue font-bold text-greslernorange hover:!bg-greslernorange hover:!text-white"
                            >
                              Intraculturalism
                            </a>
                            ,{" "}
                            <a
                              href="#"
                              className="underline underline-offset-8 decoration-greslernblue font-bold text-greslernorange hover:!bg-greslernorange hover:!text-white"
                            >
                              Dialecticism
                            </a>{" "}
                            and{" "}
                            <a className="underline underline-offset-8 decoration-greslernblue font-bold text-greslernorange hover:!bg-greslernorange hover:!text-white">
                              Contextuality
                            </a>
                            , in curricula and learning contexts whether it's
                            content, format, or methodologies.
                          </p>
                          <p className="leading-relaxed">
                            These guidelines have been developed by OSC-SEED to
                            encourage and support individuals and/or
                            organisations planning to develop educational
                            resources that appear on the GresLERN platform. The
                            guidelines have been informed by BIE pillar
                            standards. These guidelines represent OSC's
                            collective ideas, thoughts, strategies, and
                            suggestions for designing and producing content that
                            transforms education in the Global South. The
                            guidelines are just that - guidelines, they are not
                            a rigid set of rules, nor are they intended to
                            inhibit creativity.
                          </p>
                          <p className="leading-relaxed">
                            {" "}
                            The aims of these guidelines are:
                            {/* <ul className="mt-2 ml-2 list-inside list-disc"> */}
                            <ul className="list-inside">
                              <li className="flex">
                                <svg
                                  className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                To support high standards in educational
                                resources that are being produced by Member
                                States.
                              </li>
                              <li className="flex">
                                <svg
                                  className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                To support educators, teachers, and writers in
                                developing and running BIE-informed education.
                              </li>
                              <li className="flex ">
                                <svg
                                  className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                To provide a series of ideas, options, choices,
                                key questions, and viewpoints to stimulate
                                digital education platform use for BIE-led
                                transformation.
                              </li>
                            </ul>
                          </p>
                        </div>

                        <div className="flex justify-end mt-5">
                          {
                            guidelineAccepted && 
                            <button
                              onClick={handleFinalStep}
                              className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-greslernorange  hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                            >
                              {t("Skip")}
                            </button>
                          }
                          <button
                            onClick={handleNextStep}
                            className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-white bg-greslernorange hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Next")}
                          </button>
                          

                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <div>
                        <div className="font-satoshi indent-8 text-lg">
                          <p className="leading-relaxed first-letter:text-3xl">
                            <a
                              href="#"
                              className="underline underline-offset-8 decoration-greslernblue font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                            >
                              Transdisciplinarity
                            </a>{" "}
                            is an integrative, multi-perspective approach based
                            up on interconnecting both academic as well as
                            non-academic knowledge domains of complex and
                            holistic understanding of the world.
                          </p>
                          {/* <ul className="mt-2 ml-10 list-inside list-decimal"> */}
                          <ul className="list-inside">
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Engage multistakeholder, multi-sectoral and
                              transdisciplinary partnerships and collaboration
                              which produce knowledge and engage with
                              imperatives facing the world.
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Encourage mutual learning in communities which
                              acknowledge, value and amplify their respective
                              knowledge.
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Overcome disciplinary and fragmented teaching and
                              learning by integrating the interaction of
                              academic and non-academic knowledge domains into
                              curricula and assessment and introduce inter and
                              non-disciplinary thematic subjects into curricula.
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Make knowledge and the world external to formal
                              education settings quintessential to the education
                              process and design educational initiatives, in
                              collaboration with marginalised communities.
                            </li>
                          </ul>
                        </div>

                        <div className="flex justify-between mt-5">
                          <button
                            onClick={handlePrevStep}
                            className="w-32 py-2 px-4 border !border-greslernorange text-sm font-medium rounded-md text-greslernorange bg-white hover:text-white hover:!bg-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Back")}
                          </button>
                          
                          {
                            guidelineAccepted && 
                            <button
                              onClick={handleFinalStep}
                              className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-greslernorange  hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                            >
                              {t("Skip")}
                            </button>
                          }
                          <button
                            onClick={handleNextStep}
                            className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-white bg-greslernorange hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Next")}
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 3 && (
                      <div>
                        <div className=" font-satoshi indent-8 text-lg">
                          <p className="leading-relaxed first-letter:text-3xl">
                            <a
                              href="#"
                              className=" underline underline-offset-8 decoration-greslernblue  font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                            >
                              Intraculturalism
                            </a>{" "}
                            is an approach based upon in-depth cultural
                            introspection for a more complete understanding of
                            inter-indebtedness and interdependence of culture.
                          </p>
                          {/* <ul className="mt-2 ml-10 list-inside list-decimal"> */}
                          <ul className="list-inside">
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Create awareness of the multiplicity of global
                              historical, cultural, and social narrative.
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Create awareness of cultural inter-indebtedness
                              and interdependence.
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Create awareness of the evolving nature of culture
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Create awareness of endogenous and exogenous
                              dimensions of culture
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Create awareness of the plurality of narratives
                              and customs as integral to national culture
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Use mother tongue as a medium for education.
                            </li>
                          </ul>
                        </div>

                        <div className="flex justify-between mt-5">
                          <button
                            onClick={handlePrevStep}
                            className="w-32 py-2 px-4 border !border-greslernorange text-sm font-medium rounded-md text-greslernorange bg-white hover:text-white hover:!bg-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Back")}
                          </button>
                          {
                            guidelineAccepted && 
                            <button
                              onClick={handleFinalStep}
                              className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-greslernorange  hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                            >
                              {t("Skip")}
                            </button>
                          }
                          <button
                            onClick={handleNextStep}
                            className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-white bg-greslernorange hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Next")}
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 4 && (
                      <div>
                        <div className="font-satoshi indent-8 text-lg">
                          <p className="leading-relaxed first-letter:text-3xl">
                            <a
                              href="#"
                              className=" underline underline-offset-8 decoration-greslernblue font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                            >
                              Dialecticism
                            </a>{" "}
                            an interactional and synergetic approach based upon
                            problem-posing dialogue and critical exchange, for
                            free and critical thinking through the proactive
                            participation of learners.
                          </p>
                          {/* <ul className="mt-2 ml-10 list-inside list-decimal"> */}
                          <ul className="list-inside">
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Educators are mediators and facilitators who
                              foster an education process
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Learners are encouraged to become co-creators of
                              their own education, engaging with and producing
                              knowledge in dialogue and critical exchange with
                              peers and educators
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Learners are grounded in interactive dialogue,
                              thoughtful introspection, critical exchange and
                            </li>
                            <li>
                              Collective reflection for personal development.
                              Learners, individually and collectively, are
                              empowered to construct their worldviews and
                              question existing societal norms
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Learners are provided with safe educational spaces
                              to meet with educators and community members to
                              discuss, critique, and exchange diverse views
                              about the world
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Local community concerns and issues are embedded
                              in and made integral to the educational process
                            </li>
                          </ul>
                        </div>

                        <div className="flex justify-between mt-5">
                          <button
                            onClick={handlePrevStep}
                            className="w-32 py-2 px-4 border !border-greslernorange text-sm font-medium rounded-md text-greslernorange bg-white hover:text-white hover:!bg-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Back")}
                          </button>
                          {
                            guidelineAccepted && 
                            <button
                              onClick={handleFinalStep}
                              className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-greslernorange  hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                            >
                              {t("Skip")}
                            </button>
                          }
                          <button
                            onClick={handleNextStep}
                            className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-white bg-greslernorange hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Next")}
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 5 && (
                      <div>
                        <div className="font-satoshi indent-8 text-lg">
                          <p className="leading-relaxed first-letter:text-3xl">
                            <a
                              href="#"
                              className=" underline underline-offset-8 decoration-greslernblue font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                            >
                              Contextuality
                            </a>{" "}
                            is a context-centred approach based upon the
                            integration and adaptation to the realties, and
                            frameworks of learners, to develop their sense of
                            co-ownership and co-creation.
                          </p>
                          
                          {/* <ul className="mt-2 ml-10 list-inside"> */}
                          <ul className="list-inside">
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Encourage open educational institutions to develop
                              solutions adapted to local realities, concerns,
                              and challenges
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              Structurally embed cooperation with local
                              communities, institutions and civil society
                              organizations
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>{" "}
                              Adapt education to learners' socio-cultural
                              contexts as a basis for their co- ownership.
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>{" "}
                              Adapt education to interpretative frameworks and
                              the individuality of learners
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>{" "}
                              Regularly update curricula and make them more
                              flexible, adapted to real needs and relevant to
                              real-life
                            </li>
                            <li className="flex">
                              <svg
                                className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>{" "}
                              Tailor and adapt education to local and individual
                              contexts through local participation and
                              interaction.
                            </li>
                          </ul>
                        </div>

                        <div className="flex justify-between mt-5">
                          <button
                            onClick={handlePrevStep}
                            className="w-32 py-2 px-4 border !border-greslernorange text-sm font-medium rounded-md text-greslernorange bg-white hover:text-white hover:!bg-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Back")}
                          </button>
                          {
                            guidelineAccepted && 
                            <button
                              onClick={handleFinalStep}
                              className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-greslernorange  hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                            >
                              {t("Skip")}
                            </button>
                          }
                          <button
                            onClick={handleNextStep}
                            className="w-32 py-2 px-4 border border-transparent hover:!border-greslernorange text-sm font-medium rounded-md text-white bg-greslernorange hover:bg-white hover:!text-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Next")}
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 6 && (
                      <div>
                        <div className="font-satoshi text-justify indent-8 text-lg">
                          In addition, the contents, pedagogy, and assessment to
                          be followed should encourage National, regional and
                          international cooperation through the education system
                          by incorporating topics, approaches and assessments
                          that lend themselves to
                          <ul className="mt-2 ml-10 list-inside">
                            <li>
                              <FontAwesomeIcon icon={faCheck} color="green" />{" "}
                              Nationalization, regionalization
                              internationalization among and educational
                              institutions and actors,
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheck} color="green" />{" "}
                              Acknowledge the need for transdisciplinary
                              programs and projects.
                            </li>
                          </ul>
                        </div>

                        <div className="flex justify-between mt-5">
                          <button
                            onClick={handlePrevStep}
                            className="w-32 py-2 px-4 border !border-greslernorange text-sm font-medium rounded-md text-greslernorange bg-white hover:text-white hover:!bg-greslernorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t("Back")}
                          </button>
                          <button
                            onClick={handleFinalStep}
                            className=" py-2 px-4 border border-transparent hover:!border-green text-sm font-medium rounded-md text-white bg-green hover:bg-green-200 hover:!text-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greslernorange"
                          >
                            {t(
                              "I am unequivocally accepting and embracing it."
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* ================================================================================================= */}
        </div>
      </div>
    </>
  );
}

export default Guidelines;
