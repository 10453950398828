import PropTypes from 'prop-types'
import Xarrow from "react-xarrows";

const LineComponent = ({ startPointId, endPointId, startAnchor = ["bottom"], endAnchor = ["middle"], color = "#b75e2ebe" }) => {

  return (
    <div className=' invisible md:!visible bg-red-600' >
      <Xarrow
        start={startPointId}
        end={endPointId}
        startAnchor={startAnchor}
        endAnchor={endAnchor}
        // showXarrow={false}
        color={color}
        showHead={false}
        strokeWidth={7}
        path={"straight"}
        dashness={{ strokeLen: 1000, nonStrokeLen: 500, animation: 0.1 }}

        tailShape='circle'
        tailSize={3}
      // tailColor='purple'

      // headShape='circle'
      // headSize={3}
      // headColor={'blue'}
      // animateDrawing={0.5}

      />
    </div>
  );
};

LineComponent.propTypes = {
  startPointId: PropTypes.string.isRequired,
  endPointId: PropTypes.string.isRequired,
  startAnchor: PropTypes.array,
  endAnchor: PropTypes.array,
  color: PropTypes.string
};

export default LineComponent;