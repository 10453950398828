import { useState } from 'react'

import '../Login/style.css';
import BackgroudImg from '../../components/assets/african.jpg';
import SignupForm from '../../components/form/SignUpInstitute';

function SignupInstitute() {

  const [topLayerMarginLeft] = useState('0%');

  return (
    <div id="wrapper ">
      <div className={` h-full relative transition-margin-left `} style={{ marginLeft: topLayerMarginLeft, backgroundImage: `url(${BackgroudImg})`, backgroundSize: 'cover', backgroundBlendMode: 'overlay' }}>
        <SignupForm />
      </div>
    </div>
  )
}

export default SignupInstitute