import { useEffect, useState } from "react";
import { GetAllCommentList } from "../../../../services";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../root/dashboard/addon/contentHeader";
import ContentLoader from "../../../loader/ContentLoader";

const CommentsTable = (comments = []) => {
  const { t } = useTranslation()
  const [filteredComments, setFilteredComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isComment, setIsComment] = useState(false)
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredComments.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredComments.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const handleSearch = (e) => {
    const filteredData = filteredComments?.filter((comment) => {
      return Object.values(comment)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? fetchResourcesList()
      : setFilteredComments(filteredData);
  };

  const fetchResourcesList = async () => {
    setIsComment(false)
    try {
      const result = await GetAllCommentList.getAllCommentlist();
      setFilteredComments(result);
      setIsComment(true)
    } catch (error) {
      console.error("Error fetching feedbacks list:", error);
      setIsComment(true)
    }
  };
  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
    else
      return;
  }
  useEffect(() => {
    comments.length > 0 ? setFilteredComments(comments) : fetchResourcesList();
  }, []);
  return (
    <div className="w-full">
      <ContentHeader title={t("Feedbacks List")} />
      <div className="">
        <div className="my-2 flex place-content-end sm:flex-row flex-col">
          <div className="block relative">
            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
              </svg>
            </span>
            <input onChange={handleSearch} placeholder={t("Search")} className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-1 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
          </div>
        </div>
        {isComment ?
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 text-center uppercase tracking-wider">
                      {t("#No")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      {t("User")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Feedback")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Resource")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Status")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      {t("replylist")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredComments.length > 0 ? (
                    records?.map((comment: any, k: number) => (
                      <tr key={`${comment.email}_${k}_usesTable`}>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-center font-bold">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {(k + 1)}
                          </p>
                        </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center ">
                            <div className="flex-shrink-0 text-center w-6 h-6  rounded-full border-2 ">
                              {comment.username.charAt(0).toUpperCase()}
                              {/* <img
                              className="w-full h-full rounded-full"
                              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                              alt=""
                            /> */}
                            </div>
                            <div className="ml-3 ">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {comment.username}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {comment.message}
                          </p>
                        </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {comment.resourceId}
                          </p>
                        </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                          {comment.seen ? (
                            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                              <span
                                aria-hidden
                                className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                              <span className="relative">Seen</span>
                            </span>
                          ) : (
                            <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                              <span
                                aria-hidden
                                className="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                              <span className="relative">Unseen</span>
                            </span>
                          )}
                        </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                          {comment?.replylist.length}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="p-4">{t("no comment data.")}</tr>
                  )}
                </tbody>
              </table>
              <nav className="items-center">
                <ul className="list-style-none flex ml-12">
                  <li className="page-item">
                    <button className="pointer-events-none relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300" onClick={prePage}>
                      {t("Prev")}
                    </button>
                  </li>
                  {numbers.map((n, i) =>
                    <li
                      className={`${currentPage === n
                        ? "whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                        : "page-item"}`}
                      key={`adminUsertable${n.toString()}`}
                    >
                      <button
                        className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </button>
                    </li>
                  )}
                  <li>
                    <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100" onClick={nextPage}>
                      {t("Next")}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          : <ContentLoader />}
      </div>
    </div>

  );
};
export default CommentsTable;
