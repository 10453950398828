import { useRef, useState, useEffect, Suspense } from 'react'
import { Link } from 'react-router-dom'
import { Canvas, useFrame } from '@react-three/fiber';
import { Decal, Float, Stats, Preload, OrbitControls, useTexture } from "@react-three/drei";
import * as three from "three";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import create from '../../../components/assets/img/features/create.svg'
import download from '../../../components/assets/img/features/download.svg'
import share from '../../../components/assets/img/features/share.svg'
import collaborate from '../../../components/assets/img/features/collaborate.svg'
import createFr from '../../../components/assets/img/features/french/create.svg'
import downloadFr from '../../../components/assets/img/features/french/download.svg'
import shareFr from '../../../components/assets/img/features/french/share.svg'
import collaborateFr from '../../../components/assets/img/features/french/collaborate.svg'
import createAr from '../../../components/assets/img/features/arabic/create.svg'
import downloadAr from '../../../components/assets/img/features/arabic/download.svg'
import shareAr from '../../../components/assets/img/features/arabic/share.svg'
import collaborateAr from '../../../components/assets/img/features/arabic/collaborate.svg'
import createSp from '../../../components/assets/img/features/Spanish/create.svg'
import downloadSp from '../../../components/assets/img/features/Spanish/download.svg'
import shareSp from '../../../components/assets/img/features/Spanish/share.svg'
import collaborateSp from '../../../components/assets/img/features/Spanish/collaborate.svg'
import createPr from '../../../components/assets/img/features/Portuguese/create.svg'
import downloadPr from '../../../components/assets/img/features/Portuguese/download.svg'
import sharePr from '../../../components/assets/img/features/Portuguese/share.svg'
import collaboratePr from '../../../components/assets/img/features/Portuguese/collaborate.svg'
import createKm from '../../../components/assets/img/features/Khmer/create.svg'
import downloadKm from '../../../components/assets/img/features/Khmer/download.svg'
import shareKm from '../../../components/assets/img/features/Khmer/share.svg'
import collaborateKm from '../../../components/assets/img/features/Khmer/collaborate.svg'
import createSw from '../../../components/assets/img/features/Kiswahli/create.svg'
import downloadSw from '../../../components/assets/img/features/Kiswahli/download.svg'
import shareSw from '../../../components/assets/img/features/Kiswahli/share.svg'
import collaborateSw from '../../../components/assets/img/features/Kiswahli/collaborate.svg'
import CanvasLoader from '../Loader';
import { slideIn, fadeIn } from '../../../utils/motion';
import Feature_L_corner from '../../../components/assets/img/features/Feature_L_gray.png';
import Feature_R_corner from '../../../components/assets/img/features/Feature_R_gray.png';
import '../style.css'

//@ts-ignore
// import url from '../../../components/assets/video/features/Binary.mov'
import url from '../../../components/assets/video/features/BIE.mp4'

const VideoCube = () => {
    // video stuff
    const [video] = useState(() => {
        const videoElement = document.createElement("video");

        videoElement.src = url;
        videoElement.autoplay = true;
        videoElement.loop = true;
        videoElement.muted = true;

        return videoElement;
    });

    useEffect(() => {
        video?.play()
        return function () {
            video?.pause();
            video?.remove();
        };
    });

    // cube stuff
    const cube = useRef<three.Mesh>(null);

    useFrame(() => {
        if (cube.current) {
            // cube.current.rotation.x += 0.01;
            // cube.current.rotation.y += 0.002;
        }
    });

    return (
        <>
            <mesh castShadow receiveShadow scale={3} ref={cube}>
                <sphereGeometry args={[1, 32, 32]} />
                <meshStandardMaterial
                    color='#ffffff'
                >
                    <videoTexture attach="map" args={[video]} />
                </meshStandardMaterial>

            </mesh>
        </>
    );
};

const Scene = () => {
    return (
        <>
            {/* <pointLight intensity={2} position={[1, 10, 0]} castShadow /> */}
            {/* <fog attach="fog" args={["#b75e2e61", 0, 30]} /> */}
            <ambientLight intensity={2.1} />
            <directionalLight
                intensity={1}
                castShadow
                position={[5, 5, 5]}
            />
            <VideoCube />
            <mesh
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -5, 0]}
            >
                <circleGeometry args={[2, 50]} />
                <meshStandardMaterial attach="material" />
            </mesh>
        </>
    );
};

const Ball = (props) => {
    const [decal] = useTexture([props.url])
    return (
        <>
            <Float speed={1.75} rotationIntensity={1} floatIntensity={2}>
                <ambientLight intensity={0.5} />
                <directionalLight
                    position={[5, 5, 5]}
                    intensity={1}
                    castShadow // Enable shadow casting for the light
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                />
                <mesh castShadow receiveShadow scale={3} >
                    <icosahedronGeometry args={[1, 1]} />
                    <meshStandardMaterial
                        color='#b75f2e'
                        polygonOffset
                        polygonOffsetFactor={-5}
                        flatShading
                    />
                    <Decal
                        position={[0, 0, 1]}
                        rotation={[2 * Math.PI, 0, 6.25]}
                        map={decal}
                    />
                </mesh>

            </Float>
            <mesh
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -5, 0]}
            >
                <circleGeometry args={[2, 50]} />
                <meshStandardMaterial attach="material" color="#b75e2ebe" />
            </mesh>
        </>
    )
}

function Features({ id }) {
    const [createImg, setCreateImg] = useState(create)
    const [downloadImg, setDownloadImg] = useState(download)
    const [shareImg, setShareImg] = useState(share)
    const [collaborateImg, setCollaborateImg] = useState(collaborate)
    const { t } = useTranslation()

    useEffect(() => {
        switch (localStorage.getItem('languageName')) {
            case 'English':
                setCreateImg(create);
                setDownloadImg(download);
                setShareImg(share);
                setCollaborateImg(collaborate);
                break;
            case 'Français':
                setCreateImg(createFr);
                setDownloadImg(downloadFr);
                setShareImg(shareFr);
                setCollaborateImg(collaborateFr);
                break;
            case 'Español':
                setCreateImg(createSp);
                setDownloadImg(downloadSp);
                setShareImg(shareSp);
                setCollaborateImg(collaborateSp);
                break;
            case 'العربية':
                setCreateImg(createAr);
                setDownloadImg(downloadAr);
                setShareImg(shareAr);
                setCollaborateImg(collaborateAr);
                break;
            case "Portuguese":
                setCreateImg(createPr);
                setDownloadImg(downloadPr);
                setShareImg(sharePr);
                setCollaborateImg(collaboratePr);
                break;
            case 'Khmer':
                setCreateImg(createKm);
                setDownloadImg(downloadKm);
                setShareImg(shareKm);
                setCollaborateImg(collaborateKm);
                break;
            case 'Kiswahili':
                setCreateImg(createSw);
                setDownloadImg(downloadSw);
                setShareImg(shareSw);
                setCollaborateImg(collaborateSw);
                break;
            case 'Nicaragua':
                setCreateImg(create);
                setDownloadImg(download);
                setShareImg(share);
                setCollaborateImg(collaborate);
                break;
            default:
                setCreateImg(create);
                setDownloadImg(download);
                setShareImg(share);
                setCollaborateImg(collaborate); // Default case, executed when no case matches
        }
    }, [])

    return (
        <div id='featureSection' className="bg-amber-50 md:h-auto lg:h-[calc(100vh-5rem)] w-full "
            style={{
                backgroundImage: `url(${Feature_L_corner}), url(${Feature_R_corner})`,
                backgroundRepeat: 'no-repeat, no-repeat',
                backgroundPosition: 'left top, right top',
            }}
        >
            <div className="grid grid-cols-12 h-full max-w-screen-xl mx-4 lg:!mx-auto ">
                <motion.div
                    variants={slideIn('left', "tween", 0.2, 1)}
                    className="col-span-12 lg:col-span-3 flex sm:flex-row md:flex-row lg:flex-col justify-between z-[2]"
                >
                    <div className='lg:w-full w-1/2 '>
                        <div className='w-full h-32 flex justify-center '>
                            <img width='200px' src={createImg} alt='create ' />
                            {/* <Canvas
                                gl={{ preserveDrawingBuffer: true }}
                                shadows
                            >
                                <Suspense fallback={<CanvasLoader />}>
                                    <OrbitControls enableZoom={false} />
                                    <Ball url={createImg} />
                                </Suspense>
                                <Preload all />
                            </Canvas> */}
                        </div>
                        <motion.p variants={fadeIn("", "", 0.1, 1)} className='py-3'>
                            <span className='border-0 text-black font-bold p-0'>{t("featurecreateS1")}</span> {t("featurecreateS2")}
                        </motion.p>
                        <Link className='text-[#007BFF] underline' to='/comingsoon'>{t("ReadMore")}</Link>
                    </div>
                    <div className='lg:w-full w-1/2'>
                        <div className='w-full h-32 flex justify-center'>
                            <img width='200px' height='100px' src={downloadImg} alt='create' />
                            {/* <Canvas
                                gl={{ preserveDrawingBuffer: true }}
                            >
                                <Suspense fallback={<CanvasLoader />}>
                                    <OrbitControls enableZoom={false} />
                                    <Ball url={downloadImg} />
                                </Suspense>
                                <Preload all />
                            </Canvas> */}
                        </div>
                        <motion.p variants={fadeIn("", "", 0.1, 1)}
                            className='py-3'
                        ><span className='border-0 text-black font-bold p-0'>{t("featureDownloadS1")} </span> {t("featureDownloadS2")}
                        </motion.p>
                        <Link className='text-[#007BFF] underline' to='/comingsoon'>{t("ReadMore")}</Link>
                    </div >
                </motion.div>
                <div className="col-span-12 lg:col-span-6 flex justify-center items-center z-[1]">
                    <div id={id} className=" w-[550px] h-[550px] rounded-full overflow-hidden bg-transparent ">
                        <Canvas
                            camera={{
                                near: 0.1,
                                far: 1000,
                                zoom: 1
                            }}
                            shadows
                            onCreated={({ gl }) => {
                                gl.setClearColor("#ffffff");
                            }}
                        >
                            <OrbitControls />
                            <Suspense fallback={<CanvasLoader />}>
                                <Scene />
                            </Suspense>
                            <Preload all />
                        </Canvas>
                    </div>
                </div>
                <motion.div
                    variants={slideIn('right', "tween", 0.2, 1)}
                    className="col-span-12 lg:col-span-3 flex sm:flex-row md:flex-row lg:flex-col justify-between"
                >
                    <div className='lg:w-full w-1/2'>
                        <div className='w-full h-32 flex justify-center'>
                            <img width='200px' height='100px' src={shareImg} alt='create' />

                            {/* <Canvas
                                gl={{ preserveDrawingBuffer: true }}
                            >
                                <Suspense fallback={<CanvasLoader />}>
                                    <OrbitControls enableZoom={false} />
                                    <Ball url={shareImg} />
                                </Suspense>
                                <Preload all />
                            </Canvas> */}
                        </div>
                        <motion.p variants={fadeIn("", "", 0.1, 1)}
                            className='py-3'
                        ><span className='border-0 text-black font-bold p-0'>{t("featureShareS1")} </span>  {t("featureShareS2")}
                        </motion.p>
                        <Link className='text-[#007BFF] underline' to='/comingsoon'>{t("ReadMore")}</Link>
                    </div>
                    <div className='lg:w-full w-1/2'>
                        <div className='w-full h-32 flex justify-center'>
                            <img width='200px' src={collaborateImg} alt='create' />

                            {/* <Canvas
                                gl={{ preserveDrawingBuffer: true }}
                            >
                                <Suspense fallback={<CanvasLoader />}>
                                    <OrbitControls enableZoom={false} />
                                    <Ball url={collaborateImg} />
                                </Suspense>
                                <Preload all />
                            </Canvas> */}
                        </div>
                        <p className='py-3'><span className='border-0 text-black font-bold p-0'>{t("featureCollaborateS1")} </span> {t("featureCollaborateS2")}</p>
                        <Link className='text-[#007BFF] underline' to='/comingsoon'>{t("ReadMore")}</Link>
                    </div>
                </motion.div>
                {/* <div
                    className='absolute top-[160%] left-[35%] 2xl:left-[40%] 2xl:rotate-[38deg] z-0 rotate-[42deg] hidden lg:block'
                >
                    <Parallax
                        strength={200}
                        style={{ overflow: 'inherit' }}
                        renderLayer={percentage => (
                            <div className="relative">
                                <div className={` border-[#B75F2E] bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}

                                    style={{
                                        width: `8px`,
                                        height: `${percentage <= 1 ? percentage * 75 : 75}vh`,
                                    }}
                                ></div>
                            </div>
                        )}
                    >
                    </Parallax>
                </div> */}
            </div>
        </div >
    )
}

export default Features