import React from 'react'

function ResourceCardSkeleton() {
  return (
    <div className="min-h-full shadow-2xl bg-white rounded-lg animate-pulse relative overflow-hidden">
      <div className="py-5 flex flex-col items-center h-full space-y-5 ">
        <div className="w-32 h-8 absolute top-4 -right-8">
          <div className={`h-full w-full bg-gray-300 text-white text-center leading-8 font-semibold transform rotate-45`}></div>
        </div>
        <div className="bg-gray-300 w-12 h-6 rounded-full"></div>
        <div className="bg-gray-300 w-20 h-20 rounded-full"></div>
        <div className="bg-gray-300 w-36 h-6 rounded-full"></div>
        <div className="space-y-2">
          <div className="bg-gray-300 w-36 h-4 rounded-full"></div>
          <div className="bg-gray-300 w-36 h-4 rounded-full"></div>
        </div>
        <div className="bg-gray-300 w-36 h-4 rounded-full bottom-0"></div>
      </div>
    </div>
  )
}

export default ResourceCardSkeleton