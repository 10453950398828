import { t } from "i18next";
import { useEffect, useState } from "react";
import { ContentService, GetResource } from "../../../../services";
import ContentLoader from "../../../loader/ContentLoader";
import ContentHeader from "../../../root/dashboard/addon/contentHeader";

import { IContentItem } from '../../../../../../server/src/Constants/interfaces';
import { formatDateToLocaleDateAndTimeString } from "../../../../utils/functions";
import TableComponent from "../../../TableComponent";
import { faPlay, faPodcast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTip from "../../../tooltip";
import { toast } from "react-toastify";
import { toastOpt } from "../../../../config";


const ResourcesTable = () => {


  let resources = []

  type resourceProps = {
    username: string;
    creationDate: string;
    title: string;
    forcePublic: any;
    contentId: string;
    status: string;
  };

  const [filteredResources, setFilteredResources] = useState<resourceProps[]>([])
  const [isResource, setIsResource] = useState(false)

  const fetchResourcesList = async () => {
    setIsResource(false)
    try {
      const result = await GetResource.getresource();
      resources = result;
      setFilteredResources(result);
      setIsResource(true)

    } catch (error) {
      console.error("Error fetching resources list:", error);
      setIsResource(false)
    }
  };


  useEffect(() => {
    resources.length > 0 ? setFilteredResources(resources) : fetchResourcesList();
  }, [])

  const ResourcesTableActions = (resources) => {
    const contentService = new ContentService();

    const handlePostOnLandingPage = async (contentId) => {
      const response = await contentService.setContentForcePublic(contentId)
      if (response) {
        toast.success("Successfully posted", toastOpt);
        fetchResourcesList()
      }
    }
    const handleRemovePostOnLandingPage = async (contentId) => {
      const response = await contentService.setRemoveContentForcePublic(contentId)

      if (response) {
        toast.success("Successfully remove from post", toastOpt)
        fetchResourcesList()
      }
    }
    return (
      <div className="flex gap-3">

        <div>
          <ToolTip tooltip={t("play")}>
            <a href={"play/" + resources?.id?.toString()} >
              <FontAwesomeIcon
                icon={faPlay}
                className=" cursor-pointer hover:scale-150 duration-200 ease-in-out transition text-primary"
              /> {t("")}
            </a>
          </ToolTip>
        </div>

        {resources.status == "public" ?
          resources.forcePublic ?
            <div>
              <ToolTip tooltip={t("show on landing page")}>
                <button
                  onClick={() => handleRemovePostOnLandingPage(resources.id)}>
                  <FontAwesomeIcon
                    icon={faPodcast}
                    className=" cursor-pointer hover:scale-150 duration-200 ease-in-out transition text-primary"
                  /> {t("remove post")}
                </button>
              </ToolTip>
            </div>
            :
            <div>
              <ToolTip tooltip={t("show on landing page")}>
                <button
                  onClick={() => handlePostOnLandingPage(resources.id)}>
                  <FontAwesomeIcon
                    icon={faPodcast}
                    className=" cursor-pointer hover:scale-150 duration-200 ease-in-out transition text-primary"
                  /> {t("post")}
                </button>
              </ToolTip>
            </div>
          : undefined
        }
      </div>
    )
  }
  function formatContentData(data: any[]): IContentItem[] {
    return data.map(item => ({
      id: item.contentId,
      profilePhoto: undefined,
      user: item.username,
      resource: item.title,
      date: formatDateToLocaleDateAndTimeString(item.creationDate),
      status: item.status,
      forcePublic: item.forcePublic,
      isDeletedPermanently: item.isDeletedPermanently

    }));
  }

  return (
    <div className="">
      <ContentHeader title={t("Content List")} />
      <div className="">
        {isResource ?
          <TableComponent data={formatContentData(filteredResources)} actionComponent={ResourcesTableActions} title="" />
          :
          <ContentLoader />}
      </div>
    </div>
  );
}
export default ResourcesTable;
