import { createSlice } from '@reduxjs/toolkit';

const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    dataChanged:false, 
    likes: 0, // Initial state for likes
    comments: 0,
    tags: 0,
    shares: 0,
    review: 0,
    sessionID: null
  },
  reducers: {
    updateDataChanged: (state, action) => {
      state.dataChanged = action.payload;
    },
    updateLikes: (state, action) => {
      state.likes = action.payload;
    },
    updateComments: (state, action) => {
      state.comments = action.payload;
    },
    updateTags: (state, action) => {
      state.tags = action.payload;
    }, updateShares: (state, action) => {
      state.shares = action.payload;
    },
    updateReview: (state, action) => {
      state.review = action.payload;
    },
    updateSessionID: (state, action) => {
      state.sessionID = action.payload;
    },
    // Add other reducers as needed
  },
});

export const { updateDataChanged, updateLikes, updateComments, updateTags, updateShares, updateReview, updateSessionID } = socketSlice.actions;
export const selectDataChanged = (state: any) => state.socket.dataChanged;
export default socketSlice.reducer;
