import { Apis } from "../../config";
import api from "../../apiConfig";

const getAnnouncement = async (instId : any) => {
  try {
    let result = await api.get(Apis.GetAnnouncement + instId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (e) {
    console.log(e)
    return null;
  }
};
const getAllAnnouncement = async () => {
  try {
    let result = await api.get(Apis.GetAllAnnouncement)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (e) {
    console.log(e)
    return null;
  }
};
const deleteAnnouncement = async (announcementid:string , instituteId:any) => {
  try {
    let result = await api.patch(Apis.DeleteAnnouncement + announcementid,instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (e) {
    console.log(e)
    return null;
  }
};
const addAnnouncement = async (announcementData: any) => {
  try {
      const response = await api.post(Apis.CreateAnnouncement, announcementData);
      if (response.status === 200) {
          return response.data;
      }
      return null;
  } catch (error) {
      return null;
  }
};
export default {
  getAnnouncement,
  addAnnouncement,
  deleteAnnouncement,
  getAllAnnouncement
}