import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavbarAnimation from "../root/header";
import Sidebar from "./components/Sidebar";
import {
  faBook,
  faComment,
  faHistory,
  faHome,
  faInstitution,
  faMessage,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const DefaultLayout = () => {
  const { t } = useTranslation()
  return <NavbarAnimation>
    <div className="bg-[#8f9dc4] py-2 ">
      <div className=" bg-cover bg-dashboard-pattern flex flex-col md:flex-row space-y-5 lg:space-y-0  lg:space-x-10 sm:p-6 sm:my-2 sm:mx-4 sm:rounded-2xl">
        <Sidebar menus={
          [
            {
              name: t('Dashboard'),
              icon: <FontAwesomeIcon icon={faHome} />,
              link: '/admin/dashboard'
            },
            {
              name: t('Resources'),
              icon: <FontAwesomeIcon icon={faBook} />,
              link: '/admin/resources'
            },
            {
              name: t('Feedbacks'),
              icon: <FontAwesomeIcon icon={faComment} />,
              link: '/admin/comments'
            },
            {
              name: t('Users'),
              icon: <FontAwesomeIcon icon={faUsers} />,
              link: '/admin/users'
            },
            {
              name: t('Institutes'),
              icon: <FontAwesomeIcon icon={faInstitution} />,
              link: '/admin/institutes'
            },
            {
              name: t('Announcement'),
              icon: <FontAwesomeIcon icon={faMessage} />,
              link: '/admin/announcement'
            },
            {
              name: t('History'),
              icon: <FontAwesomeIcon icon={faHistory} />,
              link: '/admin/history'
            }
          ]
        } />
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <main>
            <div className="mx-auto p-4 md:p-6 2xl:p-10 bg-white/60 rounded-lg">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  </NavbarAnimation>;
};

export default DefaultLayout;
