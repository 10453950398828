import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import LogoAnimation from '../../logoAnimation';

function BackgroudL() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            const wrapper = document.querySelector('#wrapper') as HTMLElement;
            const layerOne = document.querySelector('#layerL-1') as HTMLElement;
            const layerTwo = document.querySelector('#layerL-2') as HTMLElement;
            const layerThree = document.querySelector('#layerL-3') as HTMLElement;
            const layerFour = document.querySelector('#layerL-4') as HTMLElement;
            const pageX = e.clientX - window.innerWidth / 2;
            const pageY = e.clientY - window.innerHeight / 2;

            if (wrapper && layerOne && layerTwo && layerTwo && layerThree && layerFour) {
                layerOne.style.transform = `translateX(-${50 + pageX / 200}%) translateY(-${50 + pageY / 200}%)`;
                layerTwo.style.transform = `translateX(-${50 + pageX / 1000}%) translateY(-${50 + pageY / 1000}%)`;
                layerThree.style.transform = `translateX(-${50 + pageX / 200}%) translateY(-${50 + pageY / 200}%)`;
                // layerFour.style.transform = `translateX(-${50 + pageX / 25}%) translateY(-${50 + pageY / 25}%)`;
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            // Cleanup by removing the event listener when the component unmounts
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (
        <div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full overflow-hidden">
                <div id="layerL-1" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full bg-gradient-art overflow-hidden">
                    <div id='layerL-2' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-screen overflow-hidden'></div>
                    <div id='door_1' className=" absolute left-1/2 transform -translate-x-1/2 w-[312px] h-[47px] z-[15] top-[82px] bg-white after:content after:absolute after:right-[-84px] after:top-0 after:w-0 after:h-0 after:border-t-[47px] after:border-r-[86px] after:border-b-0 after:border-l-0 after:border-solid after:border-t-white after:border-r-transparent  after:border-b-transparent  after:border-l-transparent before:content before:absolute before:left-[-85px] before:top-0 before:w-0 before:h-0 before:border-t-0 before:border-r-[86px] before:border-b-[47px] before:border-l-0 before:border-solid before:border-r-white before:border-t-transparent before:border-b-transparent before:border-l-transparent"> </div>
                    <div className="absolute left-1/2 top-[92px] transform -translate-x-1/2 w-[385px] h-[20px] rounded-full shadow-custom after:content after:absolute after:left-1/2 after:bottom-[-120px] after:transform after:-translate-x-1/2 after:w-[4000px] after:h-[240px] after:bg-gradient-radial-at-bottom "></div>
                    <div className='absolute left-1/2 top-[130px] transform -translate-x-1/2 w-[320px] h-[1px] rounded-full shadow-custom-2'></div>
                    <div id="door_2" className="absolute left-1/2 transform -translate-x-1/2 w-[312px] h-[47px] bottom-[86px] bg-[#0f172b] after:content after:absolute after:right-[-84px] after:top-0 after:w-0 after:h-0 after:border-solid after:border-t-[47px] after:border-r-0 after:border-b-0 after:border-l-[86px] after:border-t-transparent after:border-r-transparent after:border-b-transparent after:border-l-[#0f172b7c] before:content before:absolute before:left-[-85px] before:top-0 before:w-0 before:h-0 before:border-solid before:border-t-0 before:border-r-0 before:border-b-[47px] before:border-l-[86px] before:border-t-transparent before:border-r-transparent before:border-b-[#0f172b7c] before:border-l-transparent"></div>
                    <div className='absolute top-1/2 right-[-40px] transform -translate-y-1/2 rotate-[5deg] w-[1px] h-[500px] rounded-full shadow-gradient-1 '></div>
                    <div className='absolute bottom-0 left-[-100px] w-[80px] h-[80px] rounded-full shadow-gradient-2'></div>
                    <div id="layerL-3" className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[420px] h-[420px] rounded-full bg-gradient-round bg-contain bg-OSC_watermarkcircle-img shadow-round`}></div>
                </div>
                <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full bg-noise"></div>
                <div className="group relative flex w-full h-full justify-center items-center flex-wrap gap-[80px] z-[10]" id="layerL-4">
                    <div className='relative w-[350px] h-[150px] backdrop-blur-[1px] bg-[#ffffff33] transition-all duration-[0.5s] group-hover:h-[450px]'>
                        <div className='absolute inset-0 bg-transparent overflow-hidden after:content after:absolute after:inset-[3px] after:bg-transparent'></div>
                        <LogoAnimation />
                        <div className='absolute w-full h-full flex justify-center items-end overflow-hidden'>
                            <div className='py-[5px] px-[20px] text-center w-full transition-all duration-500 transform translate-y-[145px] group-hover:transform group-hover:translate-y-[0px]'>
                                <h2 className='font-semibold text-[2.5em] text-[#009FE3] leading-[2.2em]'>GreSLERN</h2>
                                <p className='text-white opacity-[0] transition-all duration-500 leading-[1.2em] group-hover:opacity-100 hover:opacity-100'>
                                    {t("LoginMoto")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackgroudL