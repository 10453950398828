import { faBook, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CardContainer from "./CardContainer";
import { Link } from "react-router-dom";



const ContentStatisticsCard = ({ resources = [] }) => {
  const { t } = useTranslation()
  const [resourcesStat, setResourcesStat] = useState()

  const getStat = (array) => {
    const count = array.reduce((acc, item) => {

      const type = item.status;
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});
    return count;
  };
  useEffect(() => {
    setResourcesStat(getStat(resources))
  }, [resources])

  const getIcon = () => {
    return <FontAwesomeIcon
      icon={faBook}
      className=" w-1/2 h-1/2  rounded-full m-1 text-white"
    />
  }

  const getStatistics = () => {
    return <>
      <div className="flex flex-col items-center justify-center">
        <p className="text-2xl font-bold text-white ">
          {resourcesStat?.private ? resourcesStat?.private : "0"}
        </p>
        <p className="text-sm font-normal text-white/50">{t("Private")}</p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <p className="text-2xl font-bold text-white ">
          {resourcesStat?.public ? resourcesStat.public : "0"}
        </p>
        <p className="text-sm font-normal text-white/50">{t("Public")}</p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <p className="text-2xl font-bold text-white ">
          {resources?.length ? resources.length : "0"}
        </p>
        <p className="text-sm font-normal text-white/50">{t("Total")}</p>
      </div>
    </>
  }


  return (
    <Link to="/admin/resources">
      <CardContainer icon={getIcon()} title="Contents" subTitle="Content Statistics" statistics={getStatistics()} iconBgColor='bg-green' shadowColor="shadow-green" />
    </Link>
  );
};


export default ContentStatisticsCard;
