

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultLandingPageLayout from '../DefaultLandingPageLayout'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {

  return (
   <DefaultLandingPageLayout>
    <div id="privacyandpolicy">
        <div className="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
        <h1 id='privacy' className="mb-2 text-2xl font-bold md:text-6xl text-greslernorange">19</h1>
        <h1 id='privacy' className="mb-2 text-2xl font-bold md:text-6xl text-greslernorange">Privacy Policy <br /> </h1>
        <p className="text-gray-700 ">Last updated: May 16, 2024 </p>
        <Link to={'http://google.com'} download={true} className=' text-greslernorange text-sm hover:text-greslernorange/50 hover:underline' >download <FontAwesomeIcon icon={faFilePdf} /></Link>
        </div>
        <div className="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
            <div className="grid gap-4 md:gap-8">
                <div>
                <p className="mb-8  text-xl text-center text-gray-500">
                    This section focus on privacy policy amd confidentiality of communications.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange pr-3">19.1</b>
                    OSC respects your privacy and is committed to the privacy protection principles, as set out below and in the Privacy Policy.
                </p>
                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.2</b>

                    The User's attention is drawn to the fact that information transmitted via the Internet, including e-mail, is susceptible to monitoring and interception. The User is therefore discouraged from transmitting to the Website or OSC any Material or information that may be confidential, proprietary, or sensitive. The User shall bear all risk of transmitting such Material or information in this manner and under no circumstances will OSC be liable for any loss, harm, or damage suffered by the User as a result thereof.


                </p>
                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.3</b>

                    OSC reserves the right to request independent verification of any information, Material or content transmitted via e-mail and the User consents to such verification should OSC deem it necessary.

                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.4</b>
                    
                    Where you submit personal information (such as your name, address, telephone number, e- mail address) to the Website, for example through sending an e-mail or filling in required fields, the following principles are observed in the handling of that information:
                    <ul className=' pl-10 pt-4'>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.4.1</h2>
                            <p className="mb-3  text-gray-500">

                            OSC collects, processes and stores only such personal information regarding users as is necessary to provide the services offered. The specific purpose for which information is collected is apparent from the context in which it is requested.
                            </p>
                        </li>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.4.2</h2>
                            <p className="mb-3  text-gray-500">
                                OSC will not use the personal information submitted for any other purpose other than the efficient provision of services, including this Website and services offered through it by third parties, without obtaining the prior written approval of the User or unless required to do so by law.
                            </p>
                        </li>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.4.3</h2>
                            <p className="mb-3  text-gray-500">
                                OSC will keep records of all personal information collected and the specific purpose for which it was collected for a period of at least one year from the date on which it was last used.
                            </p>
                        </li>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.4.4</h2>
                            <p className="mb-3  text-gray-500">
                                Subject to these General Terms and Conditions, OSC will not disclose any personal information regarding a User to any third party unless the prior written agreement of the User is obtained or OSC is required or permitted to do so by law.
                            </p>
                        </li>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.4.5</h2>
                            <p className="mb-3  text-gray-500">
                            If personal information is released with the User's consent, OSC will retain a record of the information released, the third party to which it was released, the reason for the release and the date of release, for a period of one year after such release.
                            </p>
                        </li>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.4.6</h2>
                            <p className="mb-3  text-gray-500">
                                OSC will destroy or delete any personal information under its control, which has become obsolete.
                            </p>
                        </li>
                    </ul>
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.5</b>
                    OSC will not utilise personal and other information submitted by Users through the use of this Website in order to provide further information about the Website and related services, unless users have expressly given prior permission.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.6</b>
                    In the event of the sale of OSC's business or this Website or associated services and sites, OSC will stipulate that the sale shall be subject to the condition that the purchaser observes the provisions of the Privacy Policy in the handling of personal information of Users.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.7</b>
                    This Website may use personal information collected to compile profiles for statistical and analytical purposes. No information contained in the profiles or statistics will be able to be linked to any specific user.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.8</b>
                    In order to provide the best possible and most relevant service, this Website utilises cookies. A cookie is a small file that is placed on the User's hard drive-in order to keep a record of a user's interaction with this Website and facilitate user convenience.
                    <ul className=' pl-10 pt-4'>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.8.1</h2>
                            <p className="mb-3  text-gray-500">
                                Cookies from this Website allow OSC to tailor advertising and other services to your displayed preferences. The Website may use third party cookies from an ad server for this purpose.
                            </p>
                        </li>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.8.2</h2>
                            <p className="mb-3  text-gray-500">
                            Cookies by themselves will not be used to personally identify Users but will be used to compile anonymous statistics relating to use of services offered or to provide us with feedback on the performance of this Website.
                            </p>
                        </li>
                        <li className='flex flex-row gap-2'>
                            <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.8.3</h2>
                            <p className="mb-3  text-gray-500">
                                The following classes of information will be collected in respect of Users who have enabled cookies:
                                <ul className=' pl-10 pt-4'>
                                    <li className='flex flex-row gap-2'>
                                        <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.8.3.1</h2>
                                        <p className="mb-3  text-gray-500">
                                            The browser used;
                                        </p>
                                    </li>
                                    <li className='flex flex-row gap-2'>
                                        <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.8.3.2</h2>
                                        <p className="mb-3  text-gray-500">
                                            IP address;
                                        </p>
                                    </li>
                                    <li className='flex flex-row gap-2'>
                                        <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.8.3.3</h2>
                                        <p className="mb-3  text-gray-500">
                                            Date and time of activities while visiting the Website;
                                        </p>
                                    </li>
                                    <li className='flex flex-row gap-2'>
                                        <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.8.3.4</h2>
                                        <p className="mb-3  text-gray-500">
                                            URLs of internal pages visited; and,
                                        </p>
                                    </li>
                                    <li className='flex flex-row gap-2'>
                                        <h2 className="text-lg font-semibold text-greslernorange  pr-3 ">19.8.3.5</h2>
                                        <p className="mb-3  text-gray-500">
                                            Referrers.
                                        </p>
                                    </li>
                                </ul>
                            </p>
                        </li>
                    </ul>
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.9</b>
                    If a User does not wish cookies to be employed to customize his or her interaction with this Website it is possible to alter the manner in which their browser handles cookies. Please note that, if this is done, certain services on this Website may not be available.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.10</b>
                    Users may obtain a record of the personal information relating to them held by OSC by e- mailing GresLERN@osc.int.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.11</b>
                    We retain personal data as long as your account is active or as needed to provide services.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.12</b>
                    You may request that OSC cease sending you any information or request that your personal information be removed from our database at any time by e-mailing GresLERN@osc.int.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.13</b>
                    We have no control over and accept no responsibility for the privacy practices of any third- party sites to which hyperlinks may have been provided on this Website and we strongly recommend that you review the privacy policy of any site you visit before using it further.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.14</b>
                    While reasonable measures are taken to ensure the security and integrity of information submitted to or collected by this site, this Website can not under any circumstances be held liable for any loss or other damage sustained by a user or users as a result of the release of information by OSC, an employee of OSC or any third party where such release was not due to any fault on the part of such persons.
                </p>

                <p className="mb-5  text-gray-500">
                <b className="text-lg font-semibold text-greslernorange  pr-3 ">19.15</b>
                    We regard the safety and privacy of children using this Website extremely seriously but it remains the ultimate responsibility of the parents and other care-givers of children to ensure that they are not exposed to inappropriate content or behaviour.
                </p>
                </div>
            </div>
        </div>
    </div>
   </DefaultLandingPageLayout>
  )
}

export default PrivacyPolicy