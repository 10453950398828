import { Parallax } from 'react-parallax';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next"

import tablet from '../../../components/assets/img/sharedResource/tablet.png'
import videoB from '../../../components/assets/img/sharedResource/videoB.svg'
import pictureB from '../../../components/assets/img/sharedResource/pictureB.svg'
import { fadeIn, textVariant } from '../../../utils/motion';
import SectionWrapper from '../../../hoc';

import './style.css'
import LineComponent from '../../../components/lineComponent/idex';

function ShareResource({ id, id2 }) {
  const { t } = useTranslation()
  console.log(window.innerHeight)
  return <div className="bg-amber-50  md:h-auto lg:h-[calc(100vh-5rem)] w-full">

    <LineComponent startPointId='targetElementSharedResourcePurple' endPointId='comp2' />
    <LineComponent startPointId='comp2' endPointId='targetElementSharedResourceGreen' />


    <div className="flex justify-center lg:justify-between items-center w-full h-full max-w-screen-xl mx-auto">
      <div className="absolute top-[273%] 2xl:top-[275%] 2xl:left-[35%] left-[34%] z-10 w-24 hidden lg:block">
        <Parallax strength={200} style={{ overflow: "inherit" }} renderLayer={percentage => <div className="relative  h-[100px] w-[100px] ">
          <div id={id} className={` border-[#9C15DB]  bg-[#9C15DB] rounded-full text-black absolute  animate-grow-bg`} style={{ width: `${percentage <= 1 ? percentage * 100 : 100}px`, height: `${percentage <= 1 ? percentage * 100 : 100}px`, top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
        </div>} />
      </div>
      {/* <div className="absolute top-[280%] left-[38%] z-0 rotate-[-68deg] 2xl:rotate-[-60deg] hidden lg:block">
            <Parallax strength={200} style={{ overflow: "inherit" }} renderLayer={percentage => <div className="relative">
                  <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`} style={{ width: `8px`, height: `${percentage <= 1 ? percentage * 520 : 520}px` }} />
                </div>} />
          </div> */}
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-12 lg:col-span-6 flex items-center justify-center flex-col md:flex-row">
          <motion.div variants={fadeIn("right", "spring", 0.5 * 1, 0.75)} className="w-[12rem]  h-[20rem] mt-2 md:!mt-0">
            <div className="card3d w-[300px] h-[calc(300px/1.5)] relative flex justify-center items-flex-end  rounded-t-xl group" style={{ perspective: "2500px" }}>
              <div className="wrapper absolute h-full w-full z-[-1] transition-all duration-500 bg-greslernorange rounded-t-xl group-hover:">
                <img src={videoB} className="w-full opacity-[1] object-cover group-hover:opacity-[0] cover-image" />
              </div>
              <h1 className="title">
                {t("Video")}
              </h1>
              <img src={videoB} className="character" />
            </div>
          </motion.div>
          <motion.div variants={fadeIn("right", "spring", 0.5 * 2, 0.75)} className="w-[12rem] h-[20rem] mt-0 md:!mt-28">
            <div className="card3d rounded-t-xl">
              <div className="wrapper bg-greslernblue rounded-t-xl">
                <img src={pictureB} className="cover-image" />
              </div>
              <h1 className="title">
                {t("Picture")}
              </h1>
              <img src={pictureB} className="character" />
            </div>
          </motion.div>
        </div>
        <motion.div variants={textVariant()} className="col-span-12 lg:col-span-6 z-[2]">
          <div className="flex items-center ">
            <div>
              <span className="p-0 text-6xl text-black border-0 ">
                {t("uploa")}
              </span>
            </div>
            <Parallax strength={200} renderLayer={percentage => <div className="relative h-[10.4rem] w-[10.4rem] ">
              <div className={` border-[#1ABC9C]  bg-[#1ABC9C] rounded-full text-black absolute  animate-grow-bg`} style={{ width: `${percentage <= 0.5 ? percentage * 20.8 : 10.4}rem`, height: `${percentage <= 0.5 ? percentage * 20.8 : 10.4}rem`, top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
              <span className="relative top-10 text-6xl leading-normal border-0 text-black p-0 ml-auto">
                {t("d")}
              </span>
            </div>} />
          </div>
          <div className="relative top-[-3.5rem] flex flex-col items-center px-4 lg:px-14">
            <h1 className="text-6xl font-bold">
              {t("yourcontent")}
            </h1>
            <div className="flex justify-between px-2 lg:px-5 mt-10">
              <motion.p variants={fadeIn("", "", 0.1, 1)} className="w-52 mr-2">
                {t("shreedResourceS1")} <span className="p-0 border-0 text-black font-bold">
                  {" "}{t("shreedResourceS2")}
                </span> {t("shreedResourceS3")}
              </motion.p>
              <motion.div variants={fadeIn("left", "spring", 0.5 * 1, 0.75)}>
                <img alt="tablet" src={tablet} className="w-full h-full" />
              </motion.div>
            </div>
          </div>
        </motion.div>
        <div className="absolute top-[310%] left-[52%] z-[1] w-24 hidden lg:block">
          <Parallax strength={200} style={{ overflow: "inherit" }} renderLayer={percentage => <div className="relative  h-[50px] w-[50px] ">
            <div id={"comp2"} className={` border-[#009FE3]  bg-[#009FE3] rounded-full text-black absolute  animate-grow-bg`} style={{ width: `${percentage <= 1 ? percentage * 50 : 50}px`, height: `${percentage <= 1 ? percentage * 50 : 50}px`, top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          </div>} />
        </div>
        {/* <div className="absolute top-[314%]  left-[53.5%] z-0 rotate-[20deg] hidden lg:block">
              <Parallax strength={200} style={{ overflow: "inherit" }} renderLayer={percentage => <div className="relative">
                    <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`} style={{ width: `8px`, height: `${percentage <= 1 ? percentage * 45 : 45}vh` }} />
                  </div>} />
            </div> */}
        {/* <div id={'comp2'} className='w-2 bg-red' >comp2</div> */}
        <div className="absolute top-[353%] left-[44%] z-10 w-24 hidden lg:block">
          <Parallax strength={200} style={{ overflow: "inherit" }} renderLayer={percentage => <div className="relative  h-[50px] w-[50px] ">
            <div id={id2} className={` border-[#00983A]  bg-[#00983A] rounded-full text-black absolute  animate-grow-bg`} style={{ width: `${percentage <= 1 ? percentage * 50 : 50}px`, height: `${percentage <= 1 ? percentage * 50 : 50}px`, top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          </div>} />
        </div>
      </div>
    </div>
  </div>;
}

export default ShareResource