import { useState, useEffect, useRef } from 'react'
import { Transition } from '@headlessui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faComments,
    faFolder,
    faGlobe,
    faShareNodes,
    faUserLock,
    faArrowLeft,
    faBookBookmark,
    faDashboard,
    faBook
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserName } from '../../redux/Reducer/authSlice';
import Typist from 'react-typist';


function DashbordGuid() {

    const { t } = useTranslation();
    const username = useSelector(selectUserName)
    const [tab, setTab] = useState<number>(1)
    const tabs = useRef<HTMLDivElement>(null)

    const heightFix = () => {
        if (tabs.current && tabs.current.parentElement) tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`
    }

    useEffect(() => {
        heightFix()
    }, [])

    return (
        <div className="flex-row">
            <div className="md:grid md:grid-cols-12 md:gap-6">
                {/* Tabs items */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-1 mb-8 md:mb-0 ">
                    <div className="transition-all">
                        <div className="relative flex flex-col text-center lg:text-right" ref={tabs}>
                            {/* Item 1 */}
                            <Transition
                                show={tab === 1}
                                appear={true}
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterFrom="opacity-0 translate-y-16"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-16"
                                beforeEnter={() => heightFix()}
                                unmount={false}
                            >
                                <div className="relative inline-flex flex-col">
                                    <div className="flex justify-center items-center w-16 h-16 bg-white rounded-full shadow flex-shrink-0 animate-pulse relative lg:top-0 lg:rotate-0 lg:right-5 md:right-80 right-44 rotate-90 ">
                                        <FontAwesomeIcon className="text-4xl" icon={faArrowLeft} />
                                    </div>
                                </div>
                            </Transition>
                            {/* Item 2 */}
                            <Transition
                                show={tab === 2}
                                appear={true}
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterFrom="opacity-0 translate-y-16"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-16"
                                beforeEnter={() => heightFix()}
                                unmount={false}
                            >
                                <div className="relative inline-flex flex-col">
                                    <div className="flex justify-center items-center w-16 h-16 bg-white rounded-full shadow flex-shrink-0 animate-pulse relative lg:top-20 lg:right-5 lg:rotate-0  rotate-90 md:right-60 right-28">
                                        <FontAwesomeIcon className="text-4xl" icon={faArrowLeft} />
                                    </div>
                                </div>
                            </Transition>
                            {/* Item 3 */}
                            <Transition
                                show={tab === 3}
                                appear={true}
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterFrom="opacity-0 translate-y-16"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-16"
                                beforeEnter={() => heightFix()}
                                unmount={false}
                            >
                                <div className="relative inline-flex flex-col">
                                    <div className="flex justify-center items-center w-16 h-16 bg-white rounded-full shadow flex-shrink-0 animate-pulse relative lg:top-36 lg:rotate-0 lg:right-5 rotate-90 md:right-44 right-10">
                                        <FontAwesomeIcon className="text-4xl" icon={faArrowLeft} />
                                    </div>
                                </div>
                            </Transition>
                            {/* Item 4 */}
                            <Transition
                                show={tab === 4}
                                appear={true}
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterFrom="opacity-0 translate-y-16"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-16"
                                beforeEnter={() => heightFix()}
                                unmount={false}
                            >
                                <div className="relative inline-flex flex-col">
                                    <div className="flex justify-center items-center w-16 h-16 bg-white rounded-full shadow flex-shrink-0 animate-pulse relative lg:top-52 lg:right-5 lg:rotate-0  rotate-90 md:right-28 -right-6">
                                        <FontAwesomeIcon className="text-4xl" icon={faArrowLeft} />
                                    </div>
                                </div>
                            </Transition>
                            {/* Item 5 */}
                            <Transition
                                show={tab === 5}
                                appear={true}
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterFrom="opacity-0 translate-y-16"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-16"
                                beforeEnter={() => heightFix()}
                                unmount={false}
                            >
                                <div className="relative inline-flex flex-col">
                                    <div className="flex justify-center items-center w-16 h-16 bg-white rounded-full shadow flex-shrink-0 animate-pulse relative lg:top-72 lg:rotate-0 lg:right-5 rotate-90 md:right-10 -right-16">
                                        <FontAwesomeIcon className="text-4xl" icon={faArrowLeft} />
                                    </div>
                                </div>
                            </Transition>
                            <Transition
                                show={tab === 6}
                                appear={true}
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterFrom="opacity-0 translate-y-16"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-16"
                                beforeEnter={() => heightFix()}
                                unmount={false}
                            >
                                <div className="relative inline-flex flex-col">
                                    <div className="flex justify-center items-center w-16 h-16 bg-white rounded-full shadow flex-shrink-0 animate-pulse relative lg:top-[23rem] lg:rotate-0 lg:right-5 rotate-90 md:-right-8 -right-32">
                                        <FontAwesomeIcon className="text-4xl" icon={faArrowLeft} />
                                    </div>
                                </div>
                            </Transition>
                            <Transition
                                show={tab === 7}
                                appear={true}
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterFrom="opacity-0 translate-y-16"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-16"
                                beforeEnter={() => heightFix()}
                                unmount={false}
                            >
                                <div className="relative inline-flex flex-col">
                                    <div className="flex justify-center items-center w-16 h-16 bg-white rounded-full shadow flex-shrink-0 animate-pulse relative lg:top-[27.5rem] lg:rotate-0 lg:right-5 rotate-90 md:-right-26 -right-52">
                                        <FontAwesomeIcon className="text-4xl" icon={faArrowLeft} />
                                    </div>
                                </div>
                            </Transition>

                            <Transition
                                show={tab === 8}
                                appear={true}
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterFrom="opacity-0 translate-y-16"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-16"
                                beforeEnter={() => heightFix()}
                                unmount={false}
                            >
                                <div className="relative inline-flex flex-col">
                                    <div className="flex justify-center items-center w-16 h-16 bg-white rounded-full shadow flex-shrink-0 animate-pulse relative lg:top-[32rem] lg:rotate-0 lg:right-5 rotate-90 md:-right-26 -right-52">
                                        <FontAwesomeIcon className="text-4xl" icon={faArrowLeft} />
                                    </div>
                                </div>
                            </Transition>

                        </div>
                    </div>
                </div>
                {/* Content */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-11 md:mt-6" >
                    <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                        <Typist cursor={{ show: false }}>
                            <h3 className="h3 mb-3">{t("DashbordWelcome")}<span className="relative z-10 border-0 text-black rounded-0  font-normal"> {username}</span></h3>
                        </Typist>
                        <p className="text-xl text-gray-600">{t("dashbordSubtitle")}</p>
                    </div>
                    {/* Tabs buttons */}
                    <div className="mb-8 md:mb-0">
                        <a
                            className={`flex items-center text-lg px-5 py-4 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? 'bg-white  shadow-md border-gray-200 hover:shadow-lg' : 'animate-pulse bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); setTab(1); }}
                        >
                            <div>
                                <div className="font-bold leading-snug tracking-tight mb-1">{t("BIEuserguide")}</div>
                                <div className="text-gray-600">{t("BIEuserguideP")}</div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                                <FontAwesomeIcon className="text-base" icon={faBook} />
                            </div>
                        </a>
                        <a
                            className={`flex items-center text-lg px-5 py-4 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'bg-white  shadow-md border-gray-200 hover:shadow-lg' : 'animate-pulse bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); setTab(2); }}
                        >
                            <div>
                                <div className="font-bold leading-snug tracking-tight mb-1">{t("MyResources")}</div>
                                <div className="text-gray-600">{t("myresourceP")}</div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                                <FontAwesomeIcon className="text-base" icon={faUserLock} />
                            </div>
                        </a>
                        <a
                            className={`flex items-center text-lg px-5 py-4 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'animate-pulse bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); setTab(3); }}
                        >
                            <div>
                                <div className="font-bold leading-snug tracking-tight mb-1">{t("SharedResources")}</div>
                                <div className="text-gray-600">{t("SharedResourceP")}</div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                                <FontAwesomeIcon className="text-base" icon={faShareNodes} />

                            </div>
                        </a>
                        <a
                            className={`flex items-center text-lg px-5 py-4 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 4 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'animate-pulse bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); setTab(4); }}
                        >
                            <div>
                                <div className="font-bold leading-snug tracking-tight mb-1">{t("AllResources")}</div>
                                <div className="text-gray-600">{t("publicResourceP")}</div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                                <FontAwesomeIcon className="text-base" icon={faGlobe} />
                            </div>
                        </a>
                        <a
                            className={`flex items-center text-lg px-5 py-4 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 5 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'animate-pulse bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); setTab(5); }}
                        >
                            <div>
                                <div className="font-bold leading-snug tracking-tight mb-1">{t("Bookmark")}</div>
                                <div className="text-gray-600">{t("DescriptionBookmarks")}</div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                                <FontAwesomeIcon className="text-base" icon={faBookBookmark} />
                            </div>
                        </a>
                        <a
                            className={`flex items-center text-lg px-5 py-4 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 6 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'animate-pulse bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); setTab(6); }}
                        >
                            <div>
                                <div className="font-bold leading-snug tracking-tight mb-1">{t("MyFolder")}</div>
                                <div className="text-gray-600">{t("MyFolderP")}</div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                                <FontAwesomeIcon className="text-base" icon={faFolder} />
                            </div>
                        </a>
                        <a
                            className={`flex items-center text-lg px-5 py-4 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 7 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'animate-pulse bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); setTab(7); }}
                        >
                            <div>
                                <div className="font-bold leading-snug tracking-tight mb-1">{t("Comments")}</div>
                                <div className="text-gray-600">{t("commentsP")}</div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                                <FontAwesomeIcon className="text-base" icon={faComments} />

                            </div>
                        </a>
                        <a
                            className={`flex items-center text-lg px-5 py-4 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 8 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'animate-pulse bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); setTab(8); }}
                        >
                            <div>
                                <div className="font-bold leading-snug tracking-tight mb-1">{t("analysis")}</div>
                                <div className="text-gray-600">{t("DescriptionDashboard")}</div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                                <FontAwesomeIcon className="text-base" icon={faDashboard} />

                            </div>
                        </a>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default DashbordGuid