import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getReview = async (review: any) => {
    try {
        let result = await api.post(Apis.getReview, review)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

const addReview = async (review: any) => {
    try {
        let result = await api.post(Apis.addReview, review)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const updateReview = async (review: any) => {
    try {
        let result = await api.post(Apis.updateReview, review)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const deleteReview = async (review: any) => {
    try {
        let result = await api.delete(Apis.deleteReview + review)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const deleteContentReview = async (review: any) => {
    try {
        let result = await api.delete(Apis.deleteContentReview, review)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

export default {
    getReview,
    addReview,
    updateReview,
    deleteContentReview,
    deleteReview
}