import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const addTags = async (tags, resourceId) => {
    try {
      const result = await api.post(Apis.addTags, { tags, resourceId });
      if (result.status !== 201) {
        return null;
      }
      return result.data;
    } catch (error) {
      AxiosErrorHandling(error);
      throw error;
    }
  };
  
  // Get resource tags
  const getResourceTags = async (resourceId) => {
    try {
      const result = await api.get(Apis.getResourceTags, {
        params: { resourceId }
      });
      if (result.status !== 200) {
        return null;
      }
      return result.data;
    } catch (error) {
      AxiosErrorHandling(error);
      throw error;
    }
  };
  // Get all tags this is for 
  const getAllTags = async (resourceId = null) => {
    try {
      const params = resourceId ? { params: { resourceId } } : {};
      const url =  Apis.getAllTags;
      const result = await api.get(url, params);
      
      if (result.status !== 200) {
        return null;
      }
      return result.data;
    } catch (error) {
      AxiosErrorHandling(error);
      throw error;
    }
  };
  // 
  const filterByTagName = async (tagName = null) => {
    try {
      const result = await api.get(Apis.filterByTagName, {
        params: { tagName }
      });
      if (result.status !== 200) {
        return null;
      }
      return result.data;
    } catch (error) {
      AxiosErrorHandling(error);
      throw error;
    }
  };
  // Remove a tag by name
  const removeTag = async (name, resourceId) => {
    try {
      const result = await api.delete(`${Apis.removeTags}/${name}`, { data: { resourceId } });
      if (result.status !== 200) {
        return null;
      }
      return result.data;
    } catch (error) {
      AxiosErrorHandling(error);
      throw error;
    }
  };
  
export default {
  addTags,
  getAllTags,
  getResourceTags,
  removeTag
};
