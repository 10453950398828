import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentService } from "../../../../services";
import ContentCard from "../../../ContentFile/ContentCard";
import NotFound from "../../../NotFound";
import ContentHeader from "../addon/contentHeader";
import interdesciplinary from '../../../assets/interdesciplinary.png';
import multiculture from '../../../assets/multiculture.png'


interface IContentListEntry {
  contentId: string;
  mainLibrary: string | undefined;
  title: string;
  originalNewKey?: string;
  userId: any;
}
const BieResources = () => {
  const { t } = useTranslation();

  const [publicContents, setPublicContents] = useState<IContentListEntry[]>();
  const [publicDemoContents, setPublicDemoContents] = useState<IContentListEntry[]>();

  const [currentContentId, setCurrentContentId] = useState("");
  const [displayToggle] = useState(false);
  const contentService = new ContentService();
  const getAllPublicResources = async () => {
    const response = await contentService.listBieResources();
    response && setPublicContents(response);
  };
  const getAllPublicDemoResources = async () => {
    const response = await contentService.listBieDemoResources();
    response && setPublicDemoContents(response);
  };
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep((prevStep) => (prevStep % 5) + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => ((prevStep - 2 + 5) % 5) + 1);
  };

  useEffect(() => {
    getAllPublicResources();
    getAllPublicDemoResources();
  }, []);

  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <div className="flex-1 px-2 sm:px-0">
      <div className="flex flex-wrap justify-between items-center">

        <div>
          <ContentHeader title={t("BIE GresLern Standard and User Guide")} />

          <div className="m-5 mx-auto p-16 sm:p-24 lg:p-20 bg-gray-200">
            <h1 className="w-full flex justify-center text-greslernorange text-2xl font-bold">
              {t("GreSLERN BIE APPROACH")}
            </h1>
            <div className="flex-1">
              {/* {step === 1 && (
                <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                  {t("Overview")}
                </div>
              )} */}
              {step === 1 && (
                <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                  {t("Introduction")}
                </div>
              )}
              {step === 2 && (
                <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                  {"Intraculturalism"}
                </div>
              )}
              {step === 3 && (
                <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                  {t("Transdisciplinarity")}
                </div>
              )}
              {step === 4 && (
                <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                  {t("Dialecticism")}
                </div>
              )}
              {step === 5 && (
                <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                  {t("Contextuality")}
                </div>
              )}
              {step === 7 && (
                <div className="text-lg font-bold text-greslernorange leading-tight uppercase">
                  {t("")}
                </div>
              )}
            </div>

            {/* {step === 1 && (<div className="relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl" style={{ minHeight: '19rem' }}>
              <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg" style={{ minHeight: '19rem' }}>
                <img className="absolute inset-0 w-full h-full object-cover object-center" src={interdesciplinary} alt="" />
                <div className="absolute inset-0 w-full h-full bg-orange-900 opacity-75"></div>
                <div className="absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white">

                </div>
              </div>
              <div className="w-full md:w-3/5 h-full flex items-center bg-gray-100 rounded-lg">
                <div className="p-12 md:pr-24 md:pl-16 md:py-12">
                  <p className="leading-relaxed first-letter:text-3xl">
                    This module is divided into four phases. In each phase
                    , you will learn and discover something new.
                  </p>
                  Learning outcomes:
                  <div className="flex">
                    <div className="flex-2">
                      <h1></h1>                                    <a
                        href="#"
                        className=" underline underline-offset-8 decoration-greslernblue  font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                      >
                        Explore
                      </a>{" "}

                      <ul className="list-inside">
                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Explore online content
                        </li>
                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Accomplish asynchronous learning activities
                        </li>
                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Share resources,skills ,and experiences
                        </li>

                      </ul>
                      <h1></h1>                                    <a
                        href="#"
                        className=" underline underline-offset-8 decoration-greslernblue  font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                      >
                        Deliberate
                      </a>{" "}

                      <ul className="list-inside">
                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Share your thoughts and insights about a particular topic with co-learners
                        </li>
                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Discuss topics that interest you in an ethical and scientific manner
                        </li>
                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Pose provocative and critical questions
                        </li>

                      </ul>
                      <h1></h1>                                    <a
                        href="#"
                        className=" underline underline-offset-8 decoration-greslernblue  font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                      >
                        Validate
                      </a>{" "}

                      <ul className="list-inside">
                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Apply your knowledge, and demonstrate your understanding through quizzes, case studies, and examples
                        </li>


                      </ul>
                      <h1></h1>                                    <a
                        href="#"
                        className=" underline underline-offset-8 decoration-greslernblue  font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                      >
                        Reflect
                      </a>{" "}

                      <ul className="list-inside">

                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Deepen knowledge by further reflection and study
                        </li>
                        <li className="flex">
                          <svg
                            className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          Expand your community of learners
                        </li>

                      </ul>
                    </div>
                  </div>

                </div>
                <svg className="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-gray-100 -ml-12" viewBox="0 0 100 100" preserveAspectRatio="none">
                  <polygon points="50,0 100,0 50,100 0,100" />
                </svg>
              </div>
              <button className="absolute top-0 mt-32 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline"
                onClick={handlePrevStep}>
                <span className="block" style={{ transform: 'scale(-1)' }}>&#x279c;</span>
              </button>
              <button className="absolute top-0 mt-32 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline"
                onClick={handleNextStep}>
                <span className="block" style={{ transform: 'scale(1)' }}>&#x279c;</span>

              </button>
            </div>)} */}
            {step === 1 && (<div className="relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl" style={{ minHeight: '19rem' }}>
              <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg" style={{ minHeight: '19rem' }}>
                <img className="absolute inset-0 w-full h-full object-cover object-center" src={interdesciplinary} alt="" />
                <div className="absolute inset-0 w-full h-full bg-orange-900 opacity-75"></div>
                <div className="absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white">

                </div>
              </div>
              <div className="w-full md:w-3/5 h-full flex items-center bg-gray-100 rounded-lg">
                <div className="p-12 md:pr-24 md:pl-16 md:py-12">
                  <p className="leading-relaxed first-letter:text-3xl">
                  {t(`IntroductionD`)}{" "}
                    <a
                      href="#"
                      className="underline underline-offset-8 decoration-greslernblue font-bold  text-greslernorange hover:!bg-greslernorange hover:!text-white"
                    >
                      {t("Transdisciplinarity")}
                    </a>{" "}
                    ,
                    <a
                      href="#"
                      className="underline underline-offset-8 decoration-greslernblue font-bold text-greslernorange hover:!bg-greslernorange hover:!text-white"
                    >
                      {t("Intraculturalism")}
                    </a>
                    ,{" "}
                    <a
                      href="#"
                      className="underline underline-offset-8 decoration-greslernblue font-bold text-greslernorange hover:!bg-greslernorange hover:!text-white"
                    >
                      {t("Dialecticism")}
                    </a>{" "}
                    {t("and")}{" "}
                    <a className="underline underline-offset-8 decoration-greslernblue font-bold text-greslernorange hover:!bg-greslernorange hover:!text-white">
                    {t("Contextuality")}
                    </a>
                    , in curricula and learning contexts whether it's
                    content, format, or methodologies.
                  </p>

                </div>
                <svg className="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-gray-100 -ml-12" viewBox="0 0 100 100" preserveAspectRatio="none">
                  <polygon points="50,0 100,0 50,100 0,100" />
                </svg>
              </div>
              <button className="absolute top-0 mt-32 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline"
                onClick={handlePrevStep}>
                <span className="block" style={{ transform: 'scale(-1)' }}>&#x279c;</span>
              </button>
              <button className="absolute top-0 mt-32 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline"
                onClick={handleNextStep}>
                <span className="block" style={{ transform: 'scale(1)' }}>&#x279c;</span>

              </button>
            </div>)}
            {step === 2 && (<div className="relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl" style={{ minHeight: '19rem' }}>
              <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg" style={{ minHeight: '19rem' }}>
                <img className="absolute inset-0 w-full h-full object-cover object-center" src={multiculture} alt="" />
                <div className="absolute inset-0 w-full h-full bg-orange-900 opacity-75"></div>
                <div className="absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white">

                </div>
              </div>
              <div className=" font-satoshi indent-8 text-lg">
                <p className="leading-relaxed first-letter:text-3xl">
                  <a
                    href="#"
                    className=" underline underline-offset-8 decoration-greslernblue  font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                  >
                    {t("Intraculturalism")}
                  </a>{" "}
                  is an approach based upon in-depth cultural
                  introspection for a more complete understanding of
                  inter-indebtedness and interdependence of culture.
                </p>
                {/* <ul className="mt-2 ml-10 list-inside list-decimal"> */}
                <ul className="list-inside">
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Create awareness of the multiplicity of global
                    historical, cultural, and social narrative.
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Create awareness of cultural inter-indebtedness
                    and interdependence.
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Create awareness of the evolving nature of culture
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Create awareness of endogenous and exogenous
                    dimensions of culture
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Create awareness of the plurality of narratives
                    and customs as integral to national culture
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Use mother tongue as a medium for education.
                  </li>
                </ul>
              </div>
              <button className="absolute top-0 mt-32 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline"
                onClick={handlePrevStep}>
                <span className="block" style={{ transform: 'scale(-1)' }}>&#x279c;</span>
              </button>
              <button className="absolute top-0 mt-32 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline"
                onClick={handleNextStep}>
                <span className="block" style={{ transform: 'scale(1)' }}>&#x279c;</span>

              </button>
            </div>)}
            {step === 3 && (
              <div className="relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl" style={{ minHeight: '19rem' }}>
                <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg" style={{ minHeight: '19rem' }}>
                  <img className="absolute inset-0 w-full h-full object-cover object-center" src={interdesciplinary} alt="" />
                  <div className="absolute inset-0 w-full h-full bg-orange-900 opacity-75"></div>
                  <div className="absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white">

                  </div>
                </div>
                <div className="font-satoshi indent-8 text-lg">
                  <p className="leading-relaxed first-letter:text-3xl">
                    <a
                      href="#"
                      className="underline underline-offset-8 decoration-greslernblue font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                    >
                      {t("Transdisciplinarity")}
                    </a>{" "}
                    is an integrative, multi-perspective approach based
                    up on interconnecting both academic as well as
                    non-academic knowledge domains of complex and
                    holistic understanding of the world.
                  </p>
                  {/* <ul className="mt-2 ml-10 list-inside list-decimal"> */}
                  <ul className="list-inside">
                    <li className="flex">
                      <svg
                        className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      Engage multistakeholder, multi-sectoral and
                      transdisciplinary partnerships and collaboration
                      which produce knowledge and engage with
                      imperatives facing the world.
                    </li>
                    <li className="flex">
                      <svg
                        className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      Encourage mutual learning in communities which
                      acknowledge, value and amplify their respective
                      knowledge.
                    </li>
                    <li className="flex">
                      <svg
                        className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      Overcome disciplinary and fragmented teaching and
                      learning by integrating the interaction of
                      academic and non-academic knowledge domains into
                      curricula and assessment and introduce inter and
                      non-disciplinary thematic subjects into curricula.
                    </li>
                    <li className="flex">
                      <svg
                        className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      Make knowledge and the world external to formal
                      education settings quintessential to the education
                      process and design educational initiatives, in
                      collaboration with marginalised communities.
                    </li>
                  </ul>
                </div>
                <button className="absolute top-0 mt-32 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline"
                  onClick={handlePrevStep}>
                  <span className="block" style={{ transform: 'scale(-1)' }}>&#x279c;</span>
                </button>
                <button className="absolute top-0 mt-32 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline"
                  onClick={handleNextStep}>
                  <span className="block" style={{ transform: 'scale(1)' }}>&#x279c;</span>

                </button>
              </div>)}
            {step === 4 && (<div className="relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl" style={{ minHeight: '19rem' }}>
              <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg" style={{ minHeight: '19rem' }}>
                <img className="absolute inset-0 w-full h-full object-cover object-center" src={interdesciplinary} alt="" />
                <div className="absolute inset-0 w-full h-full bg-orange-900 opacity-75"></div>
                <div className="absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white">
                </div>
              </div>
              <div className="font-satoshi indent-8 text-lg">
                <p className="leading-relaxed first-letter:text-3xl">
                  <a
                    href="#"
                    className=" underline underline-offset-8 decoration-greslernblue font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                  >
                    {t("Dialecticism")}
                  </a>{" "}
                  an interactional and synergetic approach based upon
                  problem-posing dialogue and critical exchange, for
                  free and critical thinking through the proactive
                  participation of learners.
                </p>
                {/* <ul className="mt-2 ml-10 list-inside list-decimal"> */}
                <ul className="list-inside">
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Educators are mediators and facilitators who
                    foster an education process
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Learners are encouraged to become co-creators of
                    their own education, engaging with and producing
                    knowledge in dialogue and critical exchange with
                    peers and educators
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Learners are grounded in interactive dialogue,
                    thoughtful introspection, critical exchange and
                  </li>
                  <li>
                    Collective reflection for personal development.
                    Learners, individually and collectively, are
                    empowered to construct their worldviews and
                    question existing societal norms
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Learners are provided with safe educational spaces
                    to meet with educators and community members to
                    discuss, critique, and exchange diverse views
                    about the world
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Local community concerns and issues are embedded
                    in and made integral to the educational process
                  </li>
                </ul>
              </div>
              <button className="absolute top-0 mt-32 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline"
                onClick={handlePrevStep}>
                <span className="block" style={{ transform: 'scale(-1)' }}>&#x279c;</span>
              </button>
              <button className="absolute top-0 mt-32 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline"
                onClick={handleNextStep}>
                <span className="block" style={{ transform: 'scale(1)' }}>&#x279c;</span>
              </button>
            </div>)}
            {step === 5 && (<div className="relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl" style={{ minHeight: '19rem' }}>
              <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg" style={{ minHeight: '19rem' }}>
                <img className="absolute inset-0 w-full h-full object-cover object-center" src={interdesciplinary} alt="" />
                <div className="absolute inset-0 w-full h-full bg-orange-900 opacity-75"></div>
                <div className="absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white">

                </div>
              </div>
              <div className="font-satoshi indent-8 text-lg">
                <p className="leading-relaxed first-letter:text-3xl">
                  <a
                    href="#"
                    className=" underline underline-offset-8 decoration-greslernblue font-bold  text-greslernorange hover:bg-greslernorange hover:text-white "
                  >
                    Contextuality
                  </a>{" "}
                  is a context-centred approach based upon the
                  integration and adaptation to the realties, and
                  frameworks of learners, to develop their sense of
                  co-ownership and co-creation.
                </p>

                {/* <ul className="mt-2 ml-10 list-inside"> */}
                <ul className="list-inside">
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Encourage open educational institutions to develop
                    solutions adapted to local realities, concerns,
                    and challenges
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Structurally embed cooperation with local
                    communities, institutions and civil society
                    organizations
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>{" "}
                    Adapt education to learners' socio-cultural
                    contexts as a basis for their co- ownership.
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>{" "}
                    Adapt education to interpretative frameworks and
                    the individuality of learners
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>{" "}
                    Regularly update curricula and make them more
                    flexible, adapted to real needs and relevant to
                    real-life
                  </li>
                  <li className="flex">
                    <svg
                      className="mt-2 ml-10 w-3.5 h-3.5 me-0 text-greslernorange dark:text-greslernorange flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>{" "}
                    Tailor and adapt education to local and individual
                    contexts through local participation and
                    interaction.
                  </li>
                </ul>
              </div>
              <button className="absolute top-0 mt-32 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline"
                onClick={handlePrevStep}>
                <span className="block" style={{ transform: 'scale(-1)' }}>&#x279c;</span>
              </button>
              <button className="absolute top-0 mt-32 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline"
                onClick={handleNextStep}>
                <span className="block" style={{ transform: 'scale(1)' }}>&#x279c;</span>
              </button>
            </div>)}
            {/* Carousel Tabs */}
            <div className="flex items-center pt-5 justify-between">
              <button className="px-2 opacity-50 hover:opacity-100 focus:opacity-100"><img className="w-full" src="https://stripe.com/img/v3/payments/overview/logos/kickstarter.svg" alt="" style={{ maxHeight: '60px' }} /></button>
              <button className="px-2 opacity-50 hover:opacity-100 focus:opacity-100"><img className="w-full" src="https://stripe.com/img/v3/payments/overview/logos/slack.svg" alt="" style={{ maxHeight: '60px' }} /></button>
              <button className="px-2 opacity-50 hover:opacity-100 focus:opacity-100"><img className="w-full" src="https://stripe.com/img/v3/payments/overview/logos/glossier.svg" alt="" style={{ maxHeight: '60px' }} /></button>
              <button className="px-2 opacity-50 hover:opacity-100 focus:opacity-100"><img className="w-full" src="https://stripe.com/img/v3/payments/overview/logos/charity_water.svg" alt="" style={{ maxHeight: '60px' }} /></button>
              <button className="px-2 opacity-100 hover:opacity-100 focus:opacity-100"><img className="w-full" src="https://stripe.com/img/v3/payments/overview/logos/missguided.svg" alt="" style={{ maxHeight: '60px' }} /></button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-between items-center">

        <ContentHeader title={t("BieDemos")} />
        <button
          onClick={handleRefreshClick}
          className="ml-2 text-yellow-900 focus:outline-none transform hover:rotate-90 transition duration-300 ease-in-out text-3xl font-bold"
          title={t("Refresh")}>
          ↻
        </button>
        <div className="inline-flex items-center space-x-2">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>

          </div>
        </div>
      </div>
      <ul className="mb-10 sm:mb-0 mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 3xl:grid-cols-10">
        {publicContents?.map((content, k) => (
          <div
            className={
              k == 0
                ? "animate-fadeInRt3"
                : k == 1
                  ? "animate-fadeInRt2"
                  : k == 2
                    ? "animate-fadeInRt1"
                    : "animate-fadeInRt"
            }>
            <ContentCard
              key={`${k}bieresources`}
              content={content}
              displayToggle={displayToggle}
              setCurrentContentId={setCurrentContentId}
              updateFolder={() => alert("updateFolder")}
              contentType="public"
            />
          </div>
        ))}
        {publicContents?.length == 0 && <NotFound />}
      </ul>
    </div>

  );
};
export default BieResources;
