import { Apis, toastOpt } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";
import { toast } from "react-toastify";

const GetAllSubscribersStat = async () => {
  try {
    const response = await api.get(Apis.Subscriber + "/getAllStat");
    if (response.status === 200) {
      return response.data;
    } else return undefined

  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const GetAllSubscribers = async () => {
  try {
    const response = await api.get(Apis.Subscriber + "/getAll");
    if (response.status === 200) {
      return response.data;
    } else return undefined
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const RegisterSubscriber = async (email) => {
  try {
    const response = await api.post(Apis.Subscriber + "/subscribe", {
      email: email
    });
    if (response.status === 200) {
      toast.success(response.data.message, toastOpt);
    } else if (response.status === 201 || response.status === 202) {
      toast.warning(response.data.message, toastOpt)
    } else toast.error(response.data.message, toastOpt)
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const ConfirmSubscriber = async (token) => {
  try {
    const response = await api.get(Apis.Subscriber + "/confirmSubscription/" + token);
    if (response.status === 200) {
      toast.success(response.data.message, toastOpt);
    } else if (response.status === 201 || response.status === 202) {
      toast.warning(response.data.message, toastOpt)
    } else toast.error(response.data.message, toastOpt)
    return response

  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const Unsubscribe = async (email) => {
  try {
    const response = await api.post(Apis.Subscriber + "/unsubscribe/", {
      email: email
    });
    if (response.status === 200) {
      toast.success(response.data.message, toastOpt);
    } else if (response.status === 201 || response.status === 202) {
      toast.warning(response.data.message, toastOpt)
    } else toast.error(response.data.message, toastOpt)
    return response

  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};

const SendAnnouncement = async (title, message) => {
  const response = await api.post(Apis.Subscriber + "/sendAnnouncement", {
    title: title,
    message: message
  })
  if (response.status === 200) {
    toast.success(response.data.message, toastOpt);
  } else if (response.status === 201 || response.status === 202) {
    toast.warning(response.data.message, toastOpt)
  } else toast.error(response.data.message, toastOpt)
  return response

}

export default {
  GetAllSubscribersStat,
  GetAllSubscribers,
  RegisterSubscriber,
  ConfirmSubscriber,
  SendAnnouncement,
  Unsubscribe
}