
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentSectionUi from "../../CommentSectionUi";
import { ContentService } from "../../../services/ContentService";
import { H5PPlayerUI } from "@lumieducation/h5p-react";

import NotificationSettings from "../../../services/NotificationSettings";
import { TwinSpin } from "react-cssfx-loading";
Modal.setAppElement("#root"); // Set the root element for accessibility

const ContentPlay = (props: { contentId?: any; onClose?: any; }) => {
  const { contentId, onClose } = props;
  const [isModalOpen, setModalOpen] = useState(true);
  const [content, setContent] = useState() as any;
  const h5pPlayer = useRef(null);
  const contentService = new ContentService();
  const [isContentLoading, setContentLoading] = useState(true);

  useEffect(() => {
    // Load content when the component mounts
    getContentMeta();
    contentService.getPlay(contentId).then((response) => {
      setContentLoading(false);
    });
    // NotificationSettings.updateViews(contentId);

  }, [contentId]);

  const getContentMeta = async () => {
    const c = await contentService.getContentMeta(contentId)
    // console.log("t : "+ JSON.stringify(c))
    setContent(c)
  }

  const closeModal = () => {
    setModalOpen(false);
    onClose(); // Call the onClose function passed via props
  };

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Content Play Modal" >
      <div className="flex-1 px-2 sm:px-0 pt-[35px] h-full ">
        <div className="flex flex-wrap justify-between items-center">
          <h3 className="text-3xl text-yellow-900">
            Playing Content <small>{content?.title}</small>
          </h3>
          <div className="inline-flex items-center space-x-2">
            <div className="inline-flex rounded-lg border border-gray-100 bg-gray-100 p-1">
              <button
                onClick={closeModal}
                className="inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative bg-greslernorange"
              >
                <FontAwesomeIcon icon={faClose} /> Close
              </button>
            </div>
          </div>
        </div>

        <div className="pt-2">
          {isContentLoading ? (
            <div className="w-full h-1/2 flex justify-center items-center">
              <TwinSpin color="#FF0000" width="100px" height="100px" duration="3s" />
            </div>
          ) : (
            <H5PPlayerUI
              ref={h5pPlayer}
              contentId={contentId}
              loadContentCallback={contentService.getPlay}
            // onxAPIStatement={(statement, context, event) =>
            //   // console.log(statement, context, event)
            // }
            />
          )}
        </div>
        <CommentSectionUi resourceId={contentId} showcommentlist={true} reqFromlanding={true} />
      </div>
    </Modal>
  );
};

ContentPlay.propTypes = {
  contentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContentPlay;
