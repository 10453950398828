import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './Reducer/authSlice';
import socketReducer from './Reducer/socketSlice'

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['isAuthenticated', 'username', 'email', 'userId', 'role', 'isFirstLog','institute'],
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    socket: socketReducer,
  },
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export { persistor }