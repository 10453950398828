import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import {
    faFingerprint,
    faCommenting,
    faEdit,
    faBook,
    faEye,
    faBookBookmark
} from '@fortawesome/free-solid-svg-icons';
import { Menu, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';

import { selectUserName } from '../../../redux/Reducer/authSlice';
import ContentPlay from '../ContentPlay';
import { CommentCounter, GetResource, GetResourceShared } from '../../../services';
import ContentPlayerUI from '../ContentComment';
import Loader from '../../loader';
import ToolTip from '../../tooltip';
import { Tilt } from "react-tilt"
import NotificationSettings from '../../../services/NotificationSettings';


function PlayContentModal({ t, contentService, data, generateDownloadLink, generateHtmlDownloadLink }) {
    const [editing, setEditing] = useState(data.contentId === 'new');
    const [isContent, setIsContent] = useState(false);
    const [metaresource, setMetaresource] = useState('');
    const [comment, setComment] = useState(false);
    const [databyresource, setDatabyresource] = useState(0);

    const userId = useSelector(selectUserName);
    const [isHovered, setIsHovered] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [listLikes, setListLikes] = useState([]);
    const [listTags, setlistTags] = useState([]);
    const [countComment, setCountComment] = useState(0);
    const [countDownloads, setcountDownloads] = useState(0);

    const [totalLikes, setTotalLikes] = useState(0); // State to manage total likes

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const renderLikeList = () => (
        <div className="bg-white max-h-[200px] h-auto p-[5px] overflow-y-auto absolute absolute left-0 z-10 mt-3  origin-top-right" >
            <ul>
                {listLikes.likesCount.map((like, index) => (
                    <li key={index} className="p-2 flex items-center ">
                        <div className="w-8 h-8 mr-2 items-center rounded-full border-dashed border-2 border-greslernorange text-greslernorange text-2xl text-center">
                            {like.username?.charAt(0).toUpperCase()}
                        </div>
                        {like.username}
                    </li>
                ))}
            </ul>
        </div>
    );
    const renderTagList = () => (
        <div className="bg-white max-h-[200px] h-auto p-[5px] overflow-y-auto absolute absolute left-0 z-10 mt-3  origin-top-right" >
            <ul>
                {listLikes.likesCount.map((like, index) => (
                    <li key={index} className="p-2 flex items-center ">
                        <div className="w-8 h-8 mr-2 items-center rounded-full border-dashed border-2 border-greslernorange text-greslernorange text-2xl text-center">
                            {like.username.charAt(0).toUpperCase()}
                        </div>
                        {like.username}
                    </li>
                ))}
            </ul>
        </div>
    );

    const fetchLikesList = async (resourceId) => {
        let data = await NotificationSettings.getLikes(resourceId)
        if (data) {
            setListLikes(data);
            setTotalLikes(data.likesCount.length)
        }

    }

    const fetchTagsList = async (resourceId) => {
        let data = await NotificationSettings.getTagsList(resourceId)
        if (data) {
            setlistTags(data);

        }
    }

    const fetchCommentCounter = async (resourceId) => {
        let data = await CommentCounter.getcommentcount(resourceId)
        if (data) {
            setCountComment(data);
        }

    }
    const fetchTotalDownloads = async (resourceId) => {
        let data = await NotificationSettings.getTotalDownloads(resourceId)
        if (data) {
            setcountDownloads(data.totalDownloads);
        }
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const getMainLibrary = () => {
        let test = data.mainLibrary;
        return test;
    }
    useEffect(() => {
        countNumberofShare();
        const { contentId } = data;
        fetchByResourceId(contentId);
        fetchLikesList(data.contentId);
        fetchTagsList(data.contentId);
        fetchCommentCounter(data.contentId);
        fetchTotalDownloads(data.contentId);

    }, [data]);

    const downloadH5P = () => {
        NotificationSettings.updateDownloadsCount(data.contentId);

        setIsContent(true);
        const url = generateDownloadLink(data.contentId);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${data.contentId}.h5p`;
        document.body.appendChild(a);
        a.click();
        setIsContent(false);
    };

    const downloadHtml = () => {
        NotificationSettings.updateDownloadsCount(data.contentId);

        setIsContent(true);
        const url = generateHtmlDownloadLink(data.contentId);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${data.contentId}.html`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            setIsContent(false);
        }, 3000);
    };

    const fetchByResourceId = async (contentId) => {
        try {
            let data = await GetResource.getresourceById(contentId);
            if (data) {
                fetchmetaresource(data);
            } else {
                console.log('error when fetch');
            }
        } catch (error) {
            console.error('Error fetching resource by ID:', error);
        }
    };

    const countNumberofShare = async () => {
        try {
            let data = await GetResourceShared.getresourceshared();
            if (data === null) {
                datatoStore(0);
            } else {
                datatoStore(data.length);
            }
        } catch (error) {
            console.error('Error counting shared resources:', error);
        }
    };

    const datatoStore = (jsonData) => {
        setDatabyresource(jsonData);
    };

    const fetchmetaresource = (data) => {
        setMetaresource(data[0].username);
    };

    const isNew = () => {
        return data.contentId === 'new';
    };

    const handleContentIdChange = (newContentId) => {
        // Handle content ID change if needed
    };


    return (
        <>
            {isContent ? <Loader /> : null}
            {!isNew() ? (
                <Tilt
                    options={{
                        max: 45,
                        scale: 1,
                        speed: 450
                    }}
                    className='shadow-lg transition-transform transform scale-100 hover:scale-110 rounded-2x1 h-full'
                >
                    <div className="relative group h-full bg-gray-100 py-10 sm:py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:bg-gray-900/80 hover:smooth-hover">
                        <img
                            className="animate-tada w-20 h-20 object-cover object-center rounded-full group-before:collapse group-hover:text-white group-hover:visible group-hover:animate- hover:animate-none bg-gradient-to-r from-cyan-500 to-blue-500"
                            src="/GreSLERN-content-play-button.png"
                            alt="cuisine"
                            onClick={openModal}
                        />

                        <Modal
                            style={{ zIndex: 10 }}
                            isOpen={isModalOpen}
                            onRequestClose={closeModal}
                            contentLabel="Content Play Modal"
                        >
                            <ContentPlay contentId={data.contentId} onClose={closeModal} />
                        </Modal>

                        <h6 className="text-black group-hover:text-white text-2xl font-bold capitalize text-center">
                            <FontAwesomeIcon icon={faBook} className="me-1" />
                            {data.title.substring(0, 15)}...
                        </h6>
                        <p className="text-sm text-black/50 group-hover:text-white/50">
                            {getMainLibrary()?.slice(4,)}
                        </p>
                        <p className="text-sm text-black/50 group-hover:text-white/50">
                            <FontAwesomeIcon icon={faBookBookmark} className="me-1" />
                            {metaresource}...
                        </p>
                        <p className="text-sm text-black/50 group-hover:text-white/50">
                            {/* Additional data */}
                        </p>

                        <div className="absolute bottom-2 items-center">
                            <div className="flex gap-3 m-2">
                                <ToolTip tooltip={t('Download')}>
                                    <Menu as="div" className="relative inline-block text-left h-full w-full">
                                        <div>
                                            <Menu.Button className="w-full h-full flex leading-5">
                                                <div className="flex leading-5">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 28" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                                    {/* <FontAwesomeIcon icon={faDownload} className="w-5 h-5 mr-1" /> */}
                                                </div>
                                            </Menu.Button>
                                            <p className="text-xs text-gray-500  ">{countDownloads}</p>
                                        </div>



                                        <Transition
                                            as={React.Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute left-0 z-10 mt-1 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                type="button"
                                                                className={`${active ? 'bg-[#FF6347] text-white' : 'text-gray-700'
                                                                    } block w-full px-4 py-2 text-left text-sm`}
                                                                onClick={downloadHtml}
                                                            >
                                                                {t('AsHTML')}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                type="button"
                                                                className={`${active ? 'bg-[#FF6347] text-white' : 'text-gray-700'
                                                                    } block  px-4 py-2 text-left text-sm `}
                                                                onClick={downloadH5P}
                                                            >
                                                                {t('ForEditor')}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>

                                    </Menu>
                                </ToolTip>

                                {/* <ToolTip tooltip={t('Share')}>
                                <button
                                    className="w-8 h-8 flex items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                    onClick={() => {
                                        generateShareLink(data.contentId);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faShareAlt} className="w-5 h-5" />
                                </button>
                            </ToolTip> */}



                                {/* <ToolTip tooltip={t('Clone')}>
                                <button
                                    className="w-8 h-8 flex items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                    onClick={() => {
                                        handleClone(data);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faClone} className="w-5 h-5" />
                                </button>
                            </ToolTip> */}
                                <div className='relative inline-block text-left h-full w-full'
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                    <ToolTip tooltip={t('Likes')}>
                                        <button className="w-0 h-2 flex leading-5 items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                        ></button>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>

                                    </ToolTip>
                                    {isHovered && renderLikeList()}
                                    <p className="text-xs text-gray-500 "> {totalLikes}</p>

                                </div>

                                <div className='relative inline-block text-left h-full w-full'>
                                    <ToolTip tooltip={t('Tags')}>
                                        <button className="w-0 h-2 flex items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                        ></button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 22" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-share-2"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>

                                    </ToolTip>
                                    <p className="text-xs text-gray-500	 ">{listTags}</p>
                                </div>
                                <div className='relative inline-block text-left h-full w-full'>

                                    <ToolTip tooltip={t('Feedback')}>
                                        <button
                                            className="w-5 h-5 flex items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                            onClick={() => {
                                                setComment(!comment);
                                            }}
                                            disabled
                                        >

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 22" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>                                    </button>

                                    </ToolTip>
                                    <p className="text-xs text-gray-500 ">{countComment}</p>
                                </div>


                            </div>
                        </div>
                    </div>
                </Tilt>
            ) : (
                <div className="relative group bg-gray-100 py-10 sm:py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:bg-gray-900/80 hover:smooth-hover">
                    <div>
                        <ContentPlay contentId={data.contentId} onClose={handleContentIdChange} />
                    </div>
                    <h6 className="text-black group-hover:text-white text-2xl font-bold capitalize text-center">
                        <FontAwesomeIcon icon={faBook} className="me-1" />
                        {data.title.substring(0, 30)}
                    </h6>
                    <p className="text-sm text-black/50 group-hover:text-white/50">
                        <FontAwesomeIcon icon={faFingerprint} className="me-1" />
                        {data.contentId}
                    </p>
                    <p className="text-sm text-black/50 group-hover:text-white/50">
                        {/* Additional data */}
                    </p>

                    <div className="absolute bottom-2 items-center">
                        <div className="flex gap-3 m-2">
                            <ToolTip tooltip={t('Edit')}>
                                <button
                                    className="w-8 h-8 flex items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                    onClick={() => {
                                        setEditing(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} className="w-5 h-5" />
                                </button>
                            </ToolTip>

                            {/* <ToolTip tooltip={t('Delete')}>
                                <button
                                    className="w-8 h-8 flex items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                    onClick={() => {
                                        handleDelete(data);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faWindowClose} className="w-5 h-5" />
                                </button>
                            </ToolTip> */}

                            <ToolTip tooltip={t('Feedback')}>
                                <button
                                    className="w-8 h-8 flex items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                    onClick={() => {
                                        setComment(!comment);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCommenting} className="w-5 h-5" />
                                </button>
                            </ToolTip>

                            <ToolTip tooltip={t('View')}>
                                <button
                                    className="w-8 h-8 flex items-center justify-center hover:text-[#FF6347] transition-colors duration-300"
                                    onClick={() => {
                                        fetchByResourceId(data.contentId);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEye} className="w-5 h-5" />
                                </button>
                            </ToolTip>
                        </div>
                    </div>
                </div>
            )}

            {comment && (
                <ContentPlayerUI
                    userId={userId}
                    contentId={data.contentId}
                    contentService={contentService}
                    onClose={() => setComment(false)}
                />
            )}
        </>
    );
}

export default withTranslation()(PlayContentModal);
