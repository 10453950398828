import { useState } from "react";
import BIEAssessmentScoring from "../../Radio/BIEAssessmentScoring";
import { Slide, toast } from "react-toastify";
import { ContentService } from "../../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectEmail } from "../../../redux/Reducer/authSlice";

const initialResult = {
  intraculturalism: { q1: 0, q2: 0, q3: 0, q4: 0, q5: 0, q6: 0, total: 0 },
  transdisciplinarity: { q1: 0, q2: 0, q3: 0, q4: 0, total: 0 },
  dialecticism: { q1: 0, q2: 0, q3: 0, q4: 0, q5: 0, q6: 0, total: 0 },
  contextuality: {
    q1: 0, q2: 0, q3: 0, q4: 0, q5: 0, q6: 0, total: 0
  },
  score: 0
}

const BIEAssessment = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const contentId = location.state.contentId;
  const isPublicChecked = location.state.isPublicChecked;
  const BIEmail = useSelector(selectEmail)

  const [step, setStep] = useState(1);
  const [intraculturalismScore, setIntraculturalismScore] = useState(0);
  const [transdisciplinaryScore, setTransdisciplinaryScore] = useState(0);
  const [dialecticismScore, setDialecticismScore] = useState(0);
  const [contextuallyScore, setContextuallyScore] = useState(0);

  const [questionsResult, setQuestionsResult] = useState(initialResult)

  const handleQuestionResult = (q, a) => {
    let type = 'intraculturalism'
    switch (step) {
      case 2:
        type = 'transdisciplinarity';
        break;
      case 3:
        type = 'dialecticism';
        break;
      case 4:
        type = 'contextuality';
        break;
      default:
      // Handle the default case, for example, do nothing
    }
    setQuestionsResult({
      ...questionsResult,
      [type]: {
        ...questionsResult[type],
        [q]: parseInt(a)
      }
    })
  }

  const contentService = new ContentService();

  const handlePass = () => {
    handlePublic(contentId, isPublicChecked);
  }

  const handlePublic = async (contentId: string, isPublicChecked: boolean) => {
    
    try {
      let newStatus = isPublicChecked ? "public" : "private";
      //  {  BIEmail === process.env.REACT_APP_BIE_MAIL &&(
        // const BIEmail =useSelector(selectEmail)
      if( BIEmail === process.env.REACT_APP_BIE_MAIL ){
        newStatus = isPublicChecked ? "bie" : "private"
      }
      await contentService.updateContentStatus(contentId, newStatus);

      toast.success(
        `Content status successfully changed to '${newStatus}' !`,
        {
          position: "top-right",
          transition: Slide,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
        }
      );
      navigate(-1)
    } catch (error) {
      console.error("Error updating content status:", error);
    }
  };


  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <div className="flex ">
      <div className="bg-white p-6 rounded-lg shadow-md w-full">
        <div className="">
          <h2 className="text-lg text-greslernorange font-medium mb-2">
            {t("MATERIALS AND CURRICULUM SELF-ASSESSMENT")}
          </h2>
          <div className=" text-sm text-black-800 p-2 ">
            <p>
              {t("BIE Scorring representation")}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row rounded-lg">
          <div
            className={`text-greslernorange border-r border-greslernorange rounded-t-lg ${step ===
              1
              ? "!bg-greslernorange text-white"
              : "bg-gray-200"} p-2 text-center cursor-pointer`}
            onClick={() => setStep(1)}
          >
            {t("INTRACULTURALISM")}
          </div>
          <div
            className={`text-greslernorange border-r border-greslernorange md:rounded-t-lg ${step ===
              2
              ? "!bg-greslernorange text-white"
              : "bg-gray-200"} p-2 text-center cursor-pointer`}
            onClick={() => setStep(2)}
          >
            {t("TRANSDISCIPLINARITY")}
          </div>
          <div
            className={`text-greslernorange border-r border-greslernorange md:rounded-t-lg ${step ===
              3
              ? "!bg-greslernorange text-white"
              : "bg-gray-200"} p-2 text-center cursor-pointer`}
            onClick={() => setStep(3)}
          >
            {t("DIALECTICISM")}
          </div>
          <div
            className={`text-greslernorange border-r border-greslernorange md:rounded-t-lg ${step ===
              4
              ? "!bg-greslernorange text-white"
              : "bg-gray-200"} p-2 text-center cursor-pointer`}
            onClick={() => setStep(4)}
          >
            {t("CONTEXTUALITY")}
          </div>
        </div>
        <div className=" bg-gray-100 flex flex-wrap border-2 border-greslernorange   bg-[url('https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/images/interdesciplinary.png')]  ">
          <div className="w-full p-2 text-white bg-greslernorange flex justify-between ">

            {/* <button
                    className="bg-green-300 px-6 py-1.5 rounded-lg text-gray-700 hover:bg-gray-400"
                    onClick={hadleRestart}
                  >
                    {" "}restart Assessment
                  </button> */}
            {
              intraculturalismScore +
                transdisciplinaryScore +
                dialecticismScore +
                contextuallyScore ==
                0 ? ""
                :
                intraculturalismScore +
                  transdisciplinaryScore +
                  dialecticismScore +
                  contextuallyScore <
                  56 &&
                  intraculturalismScore +
                  transdisciplinaryScore +
                  dialecticismScore +
                  contextuallyScore !=
                  0
                  ? ""
                  // "Non-Compliant & Not Ready for Sharing, Need to Take BIE Intensive Course"
                  : intraculturalismScore +
                    transdisciplinaryScore +
                    dialecticismScore +
                    contextuallyScore <
                    72 &&
                    intraculturalismScore +
                    transdisciplinaryScore +
                    dialecticismScore +
                    contextuallyScore !=
                    0
                    ? ""
                    // "Somehow Compliant, Take BIE Refresher Course, & Rework on Materials before uploading and sharing"

                    : intraculturalismScore > 27 &&
                      transdisciplinaryScore > 18 &&
                      dialecticismScore > 27 &&
                      contextuallyScore > 27 &&
                      intraculturalismScore +
                      transdisciplinaryScore +
                      dialecticismScore +
                      contextuallyScore !=
                      0
                      ?
                      <button
                        className="bg-green-300 px-6 py-1.5 rounded-lg text-gray-700 hover:bg-white-400"
                        onClick={handlePass}
                      >
                        {t("Fully aligned with BIE guidelines, Can Be Public and Shared")}
                      </button>
                      : intraculturalismScore < 27
                        ? <p>{t("Intraculturalism Assessment is too low.")}</p>
                        : transdisciplinaryScore < 18
                          ? <p>{t("Transdisciplinary Assessment is too low.")}</p>
                          : dialecticismScore < 27
                            ? <p>{t("DialecticismScore Assessment is too low.")}</p>
                            : contextuallyScore < 27 &&
                            <p>{("Contextuality Assessment is too low.")}</p>
            }
            <button
              className=" bg-green-300 px-6 py-1.5 rounded-lg text-gray-700 hover:bg-white-400"
              onClick={handlePass}>
              {t("Skip")}
            </button>
          </div>
          {step === 1
            ? <Step1
              getScore={intraculturalismScore}
              setScore={setIntraculturalismScore}
              content={contentId}
              handleQuestionResult={handleQuestionResult}
              questionsResult={questionsResult.intraculturalism}
              t={t}
            />
            : step === 2
              ? <Step2
                getScore={transdisciplinaryScore}
                setScore={setTransdisciplinaryScore}
                content={contentId}
                handleQuestionResult={handleQuestionResult}
                questionsResult={questionsResult.transdisciplinarity}
                t={t}
              />
              : step === 3
                ? <Step3
                  getScore={dialecticismScore}
                  setScore={setDialecticismScore}
                  content={contentId}
                  handleQuestionResult={handleQuestionResult}
                  questionsResult={questionsResult.dialecticism}
                  t={t}
                />
                : step === 4
                  ? <Step4
                    getScore={contextuallyScore}
                    setScore={setContextuallyScore}
                    content={contentId}
                    handleQuestionResult={handleQuestionResult}
                    questionsResult={questionsResult.contextuality}
                    t={t}
                  />
                  : ""}
        </div>
        <div className="flex justify-between mt-6">
          {step > 1 &&
            <button
              className="bg-gray-300 px-6 py-1.5 rounded-lg text-gray-700 hover:bg-gray-400"
              onClick={handleBack}
            >
              Back
            </button>}

          {step < 4 &&
            <button
              className="!bg-greslernorange px-6 py-1.5 rounded-lg text-white hover:bg-blue-600"
              onClick={handleNext}
            >
              Next
            </button>}
        </div>
      </div>
    </div>
  );
};

const Step1 = ({ getScore = 0, setScore, content, handleQuestionResult, questionsResult, t }) => {
  const [intraculturalismScore, setIntraculturalismScore] = useState(getScore);
  const calculateScore = (diff = 0) => {
    setIntraculturalismScore(intraculturalismScore + diff);
    setScore(intraculturalismScore + diff);
  };
  const intraculturalism = [
    {
      type: "Q1",
      question: t("intraQ1"),
      result: questionsResult.q1
    },
    {
      type: "Q2",
      question: t("intraQ2"),
      result: questionsResult.q2
    },
    {
      type: "Q3",
      question: t("intraQ3"),
      result: questionsResult.q3
    },
    {
      type: "Q4",
      question: t("intraQ4"),
      result: questionsResult.q4
    },
    {
      type: "Q5",
      question: t("intraQ5"),
      result: questionsResult.q5
    },
    {
      type: "Q6",
      question: t("intraQ6"),
      result: questionsResult.q6
    }
  ];
  return <>
    <div className="md:w-2/3 bg-gray-100 p-3">
      {intraculturalism.map((question, key) =>
        <div key={`culture${key}${content}`} className="mb-4">
          <label
            className="block font-medium mb-2 text-greslern"
            htmlFor={question.type}
          >
            {question.question}
          </label>
          <div id={question.type}>
            <BIEAssessmentScoring
              forwhat={`cultural${question.type}${content}`}
              onScoreChange={calculateScore}
              contentId={content}
              section="intraculturalism"
              q={question.type.toLowerCase()}
              handleQuestionResult={handleQuestionResult}
              result={question.result}
            />
          </div>
        </div>
      )}
    </div>
    <div className="hidden md:-hidden flex flex-wrap items-center justify-center w-1/3">
      {/* <img className="absolute inset-0 w-full h-full object-cover object-center" src={interdesciplinary} alt="" /> */}
      {/* <div className="rounded-full  w-50 h-50  border-4 border-white flex justify-center items-center text-3xl md:text-9xl text-white">
          {intraculturalismScore}
        </div> */}
      {/* 
        27-30		Completely Compliant	
        18-26		Somehow Compliant, Needs Revision	
        0-17		Non-compliant, Needs Further Reflection	 
        */}
      <div className="text-greslernblue p-3 text-center flex items-center bg-white rounded text-greslernorange">
        "
        {intraculturalismScore < 18 ? t("Non-compliant, Needs Further Reflection") : intraculturalismScore < 27 ? t("Somehow Compliant, Needs Revision") : t("Completely aligned with BIE guidelines")}
        "
      </div>
    </div>
  </>;
};
const Step2 = ({ getScore = 0, setScore, content, handleQuestionResult, questionsResult, t }) => {
  const [transdisciplinaryScore, setTransdisciplinaryScore] = useState(
    getScore
  );
  const calculateScore = (diff = 0) => {
    setTransdisciplinaryScore(transdisciplinaryScore + diff);
    setScore(transdisciplinaryScore + diff);
  };
  const transdisciplinary = [
    {
      type: "Q1",
      question:
        t("discipQ1"),
      result: questionsResult.q1
    },
    {
      type: "Q2",
      question:
        t("discipQ2"),
      result: questionsResult.q2
    },
    {
      type: "Q3",
      question:
        t("discipQ3"),
      result: questionsResult.q3
    },
    {
      type: "Q4",
      question:
        t("discipQ4"),
      result: questionsResult.q4
    }
  ];
  return <>
    <div className="md:w-2/3 bg-gray-100 p-2">
      {transdisciplinary.map((question, key) =>
        <div key={`discipline${key}${content}`} className="mb-4">
          <label
            className="block font-medium mb-2 text-greslern"
            htmlFor={question.type}
          >
            {question.question}
          </label>
          <div id={question.type}>
            <BIEAssessmentScoring
              forwhat={`discipline${question.type}${content}`}
              onScoreChange={calculateScore}
              contentId={content}
              section="transdisciplinarity"
              q={question.type.toLowerCase()}
              handleQuestionResult={handleQuestionResult}
              result={question.result}
            />
          </div>
        </div>
      )}
    </div>
    <div className="hidden md:-hidden flex flex-wrap items-center justify-center w-1/3 ">
      {/* <div className="rounded-full  w-50 h-50  border-4 border-white flex justify-center items-center text-3xl md:text-9xl text-white">
          {transdisciplinaryScore}
        </div> */}
      {/* 
        27-30		Completely Compliant	
        18-26		Somehow Compliant, Needs Revision	
        0-17		Non-compliant, Needs Further Reflection	 
        */}

      <div className="text-greslernorange p-2 text-center flex items-center  bg-white rounded">
        " {transdisciplinaryScore < 14 ? t("Non-compliant, Needs Further Reflection") : transdisciplinaryScore < 18 ? t("Somehow Compliant, Needs Revision") : t("Completely aligned with BIE guidelines")} "{" "}
      </div>
    </div>
  </>;
};
const Step3 = ({ getScore = 0, setScore, content, handleQuestionResult, questionsResult, t }) => {
  const [dialecticismScore, setDialecticismScore] = useState(0);
  const calculateScore = (diff = 0) => {
    setDialecticismScore(dialecticismScore + diff);
    setScore(dialecticismScore + diff);
  };
  const dialecticism = [
    {
      type: "Q1",
      question:
        t("dialectQ1"),
      result: questionsResult.q1
    },
    {
      type: "Q2",
      question:
        t("dialectQ2"),
      result: questionsResult.q2
    },
    {
      type: "Q3",
      question:
        t("dialectQ3"),
      result: questionsResult.q3
    },
    {
      type: "Q4",
      question:
        t("dialectQ4"),
      result: questionsResult.q4
    },
    {
      type: "Q5",
      question:
        t("dialectQ5"),
      result: questionsResult.q5
    },
    {
      type: "Q6",
      question:
        t("dialectQ6"),
      result: questionsResult.q6
    }
  ];
  return <>
    <div className="md:w-2/3 bg-gray-100 p-2">
      {dialecticism.map((question, key) =>
        <div key={`dialect${key}${content}`} className="mb-4">
          <label
            className="block font-medium mb-2 text-greslern"
            htmlFor={question.type}
          >
            {question.question}
          </label>
          <div id={question.type}>
            <BIEAssessmentScoring
              forwhat={`dialect${question.type}${content}`}
              onScoreChange={calculateScore}
              contentId={content}
              section="dialecticism"
              q={question.type.toLowerCase()}
              handleQuestionResult={handleQuestionResult}
              result={question.result}
            />
          </div>
        </div>
      )}
    </div>
    <div className=" hidden md:-hidden flex flex-wrap items-center justify-center w-1/3 ">
      {/* <div className="rounded-full  w-50 h-50  border-4 border-white flex justify-center items-center text-3xl md:text-9xl text-white">
          {dialecticismScore}
        </div> */}
      {/* 
        27-30		Completely Compliant	
        18-26		Somehow Compliant, Needs Revision	
        0-17		Non-compliant, Needs Further Reflection	 
        */}

      <div className="text-greslernorange p-2 text-center flex items-center  bg-white rounded">
        " {dialecticismScore < 18 ? t("Non-compliant, Needs Further Reflection") : dialecticismScore < 27 ? t("Somehow Compliant, Needs Revision") : t("Completely aligned with BIE guidelines")} "{" "}
      </div>
    </div>
  </>;
};
const Step4 = ({ getScore = 0, setScore, content, handleQuestionResult, questionsResult, t }) => {
  const [contextuallyScore, setContextuallyScore] = useState(getScore);
  const calculateScore = (diff = 0) => {
    setContextuallyScore(contextuallyScore + diff);
    setScore(contextuallyScore + diff);
  };
  const contextual = [
    {
      type: "Q1",
      question:
        t("contextQ1"),
      result: questionsResult.q1
    },
    {
      type: "Q2",
      question:
        t("contextQ2"),
      result: questionsResult.q2
    },
    {
      type: "Q3",
      question:
        t("contextQ3"),
      result: questionsResult.q3
    },
    {
      type: "Q4",
      question:
        t("contextQ4"),
      result: questionsResult.q4
    },
    {
      type: "Q5",
      question:
        t("contextQ5"),
      result: questionsResult.q5
    },
    {
      type: "Q6",
      question:
        t("contextQ6"),
      result: questionsResult.q6
    }
  ];
  return <>
    <div className="md:w-2/3  bg-gray-100 p-2">
      {contextual.map((question, key) =>
        <div key={`context${key}${content}`} className="mb-4">
          <label
            className="block font-medium mb-2 text-greslern"
            htmlFor={question.type}
          >
            {question.question}
          </label>
          <div id={question.type}>
            <BIEAssessmentScoring
              forwhat={`context${question.type}${content}`}
              onScoreChange={calculateScore}
              contentId={content}
              section="contextuality"
              q={question.type.toLowerCase()}
              handleQuestionResult={handleQuestionResult}
              result={question.result}
            />
          </div>
        </div>
      )}
    </div>
    <div className="hidden md:-hidden flex flex-wrap items-center justify-center w-1/3 ">
      {/* <div className="rounded-full  w-50 h-50  border-4 border-white flex justify-center items-center text-3xl md:text-9xl text-white">
          {contextuallyScore}
        </div> */}
      {/* 
        27-30		Completely Compliant	
        18-26		Somehow Compliant, Needs Revision	
        0-17		Non-compliant, Needs Further Reflection	 
        */}

      <div className="text-greslernblue p-2 text-center flex items-center bg-white rounded">
        " {contextuallyScore < 18 ? t("Non-compliant, Needs Further Reflection") : contextuallyScore < 27 ? t("Somehow Compliant, Needs Revision") : t("Completely aligned with BIE guidelines")} "{" "}
      </div>
    </div>
  </>;
};

export default BIEAssessment;
