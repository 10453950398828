import { useState, useMemo } from "react";
import { GetUserLogin, GetUsers } from "../../services";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeLowVision } from "@fortawesome/free-solid-svg-icons";
import { Spin } from "react-cssfx-loading";

const AdminProfile = () => {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isPassValid = useMemo(() => true, []);
  const minLength = 8
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userpasswordupdate = async (e) => {
    e.preventDefault();
    const passwordData = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    }
    if (newPassword !== confirmPassword) {
      NotificationManager.error("Password didn't match", "password Reset")
      return;
    }
    try {
      setLoading(true);
      const data = await GetUsers.updateinstitutepassword(passwordData)
      if (data.status === 200) {
        logout();
        NotificationManager.success(`${data.data}`, "User password");
        setLoading(false);
      }
      else {
        NotificationManager.warning(`${data.data}`, "User password")
        setLoading(false);

      }
    } catch (error) {
      console.error('Error during change:', error);
      setLoading(false);

    }

  }
  const logout = async () => {
    let response = await GetUserLogin.getUserLogOut()
    if (response) {
      GetUserLogin.Logout(dispatch, () => {
        window.location.reload()
      })
    }
  };
  return (
    <div className="w-full h-auto">
      <div className="py-[1px] px-[2px]">
        <div className="w-full h-auto">
          <div className="py-[16px] px-[32px]">
            <div className="grid grid-cols-12 sm:grid-cols-6">
            </div>
            <div className="grid grid-cols-12 sm:grid-cols-6">
              <div className="col-span-3 p-2">
                <h2 className="text-white text-[18px] mb-2">
                  {t("Password Setting")}
                </h2>
              </div>
              <div className="col-span-9 bg-white rounded-[0.375rem] p-3">
                <div className='sm:flex-wrap'>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    userpasswordupdate(e);
                  }}
                  >
                    <div className="grid grid-cols-12 w-full my-4 sm:flex-wrap">
                      <div className="col-span-9 bg-white rounded-[0.375rem] p-3">
                        <h2 className="text-[18px] mb-6 font-bold">
                          {t("Change your password")}{" "}
                        </h2>
                        <div className="relative grid grid-cols-12 py-2 flex items-center">
                          <h2 className="col-span-3 text-[16px] mb-2">
                            {t("Current password")}{" "}
                          </h2>
                          <input
                            id="currentpassword"
                            name='currentpassword'
                            type={showPassword ? "text" : "password"}
                            placeholder={t("Enter current password")}
                            onChange={(e) => setOldPassword(e.target.value)}
                            className={`password relative border-2 w-full py-[10px] px-[15px] outline-none rounded-[5px] text-black text-base tracking-wider bg-white placeholder:text-black col-span-9 border-solid ${isPassValid ? '!border-g-gray-200' : '!border-red-500'}`}
                            required
                          />
                          <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowPassword(!showPassword) }}>
                            <FontAwesomeIcon icon={!showPassword ? faEyeLowVision : faEye} />
                          </button>
                        </div>
                        <div className="relative grid grid-cols-12 py-2 flex items-center">
                          <h2 className="col-span-3 text-[16px] mb-2">
                            {t("New password")}{" "}
                          </h2>
                          <input
                            id="password"
                            name='password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            max={30}
                            type={showPassword ? "text" : "password"}
                            placeholder={t("Enter new password")}
                            className={`password relative border-2 w-full py-[10px] px-[15px] outline-none rounded-[5px] text-black text-base tracking-wider bg-white placeholder:text-black col-span-9 border-solid ${isPassValid ? '!border-g-gray-200' : '!border-red-500'}`}
                            required
                          />
                          <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowPassword(!showPassword) }}>
                            <FontAwesomeIcon icon={!showPassword ? faEyeLowVision : faEye} />
                          </button>
                        </div>
                        {!isPassValid && (
                          <div className='text-red-500 text-sm'>
                            {t("passwordlength")} {minLength} {t("characterlong")}
                          </div>
                        )}
                        <div className="relative grid grid-cols-12 py-2 flex items-center">
                          <h2 className="col-span-3 text-[16px] mb-2">
                            {t("Confirm new password")}{" "}
                          </h2>
                          <input
                            id="confirmPassword"
                            name='confirmPassword'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={showConfirmPassword ? "text" : 'password'}
                            placeholder={t("Enter new password again")}
                            className="relative border-2 w-full py-[10px] px-[15px] outline-none rounded-[5px] text-black text-sm tracking-wider bg-white placeholder:text-black col-span-9 border-solid border-gray-200"
                            required
                          />
                          <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowConfirmPassword(!showConfirmPassword) }}>
                            <FontAwesomeIcon icon={!showConfirmPassword ? faEyeLowVision : faEye} />
                          </button>
                        </div>
                        <div className="grid grid-cols-12 py-2 flex items-center">
                          <h2 className="col-span-3 text-[16px] mb-2"> </h2>
                          <div className="col-span-9 flex justify-start">
                            <button
                              type="submit"
                              disabled={!newPassword}
                              className={
                                `border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 text-white bg-greslernorange`}>
                              {loading ? (<Spin color="#ffffff" width="30px" height="30px" duration="1s" />) : t("Change Password")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminProfile;
