import { Apis } from "../../config";
import api from "../../apiConfig";
import axios, { AxiosError } from 'axios';
import { NotificationManager } from "react-notifications";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const createGroup = async (userdata: any) => {
  try {
    const response = await api.post(Apis.createGroupInstitute, userdata);
    if (response.status == 200) {
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Generate")
        return null;
      } else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const createMainGroup = async (userdata: any) => {
  try {
    const response = await api.post(Apis.createMainGroupInstitute, userdata);
    if (response.status == 200) {
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Generate")
        return null;
      } else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const getGroupList = async (instituteId:string) => {
  try {
    let result = await api.get(Apis.getGroupList + instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getMainGroupList = async (instituteId:string) => {
  try {
    let result = await api.get(Apis.getMainGroupList + instituteId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getGroupListSsub = async (groupidforsearch: string) => {
  try {
    let result = await api.get(Apis.getGroupListSsub + groupidforsearch)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const getListUsersingroup = async (usergroupid: any) => {
  try {
    let result = await api.get(Apis.getListUsersingroup + usergroupid)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const updateUserGroup = async (email: string, updatedata: any) => {
  try {
    let result = await api.patch(Apis.updateUserGroup + email, updatedata)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const removeUserGroup = async (email) => {
  try {
    let result = await api.patch(Apis.RemoveUserGroup + email)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const removeResourceGroup = async (contentid,resourceDate) => {
  try {
    let result = await api.patch(Apis.RemoveResourceGroup + contentid,resourceDate)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (e) {
    console.log(e)
    return null;
  }
};
const deleteGroup = async (deletegroupid: string) => {
  try {
    let result = await api.patch(Apis.DeleteGroupId + deletegroupid)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const deleteMainGroup = async (maingroupid: string) => {
  try {
    let result = await api.patch(Apis.DeleteMainGroupId + maingroupid)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const editGroup = async (groupIdNumber: string,updateData:any) => {
  try {
    let result = await api.patch(Apis.EditGroupId + groupIdNumber,updateData);

    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const editMainGroup = async (groupIdNumber: string, updateData: any) => {
  try {
    let result = await api.patch(Apis.EditMainGroupId + groupIdNumber, updateData)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const fetchGroup = async (groupIdNumber) => {
  try {
    let result = await api.get(Apis.FetchGroupId + groupIdNumber)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const fetchMainGroup = async (groupIdNumber) => {
  try {
    let result = await api.get(Apis.FetchMainGroupId + groupIdNumber)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const addResourceToGroup = async (usergroupid: string,listContent:any) => {
  try {
    let result = await api.patch(Apis.AddResourceToGroup + usergroupid,listContent)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (e) {
    console.log(e)
    return null;
  }
};
export default {

    createGroup,
    getGroupList,
    updateUserGroup,
    createMainGroup,
    getMainGroupList,
    getGroupListSsub,
    getListUsersingroup,
    removeUserGroup,
    deleteGroup,
    editGroup,
    fetchGroup,
    deleteMainGroup,
    fetchMainGroup,
    editMainGroup,
    addResourceToGroup,
    removeResourceGroup
}