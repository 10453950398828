import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next';
import SubscriberService from '../../../services/SubscriberService';

const SubsctiptionAnnouncement = props => {
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const handleAnnouncementSubmit = async (e) => {
        e.preventDefault();
        const result = await SubscriberService.SendAnnouncement(title, message)
    }

  return (
    <div className="mx-auto p-8 bg-greslernorange rounded-b-md shadow-md form-container">
        <h2 className="text-2xl font-semibold text-white mb-6">{t("subscribers announcement")}</h2>
        <form onSubmit={handleAnnouncementSubmit}>
            <div className="flex flex-row space-x-5">
            <div className="mb-4 w-full">
                <label className="block text-gray-100 text-sm font-bold mb-2">{t("title")}</label>
                <input
                type="text" id="name" name="title"
                placeholder="Enter title"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 bg-gray-400 text-white"
                />
            </div>
            </div>
            <div className="mb-6">
            <label className="block text-gray-300 text-sm font-bold mb-2">{t("Message")}</label>
            <textarea id="message" name="message" rows={4}
                placeholder="Type your message"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 bg-gray-400 text-white"></textarea>
            </div>
            <button type='submit' name='submit'
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue capitalize">
            {t("send")}
            </button>
        </form>
    </div>
  )
}

SubsctiptionAnnouncement.propTypes = {}

export default SubsctiptionAnnouncement