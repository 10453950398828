
const UserProfileAvatarComponent = ({ profilePhotoUrl, userName }) => {

  return (
    <div className="m-2 !-mr-3">
      <div className="w-8 h-8 rounded-full text-greslernorange bg-white  bg-black-700  outline-dotted outline-2 outline-offset-2 outline-greslernorange/50">
        {profilePhotoUrl ?
          <img src={`/api/public/images/${profilePhotoUrl}`} alt="" className="w-8 h-8 items-center rounded-full  text-2xl text-center" />
          :
          <div className="w-8 h-8 items-center text-2xl text-center">
            {userName?.charAt(0).toUpperCase()}
          </div>}
      </div>
    </div>
  );
}

export default UserProfileAvatarComponent