import { useState } from "react";
import ModalComponent from "../../ModalComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faAdd, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';
import { selectEmail } from '../../../redux/Reducer/authSlice'
import { NotificationManager } from 'react-notifications';

import CustomerReviews from "../CustomerReviews";
import ContentLoader from "../../loader/ContentLoader";
import ReviewCard from "../ReviewCard";
import BIEAssessmentPublic from "../../form/BIEAssesmentPublic";
import BIEAssessmentEditPublic from "../../form/BIEAssesmentPublic/edit";
import { GetReview } from "../../../services";

const Reviews = ({ reviews, isLoading, getReviews }) => {
  const email = useSelector(selectEmail)

  const myReview = reviews.find((review) => review?.userEmail === email);
  const [isBIEopen, setIsBIEopen] = useState(false)
  const [editReview, setEditReview] = useState(false);
  const [deleteReview, setDeleteReview] = useState(false);

  const handleBIEClose = () => {
    setIsBIEopen(!isBIEopen)
  }

  const handleClose = () => {
    setEditReview(false);
    setDeleteReview(false);
  };

  // const handleEditReview = (data, updatedData) => {
  //   setEditReviewLoading(true);
  //   axios
  //     .patch(`/reviews/${data?._id}?email=${user?.email}`, updatedData)
  //     .then((res) => {
  //       if (res?.data?.modifiedCount || res?.data?.matchedCount) {
  //         // toast.success("Comment Updated!");

  //         //   Close the modal
  //         handleClose();
  //         //   Refetch review to update
  //         refetch();
  //         setEditReviewLoading(false);
  //       } else {
  //         toast.error("Something went wrong. Please try again!");
  //         setEditReviewLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //       setEditReviewLoading(false);
  //     });
  //   refetch();
  //   handleClose();
  //   setEditReviewLoading(false);
  // };

  const handleDeleteReview = (data) => {
    GetReview.deleteReview(data)
      .then((res) => {
        if (res) {
          NotificationManager.success("Deleted Successfully!", "Review");
          handleClose();
          getReviews();
        } else {
          handleClose();
          NotificationManager.error("Something Went Wrong!", "Review");
        }
      })
      .catch((err) => {
        NotificationManager.error(err?.message || "Something went wrong!", "Review");
        handleClose();
        getReviews();
      });
    getReviews();
  };

  return (
    <div className="py-5 lg:px-10 px-2 text-[16px] flex flex-col gap-5">
      {/* Review card */}
      {isLoading ? (
        <ContentLoader />
      ) : reviews?.length ?
        <>
          <CustomerReviews reviews={reviews} />
          <div className=" max-h-[500px] overflow-auto px-5 py-2">
            {reviews?.map((review) => (
              <ReviewCard key={review?._id} review={review}></ReviewCard>
            ))}
          </div>
        </>
        :
        <span className="bg-[#FFEDD5] w-full text-center rounded-sm font-medium text-gray-700">
          Be the first!
        </span>
      }

      {myReview ? (
        <div className="px-8">
          <h2 className="text-base font-semibold mb-1 text-gray-700">
            Your Evaluation
          </h2>
          <ReviewCard review={myReview}></ReviewCard>
          <div className="flex gap-4 font-semibold ml-6 mt-2">
            <button
              onClick={() => setEditReview(true)}
              className="text-primary flex items-center gap-[2px] cursor-pointer hover:text-primary/70"
            >
              <FontAwesomeIcon icon={faEdit} />

              Edit
            </button>
            <button
              onClick={() => setDeleteReview(myReview)}
              className="text-danger flex items-center gap-[2px] cursor-pointer hover:text-danger/70"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
              Delete
            </button>
          </div>
        </div>
      ) :
        <div className="px-8">
          <h2 className="text-base font-semibold mb-1 text-gray-700">
            Your Review
          </h2>
          <div className="flex gap-2 font-semibold ml-8 mt-2">
            <button
              onClick={() => setIsBIEopen(true)}
              className="text-primary flex items-center gap-[2px] cursor-pointer hover:text-primary/70"
            >
              <FontAwesomeIcon icon={faAdd} />
              Evaluate
            </button>
          </div>
        </div>}
      <ModalComponent title={false} isOpen={isBIEopen} onClose={handleBIEClose}>
        <BIEAssessmentPublic onClose={handleBIEClose} getReviews={getReviews} />
      </ModalComponent>
      {myReview ?
        <ModalComponent title={false} isOpen={editReview} onClose={handleClose}>
          <BIEAssessmentEditPublic onClose={handleClose} getReviews={getReviews} review={myReview} />
        </ModalComponent>
        : null}
      <ModalComponent title={"Delete Review"} isOpen={deleteReview} onClose={handleClose}>
        <div className="flex w-full items-center justify-evenly">
          <button onClick={() => handleDeleteReview(myReview._id)} className="py-2 px-4 bg-danger text-white border-2 border-solid border-danger hover:text-danger hover:bg-white rounded-lg">Yes</button>
          <button onClick={handleClose} className="py-2 px-4 bg-primary text-white border-2 border-solid border-primary hover:text-primary hover:bg-white rounded-lg">No</button>
        </div>
      </ModalComponent>

    </div>
  );
};

export default Reviews;
