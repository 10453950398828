import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import './i18next'
import Loader from './components/loader';
// import store from './Socket/store'
import { store, persistor } from './redux/store'
import { Provider } from 'react-redux';
ReactDOM.render(
    <React.StrictMode>
        {/* <ThemeProvider> */}
        <React.Suspense fallback={<Loader />}>
            <Provider store={store}>
                <PersistGate loading={<Loader />} persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </React.Suspense>
        {/* </ThemeProvider> */}
    </React.StrictMode>,
    document.getElementById('root')
);

