import {
  faFile,
  faFileAlt,
  faFolder,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastOpt } from "../../../../config";
import {
  ContentService,
  IContentListEntry,
} from "../../../../services/ContentService";
import FolderExplorerService from "../../../../services/FolderExplorerService";
import ContentHeader from "../addon/contentHeader";

interface IFolder {
  _id: string;
  name: string;
  contentId: string[];
  parent: IFolder | null;
  children: IFolder[];
  isOpen: boolean;
}
interface IFolderSummary {
  _id?: string;
  name?: string;
}
const initialContextMenu = {
  show: false,
  x: 0,
  y: 0,
};

const FolderExplorer = () => {
  const { t } = useTranslation();

  const [folders, setFolders] = useState<IFolder[]>([]);
  const [currentFolder, setCurrentFolder] = useState<IFolder[]>([]);
  const [breadcrumbFolder, setBreadcrumbFolder] = useState<IFolderSummary[]>(
    []
  );
  const [newFolderName, setNewFolderName] = useState("");
  const [renameFolderName, setRenameFolderName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [folderTree, setFolderTree] = useState(false);
  const [editFolderId, setEditFolderId] = useState("");
  const [editFolderName, setEditFolderName] = useState("");
  const [moveFolderId, setMoveFolderId] = useState("");
  const [moveParentId, setMoveParentId] = useState("");
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [currentContentId, setCurrentContentId] = useState("");
  const [layoutTiles, setLayoutTiles] = useState(false);
  const [layoutTree, setLayoutTree] = useState(true);
  const [contextMenu, setContextMenu] = useState(initialContextMenu);
  const [fileContextMenu, setFileContextMenu] = useState(initialContextMenu);
  const [contentList, setContentList] = useState<IContentListEntry[]>([]);
  const [content, setContent] = useState<IContentListEntry>();

  const [query, setQuery] = useState("");

  const setLayout = () => {
    setLayoutTiles(false);
    setLayoutTree(true);
  };

  useEffect(() => {
    fetchFolders();
    updateList();
    setLayout();
  }, []);

  const contentService = new ContentService();
  const updateList = async () => {
    setContentList(await contentService.list());
  };

  const fetchFolders = async () => {
    setCurrentFolder([]);
    setBreadcrumbFolder([]);
    const folders = await FolderExplorerService.fetchFolders();
    setFolders(folders.filter((f) => f.parent == null));
  };

  const setOpen = async (folder: IFolder) => {
    try {
      await FolderExplorerService.setOpen(folder._id);
      fetchFolders();
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };
  const renameFolder = async (folderId: string, newName) => {
    if (newName.toLowerCase() === "root" || newName.toLowerCase().length < 1) {
      toast.warning(t("Can't name folder 'Root' or '' !"), toastOpt);
    } else {
      try {
        FolderExplorerService.renameFolder(folderId, newName);
        toast.success(`Folder Renamed To '${newName}' !`, toastOpt);
        fetchFolders();
      } catch (error) {
        console.error("Error renaming folder:", error);
      }
    }
  };

  // search
  const [currentFolderBk, setCurrentFolderBk] = useState<IFolder[]>([]);

  // Contextmenu
  const handleContextMenu = (e, id) => {
    e.preventDefault();
    setFolderId(id);

    const { pageX, pageY } = e;
    setContextMenu({
      show: true,
      x: layoutTiles ? pageX - 25 : pageX,
      y: layoutTiles ? pageY - 90 : pageY,
    });
  };
  const [folderContents, setFolderContents] = useState<IContentListEntry[]>([]);

  const openContent = (contents) => {
    setFolderContents(
      contents?.map((c) =>
        contentList.filter((content) => content.contentId == c)
      )
    );
  };

  // rendering
  const renderFolder = (folder: IFolder) => {
    return (
      <>
        <li
          key={folder._id}
          className={
            folder.name == "Root"
              ? "transition-all duration-300"
              : "pl-10 transition-all duration-300 hover:-translate-y-1"
          }>
          <div className="flex flex-row justify-between items-center bg-black/20 my-2 p-2 focus:outline-none mb-7 bg-white shadow rounded w-full">
            <div>
              <FontAwesomeIcon
                icon={folder.isOpen ? faFolderOpen : faFolder}
                size={layoutTiles ? "6x" : "2x"}
                className="text-blue-500 hover:text-blue-700  mr-2 col-start "
                onClick={() => setOpen(folder)}
                onContextMenu={(e) => {
                  handleContextMenu(e, folder._id);
                }}
              />
              <h3
                contentEditable
                suppressContentEditableWarning={true}
                spellCheck={false}
                onBlur={(e) =>
                  renameFolder(folder._id, e.currentTarget.textContent)
                }
                onKeyDown={(e) => {
                  e.key == "Enter" && e.preventDefault();
                  e.key == "Enter" &&
                    renameFolder(folder._id, e.currentTarget.textContent);
                }}
                className=" text-meta-8 inline-block align-bottom p-1 hover:bg-white focus:bg-greslernblue">
                {folder.name}
              </h3>
            </div>
            <div
              onClick={() => openContent(folder.contentId)}
              className="hover:bg-gray-900/5 order-last inline-flex items-center rounded-md bg-greslernorange/5 px-2 py-1 text-xs font-medium text-greslernorange ring-1 ring-inset ring-greslernorange/20 cursor-pointer">
              <FontAwesomeIcon icon={faFile} className="mr-2" />
              {t("Contents")}: {folder.contentId?.length}
            </div>
          </div>
          {layoutTree &&
            folder.isOpen &&
            folder.children &&
            folder.children.length > 0 &&
            folder.children.map((child) => (
              <ul key={child._id}>{renderFolder(child)}</ul>
            ))}
        </li>
      </>
    );
  };

  const renderContents = (content) => {
    content = content[0];
    return (
      <li
        key={content.contentId}
        className="group  py-1 px-4 flex flex-col space-y-2 cursor-pointer rounded-md hover:bg-gray-900/5 hover:smooth-hover">
        <a href={`/home/dashboard/play/${content.contentId}`}>
          <div
            className={"flex-col"}>
            <FontAwesomeIcon
              icon={faFileAlt}
              size={"6x"}
              className="text-greslernorange hover:text-greslernorange  mr-2 col-start "
            />
            <h3 className=" text-meta-8 inline-block align-bottom">
              {content.title}
            </h3>
          </div>
        </a>
      </li>
    );
  };

  return (
    <div className="flex-1 px-2 sm:px-0">
      <div className="flex flex-wrap justify-between items-center">
        <ContentHeader title={t("My Folders")}></ContentHeader>
        <div className="inline-flex items-center space-x-2"></div>
      </div>
      <hr />
      <div className="flex place-content-between content-stretch flex-wrap">
        <ul className={" shadow-card flex-auto mr-3"}>
          {folders.map((folder) => renderFolder(folder))}
        </ul>
        <div
          className={`${folderContents.length > 0 ? "visible" : "hidden"
            }  mt-2 p-4 bg-white rounded shadow-2xl w-1/2`}>
          <h2> {t("Files")} </h2>
          <ul className="m-4 flex flex-row flex-wrap">
            {folderContents?.map((c) => renderContents(c))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FolderExplorer;
