import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        guidelineAccepted: false,
        username: '',
        email: "",
        role: '',
        profilephoto: '',
        isFirstLog: '',
        userId: "",
        institute:'',
    },
    reducers: {
        setAuthenticate: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        setGuidelineAccepted: (state, action) => {
            state.guidelineAccepted = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setProfilephoto: (state, action) => {
            state.profilephoto = action.payload;
        },
        setIsFirstLog: (state, action) => {
            state.isFirstLog = action.payload;
        },
        setInstitute: (state, action) => {
            state.institute = action.payload;
        },
        setLogout: (state) => {
            state.isAuthenticated = false;
            state.username = '';
            state.email = '';
            state.role = '';
            state.profilephoto = '';
            state.userId = '';
            state.institute='';
        },
    },
});

export const { setAuthenticate, setGuidelineAccepted, setLogout, setUsername, setEmail, setUserId, setRole, setIsFirstLog,setInstitute } = authSlice.actions;
export const selectAuthStatus = (state: any) => state.auth.isAuthenticated;
export const selectGuidelineAccepted = (state: any) => state.auth.guidelineAccepted;

export const selectUserName = (state: any) => state.auth.username;
export const selectEmail = (state: any) => state.auth.email;
export const selectUserId = (state: any) => state.auth.userId;
export const selectRole = (state: any) => state.auth.role;
export const selectIsFirstLog = (state: any) => state.auth.isFirstLog;
export const selectprofilephoto = (state: any) => state.auth.profilephoto;
export const selectInstitute = (state: any) => state.auth.institute;

export default authSlice.reducer;