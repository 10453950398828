import { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { faArrowLeft, faArrowRight, faBackward, faBan, faCheck, faDeleteLeft, faEdit, faTrash, faTrashCanArrowUp, faUserCheck, faUserSlash, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationManager } from "react-notifications";
import countries from "countries-list"; // Import the countries-list library
import { Spin } from 'react-cssfx-loading';

import NoData from '../../institutePages/components/NoData';
import ToolTip from '../../tooltip';
import ModalComponent from '../../ModalComponent';
import { CreateInstituteInvitation, GetUsers } from '../../../services';
import CustomSelect from '../../DropDowns/userList';
import UpdateInstitute from './UpdateInstitute';

type profileprops = {
  _id: any;
  profilephoto: string;
  name: string;
  email: string;
  bio: string;
  type: string;
  gender: string;
  country: string;
  username: string;
  contentId: string;
  phone: string;
  createdAt: Date;
  status: string;
  id: string;
};

interface InstitutionType {
  label: string;
  value: string;
}

const InstituteDetail = () => {
  const { _id } = useParams<{ _id?: string }>();
  const [membersList, setMembersList] = useState<profileprops[]>([]);
  const [allMembersList, setAllMembersList] = useState<profileprops[]>([]);
  const [name, setName] = useState('');
  const [contactName, setContactName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [instAdminId, setInstAdminId] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const { t } = useTranslation();
  const [country, setCountry] = useState('');
  const [type, setInstituteType] = useState('');
  const [resetNormal, setResetNormal] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const [status, setStatus] = useState();
  const [showForm, setShowForm] = useState(false);
  const [deleteComfirmation, setDeleteComfirmation] = useState(false);
  const [banComfirmation, setBanComfirmation] = useState(false);
  const [updateComponent, setUpdateComponent] = useState<Boolean>(true);
  const [deleteComfirmationMember, setDeleteComfirmationMember] = useState(false);
  const [resetNormalMember, setResetNormalMember] = useState(false);
  const [banComfirmationMember, setBanComfirmationMember] = useState(false);
  const [userEmail, setUserEmail] = useState()
  const [userSelectedEmail, setUserSelectedEmail] = useState('')
  const [emailAssign, setEmailAssign] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [contactNameAssign, setContactNameAssign] = useState('');
  const [loading, setLoading] = useState(false);
  const [changeAdminModal, setChangeAdminModal] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false)
  const [newUserInvite, setNewUserInvite] = useState(true)
  const countryList = Object.values(countries.countries);
  countryList.sort((a, b) => a.name.localeCompare(b.name));
  countryList.sort((a, b) => a.name.localeCompare(b.name));
  const openBanMember = () => {
    setBanComfirmationMember(!banComfirmationMember);
  };
  const getInstituteDetail = async (_id: string) => {
    try {
      const data = await GetUsers.getinstitutedetail(_id);
      if (data) {
        setName(data.institutionName);
        setContactName(data.user[0].name);
        setCountry(data.country);
        setCity(data.city);
        setStreetAddress(data.streetAddress);
        setZipCode(data.zipCode);
        setPhone(data.phone);
        setInstituteType(data.type);
        setCreatedAt(data.createdAt);
        setStatus(data.user[0].status);
        setEmail(data.user[0]._id);
        setEmailUser(data.user[0].email);
        setInstAdminId(data.user[0]._id);
        fetchlistofteams(data._id);
      }
    } catch (error) { console.log(error); }

  }
  const handleResetNormal = async () => {
    setResetNormal(true);

  }
  const handleExistingUser = () => {
    setIsExistingUser(!isExistingUser);
    setNewUserInvite(!newUserInvite);
  }
  const openResetNormal = () => {
    setResetNormal(!resetNormal);
  }
  const fetchlistofteams = async (instAdmin) => {
    const data = await GetUsers.getindividualsinInst(instAdmin);
    if (data) {
      setMembersList(data);
    }
    else {
      setMembersList([]);
    }
  }
  const deleteInstitute = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.deleteUser(email ? email : '');
      if (data) {
        NotificationManager.success("Successfull deleted", "Institute");
        setUpdateComponent(!updateComponent);
      }
      else {
        NotificationManager.warning("Not deleted", "Institute")
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "Institute")
    }
    setDeleteComfirmation(false);
  }
  const ResetAgainInstitute = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.resetAgain(email ? email : '');
      if (data) {
        setUpdateComponent(!updateComponent);
        NotificationManager.success("Successfull Reseted", "Institute");
      }
      else {
        NotificationManager.warning("Not Reseted", "Institute")
      }
    } catch (error) {
      NotificationManager.warning("Not Reseted", "Institute")
    }
    setResetNormal(false);
  }
  const ResetAgainMember = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.resetAgain(userEmail ? userEmail : '');
      if (data) {
        setUpdateComponent(!updateComponent);
        NotificationManager.success("Successful deleted", "User");
      }
      else {
        NotificationManager.warning("Not deleted", "User")
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "User")
    }
    setResetNormalMember(false);
  }
  const banMember = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.banUser(userEmail ? userEmail : '');
      if (data) {
        setUpdateComponent(!updateComponent);
        NotificationManager.success("Successfull Banned", "user");
      }
      else {
        NotificationManager.warning("Not Banned", "user")
      }
    } catch (error) {
      NotificationManager.warning("Not Banned", "user")
    }
    setBanComfirmationMember(false);
  }
  const deleteMember = async () => {
    try {
      const data = await GetUsers.deleteUser(userEmail ? userEmail : '');
      if (data) {
        setUpdateComponent(!updateComponent);
        NotificationManager.success("Successfull deleted", "User");
      }
      else {
        NotificationManager.warning("Not deleted", "User")
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "User", error)
    }
    setDeleteComfirmationMember(false);
  }
  const openDelete = () => {
    setDeleteComfirmation(false);
    setBanComfirmation(false);
    setBanComfirmationMember(false);
    setDeleteComfirmationMember(false);
    setResetNormal(false);
    setResetNormalMember(false);
    setShowForm(false)
  };
  const openBan = () => {
    setBanComfirmation(!banComfirmation);
  };
  const refreshComponent = () => {
    setShowForm(!showForm)
    setUpdateComponent(!updateComponent);
  };
  const handleDeleteUser = async () => {
    setDeleteComfirmation(true);
  }
  const handleBanUser = async () => {
    setBanComfirmation(true);
  }
  const handleDeleteMember = (email) => {
    setUserEmail(email)
    setDeleteComfirmationMember(!deleteComfirmationMember);
  }
  const handleBanMember = (email) => {
    setUserEmail(email)
    setBanComfirmationMember(!banComfirmationMember);
  }
  const handleResetNormalMember = (email) => {
    setUserEmail(email)
    setResetNormalMember(!resetNormalMember);
  }
  const banInstitute = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.banUser(email ? email : '');
      if (data) {
        NotificationManager.success("Successfull Banned", "Institute");
        setUpdateComponent(!updateComponent);
      }
      else {
        NotificationManager.warning("Not Banned", "Institute")
      }
    } catch (error) {
      NotificationManager.warning("Not Banned", "Institute")
    }
    setBanComfirmation(false);
  }
  const updateInstitute = async (email) => {
    setShowForm(true);
  }
  const handleGenerate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (isExistingUser) {
        const userdata = {
          name: name,
          email: userSelectedEmail,
          instituteId: _id,
          lastUserId: instAdminId
        }
        let data = await CreateInstituteInvitation.createexistinstituteinvitationexistuser(userdata);
        if (data) {
          setShowForm(false);
          setLoading(false);
          NotificationManager.success("Done", "Change admin");
        } else {
          setLoading(false);
          setShowForm(false);
        }
      }
      else {
        const userdata = {
          contactName: contactNameAssign,
          email: emailAssign,
          _id: _id
        }
        let data = await CreateInstituteInvitation.createexistinstituteinvitationnewuser(userdata);
        if (data) {
          setShowForm(false);
          setLoading(false);
          NotificationManager.success("Link generated", "Change admin");
          setName("");
          setEmail("");
          setContactName("");
          setCountry("");
        } else {
          setName("");
          setEmail("");
          setContactName("");
          setCountry("");
          setShowForm(false);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error("Please Try Again", "Change admin");
    }
  };
  const handleChangeAdmin = () => {
    setChangeAdminModal(!changeAdminModal);
  }
  const fetchlistofusers = async () => {
    // setIsUsers(false)
    const data = await GetUsers.getuserlistAll();
    if (data) {
      setAllMembersList(data);
      // setIsUsers(true)
    }
    else {
      setAllMembersList([]);
      // setIsUsers(true)
    }
  }
  useEffect(() => {
    getInstituteDetail(_id ? _id : "");
    fetchlistofusers();
  }, [updateComponent]);
  return (
    <div className='w-full'>
      <div className="grid grid-cols-12">
        <div className='col-span-12'>
          <div className="bg-white rounded-bottom smooth-shadow-sm ">
            <div className="flex items-center justify-between pt-4 pb-6 px-4 ">
              <div className="flex items-center">
                <div className="">
                  <h2 className="mb-0">{name}
                    <Link to="/" className="text-decoration-none" data-bs-toggle="tooltip" data-placement="top" title="" data-original-title="Beginner">
                    </Link>
                  </h2>
                  <p className="mb-0 d-block">{emailUser}</p>
                </div>
              </div>
              {/* {data.status === '1' && */}
              <div className='align-items-end justify-evenly flex flex-row space-x-8'>
                <div>
                  <button onClick={(() => { window.history.back() })}>
                    <FontAwesomeIcon icon={faBackward} color='blue' className="space-x-2" />
                    <span className="">{t("goBack")}</span>
                  </button>
                </div>
                <div>
                  <button onClick={handleChangeAdmin}>
                    <FontAwesomeIcon icon={faUserSlash} color='orange' />
                    &nbsp; {t("Change Admin")}
                  </button>
                </div>
                <div>
                  <button onClick={(() => { updateInstitute(_id) })}>
                    <FontAwesomeIcon icon={faEdit} color='orange' />
                  </button>
                </div>
                {status == '0' || status === null ? (
                  <>
                    < div >
                      <button
                        onClick={handleBanUser}
                      >
                        <FontAwesomeIcon icon={faBan} color='yellow' />
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleDeleteUser}
                      >
                        <FontAwesomeIcon icon={faTrashCanArrowUp} color='red' />
                      </button>
                    </div>
                  </>
                ) : undefined
                }
                {status == '1' ? (
                  <>
                    < div >
                      <button
                        onClick={handleDeleteUser}
                      >
                        <FontAwesomeIcon icon={faTrashCanArrowUp} color='red' />
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleResetNormal}
                      >
                        <FontAwesomeIcon icon={faCheck} color='green' />
                      </button>
                    </div>
                  </>
                ) : undefined
                }
                {status == '2' ? (
                  <>
                    < div >
                      <button
                        onClick={handleBanUser}
                      >
                        <FontAwesomeIcon icon={faBan} color='red' />
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleResetNormal}
                      >
                        <FontAwesomeIcon icon={faCheck} color='green' />
                      </button>
                    </div>
                  </>
                ) : undefined
                }
              </div>
            </div>
          </div>
        </div >
      </div >
      <Col xl={6} lg={12} md={12} xs={12} className="mb-12 w-full">
        {/* card */}
        <Card>
          {/* card body */}
          <Card.Body>
            {/* card title */}
            <Card.Title as="h4">{t("Information")}</Card.Title>
            <Row>
              <Col xs={4} className="mb-5">
                <h6 className="text-uppercase fs-5 ls-2">{t("Position")}</h6>
                <p className="mb-0">{t("Institute")}</p>
              </Col>
              <Col xs={4} className="mb-5">
                <h6 className="text-uppercase fs-5 ls-2">{t("Phone")} </h6>
                <p className="mb-0">{phone}</p>
              </Col>
              <Col xs={4} className="mb-5">
                <h6 className="text-uppercase fs-5 ls-2">{t("Country")} </h6>
                <p className="mb-0">{country}</p>
              </Col>
              <Col xs={4}>
                <h6 className="text-uppercase fs-5 ls-2">{t("City")} </h6>
                <p className="mb-0">{city}</p>
              </Col>
              <Col xs={4}>
                <h6 className="text-uppercase fs-5 ls-2">{t("Street Address")}</h6>
                <p className="mb-0">{streetAddress}</p>
              </Col>
              <Col xs={4} className="mb-5">
                <h6 className="text-uppercase fs-5 ls-2">{t("Zip code")} </h6>
                <p className="mb-0">{zipCode}</p>
              </Col>
              <Col xs={4}>
                <h6 className="text-uppercase fs-5 ls-2">{t("Contact Name")} </h6>
                <p className="mb-0">{contactName}</p>
              </Col>
              {status === '1' &&
                <Col xs={4}>
                  <h6 className="text-uppercase fs-5 ls-2">{t("Status")}</h6>
                  <p className="mb-0 text-warning">{t("Banned")}</p>
                </Col>
              }
              {status === '2' &&
                <Col xs={4}>
                  <h6 className="text-uppercase fs-5 ls-2">{t("Status")}</h6>
                  <p className="mb-0 text-danger">{t("Deleted")}</p>
                </Col>
              }
              {status === null &&
                <Col xs={4}>
                  <h6 className="text-uppercase fs-5 ls-2">{t("Status")}</h6>
                  <p className="mb-0 text-success">{t("Active")}</p>
                </Col>
              }
              {status === '0' &&
                <Col xs={4}>
                  <h6 className="text-uppercase fs-5 ls-2">{t("Status")}</h6>
                  <p className="mb-0 text-success">{t("Active")}</p>
                </Col>
              }
              <Col xs={4} className="mb-5">
                <h6 className="text-uppercase fs-5 ls-2">{t("Reg.Date")} </h6>
                <p className="mb-0">{moment(createdAt).format('LL')}</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Card className="w-full">
        <Card.Body>
          {membersList.length > 0 ?
            <div className="relative flex flex-col min-w-0 break-words border border-dashed bg-clip-border rounded-2xl border-stone-200 bg-light/30">
              <div className="px-9 pt-5 flex justify-between items-stretch flex-wrap min-h-[70px] pb-0 bg-transparent">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                  <span className="mr-3 font-semibold text-dark">{("Member List")}</span>
                </h3>
                <div className="relative flex flex-wrap items-center my-2">
                  <h4 className="inline-block text-[.925rem] font-medium leading-normal text-center align-middle rounded-2xl transition-colors duration-150 ease-in-out text-light-inverse bg-light-dark border-light shadow-none border-0 py-2 px-5 hover:bg-secondary active:bg-light focus:bg-light"> {t("Under This institute")}</h4>
                </div>
              </div>
              <div className="block py-8 pt-6 px-9">
                <div className="overflow-x-auto">
                  <table className="w-full my-0 text-dark border-neutral-200">
                    <thead className="align-bottom">
                      <tr className="font-semibold text-[0.95rem] text-secondary-dark">
                        <th className="pb-3 text-start min-w-[175px]">{t("Member Name")}</th>
                        <th className="pb-3 text-start min-w-[100px]">{t("Email")}</th>
                        <th className="pb-3 pr-12 text-start min-w-[175px]">{t("STATUS")}</th>
                        <th className="pb-3 pr-12 text-start min-w-[100px]">{t("Reg.Date")}</th>
                        <th className="pb-3 text-start min-w-[50px]">{t("DETAILS")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {membersList.map((users) => (
                        <tr key={users.id} className="border-b border-dashed last:border-b-0">
                          <td className="p-1 pl-0">
                            <div className="flex flex-col justify-start">
                              <h4 className="mb-1 font-semibold transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary"> {users.name}</h4>
                            </div>
                          </td>
                          <td className="p-3 pr-0 text-start">
                            <span className="font-semibold text-light-inverse text-md/normal">{users.email}</span>
                          </td>
                          {users.status === '5' ? undefined :
                            <td className="p-3 pr-0 text-start">
                              {users.status?.toString() === '0' || users.status === null ?
                                <span className="font-semibold text-light-inverse text-md/normal text-success">
                                  {t("Active")}
                                </span>
                                : users.status?.toString() === '1' ?
                                  <span className="font-semibold text-light-inverse text-md/normal text-warning">
                                    {t("Banned")}
                                  </span>
                                  : users.status?.toString() === '2' ?
                                    <span className="font-semibold text-light-inverse text-md/normal text-danger">
                                      {t("Deactivated")}
                                    </span>
                                    : users.status === '5' ?
                                      <span className="font-semibold text-light-inverse text-md/normal text-warning">
                                        {t("Pending")}
                                      </span>
                                      : undefined
                              }
                            </td>
                          }
                          <td className="pr-0 text-start">
                            <span className="font-semibold text-light-inverse text-md/normal">{moment(users.createdAt).format("LL")}
                            </span>
                          </td>
                          <td className="p-3 pr-0 text-start">
                            <div className="flex gap-2 justify-center items-center w-full">
                              {users.status === '2' ?
                                <>
                                  <button
                                    onClick={
                                      (e) => {
                                        e.preventDefault()
                                        handleBanMember(users._id)
                                      }}
                                    className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                    <ToolTip tooltip="ban user">
                                      <FontAwesomeIcon icon={faUserSlash} color="orange" />
                                    </ToolTip>
                                  </button>
                                  <button
                                    onClick={() => handleResetNormalMember(users._id)}
                                    className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                    <ToolTip tooltip="activate user">
                                      <FontAwesomeIcon icon={faUserCheck} color="green" />
                                    </ToolTip>
                                  </button>
                                </>
                                : ""
                              }
                              {users.status === '1' ?
                                <>
                                  <button
                                    onClick={() => handleResetNormalMember(users._id)}
                                    className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                    <ToolTip tooltip="activate user">
                                      <FontAwesomeIcon icon={faUserCheck} color="green" />
                                    </ToolTip>
                                  </button>
                                  <button
                                    onClick={() => handleDeleteMember(users._id)}
                                    className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                    <ToolTip tooltip="delete">
                                      <FontAwesomeIcon icon={faTrash} color="red" />
                                    </ToolTip>
                                  </button>
                                </>
                                : ""

                              }
                              {users.status === '0' ?
                                <>
                                  <button
                                    onClick={() => handleBanMember(users._id)}
                                    className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                    <ToolTip tooltip="ban user">
                                      <FontAwesomeIcon icon={faUserSlash} color="orange" />
                                    </ToolTip>
                                  </button>
                                  <button
                                    onClick={() => handleDeleteMember(users._id)}
                                    className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                    <ToolTip tooltip="delete">
                                      <FontAwesomeIcon icon={faTrash} color="red" />
                                    </ToolTip>
                                  </button>
                                </>
                                : ""

                              }
                              {users.status === null ?
                                <>
                                  <button
                                    onClick={() => handleBanMember(users._id)}
                                    className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                    <ToolTip tooltip="ban user">
                                      <FontAwesomeIcon icon={faUserSlash} color="orange" />
                                    </ToolTip>
                                  </button>
                                  <button
                                    onClick={() => handleDeleteMember(users._id)}
                                    className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                    <ToolTip tooltip="delete">
                                      <FontAwesomeIcon icon={faTrash} color="red" />
                                    </ToolTip>
                                  </button>
                                </>
                                : ""

                              }
                              {users.status === '5' ?
                                <button
                                  onClick={() => handleDeleteMember(users._id)}
                                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                  <ToolTip tooltip="delete">
                                    <FontAwesomeIcon icon={faUserSlash} color="red" />
                                  </ToolTip>
                                </button>
                                : ""
                              }
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : (
              <NoData />
            )}
        </Card.Body>
      </Card>
      {
        deleteComfirmationMember &&
        <ModalComponent isOpen={deleteComfirmationMember} onClose={openDelete} title={<p><FontAwesomeIcon icon={faWarning} className=" text-warning " /> {t("Delete user")}</p>}>
          <label className="text-white">{t("Do you want to delete user ?")}</label>
          <button className="btn bg-green-200 mx-2 hover:bg-green-200/30 hover:text-white ring-1 ring-green-200" onClick={openDelete}>{t("Cancel")}</button>
          <button className="btn text-white bg-greslernorange mx-2 hover:bg-greslernorange/30 hover:text-white ring-1 ring-greslernorange" onClick={deleteMember}>{t("OK")}</button>
        </ModalComponent>
      }
      {
        banComfirmationMember &&
        <ModalComponent isOpen={banComfirmationMember} onClose={openBanMember} title={t("Ban user")}>
          <label className="text-white">{t("Do you want to ban?")}</label>
          <button className="btn bg-green-200 mx-2 hover:bg-green-200/30 hover:text-white ring-1 ring-green-200" onClick={openBanMember}>{t("Cancel")}</button>
          <button className="btn text-white bg-greslernorange mx-2 hover:bg-greslernorange/30 hover:text-white ring-1 ring-greslernorange" onClick={banMember}>{t("OK")}</button>
        </ModalComponent>
      }
      {resetNormalMember &&
        <ModalComponent isOpen={resetNormalMember} onClose={openResetNormal} title={t("Reset To normal user")}>

          <label className="text-white">{t("Do you want to Reset to Normal?")}</label>
          <button className="btn btn-default" onClick={openResetNormal}>{t("Cancel")}</button>
          <button className="btn btn-primary" onClick={ResetAgainMember}>{t("OK")}</button>
        </ModalComponent>
      }
      {showForm &&
        <ModalComponent isOpen={showForm} onClose={refreshComponent}
          title={<p><FontAwesomeIcon icon={faWarning} className=" text-warning mr-2" />{t("Update Institute")}</p>}>
          <UpdateInstitute _id={_id} />
        </ModalComponent>}
      {deleteComfirmation &&
        <ModalComponent isOpen={deleteComfirmation} onClose={openDelete} title={<p>
          <FontAwesomeIcon icon={faWarning} className=" text-warning " />{t("Delete user")}</p>}>
          <label className="text-white">{t("Delete Institute")}</label>
          <button className="btn btn-default" onClick={openDelete}>{t("Cancel")}</button>
          <button className="btn btn-primary" onClick={deleteInstitute}>{t("OK")}</button>
        </ModalComponent>
      }
      {banComfirmation &&
        <ModalComponent isOpen={banComfirmation} onClose={openBan} title={t("Ban user")}>
          <label className="text-white">{t("Do you want to ban the institute?")}</label>
          <button className="btn btn-default" onClick={openBan}>{t("Cancel")}</button>
          <button className="btn btn-primary" onClick={banInstitute}>{t("OK")}</button>
        </ModalComponent>
      }
      {
        resetNormal &&
        <ModalComponent isOpen={resetNormal} onClose={openResetNormal} title={t("Reset To normal user")}>
          <label className="text-white">{t("Do you want to Reset to Normal?")}</label>
          <button className="btn btn-default" onClick={openResetNormal}>{t("Cancel")}</button>
          <button className="btn btn-primary" onClick={ResetAgainInstitute}>{t("OK")}</button>
        </ModalComponent>
      }
      {
        changeAdminModal &&
        <ModalComponent isOpen={true} onClose={handleChangeAdmin} title={''}>
          <div className="bg-white mb-3 p-2 rounded-lg shadow-lg w-full">
            <div className='flex justify-around mb-3 '>
              <div>
                <h2 className="text-xl text-center text-greslernorange uppercase font-bold mb-4">{t("Change Admin")}</h2>
              </div>
            </div>
            <div className='w-full flex flex-row-reverse'>
              <div className="">
                <button
                  onClick={handleExistingUser}
                  className=" nav-button hover:drop-shadow-lg flex w-full items-center justify-center rounded-full border px-7 text-base font-bold text-white-800 !shadow-greslernorange ring-greslernorange active:ring-1 hover:ring-1">

                  {newUserInvite && <FontAwesomeIcon icon={faArrowLeft} className='text-greslernorange' />}
                  <span className='px-2'>{isExistingUser ? t('New user') : t('Exsting User')}</span>
                  {!newUserInvite && <FontAwesomeIcon icon={faArrowRight} className='text-greslernorange' />}
                </button>
              </div>
            </div>
            <form
              onSubmit={handleGenerate}
              className='p-2'
            >
              {newUserInvite &&
                <>
                  <div className="mb-1">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Contact Name")} <span className="text-red-600">*</span></label>
                    <input
                      className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                      id="contactNameAssign"
                      name="contactNameAssign"
                      type="text"
                      placeholder={t("EnterName")}
                      value={contactNameAssign}
                      onChange={(e) => setContactNameAssign(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-1">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Contact Email")} <span className="text-red-600">*</span></label>
                    <input
                      className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                      id="emailAssign"
                      name="emailAssign"
                      type="emailAssign"
                      placeholder={t("EnterEmail")}
                      value={emailAssign}
                      onChange={(e) => setEmailAssign(e.target.value.toLowerCase())}
                      required
                    />
                  </div>
                </>
              }
              {isExistingUser &&
                <div className="mb-1">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Select User")} <span className="text-red-600">*</span></label>
                  <CustomSelect allMembersList={allMembersList} selectedEmail={userSelectedEmail} setSelectedEmail={(e: string) => setUserSelectedEmail(e)} />
                </div>
              }
              <div className="md:inline-flex w-full space-y-4 md:space-y-0 px-8 py-2 text-orange items-center">
                <div className="flex justify-between w-full mx-2">
                  <button type="submit"
                    className={
                      `border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white 
                      ${loading && "bg-blue-500 text-white pointer-events-none w-full"}
                      `}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spin color="#ffffff" width="30px" height="30px" duration="1s" className='ml-20' />
                    ) : (
                      t("Invite")
                    )}
                  </button>
                </div>
              </div>
              <p className="text-red-400 text-xs text-center mt-4">
                {t("invitation link will expired after 24 hours")}
              </p>
            </form>
          </div>
        </ModalComponent>
      }
    </div>

  );
}
export default InstituteDetail;
