import React, { useEffect, useState } from 'react';
import { GetResource } from '../../../../services';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import NoData from '../NoData';
import NoRecordFound from '../../../NotRecoredFound';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default function UserAnalisis({ userEmail }) {
    const [listResource, setListResource] = useState([])
    const [totalResource, setTotalResource] = useState<Number>(0)
    const [totalLikes, setTotalLikes] = useState<Number>(0)
    const [totalViews, setTotalViews] = useState<Number>(0)
    const [totalShares, setTotalShares] = useState<Number>(0)

    const fetchByResourceEmail = async (email: string) => {
        let data = await GetResource.getresourceByemail(email);
        if (data) {
            setListResource(data);
            setTotalResource(data.length);
            data.forEach(data => {
                console.log(data, "foreach");
                setTotalLikes(prevCounter => data.likes.length + prevCounter);
                setTotalShares(prevCounter => data.sharedTo.length + prevCounter);
                setTotalViews(prevCounter => data.views + prevCounter);

            });
        }
        else {
            setListResource([]);
        }
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Basic user analisis',
            },
        },
    };

    const labels = ['Resource', 'Views', 'Share', 'Likes'];
    const data = {
        labels,
        datasets: [
            {
                label: 'Data',
                data: [totalResource, totalViews, totalShares, totalLikes],
                backgroundColor: 'rgba(150, 99, 132, 0.5)',
            },
        ],
    };
    useEffect(() => {
        fetchByResourceEmail(userEmail);
    }, [userEmail])
    return (
        <div className="flex items-center h-auto bg-white w-full">
            {listResource.length <= 0 ? <NoRecordFound /> : <Bar options={options} data={data} />}
        </div>
    );
}
