import React, { useEffect, useState } from 'react';
import GetActivity from '../../../services/GetActivity';
import { useTranslation } from "react-i18next";
import { FrequentService, GetUsers } from '../../../services';
import ActivityTable from './tables/ActivityTable';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../redux/Reducer/authSlice';
export default function Activity() {
  const { t } = useTranslation();
  const [activityList, setActivityList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const userId = useSelector(selectUserId)

  const fetchActivity = async (instId) => {
    const data = await GetActivity.getActivity(instId);
    if (data) {
      setActivityList(data);
    }
    else {
      setActivityList([]);
    }
  }
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSearchTerm(selectedValue);
  };
  const addtousedlink = async () => {
    const useddata = {
      usedlink: "activity"
    }
    try {
      await FrequentService.addusedlink(useddata);
    } catch (error) { }
  };
  const fetchInstitutesList = async () => {
    let resource = await GetUsers.getinstitutelist();
    if (resource) {
      resource.forEach((user) => {
        if (user.user[0] === userId) {
          fetchActivity(user._id);
        }
      })
    }
  };
  useEffect(() => {
    addtousedlink();
    fetchInstitutesList();
  }, []);
  return (
    <div className="left-0 w-full bg-transparent border-t border-gray-200 shadow">
      <div className="m-2 w-full">
        <div className="rounded-xl border border-gray-200 bg-transparent p-2">
          <div className="flex flex-row justify-between">
            <div>
              <p className="mt-1 text-sm">{t("Use filters to further better search")}</p>
            </div>
            <div>
              <select value={searchTerm} onChange={handleSelectChange}
                id="status" className="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                <option>{t("Select filter method")}</option>
                <option value="User Invited" >{t("Invitation")}</option>
                <option value="Profile updated">{t("Updates")}</option>
                <option value="Group created">{t("Group created")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ActivityTable activityList={activityList} filterBy={searchTerm} />
    </div>
  );
}
