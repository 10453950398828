import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetUsers } from "../../../../services";
import { formatDateToLocaleDateAndTimeString } from "../../../../utils/functions";
import TableComponent from "../../../TableComponent";
import ContentLoader from "../../../loader/ContentLoader";
import ContentHeader from "../../../root/dashboard/addon/contentHeader";
import { IUserItem } from "../../../../../../server/src/Constants/interfaces";

type userProps = {
  name: string;
  profilephoto: string;
  country: string;
  date: any;
  email: string;
  generatedTokenExpiration: Date;
  status: string;
  type: string;
};
const UsesTable = ({ users = [] }) => {
  const [membersList, setMembersList] = useState<userProps[]>([]);
  const [isUsers, setIsUsers] = useState(false)
  const { t } = useTranslation();

  const fetchlistofusers = async () => {
    setIsUsers(false)
    const data = await GetUsers.getuserlistAll();
    if (data) {
      setMembersList(data);
      setIsUsers(true)
    }
    else {
      setMembersList([]);
      setIsUsers(true)
    }
  }
  useEffect(() => {
    fetchlistofusers();
  }, []);
  // TODO move this to a component if update data works 
  const UserTableActions = (user) => (
    <button className="ml-auto relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
      <a className="hover:!text-greslernorange" href={`/admin/userdetail/${user.id}`} >
        <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
      </a>
    </button>
  )

  function formatUserData(data: any[]): IUserItem[] {

    return data.map(item => ({
      id: item._id,
      user: item.name,
      profilePhoto: item.profilephoto,
      country: item.country,
      date: formatDateToLocaleDateAndTimeString(item.createdAt),
      email: item.email,
      status: item.status == '0' || item.status === null ? 'active' : 'inactive',
      type: item.type
    }));

  }
  return (
    <div className="">
      <ContentHeader title={t("Users")} />
      <div className="">
        {isUsers ?
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
            <TableComponent data={formatUserData(membersList)} actionComponent={UserTableActions} title="" />
          </div>
          : <ContentLoader />
        }
      </div>
    </div>
  )
}
export default UsesTable;