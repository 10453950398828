import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function ButtonScroll() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    }, []);

    const goTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <button
            className='fixed bottom-[40px] text-3xl z-10 cursor-pointer text-greslernblue right-[2%] bg-none rounded-full p-0 border-2 border-greslernblue p-1 hover:bg-greslernblue hover:text-white hover:opacity-100 opacity-70'
            style={{ display: isVisible ? 'block' : 'none' }} onClick={goTop}
        >
            <FontAwesomeIcon className='px-2' icon={faArrowUp} />
        </button>
    )
}

export default ButtonScroll