import React, { useEffect, useState } from 'react'
import countries from 'countries-list';
import { useSelector, useDispatch } from 'react-redux';
import { selectRole, selectUserId } from '../../../../redux/Reducer/authSlice';
import { GetUserLogin, GetUsers } from '../../../../services';
import { NotificationManager } from "react-notifications"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { Spin } from 'react-cssfx-loading';
import { API_URL } from '../../../../apiConfig';

import ContentHeader from "../addon/contentHeader";

type profileprops = {
  profilephoto: string;
  name: string;
  email: string;
  bio: string;
  type: string;
  gender?: string;
  country: string;
  username: string;
  contentId: string;
};

const initialUserData = {
  profilephoto: "",
  name: "",
  email: "",
  bio: "",
  type: "",
  gender: "",
  country: "",
  username: "",
  contentId: "",
}

const instialPasswordData = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const ProfileEdit = () => {
  const useremail = useSelector(selectUserId);
  const userrole = useSelector(selectRole);
  const [userData, setUserData] = useState<profileprops>(initialUserData);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(instialPasswordData)
  const [image, setImage] = useState<any>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPassValid, setIsPassValid] = useState(true);
  const [maxFileSize, setMaxFileSize] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(true)

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countryList = Object.values(countries.countries);
  const minLength = 8

  const userdataupdate = async (e) => {
    e.preventDefault();

    const updatedata = {
      username: userData?.username,
      bio: userData?.bio,
      name: userData?.name,
      gender: userData?.gender,
      country: userData?.country,
    }
    try {
      let data = await GetUsers.userdataupdate(useremail, updatedata)
      if (!data.ok) {
        NotificationManager.success("Successfully updated", "User profile");
      }
      else {
        NotificationManager.warning("Profile not updated", "User profile")
      }
    } catch (error) {
      console.error('Error during feedback:', error);
    }
  }
  function refreshPage() {
    window.location.reload();
  }
  const handleCancelDelete = () => {
    toast.dismiss("delete-warning-toast");
  };

  const userpasswordupdate = async (e) => {
    e.preventDefault();
    const passwordData = {
      oldPassword: password.oldPassword,
      newPassword: password.newPassword,
    }
    if (password.newPassword !== password.confirmPassword) {
      setIsConfirmed(false)
      return;
    }
    try {
      setLoading(true);
      const data = await GetUsers.updateuserpassword(passwordData)
      if (data.status === 200) {
        logout();
        NotificationManager.success(`${data.data}`, "User password");
        setLoading(false);
      }
      else {
        NotificationManager.warning(`${data.data}`, "User password")
        setLoading(false);

      }
    } catch (error) {
      console.error('Error during change:', error);
      setLoading(false);

    }
  }

  const handleDeleteUser = async (e) => {
    e.preventDefault();
    toast.warning(
      <div>
        <p>
          {t("Are you sure you want to delete your account ?")}
        </p>
        <button
          className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 pt-2 px-2 mx-2 border-b-4 border-red-700 hover:border-red-500 rounded"
          onClick={deleteUser}
        >
          {t("Confirm")}
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 pt-2 px-2 mx-2 border-b-4 border-blue-700 hover:border-blue-500 rounded"
          onClick={handleCancelDelete}
        >
          {t("Cancel")}
        </button>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        draggable: false,
        closeButton: false,
        closeOnClick: false,
        className: "delete-warning",
        bodyClassName: "delete-warning-body",
        progressClassName: "delete-warning-progress",
        toastId: "delete-warning-toast",
        theme: "colored",
      }
    );
  }

  const deleteUser = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.deleteUser(useremail);
      if (data) {
        NotificationManager.success("Successfull", "User deletion");
        logout();
      }
      else {
        NotificationManager.warning("Not deleted", "User deletion")
      }
      refreshPage();
    } catch (error) {
      NotificationManager.warning("Profile not updated", "User profile")
    }
  }
  const updateProfilePicture = async (e) => {
    e.preventDefault();
    try {
      if (image) {
        const newdata = new FormData();
        newdata.append("photo", image);
        let data = await GetUsers.updateprofilePicture(useremail, newdata)
        if (data.status === 200) {
          NotificationManager.success("Successfully updated", "User profile")
        }
        else {
          NotificationManager.warning("Profile not updated", "User profile")
        }
      }
      else {
        NotificationManager.warning("No change", "User profile")
      }
    } catch (error) {
      console.log('Error is', error);
      NotificationManager.warning(`Not updated`, "User profile")
    }
  }
  const removeProfilePicture = async (e) => {
    e.preventDefault();

    try {
      const data = await GetUsers.removeProfilePicture(useremail)
      if (data) {
        NotificationManager.success("Successfully Removed", "Profile Picture")
      }
      else {
        setUserData(initialUserData);
      }
      refreshPage();
    } catch (error) {
      NotificationManager.warning("Profile picture not updated", "Profile Picture")
    } finally {
      setTimeout(() => {
        //   setIsUploading(false)
        //   setUploaded(true)
      }, 1000)
    }
  }

  const logout = async () => {
    let response = await GetUserLogin.getUserLogOut()
    if (response) {
      GetUserLogin.Logout(dispatch, () => {
        window.location.reload()
      })
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const handlePassword = (e) => {
    e.preventDefault();
    setPassword({ ...password, [e.target.name]: e.target.value })
    if (e.target.name === "newPassword") {
      setIsPassValid(e.target.value.length >= minLength);
    }
  }

  const handlePhoto = (e) => {
    e.preventDefault();
    const image1 = e.target.files[0];
    const maxSize = 1048576;

    if (image1 && image1.size > maxSize) {
      setMaxFileSize(true)
      e.target.value = '';
    }
    else if (image1) {
      setMaxFileSize(false)
      setImage(image1);
      setPreviewImage(URL.createObjectURL(image1));
    }
  }
  const getuserdata = async (useremail) => {
    let data = await GetUsers.getuserdataById(useremail)
    if (data) {
      setUserData(data);
      setEmail(data.email);
    }
    else {
      setUserData(initialUserData)
    }
  }
  useEffect(() => {
    getuserdata(useremail);
  }, [dispatch])
  return (
    <div className="w-full h-auto">
      <div className="py-[1px] md:px-[2px]">
        {/* {userData.map((data, index) => ( */}
        <div className="w-full h-auto" >
          <div className="py-[16px] px-2 md:px-[32px]">
            <ContentHeader title={t("Edit Profile")} />

            {/* <div className="font-bold text-[24px] mt-[10px] pb-[20px]">
                <h1 className="mb-2 text-white">{t("Edit Profile")}</h1>
                <hr />
              </div> */}
            <div className="grid md:grid-cols-12 md:mt-2 grid-cols-6">
              <div className="col-span-3 p-2">
                <h2 className="text-white text-[18px] mb-2">
                  {t("General Setting")}
                </h2>
              </div>
              <div className="col-span-9 bg-white rounded-[0.375rem] p-3">
                <div className='sm:flex-wrap'>
                  <div>
                    <h2 className="text-[18px] mb-2 font-bold">
                      {t("General Setting")}
                    </h2>
                  </div>
                  <div>
                    <form encType="multipart/form-data">
                      <div className="grid grid-cols-12 py-6">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("Profile")}
                        </h2>
                        <div className="col-span-9 flex items-center">
                          {previewImage ? (
                            <img
                              src={previewImage}
                              alt="Selected"
                              className="w-20 h-20 border-2 border-white rounded-full mr-8 sm:w-15 sm:h-15"
                            />
                          ) : (
                            <>
                              {userData?.profilephoto ? (
                                <img
                                  src={`${API_URL}/public/images/${userData?.profilephoto}`}
                                  alt={'Profile'}
                                  className="w-20 h-20 border-2 border-white rounded-full mr-8"
                                />
                              ) : (
                                <div className="w-20 h-20 border-2 border-white dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transitio rounded-full bg-greslernorange flex items-center justify-center content-center text-white text-5xl text-center sm:w-15 sm:h-15">
                                  <p>
                                    {userData?.name?.charAt(0).toUpperCase()}
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            name="photo"
                            onChange={handlePhoto}
                            className="border border-1 border-solid !border-greslernorange text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 hover:text-white hover:!bg-greslernorange"
                          />
                          {maxFileSize && (
                            <div className="text-red-500 text-sm">{t("File size exceeds the maximum allowed size(>1MB).")}</div>
                          )}
                          {userData?.profilephoto ? (
                            <button
                              onClick={removeProfilePicture}
                              className="border border-1 border-solid !border-[#fc0303] text-[#fc0303]  rounded-[0.375rem] px-3 py-2 mr-5 hover:text-white hover:!bg-[#fc0303]">
                              {t("Remove")}
                            </button>
                          ) : undefined}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2"> </h2>
                        <div className="col-span-9 flex justify-start">
                          <button
                            onClick={updateProfilePicture}
                            disabled={!previewImage}
                            className="border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 text-white bg-greslernorange">
                            {t("Save Profile")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='sm:flex-wrap'>
                  <div>
                    <h2 className="text-[18px] mb-6 font-bold">
                      {t("Basic Information")}
                    </h2>
                  </div>
                  <div>
                    <form>
                      <div className="grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("Bio")}{" "}
                        </h2>
                        <textarea
                          rows={2}
                          cols={4}
                          onChange={handleChange}
                          value={userData?.bio}
                          name="bio"
                          className="col-span-9 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                          placeholder={t("Enter bio")}
                        >
                        </textarea>
                      </div>
                      <div className="grid grid-cols-12 py-2 items-center">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("Name")}{" "}
                        </h2>
                        <input
                          type="text"
                          name='name'
                          onChange={handleChange}
                          value={userData?.name}
                          className="col-span-9 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                          placeholder={t("Enter name")}
                          required
                        />
                      </div>
                      <div className="grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("User name")}{" "}
                        </h2>
                        <input
                          id="username"
                          name="username"
                          type="text"
                          onChange={handleChange}
                          value={userData?.username}
                          className="col-span-9 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                          placeholder={t("Enter username")}
                          required
                        />
                      </div>
                      <div className="grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("Gender")}{" "}
                        </h2>
                        <select
                          required
                          onChange={handleChange}
                          value={userData?.gender}
                          name='gender'
                          className="col-span-9 border-solid border-2 py-2 px-3 rounded-[0.375rem]">
                          <option
                            className="text-[#292929]"
                            key={1}
                            value="female">
                            {t("Female")}
                          </option>
                          <option
                            className="text-[#292929]"
                            key={2}
                            value="male">
                            {t("Male")}
                          </option>
                          <option
                            className="text-[#292929]"
                            key={3}
                            value="I rather not to say">
                            {t("I rather not to say")}
                          </option>
                        </select>
                      </div>
                      <div className="grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("Country")}{" "}
                        </h2>
                        <select
                          required
                          id="country"
                          name="country"
                          value={userData?.country}
                          onChange={handleChange}
                          className="col-span-9 border-solid border-2 py-2 px-3 rounded-[0.375rem]">
                          {countryList.map((country) => (
                            <option
                              className="text-[#292929]"
                              key={country.name}
                              value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2"> </h2>
                        <div className="col-span-9 flex justify-start">
                          <button
                            onClick={userdataupdate}
                            className="border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 text-white bg-greslernorange">
                            {t("Save Change")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>

              </div>
            </div>
            <div className="grid md:grid-cols-12 md:mt-2 grid-cols-6">
              <div className="col-span-3 p-2">
                <h2 className="text-white text-[18px] mb-2">
                  {t("Password Setting")}
                </h2>
              </div>
              <div className="col-span-9 bg-white rounded-[0.375rem] p-3">
                <div className='sm:flex-wrap'>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    userpasswordupdate(e);
                  }}
                  >
                    {/* <div className="grid grid-cols-12 w-full my-4 sm:flex-wrap"> */}
                    {/* <div className="col-span-3 p-2">
                            <h2 className="text-white text-[18px] mb-2">
                              {t("Password Setting")}
                            </h2>
                          </div> */}
                    <div className="col-span-9 bg-white rounded-[0.375rem] p-3">
                      <h2 className="text-[18px] mb-6 font-bold">
                        {t("Change your password")}{" "}
                      </h2>
                      <div className="relative grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("Current password")}{" "}
                        </h2>
                        <input
                          id="oldPassword"
                          name='oldPassword'
                          value={password.oldPassword}
                          type={showPassword ? "text" : "password"}
                          placeholder={t("Enter current password")}
                          onChange={(e) => handlePassword(e)}
                          className={`password relative border-2 w-full py-[10px] px-[15px] outline-none rounded-[5px] text-black text-base tracking-wider bg-white placeholder:text-black col-span-9 border-solid !border-g-gray-200`}
                          required
                        />
                        <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowPassword(!showPassword) }}>
                          <FontAwesomeIcon icon={!showPassword ? faEyeLowVision : faEye} />
                        </button>
                      </div>
                      <div className="relative grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("New password")}{" "}
                        </h2>
                        <input
                          id="newPassword"
                          name='newPassword'
                          onChange={handlePassword}
                          value={password.newPassword}
                          max={30}
                          type={showNewPassword ? "text" : "password"}
                          placeholder={t("Enter new password")}
                          className={`password relative border-2 w-full py-[10px] px-[15px] outline-none rounded-[5px] text-black text-base tracking-wider bg-white placeholder:text-black col-span-9 border-solid ${isPassValid ? '!border-g-gray-200' : '!border-red-500'}`}
                          required
                        />
                        <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowNewPassword(!showNewPassword) }}>
                          <FontAwesomeIcon icon={!showPassword ? faEyeLowVision : faEye} />
                        </button>
                      </div>
                      {!isPassValid && (
                        <div className='text-red-500 text-sm'>
                          {t("passwordlength")} {minLength} {t("characterlong")}
                        </div>
                      )}
                      <div className="relative grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2">
                          {t("Confirm new password")}{" "}
                        </h2>
                        <input
                          id="confirmPassword"
                          name='confirmPassword'
                          value={password.confirmPassword}
                          onChange={handlePassword}
                          type={showConfirmPassword ? "text" : 'password'}
                          placeholder={t("Enter new password again")}
                          className={`password relative border-2 w-full py-[10px] px-[15px] outline-none rounded-[5px] text-black text-base tracking-wider bg-white placeholder:text-black col-span-9 border-solid ${isConfirmed ? '!border-g-gray-200' : '!border-red-500'}`}
                          required
                        />
                        <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowConfirmPassword(!showConfirmPassword) }}>
                          <FontAwesomeIcon icon={!showConfirmPassword ? faEyeLowVision : faEye} />
                        </button>
                      </div>
                      {!isConfirmed && (
                        <div className='text-red-500 text-sm'>
                          {t("confirmation password is not the same to the new password")}
                        </div>
                      )}
                      <div className="grid grid-cols-12 py-2 flex items-center">
                        <h2 className="col-span-3 text-[16px] mb-2"> </h2>
                        <div className="col-span-9 flex justify-start">
                          <button
                            type="submit"
                            // onClick={userpasswordupdate}
                            disabled={!password.newPassword}
                            className={
                              `border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 text-white bg-greslernorange`}>
                            {loading ? (<Spin color="#ffffff" width="30px" height="30px" duration="1s" />) : t("Change Password")}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </form>
                </div>

              </div>
            </div>
            {userrole != 'institute' &&
              <div className="grid md:grid-cols-12 md:mt-2 grid-cols-6">
                <div className="col-span-3 p-2">
                  <h2 className="text-white text-[18px] mb-2">
                    {t("Delete Account")}
                  </h2>
                </div>
                <div className="col-span-9 bg-white rounded-[0.375rem] p-3">
                  <div className='sm:flex-wrap'>
                    <div>
                      <h2 className="text-[18px] mb-2 font-bold">
                        {t("Delete Account")}
                      </h2>
                    </div>
                  </div>
                  <div className='sm:flex-wrap'>
                    <div className="grid grid-cols-12 py-2 flex items-center">
                      <p className="col-span-12 text-[16px] text-[#637381] mb-2">
                        {t(
                          "Delete any and all content you have, such as articles, feedbacks, your reading list or chat messages. Allow your username to become available to anyone"
                        )}
                        .{" "}
                      </p>
                    </div>
                    <div className="grid grid-cols-12 py-2 flex items-center">
                      <div className="col-span-12 flex justify-start">
                        <button
                          onClick={handleDeleteUser}
                          className="border border-1 border-solid !border-[#fc0303] rounded-[0.375rem] px-3 py-2 mr-5 text-white !bg-[#fc0303] hover:!shadow-lg hover:!shadow-[#fc0303] hover:scale-105">
                          {t("Delete Account")}
                        </button>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            }
          </div>
        </div>
        {/* ))} */}
      </div>
    </div>
  );
}

export default ProfileEdit