import React, { useEffect, useState } from 'react';
import ModalComponent from '../../../ModalComponent';
import { useTranslation } from 'react-i18next';
import { Spin } from 'react-cssfx-loading';
import CreateGroupInstitute from '../../../../services/CreateGroupInstitute';
import { NotificationManager } from "react-notifications";

export default function EditMainGroup({groupIdNumber,updatecomponetu}) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [updateComponent,setUpdateComponents] = useState(false);
    const handleUpdate = async (groupIdNumber: any) => {
        try {
            const updateData = {
                groupname:groupName,
                description:groupDescription
            }
            setLoading(true);
            const data = await CreateGroupInstitute.editMainGroup(groupIdNumber, updateData);
            if (data) {
                NotificationManager.success("Successfull updated", "Group");
                setUpdateComponents(!updateComponent);
                setLoading(false);
                updatecomponetu();

            }
            else {
                NotificationManager.warning("Not updated", "Group")
                setLoading(false);
                setUpdateComponents(!updateComponent);
            }
        } catch (error) {
            NotificationManager.warning("Not deleted", "Group")
            setLoading(false);
            setUpdateComponents(!updateComponent);
        }
    }
    const fetchGroupById = async (groupIdNumber: any)=>{
        let data = await CreateGroupInstitute.fetchMainGroup(groupIdNumber);
        if (data) {
          setGroupName(data.groupname);
          setGroupDescription(data.description);
         }
    }
    useEffect(() => {
        fetchGroupById(groupIdNumber);
    }, []);
    return (
            <form
                onSubmit={(e)=>{
                    e.preventDefault();
                    handleUpdate(groupIdNumber);
                }
                }
            >
                <div className="mb-1">
                    <label className="block text-white text-sm font-semibold mb-2">{t("Group name")} <span className="text-red-600">*</span> </label>
                    <input type="text" id="groupName"
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                        required
                        placeholder="Enter Sub group Name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </div>
                <div className="mb-1">
                    <label className="block text-white text-sm font-semibold mb-2">{t("Group Description")} 
                        <span className="text-red-600">*</span> </label>
                    <input type="text" id="groupDescription"
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                        required
                        placeholder="Enter Sub group Name"
                        value={groupDescription}
                        onChange={(e) => setGroupDescription(e.target.value)}
                    />
                </div>
                <button
                    className={
                        `w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                      ${loading
                            ? "bg-[#2d2d39] text-white pointer-events-none"
                            : "w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                        }
                      `}
                    type="submit"
                    disabled={loading}>
                    {loading ? (
                        <Spin color="#ffffff" width="30px" height="30px" duration="1s" />
                    ) : (
                        t("Update")
                    )}
                </button>
            </form>
    );
}
