import { faClose, faEdit, faArrowDown, faArrowUp, faUsers, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import CreateGroupInstitute from '../../../../services/CreateGroupInstitute';
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import ListMembers from './ListMembers';
import ModalComponent from '../../../ModalComponent';
import EditGroup from './EditGroup';
import ContentHeader from '../../../root/dashboard/addon/contentHeader';
import EditMainGroup from './EditMainGroup';
import ListMembersResource from './ListMembersResource';
import ContentLoader from '../../../loader/ContentLoader';
import NoData from '../NoData';
import { useSelector } from 'react-redux';
import { selectInstitute } from '../../../../redux/Reducer/authSlice';
import NoRecordFound from '../../../NotRecoredFound';
type groupProps = {
    subgroup: any;
    groupname: string;
    description: string;
    _id: string;
}
function ListItem({ item, isShowRes, updatecomponetu }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();
    const [listUsersingroup, setListUsersingroup] = useState(false);
    const [result, setResult] = useState([]);
    const [updateList, setUpdateList] = useState(true);
    const [deleteConfirmationGroup, setDeleteConfirmationGroup] = useState(false);
    const [deleteConfirmationSubGroup, setDeleteConfirmationSubGroup] = useState(false);
    const instituteId = useSelector(selectInstitute)
    const [editGroup, setEditGroup] = useState(false);
    const [groupidtodelete, setGroupidtodelete] = useState();
    const [groupIdNumber, setGroupIdNumber] = useState();
    const [usergroupid, setUsergroupid] = useState();
    const [mainGroupidtodelete, setMainGroupidtodelete] = useState('');
    const [editMainGroup, setEditMainGroup] = useState(false);
    const [maingroupIdNumber, setMainGroupIdNumber] = useState();

    const handleListUsersingroup = (usergroupid: any) => {
        setUsergroupid(usergroupid);

        setListUsersingroup(!listUsersingroup);
    }

    const handleListMember = () => {
        setListUsersingroup(false);
        setUpdateList(!updateList);
    }
    const deleteGroup = async (deletegroupid: any) => {
        try {

            const data = await CreateGroupInstitute.deleteGroup(deletegroupid);
            if (data) {
                NotificationManager.success("Successfull deleted", "Group");
                updatecomponetu();
                setDeleteConfirmationGroup(false);

            }
            else {
                NotificationManager.warning("Not deleted", "Group")
                updatecomponetu();
                setDeleteConfirmationGroup(false);

            }
        } catch (error) {
            NotificationManager.warning("Not deleted", "Group")
            updatecomponetu();
            setDeleteConfirmationGroup(false);

        }
        setUpdateList(!updateList);
    }
    const deletemainGroup = async (maingroupid: any) => {
        try {
            const data = await CreateGroupInstitute.deleteMainGroup(maingroupid);
            if (data) {
                NotificationManager.success("Successfull deleted", "user");
                updatecomponetu();
                setDeleteConfirmationGroup(false);

            }
            else {
                NotificationManager.warning("Not deleted", "user")
                updatecomponetu();
                setDeleteConfirmationGroup(false);

            }
        } catch (error) {
            NotificationManager.warning("Not deleted", "user")
            updatecomponetu();
            setDeleteConfirmationGroup(false);

        }
        setUpdateList(!updateList);


    }

    const fetchSubGroupList = async () => {
        let resource = await CreateGroupInstitute.getMainGroupList(instituteId);
        if (resource) {
            const options = resource.map((user) => ({
                id: user._id,
                name: user.groupname,
                description: user.description,
            }));
            setResult(options);
        }
    };
    const handleDeleteGroup = async (deletegroupid) => {
        setDeleteConfirmationSubGroup(true);
        setGroupidtodelete(deletegroupid);
    }
    const handleClose = () => {
        setDeleteConfirmationGroup(false)
        setEditGroup(false)
        setEditMainGroup(false)
    };
    const handleEdit = (groupidnumber) => {
        setGroupIdNumber(groupidnumber);
        setEditGroup(!editGroup);
    }
    const handleMainGroupEdit = (mainidnumber) => {
        setMainGroupIdNumber(mainidnumber);
        setEditMainGroup(!editMainGroup);
    }
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const deleteMainGroup = (maingroupid: string) => {
        setDeleteConfirmationGroup(true);
        setMainGroupidtodelete(maingroupid);
    };
    useEffect(() => {
        fetchSubGroupList();
    }, []);

    useEffect(() => {
        handleClose()
    }, [result])
    return (
        <div>
            <div style={{ cursor: 'pointer' }}>
                <div className='flex justify-between'>
                    <div>
                        <span className='text-greslernblue font-bold uppercase text-xl'>
                            <FontAwesomeIcon icon={faUsers} className="relative pl-2  rounded-full transform hover:scale-150" />
                            {" "}{item.groupname}
                        </span>
                    </div>
                    <div className='mr-7 text-greslernblue font-bold flex gap-2' >
                        <button onClick={(e) => {
                            e.preventDefault()
                            toggleExpand()
                        }}>
                            {
                                isExpanded ?
                                    <FontAwesomeIcon icon={faArrowUp} className="relative w-3 h-3 rounded-full transform hover:scale-150 p-1" />
                                    :
                                    <FontAwesomeIcon icon={faArrowDown} className="relative w-3 h-3 rounded-full transform hover:scale-150 p-1" />
                            }
                        </button>
                        {isExpanded &&
                            <div className='flex gap-2'>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    deleteMainGroup(item._id);
                                }}>
                                    <FontAwesomeIcon icon={faClose} className="relative w-3 h-3 rounded-full transform hover:scale-150 hover:ring-1 ring-red p-1" />
                                </button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    handleMainGroupEdit(item._id);
                                }}>
                                    <FontAwesomeIcon icon={faEdit} className="relative w-3 h-3 rounded-full transform hover:scale-150 hover:ring-1 ring-greslernorange p-1 " />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {isExpanded &&
                <>
                    {item.subgroup?.map((subname, index) =>
                        <div className="flex flex-row justify-between border-b border-greslernorange/20 rounded-md hover:bg-greslernorange/10 p-1" key={index}>
                            <div className="flex flex-row space-x-1">
                                <div className='text-greslernorange hover:bg-white p-1 rounded-md'>
                                    <FontAwesomeIcon icon={faUsers} className="relative pl-2  rounded-full" />
                                    {/* <FontAwesomeIcon icon={faArrowAltCircleRight} color="orange" /> */}

                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        handleListUsersingroup(subname._id)
                                    }}
                                    >
                                        <p className="max-w-screen-sm text-sm ml-2">{subname.name}</p>
                                    </button>
                                </div>
                            </div>
                            <div className="text-greslernorange text-sm mr-5">

                                <button onClick={() => handleDeleteGroup(subname._id)}>
                                    <FontAwesomeIcon icon={faClose} className="relative pl-2 w-3 h-3 rounded-full transform hover:scale-150" />
                                </button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    handleEdit(subname._id);
                                }}>
                                    <FontAwesomeIcon icon={faEdit} className="relative pl-2 w-3 h-3 rounded-full transform hover:scale-150" />
                                </button>
                            </div>
                        </div>
                    )}
                </>
            }
            <div className="flex justify-left place-items-center gap-1 m-1 text-xs text-blue-500 opacity-50 hover:!opacity-100">
                <div className="text-greslernorange rounded-full transform hover:scale-150bg-white/50 flex w-3 h-3 justify-center place-items-center">
                    <span>{item.subgroup?.length}</span>
                </div>
                <span className="text-meta-7">{t("Sub group")}</span>
            </div>
            <ModalComponent isOpen={deleteConfirmationGroup} title={<p><FontAwesomeIcon icon={faWarning} color='orange' /> warning</p>} onClose={handleClose} >
                <div className="flex justify-evenly text-white">
                    <div>
                        <label>{t("Do you want to delete the main Group?")}</label>
                    </div>
                    <div className='flex gap-1'>
                        <button className="bg-red-400 p-1 rounded-md hover:bg-red-200 hover:text-red-500 hover:ring-1 ring-red-500 outline-offset-3" onClick={() => { deletemainGroup(mainGroupidtodelete) }}>{t("OK")}</button>
                        <button className="bg-green-400 p-1 rounded-md hover:bg-green-200 hover:text-green-500  hover:ring-1 ring-green-500 outline-offset-3" onClick={handleClose}>{t("cancel")}</button>
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent isOpen={deleteConfirmationSubGroup} title={<p><FontAwesomeIcon icon={faWarning} color='orange' /> warning</p>} onClose={handleClose} >
                <div className="flex justify-evenly text-white">
                    <div>
                        <label>{t("Do you want to delete the Group?")}</label>
                    </div>
                    <div className='flex gap-1'>
                        <button className="bg-red-400 p-1 rounded-md hover:bg-red-200 hover:text-red-500 hover:ring-1 ring-red-500 outline-offset-3" onClick={() => { deleteGroup(groupidtodelete) }}>{t("OK")}</button>
                        <button className="bg-green-400 p-1 rounded-md hover:bg-green-200 hover:text-green-500  hover:ring-1 ring-green-500 outline-offset-3" onClick={handleClose}>{t("cancel")}</button>
                    </div>
                </div>
            </ModalComponent>
            {/* } */}
            {listUsersingroup &&
                <>
                    {isShowRes ?
                        <ListMembersResource groupName={item.groupname} usergroupid={usergroupid} details={item.subgroup} handleListMember={handleListMember} updatecomponetu={updatecomponetu} />
                        :
                        <ListMembers groupName={item.groupname} usergroupid={usergroupid} handleListMember={handleListMember} updatecomponetu={updatecomponetu} />}
                </>
            }
            {editGroup && <EditGroup groupIdNumber={groupIdNumber} updatecomponetu={updatecomponetu} />}
            {editMainGroup &&
                <ModalComponent isOpen={editMainGroup} title={"Main Group Edit"} onClose={handleClose}>
                    <EditMainGroup groupIdNumber={maingroupIdNumber} updatecomponetu={updatecomponetu} />
                </ModalComponent>
            }
        </div>
    );
}

function GroupView({ isShowRes }) {
    const { t } = useTranslation();
    const [items, setItems] = useState<groupProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const instituteId = useSelector(selectInstitute)
    const [updateComponent, setUpdateComponent] = useState(false);


    const fetchGroupList = async () => {
        setIsLoading(true);
        const data = await CreateGroupInstitute.getGroupList(instituteId);
        if (data) {
            setItems(data);
            setIsLoading(false);
        }
        else {
            setIsLoading(false);

        }
    }
    const updateGroupComponent = () => {
        setUpdateComponent(!updateComponent);
    }
    useEffect(() => {
        fetchGroupList();
    }, [updateComponent]);
    return (
        <div className="flex flex-col gap-2">
            <ContentHeader title={t("institution groups")} showIcon={false} />
            {isLoading ? <ContentLoader /> :
                <>
                    {items.length <= 0 ? <NoRecordFound /> : <>
                        {items.map((item, index) => (
                            <>
                                <div className="flex">
                                    <div className="w-full mt-2 p-1 border-b border-l border-spacing-2 border-greslernblue rounded-md mb-2 hover:bg-greslernblue/10">
                                        <ListItem key={index} item={item} isShowRes={isShowRes} updatecomponetu={updateGroupComponent} />
                                    </div>
                                </div>
                            </>
                        ))}
                    </>}

                </>
            }
        </div>
    );
}

export default GroupView;