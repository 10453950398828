import React, { useState, Fragment, useLayoutEffect, useRef } from 'react';
import './CircleNav.css'; // Import the CSS file
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import user from '../assets/user.svg';
import lock from '../assets/lock.svg';
import language from '../assets/language.svg';
import bell from '../assets/bell.svg';
import setting from '../assets/maintenance.svg';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { GetUserLogin, GetUsers } from '../../services';
import { selectEmail, selectUserName, selectRole } from '../../redux/Reducer/authSlice';
import Languages from '../../constances/languages.json';
import { Link } from 'react-router-dom';
import { API_URL } from '../../apiConfig';
import NotificationList from '../NotificationList';
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside';

interface User {
  id: string;
  name: string;
  email: string;
  profilephoto: string | null;
  gender: string;
  country: string;
  username: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface CircleNavProps {
  isActive: boolean;
  toggleNav: () => void;
}

const CircleNav: React.FC<CircleNavProps> = ({ isActive, toggleNav }) => {
  const { i18n, t } = useTranslation();
  const [notificationCount, setNotificationCount] = useState(0);
  const [userData, setUserData] = useState<User>({
    id: "",
    name: "",
    email: "",
    profilephoto: null,
    gender: "",
    country: "",
    username: "",
  });

  const dispatch = useDispatch();
  const likes = useSelector((state: any) => state.socket.likes); // Access likes from Redux store
  const comments = useSelector((state: any) => state.socket.comments);
  const tags = useSelector((state: any) => state.socket.tags);
  const shares = useSelector((state: any) => state.socket.shares);
  const review = useSelector((state: any) => state.socket.review);
  const useremail = useSelector(selectEmail);
  const username = useSelector(selectUserName);
  const usertype = useSelector(selectRole);
  const [logo, setLogo] = useState<string>('');

  const logout = async () => {
    let response = await GetUserLogin.getUserLogOut();
    if (response) {
      GetUserLogin.Logout(dispatch, () => {
        console.log('logout');
      });
    }
  };

  const getuserdata = async (useremail: string) => {
    let data = await GetUsers.getuserdata(useremail);
    if (data) {
      setLogo(`${API_URL}/public/images/${data[0].profilephoto}`)
      setUserData(data[0]);
    } else {
      setUserData({
        id: "",
        name: "",
        email: "",
        profilephoto: null,
        gender: "",
        country: "",
        username: "",
      });
    }
  };

  useLayoutEffect(() => {
    getuserdata(useremail);
  }, [useremail]);

  const resetNotificationState = () => {
    setNotificationCount(0);
  };

  const handleChangeLanguage = (lng: string, name: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    localStorage.setItem("languageName", name);
  };

  const closeNav = () => {
    isActive && toggleNav();
  };

  const contextMenuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(contextMenuRef, closeNav);

  return (
    <nav id="c-circle-nav" ref={contextMenuRef} className={`flex justify-center items-center z-[1000] w-[30px] h-[30px] rounded-[24px] sm:w-[96px] sm:h-auto sm:rounded-[48px] ${isActive ? 'is-active' : ''}`}>
      <div>
        <button
          id="c-circle-nav__toggle"
          className="group block relative z-[100] m-0 p-0 w-[30px] h-[30px] bg-transparent rounded-[24px] transition duration-300 ease-in-out cursor-pointer appearance-none shadow-none sm:w-[55px] sm:h-[55px] sm:rounded-[55px]"
          onClick={toggleNav}>
          {userData?.profilephoto ? (
            <img
              src={logo}
              alt="Profile"
              className="btn-setting text-greslernorange w-[30px] h-[30px] md:w-[50px] md:h-[50px] rounded-full border-[2px] border-solid border-greslernorange"
            />
          ) : (
            <div className="border-[2px] rounded-full border-solid border-white w-[30px] h-[30px] md:w-[50px] md:h-[50px] flex items-center btn-setting bg-greslernorange text-white content-center text-2xl text-center justify-center align-middle self-center">
              <p>{userData?.name?.charAt(0).toUpperCase()}</p>
            </div>
          )}
          <div className="absolute w-4 h-4 right-0 md:right-2 bottom-1 md:bottom-0 rounded-full bg-transparent text-blue-400 text-sm text-center leading-4">
            <FontAwesomeIcon
              icon={faGear}
              className="transform rotate-0 transition-transform duration-1000 ease-in-out group-hover:transform group-hover:rotate-[90deg] group-hover:transition-transform group-hover:duration-1000"
            />
          </div>

          {(comments > 0 || likes > 0 || tags || shares > 0 || review > 0) && (
            <div className="animate-pulse absolute bottom-auto left-auto right-3/4 top-2 z-10 inline-block -translate-y-1/2 translate-x-1/4 rounded-full bg-red py-1 px-2 text-center align-baseline text-sm leading-none text-white">
              {comments + tags + likes + shares + review > 99 ? '99+' : comments + tags + likes + shares + review}
            </div>
          )}
        </button>

        <ul className="block list-none absolute z-[90] m-0 p-0">
          <li
            className="c-circle-nav__item block absolute top-0 left-0 w-[48px] h-[48px] rounded-[24px] tool-tip fade"
            data-title={username}>
            <Link
              to={(usertype === 'teacher' || usertype === "student") ? '/home/dashboard/myprofile' : `/${usertype}/myprofile`} className="flex justify-center w-full h-full rounded-[24px] sm:rounded-[48px] bg-greslernorange box-border shadow-none border-2 border-greslernorange hover:border-purple hover:bg-purple"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                className="block max-w-full h-auto"
                style={{ width: "35px", height: "35px" }}
                src={user}
                alt={""}
              />
            </Link>
          </li>
          <li
            className="c-circle-nav__item block absolute top-0 left-0 w-[48px] h-[48px] rounded-[24px] tool-tip fade"
            data-title={t("Languages")}>
            <Menu
              as="div"
              className="relative inline-block text-left h-full w-full">
              <Menu.Button className="w-full h-full">
                <div
                  className="flex justify-center w-full h-full rounded-[24px] sm:rounded-[48px] bg-greslernorange box-border shadow-none border-2 border-greslernorange hover:border-pink hover:bg-pink"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <img
                    className="block max-w-full h-auto"
                    style={{ width: "35px", height: "35px" }}
                    src={language}
                    alt={""}
                  />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {Languages?.map((item) => (
                      <Menu.Item key={item.id}>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? "bg-[#FF6347] text-white"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}

                            onClick={() =>
                              handleChangeLanguage(
                                item.value,
                                item.name
                              )
                            }>
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
          <li
            className="c-circle-nav__item block absolute top-0 left-0 w-[48px] h-[48px] rounded-[24px] tool-tip fade"
            data-title={t("Notification")}>
            {comments + likes + tags + shares + review > 0 && (
              <div className=" flex items-center justify-center  rounded-full py-1 px-2 h-5 absolute top-[0.2rem] -left-3 bg-red  z-[10] ">
                <span className="font-semibold text-white">
                  {comments + tags + likes + shares + review > 99 ? '99+' : comments + tags + likes + shares + review}
                </span>
              </div>
            )}
            <Menu
              as="div"
              className="relative inline-block text-left h-full w-full">
              <Menu.Button
                className="w-full h-full"
                onClick={resetNotificationState}>
                <div
                  className="flex justify-center w-full h-full rounded-[24px] sm:rounded-[48px] bg-greslernorange box-border shadow-none border-2 border-greslernorange hover:border-limegreen hover:bg-limegreen"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <img
                    className="block max-w-full h-auto"
                    style={{ width: "35px", height: "35px" }}
                    src={bell}
                    alt={"new"}
                  />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-gray-900 bg-opacity-60 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className=" max-h-fit ">
                    <NotificationList />
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
          <li
            className="c-circle-nav__item block absolute top-0 left-0 w-[48px] h-[48px] rounded-[24px] tool-tip fade"
            data-title={t("Setting")}>
            <Link
              to={(usertype === "teacher" || usertype === "student") ? "/home/dashboard/mysetting" : 'mysetting'}
              className="flex justify-center w-full h-full rounded-[24px] sm:rounded-[48px] bg-greslernorange box-border shadow-none border-2 border-greslernorange hover:border-yellow hover:bg-yellow"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                className="block max-w-full h-auto"
                style={{ width: "35px", height: "35px" }}
                src={setting}
                alt={""}
              />
            </Link>
          </li>
          <li
            className="c-circle-nav__item block absolute top-0 left-0 w-[48px] h-[48px] rounded-[24px] tool-tip fade cursor-pointer"
            data-title={t("Logout")}>
            <div
              className="flex justify-center w-full h-full rounded-[24px] sm:rounded-[48px] bg-greslernorange box-border shadow-none border-2 border-greslernorange hover:border-red hover:bg-red"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={logout}>
              <img
                className="block max-w-full h-auto"
                style={{ width: "35px", height: "35px" }}
                src={lock}
                alt={""}
              />
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};


export default CircleNav;