import { useEffect } from 'react'
import _ from 'lodash'

import LottieAnimation from '../lottieAnimation';
import animationData from '../assets/lottie/login.json';

function LoginAnimation({ children, faceRef, usernameInputRef, passwordInputRef }) {

    // const handleUsernameFocus = () => {
    //     // Your focus event handling logic here
    //     if (usernameInputRef.current) {
    //         const length = Math.min(usernameInputRef.current.value.length - 16, 19);
    //         document.querySelectorAll(".hand").forEach((hand) => {
    //             hand.classList.remove("hide");
    //             hand.classList.remove("peek");
    //         });

    //         if (faceRef.current) { // Check if faceRef.current is not null
    //             faceRef.current.style.setProperty("--rotate-head", `${-length}deg`);
    //         }
    //     }
    // };

    // const handleUsernameBlur = () => {
    //     // Your blur event handling logic here
    //     if (faceRef.current) { // Check if faceRef.current is not null
    //         faceRef.current.style.setProperty("--rotate-head", "0deg");
    //     }
    // };

    // const handleUsernameInput = _.throttle((event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (usernameInputRef.current && faceRef.current) {
    //         const length = Math.min(event.target.value.length - 16, 19);
    //         const face = faceRef.current;

    //         face.style.setProperty("--rotate-head", `${-length}deg`);
    //     }
    // }, 100);

    // const handlePasswordFocus = () => {
    //     // Your focus event handling logic here
    //     if (passwordInputRef.current) {
    //         document.querySelectorAll(".hand").forEach((hand) => {
    //             hand.classList.add("hide");
    //         });
    //         const tongue = document.querySelector(".tongue");
    //         if (tongue) {
    //             tongue.classList.remove("breath");
    //         }
    //     }
    // };

    // const handlePasswordBlur = () => {
    //     // Your blur event handling logic here
    //     if (passwordInputRef.current) {
    //         document.querySelectorAll(".hand").forEach((hand) => {
    //             hand.classList.remove("hide");
    //             hand.classList.remove("peek");
    //         });
    //         const tongue = document.querySelector(".tongue");
    //         if (tongue) {
    //             tongue.classList.add("breath");
    //         }
    //     }
    // };

    // const handleShowPasswordClick = () => {
    //     // Your click event handling logic here
    //     if (passwordInputRef.current) {
    //         const passwordInput = passwordInputRef.current;
    //         if (passwordInput.type === "text") {
    //             passwordInput.type = "password";
    //             document.querySelectorAll(".hand").forEach((hand) => {
    //                 hand.classList.remove("peek");
    //                 hand.classList.add("hide");
    //             });
    //         } else {
    //             passwordInput.type = "text";
    //             document.querySelectorAll(".hand").forEach((hand) => {
    //                 hand.classList.remove("hide");
    //                 hand.classList.add("peek");
    //             });
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (usernameInputRef.current) {
    //         usernameInputRef.current.addEventListener("focus", handleUsernameFocus);
    //         usernameInputRef.current.addEventListener("blur", handleUsernameBlur);
    //         usernameInputRef.current.addEventListener("input", handleUsernameInput);
    //     }

    //     if (passwordInputRef.current) {
    //         passwordInputRef.current.addEventListener("focus", handlePasswordFocus);
    //         passwordInputRef.current.addEventListener("blur", handlePasswordBlur);
    //     }

    //     const showPasswordButton = document.querySelector(".password-button");
    //     if (showPasswordButton) {
    //         showPasswordButton.addEventListener("click", handleShowPasswordClick);
    //     }
    //     // Remove event listeners when the component unmounts
    //     return () => {
    //         if (usernameInputRef.current) {
    //             usernameInputRef.current.removeEventListener("focus", handleUsernameFocus);
    //             usernameInputRef.current.removeEventListener("blur", handleUsernameBlur);
    //             usernameInputRef.current.removeEventListener("input", handleUsernameInput);
    //         }

    //         if (passwordInputRef.current) {
    //             passwordInputRef.current.removeEventListener("focus", handlePasswordFocus);
    //             passwordInputRef.current.removeEventListener("blur", handlePasswordBlur);
    //         }

    //         if (showPasswordButton) {
    //             showPasswordButton.removeEventListener("click", handleShowPasswordClick);
    //         }

    //     };
    // }, []);

    return (
        <div className="form-container  bg-cover bg-shape-pattern h-full w-full flex justify-center items-center flex-col">
            {/* <div className="face" ref={faceRef}>
                <div className="eyes">
                    <div className="eye eye--left">
                        <div className="glow"></div>
                    </div>
                    <div className="eye eye--right">
                        <div className="glow"></div>
                    </div>
                </div>
                <div className="nose">
                    <svg width="38.161" height="22.03">
                        <path
                            d="M2.017 10.987Q-.563 7.513.157 4.754C.877 1.994 2.976.135 6.164.093 16.4-.04 22.293-.022 32.048.093c3.501.042 5.48 2.081 6.02 4.661q.54 2.579-2.051 6.233-8.612 10.979-16.664 11.043-8.053.063-17.336-11.043z"
                            fill="#243946"></path>
                    </svg>
                    <div className="glow"></div>
                </div>
                <div className="mouth">
                    <svg className="smile" viewBox="-2 -2 84 23" width="84" height="23">
                        <path
                            d="M0 0c3.76 9.279 9.69 18.98 26.712 19.238 17.022.258 10.72.258 28 0S75.959 9.182 79.987.161"
                            fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="3"></path>
                    </svg>
                    <div className="mouth-hole"></div>
                    <div className="tongue breath">
                        <div className="tongue-top"></div>
                        <div className="line"></div>
                        <div className="median"></div>
                    </div>
                </div>
            </div>
            <div className="tengah">
                <div className="hands">
                    <div className="hand hand--left">
                        <div className="finger">
                            <div className="bone"></div>
                            <div className="nail"></div>
                        </div>
                        <div className="finger">
                            <div className="bone"></div>
                            <div className="nail"></div>
                        </div>
                        <div className="finger">
                            <div className="bone"></div>
                            <div className="nail"></div>
                        </div>
                    </div>
                    <div className="hand hand--right">
                        <div className="finger">
                            <div className="bone"></div>
                            <div className="nail"></div>
                        </div>
                        <div className="finger">
                            <div className="bone"></div>
                            <div className="nail"></div>
                        </div>
                        <div className="finger">
                            <div className="bone"></div>
                            <div className="nail"></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* SVG Image End  */}
            <LottieAnimation animationData={animationData} width='300px' height='200px' />
            {children}
        </div>

    )
}

export default LoginAnimation