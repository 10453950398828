import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ menus }) => {
  const [sidebarForceOpen, setSidebarForceOpen] = useState(true);
  const activeSideNave = " bg-greslernorange ";
  const notActiveSideNave = " bg-white text-greslernorange";



  return <div className={` overflow-y-auto flex flex-row sm:!flex-col duration-300 ease-linear   bg-greslernblue text-white bg-blue-500 rounded-lg`}>
    <button onClick={() => setSidebarForceOpen(!sidebarForceOpen)} aria-controls="sidebar" aria-expanded={sidebarForceOpen} className=" invisible sm:!visible  p-2 text-white bg-blue h-10 bg-greslernorange">
      {sidebarForceOpen ? <FontAwesomeIcon icon={faArrowAltCircleLeft} /> : <FontAwesomeIcon icon={faArrowAltCircleRight} />}
    </button>

    <ul className="mb-6 flex flex-row sm:!flex-col gap-1.5 mt-4 m-2">
      {menus.map(menu =>
        <li key={menu.name}>
          <NavLink to={menu.link} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " text-lg rounded-md  hover:!bg-greslernorange group relative flex items-center gap-2.5 py-2 px-4 font-medium duration-300 ease-in-out hover:gres-blue hover:!text-white"}>
            {menu.icon}
            {sidebarForceOpen && menu.name}
          </NavLink>
        </li>
      )}
    </ul>

  </div>;
};

export default Sidebar;
