import { useEffect, useState } from 'react'
import { Dialog, Disclosure, Menu, } from '@headlessui/react'
import DefaultLandingPageLayout from '../LandingPage/DefaultLandingPageLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown, faChevronUp, faClose, faDiceFour, faFunnelDollar, faMinus, faPlus, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { ContentService } from '../../services'
import { useSelector } from 'react-redux'
import { selectAuthStatus } from '../../redux/Reducer/authSlice'
import { IContentListEntry } from '../../services/ContentService'
import { t } from 'i18next'
import NoRecordFound from '../../components/NotRecoredFound'
import ContentList from '../../components/ContentFile/ContentList'

import TagsAndFilters from '../../services/TagsAndFilters'
import SingleTagComponent from '../../components/singleTagComponent'
import { useSearchParams } from "react-router-dom";

let sortOptions = [
    { name: 'Most Popular', href: `/resources?sort=${encodeURIComponent('Most Popular')}`, current: false },
    { name: 'Best Rating', href: `/resources?sort=${encodeURIComponent('Best Rating')}`, current: false },
    { name: 'Newest', href: `/resources?sort=${encodeURIComponent('Newest')}`, current: false }
]
const subCategories = [
    { name: 'Curriculum Related', href: `/resources?tags=${encodeURIComponent('Curriculum,Lesson Plans,Syllabus')}` },
    { name: 'Academic Fields ', href: `/resources?tags=${encodeURIComponent('Fields,Science,Arts,Humanities,Social Sciences,Engineering,Physics,Chemistry')}` },
    { name: 'Education Levels', href: `/resources?tags=${encodeURIComponent('Elementary,Middle School,High School,University,Physics,Postgraduate')}` },
    { name: 'Non-Academic', href: `/resources?tags=${encodeURIComponent('First Aid,Cooking/Nutrition,Indigenous')}` }
]
const filters: any[] = []
interface Option {
    value: string;
    label: string;
    checked: boolean;
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ResourceFiltering = () => {

    const [searchParams] = useSearchParams();
    const isAuthenticated = useSelector(selectAuthStatus)
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
    const [resources, setResources] = useState<IContentListEntry[]>([])
    const [isListMode, setIsListMode] = useState(false)
    const contentService = new ContentService()
    const [tags, setTags] = useState<string[]>([]);
    const [searchKey, setSearchKey] = useState("")
    const [searchedData, setSearchedData] = useState<any>([])
    const [sortOption, setSortOption] = useState('Most Popular');

    const handleSearchKey = () => {
        setSearchedData(
            resources.filter(option =>
                option.title.toLowerCase().includes(searchKey.toLowerCase()
                )
            ))
    }


    const fetchData = async () => {

        if (isAuthenticated) {
            const list = await contentService.listPublic([], sortOption);
            setResources(list);
            setSearchedData(list);
        } else {
            const list = await contentService.getContentForcePublic();
            setResources(list);
            setSearchedData(list);
        }
    }
    const handleFilter = async () => {

        if (isAuthenticated) {
            const list = await contentService.listPublic(tags, sortOption);
            setResources(list);
            setSearchedData(list);
        } else {
            const list = await contentService.getContentForcePublic();
            setResources(list);
            setSearchedData(list);
        }
    }
    const handleFilterChecked = (isChecked, value) => {

        if (isChecked) {
            setTags((prevTags) => [...prevTags, value]);

            filters.forEach((section: { options: { value: any, checked: boolean }[] }) => {
                section.options.forEach((option) => {
                    if (value === option.value) {
                        option.checked = true;
                    }
                });
            });

        } else {
            setTags((prevTags) => prevTags.filter((t) => t !== value));
            filters.forEach((section: { options: { value: any, checked: boolean }[] }) => {
                section.options.forEach((option) => {
                    if (value === option.value) {
                        option.checked = true;
                    }
                });
            });
        }
    }
    const handleClearFilters = () => {
        filters.forEach((section: { options: { value: any, checked: boolean }[] }) => {
            section.options.forEach((option) => {
                option.checked = false;
            });
        });
        setTags([])
    }
    const fetchTags = async () => {
        const tags = await TagsAndFilters.getAllTags()
        if (tags) {

            const options: Option[] = tags.map((tag) => ({
                value: tag.name,
                label: tag.name,
                checked: false,
            }));

            const tagsObject = {
                id: 'tags',
                name: 'tags',
                options: options
            }

            filters.push(tagsObject)


        }
    }
    const handleSortChange = (option: string) => {
        setSortOption(option);
    };

    useEffect(() => {
        fetchData().then(() => {
            setSortOption(searchParams.get("sort") ?? 'Most Popular')
            setTags(searchParams.get("tags")?.split(',') || [])
            setSearchKey(searchParams.get("q") ?? '')
        }
        )
        fetchTags()
    }, [])


    useEffect(() => {
        handleFilter()
        setSearchKey('')
    }, [tags, sortOption])

    useEffect(() => {
        handleSearchKey()
    }, [searchKey])

    return (
        <DefaultLandingPageLayout>
            <div className=" flex justify-center text-center  px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto  bg-no-repeat bg-cover bg-[url('https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/images/interdesciplinary.png')] ">
                <h1 id='privacy' className=" whitespace-nowrap w-fit px-4 mb-2 text-2xl font-bold md:text-6xl text-center mix-blend-lighten bg-gray-100 uppercase">Resources <br /> </h1>
            </div>
            <div>
                <div className='flex justify-center'>
                    <div className="relative mt-2">
                        <input
                            className="appearance-none border-2 pl-10 border-greslernorange/50 hover:border-greslernorange transition-colors rounded-md w-full py-2 px-5 pr-16  text-gray-800 leading-tight focus:outline-none focus:ring-purple-600 focus:border-purple-600 focus:shadow-outline"
                            id="username"
                            type="text"
                            placeholder="Search..."
                            value={searchKey}
                            onChange={e => setSearchKey(e.target.value)}
                        />
                        <div className="absolute right-0 inset-y-0 flex items-center">
                            {searchKey == '' ? <></> :
                                <FontAwesomeIcon
                                    icon={faClose}
                                    className="-ml-1 mr-3 h-5 w-5 text-red/60 hover:text-red cursor-pointer"
                                    onClick={() => setSearchKey('')}
                                />}

                        </div>

                        <div className="absolute left-0 inset-y-0 flex items-center">
                            <FontAwesomeIcon icon={faSearch} className='h-6 w-6 ml-3 text-greslernorange/70' />
                        </div>
                    </div>
                </div>

                {/* Mobile filter dialog */}
                <Dialog className="relative z-40 lg:hidden" open={mobileFiltersOpen} onClose={setMobileFiltersOpen}>
                    <Dialog.Backdrop
                        transition
                        className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 z-40 flex">
                        <Dialog.Panel
                            transition
                            className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                        >
                            <div className="flex items-center justify-between px-4">
                                <h2 className="text-lg font-medium text-greslernorange">{t("Filters")}</h2>
                                <button
                                    type="button"
                                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                    onClick={() => setMobileFiltersOpen(false)}
                                >
                                    <span className="sr-only">{t("Close menu")}</span>
                                    <FontAwesomeIcon icon={faXmark} className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>

                            {/* Filters */}
                            <form className="mt-4 border-t border-gray-200">
                                <h3 className="sr-only">{t("Categories")}</h3>
                                <ul className="px-2 py-3 text-greslernorange font-bold">
                                    {subCategories.map((category) => (
                                        <li key={category.name}>
                                            <a href={category.href} className="block px-2 py-3">
                                                {category.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>

                                {filters.map((section) => (
                                    <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                                        {({ open }) => (
                                            <>
                                                <h3 className="-mx-2 -my-3 flow-root">
                                                    <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                        <span className="font-medium text-greslernorange">{section.name}</span>
                                                        <span className="ml-6 flex items-center">
                                                            {open ? (
                                                                <FontAwesomeIcon icon={faMinus} className="h-5 w-5" aria-hidden="true" />
                                                            ) : (
                                                                <FontAwesomeIcon icon={faPlus} className="h-5 w-5" aria-hidden="true" />
                                                            )}
                                                        </span>
                                                    </Disclosure.Button>
                                                </h3>
                                                <Disclosure.Panel className="pt-6">
                                                    <div className="space-y-6">
                                                        {section.options.map((option, optionIdx) => (
                                                            SingleTagComponent(option.checked, option.value, option.label, section.id, optionIdx, handleFilterChecked)
                                                        ))}
                                                    </div>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </form>
                        </Dialog.Panel>
                    </div>
                </Dialog>

                <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 capitalize">
                    <div className="flex  border-b border-gray-200 pt-6">
                        <div className=" flex items-center grow  text-greslernorange pb-0">
                            {t("filters")} {tags.length > 0 ? <><span className='flex items-center justify-center hover:bg-red/30 hover:border-2 hover:border-red hover:font-bold cursor-pointer border border-red rounded-full p-1 mx-2 '>
                                <FontAwesomeIcon icon={faClose} onClick={handleClearFilters} />
                            </span> |</>
                                : undefined
                            }<p className='flex text-xs px-2'>
                                {tags.length > 0 ? (
                                    <>
                                        {tags.map((t, id) => (SingleTagComponent(true, t, t, 'tags', id, handleFilterChecked)))}
                                    </>
                                ) : undefined}
                            </p>
                        </div>

                        <div className="flex items-center pb-6">
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-greslernorange hover:font-semibold">
                                        {({ open }) => (
                                            <>
                                                {t(sortOption)}
                                                {open ? <FontAwesomeIcon icon={faChevronUp}
                                                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-greslernorange group-hover:text-greslernorange"
                                                    aria-hidden="true"
                                                /> : <FontAwesomeIcon icon={faChevronDown}
                                                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-greslernorange"
                                                    aria-hidden="true"
                                                />}
                                                {open}
                                            </>
                                        )}
                                    </Menu.Button>
                                </div>

                                <Menu.Items
                                    transition
                                    className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl shadow-greslernorange/30 ring-1 ring-greslernorange/50 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <div className="py-1">
                                        {sortOptions.map((option) => (
                                            <Menu.Item key={option.name}>
                                                {({ active }) => (
                                                    <a
                                                        href={option.href}
                                                        className={classNames(
                                                            option.name === sortOption ? 'font-medium text-greslernorange ' : 'text-gray-500 ',
                                                            active ? 'bg-greslernorange/80 ' : '',
                                                            ' block px-4 py-2 text-sm hover:text-white ',
                                                        )}
                                                        onClick={() => handleSortChange(option.name)}
                                                    >
                                                        {option.name}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Menu>

                            <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                                <span className="sr-only">View grid</span>
                                <FontAwesomeIcon icon={isListMode ? faDiceFour : faBars} size='2x' className="h-5 w-5 text-greslernorange hover:text-greslernorange/50" aria-hidden="true" onClick={() => setIsListMode(!isListMode)} />
                            </button>
                            <button
                                type="button"
                                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                                onClick={() => setMobileFiltersOpen(true)}
                            >
                                <span className="sr-only">{t("Filters")}</span>
                                <FontAwesomeIcon icon={faFunnelDollar} className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>

                    <section aria-labelledby="products-heading" className="pb-24 pt-6">
                        <h2 id="products-heading" className="sr-only">
                            {t("Resouces")}
                        </h2>

                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                            {/* Filters */}
                            <form className="hidden lg:block">
                                <h3 className="sr-only">{t("Categories")}</h3>
                                <ul className="space-y-4 border-b border-gray-200 pb-6 text-sm font-bold text-greslernorange">
                                    {subCategories.map((category) => (
                                        <li key={category.name}>
                                            <a href={category.href}>{category.name}</a>
                                        </li>
                                    ))}
                                </ul>

                                {filters.map((section) => (
                                    <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                                        {({ open }) => (
                                            <>
                                                <h3 className="-my-3 flow-root">
                                                    <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                                        <span className="font-medium text-greslernorange">{section.name}</span>
                                                        <span className="ml-6 flex items-center">
                                                            {open ? (
                                                                <FontAwesomeIcon icon={faMinus} className="h-5 w-5" aria-hidden="true" />
                                                            ) : (
                                                                <FontAwesomeIcon icon={faPlus} className="h-5 w-5" aria-hidden="true" />
                                                            )}
                                                        </span>
                                                    </Disclosure.Button>
                                                </h3>
                                                <Disclosure.Panel className="pt-6">
                                                    <div className=" max-h-42 overflow-y-auto">
                                                        {section.options.map((option, optionIdx) => (
                                                            SingleTagComponent(option.checked, option.value, option.label, section.id, optionIdx, handleFilterChecked)
                                                        ))}
                                                    </div>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </form>
                            {/* Product grid */}
                            <div className="lg:col-span-3">
                                <ul className={`grid ${isListMode ? 'grid-cols-1' : 'grid-cols-2'} gap-2`}>

                                    {searchedData.length ?
                                        searchedData.map((content: any, index: number) => (
                                            <ContentList
                                                key={index}
                                                content={content}
                                                contentType={'public'}
                                            />
                                        )) :
                                        <NoRecordFound />
                                    }
                                </ul>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </DefaultLandingPageLayout>
    )
}

export default ResourceFiltering;


