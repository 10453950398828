import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const fetchFolders = async () => {
  try {
    const response = await api.get(Apis.Folder + "/list");
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(
      `Request to REST endpoint returned ${response.status} ${response.statusText}`
    );
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const fetchFolderById = async (folderId: string) => {
  try {
    const response = await api.get(`${Apis.Folder}/${folderId}/list`);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(
      `Request to REST endpoint returned ${response.status} ${response.statusText}`
    );
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const createFolder = async (parentId, newFolderName = '') => {
  try {
    const response = await api.post(Apis.Folder, {
      name: newFolderName,
      parent: parentId
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(
      `Request to REST endpoint returned ${response.status} ${response.statusText}`
    );
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }

}
const deleteFolder = async (folderId: string) => {
  try {

    const result = await api.delete(`${Apis.Folder}/${folderId}/remove`);
    if (result.status === 200) {
      return true
    }
    throw new Error(
      `Error while deleting folder: ${result.status} ${result.statusText}`
    );
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const renameFolder = async (folderId: string, newName: string) => {
  try {
    await api.patch(`${Apis.Folder}/${folderId}/rename`, { name: newName });
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
}
const setOpen = async (folderId: string) => {
  try {
    await api.patch(`${Apis.Folder}/${folderId}/toggleOpen`);

  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
}
const moveFolder = async (folderId: string, newParentId: string) => {
  try {

    return await api.put(`${Apis.Folder}/${folderId}/move`, {
      parentId: newParentId
    });
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
}
const moveFileToFolder = async (contentId: string, folderId: string) => {
  try {
    return await api.put(`${Apis.Folder}/${folderId}/moveFile`, {
      contentId: contentId
    });
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const moveToTrash = async (id: string, type: string) => {
  try {
    return await api.put(`${Apis.Folder}/moveToTrash`, {
      id: id,
      type: type
    });
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const restoreFromTrash = async (id: string, type: string) => {
  try {
    return await api.put(`${Apis.Folder}/restoreFromTrash`, {
      id: id,
      type: type
    });
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const deletePermanently = async (id: string, type: string) => {
  try {
    return await api.put(`${Apis.Folder}/deletePermanently`, {
      id: id,
      type: type
    });
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const removeFile = async (contentId: string) => {
  try {
    await api.put(`${Apis.Folder}/${contentId}/removeFile`, {
      contentId: contentId
    });
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const emptyTrash = async () => {
  try {
    return await api.put(`${Apis.Folder}/emptyTrash`);
  } catch (error) {
    console.error("Error removing content  :", error);
    return false;
  }
};

export default {
  fetchFolders,
  fetchFolderById,
  createFolder,
  deleteFolder,
  renameFolder,
  moveFolder,
  moveFileToFolder,
  moveToTrash,
  restoreFromTrash,
  deletePermanently,
  setOpen,
  removeFile,
  emptyTrash
  // FolderExplorerService
};