import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import countries from "countries-list";

import { GetUsers } from '../../../services';
interface InstitutionType {
    label: string;
    value: string;
}

const UpdateInstitute = ({ _id }) => {
    const [name, setName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const { t } = useTranslation();
    const [country, setCountry] = useState('');
    const [type, setInstituteType] = useState('');
    const countryList = Object.values(countries.countries);
    countryList.sort((a, b) => a.name.localeCompare(b.name));
    countryList.sort((a, b) => a.name.localeCompare(b.name));
    const typeOptions: InstitutionType[] = [
        { label: "School", value: "School" },
        { label: "College", value: "College" },
        { label: 'University', value: 'University' },
        { label: "Training center", value: "Training center" },
        { label: 'Other', value: 'Other' },
    ];
    const instituteupdate = async (e) => {
        e.preventDefault();
        const updatedata = {
            instituteName: name,
            country: country,
            city: city,
            streetAddress: streetAddress,
            zipCode: zipCode,
            type: type,
        }
        try {
            let data = await GetUsers.institutedataupdate(_id ? _id : "", updatedata)
            if (data) {
                NotificationManager.success("Institute updated", "Institute profile");
            }
            else {
                NotificationManager.warning("Institute not updated", "Institute profile")
            }
        } catch (error) {
            NotificationManager.warning("Institute not updated", "Institute profile")
        }
    };
    const getInstituteDetail = async (_id: string) => {
        try {
            const data = await GetUsers.getinstitutedetail(_id);
            if (data) {
                setName(data.institutionName);
                setCountry(data.country);
                setCity(data.city);
                setStreetAddress(data.streetAddress);
                setZipCode(data.zipCode);
                setInstituteType(data.type);
            }
        } catch (error) { console.log(error); }

    }
    const handleType = (e) => {
        e.preventDefault();
        setInstituteType(e.target.value);
    }
    const handleCountry = (e) => {
        const value = e.target.value;
        setCountry(value);
    }
    useEffect(() => {
        getInstituteDetail(_id ? _id : "");
    }, []);
    return (
        <div className='w-full'>
            <div className="bg-white p-2 rounded-lg shadow-lg w-full">
                <form className="relative w-full  justify-center items-center flex-col gap-[15px] py-0 px-[40px] z-[10]">
                    <input
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-500 focus:ring-blue-500"
                        id="institutionName"
                        name="institutionName"
                        type="text"
                        placeholder={t("Enter Institute/School")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <br />
                    <br />
                    <select
                        id="type"
                        name="type"
                        value={type}
                        onChange={handleType}
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                    >
                        <option className="text-[#c2bebe]" hidden>
                            {t("Institution type")}
                        </option>
                        {typeOptions.map((option, k) => (
                            <option
                                key={`$typeOptios${option.value}`}
                                className="text-[#817e7e]"
                                value={option.value}
                            >
                                {t(option.label)}
                            </option>
                        ))}
                    </select>
                    <br />
                    <br />
                    <select
                        id="country"
                        name="country"
                        value={country}
                        onChange={handleCountry}
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                    >
                        <option hidden>
                            {t("selectCountry")}
                        </option>
                        {countryList.map((country, k) => (
                            <option
                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                                key={`$countryList${country.name}`}
                                value={country.name}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                    <br />
                    <br />
                    <input
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                        id="city"
                        name="city"
                        type="text"
                        placeholder={t("City")}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                    />
                    <br />
                    <br />
                    <input
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                        id="streetAddress"
                        name="streetAddress"
                        type="text"
                        placeholder={t("Street ")}
                        value={streetAddress}
                        onChange={(e) => setStreetAddress(e.target.value)}
                        required
                    />
                    <br />
                    <br />
                    <input
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                        id="zipCode"
                        name="zipCode"
                        type="text"
                        placeholder={t("Zip /Postal Code")}
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        required
                    />
                    <br />
                    {/* <br />
                    <input
                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                        id="phone"
                        name="phone"
                        type="text"
                        placeholder={t("Enter Phone")}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                    <br /> */}
                    <br />
                    <button type="submit"
                        onClick={instituteupdate}
                        className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    >{t("Update")}
                    </button>
                </form>
            </div>
        </div>

    );
}
export default UpdateInstitute;
