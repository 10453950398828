import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { API_URL } from './apiConfig';

i18n
    .use(HttpBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'en',
        debug: true,
        fallbackLng: 'en',
        preload: ['en', 'fr', 'ar', 'es'],
        ns: ['translation'],
        defaultNS: 'translation',
        backend: {
            loadPath: `${API_URL}/locals/{{lng}}/{{ns}}.json`
        }
    });

export default i18n;