import { faArrowLeft, faArrowRight, faFileDownload, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import countries from "countries-list";
import { useState } from 'react';
import { Spin } from 'react-cssfx-loading';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from "react-notifications";
import { CreateInstituteInvitation } from '../../../services';
import ModalComponent from '../../ModalComponent';
export default function RegisterMember({ onUpdate }) {
    const { t } = useTranslation();
    const [country, setCountry] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [ischecked, setIschecked] = useState(false)
    const [showFileInput, setShowFileInput] = useState(false)
    const [showNormalInput, setShowNormalInput] = useState(true)
    const [file, setFile] = useState(null);
    const [show, setShow] = useState(true);
    const countryList = Object.values(countries.countries);
    countryList.sort((a, b) => a.name.localeCompare(b.name));
    const handleBulkemailButton = () => {
        setIschecked(!ischecked);
        setShowFileInput(!showFileInput);
        setShowNormalInput(!showNormalInput);
    }
    const handleGenerateForCsv = async (e) => {
        e.preventDefault();
        try {
            if (!file) { NotificationManager.warning("All filed Required", "Generate"); return; }
            setLoading(true);
            const userdata = new FormData();
            userdata.append("fileCsv", file ?? '');
            let data = await CreateInstituteInvitation.createindividualsinvitationCvs(userdata);
            if (data) {
                setLoading(false);
                NotificationManager.success("Successfull sent", "Invitation");
                onUpdate();
            }
            else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            NotificationManager.error("Can't generate link, Try Again", "Invitation");
        }
    };
    const handleGenerate = async (e) => {
        e.preventDefault();
        if (!name || !email || !country) {
            NotificationManager.warning("All filed Required", "Generate");
            return;
        }
        try {
            setLoading(true);
            const userdata = { name: name, email: email, country: country, }
            let data = await CreateInstituteInvitation.createindividualsinvitation(userdata);
            if (data) {
                setLoading(false);
                setName('');
                setEmail('');
                setCountry('');
                NotificationManager.success("Link generated", "Invitation");
                onUpdate();
            }
            else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };
    const handleFileChange = (event) => {
        event.preventDefault();
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };
    const handleClose = () => { setShow(false); };
    return (
        <ModalComponent isOpen={show} title={false} onClose={handleClose}>
            <div className="bg-white mb-3 p-2 rounded-lg shadow-lg w-full">
                <div className="flex justify-center mb-3">
                    <span className="inline-block rounded-full text-greslernorange ">
                        <FontAwesomeIcon icon={faUserPlus} size='3x' />
                    </span>
                </div>
                <div className='flex justify-around mb-3 '>
                    <div>
                        <h2 className="text-xl text-center text-greslernorange uppercase font-bold mb-4">{t("invite Member")}</h2>
                    </div>

                </div>
                <div className='w-full flex flex-row-reverse'>
                    <div className="">
                        <button
                            onClick={handleBulkemailButton}
                            className=" nav-button hover:drop-shadow-lg flex w-full items-center justify-center rounded-full border px-7 text-base font-bold text-white-800 !shadow-greslernorange ring-greslernorange active:ring-1 hover:ring-1">
                            {ischecked && <FontAwesomeIcon icon={faArrowLeft} className='text-greslernorange' />}
                            <span className='px-2'>{ischecked ? t('single invitation') : t('bulk invitation')}</span>
                            {!ischecked && <FontAwesomeIcon icon={faArrowRight} className='text-greslernorange' />}
                        </button>
                    </div>
                </div>
                <form
                    onSubmit={ischecked ? handleGenerateForCsv : handleGenerate}
                    className='p-2'
                >
                    {
                        showNormalInput && <>
                            <div className="mb-1">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Name")} <span className="text-red-600">*</span> </label>
                                <input type="text" id="name"
                                    className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                    required
                                    placeholder="Enter name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-1">
                                <label
                                    htmlFor="country"
                                    className="block text-gray-700 text-sm font-semibold mb-2"
                                >{t("Country")} <span className="text-red-600">*</span></label>
                                <select
                                    id="country"
                                    name="country"
                                    value={country}
                                    onChange={(e) => {
                                        const selectedOptionValue = e.target.value;
                                        setCountry(selectedOptionValue)
                                    }
                                    }
                                    className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                >
                                    <option hidden>
                                        {t("Select country")}
                                    </option>
                                    {countryList.map((country, k) => (
                                        <option
                                            className="text-[#292929]"
                                            key={`$countryLis1${country.name}`}
                                            value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">{t("Email Address")} <span className="text-red-600">*</span></label>
                                <input type="email" id="email"
                                    className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                    required placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                />
                            </div>
                        </>
                    }
                    {showFileInput &&
                        <div className="mb-1">
                            <label className="block text-gray-700 text-sm font-semibold mb-2">{t("CSV File")} <span className="text-red-600">*</span> </label>
                            <input type="file" onChange={handleFileChange}
                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                            />
                            <div className='flex justify-end text-sm text-greslernblue/80 p-2'>
                                <a href='/downloads/greslern-bulk-invitation-template.csv' download>
                                    <FontAwesomeIcon icon={faFileDownload} />{t("template")}</a>
                            </div>
                        </div>
                    }
                    <button
                        className={
                            `relative flex justify-center  mt-4 w-[100%] py-[10px] px-[15px] rounded-[5px] text-base tracking-wider transition-[0.5s] cursor-pointer 
                  ${loading
                                ? "bg-blue-500 text-white pointer-events-none w-full"
                                : 'bg-blue-500 text-[#2d2d39] hover:!bg-[#1a1654] hover:text-white'}
                  `}
                        type="submit"
                        disabled={loading}>
                        {loading ? (
                            <Spin color="#ffffff" width="30px" height="30px" duration="1s" className='ml-20' />
                        ) : (
                            t("Invite")
                        )}
                    </button>
                    <p className="text-red-400 text-xs text-center mt-4">
                        {t("invitation link will expired after 24 hours")}
                    </p>
                </form>
            </div>
        </ModalComponent>
    );
}
