import { useEffect, useState } from 'react';
import { Col, Row, Card, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { faBackward, faBan, faCheck, faEdit, faTrashCanArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationManager } from "react-notifications";
import countries from 'countries-list';
import { API_URL } from '../../../apiConfig';
import { GetUsers } from '../../../services';
type profileprops = {
    profilephoto: string;
    name: string;
    email: String;
    bio: string;
    gender: string;
    country: string;
    username: string;
    phone: string;
    createdAt: Date;
    status: String;
};

const InstituteDetail = () => {

    const { t } = useTranslation();

    const { email } = useParams()
    const [instituteDetails, setInstituteDetails] = useState<profileprops[]>([]);
    const [showForm, setShowForm] = useState(false);
    const [deleteComfirmation, setDeleteComfirmation] = useState(false);
    const [banComfirmation, setBanComfirmation] = useState(false);
    const [resetNormal, setResetNormal] = useState(false);
    const countryList = Object.values(countries.countries);


    const getInstituteDetail = async (email) => {
        const data = await GetUsers.getuserdata(email);
        if (data) {
            setInstituteDetails(data);
        }
        else {
            setInstituteDetails([]);
        }
    }
    const deleteInstitute = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.deleteUser(email ? email : "null");
            if (data) {
                NotificationManager.success("Successfull deleted", "User");
                // logout();
            }
            else {
                NotificationManager.warning("Not deleted", "User")
            }
            // refreshPage();
        } catch (error) {
            NotificationManager.warning("Not deleted", "User")
        }
        setDeleteComfirmation(false);
    }
    const ResetAgain = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.resetAgain(email ? email : "null");
            if (data) {
                NotificationManager.success("Successfull deleted", "User");
                // logout();
            }
            else {
                NotificationManager.warning("Not deleted", "User")
            }
            // refreshPage();
        } catch (error) {
            NotificationManager.warning("Not deleted", "User")
        }
        setDeleteComfirmation(false);
    }
    const openModal = () => {
        setShowForm(!showForm);
    };

    const openDelete = () => {
        setDeleteComfirmation(!deleteComfirmation);
    };
    const openResetNormal = () => {
        setResetNormal(!resetNormal);
    }
    const openBan = () => {
        setBanComfirmation(!banComfirmation);
    };

    const handleDeleteUser = async () => {
        setDeleteComfirmation(true);
    }

    const handleBanUser = async () => {
        setBanComfirmation(true);
    }
    const handleResetNormal = async () => {
        setResetNormal(true);

    }
    const banInstitute = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.banUser(email ? email : "");
            if (data) {
                NotificationManager.success("Successfull Banned", "Institute");
                // logout();
            }
            else {
                NotificationManager.warning("Not Banned", "Institute")
            }
            // refreshPage();
        } catch (error) {
            NotificationManager.warning("Not Banned", "Institute")
        }
        setBanComfirmation(false);
    }

    const updateInstitute = async (email) => {
        setShowForm(true);
    }
    useEffect(() => {
        getInstituteDetail(email);
    }, []);

    return (
        <>
            {instituteDetails && instituteDetails.map((data, index) => (
                <>
                    <Row className="align-items-center">
                        <Col xl={12} lg={12} md={12} xs={12}>
                            <div className="bg-white rounded-bottom smooth-shadow-sm ">
                                <div className="d-flex align-items-center justify-content-between pt-4 pb-6 px-4">
                                    <div className="d-flex align-items-center">
                                        {/* avatar */}
                                        <div className="avatar-xxl avatar-indicators avatar-online me-2 position-relative d-flex justify-content-end align-items-end mt-n10">
                                            {data.profilephoto ? (
                                                <img
                                                    src={`${API_URL}/public/images/${data.profilephoto}`}
                                                    className="avatar-xxl rounded-circle border border-4 border-white-color-40"
                                                />
                                            ) : (
                                                <div className="avatar-xxl rounded-circle border border-4 border-white-color-40">
                                                    {data.username.charAt(0).toUpperCase()}
                                                </div>
                                            )}
                                        </div>
                                        {/* text */}
                                        <div className="lh-1">
                                            <h2 className="mb-0">{data.name}
                                                <Link to="/" className="text-decoration-none" data-bs-toggle="tooltip" data-placement="top" title="" data-original-title="Beginner">
                                                </Link>
                                            </h2>
                                            <p className="mb-0 d-block">{data.email}</p>
                                        </div>
                                    </div>
                                    <div className='align-items-end justify-evenly flex flex-row space-x-8'>
                                        <div>
                                            <button onClick={(() => { window.history.back() })}>
                                                <FontAwesomeIcon icon={faBackward} color='blue' />
                                                &nbsp; {t("goBack")}
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={(() => { updateInstitute(email) })}>
                                                <FontAwesomeIcon icon={faEdit} color='orange' />
                                            </button>
                                        </div>
                                        {data.status == '1' ?
                                            <div>
                                                <button
                                                    hidden
                                                    onClick={handleDeleteUser}
                                                >
                                                    <FontAwesomeIcon icon={faTrashCanArrowUp} color='red' />
                                                </button>
                                            </div>
                                            : (
                                                <>
                                                    <div>
                                                        <button
                                                            onClick={handleBanUser}
                                                        >
                                                            <FontAwesomeIcon icon={faBan} color='yellow' />
                                                        </button>

                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={handleDeleteUser}
                                                        >
                                                            <FontAwesomeIcon icon={faCheck} color='green' />
                                                        </button>
                                                    </div>
                                                </>

                                            )
                                        }
                                        <div>
                                        </div>
                                        {data.status == '2' ?
                                            <div>
                                                <button
                                                    hidden
                                                    onClick={handleBanUser}
                                                >
                                                    <FontAwesomeIcon icon={faBan} color='yellow' />
                                                    {/* Ban */}
                                                </button>

                                            </div>
                                            : (
                                                <>
                                                    <div>
                                                        <button
                                                            onClick={handleDeleteUser}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashCanArrowUp} color='red' />
                                                        </button>

                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={handleResetNormal}
                                                        >
                                                            <FontAwesomeIcon icon={faCheck} color='green' />
                                                        </button>
                                                    </div>
                                                </>

                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col >
                    </Row >
                    <Col xl={6} lg={12} md={12} xs={12} className="mb-12 w-full">
                        {/* card */}
                        <Card>
                            {/* card body */}
                            <Card.Body>
                                {/* card title */}
                                <Card.Title as="h4">{t("Information")}</Card.Title>
                                <span className="text-uppercase fw-medium text-dark fs-5 ls-2">{t("Bio")}</span>
                                <p className="mt-2 mb-6">{data.bio}.
                                </p>
                                <Row>
                                    <Col xs={12} className="mb-5">
                                        <h6 className="text-uppercase fs-5 ls-2">{t("Position")}</h6>
                                        <p className="mb-0">{t("Creator")}</p>
                                    </Col>
                                    <Col xs={6} className="mb-5">
                                        <h6 className="text-uppercase fs-5 ls-2">{t("Phone")} </h6>
                                        <p className="mb-0">{data.phone}</p>
                                    </Col>
                                    <Col xs={6} className="mb-5">
                                        <h6 className="text-uppercase fs-5 ls-2">{t("Reg.Date")} </h6>
                                        <p className="mb-0">{moment(data.createdAt).format('LL')}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <h6 className="text-uppercase fs-5 ls-2">{t("Email")} </h6>
                                        <p className="mb-0">{data.email}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <h6 className="text-uppercase fs-5 ls-2">{t("Location")}</h6>
                                        <p className="mb-0">{data.country}</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    {showForm ? (
                        <Modal
                            show={true}
                            onHide={openModal}
                            className="w-full h-full bg-transparent opacity-90 mt-12">
                            <Modal.Body>
                                <div className="bg-white p-2 rounded-lg shadow-lg w-full">
                                    <div className="flex justify-center mb-6">
                                        <span className="inline-block bg-gray-200 rounded-full p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4" /></svg>
                                        </span>
                                    </div>
                                    <h2 className="text-2xl font-semibold text-center mb-4">{t("Update User Info")}</h2>
                                    <form encType="multipart/form-data">
                                        <div className="mb-1">
                                            <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Bio")} *</label>
                                            <textarea
                                                rows={2}
                                                cols={4}
                                                // onChange={handlebio}
                                                value={data?.bio}
                                                name="bio"
                                                className="col-span-9 border-solid border-2 py-2 px-3 rounded-[0.375rem] form-input w-full text-gray-700 focus:ring-blue-500"
                                                placeholder={t("Enter bio")}
                                            ></textarea>
                                            <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Name")} *</label>
                                            <input
                                                type="text"
                                                // onChange={handlename}
                                                value={data?.name}
                                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                                                placeholder={t("Enter name")}
                                                required
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Gender")} *</label>
                                            <select
                                                required
                                                // onChange={handleGender}
                                                value={data?.gender}
                                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                                            >
                                                <option
                                                    className="text-[#292929]"
                                                    key={1}
                                                    value="female">
                                                    {t("Female")}
                                                </option>
                                                <option
                                                    className="text-[#292929]"
                                                    key={2}
                                                    value="male">
                                                    {t("Male")}
                                                </option>
                                                <option
                                                    className="text-[#292929]"
                                                    key={2}
                                                    value="I rather not to say">
                                                    {t("I rather not to say")}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="mb-1">
                                            <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Country")} *</label>
                                            <select
                                                required
                                                id="country"
                                                name="country"
                                                value={data?.country}
                                                // onChange={handleCountry}
                                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                                            >
                                                {countryList.map((country) => (
                                                    <option
                                                        className="text-[#292929]"
                                                        key={country.name}
                                                        value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">{t("Update")}</button>
                                    </form>
                                </div>
                            </Modal.Body>
                        </Modal>
                    ) : undefined}
                    {deleteComfirmation &&
                        <Modal show={true} onHide={openDelete} centered dialogClassName={`modal-md`}>
                            <Modal.Header>
                                <label>{t("Delete user")}</label>
                            </Modal.Header>
                            <Modal.Body>
                                <label>{t("Do you want to delete?")}</label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-default" onClick={openDelete}>{t("Cancel")}</button>
                                <button className="btn btn-primary" onClick={deleteInstitute}>{t("OK")}</button>
                            </Modal.Footer>
                        </Modal>}
                    {banComfirmation &&
                        <Modal show={true} onHide={openDelete} centered dialogClassName={`modal-md`}>
                            <Modal.Header>
                                <label>{t("Ban user")}</label>
                            </Modal.Header>
                            <Modal.Body>
                                <label>{t("Do you want to ban?")}</label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-default" onClick={openBan}>{t("Cancel")}</button>
                                <button className="btn btn-primary" onClick={banInstitute}>{t("OK")}</button>
                            </Modal.Footer>
                        </Modal>}
                    {resetNormal &&
                        <Modal show={true} onHide={openResetNormal} centered dialogClassName={`modal-md`}>
                            <Modal.Header>
                                <label>{t("Change to Active")}</label>
                            </Modal.Header>
                            <Modal.Body>
                                <label>{t("Do you want to activate?")}</label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-default" onClick={openResetNormal}>{t("Cancel")}</button>
                                <button className="btn btn-primary" onClick={ResetAgain}>{t("OK")}</button>
                            </Modal.Footer>
                        </Modal>}
                </>

            ))}
        </>
    );
}
export default InstituteDetail;
