import type {
    IEditorModel,
    IPlayerModel,
    IContentMetadata
} from '@lumieducation/h5p-server';
import api from '../../apiConfig';
import { Apis } from '../../config';
import { useNavigate } from 'react-router-dom';
import { eraseCookie } from '../../function';
import { ContentMetadata } from '@lumieducation/h5p-server/build/src/ContentMetadata';
import { API_URL } from '../../apiConfig';
import AxiosErrorHandling from '../../utils/AxiosErrorHandling';

export interface IContentListEntry {
    contentId: string;
    mainLibrary: string | undefined;
    title: string;
    originalNewKey?: string;
    userId: any;

}

export interface IContentService {
    delete(contentId: string): Promise<void>;
    getContentStatus(contentId: string): Promise<void>;
    updateContentStatus(contentId: string, newStatus: string): Promise<void>;
    shareTo(contentId: string, shareto: any): Promise<void>;
    tagTo(contentId: string, tagto: any): Promise<void>;
    bookmarks(email: string): Promise<IContentListEntry[]>;
    getEdit(contentId: string): Promise<IEditorModel>;
    getPlay(contentId: string): Promise<IPlayerModel>;
    list(): Promise<IContentListEntry[]>;
    listPublic(): Promise<IContentListEntry[]>;
    listBieResources(): Promise<IContentListEntry[]>;
    listBieDemoResources(): Promise<IContentListEntry[]>;
    getContentForcePublic(): Promise<IContentListEntry[]>;
    setContentForcePublic(contentId: string): Promise<IContentListEntry[]>;

    listSharedWithMe(): Promise<IContentListEntry[]>;
    countSharedWithMe(): Promise<IContentListEntry[]>;
    countListSharedWithMe();

    save(
        contentId: string,
        requestBody: { library: string; params: any }
    ): Promise<{ contentId: string; metadata: IContentMetadata }>;
    generateDownloadLink(contentId: string): string;
    generateHtmlDownloadLink(contentId: string): string;
}

export class ContentService implements IContentService {
    /**
     *
     */
    constructor(protected baseUrl: string = '') { }

    countSharedWithMe(): Promise<IContentListEntry[]> {
        throw new Error('Method not implemented.');
    }
    async countListSharedWithMe() {
        try {
            const res = await api.get(`${Apis.GetH5P}/listshared`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                console.log(res.data)
                alert("session Expires")

                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            // this.redirectToLogin()
            alert("session Expires")
            console.error('Error fetching shared content list:', error);
            throw error;
        }
    }
    private csrfToken: string | undefined = undefined;

    getContentStatus = async (contentId: string): Promise<void> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/${contentId}/getcontentstatus`);

            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            throw new Error(`${res.status} ${res.statusText}`);
        } catch (error) {
            console.error('Error during content save:', error);
            throw error;
        }
    };
    // Function to retrieve content metadata (Tesfa)
    getContentMeta = async (contentId: string | undefined): Promise<ContentMetadata> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/${contentId}/getcontentmeta`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            throw new Error(`${res.status} ${res.statusText}`);
        } catch (error) {
            console.error('Error during content save:', error);
            throw error;
        }
    }

    // / Function to update content status
    updateContentStatus = async (contentId: string, newStatus: string): Promise<void> => {
        try {
            const res = await api.post(`${Apis.GetH5P}/${contentId}/postcontentstatus`, {
                contentId,
                newStatus
            });

            if (res.status === 200) {
                const { status } = res.data; // Get the status from the response data

                return status;
            }
            else if (res.status === 401) {
                this.redirectToLogin()
            } else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            console.error('Error updating content status:', error);
            throw error;
        }
    }
    shareTo = async (contentId: string, shareTo: any): Promise<void> => {

        try {
            const response = await api.post(Apis.ResourceSharedTo, {
                resourceId: contentId.toString(), //  resource ID
                sharedTo: shareTo, // Array of email addresses
            });
        } catch (error) {
            console.error('Error sharing resource:', error);
            // Handle the error
        }
    }
    tagTo = async (contentId: string, shareTo: any): Promise<void> => {

        try {
            const response = await api.post(Apis.ResourceTaggedTo, {
                resourceId: contentId.toString(), //  resource ID
                sharedTo: shareTo, // Array of email addresses
            });
        } catch (error) {
            console.error('Error sharing resource:', error);
            // Handle the error
        }
    }

    delete = async (contentId: string): Promise<void> => {
        try {
            const result = await api.delete(`${Apis.GetH5P}/${contentId}`)
            if (result.status === 200) {
            }
            else if (result.status === 401) {
                this.redirectToLogin()
            }
            throw new Error(
                `Error while deleting content: ${result.status} ${result.statusText}`
            );
        } catch (error) {
            console.error('Error deleting content:', error);
        }
    };

    getEdit = async (contentId: string): Promise<IEditorModel> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/${contentId}/edit`)
            if (res.status === 200) {
                return res.data;
            }
            else if (res.status === 401) {
                this.redirectToLogin()
            }
            throw new Error(`${res.status} ${res.statusText}`);
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };

    getPlay = async (contentId: string): Promise<IPlayerModel> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/${contentId}/play`)
            if (res.status === 200) {
                return res.data;
            }
            // else if (res.status === 401) {
            //     this.redirectToLogin()
            // }
            throw new Error(`${res.status} ${res.statusText}`);
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };

    list = async (): Promise<IContentListEntry[]> => {
        try {
            const result = await api.get(Apis.GetH5P)
            if (result.status === 200) {
                return result.data;
            }
            else if (result.status === 401) {
                this.redirectToLogin()
            }
            throw new Error(
                `Request to REST endpoint returned ${result.status} ${result.statusText}`
            );
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };

    // listPublic = async (tagFilters:string[]=[]): Promise<IContentListEntry[]> => {
    //     try {
    //         const res = await api.get(`${Apis.GetH5P}/public?${tagFilters.length > 0 && 'tags='+tagFilters.join(',')}`);
    //         if (res.status === 200) {
    //             return res.data;
    //         } else if (res.status === 401) {
    //             this.redirectToLogin()
    //         }
    //         else {
    //             throw new Error(`${res.status} ${res.statusText}`);
    //         }
    //     } catch (error) {
    //         AxiosErrorHandling(error)
    //         throw error;
    //     }
    // };
    listPublic = async (tagFilters: string[] = [], sortOption: string = 'Most Popular'): Promise<IContentListEntry[]> => {
        try {
          const res = await api.get(`${Apis.GetH5P}/public?${tagFilters.length > 0 && 'tags=' + tagFilters.join(',')}&sort=${sortOption}`);
          if (res.status === 200) {
            return res.data;
          } else if (res.status === 401) {
            this.redirectToLogin()
          } else {
            throw new Error(`${res.status} ${res.statusText}`);
          }
        } catch (error) {
          AxiosErrorHandling(error)
          throw error;
        }
      };
    listTrash = async (): Promise<IContentListEntry[]> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/trash`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    listBieResources = async (): Promise<IContentListEntry[]> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/bieresources`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    listBieDemoResources = async (): Promise<IContentListEntry[]> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/biedemoresources`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    listBieGuidesResources = async (): Promise<IContentListEntry[]> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/bieguidesresources`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    listBieWalkthroughsResources = async (): Promise<IContentListEntry[]> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/biewalkthroughresources`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    setContentForcePublic = async (contentId: string): Promise<IContentListEntry[]> => {
        try {
            const res = await api.patch(`${Apis.GetH5P}/setContentForcePublic`, { contentId: contentId.toString() });
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    setRemoveContentForcePublic = async (contentId: string): Promise<IContentListEntry[]> => {
        try {
            const res = await api.patch(`${Apis.GetH5P}/setRemoveContentForcePublic`, { contentId: contentId.toString() });
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    getContentForcePublic = async (): Promise<IContentListEntry[]> => {
        try {
            const res = await api.get(`${Apis.GetH5P}/getContentForcePublic`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    bookmarks = async (email: string): Promise<IContentListEntry[]> => {

        try {
            const res = await api.get(Apis.ListBookmarks + email);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    }
    async listSharedWithMe(): Promise<IContentListEntry[]> {
        try {
            const res = await api.get(`${Apis.GetH5P}/listshared`);
            if (res.status === 200) {
                return res.data;
            } else if (res.status === 401) {
                this.redirectToLogin()
            }
            else {
                throw new Error(`${res.status} ${res.statusText}`);
            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    }

    save = async (contentId: string, requestBody: { library: string; params: any }
    ): Promise<{ contentId: string; metadata: IContentMetadata }> => {
        try {
            const body = JSON.stringify(requestBody);
            const res = contentId
                ? await api.patch(`${Apis.GetH5P}/${contentId}`, body)
                : await api.post(Apis.GetH5P, body);
            if (res.status === 200) {
                return res.data;
            }
            else if (res.status === 401) {
                this.redirectToLogin()
            }
            throw new Error(`${res.status} ${res.statusText}`);
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    saveclone = async (contentId: string, requestBody: { library: string; params: any }
    ): Promise<{ contentId: string; metadata: IContentMetadata }> => {
        try {
            const body = JSON.stringify(requestBody);
            const res = await api.post(Apis.GetH5P, body);
            if (res.status === 200) {
                return res.data;
            }
            else if (res.status === 401) {
                this.redirectToLogin()
            }
            throw new Error(`${res.status} ${res.statusText}`);
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    generateDownloadLink = (contentId: string): string =>
        `${API_URL}/h5p/download/${contentId}`;

    generateHtmlDownloadLink = (contentId: string): string =>
        `${API_URL}/html/${contentId}`;

    setCsrfToken = (csrfToken: string | undefined): void => {
        this.csrfToken = csrfToken;
    };
    getCsrfToken = (): string | undefined => {
        return this.csrfToken;
    };

    // Function to redirect to the login page
    redirectToLogin = async () => {
        try {
            let response = await this.getUserLogOut;
            if (await response()) {
                this.logout();
                window.location.href = '/login';

            }
        } catch (error) {
            AxiosErrorHandling(error)
            throw error;
        }
    };
    logout = () => {
        if (typeof window !== "undefined") {
            eraseCookie("authToken");
            eraseCookie("username");
            eraseCookie("XSRF-token");
            window.location.reload();
        }
    };

    getUserLogOut = async () => {
        try {
            const response = await api.post(Apis.GetUserLogout);

            if (response.status !== 200) {
                return null;
            }

            return response;
        } catch (error) {
            console.log(error);
            return null;
        }
    };
}





