import { Link } from "react-router-dom";
import './styles.css';
import Header from "../LandingPage/Header";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthStatus, setAuthenticate } from "../../redux/Reducer/authSlice";
import { GetUserLogin } from "../../services";

const ErrorPage = () => {

      const [lanTrigger, setLanTrigger] = useState("English");

      const dispatch = useDispatch();
      const isAuthenticated = useSelector(selectAuthStatus);
      const checkAuth = async () => {
        const response = await GetUserLogin.isAuthenticate();
        if (isAuthenticated !== response) {
          dispatch(setAuthenticate(response));
        }
      };
      checkAuth();

  return (
    <div className="error-page-container">
      <Header setLanTrigger={setLanTrigger} isAuthenticated={isAuthenticated}/>
      <div className="error-text-container">
        <h1 className="error-heading !text-greslernorange">404</h1>
        <div>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            {/* <i>Page {error.statusText || error.message}</i> */}
          </p>
        </div>
        <div>
          <Link to={"/"} className="error-btn-secondary !bg-greslernorange">
            Back to Home
          </Link>
        </div>
      </div>
      <div>
        <img
          src="https://envato.ukie.company/404-error/monkey/assets/img/monkey.gif"
          alt=""
        />
      </div>
    </div>
  );
};

export default ErrorPage;
