import {
  faEnvelopeOpen,
  faFileCirclePlus,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastOpt } from "../../../../config";
import {
  ContentService,
  IContentListEntry,
} from "../../../../services/ContentService";
import FolderExplorerService from "../../../../services/FolderExplorerService";
import ContentCard from "../../../ContentFile/ContentCard";
import FolderContextMenu from "../../../folderContexMenu";
import FolderSelectElement from "../../../folderSelect";
import ToolTip from "../../../tooltip";
import ContentHeader from "../addon/contentHeader";
import ResourceCardSkeleton from "../../../loader/ResourceCardSkeleton";

interface IFolder {
  _id: string;
  name: string;
  contentId: string[];
  parent: IFolder | null;
  children: IFolder[];
  isOpen: boolean;
}
interface IFolderSummary {
  _id?: string;
  name?: string;
}
const initialContextMenu = {
  show: false,
  x: 0,
  y: 0,
};

interface Props {
  layout: string;
}
const MyResources: React.FC<Props> = ({ layout }) => {
  const { t } = useTranslation();

  const [folders, setFolders] = useState<IFolder[]>([]);
  const [currentFolder, setCurrentFolder] = useState<IFolder[]>([]);
  const [breadcrumbFolder, setBreadcrumbFolder] = useState<IFolderSummary[]>([]);
  const [folderId, setFolderId] = useState("");
  const [currentContentId, setCurrentContentId] = useState("");
  const [layoutTiles, setLayoutTiles] = useState(false);
  const [layoutTree, setLayoutTree] = useState(true);
  const [contextMenu, setContextMenu] = useState(initialContextMenu);
  const [contentList, setContentList] = useState<IContentListEntry[]>([]);
  const [visibleItems, setVisibleItems] = useState<IContentListEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [doUUpdateList, setDoUpdateList] = useState(false)
  const itemsPerLoad = 7;

  const setLayout = () => {
    if (layout === "tiles") {
      setLayoutTiles(true);
      setLayoutTree(false);
    } else if (layout === "tree") {
      setLayoutTiles(false);
      setLayoutTree(true);
    }
  };

  useEffect(() => {
    fetchFolders();
    updateList();
    setLayout();
  }, []);

  useEffect(() => {
    updateList();
  }, [doUUpdateList]);

  const contentService = new ContentService();
  const updateList = async () => {
    setLoading(true);
    await contentService.list().then((content) => {
      const revContent = content.toReversed()
      setContentList(revContent);
      setVisibleItems((revContent).slice(0, 14));
      setLoading(false);
    })
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      if (visibleItems.length !== contentList.length) loadMoreItems();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleItems]);

  const loadMoreItems = () => {
    setLoading(true)
    const currentLength = visibleItems.length;
    const moreItems = contentList.slice(currentLength, currentLength + itemsPerLoad);
    setVisibleItems(prevItems => [...prevItems, ...moreItems]);
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  };

  const updateFolder = async (contentId) => {
    await FolderExplorerService.removeFile(contentId);
    setTimeout(() => {
      currentFolder.length > 0
        ? openFolder(currentFolder[0]._id)
        : fetchFolders();

      currentFolder[0].children.forEach((f) =>
        f.children.filter((item) => item !== contentId)
      );
    }, 1000);
  };
  const fetchFolders = async () => {
    setCurrentFolder([]);
    setBreadcrumbFolder([]);
    const folders = await FolderExplorerService.fetchFolders();
    setFolders(folders);
    openFolder(folders[0]._id);
    setCurrentFolder(folders);
  };
  let i = 1;
  const checkFolderNameExists = (newFileName) => {
    let counter;
    currentFolder.length > 0
      ? (counter = currentFolder[0].children.filter(
        (f) => f.name.toLowerCase() === newFileName.toLowerCase()
      ).length)
      : (counter = folders.filter(
        (f) => f.name.toLowerCase() === newFileName.toLowerCase()
      ).length);
    if (counter === 0) {
      i = 1;
      return newFileName;
    }
    if (counter === 1) {
      const newName = `${newFileName.split(" (")[0]} (${i})`;
      i++;
      return checkFolderNameExists(newName);
    }
  };

  // Cleaned
  const createFolder = async (parentId, newFolderName = "") => {
    const newFolderNameCK = checkFolderNameExists(newFolderName);
    try {
      (await FolderExplorerService.createFolder(parentId, newFolderNameCK)) &&
        toast.success(
          `${newFolderNameCK} ${t("Created Successfully")}!`,
          toastOpt
        );
      currentFolder.length > 0
        ? openFolder(currentFolder[0]._id)
        : fetchFolders();
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  // Cleaned
  const openFolder = async (folderId) => {
    setFolderId(folderId);
    const folder = await FolderExplorerService.fetchFolderById(folderId);
    setCurrentFolder(folder);

    if (breadcrumbFolder.findIndex((f) => f._id === folderId) > -1) {
      setBreadcrumbFolder((breadcrumbFolder) =>
        breadcrumbFolder.slice(
          0,
          breadcrumbFolder.findIndex((f) => f._id === folderId) + 1
        )
      );
    } else {
      const folderSummery: IFolderSummary = {
        _id: folder[0]?._id,
        name: folder[0]?.name,
      };
      setBreadcrumbFolder((breadcrumbFolder) => [
        ...breadcrumbFolder,
        folderSummery,
      ]);
    }
  };
  // unused
  const setOpen = async (folder: IFolder) => {
    try {
      await FolderExplorerService.setOpen(folder._id);
      fetchFolders();
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };
  const handleDelete = (folderId) => {
    toast.warning(
      <div>
        <p>
          {t("Are you sure you want to delete")}{" "}
          {t("folder and all its content")} ?
        </p>
        <button
          className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 pt-2 px-2 mx-2 border-b-4 border-red-700 hover:border-red-500 rounded"
          onClick={() => deleteFolder(folderId)}>
          {t("Confirm")}
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 pt-2 px-2 mx-2 border-b-4 border-blue-700 hover:border-blue-500 rounded"
          onClick={handleCancelDelete}>
          {t("Cancel")}
        </button>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        draggable: false,
        closeButton: false,
        closeOnClick: false,
        className: "delete-warning",
        bodyClassName: "delete-warning-body",
        progressClassName: "delete-warning-progress",
        toastId: "delete-warning-toast",
        theme: "colored",
      }
    );
  };
  const deleteFolder = async (id: string) => {
    try {
      const deleted = await FolderExplorerService.deleteFolder(id);
      if (!deleted) {
        toast.error(t("Folder Deleted Failed!"), toastOpt);
        setDoUpdateList(true)
      } else {
        toast.dismiss("delete-warning-toast");
        toast.success(t("Folder Deleted Successfully!"), toastOpt);
        currentFolder.length > 0
          ? openFolder(currentFolder[0]._id)
          : fetchFolders();
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };
  const handleCancelDelete = () => {
    toast.dismiss("delete-warning-toast");
  };
  const renameFolder = async (folderId: string, newName) => {
    if (newName.toLowerCase() === "root" || newName.toLowerCase().length < 1) {
      newName.toLowerCase() === "root" && toast.warning(t("Can't name folder 'Root' or '' !"), toastOpt);
    } else {
      let status = true;
      currentFolder.length > 0
        ? currentFolder[0].children?.forEach((f) => {
          if (f._id.toString() != folderId && f.name == newName) {
            status = false;
          }
        })
        : folders[0].children?.forEach((f) => {
          if (f._id.toString() != folderId && f.name == newName) {
            status = false;
          }
        });
      if (status) {
        try {
          FolderExplorerService.renameFolder(folderId, newName);
          toast.success(`${t("Folder Renamed To ")}'${newName}' !`, toastOpt);
        } catch (error) {
          console.error("Error renaming folder:", error);
        }
      } else toast.error(`${t("Folder")} ${newName} ${t("Exists")}.`, toastOpt);
    }
  };

  const moveFolder = async (newParentId: string) => {
    try {
      if (folderId == newParentId) {
        toast.warning(t("Cant move to same folder!"), toastOpt);
      } else if (
        await FolderExplorerService.moveFolder(folderId, newParentId)
      ) {
        toast.success(t("Folder moved Successfully!"), toastOpt);
        currentFolder.length > 0
          ? openFolder(currentFolder[0]._id)
          : fetchFolders();
      } else {
        toast.error(`Folder moving error.`, toastOpt);
      }
    } catch (error) {
      console.error("Error moving folder:", error);
    }
  };

  const moveFileToFolder = async (folderId: string) => {
    if (folderId === "none") {
      toast.error(`Can't move content to root :).`, toastOpt);
    } else {
      try {
        await FolderExplorerService.moveFileToFolder(
          currentContentId,
          folderId
        );
        toast.success(t("File moved Successfully!"), toastOpt);
        fetchFolders();
      } catch (error) {
        console.error("Error moving file:", error);
      }
    }
  };
  // search
  const handleSearch = (e) => {
    const filteredData = contentList.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === "" ? updateList() : setContentList(filteredData);
  };
  const contextMenuClose = () => setContextMenu(initialContextMenu);
  const handleContextMenu = (e, id) => {
    e.preventDefault();
    setFolderId(id);
    const { pageX, pageY } = e;
    setContextMenu({ show: true, x: pageX - 25, y: pageY - 90 });
  };
  const handleDragStart = (e, itemId) => {
    setFolderId(itemId);
    e.currentTarget.classList.toggle("animate-bounce");
  };
  const handleDragEnd = (e, itemId) => {
    setFolderId(itemId);
    e.currentTarget.classList.toggle("animate-bounce");
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.currentTarget.classList.toggle("border-2");
    e.currentTarget.classList.toggle("scale-75");
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.classList.toggle("border-2");
    e.currentTarget.classList.toggle("scale-75");
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e, parentId) => {
    !currentContentId ? moveFolder(parentId) : moveFileToFolder(parentId);
  };

  // rendering Folders
  const renderFolder = (folder: IFolder) => {
    if (folder.name == 'Trash') {
      return (<></>)
    }
    return (
      <button
        key={folder._id}
        id={folder._id}
        draggable
        onDragStart={(e) => handleDragStart(e, folder._id)}
        onDragEnd={(e) => handleDragEnd(e, folder._id)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onDrop={(e) => handleDrop(e, folder._id)}
        className={`${folder.name == 'Trash' ? 'absolute right-0 bottom-0' : ''} ease-in-out border-dotted border-green-400 group  py-20 px-4 flex flex-col space-y-2 items-center cursor-move rounded-md  hover:smooth-hover `}>
        <div
          className={`${layoutTiles ? "flex-col" : "flex-row"
            } flex  items-center relative`}>


          <FontAwesomeIcon
            icon={
              folder.name == 'Trash' ? faTrashAlt : layoutTiles ? faFolder : folder.isOpen ? faFolderOpen : faFolder
            }
            size={folder.name == "Trash" ? "3x" : layoutTiles ? "6x" : "2x"}
            className={`${folder.name == 'Trash' ? "text-red-500 hover:text-red-700" : "text-blue-500 hover:text-blue-700 "} cursor-pointer  mr-2 col-start outline-pink-500`}
            onDoubleClick={
              layoutTiles ? () => openFolder(folder._id) : () => setOpen(folder)
            }
          // onContextMenu={(e) => handleContextMenu(e, folder._id)}
          />
          {
            folder.name !== 'Trash' &&
            <FontAwesomeIcon
              icon={faTimes}
              className="absolute bg-red flex items-center justify-center w-8 h-8 md:w-4 md:h-4 text-xs font-bold text-white border-2 !border-red rounded-full top-0 right-2/3 dark:border-gray-900 md:invisible group-hover:!visible hover:cursor-pointer transform transition-transform  ease-in-out hover:scale-150 rotate-0 hover:rotate-[90deg]"
              onClick={() => handleDelete(folder._id)}
            />
          }

          <input
            type="text"
            spellCheck={false}
            disabled={folder.name == 'Trash' || folder.name == 'Root'}
            onBlur={(e) =>
              renameFolder(folder._id, e.target.value)
            }
            onKeyDown={(e) => {
              e.key == "Enter" &&
                renameFolder(folder._id, e.currentTarget.value);
            }}
            className=" active:placeholder-opacity-50 placeholder-white placeholder-opacity-100 !bg-transparent w-full text-white text-center font-bold  hover:cursor-text focus:text-black focus:bg-greslernblue"
            placeholder={folder.name}
          />


        </div>
      </button>
    );
  };

  return (
    <>
      {contextMenu.show && (
        <FolderContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          closeContextMenu={contextMenuClose}>
          <button
            onClick={() => openFolder(folderId)}
            className="border-b-2 border-gray pb-2 hover:bg-blue hover:text-blue">
            <FontAwesomeIcon icon={faEnvelopeOpen} /> {t("Open")}{" "}
          </button>
          <button
            onClick={() => createFolder(folderId, "New Folder")}
            className="border-b-2 border-gray pb-2 hover:bg-blue hover:text-blue">
            <FontAwesomeIcon icon={faFolderPlus} /> {t("NewFolder")}{" "}
          </button>
          <a
            href={`/home/dashboard/new/${folderId}`}
            className="border-b-2 border-gray pb-2 hover:bg-blue hover:text-blue">
            <FontAwesomeIcon icon={faFileCirclePlus} /> {t("NewFile")}{" "}
          </a>
          <button
            onClick={() => handleDelete(folderId)}
            className="border-b-2 border-gray pb-2 hover:bg-blue hover:text-blue">
            <FontAwesomeIcon
              icon={faTrashAlt}
              color="red"
              className="highlight"
            />{" "}
            {t("Delete")}{" "}
          </button>
          <FolderSelectElement setfoldertosave={moveFolder} />
        </FolderContextMenu>
      )}
      <div className="flex-row flex-grow ">
        {/* <div className="md:grid md:grid-cols-12 md:gap-6"> */}
        <div className="flex-1 px-2 sm:px-0">
          {layoutTiles && (
            <div className="flex flex-wrap justify-between items-center">

              <ContentHeader title={`${t("MyResources")}`} showIcon={false} />
              {/* <button
                onClick={handleRefreshClick}
                className="ml-2 text-yellow-900 focus:outline-none transform hover:rotate-90 transition duration-300 ease-in-out text-3xl font-bold"
                title={t("Refresh")}>
                ↻
              </button> */}

              <div className="inline-flex items-center space-x-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20">
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>

                  <input
                    onChange={handleSearch}
                    type="search"
                    id="default-search"
                    placeholder={t("Search Contents")}
                    className="block w-full pl-10 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <button
                  className="bg-gray-900 text-white/50 p-2 rounded-md hover:text-white smooth-hover"
                  onClick={() =>
                    createFolder(currentFolder[0]?._id, "New Folder")
                  }>
                  <ToolTip tooltip="create new folder">
                    <FontAwesomeIcon icon={faFolderPlus} />
                  </ToolTip>
                </button>
              </div>
            </div>
          )}
          {layoutTiles && (
            <ol
              className="flex items-center whitespace-nowrap min-w-0 py-2"
              aria-label="Breadcrumb">
              <li className="text-sm">
                <a
                  className="flex items-center text-gray-500 hover:text-blue-600"
                  onClick={() => fetchFolders()}
                  href="/home/dashboard/myresources">
                  <FontAwesomeIcon className="pr-1" icon={faFolder} />
                </a>
              </li>
              {breadcrumbFolder.map((folder, k) => (
                <li key={`${k}breadcrumbFolder`} className="text-sm">
                  <button
                    className="flex items-center text-gray-500 hover:text-blue-600"
                    onClick={(e) => {
                      e.preventDefault();
                      openFolder(folder._id);
                    }}
                  >
                    <svg
                      className="flex-shrink-0 overflow-visible h-2.5 text-blue-600 dark:text-blue"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                    {folder.name}
                  </button>
                </li>
              ))}
            </ol>
          )}

          <hr />
          <ul className="mb-10 sm:mb-0 mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 3xl:grid-cols-10">
            {
              currentFolder[0]?.name !== 'Trash' ?
                <li>
                  <div className="group bg-gray-900/30 sm:py-20 py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:bg-gray-900/40 hover:smooth-hover">
                    <a
                      className="bg-gray-900/70 text-white/50 group-hover:text-white group-hover:smooth-hover flex w-20 h-20 rounded-full items-center justify-center"
                      href={`/home/dashboard/new/${currentFolder.length > 0 && currentFolder[0]?._id
                        }`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </a>
                    <a className="text-white/50 group-hover:text-white group-hover:smooth-hover text-center">
                      {t("CreateResources")}
                    </a>
                  </div>
                </li>
                : ""
            }
            {layoutTiles &&
              currentFolder.length > 0 &&
              currentFolder[0].contentId?.toReversed().map((c, i) =>
                visibleItems
                  .filter((content) => content.contentId == c)
                  .map((filteredContent) => (
                    <div
                      className={
                        i == 0
                          ? "animate-fadeInRt3"
                          : i == 1
                            ? "animate-fadeInRt2"
                            : i == 2
                              ? "animate-fadeInRt1"
                              : "animate-fadeInRt"
                      }>
                      <ContentCard
                        content={filteredContent}
                        setCurrentContentId={setCurrentContentId}
                        updateFolder={updateFolder}
                        contentType={"owner"}
                      />
                    </div>
                  ))
              )}
            {layoutTiles &&
              currentFolder.length > 0 &&
              currentFolder[0].children?.map((folder) => renderFolder(folder))}
            {loading &&
              <ResourceCardSkeleton />}
            {!loading && visibleItems.length === contentList.length && <p className="text-2xl text-greslernorange">{t("No more items to load.")}</p>}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MyResources;