import axios from 'axios';
import { getCookie } from '../function';
import { io } from 'socket.io-client';

// Determine API URL based on the environment
const isLocal = document.domain === 'localhost';

let API_URL: any
let socket: any

API_URL = process.env.REACT_APP_NODE_ENV == 'test' ? process.env.REACT_APP_TEST_URL
  : process.env.REACT_APP_NODE_ENV == 'production' ? process.env.REACT_APP_PRODUCTION_URL
    : process.env.REACT_APP_LOCAL_URL;

const socketOptions = {
  withCredentials: true,
  path: "/greslernsocket"
};
if (API_URL) {
  socket = io(API_URL.substring(0, API_URL.length - 4), socketOptions);
  console.log(socket)
}

const axiosConfig = {
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('language') || 'en',
  },
};

const axiosFileConfig = {
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-type': 'multipart/form-data',
    'Accept-Language': localStorage.getItem('language') || 'en',
  },
};

const instance = axios.create(axiosConfig);
const axiosFile = axios.create(axiosFileConfig);

// Get CSRF token if available
const csrfToken = getCookie('csrfToken');
// if (csrfToken) {
//   instance.defaults.headers['CSRF-Token'] = csrfToken;
// }
if (csrfToken) {
  instance.defaults.headers['CSRF-Token'] = csrfToken;
  axiosFile.defaults.headers['CSRF-Token'] = csrfToken;
}
export default instance;
export { API_URL, socket, axiosFile };
