import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getmetaresource = async () => {
    try {
        let result = await api.get(Apis.GetDataReport)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getresourceById = async (resourceId: any) => {
    try {
        let result = await api.get(Apis.GetDataReport + resourceId)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getresourceReport = async (duration: any) => {
    try {
        let result = await api.get(Apis.GetDataReport + duration)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
export default {
    getresourceById,
    getmetaresource,
    getresourceReport
}