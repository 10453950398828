import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getresourceshared = async () => {
    try {
        let response = await api.get(Apis.GetResourceShared)
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getresourceByResId = async (resourceId: any) => {
    try {
        let result = await api.get(Apis.GetResourceShared + resourceId)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const countSharedWithMe = async () => {
    try {
        let result = await api.get(`${Apis.GetH5P}/countshared`);
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};


export default {
    getresourceshared,
    getresourceByResId,
    countSharedWithMe
}