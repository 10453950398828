

import { faBars, faClose, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll';
import DefaultLandingPageLayout from '../DefaultLandingPageLayout'
import { useState } from 'react';
import { dir } from 'tmp-promise';
import TermsAndConditionsContents from './contents';

const TermsAndConditions = () => {
    const [showSideHeaders, setShowSideHeaders] = useState(true)
    const handleSideHeaders = () => {
        setShowSideHeaders(!showSideHeaders)
    }
  return (
   <DefaultLandingPageLayout>
    <div id="termsandconditions">
        <div className=" text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto ">
            <h1 id='privacy' className="mb-2 text-xl font-bold md:text-3xl text-greslernorange">WEBSITE GENERAL</h1>
            <h1 id='privacy' className=" whitespace-nowrap w-ful mb-2 text-2xl font-bold md:text-6xl text-center text-greslernorange">TERMS AND CONDITIONS <br /> </h1>
            <p className="text-gray-700 ">Last updated: May 16, 2024 </p>
            <Link to={'https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/documents/pdf/GreSLERN-General-Terms-and-Conditions.pdf'} target='_blank' download={true} className=' text-greslernorange text-sm hover:text-greslernorange/50 hover:underline' >download <FontAwesomeIcon icon={faFilePdf} /></Link>
        </div>
        <div className='m-0 flex flex-col fixed'>
            <button onClick={handleSideHeaders} className='bg-greslernorange/20 text-greslernorange rounded-md w-fit px-2'>
                {showSideHeaders ? <FontAwesomeIcon icon={faClose} /> : <FontAwesomeIcon icon={faBars} /> }
            </button>
            {
            showSideHeaders ? 
                <div dir='rtr' className='p-4 px-2 bg-greslernorange md:bg-greslernorange/20 w-auto h-auto !max-w-xs !max-h-60 rounded-md text-white md:!text-greslernorange font-bold overflow-y-auto scrollbar-thin scrollbar-webkit scrollbar-left'>
                    <div dir="ltr" className='flex flex-col  '>
                        <ScrollLink to={'termsandconditions'}  smooth={true} duration={500} className={'cursor-pointer'} >TERMS & CONDITIONS</ScrollLink>
                        <ScrollLink to={'parties'}  smooth={true} duration={500} className={'cursor-pointer'} >1. PARTIES</ScrollLink>
                        <ScrollLink to={'rightofuse'}  smooth={true} duration={500} className={'cursor-pointer'} >3. RIGHT OF USE</ScrollLink>
                        <ScrollLink to={'introduction'}  smooth={true} duration={500} className={'cursor-pointer'} >4. INTRODUCTION	</ScrollLink>
                        <ScrollLink to={'definitions'}  smooth={true} duration={500} className={'cursor-pointer'} >5. DEFINITIONS</ScrollLink>
                        <ScrollLink to={'useofthiswebsite'}  smooth={true} duration={500} className={'cursor-pointer'} >6. USE OF THIS WEBSITE	</ScrollLink>
                        <ScrollLink to={'amendmentstocontentandinformation'}  smooth={true} duration={500} className={'cursor-pointer'} >7. AMENDMENTS TO CONTENT AND INFORMATION</ScrollLink>
                        <ScrollLink to={'disclaimers'}  smooth={true} duration={500} className={'cursor-pointer'} >8. DISCLAIMERS</ScrollLink>
                        <ScrollLink to={'indemnity'}  smooth={true} duration={500} className={'cursor-pointer'} >9. INDEMNITY</ScrollLink>
                        <ScrollLink to={'noadvice'}  smooth={true} duration={500} className={'cursor-pointer'} >10. NO ADVICE</ScrollLink>
                    </div>
                </div>
                : undefined
            }
            {/* 
                
                11. USE AND SUBSCRIPTIONS	9
                12. DATA MESSAGES AND EMAILS	10
                13. MONITORING AND INTERCEPTION OF COMMUNICATIONS	10
                14. SUBMISSION AND UPLOADING OF MATERIALS	11
                15. LICENSE AND WARRANTY FOR YOUR SUBMISSIONS	11
                16. REMOVAL OF CONTENT	12
                17. SECURITY	13
                18. INTELLECTUAL PROPERTY RIGHTS GRANTED	14
                19. PRIVACY POLICY AND CONFIDENTIALITY OF COMMUNICATIONS	15
                20. ADVERTISING AND SPONSORSHIP	18
                21. AUTOMATED SEARCHING	18
                22. HYPERLINKS, DEEP LINKS, FRAMING AND WEB CRAWLERS	19
                23. TERMINATION	19
                24. AMENDMENT OF THE GENERAL TERMS AND CONDITIONS	20
                25. CONFIDENTIALITY	21
                26. MEMBERSHIP	22
                27. ACCEPTABLE USE	23
                28. LIMITATION OF LIABILITY	24
                29. WARRANTIES	25
                30. INDULGENCE	26
                31. WHOLE AGREEMENT AND UPDATES	26
                32. SEVERABILITY	27
                33. NO URGENT OR INJUNCTIVE RELIEF	27
                34. APPLICABLE LAW	27
                35. DISPUTES	27
                36. DOMICILIUM	28
                37. POLICIES	28 
            */}
        </div>
        <TermsAndConditionsContents />
    </div>
   </DefaultLandingPageLayout>
  )
}

export default TermsAndConditions