import { faInstitution } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import CardContainer from "./CardContainer";
import { Link } from "react-router-dom";

const InstituresStatisticsCard = ({ institutes = [] }) => {

  const { t } = useTranslation()

  return (
    <Link to='/admin/institutes'>
      <CardContainer
        icon={
          <FontAwesomeIcon
            icon={faInstitution}
            className=" w-1/2 h-1/2 rounded-full m-1 text-white"
          />
        }
        iconBgColor="bg-greslernorange"
        title='Institutes'
        subTitle="institutes"
        statistics={
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-white ">
              {institutes ? institutes?.length : "0"}
            </p>
            <p className="text-sm font-normal text-white/50">{t("Total")}</p>
          </div>
        }
        shadowColor="shadow-greslernorange"
      />
    </Link>
  );
};

export default InstituresStatisticsCard;
