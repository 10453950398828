import moment from "moment";
import { API_URL } from "../../../apiConfig";
import './style.css'

const ReviewCard = ({
  review: { user, BIEs, createdAt },
}) => {
  return (
    <div className="text-gray-700">
      {/* Name, image, and star */}
      <div className="flex items-center gap-2 border-b-2 py-2">
        <div className="avatar">
          <div className="w-4 h-4 rounded-full ring ring-secondary ring-offset-base-100 ring-offset-2">
            <img
              src={`${API_URL}/public/images/${user?.image}`}
              alt="" />
          </div>
        </div>
        <h4 className="font-semibold">{user?.name}</h4>

        <div className="rating relative inline-flex">
          {[...Array(parseInt(BIEs?.score)).keys()].map((idx) => (
            <span
              key={idx}
              //  name="rating-2"
              className="mask mask-star-2 bg-greslernorange w-3 h-3"
            />
          ))}
        </div>
        <span>({parseFloat(BIEs?.score).toFixed(2)})</span>
        <span className="italic text-xs flex-1 text-right leading-none">
          {moment(createdAt).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default ReviewCard;
