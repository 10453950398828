import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import countries from "countries-list"; // Import the countries-list library

import InstitutionsTable from "./components/tables/InstitutionsTable";
import InvitationTable from "./components/tables/InvitationTable";
import ContentLoader from "../loader/ContentLoader";
import ModalComponent from '../ModalComponent';
import { GetUsers } from "../../services";
import InviteInstituteForm from "./components/InviteInstituteForm";


type profileprops = {
  profilephoto: string;
  name: string;
  email: string;
  bio: string;
  type: string;
  gender: string;
  country: string;
  username: string;
  phone: string;
  createdAt: Date;
  status: string;
  contactName: string;
};
const Institutes = () => {
  const [invitationsentlist, setInvitationsentlist] = useState<profileprops[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [deleteComfirmation, setDeleteComfirmation] = useState(false);
  const [emailFordelete, setEmailFordelete] = useState();
  const [updateList, setUpdateList] = useState(true);
  const [isInstitution, setIsInstitution] = useState(false)
  const [isInvitation, setIsInvitation] = useState(false)
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const countryList = Object.values(countries.countries);
  countryList.sort((a, b) => a.name.localeCompare(b.name));
  const fetchInstitutesList = async () => {
    setIsInstitution(false)
    let resource = await GetUsers.getinstitutelist();
    if (resource) {
      setIsInstitution(true)
    }
  };
  // const removedExpiredToken = async () => {
  //   await GetUsers.removeExpireTokenInvited();
  // };
  const fetchinvitationsentlist = async () => {
    setIsInvitation(false)
    let resource = await GetUsers.getinvitationsentlist();
    if (resource) {
      setInvitationsentlist(resource);
      setIsInvitation(true)
    }
    setIsInvitation(true)
  };
  const showFormModal = async (email) => {
    setShowForm(!showForm);

  }
  const handleDeleteUser = async (deleteEmail) => {
    setEmailFordelete(deleteEmail);
    setDeleteComfirmation(true);
  }
  const deleteInstitute = async (emailFordelete) => {
    try {
      const data = await GetUsers.deleteInvitedInstitute(emailFordelete);
      if (data) {
        NotificationManager.success("Successfull deleted", "Institute");
        setDeleteComfirmation(false);
        setUpdateList(!updateList);
      }
      else {
        NotificationManager.warning("Not deleted", "Institute")
        setDeleteComfirmation(false);
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "Institute")
      setDeleteComfirmation(false);
    }
    setDeleteComfirmation(false);
  }
  const openDelete = () => {
    setDeleteComfirmation(!deleteComfirmation);
    setShow(!show);
  };

  useEffect(() => {
    // removedExpiredToken();
    fetchInstitutesList();
    fetchinvitationsentlist();
  }, [updateList])
  return (
    <>
      <div className="container mx-auto px-2 sm:px-4">
        <div className="py-8">
          <div className="flex flex-row  justify-end ml-20">
            <div>
              <button onClick={showFormModal} className=" bg-transparent hover:!bg-greslernorange text-greslernorange font-semibold hover:text-white py-2 px-4 border border-greslernorange hover:border-transparent rounded">
                <h5 className="text-orange-300">+ {t("Register Institute")}</h5>
              </button>
            </div>
          </div>
          <div className="-mx-3 mb-5">
            {isInstitution ?
              <InstitutionsTable />
              : <ContentLoader />}
          </div>
          <div className="flex flex-wrap -mx-3 mb-5">
            {isInvitation ?
              <InvitationTable invitations={invitationsentlist || ""} handleDeleteUser={handleDeleteUser} />
              : <ContentLoader />}
          </div>
        </div>
      </div>
      {showForm && <InviteInstituteForm />}
      {deleteComfirmation &&
        <ModalComponent isOpen={show} title={false} onClose={openDelete}>
          <label>{t("Delete Invited Inst")}.</label>
          <label>{t("Do you want to delete the institute?")}</label>
          <button className="btn btn-default" onClick={openDelete}>{t("Cancel")}</button>
          <button className="btn btn-primary" onClick={() => deleteInstitute(emailFordelete)}>{t("OK")}</button>
        </ModalComponent>
      }
    </>

  );
}
export default Institutes;