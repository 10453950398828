import { faArrowLeft, faArrowRight, faFileDownload, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../ModalComponent';
import { useEffect, useState } from 'react';
import { NotificationManager } from "react-notifications";
import { CreateInstituteInvitation, GetUsers } from '../../../services';
import countries from "countries-list";
import { Spin } from 'react-cssfx-loading';
type profileprops = {
    profilephoto: string;
    name: string;
    email: string;
    bio: string;
    type: string;
};
export default function InviteInstituteForm() {
    const { t } = useTranslation();
    const [show, setShow] = useState(true);
    const [file, setFile] = useState(null);
    const [updateList, setUpdateList] = useState(true);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [contactName, setContactName] = useState('');
    const countryList = Object.values(countries.countries);
    const [currentPage, setCurrentPage] = useState(1);
    const [ischecked, setIschecked] = useState(false)
    const [isExistingUser, setIsExistingUser] = useState(false)
    const [newUserInvite, setNewUserInvite] = useState(true)
    const [showFileInput, setShowFileInput] = useState(false)
    const [showNormalInput, setShowNormalInput] = useState(true)
    const [membersList, setMembersList] = useState<profileprops[]>([]);

    countryList.sort((a, b) => a.name.localeCompare(b.name));
    const handleClose = () => {
        setShow(false);
    };
    const handleBulkemailButton = () => {
        setIschecked(!ischecked);
        setShowFileInput(!showFileInput);
        setShowNormalInput(!showNormalInput);
    }
    const handleNext = () => {
        setCurrentPage(currentPage + 1);
    };
    const handlePrevious = () => {
        setCurrentPage(currentPage - 1);
    };
    const handleGenerateForCsv = async (e) => {
        e.preventDefault();
        if (!file) {
            NotificationManager.warning("All filed Required", "Generate");
            setUpdateList(!updateList);
            return;
        }
        try {
            setLoading(true);
            const userdata = new FormData();
            userdata.append("fileCsv", file ?? '');
            let data = await CreateInstituteInvitation.createinstitutesinvitationCvs(userdata);
            if (data) {
                setUpdateList(!updateList);
                setLoading(false);
                NotificationManager.success("Successfull sent", "Invitation");
            }
            else {
                setLoading(false);
            }
        } catch (error) {
            NotificationManager.error("You cant generate link, Try Again", "Invitation", error);
            setLoading(false);
        }
    };
    const handleGenerate = async (e) => {
        e.preventDefault();
        if (!name || !email) {
            NotificationManager.warning("All filed Required", "Generate");
            return;
        }
        try {
            setLoading(true);
            const userdata = {
                name, email, contactName, country
            }
            if (isExistingUser) {
                let data = await CreateInstituteInvitation.createinstituteinvitationexistuser(userdata);
                if (data) {
                    setLoading(false);
                    NotificationManager.success("Done", "Generate");
                    setUpdateList(!updateList);
                }
                setLoading(false);
            }
            else {
                let data = await CreateInstituteInvitation.createinstituteinvitation(userdata);
                if (data) {
                    setLoading(false);
                    NotificationManager.success("Done", "Generate");
                    setUpdateList(!updateList);
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            NotificationManager.error("You can't generate link, Try Again", "Generate");
        }
    };
    const fetchlistofusers = async () => {
        const data = await GetUsers.getuserlistAll();
        if (data) {
            setMembersList(data);
        }
        else {
            setMembersList([]);
        }
    }
    const handleExistingUser = () => {
        setIsExistingUser(!isExistingUser);
        setNewUserInvite(!newUserInvite);
    }
    const handleFileChange = (event) => {
        event.preventDefault();
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };
    useEffect(() => {
        fetchlistofusers();
    }, [updateList])
    return (
        <ModalComponent isOpen={show} title={false} onClose={handleClose}>
            <div className="bg-white mb-3 p-2 rounded-lg shadow-lg w-full">
                <div className="flex justify-center mb-3">
                    <span className="inline-block rounded-full text-greslernorange ">
                        <FontAwesomeIcon icon={faUserPlus} size='3x' />
                    </span>
                </div>
                <div className='flex justify-around mb-3 '>
                    <div>
                        <h2 className="text-xl text-center text-greslernorange uppercase font-bold mb-4">{t("Register Institute")}</h2>
                    </div>

                </div>
                <div className='w-full flex flex-row-reverse'>
                    <div className="">
                        {currentPage === 2 ? <button
                            onClick={handleExistingUser}
                            className=" nav-button hover:drop-shadow-lg flex w-full items-center justify-center rounded-full border px-7 text-base font-bold text-white-800 !shadow-greslernorange ring-greslernorange active:ring-1 hover:ring-1">

                            {ischecked && <FontAwesomeIcon icon={faArrowLeft} className='text-greslernorange' />}
                            <span className='px-2'>{isExistingUser ? t('New user') : t('Exsting User')}</span>
                            {!ischecked && <FontAwesomeIcon icon={faArrowRight} className='text-greslernorange' />}
                        </button> :
                            <button
                                onClick={handleBulkemailButton}
                                className=" nav-button hover:drop-shadow-lg flex w-full items-center justify-center rounded-full border px-7 text-base font-bold text-white-800 !shadow-greslernorange ring-greslernorange active:ring-1 hover:ring-1">

                                {ischecked && <FontAwesomeIcon icon={faArrowLeft} className='text-greslernorange' />}
                                <span className='px-2'>{ischecked ? t('single invitation') : t('bulk invitation')}</span>
                                {!ischecked && <FontAwesomeIcon icon={faArrowRight} className='text-greslernorange' />}
                            </button>
                        }
                    </div>
                </div>
                <form
                    onSubmit={ischecked ? handleGenerateForCsv : handleGenerate}
                    className='p-2'
                >
                    {currentPage === 1 && (
                        <>
                            {
                                showNormalInput && <>
                                    <div className="mb-1">
                                        <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Institute Name")} <span className="text-red-600">*</span></label>
                                        <input type="text" id="name"
                                            className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                            required
                                            placeholder="Enter company name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-1">
                                        <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Institute Country")} <span className="text-red-600">*</span></label>
                                        <select
                                            id="country"
                                            name="country"
                                            value={country}
                                            required
                                            onChange={(e) => {
                                                const selectedOptionValue = e.target.value;
                                                setCountry(selectedOptionValue)
                                            }}
                                            className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                        >
                                            <option hidden>
                                                {t("Select country")}
                                            </option>
                                            {countryList.map((country, k) => (
                                                <option
                                                    className="text-[#292929]"
                                                    key={`$countryList2${country.name}`}
                                                    value={country.name}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            }
                            {showFileInput &&
                                <>
                                    <div className="mb-1">
                                        <label className="block text-gray-700 text-sm font-semibold mb-2">{t("CSV File")} <span className="text-red-600">*</span> </label>
                                        <input type="file"
                                            onChange={handleFileChange}
                                            className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                        />
                                        <div className='flex justify-end text-sm text-greslernblue/80 p-2'>
                                            <a href='/downloads/greslern-bulk-invitation-template.csv' download><FontAwesomeIcon icon={faFileDownload} /> template</a>
                                        </div>
                                    </div>
                                    <div className="md:inline-flex w-full space-y-4 md:space-y-0 px-8 py-2 text-orange items-center">
                                        <div className="flex justify-between w-full mx-2">
                                            <button type="submit"
                                                className={
                                                    `border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white 
            ${loading && "bg-blue-500 text-white pointer-events-none w-full"}
            `}
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <Spin color="#ffffff" width="30px" height="30px" duration="1s" className='ml-20' />
                                                ) : (
                                                    t("Invite")
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </>

                            }
                            {!ischecked && <div className="md:inline-flex w-full space-y-4 md:space-y-0 px-8 py-2 text-orange items-center">
                                <div className="flex justify-end w-full mx-2">
                                    <button type="button" className="border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white" onClick={handleNext}>
                                        {t("Next")}
                                    </button>
                                </div>
                            </div>}
                        </>
                    )}
                    {currentPage === 2 && (
                        <>
                            {newUserInvite &&
                                <>
                                    <div className="mb-1">
                                        <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Contact Name")} <span className="text-red-600">*</span></label>
                                        <input
                                            className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                            id="name"
                                            name="contactName"
                                            type="text"
                                            placeholder={t("EnterName")}
                                            value={contactName}
                                            onChange={(e) => setContactName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-1">
                                        <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Contact Email")} <span className="text-red-600">*</span></label>
                                        <input
                                            className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder={t("EnterEmail")}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                            required
                                        />
                                    </div>
                                </>
                            }
                            {isExistingUser &&
                                <div className="mb-1">
                                    <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Select User")} <span className="text-red-600">*</span></label>
                                    <select
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => {
                                            const selectedOptionValue = e.target.value;
                                            setEmail(selectedOptionValue)
                                        }
                                        }
                                        className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                    >
                                        <option hidden>
                                            {t("Select user")}
                                        </option>
                                        {membersList.map((emailuser, k) => (
                                            <option
                                                className="text-[#292929]"
                                                key={`$emailuser${emailuser.email}`}
                                                value={emailuser.email}>
                                                {emailuser.email}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            }
                            <div className="md:inline-flex w-full space-y-4 md:space-y-0 px-8 py-2 text-orange items-center">
                                <div className="flex justify-between w-full mx-2">
                                    <button type="button" className="border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white" onClick={handlePrevious}>
                                        {t("Previous")}
                                    </button>
                                    <button type="submit"
                                        className={
                                            `border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white 
            ${loading && "bg-blue-500 text-white pointer-events-none w-full"}
            `}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <Spin color="#ffffff" width="30px" height="30px" duration="1s" className='ml-20' />
                                        ) : (
                                            t("Invite")
                                        )}
                                    </button>
                                </div>
                            </div>
                            <p className="text-red-400 text-xs text-center mt-4">
                                {t("invitation link will expired after 24 hours")}
                            </p>
                        </>
                    )}
                </form>
            </div>
        </ModalComponent>
    );
}
