import {
  faBookBookmark,
  faComments,
  faDashboard,
  faEllipsisH,
  faEllipsisV,
  faFolder,
  faGlobe,
  faInfoCircle,
  faRectangleXmark,
  faShareNodes,
  faTrashAlt,
  faUserLock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { socket } from "../../../apiConfig";
import { CountAllComment } from "../../../services";
import ContentHeader from "./addon/contentHeader";
import BieContentGuides from "./components/BieContentGuides";
import BieContentTemplates from "./components/BieContentTemplates";
import BieContentWalkthroughs from "./components/BieContentWalkthrough";
import "./styles.css";

const Dashbord = () => {
  const { t } = useTranslation();
  const [counterComment, setCounterComment] = useState(0);
  const [sharedCounter, setSharedCounter] = useState(0);
  const [additionalMenu, setAdditionlaMenu] = useState(false);
  const [leftDrawer, setLeftDrawer] = useState(false)
  const [templates, setTemplates] = useState(false)
  const [guides, setGuides] = useState(false)
  const [walkthroughs, setWalkthroughs] = useState(false)

  const toggleLeftDrawer = () => {
    setLeftDrawer(!leftDrawer)
    !templates && !walkthroughs && setGuides(true)
  }
  const showGuides = () =>{
    setLeftDrawer(true)
    setTemplates(false)
    setGuides(true)
    setWalkthroughs(false)
  }
  const showTemplates = () =>{
    setLeftDrawer(true)
    setTemplates(true)
    setGuides(false)
    setWalkthroughs(false)
  }
  const showWalkthroughs = () =>{
    setLeftDrawer(true)
    setTemplates(false)
    setGuides(false)
    setWalkthroughs(true)
  }


  const fetchCommentCounter = async () => {
    try {
      const resource = await CountAllComment.getallcommentcount();
      if (resource) {
        resource.forEach(data => {
          setCounterComment(prevCounter => data.countComment + prevCounter);
        });
      }
    } catch (error) {
      console.error("Error fetching feedback count:", error);
    }
  };


  const activeSideNave = " bg-greslernorange text-white ";
  const notActiveSideNave =
    "text-greslernorange bg-white hover:!bg-greslernorange hover:text-white smooth-hover ";

  const updateListShared = async () => {
    try {
      const response = await CountAllComment.countListSharedWithMe();
      const sharedCounter = response.length;
      setSharedCounter(sharedCounter);
    } catch (error) {
      console.error("Error counting shared resources:", error);
    }
  };


  useEffect(() => {
    fetchCommentCounter();
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    updateListShared();

    socket.on("like", () => {
      // Handle the updated like count received from the server
    });

    socket.on("connection", () => {
      console.log("server is connected");
      // Handle the updated like count received from the server
    });

  }, []);

  return (
  <div className="dashbord">
    <div className="bg-[#8f9dc4] py-2 ">
      <div className=" bg-cover bg-dashboard-pattern flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 sm:p-6 sm:my-2 sm:mx-4 sm:rounded-2xl">
        <div className="tbox relative bg-blue-900 px-2 lg:px-4 py-2 lg:py-10 sm:rounded-xl flex lg:flex-col justify-between">
          <nav className="!z-[8] flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-2  w-full">
            <NavLink to={"bieresources"} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " border border-greslernorange group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
              <FontAwesomeIcon className="text-2xl " icon={faInfoCircle} />
              <span className="absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-100">
                {t("BieResources")}
              </span>
            </NavLink>
            <NavLink to={"myresources"} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " border border-greslernorange group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
              <FontAwesomeIcon className="text-2xl " icon={faUserLock} />
              <span className="  absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-100">
                {t("MyResources")}
              </span>
            </NavLink>
            <NavLink to={"sharedtome"} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " border border-greslernorange group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
              {sharedCounter != 0 &&
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-greslernorange border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                  {sharedCounter}
                </div>
              }
              <FontAwesomeIcon className="text-2xl " icon={faShareNodes} />
              <span className="  absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-100">
                {t("SharedResources")}
              </span>
            </NavLink>
            <NavLink to={"publicresources"} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " border border-greslernorange group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
              <FontAwesomeIcon className="text-2xl " icon={faGlobe} />
              <span className="absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-100">
                {t("AllResources")}
              </span>
            </NavLink>
            <button onClick={() => setAdditionlaMenu(!additionalMenu)} className={`${additionalMenu ? "bg-greslernorange text-white" : "bg-white !text-greslernorange"} md:hidden border border-greslernorange  p-3 inline-flex justify-center rounded-md`}>
              <FontAwesomeIcon icon={additionalMenu ? faEllipsisV : faEllipsisH} />
            </button>
            <div className={(additionalMenu ? "tbox bg-blue-900/90 p-2 w-1/2 translate-x-0 !absolute flex-col  top-16  space-y-3 scroll-smooth duration-100" : "") + "!z-[12] transform  flex space-x-2 lg:space-x-0 lg:flex-col lg:space-y-2 items-center translate-x-full md:translate-x-0"}>
              <NavLink to={"bookmarkresources"} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " border border-greslernorange group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
                <FontAwesomeIcon className="text-2xl " icon={faBookBookmark} />
                <span className="absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-100">
                  {t("bookMarks")}
                </span>
              </NavLink>

              <NavLink to={"myfolders"} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " border border-greslernorange group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
                <FontAwesomeIcon className="text-2xl " icon={faFolder} />
                <span className="absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-100">
                  {t("MyFolder")}
                </span>
              </NavLink>

              <NavLink to={"mycomments"} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " border border-greslernorange group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-greslernorange border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                  {counterComment !== undefined ? counterComment : 0}
                </div>
                <FontAwesomeIcon className="text-2xl " icon={faComments} />
                <span className="absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-100">
                  {t("myFeedbacks")}
                </span>
              </NavLink>
              <NavLink to={"AnalysisDashboard"} className={({ isActive }) => (isActive ? activeSideNave : notActiveSideNave) + " border border-greslernorange group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
                <FontAwesomeIcon className="text-2xl " icon={faDashboard} />
                <span className="absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-100">
                  {t("AnalysisDashboard")}
                </span>
              </NavLink>
              <NavLink to={"trash"} className={({ isActive }) => (isActive ? "border-white bg-red text-white" : "text-red") + " border !border-red hover:!bg-red hover:!text-white   group relative  p-3  !w-16 !h-16 inline-flex justify-center rounded-md origin-bottom-left  ease-in-out hover:rotate-2"}>
                <FontAwesomeIcon className="text-2xl " icon={faTrashAlt} />
                <span className="absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-auto px-2 py-1 bg-white/50 rounded-lg text-center text-black text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent ">
                  {t("Trash")}
                </span>
              </NavLink>
            </div>
          </nav>

          <div className="tdiv top" />
          <div className="tdiv right" />
          <div className="tdiv bottom" /> 
          <div className="tdiv left" />
        </div>
        <Outlet />
        
        <div className={`${leftDrawer ? 'w-1/4 transition-width duration-500 ease-in-out' : ' invisible w-0 transition-width duration-500 ease-in-out'} bg-white/60 rounded-md overflow-x-hidden`}>
          <div className="w-full flex justify-between items-center px-2">

            <ContentHeader title={t(guides ? "guides" : templates ? "templates":walkthroughs?'walkthrough' : '')} />
            
            <FontAwesomeIcon icon={faRectangleXmark} size="2x"
              onClick={toggleLeftDrawer}
              className="text-greslernorange/50 hover:text-greslernorange cursor-pointer"
            />
          </div>
          <div className="flex px-1 py-1 rounded-lg mt-2">
            {guides && <BieContentGuides /> }
            {templates && <BieContentTemplates />}
            {walkthroughs && <BieContentWalkthroughs />}
          </div>
        </div>
        {/* side floating buttons  */}
        <div className="capitalize group fixed  top-96 right-0 p-2  flex items-end justify-end w-24 h-24 ">
            <button onClick={toggleLeftDrawer} className=" absolute from-greslernorange/70 to-greslernorange shadow-xl -right-1 top-1/4 opacity-80 group-hover:!opacity-100 text-white cursor-pointer rounded-tr-lg rounded-tl-lg z-50 ">
                <FontAwesomeIcon icon={faInfoCircle} className=" bg-greslernorange text-white rounded-full p-1 animate-pulse group-hover:animate-none group-hover:bg-white group-hover:!text-greslernorange " size="3x" />
                {t("")}
            </button>
            <button onClick={showTemplates} className=" border border-greslernorange absolute rounded-full transition-all duration-[0.2s] ease-out scale-x-0 group-hover:scale-x-100 group-hover:-translate-x-6 group-hover:-translate-y-16  flex  p-2 hover:p-3 bg-greslernorange/70 hover:bg-greslernorange  text-white cursor-pointer">
                {t("template")}
            </button>
            <button onClick={showWalkthroughs} className=" border border-greslernorange absolute rounded-full transition-all duration-[0.2s] ease-out scale-y-0 group-hover:scale-y-100 group-hover:-translate-x-12 group-hover:-translate-y-4  flex  p-2 hover:p-3 bg-green-300 scale-100 hover:bg-green-400 text-white cursor-pointer">
                {t("walkthrough")}
            </button>
            <button onClick={showGuides} className=" border border-greslernorange absolute rounded-full transition-all duration-[0.2s] ease-out scale-y-0 group-hover:scale-y-100 group-hover:-translate-x-6 group-hover:translate-y-8  flex  p-2 hover:p-3 bg-blue-300 scale-100 hover:bg-blue-400 text-white cursor-pointer">
                {t("guide")}
            </button>
        </div>

      </div>
    </div>
  </div>
  );
};

export default Dashbord;