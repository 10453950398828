
import { setCookie, eraseCookie } from "../../function";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { NotificationManager } from 'react-notifications';
import { setAuthenticate, setEmail, setUsername, setLogout, setUserId, setRole, setGuidelineAccepted, setIsFirstLog ,setInstitute} from "../../redux/Reducer/authSlice";
import { Apis } from "../../config";
import api from '../../apiConfig'
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

// Define an interface for the error response
interface ErrorResponse {
  error: string;
}
const signup = async (userData: any) => {
  try {
    const response: AxiosResponse = await api.post(Apis.GetUserRegsiter, userData);
    if (response.status === 201) {
      setCookie("VerificationEmail", userData.email, 1440);
      return response.data;
    }
    return null;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 400) {
        // Bad Request: Email already in use
        const errorData = axiosError.response.data as ErrorResponse;
        NotificationManager.error(errorData.error, 'SignUp')
        return null
      } else {
        // Handle other errors
        NotificationManager.error('An error occurred during registration.', "SignUp")
        return null
      }
    }
  }
}
const fetchUsers = async () => {
  try {
    const response = await api.get(Apis.GetUserList);

    if (response.status === 200) {
      return response.data;
    }

    throw new Error('Failed to fetch users');
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};

const getUserByEmail = async (email: string) => {
  try {
    const response = await api.post(Apis.GetUserByEmail, {
      email: email
    })
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  }
  catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
}

const getUserByToken = async (token: string) => {
  try {
    const response = await api.post(Apis.GetUserByToken, {
      token: token
    })
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  }
  catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
}

const getUserLogin = async (email: string, password: string) => {
  try {
    const response: AxiosResponse = await api.post(Apis.GetUserLogin, {
      email: email,
      password: password,
    });
    if (response.status === 200) {
      return response;
    }

  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 403) {
        NotificationManager.error(axiosError.response?.data, "SignUp")
        return undefined
      } else {
        // Handle other errors
        NotificationManager.error(axiosError.response?.data, "SignUp")
        return null
      }
    }
  }
};
// Function to send the verification code to the server
const sendVerificationCode = async (verificationCode: string) => {
  try {
    const response = await api.post(Apis.GetEmailVerificaton, { code: verificationCode }); // Adjust the URL as needed
    if (response.status === 200) {
      NotificationManager.success('Email verification Successfull', 'Verification');
      eraseCookie('VerificationEmail')
      window.location.href = '/login';
      return response.data
    } else {
      return null
    }
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
// Function to send the institution verification code to the server
const sendInstituteVerificationCode = async (verificationCode: string) => {
  try {
    const response = await api.post(Apis.GetInstituteEmailVerificaton, { code: verificationCode }); // Adjust the URL as needed
    if (response.status === 200) {
      NotificationManager.success('Email verification Successfull', 'Verification');
      eraseCookie('VerificationEmail')
      window.location.href = '/login';
      return response.data
    } else {
      return null
    }
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
// Function to send the verification code to the server
const reSendVerificationCode = async (email: string) => {
  try {
    const response = await api.post(`${Apis.GetResendVerification}/${email}`);
    if (response.status === 200) {
      NotificationManager.success('Verification code send sucessfully', 'Verification');
      return response.data
    } else {
      return null
    }
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
// Function to send the email to the server for password reset 
const sendEmailForPasswordReset = async (verificationEmail: string) => {
  try {
    const response = await api.post(Apis.GetForgetPassword, { email: verificationEmail }); // Adjust the URL as needed
    if (response.status == 200) {
      NotificationManager.success("Password reset link sent to you email", "Forget Password")
      return response.data
    } else {
      return null
    }
  } catch (error) {
    console.error('Error sending verification code:', error);
    NotificationManager.error("Some thing error sending Email", "Forget Password")
    return null
  }
};
const sendEmailForAccountReactivate = async (verificationEmail: string) => {
  try {
    const response = await api.post(Apis.GetReactivateAccount, { email: verificationEmail }); // Adjust the URL as needed
    if (response.status == 200) {
      NotificationManager.success("Reactivation link sent to you email", "Reactivate Account")
      return response.data
    } else {
      return null
    }
  } catch (error) {
    console.error('Error sending verification code:', error);
    NotificationManager.error("Some thing error sending Email", "Reactivate Account")
    return null
  }
};

const passwordReset = async (passwordReset: object) => {

  try {
    const response = await api.post(Apis.GetPasswordReset, passwordReset); // Adjust the URL as needed
    if (response.status == 200) {
      NotificationManager.success("Password reset successfully", "Reset Password")
      return response.data
    } else {
      return null
    }
  } catch (error) {
    console.error('Error sending verification code:', error);
    NotificationManager.error("Some thing error resetting password", "Reset Password")
    return null
  }
};
const reactivatepasswordReset = async (passwordReset: object) => {

  try {
    const response = await api.post(Apis.GetreactivatePasswordReset, passwordReset); // Adjust the URL as needed
    if (response.status == 200) {
      NotificationManager.success("Password reset successfully", "Reset Password")
      return response.data
    } else {
      return null
    }
  } catch (error) {
    console.error('Error sending verification code:', error);
    NotificationManager.error("Some thing error resetting password", "Reset Password")
    return null
  }
};
const putUserUpdate = async (updatedUserData: any) => {

  api.put(`/update/${updatedUserData.username}`, updatedUserData)
    .then(response => {
      // Handle success
    })
    .catch(error => {
      console.error('Error updating user:', error);
      // Handle error
    });
}

const getUserLogOut = async () => {
  try {
    const response = await api.delete(Apis.GetUserLogout);

    if (response.status !== 200) {
      return null;
    }

    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};


const authenticate = (user: any, dispatch, next: any) => {
  if (typeof window !== "undefined") {
    console.log(user)
    dispatch(setAuthenticate(true))
    dispatch(setUserId(user.userId))
    dispatch(setEmail(user.email))
    dispatch(setUsername(user.name))
    dispatch(setRole(user.role))
    dispatch(setGuidelineAccepted(user.guidelineAccepted))
    dispatch(setIsFirstLog(user.isFirstLog))
    dispatch(setInstitute(user.institute))
    setCookie("csrfToken", user.csrfToken, 1440);
    next();
  }
};
const Logout = (dispatch: any, next: any) => {
  if (typeof window !== "undefined") {
    dispatch(setLogout())
    eraseCookie("csrfToken");
    eraseCookie("connect.sid");
  }
};

const isAuthenticate = async () => {
  try {
    const response: AxiosResponse = await api.get(Apis.GetUserSession);
    if (response.status === 200) {
      return true
    }
    return false;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 401) {
        return false
      }
      return false;
    }
  }
};
const guidelineAccepted = async () => {
  try {
    const response = await api.patch(Apis.GuidelineAccepted);
    if (response.status === 200) {
      return true
    }
    return false;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 401) {
        return false
      }
      return false;
    }
  }
};
const getGuidelineAccepted = async () => {
  try {
    const response = await api.get(Apis.GetGuidelineAccepted);
    if (response.status === 200) {
      return response.data.guidelineAccepted
    }
    return false;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 401) {
        return false
      }
      return false;
    }
  }
};
const signupInstitute = async (userData: any) => {
  try {
    const response: AxiosResponse = await api.post(Apis.GetInstituteRegsiter, userData);
    if (response.status === 201) {
      setCookie("VerificationEmail", userData.email, 1440);
      return response.data;
    }
    return null;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 400) {
        // Bad Request: Email already in use
        const errorData = axiosError.response.data as ErrorResponse;
        NotificationManager.error(errorData.error, 'SignUp')
        return null
      } else {
        // Handle other errors
        NotificationManager.error('An error occurred during registration.', "SignUp")
        return null
      }
    }
  }
}
export default {
  signup,
  getUserLogin,
  authenticate,
  Logout,
  fetchUsers,
  isAuthenticate,
  getUserLogOut,
  sendVerificationCode,
  sendEmailForPasswordReset,
  getUserByEmail,
  reSendVerificationCode,
  passwordReset,
  sendEmailForAccountReactivate,
  reactivatepasswordReset,
  getGuidelineAccepted,
  guidelineAccepted,
  // generateLink,
  signupInstitute,
  sendInstituteVerificationCode,
  getUserByToken
}
