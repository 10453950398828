//Author muluken Teju --- search user modal component
// submite selected users information to the collection is not implimented
import { useState, useEffect } from "react";

import { GetResourceShared } from "../services";
import ModalComponent from "./ModalComponent";
import { useTranslation } from "react-i18next";

type resultProps = {
  username: string;
  name: String;
  _id: String;
  resourceId: String;
  selectedOptions: number;
  status: boolean;
  ownerUser: String;
  createdAt: String;
  creationDate: undefined;
  title: string;
};

const SharedUsersList = (props, showModel: boolean = true) => {
  const { t } = useTranslation();

  const [resourceId] = useState(props.resourceId);
  const [show, setShow] = useState(showModel);
  const [result, setResult] = useState<resultProps[]>([]);
  const [depArray, setDepArray] = useState([]);

  const handleClose = () => {
    setShow(false);
  };

  const fetchByResource = async (resourceId) => {
    let data = await GetResourceShared.getresourceByResId(resourceId);
    if (!data.ok) {
    }
    setDepArray(data[0].sharedTo);
    setResult(data);

  };
  useEffect(() => {
    fetchByResource(resourceId);
  }, []);

  return (
    <ModalComponent isOpen={show} title={false} onClose={handleClose}>
      <div className="w-full h-full bg-[#0e1c44a7]">

        <label className="block mb-2 font-bold text-white">
          {t("Shared to")} {depArray.length} {t(" users")}
        </label>
        <div className="">
          <ul role="list" className="divide-y divide-gray-100">
            {depArray?.map((share) => (
              <li className="flex justify-between gap-x-6 p-3 cursor-pointer bg-white border border-gray-300 rounded-md shadow-md mt-1">
                <div className="flex min-w-0 gap-x-4">
                  {/* {
                                      share.userId.profile ? 
                                      <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
                                      :<div className="w-8 h-8 items-center text-white text-2xl text-center">
                                          {share.userId.name.charAt(0).toUpperCase()}
                                      </div>
                                  } */}

                  <div className="flex-shrink-0">
                    <div className="w-8 h-8 rounded-full bg-black outline-dashed outline-2 outline-offset-2 outline-blue-500">
                      {share.userId.profile ? (
                        <img
                          className="h-12 w-12 flex-none rounded-full bg-gray-50"
                          src=""
                          alt="user"
                        />
                      ) : (
                        <div className="w-8 h-8 items-center text-white text-2xl text-center">
                          {share.userId.name.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {share.userId.name}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {share.userId.email}
                    </p>
                  </div>
                </div>
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">
                    {share.userId.type}
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    {share.userId.country}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-3 flex sm:flex-row-reverse  sm:px-6 ">
          <button
            type="button"
            className=" inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={handleClose}>
            {t("Close")}
          </button>
        </div>
      </div>
    </ModalComponent>

  );
};
export default SharedUsersList;
