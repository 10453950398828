import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const UsersListCard = (props: { usersList: any; action: (arg0: any) => void; }) => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5

  const filteredData = props.usersList

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="">
      <ul className="divide-y divide-gray-100">
        {currentRows?.map((user) => (
          <li key={user.email} className="flex justify-between gap-x-6 p-3 cursor-pointer bg-white border border-gray-300 rounded-md shadow-md mt-1 hover:!bg-greslernorange/20"
            onClick={() => props.action(user.email)}
          >
            <div className="flex min-w-0 gap-x-4">
              <div className="flex-shrink-0">
                <div className="w-8 h-8 rounded-full bg-greslernorange outline-dashed outline-2 outline-offset-2 outline-greslernorange/40">
                  {user.profilePicture ? (
                    <img
                      className="h-12 w-12 flex-none rounded-full bg-gray-50"
                      src=""
                      alt="user"
                    />
                  ) : (
                    <div className="w-8 h-8 items-center text-white text-2xl text-center">
                      {user.name.charAt(0).toUpperCase()}
                    </div>
                  )}
                </div>
              </div>
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {user.name}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                  {user.email}
                </p>
              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">
                {user.type}
              </p>
              <p className="mt-1 text-xs leading-5 text-gray-500">
                {user.country}
              </p>
            </div>
          </li>
        ))}
      </ul>
      {filteredData.length > 0 &&
        <nav aria-label="Table navigation" className='mt-4'>
          <ul className="inline-flex flex-wrap items-center -space-x-px">
            <li>
              <button
                onClick={handlePrevPage}
                className={`px-3 py-1 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-greslernorange dark:bg-gray-800 dark:border-greslernorange dark:text-gray-400 dark:hover:bg-greslernorange dark:hover:text-white ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''
                  }`}
              >
                {t("Prev")}
              </button>
            </li>
            {Array.from({ length: Math.ceil(filteredData.length / rowsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
              <li key={pageNumber}>
                <button
                  onClick={() => paginate(pageNumber)}
                  className={`px-3 py-1 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-greslernorange dark:bg-gray-800 dark:border-greslernorange dark:text-gray-400 dark:hover:bg-greslernorange dark:hover:text-white ${currentPage === pageNumber ? 'z-10 text-white !bg-greslernorange/70 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-blue-500 dark:bg-blue-900 dark:text-white dark:hover:bg-greslernorange/70' : ''
                    }`}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            <li>
              <button
                onClick={handleNextPage}
                className={`px-3 py-1 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-greslernorange dark:bg-gray-800 dark:border-greslernorange dark:text-gray-400 dark:hover:bg-greslernorange dark:hover:text-white ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''
                  }`}
              >
                {t("Next")}
              </button>
            </li>
          </ul>
        </nav>
      }
    </div>
  )
}

UsersListCard.propTypes = {
  usersList: [],
  action: () => { },
}

export default UsersListCard