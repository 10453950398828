import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd,  faTrash,  faUser,  faUserCheck, faUserClock,  faUserSlash, faUsers,  } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import countries from "countries-list";
import { FrequentService, GetUsers } from "../../services";
import Map from "./components/Map";
import RegisterMember from "./components/RegisterMember";
import UsesTable from "./components/tables/UsesTable";
import CreateGroup from "./components/Grouping/CreateGroup";
import GroupView from "./components/Grouping/GroupView";
import ModalComponent from "../ModalComponent";
import { useLocation } from "react-router-dom";

type instituteProps = {
  name: string;
  country: string;
  createdAt: any;
  email: string;
  generatedTokenExpiration: Date;
  status: string;
  _id: string;

};
const Institutes = () => {
  const location = useLocation();
  let valueOne = 3;
  if (location.state) {
    let { value } = location.state;
    valueOne = value;
  }
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [deleteComfirmation, setDeleteComfirmation] = useState(false);
  const [deleteComfirmationGroup, setDeleteComfirmationGroup] = useState(false);
  const [emailFordelete] = useState();
  const [updateList, setUpdateList] = useState(true);
  const [isIndivisual, setIsIndivisual] = useState(false)
  const [showGroup, setShowGroup] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showActive, setShowActive] = useState(valueOne === 0);
  const [showBanned, setShowBanned] = useState(valueOne === 1);
  const [showDeactivated, setShowDeactivated] = useState(valueOne === 2);
  const [showAll, setShowAll] = useState(valueOne === 3);
  const [showPending, setShowPending] = useState(valueOne === 5);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const countryList = Object.values(countries.countries);
  const [show, setShow] = useState(false);
  countryList.sort((a, b) => a.name.localeCompare(b.name));
  const showFormModal = async (email: any) => {
    setShowForm(!showForm);
  }
  const openDelete = () => {
    setDeleteComfirmation(false);
    setShowForm(false);
    setShowActive(false);
    setUpdateList(false);
    setShowGroup(false);
    setShowActive(false);
    setShowPending(false)
    setShowMap(false);
    setShowBanned(false);
    setShowGroupForm(false);
    setDeleteComfirmationGroup(false);
  };
  const deleteuser = async (newEmail: string | undefined) => {
    try {
      const data = await GetUsers.deleteInvitedInstitute(newEmail || "");
      if (data) {
        NotificationManager.success("Successfull removed", "user");
        setUpdateList(!updateList);
        setDeleteComfirmation(false);
      }
      else {
        NotificationManager.warning("Not deleted", "user")
        setDeleteComfirmation(false);
        setUpdateList(!updateList);
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "user")
      setDeleteComfirmation(false);
      setUpdateList(!updateList);
    }
    setDeleteComfirmation(false);
    setUpdateList(!updateList);

  }

  const handleGroupShow = () => {
    setShowGroup(true);
    setShowActive(false);
    setShowPending(false);
    setShowMap(false);
    setShowBanned(false);
    setShowAll(false);
    setShowDeactivated(false);

  }
  const handleActiveShow = () => {
    setShowGroup(false);
    setShowActive(true);
    setShowPending(false);
    setShowMap(false);
    setShowBanned(false);
    setShowAll(false);
    setShowDeactivated(false);
  }
  const handleShowAll = () => {
    setShowGroup(false);
    setShowActive(false);
    setShowPending(false);
    setShowMap(false);
    setShowBanned(false);
    setShowAll(true);
    setShowDeactivated(false);
  }
  const handlePendingShow = () => {
    setShowGroup(false);
    setShowActive(false);
    setShowPending(true);
    setShowMap(false);
    setShowBanned(false);
    setShowAll(false);
    setShowDeactivated(false);
  }
  const handleBannedShow = () => {
    setShowGroup(false);
    setShowActive(false);
    setShowPending(false);
    setShowBanned(true);
    setShowMap(false);
    setShowAll(false);
    setShowDeactivated(false);
  }
  const handleDeactivatedShow = () => {
    setShowGroup(false);
    setShowActive(false);
    setShowPending(false);
    setShowBanned(false);
    setShowMap(false);
    setShowAll(false);
    setShowDeactivated(true);
  }
  const handlecreateGroup = () => {
    setShowGroupForm(!showGroupForm);
  }
  const handleClose = () => {
    setShow(false);
  };
  const addtousedlink = async () => {
    const useddata = {
      usedlink:"team"
    }
    try {
       await FrequentService.addusedlink(useddata);
    } catch (error) {
    }
};
  useEffect(() => {
    addtousedlink();
  }, [updateList]);
  return (
    <>
      <div className="container mx-auto ">
        <div className="py-8">
          <div className="container mx-auto">
            <div className="py-1">
              <div className="flex justify-between bg-transparent mb-2 ">
                <div className="">
                  <ul className="flex flex-wrap text-center text-greslernorange ">
                    <button className="me-2" onClick={handleShowAll}>
                      <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group ${showAll ? " !border-greslernorange font-extrabold " : ""}`}>
                        <FontAwesomeIcon icon={faUser} />
                        <span>{t("Members")}</span>
                      </div>
                    </button>
                    <button className="me-2" onClick={handleActiveShow}>
                      <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group ${showActive ? " !border-greslernorange font-extrabold " : ""}`}>
                        <FontAwesomeIcon icon={faUserCheck} />
                        <span>{t("Active")}</span>
                      </div>
                    </button>
                    <button className="me-2" onClick={handleBannedShow}>
                      <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group ${showBanned ? " !border-greslernorange font-extrabold " : ""} `}>
                        <FontAwesomeIcon icon={faUserSlash} />
                        <span> {t("Ban")}</span>
                        <div className="mb-1 bg-orange-300">
                        </div>
                      </div>
                    </button>
                    <button className="me-2" onClick={handleDeactivatedShow}>
                      <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group ${showDeactivated ? " !border-greslernorange font-extrabold " : ""} `}>
                        <FontAwesomeIcon icon={faTrash} />
                        <span> {t("Deactivate")}</span>
                        <div className="mb-1 bg-orange-300">
                          {/* <span className="mt-5 text-xs text-red-500 ml-1 pb-2"> 1</span> */}
                        </div>
                      </div>
                    </button>
                    <button className="me-2" onClick={handlePendingShow}>
                      <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group active   ${showPending ? " !border-greslernorange font-extrabold " : ""} `}>
                        <FontAwesomeIcon icon={faUserClock} />
                        <span> {t("Pending")}</span>  <div className="mb-1 bg-orange-300">
                          {/* <span className="mt-5 text-xs text-red-500 ml-1 pb-2"> 1</span> */}
                        </div>
                      </div>
                    </button>
                    <button className="me-2" onClick={handleGroupShow}>
                      <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group active"  ${showGroup ? " !border-greslernorange font-extrabold " : ""} `}>
                        <FontAwesomeIcon icon={faUsers} />
                        <span>{t("Group")}</span>
                        <div className="mb-1 bg-orange-300 border border-spacing-4">
                          {/* <span className="mt-5 text-xs text-red-500 ml-1 pb-2"> 1</span> */}
                        </div>
                      </div>
                    </button>
                  </ul>
                </div>
                <div className="">
                  {showGroup ?
                    <button onClick={handlecreateGroup} className=" bg-greslernorange font-semibold text-white hover:!bg-white hover:!text-greslernorange py-2 px-2 border border-white hover:border-transparent rounded">
                      <FontAwesomeIcon icon={faAdd} /> {" "}
                      {t("Create Group")}
                    </button>
                    :
                    <button onClick={showFormModal} className=" bg-greslernorange font-semibold text-white hover:!bg-white hover:!text-greslernorange py-2 px-2 border border-white hover:border-transparent rounded">
                      <FontAwesomeIcon icon={faAdd} /> {" "}
                      {t("Register Member")}
                    </button>
                  }
                </div>
              </div>
              {showMap && <Map />}
              {showActive && <UsesTable status={0} />}
              {showBanned && <UsesTable status={1} />}
              {showDeactivated && <UsesTable status={2} />}
              {showAll && <UsesTable status={3} />}
              {showPending && <UsesTable status={5} />}
              {showGroup && <GroupView isShowRes={false}/>}
            </div>
          </div>
        </div>
      </div>
      {showForm && ( <RegisterMember />)   }
      {deleteComfirmation &&
        <ModalComponent isOpen={show} title={''} onClose={handleClose}>
          <label>{t("Delete Invited member")}.</label>
          <label>{t("Do you want to delete the member?")}</label>
          <button className="btn btn-default" onClick={openDelete}>{t("Cancel")}</button>
          <button className="btn btn-primary" onClick={() => { deleteuser(emailFordelete) }}>{t("OK")}</button>
        </ModalComponent>
      }
      {showGroupForm && <CreateGroup onClose={setShowGroupForm} />}
    </>

  );
}
export default Institutes;