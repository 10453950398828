import { Link, useParams } from "react-router-dom";
import Header from "../LandingPage/Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthStatus, setAuthenticate } from "../../redux/Reducer/authSlice";
import { GetUserLogin } from "../../services";
import DefaultLandingPageLayout from "../LandingPage/DefaultLandingPageLayout";
import SubscriberService from "../../services/SubscriberService";
import { faCheck, faCheckToSlot, faFilePdf, faSadCry, faSadTear, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import ErrorPage from "../ErrorPage/ErrorPage";

const UnsubscribeConfirmation = () => {

  const { email } = useParams();

  const [Message, setMessage] = useState({
    status:false,
    title:'',
    description:''
})

  const handleUnsubscribeConfirmation = async () => {
    const result = await SubscriberService.Unsubscribe(email)
    if(result.status == 200){
        setMessage({status:true, title: '', description: t('You are unsubscribed to GreSLERN newsletter ')})
    }else setMessage({status:false, title:'', description: t('Your are not subscribed to GreSLERN newsletter.')})
  }

  useEffect(() => {
    handleUnsubscribeConfirmation()
  }, [])
  

  return (
    <DefaultLandingPageLayout >
        { Message.status ?
            <div>
                <div className="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
                    <h1 id='privacy' className="mb-2 text-2xl font-bold md:text-6xl text-greslernorange">{t('Unsubscribed !')} <br /> </h1>
                    <FontAwesomeIcon icon={faSadTear} className=" stroke-greslernorange text-white" strokeWidth={20}  size="10x"  />
                    <h1 id='privacy' className="mb-2 text-2xl font-bold md:text-6xl text-green">{Message.title} <br /> </h1>
                    <p className="text-greslernorange ">{Message.description} </p>
                    <p className="text-gray-400 ">{t('you will no longer receive newsletters from GreSLERN')} </p>
                    {/* <Link to={'http://google.com'} download={true} className=' text-greslernorange text-sm hover:text-greslernorange/50 hover:underline' >download <FontAwesomeIcon icon={faFilePdf} /></Link> */}
                </div>
            </div>
            :
            <div className="container">
                <ErrorPage />
            </div>
        }
    </DefaultLandingPageLayout>
  );
};

export default UnsubscribeConfirmation;
