import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from "react-notifications";
import CreateGroupInstitute from '../../../../services/CreateGroupInstitute';
import { Spin } from 'react-cssfx-loading';
import { useTranslation } from "react-i18next";
import ModalComponent from '../../../ModalComponent';
import { useSelector } from 'react-redux';
import {selectInstitute } from '../../../../redux/Reducer/authSlice';
type instituteProps = {
    name: string;
    country: string;
    createdAt: any;
    email: string;
    generatedTokenExpiration: Date;
    status: string;
    _id: string;
    id: any;
    groupname:String;

};
export default function CreateGroup({onClose}) {
    const { t } = useTranslation();
    const [showMainGroup, setShowMainGroup] = useState(true);
    const [showSubGroup, setShowSubGroup] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [groupId, setGroupId] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<instituteProps[]>([]);
    const [updateCard, setUpdateCard] = useState(true);
    const [show, setShow] = useState(true);
    const instituteId = useSelector(selectInstitute)
    const shifttoCollage = () => {
        setShowMainGroup(true);
        setShowSubGroup(false);
    }
    const shifttoDepartment = () => {
        setShowMainGroup(false);
        setShowSubGroup(true);
    }
    const handleMainGroupCreate = async (e) => {
        e.preventDefault();
        try {
            if (!groupName) {
                NotificationManager.warning("All filed Required", "Group");
                return;
            }
            setLoading(true);
            const userdata = { groupName, groupDescription,instituteemail: instituteId}
            let data = await CreateGroupInstitute.createMainGroup(userdata);
            if (data) {
                setLoading(false);
                setGroupName(' ');
                setUpdateCard(!updateCard);
                NotificationManager.success("Group created", "Group");
            } else{
                setLoading(false);
                NotificationManager.warning("Not created", "Group");
                setGroupName(' ');
            }
        } catch (error) {
            setLoading(false);
            NotificationManager.error("You cant create group, Try Again", "Group");
        }
    };
    
    const fetchSubGroupList = async () => {
        let resource = await CreateGroupInstitute.getMainGroupList(instituteId);
        if (resource) {
          const options = resource.map((user) => ({
            id: user._id,
            name: user.groupname,
            description: user.description,
          }));
          setResult(options);
        }
      };
    const handleGroupCreate = async (e) => {
        e.preventDefault();
        try {
            if (!groupName) {
                NotificationManager.warning("All filed Required", "Group");
                return;
            }
            setLoading(true);
            const userdata = { groupName, groupId }
            let data = await CreateGroupInstitute.createGroup(userdata);
            if (data === null) {
                setLoading(false);
                setShowSubGroup(false);
                setShowMainGroup(false);
            } else if (!data) {
                setLoading(false);
                NotificationManager.success("Group created", "Created");
                setShowSubGroup(false);
                setShowMainGroup(false);
            }

            else {
                setLoading(false);
                NotificationManager.success("Group created", "Created");
            }

        } catch (error) {
            setLoading(false);
            NotificationManager.error("You cant create group, Try Again", "Group");
        }
    };
    const handleClose = () => {
        setShow(false);
        onClose(false)
      };
      useEffect(()=>{
        fetchSubGroupList();
      },[updateCard])
    return (
        <ModalComponent isOpen={show} title={false} onClose={handleClose}>
            <div className="bg-white p-2 rounded-lg shadow-lg w-full">
                {/* <div><FontAwesomeIcon icon={faClose} color='red' onClick={closeModal} /></div> */}
                <div className="flex justify-center mb-6">
                    <span className="inline-block  rounded-full p-3">
                        <FontAwesomeIcon icon={faUsers} className='text-greslernorange' size='4x' />
                    </span> 
                </div>
                <h4 className="text-2xl font-semibold text-center mb-4 text-greslernorange ">{t("Create Group")}</h4>
                <div className="flex flex-row justify-evenly mb-3 text-sm font-light">
                    <div className="items-center">
                        <button onClick={shifttoCollage}>
                            <span className={` ${showMainGroup ? "underline underline-offset-1 !text-greslernorange !font-bold" : ""}`}>
                                {t("Main groups")}</span></button></div>
                    <div className="items-center">
                        <button onClick={shifttoDepartment}>
                            <span className={`${showSubGroup ? "underline underline-offset-1 !text-greslernorange !font-bold" : ""}`}>
                                {t("Sub groups")}</span></button></div>
                </div>
                {showMainGroup &&
                    <form
                        onSubmit={handleMainGroupCreate}
                    >
                        <div className="mb-1">
                            <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Main group name")} <span className="text-red-600">*</span> </label>
                            <input type="text" id="groupName"
                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                required
                                placeholder="Enter main group Name"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="Description" className="block text-gray-700 text-sm font-semibold mb-2">{t("Description")}</label>
                            <textarea
                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                name="groupDescription" id="groupDescription"
                                value={groupDescription}
                                onChange={(e) => setGroupDescription(e.target.value)}
                                cols={3} rows={2} placeholder="Enter description">
                                
                            </textarea>
                        </div>
                        <button
                            className={
                                `w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                      ${loading
                                    ? "bg-[#2d2d39] text-white pointer-events-none"
                                    : "w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                }
                      `}
                            type="submit"
                            disabled={loading}>
                            {loading ? (
                                <Spin color="#ffffff" width="30px" height="30px" duration="1s" />
                            ) : (
                                t("Create")
                            )}
                        </button>
                    </form>
                }

                {showSubGroup &&
                    <form
                        onSubmit={handleGroupCreate}
                    >
                        <div className="mb-2">
                            <label
                                htmlFor="country"
                                className="block text-gray-700 text-sm font-semibold mb-2"
                            >{t("Main Group")}
                                <span className="text-red-600">*</span>
                            </label>
                            <select
                                id="groupId"
                                name="groupId"
                                value={groupId}
                                onChange={(e) => {
                                    const selectedOptionValue = e.target.value;
                                    setGroupId(selectedOptionValue)
                                }}
                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                            >
                                <option hidden>
                                    {t("Select Group")}
                                </option>
                                {result.map((group) => (
                                    <option
                                        className="text-[#292929]"
                                        key={group.id}
                                        value={group.id}>
                                        {group.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Group name")} <span className="text-red-600">*</span> </label>
                            <input type="text" id="groupName"
                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                required
                                placeholder="Enter Sub group Name"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                            />
                        </div>
                        <button
                            className={
                                `w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                      ${loading
                                    ? "bg-[#2d2d39] text-white pointer-events-none"
                                    : "w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                }
                      `}
                            type="submit"
                            disabled={loading}>
                            {loading ? (
                                <Spin color="#ffffff" width="30px" height="30px" duration="1s" />
                            ) : (
                                t("Create")
                            )}
                        </button>
                    </form>
                }
            </div>
        </ModalComponent>
    );
}
