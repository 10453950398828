import ResetForm from '../../../components/form/reset';
import BackgroudL from '../../../components/background/backgroundL';

function PasswordReset() {

    return (
        <div id="wrapper">
            <div className="flex min-h-screen">
                <div className="hidden md:block md:w-1/2 bg-art bg-cover bg-center relative overflow-hidden justify-center">
                    <BackgroudL />
                </div>
                <div className="w-full md:w-1/2 bg-right animate-gradient ">
                    <div className='w-full h-full bg-line-pattern bg-cover flex items-center justify-center'>
                        <ResetForm />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
