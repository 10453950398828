import { Routes, Route } from "react-router-dom";
import Dashbord from "./dashboard";
import Admin from "./admin";
import Footer from "./footer";
import Home from "./home";
import NavbarAnimation from "./header";
import "./header/style.css";
import "./styles.css";

import CommentList from "../CommentList";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import ContentPlay from "../ContentFile/ContentPlay";
import ContentEdit from "../ContentFile/ContentEdit";
import DashbordGuid from "../DashbordGuid";
import MyResources from "./dashboard/MyResources";
import MyPublicResources from "./dashboard/MyPublicResources";
import BieResources from "./dashboard/BieResources";
import MyFolders from "./dashboard/MyFolders";
import AnalysisDashboard from "./dashboard/AnalysisDashboard"
import ContentNew from "../ContentFile/ContentNew";
import Setting from "./setting";
import Profile from "./dashboard/Profile";
import ProfileEdit from "./dashboard/ProfileEdit";
import SharedToMeResources from "./dashboard/SharedToMeResources";
import BookmarkedResources from "./dashboard/BookMarks";
import Guidelines from "./dashboard/addon/guidelines";
import BIEAssessment from "../form/BIEAssesment/BIEAssessment";
import { selectGuidelineAccepted } from "../../redux/Reducer/authSlice";
import { useSelector } from "react-redux";
import ContentClone from "../ContentFile/ContentClone";
import TrashResources from "./dashboard/MyTrash";

function RootRoutes() {
  const guidelineAccepted = useSelector(selectGuidelineAccepted)
  return <main>
    <NavbarAnimation>
      <div id="layoutSidenav" key={1}>
        <Routes>
          <Route path={`/`} element={<Home />} />
          <Route path={"/dashboard"} element={<Dashbord load={"myResources"} />}>
            <Route path="" element={<DashbordGuid />} />
            <Route path="myresources" element={<MyResources layout="tiles" />} />
            <Route path="myprofile" element={<Profile />} />
            <Route path="editprofile" element={<ProfileEdit />} />
            <Route path="sharedtome" element={<SharedToMeResources />} />
            <Route path="publicresources" element={<MyPublicResources />} />
            <Route path="bieresources" element={<BieResources />} />
            <Route path="bookmarkresources" element={<BookmarkedResources />} />
            <Route path="myPublic" element={<Dashbord load={"myPublicResources"} />} />
            <Route path="myfolders" element={<MyFolders />} />
            <Route path="AnalysisDashboard" element={<AnalysisDashboard />} />
            <Route path="play/:contentId" element={<ContentPlay />} />
            <Route path="edit/:contentId" element={<ContentEdit />} />
            <Route path="new/:folderId?" element={<Guidelines />} />
            <Route path="BIEassesment" element={<BIEAssessment />} />
            <Route path="new/:folderId?/create" element={guidelineAccepted ? <ContentNew /> : <Guidelines />} />
            <Route path="clone/:contentId" element={<ContentClone />} />
            <Route path="mycomments" element={<CommentList />} />
            <Route path={"mysetting"} element={<Setting />} />
            <Route path={"trash"} element={<TrashResources />} />
          </Route>
          <Route path={"/admin"} element={<Admin />} />
          {/* <Route path={"/institute"} element={<InstituteRoutes />} /> */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </NavbarAnimation>
    <Footer />
  </main>;
};

export default RootRoutes;
