import { Parallax } from 'react-parallax';
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';

import { fadeIn, textVariant } from '../../../utils/motion';
import SectionWrapper from '../../../hoc';
import ContentListComponentLanding from '../../../components/ContentFileLandingPage/ContentList';
import explorer_pattert from '../../../components/assets/img/resources/explorer_pattern.jpg'

function Explorer({id, isAuthenticated=false}) {
    const { t } = useTranslation()
    return (
        <div id='resourceSection' className="bg-white h-auto w-full"
            style={{
                backgroundImage: `url(${explorer_pattert})`,
                backgroundRepeat: ' no-repeat',
                backgroundPosition: 'bottom',
                backgroundSize: "cover"
            }}
        >
            <div className=" h-full max-w-screen-xl mx-auto py-4">
                <div className='w-full flex justify-center'>
                    <div className='flex lg:flex-row flex-col w-[38rem] items-center'>
                        {/* <div
                            className='absolute top-[543%] left-[52%] z-0 rotate-[70deg] 2xl:rotate-[50deg]  hidden lg:block'
                        >
                            <Parallax
                                strength={200}
                                style={{ overflow: 'inherit' }}
                                renderLayer={percentage => (
                                    <div className="relative">
                                        <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `8px`,
                                                height: `${percentage <= 1 ? percentage * 45 : 45}vh`,
                                            }}
                                        ></div>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </div> */}
                        <motion.div
                            variants={textVariant()}
                            className='flex items-center'
                        >
                            <Parallax
                                strength={200}
                                renderLayer={percentage => (
                                    <div id={id} className="relative h-[8rem] w-[8rem]">
                                        <div className={` border-[#009FE3]  bg-[#009FE3] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `${percentage <= 0.5 ? percentage * 16 : 8}rem`,
                                                height: `${percentage <= 0.5 ? percentage * 16 : 8}rem`,
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        ></div>
                                        <span className='relative top-5 left-5 text-6xl leading-normal border-0 text-white p-0 ml-auto'>{t("exp")}</span>
                                    </div>
                                )}
                            >
                            </Parallax>
                            <div className='z-[2]'>
                                <span className='p-0 text-6xl text-black border-0'>{t("lore")}</span>
                            </div>
                        </motion.div>
                        <motion.p
                            variants={fadeIn("", "", 0.1, 1)}
                            className=' px-4 z-[2]'
                        >
                            {t("with")} <span className='p-0 border-0 text-black font-bold'> {t("exploreS1")}</span> {t("exploreS2")}
                        </motion.p>
                    </div>
                </div>
                <div className='flex mt-10 '>
                    <ContentListComponentLanding admin={true} isAuthenticated={isAuthenticated} />
                </div>
            </div>
        </div>
    )
}

// export default SectionWrapper(Explorer, "resources")
export default Explorer