import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spin } from 'react-cssfx-loading'
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import BIEAssessmentScoring from "../../Radio/BIEAssessmentScoring";
import { selectEmail } from "../../../redux/Reducer/authSlice";
import { GetReview } from "../../../services";

const initialResult = {
    intraculturalism: { q1: 0, q2: 0, q3: 0, q4: 0, q5: 0, q6: 0, total: 0 },
    transdisciplinarity: { q1: 0, q2: 0, q3: 0, q4: 0, total: 0 },
    dialecticism: { q1: 0, q2: 0, q3: 0, q4: 0, q5: 0, q6: 0, total: 0 },
    contextuality: {
        q1: 0, q2: 0, q3: 0, q4: 0, q5: 0, q6: 0, total: 0
    },
    score: 0
}

const BIEAssessmentPublic = ({ onClose, getReviews }) => {

    const { t } = useTranslation()
    const { contentId } = useParams()
    const useremail = useSelector(selectEmail)

    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [intraculturalismScore, setIntraculturalismScore] = useState(0);
    const [transdisciplinaryScore, setTransdisciplinaryScore] = useState(0);
    const [dialecticismScore, setDialecticismScore] = useState(0);
    const [contextuallyScore, setContextuallyScore] = useState(0);
    const [questionsResult, setQuestionsResult] = useState(initialResult)
    const [meanValue, setMeanValue] = useState({
        intraculturalism: 0,
        transdisciplinarity: 0,
        dialecticism: 0,
        contextuality: 0
    })

    const handleQuestionResult = (q, a) => {
        let type = 'intraculturalism'
        switch (step) {
            case 2:
                type = 'transdisciplinarity';
                break;
            case 3:
                type = 'dialecticism';
                break;
            case 4:
                type = 'contextuality';
                break;
            default:
            // Handle the default case, for example, do nothing
        }
        setQuestionsResult({
            ...questionsResult,
            [type]: {
                ...questionsResult[type],
                [q]: parseInt(a)
            }
        })
    }

    const calculateTotalScores = () => {
        const categoryNames = Object.keys(questionsResult).filter((key) => key !== 'score');
        return categoryNames.reduce((acc, category) => {
            const categoryTotal = meanValue[category];
            setQuestionsResult((prevScores) => ({
                ...prevScores,
                [category]: { ...prevScores[category], total: categoryTotal },
            }));
            return acc + categoryTotal;
        }, 0);
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handlePass = () => {
        setLoading(true)
        const BIEs = ['intraculturalism', "transdisciplinarity", 'dialecticism', 'contextuality']
        for (let i = 0; i < BIEs.length; i++) {
            const type = BIEs[i]
            const divider = type === "transdisciplinarity" ? 4 : 6
            meanValue[type] = Object.values(questionsResult[type]).reduce((acc: number, val: any) => acc + val, 0) / divider;
        }
        questionsResult.score = calculateTotalScores() / BIEs.length;

        const data = {
            BIEs: questionsResult,
            BIEsEach: meanValue,
            contentId: contentId,
            userEmail: useremail
        }

        GetReview.addReview(data).then((response) => {
            if (response) {
                setQuestionsResult(initialResult)
                setMeanValue({
                    intraculturalism: 0,
                    transdisciplinarity: 0,
                    dialecticism: 0,
                    contextuality: 0
                });
                getReviews();
                setStep(1);
                setLoading(false)
                onClose();
            }
            else {
                setQuestionsResult(initialResult)
                setMeanValue({
                    intraculturalism: 0,
                    transdisciplinarity: 0,
                    dialecticism: 0,
                    contextuality: 0
                });
                setStep(1);
                setLoading(false)
                onClose();
                NotificationManager.error("Error occure on Review, try again", "Review")

            }
        }).catch((error) => {
            NotificationManager.error("Error occure on Review, try again", "Review")
        })

    }

    return (
        <div className="flex ">
            <div className="bg-white p-6 rounded-lg shadow-md w-full">
                <div className="">
                    <h2 className="text-lg text-greslernorange font-medium mb-2">
                        {t("MATERIALS AND CURRICULUM ASSESSMENT")}
                    </h2>
                    <div className=" text-sm text-black-800 p-2 ">
                        <p>
                            {t("BIE Scorring representation")}
                        </p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row rounded-lg">
                    <div
                        className={`text-greslernorange border-r border-greslernorange rounded-t-lg ${step ===
                            1
                            ? "!bg-greslernorange text-white"
                            : "bg-gray-200"} p-2 text-center cursor-pointer`}
                        onClick={() => setStep(1)}
                    >
                        {t("INTRACULTURALISM")}
                    </div>
                    <div
                        className={`text-greslernorange border-r border-greslernorange md:rounded-t-lg ${step ===
                            2
                            ? "!bg-greslernorange text-white"
                            : "bg-gray-200"} p-2 text-center cursor-pointer`}
                        onClick={() => setStep(2)}
                    >
                        {t("TRANSDISCIPLINARITY")}
                    </div>
                    <div
                        className={`text-greslernorange border-r border-greslernorange md:rounded-t-lg ${step ===
                            3
                            ? "!bg-greslernorange text-white"
                            : "bg-gray-200"} p-2 text-center cursor-pointer`}
                        onClick={() => setStep(3)}
                    >
                        {t("DIALECTICISM")}
                    </div>
                    <div
                        className={`text-greslernorange border-r border-greslernorange md:rounded-t-lg ${step ===
                            4
                            ? "!bg-greslernorange text-white"
                            : "bg-gray-200"} p-2 text-center cursor-pointer`}
                        onClick={() => setStep(4)}
                    >
                        {t("CONTEXTUALITY")}
                    </div>
                </div>
                <div className=" bg-gray-100 flex flex-wrap border-2 border-greslernorange   bg-[url('https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/images/interdesciplinary.png')]  ">
                    {step === 1
                        ? <Step1
                            getScore={intraculturalismScore}
                            setScore={setIntraculturalismScore}
                            content={contentId}
                            handleQuestionResult={handleQuestionResult}
                            questionsResult={questionsResult.intraculturalism}
                            t={t}
                        />
                        : step === 2
                            ? <Step2
                                getScore={transdisciplinaryScore}
                                setScore={setTransdisciplinaryScore}
                                content={contentId}
                                handleQuestionResult={handleQuestionResult}
                                questionsResult={questionsResult.transdisciplinarity}
                                t={t}
                            />
                            : step === 3
                                ? <Step3
                                    getScore={dialecticismScore}
                                    setScore={setDialecticismScore}
                                    content={contentId}
                                    handleQuestionResult={handleQuestionResult}
                                    questionsResult={questionsResult.dialecticism}
                                    t={t}
                                />
                                : step === 4
                                    ? <Step4
                                        getScore={contextuallyScore}
                                        setScore={setContextuallyScore}
                                        content={contentId}
                                        handleQuestionResult={handleQuestionResult}
                                        questionsResult={questionsResult.contextuality}
                                        t={t}
                                    />
                                    : ""}
                </div>
                <div className="flex justify-between mt-6">
                    {step > 1 &&
                        <button
                            className="bg-gray-300 px-6 py-1.5 rounded-lg text-gray-700 hover:bg-gray-400"
                            onClick={handleBack}
                        >
                            Back
                        </button>}

                    {step < 4 &&
                        <button
                            className="!bg-greslernorange px-6 py-1.5 rounded-lg text-white hover:bg-blue-600"
                            onClick={handleNext}
                        >
                            Next
                        </button>}
                    {step === 4 &&
                        <button
                            disabled={loading}
                            className={` border-2 border-solid border-green-300 px-6 py-1.5 rounded-lg 
                            ${loading ? 'bg-gray-600 text-white border-gray-600' : 'bg-green-300 text-gray-700 hover:bg-white'}
                            `}
                            onClick={handlePass}
                        >
                            {loading ? (<Spin color="#ffffff" width="30px" height="30px" duration="1s" />) : t("Submit")}

                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

const Step1 = ({ getScore = 0, setScore, content, handleQuestionResult, questionsResult, t }) => {
    const [intraculturalismScore, setIntraculturalismScore] = useState(getScore);
    const calculateScore = (diff = 0) => {
        setIntraculturalismScore(intraculturalismScore + diff);
        setScore(intraculturalismScore + diff);
    };
    const intraculturalism = [
        {
            type: "Q1",
            question: t("intraQ1"),
            result: questionsResult.q1
        },
        {
            type: "Q2",
            question: t("intraQ2"),
            result: questionsResult.q2
        },
        {
            type: "Q3",
            question: t("intraQ3"),
            result: questionsResult.q3
        },
        {
            type: "Q4",
            question: t("intraQ4"),
            result: questionsResult.q4
        },
        {
            type: "Q5",
            question: t("intraQ5"),
            result: questionsResult.q5
        },
        {
            type: "Q6",
            question: t("intraQ6"),
            result: questionsResult.q6
        }
    ];
    return <div className="md:w-2/3 bg-gray-100 p-3">
        {intraculturalism.map((question, key) =>
            <div key={`culture${key}${content}`} className="mb-4">
                <label
                    className="block font-medium mb-2 text-greslern"
                    htmlFor={question.type}
                >
                    {question.question}
                </label>
                <div id={question.type}>
                    <BIEAssessmentScoring
                        forwhat={`cultural${question.type}${content}`}
                        onScoreChange={calculateScore}
                        contentId={content}
                        section="intraculturalism"
                        q={question.type.toLowerCase()}
                        handleQuestionResult={handleQuestionResult}
                        result={question.result}
                    />
                </div>
            </div>
        )}
    </div>
};
const Step2 = ({ getScore = 0, setScore, content, handleQuestionResult, questionsResult, t }) => {
    const [transdisciplinaryScore, setTransdisciplinaryScore] = useState(
        getScore
    );
    const calculateScore = (diff = 0) => {
        setTransdisciplinaryScore(transdisciplinaryScore + diff);
        setScore(transdisciplinaryScore + diff);
    };
    const transdisciplinary = [
        {
            type: "Q1",
            question:
                t("discipQ1"),
            result: questionsResult.q1
        },
        {
            type: "Q2",
            question:
                t("discipQ2"),
            result: questionsResult.q2
        },
        {
            type: "Q3",
            question:
                t("discipQ3"),
            result: questionsResult.q3
        },
        {
            type: "Q4",
            question:
                t("discipQ4"),
            result: questionsResult.q4
        }
    ];
    return <div className="md:w-2/3 bg-gray-100 p-2">
        {transdisciplinary.map((question, key) =>
            <div key={`discipline${key}${content}`} className="mb-4">
                <label
                    className="block font-medium mb-2 text-greslern"
                    htmlFor={question.type}
                >
                    {question.question}
                </label>
                <div id={question.type}>
                    <BIEAssessmentScoring
                        forwhat={`discipline${question.type}${content}`}
                        onScoreChange={calculateScore}
                        contentId={content}
                        section="transdisciplinarity"
                        q={question.type.toLowerCase()}
                        handleQuestionResult={handleQuestionResult}
                        result={question.result}
                    />
                </div>
            </div>
        )}
    </div>
};
const Step3 = ({ getScore = 0, setScore, content, handleQuestionResult, questionsResult, t }) => {
    const [dialecticismScore, setDialecticismScore] = useState(0);
    const calculateScore = (diff = 0) => {
        setDialecticismScore(dialecticismScore + diff);
        setScore(dialecticismScore + diff);
    };
    const dialecticism = [
        {
            type: "Q1",
            question:
                t("dialectQ1"),
            result: questionsResult.q1
        },
        {
            type: "Q2",
            question:
                t("dialectQ2"),
            result: questionsResult.q2
        },
        {
            type: "Q3",
            question:
                t("dialectQ3"),
            result: questionsResult.q3
        },
        {
            type: "Q4",
            question:
                t("dialectQ4"),
            result: questionsResult.q4
        },
        {
            type: "Q5",
            question:
                t("dialectQ5"),
            result: questionsResult.q5
        },
        {
            type: "Q6",
            question:
                t("dialectQ6"),
            result: questionsResult.q6
        }
    ];
    return <div className="md:w-2/3 bg-gray-100 p-2">
        {dialecticism.map((question, key) =>
            <div key={`dialect${key}${content}`} className="mb-4">
                <label
                    className="block font-medium mb-2 text-greslern"
                    htmlFor={question.type}
                >
                    {question.question}
                </label>
                <div id={question.type}>
                    <BIEAssessmentScoring
                        forwhat={`dialect${question.type}${content}`}
                        onScoreChange={calculateScore}
                        contentId={content}
                        section="dialecticism"
                        q={question.type.toLowerCase()}
                        handleQuestionResult={handleQuestionResult}
                        result={question.result}
                    />
                </div>
            </div>
        )}
    </div>
};
const Step4 = ({ getScore = 0, setScore, content, handleQuestionResult, questionsResult, t }) => {
    const [contextuallyScore, setContextuallyScore] = useState(getScore);
    const calculateScore = (diff = 0) => {
        setContextuallyScore(contextuallyScore + diff);
        setScore(contextuallyScore + diff);
    };
    const contextual = [
        {
            type: "Q1",
            question:
                t("contextQ1"),
            result: questionsResult.q1
        },
        {
            type: "Q2",
            question:
                t("contextQ2"),
            result: questionsResult.q2
        },
        {
            type: "Q3",
            question:
                t("contextQ3"),
            result: questionsResult.q3
        },
        {
            type: "Q4",
            question:
                t("contextQ4"),
            result: questionsResult.q4
        },
        {
            type: "Q5",
            question:
                t("contextQ5"),
            result: questionsResult.q5
        },
        {
            type: "Q6",
            question:
                t("contextQ6"),
            result: questionsResult.q6
        }
    ];
    return <div className="md:w-2/3  bg-gray-100 p-2">
        {contextual.map((question, key) =>
            <div key={`context${key}${content}`} className="mb-4">
                <label
                    className="block font-medium mb-2 text-greslern"
                    htmlFor={question.type}
                >
                    {question.question}
                </label>
                <div id={question.type}>
                    <BIEAssessmentScoring
                        forwhat={`context${question.type}${content}`}
                        onScoreChange={calculateScore}
                        contentId={content}
                        section="contextuality"
                        q={question.type.toLowerCase()}
                        handleQuestionResult={handleQuestionResult}
                        result={question.result}
                    />
                </div>
            </div>
        )}
    </div>
};

export default BIEAssessmentPublic;
