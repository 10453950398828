import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import NoData from '../NoData';
type ResourceList = {
    username: string,
    title: string,
    views: number,
    status: string,
    creationDate: string,
    comments: any,
    likes: any,
    sharedTo: any
}
export default function ResourceUsageReport({ resourceList }) {
    const [filteredPostedResources, setFilteredPostedResources] = useState<ResourceList[]>([])
    const { t } = useTranslation();
    const sortByViews = (resourceList) => {
        const sortedData = [...resourceList].sort((a, b) => b.views - a.views);
        setFilteredPostedResources(sortedData.slice(0, 5));
    };
    useEffect(() => {
        sortByViews(resourceList);
    }, [resourceList])
    return (
        <>
            <div className="flex items-center h-auto bg-gray-100 w-full">
                <div id="pdf_report" className="w-full bg-white">
                    <div className='flex justify-center place-self-center w-full mt-2'>
                        <div className="block w-auto overflow-x-auto  border">
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                            #
                                        </th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                            {t("user name")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                            {t("resource name")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px">
                                            {t("resource status")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("shared to")}
                                        </th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("Views")} </th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("likes")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("comments")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("resource date")}</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-100">
                                    {filteredPostedResources.length <= 0 ? <NoData /> :
                                        <>
                                            {
                                                filteredPostedResources?.map((res, key) =>
                                                    <tr className="text-gray-500">
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                            {key + 1}
                                                        </td>
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 p-4">
                                                            {res.username}
                                                        </td>
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 p-4">
                                                            {res.title}
                                                        </td>
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                            {res.status}
                                                        </td>
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                            {res.sharedTo?.length}
                                                        </td>
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                            {res.views}
                                                        </td>
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                            {res.likes?.length}
                                                        </td>
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                            {res.comments?.length}
                                                        </td>
                                                        <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 p-4">
                                                            {new Date(res.creationDate).toDateString()}{" "}
                                                            {new Date(res.creationDate).toLocaleTimeString()}
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
