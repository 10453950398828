
import React from "react";
import { useNavigate } from "react-router-dom";

function LogoAnimation() {
    const navigate = useNavigate();

    return (
        <div className='absolute top-[-40px] left-1/2 w-[150px] h-[70px] transform -translate-x-1/2 bg-black transition-all duration-500 z-10 overflow-hidden flex justify-center items-center group-hover:top-[25px] group-hover:w-[250px] group-hover:h-[120px] before:content before:absolute before:top-1/2 before:left-1/2 before:w-[600px] before:h-[180px] before:transform before:-translate-x-1/2 before:-translate-y-1/2 before:bg-imgBx before:animate-animate2 before:group after:content after:absolute after:inset-[3px] after:bg-greslernorange'>
            <img className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[170px] z-[200] brightness-[1] filter opacity-[0.5] transition-all duration-500 group-hover:opacity-[1] group-hover:w-[210px]' src='/GreSLERN-logo.svg' onClick={() => navigate('/')} />
        </div>
    )
}

export default LogoAnimation;