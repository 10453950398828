import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faDownload, faEdit, faFingerprint, faList, faList12, faListNumeric } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from 'moment'
import CommentSectionUi from "../CommentSectionUi";
import { GetCommentList, GetResource, GetMetaResource, GetResourceById } from '../../services';
import { withTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import ContentHeader from "../root/dashboard/addon/contentHeader";

type commentProps = {
    message: string;
    isPublic: string;
    name: String;
    _id: String;
    resourceId: String;
    createdAt: undefined;
    contentId: String;
    title: String;
    comments: any;
    replylist: [];
};
type resourceProps = {
    isPublic: string;
    name: String;
    contentId: String;
    title: String;
    comments: [];
    replylist: [];
};
const CommentList = () => {
    const [databyresource, setDatabyresource] = useState<resourceProps[]>([]);
    const [selectedresource, setSelectedresource] = useState<resourceProps[]>([]);
    const [commentlist, setCommentlist] = useState<commentProps[]>([]);
    const [showComment, setShowComment] = useState(false);
    const [commentId, setCommetnId] = useState();
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = commentlist.slice(firstIndex, lastIndex);
    const npage = Math.ceil(commentlist.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const fetchByResource = async () => {
        try {
            const data = await GetMetaResource.getmetaresource();
            if (data) {
                setDatabyresource(data);
            }
            else {
                console.log('error when fetch');
            }
        } catch (err) {
            console.log(err);
        }
    }
    const { t } = useTranslation();
    const fetchByResourceById = async (resourceId) => {
        let data = await GetResourceById.getresourceById(resourceId);
        setSelectedresource(data);
    }
    const commentListfun = async (resourceId) => {
        fetchByResourceById(resourceId);
        setCommetnId(resourceId);
        let data = await GetCommentList.getcommentlist(resourceId)
        if (data) {
            setCommentlist(data);
            setShowComment(true);
        }
        else {
            setShowComment(false);
        }
    }
    useEffect(() => {

        fetchByResource();
    }, []);
    const showCommentsmodal = () => {
        setShowCommentModal(!showCommentModal);
    }
    const exportPdf = async (commentId) => {
        const doc = new jsPDF({ orientation: "landscape" });

        doc.autoTable({
            html: "#my-table",
        });

        doc.save(`feedbacks${commentId}.pdf`);
    };
    return (
        <div className="flex flex-col w-full">
        
            <div className="flex flex-wrap justify-between items-center mb-2">
                <ContentHeader title={t("My Feedbacks")} showIcon={false}/>
                <div className="inline-flex items-center space-x-2"></div>
            </div>
            {(databyresource.length === 0 ? (
                <div aria-label="group of cards" className="focus:outline-none py-1 w-full">
                    <div className="flex flex-col w-full">
                        <div className="focus:outline-none mb-7 bg-white p-6 shadow rounded w-full">
                            <div className="flex items-center border-b border-gray-200 pb-6">
                                <div className="flex items-start justify-between w-full">
                                    <div className="pl-3 w-full">
                                        <p className="focus:outline-none text-xl font-medium leading-5 text-gray-800">{t("No feedback")}...</p>
                                    </div>
                                    <div role="img" aria-label="bookmark">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col md:flex-row gap-2 ">
                    <div aria-label="group of cards"
                        className="focus:outline-none w-full">
                        <div className="flex flex-col w-full">
                            {databyresource.map((data, index) => (
                                <>
                                    {data.comments.length === 0 ? (
                                        null
                                    ) :
                                        <>
                                            <div
                                                className=
                                                "focus:outline-none mb-3 bg-white p-6 shadow rounded w-full"
                                                key={index}>
                                                <div className="flex items-center">
                                                    <div className="flex items-start justify-between w-full">
                                                        <div className="w-full">
                                                            <label className=" text-gray/30">
                                                                {t('resource name')}
                                                            </label>
                                                            <p
                                                                className=" text-greslernorange focus:outline-none text-xl font-medium leading-5 text-gray-800"
                                                            >{data.title}</p>
                                                        </div>
                                                        <div role="img" aria-label="bookmark">
                                                            {data.comments.length === 0 ? (
                                                                <>
                                                                    <button
                                                                        disabled
                                                                        className="focus:outline-none text-gray-800 border-spacing-2 flex flex-row">
                                                                        <FontAwesomeIcon
                                                                            icon={faComment}
                                                                            color="lightblue"
                                                                            className="-ml-0.5 mr-1.5 mt-1 px-2  h-3 w-3 bg-white "
                                                                        />
                                                                        <h5 className="text-black">{t("Feedbacks")}(0)</h5>
                                                                    </button>
                                                                </>
                                                            ) :
                                                                <>
                                                                    {showComment ?
                                                                        <button
                                                                            onClick={() => commentListfun(data.contentId)}
                                                                            className="focus:outline-none text-gray-800 border-spacing-2 flex flex-row">
                                                                            <FontAwesomeIcon
                                                                                icon={faComment}
                                                                                color="gray"
                                                                                className="-ml-0.5 mr-1.5 mt-1 px-2 pb-5 h-3 w-3 bg-white "
                                                                            />
                                                                            <h5 className="text-black">
                                                                                <Button
                                                                                    className='text-[#fc2803] border-[#fc2803] hover:!text-[#fff] hover:border-[#fc2803] text-sm'
                                                                                >

                                                                                    {t("Feedbacks")}({data.comments.length})
                                                                                </Button>
                                                                            </h5>
                                                                        </button>
                                                                        :
                                                                        <button
                                                                            onClick={() => commentListfun(data.contentId)}
                                                                            className="focus:outline-none text-gray-800 border-spacing-2 flex flex-row">
                                                                            <FontAwesomeIcon
                                                                                icon={faComment}
                                                                                color="gray"
                                                                                className="-ml-0.5 mr-1.5 mt-1 px-2 pb-5 h-5 w-5 bg-white "
                                                                            />
                                                                            <h5 className="text-black">
                                                                                <Button
                                                                                    className='text-[#fc2803] border-[#fc2803] hover:!text-[#fff] hover:border-[#fc2803]'
                                                                                >

                                                                                    {t("Feedbacks")}({data.comments.length})
                                                                                </Button>
                                                                            </h5>
                                                                        </button>
                                                                    }
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                    {showComment ? (
                        
                            selectedresource?.map((data, index) => 
                                    <div className=" -my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8 max-w-prose min-w-prose" key={index}>
                                        <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12">
                                            <div className="justify-between flex flex-row">
                                                <button
                                                    onClick={() => showCommentsmodal()}
                                                    className='text-[#fc2803] border-[#fc2803] hover:!text-[#fff] hover:bg-[#a56054] hover:border-[#b39b97]'
                                                >
                                                    {t("Write feedback on...")}
                                                </button>

                                                <h2 className="text-sm font-50 mb-4">
                                                    &#160;{data.title}
                                                    <hr></hr>
                                                </h2>
                                                <button
                                                    onClick={() => exportPdf(commentId)}
                                                    type="button"
                                                    className="inline-block rounded border-1 border-primary-100 px-3 pb-[2px] text-xs text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200"
                                                >
                                                    <FontAwesomeIcon icon={faDownload} className="w-5 h-5 mr-1"
                                                    />
                                                    {t("Download")}
                                                </button>
                                            </div>
                                        </div>
                                        {showCommentModal ? ( 
                                            <CommentSectionUi resourceId={commentId} showcommentlist={false} reqFromlanding={false} /> 
                                        ) : undefined}
                                        <div className="align-middle inline-block w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
                                            <table className="" id="my-table">
                                                <thead>
                                                    <tr>
                                                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">{t("No")}</th>
                                                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">{t("Date")}</th>
                                                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">{t("Commenter")}</th>
                                                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">{t("Comment")}</th>
                                                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">{t("Reply")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {records.map((data, i) => (
                                                        <tr key={i}>
                                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                                                <div className="flex items-center">
                                                                    <div>
                                                                        <div className="text-sm leading-5 text-gray-800">#{i + 1}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                                                <div className="text-sm leading-5 text-blue-900">{moment(data.createdAt).format('LL')}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">{data.users[0]?.name}</td>
                                                            <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">{data?.message}</td>
                                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900 text-sm leading-5">
                                                                {data.replylist.length > 0 ? (
                                                                    <>
                                                                        {data.replylist?.map((reply, i) => (
                                                                            <>
                                                                                <h6>- {reply?.reply}</h6>
                                                                                <br />
                                                                            </>
                                                                        ))}
                                                                    </>

                                                                ) : <>
                                                                    <h6 className="text-red-500">{t("No reply")}</h6>
                                                                    <br />
                                                                </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            
                                            <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between mt-4 work-sans">
                                                <div>

                                                </div>
                                                <div>
                                                    <nav className="relative z-0 inline-flex shadow-sm">
                                                        <ul className="list-style-none flex ml-12">
                                                            <li className='page-item'>
                                                                <a
                                                                    className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-blue-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                                                                    onClick={prePage}
                                                                    href='#'
                                                                >{t("Prev")}
                                                                </a>
                                                            </li>
                                                            {
                                                                numbers.map((n, i) => (
                                                                    <li className={`${currentPage === n ? 'whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]' : 'page-item'}`} key={i}>
                                                                        <a className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                                                                            href='#' onClick={() => changeCPage(n)}>{n}</a>
                                                                    </li>
                                                                ))
                                                            }
                                                            <li>
                                                                <a
                                                                    className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-blue-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                                                                    href="#" onClick={nextPage}>{t("Next")}</a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                            )
                        
                    ) : (undefined
                    )}
                </div>
            ))}
        </div>
    );
    function prePage() {
        if (currentPage !== firstIndex) {
            setCurrentPage(currentPage - 1);
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== lastIndex) {
            setCurrentPage(currentPage + 1);
        }
        else
            return;
    }
}

export default withTranslation()(CommentList)