// src/SubscriberChart.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import SubscriberService from '../../services/SubscriberService';
import { t } from 'i18next';
import Loader from '../loader';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SubscribersGraphComponent = () => {

    const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Subscribers per Day',
        data: [],
        borderColor: 'rgba(181, 94, 46, 1)',
        backgroundColor: 'rgba(181, 94, 46, 0.2)', // Use the same color with lower opacity for the background
        fill: true,
        tension: 0.4 // Make the line smooth
        
      }
    ]
  });
  
  const options = {
        responsive: true, 
        scales: {
            x: {
              ticks: {
                color: 'rgba(181, 94, 46, 1)' // X-axis labels color
              },
              grid: {
                color: 'rgba(181, 94, 46, 0.2)' // X-axis grid line color
              }
            },
            y: {
              ticks: {
                color: 'rgba(181, 94, 46, 1)' // Y-axis labels color
              },
              grid: {
                color: 'rgba(181, 94, 46, 0.2)' // Y-axis grid line color
              }
            }
          }
    }

  useEffect(() => {
    const fetchSubscriberData = async () => {
      try {
        const data = await SubscriberService.GetAllSubscribersStat();

        const dates = data.map(entry => entry._id);
        const counts = data.map(entry => entry.count);

        setChartData({
          labels: dates,
          datasets: [
            {
              label: 'Subscribers per Day',
              data: counts,
              borderColor: 'rgba(181, 94, 46, 1)',
              backgroundColor: 'rgba(181, 94, 46, 0.2)', // Use the same color with lower opacity for the background
              fill: true
            }
          ]
        });
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching subscriber data', error);
      }
    };

    fetchSubscriberData();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className=' w-full'>
      <h2 className='text-greslernorange'>{t("Subscriber Growth Over Time")}</h2>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default SubscribersGraphComponent;
