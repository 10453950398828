import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import { useSpring, animated } from 'react-spring';
import Typist from 'react-typist';

import Left_Corner from '../../../components/assets/img/hero/Capa_L.svg'
import Right_Corner from '../../../components/assets/img/hero/Capa_R.svg'
import Left_B_Corner from '../../../components/assets/img/hero/Capa_L_gray.png'
import Right_B_Corner from '../../../components/assets/img/hero/Capa_R_gray.png'
import phone_ico from '../../../components/assets/img/hero/phone.png'
import comment_ico from '../../../components/assets/img/hero/comment_ico.png'
import group_ico from '../../../components/assets/img/hero/group.png'
import ToolTip from '../../../components/tooltip';
import styles from '../AnimatedBackground.module.css';
import '../style.css'

import LineComponent from '../../../components/lineComponent/idex';

function Hero({id}) {
    const { t } = useTranslation()

    const { width } = useSpring({
        from: { background: '#B55E2E', width: '0%' },
        to: { background: '#B55E2E', width: '100%' },
        config: { duration: 2000 }, // Adjust the duration as needed
    });

    return (
        <div id='heroSection' className="bg-white  md:h-auto lg:h-[calc(100vh-5rem)] w-full mt-[5rem]"
            style={{
                backgroundImage: `url(${Left_Corner}), url(${Right_Corner}), url(${Left_B_Corner}), url(${Right_B_Corner})`,
                backgroundRepeat: 'no-repeat, no-repeat, no-repeat, no-repeat',
                backgroundPosition: 'left top, right top, left bottom, right bottom',
            }}
        >
            <LineComponent startPointId='targetElementWherever' endPointId='targetElementCreateBlue' startAnchor={['bottom','right']} />

            <div
                className="flex flex-col justify-evenly h-full max-w-screen-xl mx-auto">
                <div className="flex justify-between items-center w-full bg-white/70">
                    <div className="w-[25%] hidden sm:block">
                        <div className="relative lg:left-32 md:left-8 top-10  delay-300">
                            <ToolTip tooltip={t('heroPlatform')} bgColor='bg-greslernorange'>
                                <img alt="phone-ico" src={phone_ico} />
                            </ToolTip>
                        </div>
                    </div>
                    <div id='targetElementWherever' className="mx-auto flex-col flex justify-center items-center text-5xl sm:text-7xl font-light">
                        <Typist cursor={{ show: false }}>
                            <div className='text-center'>
                                <h1 className="font-bold text-nowrap text-greslernorange lg:text-black" >{t("e-learning")}</h1>
                                <h1 className="md:ml-[30%] ml-[0%]">{t("materials")} </h1>
                                <h1 className="font-bold flex lg:flex-row flex-col gap-2">
                                    <div className="relative overflow-hidden ">
                                        <animated.div
                                            className={`w-full h-20 bg-[#B55E2E] absolute left-0 mt-2 `}
                                            style={{ width }} // Apply the animation properties to the style
                                        ></animated.div>
                                        <span className="relative z-10 border-0 text-white rounded-0  font-normal">{t("wherever")}</span>

                                    </div>
                                    {" "}
                                    {t("youare")}
                                </h1>
                            </div>
                        </Typist>
                    </div>
                    <div className="w-[25%] hidden sm:block">
                        <div className="relative lg:right-24 right-2 bottom-8  delay-100">
                            <ToolTip tooltip={t('heroCreate')} bgColor='bg-greslernorange'>
                                <img alt="phone-ico" src={comment_ico} />
                            </ToolTip>
                        </div>
                        <div className="relative lg:left-10 md:left-4 left-0  top-8  delay-500">
                            <ToolTip tooltip={t('heroShare')} bgColor='bg-greslernorange'>
                                <img alt="phone-ico" src={group_ico} />
                            </ToolTip>
                        </div>
                    </div>
                </div>
                {/* <div
                    className='absolute top-[55%] w-[3rem] left-[35%] z-0 xl:rotate-[32deg] rotate-[30deg]  hidden lg:block'
                >
                    <Parallax
                        strength={200}
                        style={{ overflow: 'inherit' }}
                        renderLayer={percentage => (
                            <div className="relative">
                                <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}
                                    style={{
                                        width: `8px`,
                                        height: `${percentage <= 1 ? percentage * 55 : 55}vh`,
                                    }}
                                ></div>
                            </div>
                        )}
                    >
                    </Parallax>
                </div> */}
                <div className="flex flex-wrap justify-center items-center">
                    <div className="flex relative overflow-hidden mt-2">
                        <a
                            href='https://osc.int/documentation/'
                            target='_blank'
                            className="text-center  mx-3 border-2 border-[#007BFF] bg-[#007BFF] text-white p-2 sm:!p-4  hover:!text-[#007BFF] hover:bg-white rounded-lg transition-all duration-350 ease-in-out w-32 sm:w-48 hover:w-36"
                        >
                            {t("LearnMore")}
                        </a>
                        <div className="icon w-12 h-12 border-2 border-transparent rotate-45 relative top-1 z-[-1] transition-all duration-350 ease-in-out hidden lg:block">
                            <svg className="w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] fill-white transition-all duration-350 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                <path stroke="#007BFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 16.5c0-1-8-2.7-9-2V1.8c1-1 9 .707 9 1.706M10 16.5V3.506M10 16.5c0-1 8-2.7 9-2V1.8c-1-1-9 .707-9 1.706" />
                            </svg>
                        </div>
                    </div>
                    <div className="flex relative hover:!border-[#3C3C3B] overflow-hidden mt-2">
                        <a
                            href='/landingPageUserGuide'
                            target='_blank'
                            className="text-center mx-3 border-2 border-[#3C3C3B] text-[#3C3C3B] p-2 sm:!p-4 rounded-lg hover:text-white hover:bg-[#3C3C3B] transition-all duration-350 ease-in-out w-32 sm:w-48 hover:w-36">{t("User Guide")}</a>
                        <div className="icon_secondary w-12 h-12 border-2 border-transparent  rotate-45 relative top-1 z-[-1] transition-all duration-350 ease-in-out hidden lg:block">
                            <svg className="w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] fill-white transition-all duration-350 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                <path stroke="#3C3C3B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 16.5c0-1-8-2.7-9-2V1.8c1-1 9 .707 9 1.706M10 16.5V3.506M10 16.5c0-1 8-2.7 9-2V1.8c-1-1-9 .707-9 1.706" />
                            </svg>
                        </div>
                        {/* <div className="icon_secondary w-12 h-12 border-2 border-transparent bg-[#3C3C3B] rotate-45 relative top-1 z-[-1] transition-all duration-350 ease-in-out hidden lg:block">
                            <svg className=" w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] fill-white transition-all duration-350 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 8h6m-3 3V5m-6-.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM5 11h3a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z" />
                            </svg>
                        </div> */}
                    </div>
                </div>
                <div className='absolute top-[95%] left-[18%] z-10 w-24 hidden lg:block'>
                    <Parallax
                        strength={200}
                        style={{ overflow: 'inherit' }}
                        renderLayer={percentage => (
                            <div className="absolute  h-[70px] w-[70px] ">
                                <div id={id} className={` border-[#009FE3]  bg-[#009FE3] rounded-full text-black absolute  animate-grow-bg`}
                                    style={{
                                        width: `${percentage <= 1 ? percentage * 70 : 70}px`,
                                        height: `${percentage <= 1 ? percentage * 70 : 70}px`,
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                ></div>
                            </div>
                        )}
                    >
                    </Parallax>
                </div>
                {/* <div
                    className='absolute top-[98%] w-[4rem] left-[20%] z-0 rotate-[-33deg]  hidden lg:block'
                >
                    <Parallax
                        strength={200}
                        style={{ overflow: 'inherit' }}
                        renderLayer={percentage => (
                            <div className="absolute">
                                <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}
                                    style={{
                                        width: `8px`,
                                        height: `${percentage / 2 <= 1 ? percentage / 2 * 80 : 80}vh`,
                                    }}
                                ></div>
                            </div>
                        )}
                    >
                    </Parallax>
                </div> */}
            </div>

        </div >
    )
}

export default Hero
