import React from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
}

const ModalComponent: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <div className={`fixed z-[40] inset-0 ${isOpen ? "" : "hidden"}`}>
      <button className="fixed inset-0 transition-opacity" onClick={closeModal}>
        <div className="absolute inset-0 bg-gray-500 opacity-75" />
      </button>
      <div className=" w-full h-full flex  justify-center items-center">

        <div className="bg-noise bg-g-blue rounded-lg text-left overflow-hidden shadow-xl transform transition-all min-w-[30%] p-2">

          {title && <h1 className="text-2xl font-bold mb-4 text-white">
            {title}
          </h1>}
          {children}
          {/* <button
              onClick={closeModal}
              className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Close Modal
            </button> */}

        </div>
      </div>
    </div>)
};

export default ModalComponent;
