import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getresource = async () => {
    try {
        let result = await api.get(Apis.GetResource)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getresourcesortbylikes = async () => {
    try {
        let result = await api.get(Apis.GetresourceSortByLikes)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getmetaresource = async () => {
    try {
        const result = await api.get(Apis.GetMetaResource)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getresourceById = async (resourceId: any) => {
    try {
        let result = await api.get(Apis.GetResourceById + resourceId)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getresourceByemail = async (email: any) => {
    try {
        let result = await api.get(Apis.GetResourceByEmail + email)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (e) {
        console.log(e)
        return null;
    }
};
export default {
    getresource,
    getresourceById,
    getmetaresource,
    getresourcesortbylikes,
    getresourceByemail
}