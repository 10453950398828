import { useEffect, useState } from "react";
import { GetUsers } from "../../services";
import moment from "moment";
import { useTranslation } from "react-i18next";
import countries from "countries-list";
import ContentHeader from "../root/dashboard/addon/contentHeader";
import ContentLoader from "../loader/ContentLoader";

type profileprops = {
  name: string;
  email: string;
  type: string;
  gender: string;
  country: string;
  username: string;
  phone: string;
  createdAt: Date;
  instName: string;
  status: string;
  contactName: string;
  sendto: string;
};
const History = () => {
  const [institutesList, setInstitutesList] = useState<profileprops[]>([]);
  const [membersList, setMembersList] = useState<profileprops[]>([]);
  const [currentPagei, setCurrentPagei] = useState(1);
  const [isInstitute, setIsInstitute] = useState(false)
  const recordsPerPagei = 3;
  const lastIndexi = currentPagei * recordsPerPagei;
  const firstIndexi = lastIndexi - recordsPerPagei;
  const recordsi = institutesList.slice(firstIndexi, lastIndexi);
  const npagei = Math.ceil(institutesList.length / recordsPerPagei);
  const { t } = useTranslation();
  const numbersi = [...Array(npagei + 1).keys()].slice(1);
  const countryList = Object.values(countries.countries);
  countryList.sort((a, b) => a.name.localeCompare(b.name));
  function prePagei() {
    if (currentPagei !== firstIndexi) {
      setCurrentPagei(currentPagei - 1);
    }
  }
  function changeCPagei(id) {
    setCurrentPagei(id);
  }
  function nextPagei() {
    if (currentPagei !== lastIndexi) {
      setCurrentPagei(currentPagei + 1);
    }
    else
      return;
  }
  const fetchInstitutesList = async () => {
    setIsInstitute(false)
    let resource = await GetUsers.getinvitationsentExpired();
    if (resource) {
      setInstitutesList(resource);
      setIsInstitute(true)
    }
  };
  const handleSearch = (e) => {
    const filteredData = membersList?.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? fetchInstitutesList()
      : setMembersList(filteredData);
  };
  useEffect(() => {
    fetchInstitutesList();
  }, [])
  return (
    <div className="">
      <ContentHeader title={t("History")} />
      <div className="">
        <div className="my-2 flex place-content-end sm:flex-row flex-col">
          <div className="block relative">
            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
              </svg>
            </span>
            <input onChange={handleSearch} placeholder={t("Search")} className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-1 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
          </div>
        </div>
        {isInstitute ?
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wide">
                      {t("#No")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                      {t("Name")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                      {t("Email")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                      {t("Country")}
                    </th>
                    <th className="ppx-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                      {t("Type")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                      {t("RDate")}
                    </th>
                    <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                      {t("Status")}
                    </th>
                  </tr>
                </thead>
                {/* {institutesList.length > 0 ? (
                  institutesList?.map((users, k) => ( */}
                <tbody>
                  {recordsi?.map((users, i) =>
                    <tr
                      key={users.name}
                      className=""
                    // key={`adminUsertable${users.email}`}
                    >
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-center font-bold">
                        {i + 1}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm font-semibold">
                        {users.name}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm font-semibold">
                        {users.email}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {users.country}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {users.sendto}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {moment(users.createdAt).format('LL')}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-danger">
                        {t("Expired")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <nav className="items-center">
                <ul className="list-style-none flex ml-12">
                  <li className="page-item">
                    <button className="pointer-events-none relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300" onClick={prePagei}>
                      {t("Prev")}
                    </button>
                  </li>
                  {numbersi.map((n, i) =>
                    <li
                      className={`${currentPagei === n
                        ? "whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                        : "page-item"}`}
                      key={`adminUsertable${n.toString()}`}
                    >
                      <button
                        className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                        onClick={() => changeCPagei(n)}
                      >
                        {n}
                      </button>
                    </li>
                  )}
                  <li>
                    <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100" onClick={nextPagei}>
                      {t("Next")}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          : <ContentLoader />}
      </div>
    </div>

  );
}
export default History;