import { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { faBackward, faBan, faCheck, faEdit, faTrashCanArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationManager } from "react-notifications";
import { API_URL } from '../../../apiConfig';
import { GetUsers } from '../../../services';
import ModalComponent from '../../ModalComponent';
import UpdateUser from './UpdateUser';
function UserDetail() {

    const { email } = useParams()
    const { t } = useTranslation();
    const [bio, setBio] = useState<any>();
    const [gender, setGender] = useState<any>();
    const [profilephoto, setProfilephoto] = useState<any>();
    const [createdAt, setCreatedAt] = useState<any>();
    const [country, setSelectedCountry] = useState('');
    const [name, setName] = useState('');
    const [useremail, setUseremail] = useState('');
    const [status, setStatus] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [deleteComfirmation, setDeleteComfirmation] = useState(false);
    const [banComfirmation, setBanComfirmation] = useState(false);
    const [resetNormal, setResetNormal] = useState(false);
    const [instName, setInstName] = useState('');
    const [show, setShow] = useState(true);
    const [updateComponent, setUpdateComponent] = useState(false);

    const getInstituteDetail = async () => {
        const data = await GetUsers.getuserdataById(email ?? '');
        if (data) {
            fetchInstituteInfo(data.institute);
            setName(data.name);
            setUseremail(data.email);
            setStatus(data.status);
            setGender(data.gender);
            setSelectedCountry(data.country);
            setBio(data.bio);
            setProfilephoto(data.profilephoto)
            setCreatedAt(data.createdAt);
        }
    }
    const handleComponent = () => {
        setUpdateComponent(!updateComponent);
    }
    const fetchInstituteInfo = async (instEmail) => {
        const data = await GetUsers.getinstitutedetailRegistration(instEmail);
        if (data) {
            setInstName(data.institutionName);
        }
        else {
            setInstName('');
        }
    }
    const deleteInstitute = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.deleteUser(email ?? '');
            if (data) {
                NotificationManager.success("Successfull deleted", "User");
                handleComponent();
            }
            else {
                NotificationManager.warning("Not deleted", "User")
            }
        } catch (error) {
            NotificationManager.warning("Not deleted", "User")
        }
        setDeleteComfirmation(false);
    }

    const openModal = () => {
        setShowForm(!showForm);
        handleComponent();
    };

    const openDelete = () => {
        setDeleteComfirmation(!deleteComfirmation);
        setShow(false);
    };

    const openBan = () => {
        setBanComfirmation(!banComfirmation);
    };
    const handleResetNormal = async () => {
        setResetNormal(true);

    }
    const handleDeleteUser = async () => {
        setDeleteComfirmation(true);
    }
    const openResetNormal = () => {
        setResetNormal(!resetNormal);
    }
    const handleBanUser = async () => {
        setBanComfirmation(true);
    }
    const ResetAgain = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.resetAgain(email ?? '');
            if (data) {
                NotificationManager.success("Successfull reseted", "User");
                handleComponent();
            }
            else {
                NotificationManager.warning("Not reseted", "User")
            }
        } catch (error) {
            NotificationManager.warning("Not reseted", "User")
        }
        setResetNormal(false);
    }
    const banInstitute = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.banUser(email ?? '');
            if (data) {
                NotificationManager.success("Successfull Banned", "user");
                handleComponent();
            }
            else {
                NotificationManager.warning("Not Banned", "user")
            }
        } catch (error) {
            NotificationManager.warning("Not Banned", "user")
        }
        setBanComfirmation(false);
    }
    const updateInstitute = async (email) => {
        setShowForm(true);
    }
    useEffect(() => {
        getInstituteDetail();
    }, [updateComponent]);
    return (
        <>
            <Row className="align-items-center">
                <Col xl={12} lg={12} md={12} xs={12}>
                    <div className="bg-white rounded-bottom smooth-shadow-sm ">
                        <div className="d-flex align-items-center justify-content-between pt-4 pb-6 px-4">
                            <div className="d-flex align-items-center">
                                {/* avatar */}
                                <div className="avatar-xxl avatar-indicators avatar-online me-2 position-relative d-flex justify-content-end align-items-end mt-n10">
                                    {profilephoto ? (
                                        <img
                                            alt='PP'
                                            src={`${API_URL}/public/images/${profilephoto}`}
                                            className="w-10 h-10 avatar-xxl rounded-circle border border-4 border-white-color-40"
                                        />
                                    ) : (
                                        <div className="avatar-xxl rounded-circle border border-4 border-white-color-40">
                                            {name.charAt(0).toUpperCase()}
                                        </div>
                                    )}
                                </div>
                                {/* text */}
                                <div className="lh-1">
                                    <h2 className="mb-0">{name}
                                        <Link to="/" className="text-decoration-none" data-bs-toggle="tooltip" data-placement="top" title="" data-original-title="Beginner">
                                        </Link>
                                    </h2>
                                    <p className="mb-0 d-block">{useremail}</p>
                                </div>
                            </div>
                            <div className='align-items-end justify-evenly flex flex-row space-x-8'>
                                <div>
                                    <button onClick={(() => { window.history.back() })}>
                                        <FontAwesomeIcon icon={faBackward} color='blue' />
                                        &nbsp; {t("goBack")}
                                    </button>
                                </div>
                                <div>
                                    <button onClick={(() => { updateInstitute(email) })}>
                                        <FontAwesomeIcon icon={faEdit} color='orange' />
                                    </button>
                                </div>
                                {status == '0' || status === null ? (
                                    <>
                                        < div >
                                            <button
                                                onClick={handleBanUser}
                                            >
                                                <FontAwesomeIcon icon={faBan} color='yellow' />
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={handleDeleteUser}
                                            >
                                                <FontAwesomeIcon icon={faTrashCanArrowUp} color='red' />
                                            </button>
                                        </div>
                                    </>
                                ) : undefined
                                }
                                {status == '1' ? (
                                    <>
                                        < div >
                                            <button
                                                onClick={handleDeleteUser}
                                            >
                                                <FontAwesomeIcon icon={faTrashCanArrowUp} color='red' />
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={handleResetNormal}
                                            >
                                                <FontAwesomeIcon icon={faCheck} color='green' />
                                            </button>
                                        </div>
                                    </>
                                ) : undefined
                                }
                                {status == '2' &&
                                    <>
                                        < div >
                                            <button
                                                onClick={handleBanUser}
                                            >
                                                <FontAwesomeIcon icon={faBan} color='yellow' />
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={handleResetNormal}
                                            >
                                                <FontAwesomeIcon icon={faCheck} color='green' />
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Col >
            </Row >
            <Col xl={6} lg={12} md={12} xs={12} className="mb-12 w-full">
                {/* card */}
                <Card>
                    {/* card body */}
                    <Card.Body>
                        {/* card title */}
                        <Card.Title as="h4">{t("Information")}</Card.Title>
                        <span className="text-uppercase fw-medium text-dark fs-5 ls-2">{t("Bio")}</span>
                        <p className="mt-2 mb-6">{bio}.
                        </p>
                        <Row>
                            <Col xs={4} className="mb-5">
                                <h6 className="text-uppercase fs-5 ls-2">{t("Position")}</h6>
                                <p className="mb-0">{t("Creator")}</p>
                            </Col>
                            <Col xs={4} className="mb-5">
                                <h6 className="text-uppercase fs-5 ls-2">{t("Reg.Date")} </h6>
                                <p className="mb-0">{moment(createdAt).format('LL')}</p>
                            </Col>
                            <Col xs={4}>
                                <h6 className="text-uppercase fs-5 ls-2">{t("Sex")} </h6>
                                <p className="mb-0">{gender}</p>
                            </Col>
                            <Col xs={4}>
                                <h6 className="text-uppercase fs-5 ls-2">{t("Location")}</h6>
                                <p className="mb-0">{country}</p>
                            </Col>
                            {status === '1' &&
                                <Col xs={4} className="mb-5">
                                    <h6 className="text-uppercase fs-5 ls-2">{t("Status")} </h6>
                                    <p className="mb-0 text-warning">{t("Banned")}</p>
                                </Col>
                            }
                            {status === '2' &&
                                <Col xs={4} className="mb-5">
                                    <h6 className="text-uppercase fs-5 ls-2">{t("Status")} </h6>
                                    <p className="mb-0 text-danger">{t("Deactivated")}</p>
                                </Col>
                            }
                            {status === null &&
                                <Col xs={4} className="mb-5">
                                    <h6 className="text-uppercase fs-5 ls-2">{t("Status")} </h6>
                                    <p className="mb-0 text-success">{t("Active")}</p>
                                </Col>
                            }
                            {status === '0' &&
                                <Col xs={4} className="mb-5">
                                    <h6 className="text-uppercase fs-5 ls-2">{t("Status")} </h6>
                                    <p className="mb-0 text-success">{t("Active")}</p>
                                </Col>
                            }
                            <Col xs={4}>
                                <h6 className="text-uppercase fs-5 ls-2">{t("Reference")}</h6>
                                <p className="mb-0">{instName}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            {showForm &&
                <ModalComponent isOpen={show} title={false} onClose={openModal}>
                    <UpdateUser email={email} />
                </ModalComponent>
            }
            {
                deleteComfirmation &&
                <ModalComponent isOpen={show} title={false} onClose={openDelete}>
                    <label>{t("Delete user")}</label>
                    <label>{t("Do you want to delete?")}</label>
                    <button className="btn btn-default" onClick={openDelete}>{t("Cancel")}</button>
                    <button className="btn btn-primary" onClick={deleteInstitute}>{t("OK")}</button>
                </ModalComponent>
            }
            {
                banComfirmation &&
                <ModalComponent isOpen={show} title={false} onClose={openBan}>
                    <label>{t("Ban user")}</label>
                    <label>{t("Do you want to ban?")}</label>
                    <button className="btn btn-default" onClick={openBan}>{t("Cancel")}</button>
                    <button className="btn btn-primary" onClick={banInstitute}>{t("OK")}</button>
                </ModalComponent>
            }
            {
                resetNormal &&
                <ModalComponent isOpen={show} title={false} onClose={openResetNormal}>
                    <label>{t("Reset to active user")}</label>
                    <label>{t("Do you want to reset to active?")}</label>
                    <button className="btn btn-default" onClick={openResetNormal}>{t("Cancel")}</button>
                    <button className="btn btn-primary" onClick={ResetAgain}>{t("OK")}</button>
                </ModalComponent>
            }
        </>
    );
}
export default UserDetail;
