import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentService } from "../../../../services";
import ContentCard from "../../../ContentFile/ContentCard";
import NotFound from "../../../NotFound";
import ContentHeader from "../addon/contentHeader";

interface IContentListEntry {
  contentId: string;
  mainLibrary: string | undefined;
  title: string;
  originalNewKey?: string;
  userId: any;
  owner?: any;
}
const SharedToMeResources = () => {
  const { t } = useTranslation();

  const [sharedContents, setSharedContents] = useState<IContentListEntry[]>();
  const [currentContentId, setCurrentContentId] = useState("");
  const [displayToggle, setDisplayToggle] = useState(false);

  const contentService = new ContentService();

  const getAllPublicResources = async () => {
    const contentListsh = await contentService.listSharedWithMe();
    setSharedContents(contentListsh);
  };

  useEffect(() => {
    getAllPublicResources();
  }, []);
  const handleRefreshClick = () => {
    window.location.reload();
  };
  // search
  const handleSearch = (e) => {
    const filteredData = sharedContents?.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? getAllPublicResources()
      : setSharedContents(filteredData);
  };

  return (
    <>
      <div className="flex-1 px-2 sm:px-0">
        <div className="flex flex-wrap justify-between items-center">
          <ContentHeader title={t("HomeShortcutSharedResources")} />
          <button
            onClick={handleRefreshClick}
            className="ml-2 text-yellow-900 focus:outline-none transform hover:rotate-90 transition duration-300 ease-in-out text-3xl font-bold"
            title={t("Refresh")}>
            ↻
          </button>
          <div className="inline-flex items-center space-x-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                onChange={handleSearch}
                type="search"
                id="default-search"
                className="block w-full pl-10 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
        </div>
        <ul className="mb-10 sm:mb-0 mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 3xl:grid-cols-10">
          {sharedContents?.map((content,k) => (
            <ContentCard
              key={`${k}sharedContents`}
              content={content}
              setCurrentContentId={setCurrentContentId}
              updateFolder={() => alert("updateFolder")}
              displayToggle={false}
              contentType="shared"
            />
          ))}
          {sharedContents?.length == 0 && <NotFound />}
        </ul>
      </div>
    </>
  );
};
export default SharedToMeResources;
