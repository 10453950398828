import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import { Spin } from 'react-cssfx-loading'

import { GetUserLogin } from '../../../services';

function ReactivateForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    // Function to send the verification code to the server
    const sendEmail = async () => {
        setLoading(true);
        GetUserLogin.sendEmailForAccountReactivate(email).then((response) => {
            if (!response) {
                NotificationManager.error("Email is not found", "Email Error")
                setLoading(false);
            }
            else {
                navigate('/login')
            }
        }).catch((error) => {
            NotificationManager.error("Some thing error sending Email", "Account activation")
            setLoading(false);
        });
    };


    return (
        <div className='relative w-[400px] flex justify-center items-center rounded-[20px] bg-box animate-animate3 box-form h-auto py-[20px] before:content before:absolute before:w-full before:h-full before:bg-box-before before:animate-animate3 before:rounded-[20px] before:animation-delay-1000 after:content after:absolute after:inset-[8px] after:bg-greslernorange after:bg-noise  after:rounded-[15px] after:border-4 after:border-transparent'>
            <form
                className='relative w-full flex justify-center items-center flex-col gap-[25px] py-0 px-[40px] z-[10]'
                onSubmit={(e) => {
                    e.preventDefault();
                    sendEmail();
                }}>
                <div className="mb-1">
                    <h2 className='text-white text-[32px] text-center font-semibold'>{t("Re activate")}</h2>
                    <label className="mb-2.5 block font-medium text-gray-400 text-center dark:text-white">
                        You can get all your resource as soon as you reactivate
                    </label>
                    <div className="relative">
                        <input
                            id="email"
                            name='email'
                            type="text"
                            placeholder={t("Email")}
                            onChange={(e) => setEmail(e.target.value)}
                            className="password relative w-full py-[10px] px-[15px] border !border-g-blue outline-none rounded-[5px] text-white text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]"
                        />
                        <div className="absolute right-4 top-3 text-g-blue">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                    </div>
                </div>
                <div className="mb-2 w-full flex justify-center">
                    <button
                        disabled={loading}
                        type="submit"
                        className={` relative flex justify-center  mt-1 w-[85%] py-[10px] px-[15px] rounded-[5px] text-base tracking-wider transition-[0.5s] cursor-pointer
                ${loading
                                ? 'bg-[#2d2d39] text-white pointer-events-none'
                                : 'bg-white text-[#2d2d39] hover:!bg-[#243946] hover:text-white'}
              `}
                    >
                        {loading ? (<Spin color="#ffffff" width="30px" height="30px" duration="1s" />) : t("Reactivate")}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ReactivateForm;
