import { faClose, faCross } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function NoticeBar() {
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation()

    const handleCloseClick = () => {
        setIsVisible(false);
    };
    return (
        <div id="noticeBar" className={`position-fixed bottom-0 left-0 w-full bg-greslernorange bg-opacity-[0.7] text-white p-[15px] text-center z-[20] ${isVisible ? "block" : "hidden"}`}>
            <FontAwesomeIcon icon={faClose} className='position-absolute top-[30%] right-[15px] hover:cursor-pointer text-[20px] font-[400]' onClick={handleCloseClick} />
            <p>{t("Explore GreSLERN - Now in Beta! Your feedback matters,")}<a className='text-bold  text-black' href="/contactus">  {t("Contact Us")}</a></p>

        </div>
    )
}

export default NoticeBar
