import React, { useEffect, useState } from "react";

const CustomerReviews = ({ reviews }) => {

  const [score5, setScore5] = useState(0)
  const [score4, setScore4] = useState(0)
  const [score3, setScore3] = useState(0)
  const [score2, setScore2] = useState(0)
  const [score1, setScore1] = useState(0)
  const [score0, setScore0] = useState(0)
  const [totalRateing, setTotalRating] = useState(0)

  useEffect(() => {
    let meanReview = 0;
    let num0 = 0
    let num1 = 0
    let num2 = 0
    let num3 = 0
    let num4 = 0
    let num5 = 0
    reviews.map((review: any) => {
      meanReview += review.BIEs.score;
      switch (parseInt(review.BIEs.score)) {
        case 0:
          num0++;
          break
        case 1:
          num1++;
          break;
        case 2:
          num2++;
          break;
        case 3:
          num3++;
          break;
        case 4:
          num4++;
          break;
        case 5:
          num5++;
          break;
        default:
          break;
      }
    })
    setScore0(reviews.length ? (num0 / reviews.length) * 100 : 0)
    setScore1(reviews.length ? (num1 / reviews.length) * 100 : 0)
    setScore2(reviews.length ? (num2 / reviews.length) * 100 : 0)
    setScore3(reviews.length ? (num3 / reviews.length) * 100 : 0)
    setScore4(reviews.length ? (num4 / reviews.length) * 100 : 0)
    setScore5(reviews.length ? (num5 / reviews.length) * 100 : 0)
    setTotalRating(reviews.length ? meanReview / reviews.length : 0)
  }, [])

  return (
    <div className="flex flex-col text-gray-700">
      <div className="flex flex-col w-full px-4">
        <h2 className="text-base mb-2 font-semibold">User Evaluations</h2>
        <div className="flex flex-wrap items-center mb-1 space-x-2">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={`w-4 h-4 ${totalRateing >= 1 ? 'text-greslernorange' : 'text-gray-300'} `}
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={`w-4 h-4 ${totalRateing >= 2 ? 'text-greslernorange' : 'text-gray-300'} `}
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={`w-4 h-4 ${totalRateing >= 3 ? 'text-greslernorange' : 'text-gray-300'} `}
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={`w-4 h-4 ${totalRateing >= 4 ? 'text-greslernorange' : 'text-gray-300'} `}
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={`w-4 h-4 ${totalRateing >= 5 ? 'text-greslernorange' : 'text-gray-300'} `}
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
          </div>
          <span className="text-gray-400">{parseFloat(reviews[0]?.BIEs.score).toFixed(2)} out of 5</span>
        </div>
        <p className="text-gray-400">{reviews?.length} global Evaluation</p>
        <div className="flex flex-col mt-2 px-8">
          <div className="flex items-center space-x-1">
            <span className="flex-shrink-0 w-16">5 score</span>
            <div className="flex-1 h-3 overflow-hidden rounded-sm bg-gray-200">
              <div className={`bg-greslernorange opacity-[0.7] h-3 `} style={{ width: `${score5}%` }}></div>
            </div>
            <span className="flex-shrink-0 w-12 text-right">{score5}%</span>
          </div>
          <div className="flex items-center space-x-1">
            <span className="flex-shrink-0 w-16">4 score</span>
            <div className="flex-1 h-3 overflow-hidden rounded-sm bg-gray-200">
              <div className="bg-greslernorange opacity-[0.7] h-3" style={{ width: `${score4}%` }}></div>
            </div>
            <span className="flex-shrink-0 w-12 text-right">{score4}%</span>
          </div>
          <div className="flex items-center space-x-1">
            <span className="flex-shrink-0 w-16">3 score</span>
            <div className="flex-1 h-3 overflow-hidden rounded-sm bg-gray-200">
              <div className="bg-greslernorange opacity-[0.7] h-3 " style={{ width: `${score3}%` }}></div>
            </div>
            <span className="flex-shrink-0 w-12 text-right">{score3}%</span>
          </div>
          <div className="flex items-center space-x-1">
            <span className="flex-shrink-0 w-16">2 score</span>
            <div className="flex-1 h-3 overflow-hidden rounded-sm bg-gray-200">
              <div className="bg-greslernorange opacity-[0.7] h-3 " style={{ width: `${score2}%` }}></div>
            </div>
            <span className="flex-shrink-0 w-12 text-right">{score2}%</span>
          </div>
          <div className="flex items-center space-x-1">
            <span className="flex-shrink-0 w-16">1 score</span>
            <div className="flex-1 h-3 overflow-hidden rounded-sm bg-gray-200">
              <div className="bg-greslernorange opacity-[0.7] h-3 " style={{ width: `${score1}%` }}></div>
            </div>
            <span className="flex-shrink-0 w-12 text-right">{score1}%</span>
          </div>
          <div className="flex items-center space-x-1">
            <span className="flex-shrink-0 w-16">0 score</span>
            <div className="flex-1 h-3 overflow-hidden rounded-sm bg-gray-200">
              <div className="bg-greslernorange opacity-[0.7] h-3 " style={{ width: `${score0}%` }}></div>
            </div>
            <span className="flex-shrink-0 w-12 text-right">{score0}%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReviews;
