import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getActivity = async (instId:any) => {
  try {
    let result = await api.get(Apis.GetActivity + instId)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
export default {
  getActivity,
}