 const  SingleTagComponent = (checked: any, value: any,label:any, sectionId: any, optionIdx: any, handleFilterChecked: (isChecked: any, value: any) => void) => {
    return <div key={value} className="inline-block relative py-1 text-xs">
        <div className={`absolute inset-0 ${checked ? 'text-gray-200' : 'text-gray-100'} flex`}>
            <svg height="100%" viewBox="0 0 50 100">
                <path d="M49.9,0a17.1,17.1,0,0,0-12,5L5,37.9A17,17,0,0,0,5,62L37.9,94.9a17.1,17.1,0,0,0,12,5ZM25.4,59.4a9.5,9.5,0,1,1,9.5-9.5A9.5,9.5,0,0,1,25.4,59.4Z"
                    fill="currentColor" />
            </svg>
            <div className={`flex-grow h-full -ml-px rounded-md rounded-l-none ${checked ? 'bg-gray-200' : 'bg-gray-100'}`}>
            </div>
        </div>
        <div className="flex items-center">
            <label htmlFor={`filter-${sectionId}-${optionIdx}`} className="relative text-greslernorange/70 capitalize pr-px cursor-pointer">
                <input
                    id={`filter-${sectionId}-${optionIdx}`}
                    name={`${sectionId}[]`}
                    defaultValue={value}
                    type="checkbox"
                    defaultChecked={checked}
                    // checked={option.checked}
                    className="sr-only peer"
                    onChange={(e) => handleFilterChecked(e.target.checked, value)} />
                <span className={`${checked ? " !text-greslernorange  !font-semibold " : " "} peer-hover:text-greslernorange  pr-2 pl-3  rounded-md`}> {label}</span>
            </label>
        </div>
    </div>
}
export default SingleTagComponent