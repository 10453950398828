import React, { useState } from 'react';
import TagUserModal from "../../tagUserModal/TagUserModal"
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { selectAuthStatus } from '../../../redux/Reducer/authSlice';

const ShareButton = ({setLogin, resourceId, fetchTagsList }) => {

  const [searchUserModal, setSearchUserModal] = useState(false);
  const isAuthenticatied = useSelector(selectAuthStatus)
  
  const handleUserModal = ()=>{
    if(isAuthenticatied){
      setSearchUserModal(true)
    }
    else{
      setLogin(true)
    }
  }

  return <div>
      <button onClick={handleUserModal} className="share-button hover:text-orange ">
        <FontAwesomeIcon className="text-2xl " icon={faTags} />
        {/* <h2 className="text-sm   text-gray-900">
          {listTags} {t("Tags")}
        </h2> */}
      </button>

      {/* <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"> */}
      {searchUserModal ? 
        <TagUserModal 
          resourceId={resourceId // call search user modal component based on value -- muluken
            } 
          fetchTagsList={fetchTagsList} 
          setSearchUserModal={setSearchUserModal} 
          /> : undefined // call the component here -- muluken
      }
      {/* {modalVisible && <Modal onClose={() => setModalVisible(false)} />} */}
    </div>;
};

export default ShareButton;
