import { Listbox } from '@headlessui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomSelect = ({ allMembersList, selectedEmail, setSelectedEmail }) => {
    const { t } = useTranslation()
    const [searchQuery, setSearchQuery] = useState("");

    const filteredOptions = allMembersList.filter(option =>
        option.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (
        <div className="w-full relative">
            <Listbox value={selectedEmail} onChange={setSelectedEmail}>
                {({ open }) => (
                    <>
                        <Listbox.Button className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]">
                            {selectedEmail || t("Select user")}
                        </Listbox.Button>
                        {open && (
                            <Listbox.Options className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                <input
                                    type="text"
                                    placeholder={t("Search by user name")}
                                    value={searchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                    className="block w-full py-2 px-4 mb-2 text-sm rounded-md bg-gray-200"
                                />
                                {filteredOptions.map((data) => (
                                    <Listbox.Option
                                        key={data.email}
                                        className={({ active }) =>
                                            `cursor-pointer select-none relative py-2 pl-10 pr-4 ${active ? 'text-amber-900 bg-amber-800' : 'text-gray-900'
                                            }`
                                        }
                                        value={data.email}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center space-x-4">
                                                    <div className="flex-shrink-0">
                                                        <div className="w-8 h-8 rounded-full bg-white bg-black-700 outline-dashed outline-2 outline-offset-2 outline-blue-500">
                                                            {data.profilephoto ? (
                                                                <img
                                                                    src={`/api/public/images/${data.profilephoto}`}
                                                                    className="w-8 h-8 items-center rounded-full text-greslernorange text-2xl text-center"
                                                                    alt='profile'
                                                                />
                                                            ) : (
                                                                <div className="w-8 h-8 items-center text-greslernorange text-2xl text-center">
                                                                    {data.name.charAt(0).toUpperCase()}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <p className="text-sm font-semibold truncate text-greslernorange">
                                                            {data.name}
                                                        </p>
                                                        <p className="text-sm text-blue-500 truncate dark:text-gray-400">
                                                            {data.email}
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                                        {data.type}
                                                    </div>
                                                </div>
                                                {selected && (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-amber-600' : 'text-amber-800'
                                                            }`}
                                                    >
                                                        <svg
                                                            className="h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414L8.414 15l-4.707-4.707a1 1 0 011.414-1.414L8.414 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        )}
                    </>
                )}
            </Listbox>
        </div>
    );
};

export default CustomSelect;