import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const setAs = async (type,contentId: any) => {
    try {
        let result = await api.patch(Apis.SetAsResource, {type:type, contentId: contentId.toString() })

        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

const setAsTemplate = async (contentId: any) => {
    try {
        let result = await api.patch(Apis.SetAsTemplateResource, { contentId: contentId.toString() })

        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

const getAllTemplates = async () => {

    try {
        const result = await api.get(Apis.GetAllTemplateResource)

        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
}


const setResourceChatSetting = async (contentId: any, chatSetting) => {
    try {
        let result = await api.patch(Apis.SetResourceChatSetting, { contentId: contentId.toString(), chatSetting: chatSetting })
        console.log("TS res: " + JSON.stringify(result))
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};


export default {
    setAs,
    setAsTemplate,
    getAllTemplates,
    setResourceChatSetting
}