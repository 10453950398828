import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import CreateGroupInstitute from '../../../../services/CreateGroupInstitute';
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../../../ModalComponent';
import { GetUsers } from '../../../../services';
import ContentLoader from '../../../loader/ContentLoader';
import NoData from '../NoData';
import { useSelector } from 'react-redux';
import { selectInstitute } from '../../../../redux/Reducer/authSlice';
type instituteProps = {
    name: string;
    country: string;
    createdAt: any;
    email: string;
    generatedTokenExpiration: Date;
    status: string;
    _id: string;
    id: string;
    profilephoto: any;
    type?: string;
};
export default function ListMembers({ usergroupid, groupName, handleListMember, updatecomponetu }) {
    const { t } = useTranslation();
    const [memberlistingroup, setMemberlistingroup] = useState<instituteProps[]>([]);
    const [show, setShow] = useState(true);
    const [adduserlist, setAdduserlist] = useState(false);
    const [islistdisplay, setIslistdisplay] = useState(true);
    const [membersListactive] = useState<instituteProps[]>([]);
    const [showAdd, setShowAdd] = useState(false);
    const [updatecomponent, setUpdatecomponent] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userAddButton, setUserAddButton] = useState(false);
    const instituteId = useSelector(selectInstitute)
    let existingmember: any = [];
    const handleRemoveUser = async (email: string) => {
        let resource = await CreateGroupInstitute.removeUserGroup(email);
        if (resource) {
            NotificationManager.success("Removed", "user");
            setUpdatecomponent(!updatecomponent);
            handleListMember();
            updatecomponetu();
        }
        else {
            NotificationManager.warning("Not removed", "user");
            setUpdatecomponent(!updatecomponent);
        }
    }
    const fetchListUsersingroup = async (usergroupid: any) => {
        setIsLoading(true);
        const data = await CreateGroupInstitute.getListUsersingroup(usergroupid);
        if (data) {
            data.forEach((r: any) => {
                existingmember.push(r?.email);
                console.log(existingmember, "exist");
                console.log(data, "fetchoup");
            })
            setMemberlistingroup(data);
            setIsLoading(false);

        }
        else {
            setMemberlistingroup([]);
            setIsLoading(false);
        }
    }
    const handleAddUsers = () => {
        setAdduserlist(!adduserlist);
        setIslistdisplay(!islistdisplay);
        setShowAdd(!showAdd);
    };
    const addSingleUserGroup = async (email: string, usergroupId: string) => {
        const updatedata = {
            subgroupId: usergroupId,
        }
        try {
            let data = await CreateGroupInstitute.updateUserGroup(email || "", updatedata)
            if (!data.ok) {
                NotificationManager.success("Successfully Added", "Add to group");
                updatecomponetu();
            }
            else {
                NotificationManager.warning("User not Added", "Add to group")
            }
        } catch (error) {
            console.error('Error during add:', error);
            NotificationManager.warning("User not Added", "Add to group")
        }
    }
    const handleClose = () => {
        setShow(false);
    };
    const fetchlistofactiveusers = async () => {
        setIsLoading(true);
        const data = await GetUsers.getmembersList(instituteId);
        if (data) {
            data.forEach((r) => {
                if (!existingmember.includes(r?.email?.toString())) {
                    membersListactive.push(r)
                }
            })
            setIsLoading(false)
        }
        else {
            // setMembersListactive([]);
        }
    }
    const handleItemClick = (item: string) => {
        const optionIndex = selectedItem.findIndex(
            selected => selected === item
        );
        if (optionIndex !== -1) {
            setSelectedItem(
                selectedItem.filter((_, index) => index !== optionIndex)
            );
        } else {
            setSelectedItem([...selectedItem, item]);
            setUserAddButton(true);
        }
    };
    const addUserToGroup = async () => {
        setIsLoading(true);
        try {
            selectedItem.forEach(async element => {
                addSingleUserGroup(element, usergroupid);
            });
            setIsLoading(false);
            setAdduserlist(false);
            setShowAdd(false);
            handleListMember();
        } catch (e) {
            setIsLoading(false);
            setAdduserlist(false);
            handleListMember();
        }
    }
    useEffect(() => {
        fetchListUsersingroup(usergroupid);
        fetchlistofactiveusers();
    }, [updatecomponent]);
    return (
        <ModalComponent isOpen={show} title={false} onClose={handleClose}>
            <div className="bg-white p-2 rounded-lg shadow-lg w-full">
                <div className='flex flex-row justify-between mb-1 p-2'>
                    <div>
                        <span className=''>{t("Member's")}</span>
                    </div>
                    {showAdd ?
                        <div>
                            <button
                                onClick={(e) => { e.preventDefault(); addUserToGroup(); handleListMember() }}
                                disabled={!userAddButton}
                                className="bg-greslernorange font-semibold text-white hover:!bg-white hover:!text-greslernorange py-2 px-2 border border-white hover:border-transparent rounded"
                            >
                                <FontAwesomeIcon icon={faAdd} />
                                <h6>{t("Add")}</h6>
                            </button>
                        </div>
                        :
                        <div className='mr-8'><button onClick={handleAddUsers}>
                            <FontAwesomeIcon icon={faAdd} />{t("Add user")}</button></div>
                    }
                </div>
                {isLoading ? <ContentLoader /> : <>
                    {adduserlist &&
                        <div className=" box-content">
                            <label className="block mb-2 font-bold text-white">
                                {membersListactive.length} {t("users selected")}{" "}
                            </label>
                            {/* <input
                            type="text"
                            placeholder={t("Search by user name")}
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="block w-full py-2 px-4 mb-2 text-sm rounded-md bg-gray-200"
                        /> */}
                            {membersListactive?.length <= 0 ?
                                <div className="h-[15.5rem] overflow-y-scroll scroll-py-6  snap-y scroll-smooth focus:scroll-auto hideScrollbar resize-y ">
                                    <NoData />
                                </div> :
                                <div className="h-auto overflow-y-scroll scroll-py-6  snap-y scroll-smooth focus:scroll-auto hideScrollbar resize-y ">
                                    <div>
                                        <ul className=" w-full ">
                                            {membersListactive.map((item, index) => (
                                                <li
                                                    key={item.email}
                                                    onClick={() => handleItemClick(item.email)}
                                                    className={`p-3 sm:py-4 cursor-pointer bg-white border border-gray-300 rounded-md shadow-md mt-1 
                          ${selectedItem.some(selected => selected === item.email) &&
                                                        "!bg-blue-200  !text-white"}`
                                                    }
                                                // style={{ cursor: 'pointer', fontWeight: selectedItem.some(selected => selected === item.email) ? 'bold' : 'normal' }}
                                                >
                                                    <div className="flex items-center space-x-4">
                                                        <div className="flex-shrink-0">
                                                            <div className="w-8 h-8 rounded-full bg-white  bg-black-700  outline-dashed outline-2 outline-offset-2 outline-blue-500">
                                                                {item.profilephoto
                                                                    ? <img
                                                                        src={`/api/public/images/${item.profilephoto}`}
                                                                        className="w-8 h-8 items-center rounded-full text-greslernorange text-2xl text-center"
                                                                    />
                                                                    : // {/* <img className="" src=" sdfsdf" /> */}
                                                                    <div className="w-8 h-8 items-center text-greslernorange text-2xl text-center">
                                                                        {item.name.charAt(0).toUpperCase()}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 min-w-0">
                                                            <p
                                                                className={`text-sm font-semibold truncate dark:text-white ${selectedItem.some(
                                                                    selected => selected === item.email
                                                                )
                                                                    ? " text-white-900"
                                                                    : " !text-white-200"}`}
                                                            >
                                                                {item.name}
                                                            </p>
                                                            <p className="text-sm text-blue-500 truncate dark:text-gray-400">
                                                                {item.email}
                                                            </p>
                                                        </div>
                                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                            {item.type}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        {/* {selectedItem && <p>Selected Item: {selectedItem}</p>} */}
                                    </div>
                                </div>
                            }

                        </div>
                    }
                    {islistdisplay &&
                        <>
                            {
                                memberlistingroup.length <= 0 ? <NoData /> :
                                    <>
                                        {memberlistingroup?.map((item) =>
                                            <div key={item._id}
                                                className=" flex flex-row justify-between p-3 sm:py-4 cursor-pointer bg-white border border-gray-300 rounded-md shadow-md mt-1"
                                            >
                                                <div className="flex items-center space-x-4">
                                                    <div className="flex-shrink-0">
                                                        <div className="w-8 h-8 rounded-full bg-white  bg-black-700  outline-dashed outline-2 outline-offset-2 outline-blue-500">
                                                            {item.profilephoto
                                                                ? <img
                                                                    src={`/api/public/images/${item.profilephoto}`}
                                                                    className="w-8 h-8 items-center rounded-full text-greslernorange text-2xl text-center"
                                                                />
                                                                : // {/* <img className="" src=" sdfsdf" /> */}
                                                                <div className="w-8 h-8 items-center text-greslernorange text-2xl text-center">
                                                                    {item.name.charAt(0).toUpperCase()}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <p
                                                            className="text-sm font-semibold truncate dark:text-white text-white-200">
                                                            {item.name}
                                                        </p>
                                                        <p className="text-sm text-blue-500 truncate dark:text-gray-400">
                                                            {item.email}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleRemoveUser(item.email)
                                                        }}
                                                        className="flex-no-shrink p-1 ml-2 border-2 rounded text-red border-red hover:text-white hover:bg-red">
                                                        {t("Remove")}
                                                    </button>
                                                </div>

                                            </div>
                                        )}
                                    </>
                            }
                        </>
                    }
                </>
                }

            </div >
        </ModalComponent >
    );
}
function asyc() {
    throw new Error('Function not implemented.');
}

