
import React, { useEffect, useState } from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { ContentService } from "../../../services";
import { IContentListEntry, IContentService } from '../../../services/ContentService';
import PlayContentModal from '../ContentCard';

import { faArrowAltCircleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotFound from '../../NotFound';
import { t } from 'i18next';


const ContentListLanding: React.FC<{ contentService: IContentService, isAuthenticated } & WithTranslation> = (props) => {
    const [contentListp, setContentListp] = useState<IContentListEntry[]>([]);
    const [newContent, setNewContent] = useState(false);
    const [limitation, setLimitation] = useState(5);

    const contentService: IContentService = new ContentService("/h5p");

    useEffect(() => {
        updateList();
    }, [props.isAuthenticated]);

    const showMore = () => {
        setLimitation(prevLimitation => prevLimitation + 5);
    };


    const updateList = async () => {
        if (props.isAuthenticated) {
            const list = await contentService.listPublic();
            setContentListp(list);
        } else {
            const list = await contentService.getContentForcePublic();
            setContentListp(list);
        }
    };

    return (
            <div className="flex-1 px-2 sm:px-0">
                <div className="flex justify-between items-center ">
                    <div className={
                        newContent ? "" : "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4"
                    }>
                        {contentListp.length ?
                            contentListp.slice(0, limitation).map((content) => (
                                <PlayContentModal
                                    contentService={contentService}
                                    data={content}
                                    generateDownloadLink={
                                        contentService.generateDownloadLink
                                    }
                                    generateHtmlDownloadLink={
                                        contentService.generateHtmlDownloadLink
                                    }
                                />
                            )) :
                            <NotFound />
                        }
                    </div>
                </div>
                <div className='flex justify-end w-full mt-10'>
                    {contentListp.length > 5 ? (
                        <>
                            <button onClick={showMore}>
                                {t("See More Content")}
                                <FontAwesomeIcon icon={faArrowAltCircleRight} className='pl-2 fa-solid fa-heart fa-beat fa-beat-scale: 2.0' />
                            </button>
                        </>
                    ) : undefined}
                </div>
                <div className='flex justify-center '> 
                {/* <a href='resources' className='text-greslernorange font-bold hover:text-greslernorange/60 cursor-pointer'>see all </a> */}
                    <a href="resources" className="flex items-center text-greslernorange capitalize border border-greslernorange py-2 px-6 gap-2 rounded inline-flex items-center hover:!border-1 hover:font-bold hover:text-greslernorange">
                        <span>
                            {t("see all")}
                        </span>
                        <FontAwesomeIcon icon={faArrowRight} className='w-4' />
                    </a>
                </div>
            </div>
    );
};

export default withTranslation()(ContentListLanding);