import React from 'react'
import { Parallax } from 'react-parallax';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import computer from '../../../components/assets/img/platform/computer.png'
import mobile from '../../../components/assets/img/platform/mobile.png'
import tv from '../../../components/assets/img/platform/Tv.png'
import pattern_1 from '../../../components/assets/img/platform/pattern_1.png'
import { fadeIn, textVariant } from '../../../utils/motion';
import SectionWrapper from '../../../hoc';
import LineComponent from '../../../components/lineComponent/idex';

function Platform({ id, id2 }) {
    const { t } = useTranslation();
    return (
        < div id='platformSection' className="bg-white md:h-auto lg:h-[calc(100vh-5rem)] w-full"
            style={{
                backgroundImage: `url(${pattern_1})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: ' right',
                backgroundSize: "contain"
            }}
        >
            <div className="flex justify-between items-center h-full max-w-screen-xl mx-auto my-4 lg:my-0">
                <div className='grid grid-cols-12'>
                    <div className='col-span-12 lg:col-span-4 z-1'>
                        <motion.div variants={textVariant()}
                            className='flex items-center'
                        >
                            <Parallax
                                strength={200}
                                renderLayer={percentage => (
                                    <div className="relative h-[150px] w-[150px]">
                                        <div id={id} className={` border-[#FFDE00]  bg-[#FFDE00] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `${percentage <= 0.5 ? percentage * 300 : 150}px`,
                                                height: `${percentage <= 0.5 ? percentage * 300 : 150}px`,
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        ></div>
                                        <span className="relative left-16 top-8 text-6xl leading-normal border-0 text-black p-0 ml-auto z-1 "> {t("wh")}</span>
                                    </div>
                                )}
                            >
                            </Parallax>
                            <span className='p-0 text-6xl text-black border-0 z-1'>{t("erever")}</span>
                        </motion.div>
                        <motion.h2 variants={textVariant()} className='relative bottom-12 left-36 text-6xl md:w-3/4 w-1/2 font-extrabold z-[2]'>
                            {t("youare")}
                        </motion.h2>
                        <div
                            className='absolute top-[233%] left-[28%] z-[1] w-24  hidden lg:block'
                        >
                            <Parallax
                                strength={200}
                                style={{ overflow: 'inherit' }}
                                renderLayer={percentage => (
                                    <div className="relative  h-[50px] w-[50px] ">
                                        <div id={id2} className={` border-[#E30613]  bg-[#E30613] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `${percentage <= 1 ? percentage * 50 : 50}px`,
                                                height: `${percentage <= 1 ? percentage * 50 : 50}px`,
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        ></div>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </div>
                        {/* <div
                            className='absolute top-[236%] left-[29.4%] z-0 rotate-[340deg] hidden lg:block'
                        >
                            <Parallax
                                strength={200}
                                style={{ overflow: 'inherit' }}
                                renderLayer={percentage => (
                                    <div className="relative">
                                        <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `8px`,
                                                height: `${percentage <= 1 ? percentage * 45 : 45}vh`,
                                            }}
                                        ></div>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </div> */}


                        <motion.p variants={fadeIn("", "", 0.1, 1)}
                            className='px-5 z-[2]'>
                            <span className='border-0 text-black font-bold p-0 '>
                                {t("patformS1")}
                            </span>
                            {t("patformS2")}
                        </motion.p>
                    </div>
                    <div className='col-span-12 lg:col-span-8 flex flex-col lg:flex-row justify-evenly items-center mt-4 lg:mt-0'>
                        <motion.div
                            variants={fadeIn("left", "spring", 0.5 * 3, 0.75)}
                            className='flex flex-col items-center'
                        >
                            <img alt='computer' src={computer} />
                            <h3 className='pt-4 pb-2 font-bold'>{t("Laptop/Desktop")}</h3>
                            <small>{t("computerSentence")}</small>

                        </motion.div>
                        <motion.div
                            variants={fadeIn("left", "spring", 0.5 * 2, 0.75)}
                            className='flex flex-col items-center mt-2 lg:mt-0'
                        >
                            <img alt='mobile' src={mobile} />
                            <h3 className='pt-4 pb-2 font-bold'>{t("Mobile/Tablet")}</h3>
                            <small>{t("phoneSentence")}</small>
                        </motion.div>
                        <motion.div
                            variants={fadeIn("left", "spring", 0.5 * 1, 0.75)}
                            className='flex flex-col items-center mt-2 lg:mt-0'
                        >
                            <img alt='Television' src={tv} />
                            <h3 className='pt-4 pb-2 font-bold'>{t("Screens")}</h3>
                            <small>{t("ScreensSentence")}</small>
                        </motion.div>
                    </div>
                </div>
            </div>
            <LineComponent startPointId='targetElementPlatformWhereverYellow' endPointId='targetElementPlatformWhereverRed' />
        </div>
    )
}

export default Platform