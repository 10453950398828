import React, { useRef, useState, Fragment } from 'react'
import { useTranslation } from "react-i18next"
import { Menu, Transition } from '@headlessui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../Login/style.css';
import BackgroundR from '../../components/background/backgroundR';
import BackgroudL from '../../components/background/backgroundL';
import BackgroudImg from '../../components/assets/african.jpg';

import LoginAnimation from '../../components/loginAnimation';
import SignupForm from '../../components/form/signup';
import LoginForm from '../../components/form/login';
import Languages from '../../constances/languages.json'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Signup() {
  const { t, i18n } = useTranslation()
  const usernameInputRef = useRef<HTMLInputElement | null>(null);
  const passwordInputRef = useRef<HTMLInputElement | null>(null);
  const faceRef = useRef<HTMLDivElement | null>(null);
  const [slideBoxMarginLeft, setSlideBoxMarginLeft] = useState('50%');
  const [topLayerMarginLeft, setTopLayerMarginLeft] = useState('0%');
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('languageName') || 'English');
  const language = Languages.slice(0, 4);
  const languagesSecond = Languages.slice(4);

  const handleGoRightClick = () => {
    setSlideBoxMarginLeft('0%');
    setTopLayerMarginLeft('100%');
  };

  const handleGoLeftClick = () => {
    setSlideBoxMarginLeft('50%');
    setTopLayerMarginLeft('0%');
  };

  const handleButtonClick = (name: string, value: string) => {
    setSelectedLanguage(name);
    localStorage.setItem("language", value)
    localStorage.setItem("languageName", name)
    i18n.changeLanguage(value);
  };

  return (
    <div id="wrapper ">
      <div id="back" className="hidden lg:block">
        <div className="absolute right-0 w-1/2 h-full bg-art bg-cover bg-center" >
          <BackgroundR />
        </div>
        <div className="absolute left-0 w-1/2 h-full bg-art bg-cover bg-center" >
          <BackgroudL />
        </div>
        {/* <SignupForm  /> */}

      </div>
      <div id="slideBox" className={`w-full lg:w-1/2 min-h-full h-full overflow-hidden absolute shadow-slide  transition-margin-left duration-[0.5s] ease-in-out `} style={{ marginLeft: slideBoxMarginLeft }}  >
        <div className={`w-[200%] h-full relative left-0 left-[-100%]  transition-margin-left duration-[0.5s] ease-in-out `} style={{ marginLeft: topLayerMarginLeft }}>
          <div className="w-1/2 h-[100vh]  bg-left  absolute left-0 animate-gradient bg-[length:400%_400%]">
            <LoginAnimation usernameInputRef={usernameInputRef} passwordInputRef={passwordInputRef} faceRef={faceRef}>
              <LoginForm handleGoLeftClick={handleGoLeftClick} usernameInputRef={usernameInputRef} passwordInputRef={passwordInputRef} />
              <div className='row mt-2'>
                {language.map((item) => (
                  <div
                    key={item.id}
                    className={selectedLanguage === item.name ? 'lng-btn selected col' : 'lng-btn col'}
                    onClick={() => handleButtonClick(item.name, item.value)}
                    style={{
                      color: selectedLanguage === item.name ? '#243946' : '', // Set the text color conditionally
                    }}
                  >
                    {item.name}
                  </div>
                ))}
                <Menu as="div" className="relative inline-block text-left h-full w-auto">
                  <Menu.Button className="w-auto h-full text-slate-600  flex justify-center items-center border-0  p-2 hover:!border-[#007BFF]">
                    <FontAwesomeIcon icon={faChevronDown} className=" h-5 w-5 text-white " aria-hidden="true" />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute bottom-0 left-16 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {languagesSecond?.map((item) => (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames(
                                  active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                  'block w-full px-4 py-2 text-left text-sm'
                                )}
                                key={item.id}
                                onClick={() => handleButtonClick(item.name, item.value)}
                              >
                                {item.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </LoginAnimation>
          </div>
          <div className="w-1/2 h-full right-0 absolute bg-right">
            <div className="relative top-1/2 left-1/2 w-full h-screen flex justify-center items-center flex-col rounded-[3px] transform -translate-x-1/2 -translate-y-1/2 overflow-y-scroll overflow-x-hidden bg-cover bg-line-pattern">
              <SignupForm handleGoRightClick={handleGoRightClick} />
              <div className='row mt-2'>
                {language.map((item) => (
                  <div
                    key={item.id}
                    className={selectedLanguage === item.name ? 'lng-btn selected col text-lg font-bold' : 'lng-btn col'}
                    onClick={() => handleButtonClick(item.name, item.value)}
                    style={{
                      color: selectedLanguage === item.name ? '#a34f05 ' : '', // Set the text color conditionally
                    }}
                  >
                    {item.name}
                  </div>
                ))}
                <Menu as="div" className="relative inline-block text-left h-full w-auto">
                  <Menu.Button className="w-auto h-full text-slate-600  flex justify-center items-center border-0  p-2 hover:!border-[#007BFF]">
                    <FontAwesomeIcon icon={faChevronDown} className=" h-5 w-5 text-white " aria-hidden="true" />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute bottom-0 left-16 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {languagesSecond?.map((item) => (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames(
                                  active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                  'block w-full px-4 py-2 text-left text-sm'
                                )}
                                key={item.id}
                                onClick={() => handleButtonClick(item.name, item.value)}
                              >
                                {item.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup