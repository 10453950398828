import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socket } from '../../../apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import { GetNotification } from '../../../services';
import { toast } from 'react-toastify';
import { toastOpt } from '../../../config';
import { selectAuthStatus } from '../../../redux/Reducer/authSlice';
const LikeButton = ({ resourceId, username, setLogin }) => {
  const [liked, setLiked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0); // State to manage total likes
  const sessionID = useSelector((state: any) => state.socket.sessionID);
  const isAuthenticated = useSelector(selectAuthStatus)

  useEffect(() => {
    fetchLikesList();
    getlikeStatus()
  }, []);

  const handleLikeClick = async () => {
    if(isAuthenticated){
      if (username && resourceId) {
        // new notification implementation
        !liked ? await GetNotification.saveNotification(resourceId, 'like', null, null, 'Like content')
          : await GetNotification.deleteLikeNotification(resourceId)

        // socket to update likes 
        await socket.emit('likeStatus', { resourceId, username, liked, sessionID })

        setTimeout(() => {
          fetchLikesList();
          getlikeStatus()
        }, 50);

        // alert(username)
        setLiked(!liked)
      
      }
    }else{
      setLogin(true)
    }

  };
  const fetchLikesList = async () => {
    let data = await GetNotification.getLikeNo(resourceId)
    if (data) {
      setTotalLikes(data);
    }
  }

  const getlikeStatus = async () => {
    let data = await GetNotification.getUserLikeStatus(resourceId);
    if (data) {
      setLiked(data);

    }
  }

  return (
    <button
      className={`flex items-center space-x-1 text-gray-600 hover:text-orange focus:outline-none ${liked ? 'text-orange' : ''
        }`}
      onClick={handleLikeClick}
    >
      <FontAwesomeIcon
        className="text-2xl "
        icon={faThumbsUp}
      />
      <h2 className={`transition duration-300 ${liked ? 'text-orange text-lg' : 'text-sm   text-gray-900'}`}>
        {totalLikes} Likes
      </h2>
    </button>
  );
};

export default LikeButton;
