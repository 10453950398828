import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getFrequent = async () => {
  try {
    let result = await api.get(Apis.GetFrequent)
    if (result.status !== 200) {
      return null;
    }
    return result.data
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
const addusedlink = async (useddata: any) => {
  try {
    const response = await api.post(Apis.CreateFrequent, useddata);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    AxiosErrorHandling(error)
    throw error;
  }
};
export default {
  getFrequent,
  addusedlink,
}