import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FolderExplorerService from "../../services/FolderExplorerService";

const generateOptions = (folders) => {
  const options = folders.map((folder) => ({
    label: folder.name,
    value: folder._id,
  }));

  const groupedOptions = options.reduce((result, option) => {
    const firstLetter = option.label.charAt(0).toUpperCase();
    if (!result[firstLetter]) {
      result[firstLetter] = [];
    }
    result[firstLetter].push(option);
    return result;
  }, {});

  return groupedOptions;
};

const FolderSelectElement = ({ setfoldertosave, folderId = false }) => {
  const [folders, setFolders] = useState([]);
  const [groupedOptions, setGroupedOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getFolders();
  }, []);

  const getFolders = async () => {
    const folders = await FolderExplorerService.fetchFolders();
    setFolders(folders);
    setGroupedOptions(generateOptions(folders));

    setfoldertosave(
      folders?.find((el) => {
        return el.name === "Root";
      })._id
      );
  };
  const hadleSelectChange = (event) => {
    let value = event.target.value;
    setfoldertosave(value);
  };

  return <select className="p-2 w-full rounded-lg bg-transparent text-gray-500 sm:text-sm cursor-pointer border border-transparent hover:!border-greslernorange hover:text-greslernorange" onChange={hadleSelectChange}>
      <option selected disabled>
        {t("select folder")}
      </option>
      {Object.entries(groupedOptions)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([group, options]) =>
          <optgroup key={group} label={group}>
            {options.map(option => option.label != 'Trash' &&
              <option
                key={option.value}
                value={option.value}
                selected={
                  option.value === folderId ||
                  (option.label === "Root" &&
                    (folderId === false || folderId === undefined))
                }
                className="hover:!bg-greslernorange"
              >
                /{option.label}
              </option>
            )}
          </optgroup>
        )}
    </select>;
};

export default FolderSelectElement;
