import React from 'react';
import { useTranslation } from 'react-i18next';

export default function() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center justify-center mt-5">
            <h1 className="text-5xl text-gray-400 font-bold mb-8 animate-pulse">
            {t("Coming Soon")}
            </h1>
            <p className="text-white text-lg mb-8">
            {t("We're working hard to bring you. Stay tuned!")} 
            </p>
        </div>
    );
}
