import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetUsers } from "../../../../services";
import ContentHeader from "../../../root/dashboard/addon/contentHeader";
import ToolTip from "../../../tooltip";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare, faTrash, faUserCheck, faUserSlash, faUsers } from "@fortawesome/free-solid-svg-icons";
import NoData from "../../../institutePages/components/NoData";
import ModalComponent from "../../../ModalComponent";
import { NotificationManager } from "react-notifications";

type profileprops = {
  profilephoto: string;
  name: string;
  email: string;
  bio: string;
  type: string;
  gender: string;
  country: string;
  username: string;
  phone: string;
  createdAt: Date;
  status: string;
  contactName: string;
  _id:string;
  institutionName:string;
};

const InstitutionsTable = ({ institutions = [] }) => {

  const [institutesList, setInstitutesList] = useState<profileprops[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = institutesList.slice(firstIndex, lastIndex);
  const npage = Math.ceil(institutesList.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [banComfirmation, setBanComfirmation] = useState(false);
  const [deleteComfirmation, setDeleteComfirmation] = useState(false);
  const [resetNormal, setResetNormal] = useState(false);
  const [updateComponent,setUpdateComponent] =useState<Boolean>(true);
  const [emailAction,setEmailAction] =useState('');

  const { t } = useTranslation();

  const handleBanUser = async (instEmail:string) => {
    setEmailAction(instEmail);
    setBanComfirmation(true);
  }
  const handleResetNormal = async (_id:string) => {
    setEmailAction(_id);
    setResetNormal(true);

  }
  const handleDeleteUser = async (instEmail:string) => {
    setEmailAction(instEmail);
    setDeleteComfirmation(true);
  }
  const openBan = () => {
    setBanComfirmation(!banComfirmation);
  };
  const openDelete = () => {
    setDeleteComfirmation(!deleteComfirmation);
  };
  const openReset = () => {
    setResetNormal(!resetNormal);
  };
  const fetchInstitutesList = async () => {
    let resource = await GetUsers.getinstitutelist();
    if (resource) {
      setInstitutesList(resource);
    }
  };
  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
    else
      return;
  }
  const handleSearch = (e) => {
    const filteredData = institutesList?.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? fetchInstitutesList()
      : setInstitutesList(filteredData);
  };
  const deleteInstitute = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.deleteInstitute(emailAction ? emailAction : "null");
      if (data) {
        setUpdateComponent(!updateComponent);
        NotificationManager.success("Successfull deleted", "Institute");
      }
      else {
        NotificationManager.warning("Not deleted", "Institute")
      }
    } catch (error) {
      NotificationManager.warning("Not deleted", "Institute")
    }
    setDeleteComfirmation(false);
  }
  const banInstitute = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.baninstitute(emailAction ? emailAction : "null");
      if (data) {
        NotificationManager.success("Successfull Banned", "Institute");
        setUpdateComponent(!updateComponent);
      }
      else {
        NotificationManager.warning("Not Banned", "Institute")
      }
    } catch (error) {
      NotificationManager.warning("Not Banned", "Institute")
    }
    setBanComfirmation(false);
  }
  const ResetAgainInstitute = async (e) => {
    e.preventDefault();
    try {
      const data = await GetUsers.resetAgainInstitute(emailAction ? emailAction : "null");
      if (data) {
        NotificationManager.success("Successfull Reseted", "Institute");
        setUpdateComponent(!updateComponent);
      }
      else {
        NotificationManager.warning("Not Reseted", "Institute")
      }
    } catch (error) {
      NotificationManager.warning("Not Reseted", "Institute")
    }
    setResetNormal(false);
  }
  useEffect(() => {
    fetchInstitutesList();
  }, [updateComponent]);
  return (
    <div className="w-full">
      <ContentHeader title={t("Institutions List")} showIcon={false} />
      <div className="">
        <div className="my-2 flex place-content-end sm:flex-row flex-col">
          <div className="block relative">
            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
              </svg>
            </span>
            <input onChange={handleSearch} placeholder={t("Search")} className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-1 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
          </div>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("#No")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Name")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Type")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Country")}
                  </th>
                  <th className="ppx-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Phone")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("RDate")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Status")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {institutesList.length > 0 ? (
                  records.map((institutions, i) =>
                    <tr
                      className=""
                      key={`adminUsertable${institutions._id}`}
                    >
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-center font-bold">
                        {i + 1}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {institutions.institutionName}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm font-semibold">
                        {institutions.type}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {institutions.country}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {institutions.phone}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {moment(institutions.createdAt).format('LL')}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {institutions.status === '0' &&
                          <span className="font-semibold text-light-inverse text-md/normal text-success">{t("Active")}</span>
                        }
                        {institutions.status === '1' &&
                          <span className="font-semibold text-light-inverse text-md/normal text-warning">{t("Banned")}</span>
                        }
                        {institutions.status === '2' &&
                          <span className="font-semibold text-light-inverse text-md/normal text-danger">{t("Deactivated")}</span>
                        }
                        {institutions.status === null &&
                          <span className="font-semibold text-light-inverse text-md/normal text-success">{t("Active")}</span>
                        }
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        <div className="flex gap-2 justify-center items-center w-full">
                          {
                            institutions.status === '2' ?
                              <>
                                <button onClick={
                                  (e) => {
                                    e.preventDefault()
                                    handleBanUser(institutions._id)
                                  }}
                                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                  <ToolTip tooltip="ban user">
                                    <FontAwesomeIcon icon={faUserSlash} color="orange" />
                                  </ToolTip>
                                </button>
                                <button
                                  onClick={() => handleResetNormal(institutions._id)}
                                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                  <ToolTip tooltip="activate user">
                                    <FontAwesomeIcon icon={faUserCheck} color="green" />
                                  </ToolTip>
                                </button>
                              </>
                              : ""

                          }
                          {
                            institutions.status === '1' ?
                              <>
                                <button
                                  onClick={() => handleResetNormal(institutions._id)}
                                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                  <ToolTip tooltip="activate user">
                                    <FontAwesomeIcon icon={faUserCheck} color="green" />
                                  </ToolTip>
                                </button>
                                <button
                                  onClick={() => handleDeleteUser(institutions._id)}
                                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                  <ToolTip tooltip="delete">
                                    <FontAwesomeIcon icon={faTrash} color="red" />
                                  </ToolTip>
                                </button>
                              </>
                              : ""

                          }
                          {
                            institutions.status === '0' || institutions.status === null ?
                              <>
                                <button
                                  onClick={() => handleBanUser(institutions._id)}
                                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                  <ToolTip tooltip="ban user">
                                    <FontAwesomeIcon icon={faUserSlash} color="orange" />
                                  </ToolTip>
                                </button>
                                <button
                                  onClick={() => handleDeleteUser(institutions._id)}
                                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                  <ToolTip tooltip="delete">
                                    <FontAwesomeIcon icon={faTrash} color="red" />
                                  </ToolTip>
                                </button>
                              </>
                              : ""

                          }
                          {
                            institutions.status === '5' ?
                              <>
                                <button
                                  // onClick={() => handleDeleteUserPending(users.email)} 
                                  className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                  <ToolTip tooltip="delete">
                                    <FontAwesomeIcon icon={faUserSlash} color="red" />
                                  </ToolTip>
                                </button>
                              </>
                              : ""

                          }
                          <button className=" relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                            <ToolTip tooltip="see detail">
                              <Link to={`/admin/detail/${institutions._id}`} className='hover:!text-greslernorange'>
                                <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                  </svg>
                                </span>
                              </Link>
                            </ToolTip>
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )
                  : (
                    <tr>
                      <NoData />
                    </tr>
                  )
                }
              </tbody>
            </table>
            {institutesList.length <= 0 ? <NoData /> : <>
              <nav className="items-center">
                <ul className="list-style-none flex ml-12">
                  <li className="page-item">
                    <button className="pointer-events-none relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300" onClick={prePage}>
                      {t("Prev")}
                    </button>
                  </li>
                  {numbers.map((n) =>
                    <li
                      className={`${currentPage === n
                        ? "whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                        : "page-item"}`}
                      key={`adminUsertable${n.toString()}`}
                    >
                      <button
                        className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </button>
                    </li>
                  )}
                  <li>
                    <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100" onClick={nextPage}>
                      {t("Next")}
                    </button>
                  </li>
                </ul>
              </nav>
            </>
            }
          </div>
        </div>
        {deleteComfirmation &&
          <ModalComponent isOpen={true} title={false}>
            <label>{t("Delete Institute")}</label>
            <label>{t("Do you want to delete the institute?")}</label>
            <button className="btn btn-default" onClick={openDelete}>{t("Cancel")}</button>
            <button className="btn btn-primary" onClick={deleteInstitute}>{t("OK")}</button>
          </ModalComponent>
        }
        {banComfirmation &&
          <ModalComponent isOpen={true} title={false} >
            <label>{t("Ban Institute")}</label>
            <label>{t("Do you want to ban the institute?")}</label>
            <button className="btn btn-default" onClick={openBan}>{t("Cancel")}</button>
            <button className="btn btn-primary" onClick={banInstitute}>{t("OK")}</button>
          </ModalComponent>
        }
        { resetNormal &&
          <ModalComponent isOpen={true} title={false}>
            <label>{t("Reset To normal Institute")}</label>
            <label>{t("Do you want to Reset to Normal?")}</label>
            <button className="btn btn-default" onClick={openReset}>{t("Cancel")}</button>
            <button className="btn btn-primary" onClick={ResetAgainInstitute}>{t("OK")}</button>
          </ModalComponent>
        }
      </div>
    </div>
  )
}
export default InstitutionsTable;