import { FC,ReactNode, useRef } from "react";
import { useOnClickOutside } from "../../utils/hooks/useOnClickOutside";

interface FolderContextMenuProps {
  x: number;
  y: number;
  closeContextMenu: () => void;
  children: ReactNode;
}
const FolderContextMenu: FC<FolderContextMenuProps> = ({
  x,
  y,
  closeContextMenu,
  children
}) => {
  const contextMenuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(contextMenuRef, closeContextMenu);

  return <div ref={contextMenuRef} onClick={() => closeContextMenu()} className=" -z-999999 absolute grid grid-row-4 shadow-md bg-gray-100 justify-items-start p-2 text-sm" style={{ top: `${y}px`, left: `${x}px` }}>
      {children}
    </div>;
};

export default FolderContextMenu;
