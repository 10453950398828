import { faCommentAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import CardContainer from "./CardContainer";
import { Link } from "react-router-dom";

const CommentsStatisticsCard = ({comments = []}) => {
  const { t } = useTranslation()

  return (
    <Link to='/admin/comments'>
      <CardContainer 
        icon={
          <FontAwesomeIcon
              icon={faCommentAlt}
              className=" w-1/2 h-1/2 rounded-full m-1 text-white"
            />
        } 
        title="Feedbacks" 
        subTitle="Feedbacks Statistics" 
        statistics={
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-white ">
              {comments ? comments?.length : "0"}
            </p>
            <p className="text-sm font-normal text-white/50">{t("Total")}</p>
          </div>
          } 
        iconBgColor='bg-yellow' 
        shadowColor="shadow-yellow" />
    </Link>
  );
};

export default CommentsStatisticsCard;
