import { useEffect, useState } from 'react'
import { ContentService } from '../../../../../services'
import { IContentListEntry } from '../../../../../services/ContentService'
import NoRecordFound from '../../../../NotRecoredFound'
import ContainerComponent from '../ContainerComponent'

const BieContentWalkthroughs = () => {
    const [resources, setResources] = useState<IContentListEntry[]>([])
    const contentService = new ContentService()

    const fetchData = async () => {
        const list = await contentService.listBieWalkthroughsResources();
        setResources(list);
    }
    
    useEffect(() => {
        fetchData()
    }, [])

    return  resources.length > 0 ? <ContainerComponent resources={resources} /> : <NoRecordFound />
}

export default BieContentWalkthroughs