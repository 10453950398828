import React, { useState } from "react";
import { faAdd, faDashboard, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import RegisterMember from "../../../../institutePages/components/RegisterMember";

function ContentHeader({ title = '', showIcon }: Readonly<{ title: string, showIcon?: any }>) {
  const [showRegistration, setShowRegistration] = useState(false);
  const handleAddStudent = () => {
    setShowRegistration(!showRegistration)
  }
  return (
    <div className="flex justify-between">
      <div className="!pb-0 tbox relative px-2 lg:px-4 py-2 lg:py-10 sm:rounded-xl !shadow-none  !border-t-0 !border-x-0 bg-gradient-to-tr from-white/25 to-transparent uppercase">
        <h5 className="text-3xl font-mono font-extrabold  text-greslernorange  ">
          {title}
        </h5>
      </div>
      {showIcon === "true" &&
        <>
          <Link to="/institute/resource">
            <div className="resource">
              <FontAwesomeIcon icon={faAdd} color="orange" />
              <span> Resource</span>
            </div>
          </Link>
          <Link to="/institute/individuals">
            <div className="users">
              <FontAwesomeIcon icon={faUsers} color="orange" />
              <span> Users</span>
            </div>
          </Link>
          <Link to="/institute/report">
            <div className="analisis">
              <FontAwesomeIcon icon={faDashboard} color="orange" />
              <span> Report</span>
            </div>
          </Link>
          {/* <Link to="/institute/individuals"> */}
          <div className="addstudent" onClick={handleAddStudent}>
            <FontAwesomeIcon icon={faAdd} color="orange" />
            <span> Add Student</span>
          </div>
          {/* </Link> */}
        </>
      }
      {
        showRegistration && <RegisterMember />
      }
    </div>

  );
}

export default ContentHeader;