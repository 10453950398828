import { H5PPlayerUI } from '@lumieducation/h5p-react';
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import { ContentService } from '../../../services/ContentService';
import { GetResourceShared } from "../../../services";
import CommentSectionUi from '../../CommentSectionUi';
import { faClose, faCopyright } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import ContentHeader from "../../root/dashboard/addon/contentHeader";
import Reviews from '../../Reviews/Reviews';
import { GetResourceById, GetReview, GetUsers } from '../../../services';
import ModalComponent from '../../ModalComponent';


const ContentPlay = () => {

  const { t } = useTranslation()
  const { contentId } = useParams()
  const [content, setContent] = useState() as any;
  const [isComm, setIsComm] = useState(true);
  const h5pPlayer = useRef<H5PPlayerUI>(null);
  const contentService = new ContentService;
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [showCopyright, setShowCopyright] = useState(false)

  const toggleCopyright = () =>{
    setShowCopyright(!showCopyright)
  }

  const getContentMeta = async () => {
    
    let data = await GetResourceShared.getresourceByResId(contentId);
    // const c = await contentService.getContentMeta(contentId)
    // console.log("t : "+ JSON.stringify(c))
    setContent(data[0])
  }

  const getReviews = async () => {
    setIsLoading(true)
    const response = await GetReview.getReview({ contentId: contentId });
    if (response) {
      const reviewsWithUserData: any = [];

      for (const item of response) {
        const data = await GetUsers.getuserdata(item.userEmail);
        if (data && data.length > 0) {
          const userData = {
            email: data[0].email,
            name: data[0].name,
            image: data[0].profilephoto,
            country: data[0].country,
            gender: data[0].gender
          };

          // Create a new object with the existing review data and user data
          const reviewWithUserData = { ...item, user: userData };
          reviewsWithUserData.push(reviewWithUserData);
        }
      }
      setReviews(reviewsWithUserData);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getReviews()
    getContentMeta()
  }, [])

  const navigate = useNavigate()
  return <div className="flex-1 px-2 sm:px-0">
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex lg:flex-row md:flex-row md:justify-between lg:justify-between md:items-center lg:items-center flex-col">
          <ContentHeader title={t("Playing Content")} showIcon={false} />
          <h3 className="lg:pl-5 md: pl-2 text-3xl  text-yellow-900 font-extrabold">
            {/* {content?.title} */}
          </h3>
        </div>
        <div className="inline-flex items-center space-x-2">
          <div className="inline-flex rounded-lg border border-gray-100 bg-gray-100 p-1">
            <button onClick={() => navigate(-1)} className="inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative">
              <FontAwesomeIcon icon={faClose} /> {t("Close")}
            </button>
          </div>
        </div>
      </div>

      <div className=" pt-2">        
        <div className='bg-[#1e3a8adb] rounded-t-md text-white capitalize flex flex-col gap-1 px-4 py-2 '>
          <div className=' text-2xl'>
            <p>{t('')}<b> {content?.title}</b> </p>
          </div>
          <div className='flex flex-row gap-6'>
            <p>{t('by')}<b> {content?.username}</b> </p>
            <p>{t('status')}<b> {content?.status}</b></p>
            <p>{t('')} {new Date(content?.creationDate).toGMTString()} </p>
            <button
              onClick={toggleCopyright} 
              className='grow text-right cursor-pointer text-sm' >
                <FontAwesomeIcon icon={faCopyright} /> Copyright 
            </button>
          </div>
          {/* {JSON.stringify(content)} */}
        </div>
        <ModalComponent isOpen={showCopyright} onClose={toggleCopyright} title={"license"} >

          <div
            className='text-white'
            dangerouslySetInnerHTML={{
                                    __html:
                                    h5pPlayer.current?.getCopyrightHtml()  !== '' ?
                                      h5pPlayer.current?.getCopyrightHtml() :
                                      'No copyright information'
                            }}
                        ></div>
        </ModalComponent>
        {contentId && <H5PPlayerUI ref={h5pPlayer} contentId={contentId} loadContentCallback={contentService.getPlay} />
        // onInitialized={this.onPlayerInitialized}
        // onxAPIStatement={(statement: any, context: any, event) =>
        //   // console.log(statement, context, event)
        // }
        
        }
      </div>
      <div className=" bg-white py-3 text-sm rounded-sm">

        {/* Header */}
        <div className="border-b transition-all delay-75 flex justify-center gap-10 font-semibold text-gray-700">
          <button onClick={() => setIsComm(true)} className={`border-b-2 py-1 hover:text-gray-700 capitalize ${isComm ? "border-primary" : "border-white text-gray-500"}`}>
            {t("feedbacks")}
          </button>
          <button onClick={() => setIsComm(false)} className={`border-b-2 py-1  hover:text-gray-700 ${!isComm ? "border-primary" : "border-white text-gray-500"}`}>
            {t("Reviews")}
          </button>
        </div>

        {isComm ? <CommentSectionUi resourceId={contentId} showcommentlist={true} /> : <Reviews reviews={reviews} isLoading={isLoading} getReviews={getReviews} />}
      </div>
    </div>;
}

export default ContentPlay
