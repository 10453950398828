import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetResourceShared } from "../../services";


const MultipleSelectWithSearch = ({ options, shareToList, resourceId, type = 'shared' }) => {
  const [t] = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOptionToggle = option => {
    const optionIndex = selectedOptions.findIndex(
      (selected: { id: string }) => selected.id === option.id
    );
    if (optionIndex !== -1) {
      setSelectedOptions(
        selectedOptions.filter((_, index) => index !== optionIndex)
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
    shareToList(selectedOptions);
  };

  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const fetchByResource = async resourceId => {
    let data = await GetResourceShared.getresourceByResId(resourceId);
    let selectUser
    let selectUsers: any = []

    let listOfUsers
    if (type === 'shared') {
      listOfUsers = data[0].sharedTo;
    } else if (type === 'tagged') {
      listOfUsers = data[0].taggedTo;
    }
    listOfUsers.forEach(((user) => {
      // if(user.fromUserId.email == currentUserEmail){

      selectUser =
      {
        id: user.userId._id,
        name: user.userId.name,
        email: user.userId.email,
        gender: user.userId.gender,
        country: user.userId.country,
        profilephoto: user.userId.profilephoto
      }
      selectUsers.push(selectUser);
      // }
    }))

    setSelectedOptions(selectUsers);
  };

  useEffect(() => {
    fetchByResource(resourceId);
  }, []);

  return (
    <div className=" box-content">
      <label className="block mb-2 font-bold text-white">
        {selectedOptions.length} {t("users selected")}{" "}
      </label>

      <input
        type="text"
        placeholder={t("Search by user name")}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="block w-full py-2 px-4 mb-2 text-sm rounded-md bg-gray-200"
      />
      <div className="h-[15.5rem] overflow-y-scroll scroll-py-6  snap-y scroll-smooth focus:scroll-auto hideScrollbar resize-y ">
        <ul className=" w-full ">
          {filteredOptions.map(option =>
            <li
              key={option.email}
              className={`p-3 sm:py-4 cursor-pointer bg-white border border-gray-300 rounded-md shadow-md mt-1 
                          ${selectedOptions.some((selected: { id: string }) => selected.id === option.id) &&
                "!bg-greslernorange  !text-white"}`
              }
              onClick={() => handleOptionToggle(option)}
            >
              {shareToList(selectedOptions)}
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <div className="w-8 h-8 rounded-full bg-white  bg-black-700  outline-dashed outline-2 outline-offset-2 outline-blue-500">
                    {option.profilephoto
                      ? <img
                        src={`/api/public/images/${option.profilephoto}`}
                        className="w-8 h-8 items-center rounded-full text-greslernorange text-2xl text-center"
                      />
                      : // {/* <img className="" src=" sdfsdf" /> */}
                      <div className="w-8 h-8 items-center text-greslernorange text-2xl text-center">
                        {option.name.charAt(0).toUpperCase()}
                      </div>}
                  </div>
                </div>
                <div className="flex-1 min-w-0">
                  <p
                    className={`text-sm font-semibold truncate dark:text-white ${selectedOptions.some(
                      (selected: { id: string }) => selected.id === option.id
                    )
                      ? " text-white-900"
                      : " !text-greslernorange"}`}
                  >
                    {option.name}
                  </p>
                  <p className="text-sm text-blue-500 truncate dark:text-gray-400">
                    {option.email}
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {option.type}
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MultipleSelectWithSearch;
