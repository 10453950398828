import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const sendFeedback = async (feedback: any) => {
    try {
        let result = await api.post(Apis.sendFeedback, feedback)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};

export default {
    sendFeedback
}