import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentService } from "../../../../services";
import ContentCard from "../../../ContentFile/ContentCard";
import NotFound from "../../../NotFound";
import ContentHeader from "../addon/contentHeader";
import ResourceCardSkeleton from "../../../loader/ResourceCardSkeleton";

interface IContentListEntry {
  contentId: string;
  mainLibrary: string | undefined;
  title: string;
  originalNewKey?: string;
  userId: any;
}
const PublicResources = () => {
  const { t } = useTranslation();

  const [publicContents, setPublicContents] = useState<IContentListEntry[]>([]);
  const [visibleItems, setVisibleItems] = useState<IContentListEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentContentId, setCurrentContentId] = useState("");
  const [displayToggle, setDisplayToggle] = useState(false);
  const contentService = new ContentService();
  const itemsPerLoad = 8;


  const getAllPublicResources = async () => {
    setLoading(true);
    await contentService.listPublic().then((response) => {
      if (response) {
        setPublicContents(response);
        setVisibleItems((response).slice(0, 14));
      }
      setLoading(false);
    })
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      if (visibleItems.length !== publicContents.length) loadMoreItems();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleItems]);

  const loadMoreItems = () => {
    setLoading(true)
    const currentLength = visibleItems.length;
    const moreItems = publicContents.slice(currentLength, currentLength + itemsPerLoad);
    setVisibleItems(prevItems => [...prevItems, ...moreItems]);
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  };

  const handleSearch = (e) => {
    const filteredData = publicContents?.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? getAllPublicResources()
      : setPublicContents(filteredData);
  };

  useEffect(() => {
    getAllPublicResources();
  }, []);

  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <div className="flex-1 px-2 sm:px-0">
      <div className="flex flex-wrap justify-between items-center">
        <ContentHeader title={t("MyPublicResources")} />
        <button
          onClick={handleRefreshClick}
          className="ml-2 text-yellow-900 focus:outline-none transform hover:rotate-90 transition duration-300 ease-in-out text-3xl font-bold"
          title={t("Refresh")}>
          ↻
        </button>
        <div className="inline-flex items-center space-x-2">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              onChange={handleSearch}
              type="search"
              id="default-search"
              placeholder={t("Search Contents")}
              className="block w-full pl-10 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <ul className="mb-10 sm:mb-0 mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 3xl:grid-cols-10">
        {visibleItems?.map((content, k) => (
          <div
            className={
              k == 0
                ? "animate-fadeInRt3"
                : k == 1
                  ? "animate-fadeInRt2"
                  : k == 2
                    ? "animate-fadeInRt1"
                    : "animate-fadeInRt"
            }>
            <ContentCard
              key={`${k}publicContents`}
              content={content}
              displayToggle={displayToggle}
              setCurrentContentId={setCurrentContentId}
              updateFolder={() => alert("updateFolder")}
              contentType="public"
            />
          </div>
        ))}
        {loading && <ResourceCardSkeleton />}
        {!loading && visibleItems.length === publicContents.length && <p className="text-2xl text-greslernorange">{t("No more items to load.")}</p>}
        {!loading && publicContents?.length === 0 && <NotFound />}
      </ul>
    </div>
  );
};
export default PublicResources;
