import { motion } from 'framer-motion'

import { staggerContainer } from '../utils/motion'

const SectionWrapper = (Component: any, idName: string, lan?: string) =>
    function HOC() {
        return (
            <motion.section
                variants={staggerContainer()}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.25 }}
            >
                <div id={idName}></div>
                <Component lanTrigger={lan} />
            </motion.section>
        )
    }


export default SectionWrapper