import { useEffect, useState } from 'react'
import { faShareNodes, faComments, faHeart, faUsersViewfinder, faPlay, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { CreateComment, GetMetaResource, GetResource, GetUsers } from '../../../../services';
import { selectEmail, selectUserId, selectRole } from '../../../../redux/Reducer/authSlice';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications"
import { selectUserName } from '../../../../redux/Reducer/authSlice';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { API_URL } from '../../../../apiConfig';

type profileprops = {
    profilephoto: string;
    name: string;
    email: String;
    bio: string;
    type: string;
    gender: string;
    country: string;
    username: string;
    contentId: string;
};
type resourceprops = {
    title: string;
    creationDate: Date;
    likes: [];
    tagsToMe: [];
    contentId: string;
    comments: [];
    gender: string;
    country: string;
    sharedTo: [];
};
const Profile = () => {
    const useremail = useSelector(selectUserId);
    const userrole = useSelector(selectRole);
    const [userData, setUserData] = useState<profileprops>();
    const [databyresource, setDatabyresource] = useState<resourceprops[]>([]);
    const [usernameData, setUsernameData] = useState('');
    const [message, setMessage] = useState('');
    const [sortedwithlikeresource, setSortedwithlikeresource] = useState<resourceprops[]>([]);
    const [alertMessage, setAlertMessage] = useState<boolean>();
    const accessTokenUsername = useSelector(selectUserName);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleComing = () => {
        navigate('/comingsoon')
    }
    const handleChangemessage = (event) => {
        const value = event.target.value;
        setMessage(value);
    };
    const submitComment = async (contentId: string) => {
        if (message === '') {
            NotificationManager.warning("Please first you have to write something", "Feedback")
            return;
        }
        const commentData = {
            name: accessTokenUsername,
            resourceId: contentId,
            message: message,
            isPublic: false,
        }
        try {
            let data = await CreateComment.createcomment(commentData)
            if (data) {
                setAlertMessage(false);
                setMessage('');
            }
            else {
                setAlertMessage(true);
                setMessage('');
            }
        } catch (error) {
            console.error('Error during comment:', error);
        }
    }
    const getuserdata = async (useremail) => {
        let data = await GetUsers.getuserdata(useremail)
        console.log(useremail, "useremail", data, "userdata");
        if (data) {
            setUserData(data);
            setUsernameData(data.username);
        }
        else {

        }
    }
    const fetchByResource = async () => {
        let data = await GetMetaResource.getmetaresource();
        console.log('data on resource--', data[0].myResourceTagged.length);
        if (data) {
            setDatabyresource(data);
        }
        else {
            setDatabyresource([]);
        }
    }
    const getresourcesortwithlikes = async () => {
        let data = await GetResource.getresourcesortbylikes();
        if (data) {
            setSortedwithlikeresource(data);
        }
        else {
            setSortedwithlikeresource([]);
        }
    }

    const getEditProfileUrl = (userType) => {
        switch (userType) {
            case 'admin':
                return '/admin/editprofile';
            case 'institute':
                return '/institute/editprofile';
            default:
                return '/home/dashboard/editprofile';
        }
    };


    useEffect(() => {
        getuserdata(useremail);
        fetchByResource();
        getresourcesortwithlikes();

    }, [alertMessage])
    return (
        <div className='w-full h-auto py-[1px] px-[2px]'>

            <div className='lg:px-[5px]' >
                <div className="h-[143px] bg-[#143d78] bg-[url('https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/images/interdesciplinary.png')] w-full">
                </div>
                <div className='bg-white flex items-center justify-between flex-wrap'>
                    <div className='flex flex-col md:flex-row md:justify-between justify-center  items-center w-full'>
                        <div className=''>
                            {userData?.profilephoto ? (
                                <img
                                    src={`${API_URL}/public/images/${userData?.profilephoto}`}
                                    alt={"profile"}
                                    className="relative top-[-45px] ml-[20px] w-[130px] h-[130px] border  border-3 !border-white rounded-full "
                                />
                            ) : (
                                <div className="relative top-[-45px] ml-[20px] w-[130px] h-[130px] border  border-3 !border-white bg-greslernorange rounded-full  flex items-center justify-center text-white text-7xl">
                                    {userData?.name.charAt(0).toUpperCase()}
                                </div>
                            )
                            }
                        </div>
                        <div className='text-center'>
                            <p className=' text-greslernorange text-4xl capitalize font-extrabold'> {userData?.name} </p>
                            <p>  {userData?.email} </p>
                        </div>
                        <div className='py-2'>
                            {/* <button className='lg:hidden p-[5px] border-2 border-solid !border-greslernorange rounded bg-white text-greslernorange mr-[20px] hover:bg-greslernorange hover:!text-white hover:!border-greslernorange'
                            >
                                <a className='hover:!text-greslernorange' href="/home/dashboard/editprofile">
                                    <FontAwesomeIcon icon={faEdit} className='mr-[10px]' />
                                </a> 
                            </button>*/}
                            <button
                                className='p-[5px] border-2 border-solid !border-greslernorange rounded bg-greslernorange text-white mr-[20px] hover:!text-white hover:!bg-white'>
                                <a className='hover:text-greslernorange' href={getEditProfileUrl(userData?.type)}>
                                    <FontAwesomeIcon icon={faEdit} className='mr-[10px]' /> {t("Edit profile")}
                                </a>
                            </button>
                        </div>
                    </div>
                    {/* <div className=''>
                            <button
                                className='p-[5px] border-2 border-solid !border-[#7403c5] rounded bg-white text-[#7403c5] mr-[20px] hover:!text-greslernorange hover:!border-greslernorange'>
                                <a className='hover:!text-greslernorange' href="/home/dashboard/editprofile">
                                    {t("Edit profile")}
                                </a>
                            </button>
                        </div> */}
                </div>
                <div className='border-t-2 border-t-solid !border-t-[#dfe3e8] bg-white rounded-bl-[0.375rem] rounded-br-[0.375rem]'>
                    <ul className='h-[60px] flex list-none ml-[30px]'>
                        <li className={`px-[16px] py-[20px] items-center ${true ? 'bg-transparent border-t-2 border-t-solid !border-t-greslernorange' : ''} hover:bg-transparent  hover:border-t-2 hover:border-t-solid hover:!border-t-greslernorange`}>{t("Overview")}</li>
                        <li className={`px-[16px] py-[20px] items-center ${false ? 'bg-transparent border-t-2 border-t-solid !border-t-greslernorange' : ''} hover:bg-transparent hover:border-t-2 hover:border-t-solid hover:!border-t-greslernorange`}><button onClick={handleComing}>{t("Resources")}</button></li>
                        <li className={`px-[16px] py-[20px] items-center ${false ? 'bg-transparent border-t-2 border-t-solid !border-t-greslernorange' : ''} hover:bg-transparent  hover:border-t-2 hover:border-t-solid hover:!border-t-greslernorange`}><button onClick={handleComing}>{t("Activity")}</button></li>
                    </ul>
                </div>
            </div>
            <div className='py-[15px] px-2 flex flex-col md:flex-row gap-3 space-y-4'>
                {/* ========== Card About me ========= */}
                <div className={` ${userrole != "institute" && userrole != "admin" ? "md:w-2/5 space-y-4" : "md:w-full"}`}>
                    <div className='bg-white rounded-[0.375rem] p-[20px] '>
                        <h2 className=' font-bold capitalize text-[18px] text-greslernorange my-[5px] pb-2'>{t("About Me")}</h2>
                        <h2 className='text-[18px] my-[10px]'>{t("Bio")}</h2>
                        <p className='mt-2 mb-6 text-[#637381]'>
                            {userData?.bio}.
                        </p>
                        <div className='my-[5px]'>
                            <h2 className="text-[18px]">{t("Role")}</h2>
                            <p className='text-[#637381]'>{t("Am a")} <span> {userData?.type}</span> </p>
                        </div>
                        <div className='flex flex-row items-center'>
                            <div className='flex-1/2 grid w-1/2'>
                                <div className='my-2'>
                                    <h2 className="text-[18px]">{t("EMAIL")}</h2>
                                    <p className='text-[#637381]'>{userData?.email}</p>
                                </div>
                                <div className='my-2'>
                                    <h2 className="text-[18px]">{t("User name")}</h2>
                                    <p className='text-[#637381]'>{userData?.username}</p>
                                </div>
                                <div className='my-2'>
                                    <h2 className="text-[18px]">{t("LOCATION")}</h2>
                                    <p className='text-[#637381]'>{userData?.country}</p>
                                </div>
                            </div>
                            {/* <div className='my-2'>
                                        <h2 className="text-[18px]">{t("Gender")}</h2>
                                        <p className='text-[#637381]'>{userData?.gender}</p>
                                    </div> */}

                        </div>
                    </div>
                    {userrole === "institute" ? undefined :
                        <>
                            {/* ========- Project ========= */}
                            {userData?.type != 'admin' &&
                                <div className='bg-white rounded-[0.375rem] p-[20px] '>
                                    <h2 className='text-[18px] text-greslernorange font-bold mb-[10px]'>{t("Latest Resource")}</h2>
                                    {(databyresource.length === 0 || databyresource === null ? (
                                        <div className='flex my-[10px]'>
                                            {/* <div className="'pl-[15px] ml-24' border-white dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transitio rounded-full bg-greslernorange items-center text-white  text-center">
                                        {data.username.charAt(0).toUpperCase()}
                                    </div> */}
                                            {/* <div className='pl-[15px] items-center ml-24'>
                                        <img className='bg-white border border- border-solid !border-[#99b1c7] p-[5px] w-[300px] h-[200px]'
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTINt2JgiE78a2PimWc48hnTgeVR0Dm0m39q4PJssoKOoW0WTT0WKUNmoLUuKmEizmAFWI&usqp=CAU' alt='resource' />
                                    </div> */}
                                        </div>
                                    ) : (
                                        <>
                                            {databyresource.slice(0, 5).map((data, index) => (
                                                <div className='flex justify-between' key={index}>
                                                    <div className='flex my-[10px]'>
                                                        {/* <div className="'pl-[15px] ml-24' border-white dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transitio rounded-full bg-greslernorange items-center text-white  text-center">
                                                        {data.username.charAt(0).toUpperCase()}
                                                    </div> */}
                                                        <div className='pl-[15px]'>
                                                            <h2 className='text-[18px]'>{data.title}</h2>
                                                            <p className='text-greslernorange text-sm'>{t("Posted")} -  {moment(data.creationDate).fromNow()} ...</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        <div className=''>
                                                            <p className="flex -space-x-4">
                                                                <img
                                                                    className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition"
                                                                    src="https://www.shutterstock.com/image-vector/man-icon-vector-260nw-1040084344.jpg"
                                                                    alt="share"
                                                                />
                                                                <button
                                                                    // onClick={() => toggleshareduserslist()}
                                                                    className="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-greslernorange border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition"
                                                                >
                                                                    {data.sharedTo.length}
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ))}
                                </div>
                            }

                        </>}

                </div>
                {/*=============== Post-Team-Activity ==============  */}
                {userData?.type != 'admin' && userData?.type != "institute" &&
                    <div className='md:w-3/5 space-y-4'>
                        {/* ============ Post ============= */}
                        {(databyresource.length === 0 || databyresource === null ? (
                            <div className='bg-white rounded-[0.375rem] p-2'>
                                <div className='flex justify-between items-center'>
                                    <div className='flex mb-[20px] items-center'>
                                        <div className=''>
                                            {userData?.profilephoto ? (
                                                <img
                                                    src={`${API_URL}/public/images/${userData?.profilephoto}`}
                                                    alt={"profile"}
                                                    className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition"
                                                />
                                            ) :
                                                (
                                                    <div className=" w-10 h-10 border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition bg-greslernorange flex justify-center items-center text-white  text-center">
                                                        {userData?.name.charAt(0).toUpperCase()}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className=''>
                                            <p className='text-[13px] pl-[15px]'> <b>{t("Last post")}</b> <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-[15px]'>
                                    <p className='text-#637381'>{t("No more resource")}</p>
                                </div>
                                <div className='py-[15px]'>
                                </div>
                                <hr />
                                <div className='flex items-center my-[20px]'>
                                    <div className='flex-1/4 grid'>
                                        <img
                                            className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition"
                                            src="/profile.png"
                                            alt=""
                                        />                                </div>
                                    <div className='flex-[75%] flex items-center'>
                                        <p className='ml-2'>{t("Resource")}</p>
                                        <input className='ml-[75px] mr-[20px] p-[10px] rounded-[0rem] border-none bg-[#ffffff]' type='search' />
                                        <button className='p-[10px] w-[65px] bg-greslernorange text-white rounded-[0.375rem] border-none'><a href="/home/dashboard/new/65200bbb39f8b39817b57745">{t("Post")}</a> </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {databyresource.slice(0, 1).map((item, index) => (
                                    <div className='bg-white rounded-[0.375rem] p-2 ' key={userData?.username}>
                                        <div className='flex justify-between'>
                                            <div className='flex mb-[20px]'>
                                                <div className=''>
                                                    {userData?.profilephoto ? (
                                                        <img
                                                            src={`${API_URL}/public/images/${userData?.profilephoto}`}
                                                            alt={"profile"}
                                                            className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition"
                                                        />
                                                    ) : (
                                                        // <Avatar size='2xs' name={data.name}
                                                        //     className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition"
                                                        // />

                                                        <div className="pt-2 w-10 h-10 border-2 border-white dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transitio rounded-full bg-greslernorange items-center text-white  text-center">
                                                            {userData?.username.charAt(0).toUpperCase()}
                                                        </div>
                                                    )
                                                    }

                                                </div>
                                                <div className=''>
                                                    <p className='text-[13px] pl-[15px]'> <b>{t("Last post")}</b> <br />
                                                        {moment(item.creationDate).fromNow()}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-[15px]'>
                                            <p className='text-#637381'>{item.title}.</p>
                                        </div>
                                        <div className='py-[15px]'>
                                            <div className=''>
                                                <ul className='flex list-none'>
                                                    <li className='mr-[30px] active' >
                                                        <FontAwesomeIcon icon={faHeart} className='mr-[10px]' />
                                                        <a href={`/home/dashboard/play/${item.contentId}`}>{item.likes?.length} {t("Likes")} </a>
                                                    </li>
                                                    <li className='mr-[30px]'>
                                                        <FontAwesomeIcon icon={faComments} className='mr-[10px]' />
                                                        <a href={`/home/dashboard/play/${item.contentId}`}>{item.comments?.length} </a>
                                                        {t("Comments")}
                                                    </li>
                                                    <li className='mr-[30px]'>
                                                        <FontAwesomeIcon icon={faShareNodes} className='mr-[10px]' />
                                                        <a href={`/home/dashboard/play/${item.contentId}`}>{item.sharedTo?.length} {t("share")}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <hr className='text-greslernorange' />
                                        <div className='flex flex-col md:flex-row items-center my-[20px]'>

                                            <img
                                                className="w-10 h-10 border-2 border-greslernorange rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition"
                                                src="/profile.png"
                                                alt=""
                                            />
                                            <div className='flex  flex-col md:flex-row items-center '>
                                                <p className='ml-2'>{t("Comment")}</p>
                                                <input
                                                    value={message}
                                                    required
                                                    onChange={handleChangemessage}
                                                    className='m-1 p-[10px] rounded-[0.375rem] border-none bg-[#ebeaf7]' type='search' />
                                                <button
                                                    onClick={() => submitComment(item.contentId)}
                                                    className='p-[10px] w-[65px] border-2 !border-greslernorange  bg-greslernorange text-white  hover:!text-greslernorange hover:bg-white rounded-[0.375rem] border-none'> {t("Post")}</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ))}
                        {/* ========== team-Activity ============== */}

                        <div className=' rounded-[0.375rem] space-y-4'>
                            {/* =========== Activity ============== */}
                            {userrole === "institute" ? undefined :
                                <>
                                    {/* ========= Team ============= */}
                                    <div className='bg-white py-[20px] px-2 rounded-[0.375rem] '>
                                        <h2 className='mb-[10px] text-greslernorange font-bold text-[18px]'>{t("More likes")}</h2>
                                        {sortedwithlikeresource.slice(0, 4).map((newitems, index) => (

                                            <div className='flex justify-between' key={index}>
                                                <div className='flex my-[10px]'>
                                                    <div className=''>
                                                    </div>
                                                    <div className='pl-[15px]'>
                                                        <h2 className='text-[18px]'>{newitems.title} ({newitems.likes.length} {t("likes")})</h2>
                                                        <p className='text-greslernorange text-sm'> {t("Posted date")} - {moment(newitems.creationDate).fromNow()}</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center'>
                                                    <div className='pr-[10px]'>
                                                        <a className='text-greslernorange hover:text-greslernblue hover:scale-150 duration-200 ease-in-out transition ' href={`home/dashboard/play/${newitems.contentId}`}>
                                                            <FontAwesomeIcon icon={faUsersViewfinder} />
                                                        </a>
                                                    </div>

                                                    <div className="pl-[5px]">
                                                        <a className='text-greslernorange hover:text-greslernblue hover:scale-150 duration-200 ease-in-out transition ' href={`/home/dashboard/play/${newitems.contentId}`}>
                                                            <FontAwesomeIcon icon={faPlay} />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                    <div className='bg-white rounded-[0.375rem] px-2 py-[20px]'>
                                        <p className='mb-[10px] text-greslernorange font-bold text-[18px]'>{t("Tags")}</p>
                                        {sortedwithlikeresource.slice(0, 2).map((newitems, index) => (
                                            <div className='flex justify-between' key={index}>
                                                <div className='flex my-[10px]'>
                                                    <div className=''>
                                                    </div>
                                                    <div className='pl-[15px]'>
                                                        <h2 className='text-[18px]'>{newitems.title}</h2>
                                                        <p className='text-greslernorange text-sm'>{t("Taged date")} - {moment(newitems.creationDate).fromNow()}</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center'>
                                                    <div className='pr-[10px]'>
                                                        <a className='text-greslernorange hover:text-greslernblue hover:scale-150 duration-200 ease-in-out transition ' href={`home/dashboard/play/${newitems.contentId}`}>
                                                            <FontAwesomeIcon icon={faUsersViewfinder} />
                                                        </a>
                                                    </div>

                                                    <div className="pl-[5px]">
                                                        <a className='text-greslernorange hover:text-greslernblue hover:scale-150 duration-200 ease-in-out transition ' href={`/home/dashboard/play/${newitems.contentId}`}>
                                                            <FontAwesomeIcon icon={faPlay} />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                        {/* <div className='flex justify-between'>
                                    <div className='flex my-[10px]'>
                                        <div className=''>
                                            <img
                                                className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition"
                                                src="/profile.png"
                                                alt=""
                                            />
                                        </div>
                                        <div className='pl-[15px]'>
                                            <h2 className='text-[18px]'>New user</h2>
                                            <p className='text-[15px] text-[#637381]'>User role - Student  ...</p>
                                            <p>Member since 2023</p>
                                        </div>
                                    </div>
                                </div> */}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Profile;