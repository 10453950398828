
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { NotificationItem } from './addon/NotificationItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faComment, faEnvelope, faEnvelopeOpen, faEraser, faRankingStar, faShareAlt, faTag, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import ToolTip from '../tooltip';
import { toast } from 'react-toastify';
import { toastOpt } from '../../config';
import { updateLikes, updateComments, updateTags, updateShares } from '../../redux/Reducer/socketSlice'
import { GetNotification } from '../../services';
interface INotification {
  id: string,
  userId?: string;
  userImg?: string;
  userName?: string;
  type?: string;
  seen?: boolean;
  clear?: boolean;
  resourceId?: string;
  resourceName?: string;
  message?: string;
  date?: string;
}

export default function NotificationList() {
  const { t } = useTranslation()
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [notificationsBk, setNotificationsBk] = useState<INotification[]>([]);

  const dispatch = useDispatch()
  let commentNo = 0
  let sharedNo = 0
  let tagNo = 0
  let likeNo = 0

  const getnotificationList = async () => {
    commentNo = 0
    sharedNo = 0
    tagNo = 0
    likeNo = 0
    let data = await GetNotification.getAll();
    // alert(JSON.stringify(data))
    if (data) {
      setNotificationsBk(data)
      setNotifications(data);
      data.map(notification => {
        if (notification.type === 'comment' && !notification.clear) {
          return commentNo++;
        }
        if (notification.type === 'share' && !notification.clear) {
          return sharedNo++;
        }
        if (notification.type === 'tag' && !notification.clear) {
          return tagNo++;
        }
        if (notification.type === 'like' && !notification.clear) {
          return likeNo++;
        }

      }).then(() => updateCounters());

      setNotifications(data);
    }

  }

  useEffect(() => {
    getnotificationList()
  }, [])
  const updateCounters = () => {
    dispatch(updateComments(commentNo))
    dispatch(updateShares(sharedNo))
    dispatch(updateTags(tagNo))
    dispatch(updateLikes(likeNo))
  }

  const clearAll = async () => {
    let data = await GetNotification.clearAll()
    if (data) {
      toast.success("Notifications Cleared", toastOpt)
      getnotificationList()
    }
  }
  const MarkAllAsRead = async () => {
    let data = await GetNotification.markAllSeen()
    if (data.acknowledged) {
      toast.success("Notifications marked as read", toastOpt)
      getnotificationList()
    }
  }

  const notificationFilter = (filter = "") => {
    setNotifications(notificationsBk)
    if (filter === "") {
      setNotifications(notificationsBk)
      return
    }

    if (filter == "all") {
      setNotifications(notificationsBk)
    } else {
      const noti = notificationsBk
      const filteredData = noti?.filter((content) => {
        return Object.values(content)
          .join("")
          .toLowerCase()
          .includes(filter.toLowerCase());
      });
      setNotifications(filteredData);
    }

  };

  return <div>
    <div className=' overflow-hidden inline-block absolute w-11 -top-5 right-2'>
      <div className='h-5 w-5 bg-gray-900 bg-opacity-60 rotate-45 transform origin-bottom-left rounded-tl-sm '></div>

    </div>
    {/* <div className=' absolute w-6 h-6 -top-3 right-3 -z-1 rotate-45 bg-gray-900 bg-opacity-60 '> */}
    {/* <FontAwesomeIcon icon={faTriangleCircleSquare} /> */}
    {/* </div> */}
    <div className="flex justify-between px-2 pt-2 z-1">
      <div className="text-gray-700 font-semibold text-white ">
        <FontAwesomeIcon icon={faBell} /> ({notifications.length}){" "}
      </div>
      <div className="last text-gray-700 mb-1 text-s cursor-pointer hove:text-greslernorange hover:transform bg-white/50 rounded-lg px-3">
        <button className="text-red hover:text-greslernorang focus:bg-white" onClick={() => notificationFilter("like")}>
          <FontAwesomeIcon icon={faThumbsUp} className=" px-1" />
        </button>
        <button className="text-green hover:text-greslernorange focus:bg-white/60" onClick={() => notificationFilter("share")}>
          <FontAwesomeIcon icon={faShareAlt} className=" px-1" />
        </button>
        <button className="!text-greslernblue hover:text-greslernorange focus:bg-white/60" onClick={() => notificationFilter("comment")}>
          <FontAwesomeIcon icon={faComment} className=" px-1" />
        </button>
        <button className="!text-brown hover:text-greslernorange focus:bg-white/60" onClick={() => notificationFilter("review")}>
          <FontAwesomeIcon icon={faRankingStar} className=" px-1" color='yellow' />
        </button>
        <button className="!text-brown hover:text-greslernorange focus:bg-white/60" onClick={() => notificationFilter("tag")}>
          <FontAwesomeIcon icon={faTag} className=" px-1" color='brown' />
        </button>
        {/* <button className="!text-gray hover:text-greslernorange" onClick={() => notificationFilter("unseen")}>
            <FontAwesomeIcon icon={faEnvelopeOpen} className=" px-1" />
          </button>
          <button className="!text-gray hover:text-greslernorange" onClick={() => notificationFilter("unseen")}>
            <FontAwesomeIcon icon={faEnvelope} className=" px-1" />
          </button> */}
        <button className="!text-gray hover:text-greslernorange focus:bg-white/60" onClick={() => notificationFilter("all")}>
          <FontAwesomeIcon icon={faEnvelope} className=" px-1" /> All
        </button>
      </div>
    </div>

    <div className="relative px-2 ">
      {/* <div className=" !min-w-full bg-white/30 text-xs">
        <FontAwesomeIcon icon={faEnvelope} className=" px-1" /> unseen{" "}
      </div> */}

      <div className=" w-auto mx-auto items-center min-h-[5em] max-h-[15em] overflow-x-hidden overflow-y-auto scroll-smooth hideScrollbar  ">
        {notifications.map((notification, index) =>
          <NotificationItem key={index} notification={notification} />
        )}
      </div>
      {/* <div className=" !min-w-full bg-white/30 text-xs">
          <FontAwesomeIcon icon={faEnvelopeOpen} className=" px-1" /> unseen{" "}
        </div>
        <div className=" w-auto mx-auto items-center min-h-[5em] max-h-[8em] overflow-x-hidden overflow-y-auto scroll-smooth  ">
          {notifications.map((notification, index) =>
            <NotificationItem key={index} notification={notification} />
          )}
        </div> */}
    </div>
    <div className=" flex flex-row-reverse gap-3 text-sm p-1 last text-gray-700 font-semibold text-s cursor-pointer hove:text-greslernorange hover:transform ">
      <button className="text-red hover:text-greslernorange" onClick={clearAll}>
        <FontAwesomeIcon icon={faEraser} className=" px-1" />
        {t("clear")}
      </button>
      <div className="text-white hover:text-greslernorange">
        <ToolTip tooltip='Mark All As read'>
          <button onClick={MarkAllAsRead}>
            <FontAwesomeIcon icon={faEnvelopeOpen} className=" px-1" />
            {t("all")}
          </button>
        </ToolTip>
      </div>


    </div>
  </div>;
}
