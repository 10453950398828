import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,SubTitle, Title
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend,SubTitle, Title);


export function MembersRadialLinear({activeMembers, pendingMembers, deactiveMembers}) {
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'USER STATUS SUMMARY',
        position:'bottom',
      },
      subtitle: {
        display: true,
        text: 'members invitaion and acivated inviations',
        color:'blue',
        position:'bottom',
      },
      legend: {
        display: true,
        position: 'top'

    }

    }
}
  const data = {
  labels: ['active', 'deactive', 'pending'],
  datasets: [
    {
      label: '# of members',
      data: [activeMembers, deactiveMembers, pendingMembers],
      backgroundColor: [
        'rgba(75, 192, 192, 0.5)',
        'rgba(255, 99, 132, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        // 'rgba(54, 162, 235, 0.5)',
        // 'rgba(153, 102, 255, 0.5)',
        // 'rgba(255, 159, 64, 0.5)',
      ],
      borderWidth: 1,
    },
  ],
};
  return <PolarArea data={data} options={options} />;
}