import { H5PEditorUI } from "@lumieducation/h5p-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  ContentService,
} from "../../../services/ContentService";
import FolderExplorerService from "../../../services/FolderExplorerService";
import { toast } from "react-toastify";
import ContentHeader from "../../root/dashboard/addon/contentHeader";
import { useTranslation } from "react-i18next";
import Loader from "../../loader";
import ContentSettingService from "../../../services/ContentSettingService";
import ModalComponent from "../../ModalComponent";
import ContentCard from "../ContentCard";

import EditorMenu from "../addon/EditorMenu";
import TagSelectorComponent from "../../TagSelectorComponent";
import TagsAndFilters from "../../../services/TagsAndFilters";

const ContentNew = () => {
  const { folderId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const h5pEditor = useRef<H5PEditorUI>(null);
  const contentService = new ContentService();

  const [folderToSave, setFolderToSave] = useState(folderId || "");
  const [canSave, setCanSave] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activityIndicator, setActivityIndicator] = useState(false)
  const [beginner, setBeginner] = useState(true)
  const [items, setItems] = useState([]);
  const [templates, setTemplates] = useState(false)
  const [templateContents, setTemplateContents] = useState([])
  const [chatMode, setChatMode] = useState(true)
  const [actvitySaveExit, setActivityIndicatorExit] = useState(false)
  const [newItemTags, setNewItemTags] = useState<string[]>([]);

  const setFolder = (folder) => {
    setFolderToSave(folder);
  };

  const handleTemplate = async () => {
    console.log("TS handleTemplate")

    const t = await ContentSettingService.getAllTemplates()
    if (t) {

      setTemplateContents(t)
      setTemplates(!templates)
    }

  }
  const onSaved = async (event) => {
    setCanSave(true);
  };
  const onEditorLoaded = async (event) => {
    setCanSave(true);
    setIsLoading(false)
    setTimeout(() => { }, 3000);
  };
  const onSaveError = async (event) => {
    // setCanSave(false)
  };
  const setEditorMode = () => {
    const iframeElement = document.querySelector('.h5p-editor-iframe');

    if (iframeElement) {

      const iframeDocument = iframeElement.contentDocument || iframeElement.contentWindow.document;
      // removing basic functionality
      if (beginner) {
        iframeDocument.getElementsByClassName('field-name-override')[0].style.display = "none"
        iframeDocument.getElementsByClassName('common')[0].style.display = "none"
      } else {
        iframeDocument.getElementsByClassName('field-name-override')[0].style.display = "block"
        iframeDocument.getElementsByClassName('common')[0].style.display = "block"
      }

      const ulElement = iframeDocument.getElementsByClassName('h5p-dragnbar-ul')[1];

      if (ulElement) {
        let liElements = Array.from(ulElement.children);
        setItems(liElements)
        liElements.forEach(function (liElement) {
          ulElement.removeChild(liElement);
        });
        let beginnerElements = [
          "Open Ended Question",
          "Text",
          // "Link",
          "Image",
          // "Shapes",
          "Go to slide",
          "Video",
          // "Audio",
          // "Fill in the Blanks",
          // "Single Choice Set",
          // "h5p-flashcards",
          // "Multiple Choice",
          // "True/False Question",
          // "Drag and Drop",
          // "SummaryDrag the WordsMark the WordsDialog CardsContinuous TextExportable Text AreaTableInteractive VideoAudio RecorderImage Choice",
          "Paste"
        ];

        // Filter the liElements based on the idOrder array
        liElements = liElements.filter(function (liElement) {
          let outerText = liElement.outerText;
          return beginnerElements.includes(outerText);
        });

        // Append the filtered list items back to the olElement
        beginner ?
          liElements.forEach(function (liElement) {
            ulElement.appendChild(liElement);
          })

          : items.forEach(function (liElement) {
            ulElement.appendChild(liElement);
          })
      }


    }

    setBeginner(!beginner)
  }
  const save = async (type = 'normal') => {

    setCanSave(true);
    setActivityIndicator(true);
    try {
      const returnData = await h5pEditor.current?.save();
      if (returnData) {
        let status;
        await handleAddNewTag(newItemTags, returnData.contentId);
        console.log(`New tags ${newItemTags} saved `);
        if (folderToSave != "Root") {
          status = await FolderExplorerService.moveFileToFolder(
            returnData.contentId,
            folderToSave
          );
        } else {
          const folder = await FolderExplorerService.createFolder(null, "Root");
          status = await FolderExplorerService.moveFileToFolder(
            returnData.contentId,
            folder.id
          );
        }
        if (status) {
          // set template flag
          if (type == "template") {

            const saved = await ContentSettingService.setAs('template',returnData.contentId)
            // alert("TS: " + JSON.stringify(saved))
          }
          if (type == "guide") {

            const saved = await ContentSettingService.setAs('guide',returnData.contentId)
            // alert("TS: " + JSON.stringify(saved))
          }
          if (type == "walkthrough") {

            const saved = await ContentSettingService.setAs('walkthrough',returnData.contentId)
            // alert("TS: " + JSON.stringify(saved))
          }

          const saved = await ContentSettingService.setResourceChatSetting(returnData.contentId, chatMode)


          setFolderToSave("");
          setActivityIndicator(false)
          toast.success(`Content ${returnData.metadata.title} saved `);
          // setTimeout(() => { navigate(-2); }, 50);
        } else {
          setActivityIndicator(false);
          toast.error(`Content not saved to folder`);
        }
      } else {
        setActivityIndicator(false)
        toast.warning(`Content not saved!`);
      }
    } catch (error) {
      setActivityIndicator(false)
      toast.error(`Content error while saving`);
    }
  };
  const saveExit = async () => {
    setActivityIndicatorExit(true)
    await save();
    setTimeout(() => {
      setActivityIndicatorExit(false)
      navigate(-2);
    }, 50);
  };

  const handleAddNewTag = async (newTagName: string[], resourceId: string) => {
    try {
      await TagsAndFilters.addTags(newTagName, resourceId);
    } catch (error) {
      console.error('Error adding new tag', error);
    }
  };
  useEffect(() => {
    // setMeta()
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, [newItemTags]);

  return (
      <div className="flex-1 px-2 sm:px-0 ">

        <div className="flex flex-wrap justify-between items-center">
          <ContentHeader title={t("New Content")}></ContentHeader>

          <EditorMenu
            canSave={canSave}
            folderId={folderId}
            setFolder={setFolder}
            activityIndicator={activityIndicator}
            templates={templates}
            handleTemplate={handleTemplate}
            save={save}
            beginner={beginner}
            chatMode={chatMode}
            setChatMode={setChatMode}
            setEditorMode={setEditorMode}
            saveExit={saveExit}
            actvitySaveExit={actvitySaveExit} />
        </div>
        <div className="flex flex-wrap justify-between items-center">
          {canSave ? <TagSelectorComponent selectedTags={newItemTags} setSelectedTags={setNewItemTags} /> : undefined}
        </div>

        <div className=" flex pt-2 justify-center  ">

          <div className="w-5/6">
            <H5PEditorUI
              ref={h5pEditor}
              contentId={"new"}
              loadContentCallback={contentService.getEdit}
              onLoaded={onEditorLoaded}
              saveContentCallback={contentService.save}
              onSaved={onSaved}
              onSaveError={onSaveError}
            />
          </div>

        </div>
        {isLoading && <Loader />}
      </div>
  );
};

export default ContentNew;