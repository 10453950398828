import CardOne from './components/CardOne';
import ContentHeader from '../root/dashboard/addon/contentHeader'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserName, selectInstitute, selectIsFirstLog } from '../../redux/Reducer/authSlice';
import Analisis from './components/ReportAnalisis/Analisis';
import UsesTable from './components/tables/UsesTable';
import ResourcesTable from './components/tables/ResourcesTable';
import { useEffect, useState } from 'react';
import { FrequentService } from '../../services';
import { faBook, faDashboard, faHistory, faPlusSquare, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import RegisterMember from './components/RegisterMember';
import Congrat from './components/Congrat';
import Announcement from './components/Announcement ';
import AnnouncementService from '../../services/AnnouncementService';
import NoData from './components/NoData';
const Dashboard = () => {
  const { t } = useTranslation()
  const [listUsedLink, setListUsedLink] = useState([]);
  const [announcement, setAnnouncement] = useState([]);
  const [showRegistration, setShowRegistration] = useState(false);
  const [refetchAnnaouncementI, setRefetchAnnaouncementI] = useState<boolean>(false);
  const username = useSelector(selectUserName)
  const instituteId = useSelector(selectInstitute)
  const isFirstLog = useSelector(selectIsFirstLog)
  const handleAddStudent = () => {
    setShowRegistration(!showRegistration)
  }
  const fetchUsedLink = async () => {
    const data = await FrequentService.getFrequent();
    if (data) { setListUsedLink(data); }
  }
  const reFetchAnnouncement = () => { setRefetchAnnaouncementI(!refetchAnnaouncementI); }
  const fetchAnnouncement = async () => {
    const data = await AnnouncementService.getAnnouncement(instituteId);
    if (data) { setAnnouncement(data[0].announcements); }
    else { setAnnouncement([]); }
  }
  useEffect(() => {
    fetchUsedLink();
    fetchAnnouncement();
  }, [refetchAnnaouncementI]);
  return (
    <>
      {showRegistration && <RegisterMember onUpdate={reFetchAnnouncement} />}
      {isFirstLog && <Congrat />}
      <ContentHeader title={t("Institution area") + " - " + t("welcome ") + username} showIcon="false" />
      {announcement?.length > 0 && <Announcement announcementList={announcement} onUpdate={reFetchAnnouncement} />}
      <div className="flex flex-col md:flex-row gap-1 shrink  w-full">
        <CardOne />
      </div>
      <div className="flex flex-col md:flex-row gap-1 shrink  w-full pt-4">
        <div className='w-3/4'>
          <Analisis />
        </div>
        <div className='flex flex-col justify-center items-center w-1/4'>
          <h2 className='w-full text-center text-white text-lg font-bold uppercase'>{t("shortcuts")}</h2>
          {listUsedLink?.length <= 0 ?
            <div><NoData /></div>
            :
            <div className='grid grid-cols-2 border-2 border-white rounded-lg'>
              {listUsedLink?.map((usedlink: any) =>
                // {usedlink === }
                <Link to={`/institute/${usedlink._id}`} key={usedlink._id}>
                  <div className="transition ease-in-out hover:scale-125 resource flex flex-col justify-center items-center text-greslernorange text-lg p-2 bg-white rounded-lg m-2">
                    {usedlink._id === "resource" && <FontAwesomeIcon icon={faBook} size='4x' />}
                    {usedlink._id === "team" && <FontAwesomeIcon icon={faPlusSquare} size='4x' />}
                    {usedlink._id === "report" && <FontAwesomeIcon icon={faDashboard} size='4x' />}
                    {usedlink._id === "profile" && <FontAwesomeIcon icon={faUsers} size='4x' />}
                    {usedlink._id === "activity" && <FontAwesomeIcon icon={faHistory} size='4x' />}
                    <span>{`${t(usedlink._id)}`}</span>
                  </div>
                </Link>
              )}
              <button className="transition ease-in-out hover:scale-125 addstudent flex flex-col justify-center items-center text-greslernorange text-lg p-2 bg-white rounded-lg m-2 "
                onClick={handleAddStudent}>
                <FontAwesomeIcon icon={faUserPlus} size='4x' />
                <span> {t("Member")}</span>
              </button>
            </div>
          }
        </div>
      </div>
      <div className="mt-2">
        <UsesTable status={1} />
      </div>
      <div className="gap-1 shrink  w-full">
        <ResourcesTable status={1} />
      </div>
    </>
  );
};

export default Dashboard;
