import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons'
import { Spin } from 'react-cssfx-loading'
import { useDispatch } from 'react-redux'

import { ContentService } from '../../../services'
import { GetUserLogin } from '../../../services';
import ModalComponent from '../../ModalComponent'
import { setUsername } from '../../../redux/Reducer/authSlice'

interface LoginCredentials {
    email: string;
    password: string;
}

function LoginForm({ handleGoLeftClick }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [contentService,] = useState(new ContentService('/h5p'));
    const [credentials, setCredentials] = useState<LoginCredentials>({ email: "", password: "" })
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reactive, setReactive] = useState(false);

    const handleLogin = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const { email, password } = credentials
        let response = await GetUserLogin.getUserLogin(email, password)
        setLoading(true);
        if (response?.status === 200) {
            dispatch(setUsername(''))
            GetUserLogin.authenticate(response.data, dispatch, () => {
                contentService.setCsrfToken(response?.data.csrfToken);
                NotificationManager.success('User LogIn Successfull', 'LogIn');
                setLoading(false);
            })
        }
        else if (response === undefined) {
            setReactive(true);
            setLoading(false);
        }
        else {
            contentService.setCsrfToken(undefined);
            setLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const closeModal = () => {
        // handleClose()
        setReactive(false);
    };

    return (
        <>
            <div className='relative w-[400px] flex justify-center items-center rounded-[20px] bg-box animate-animate3 box-form h-auto py-[20px] before:content before:absolute before:w-full before:h-full before:bg-box-before before:animate-animate3 before:rounded-[20px] before:animation-delay-1000 after:content after:absolute after:inset-[8px] after:bg-g-blue after:bg-noise  after:rounded-[15px] after:border-4 after:border-transparent'>
                <form
                    onSubmit={handleLogin}
                    className='relative w-full flex justify-center items-center flex-col gap-[25px] py-0 px-[40px] z-[10]'>
                    <h2 className='text-white font-semibold'>{t("SignIn")}</h2>
                    <input
                        id="email"
                        name='email'
                        type="email"
                        onChange={(e) => handleChange(e)}
                        required
                        placeholder={t("Email")}
                        className="username relative w-[85%] py-[10px] px-[15px] border !border-greslernorange outline-none rounded-[5px] text-white text-base tracking-wider bg-gresLightOrange placeholder:text-[#dfdfdfe6]"
                    />
                    <input
                        id="password"
                        name='password'
                        type={showPassword ? "text" : "password"}
                        placeholder={t("Password")}
                        onChange={(e) => handleChange(e)}
                        required
                        className="password relative w-[85%] py-[10px] px-[15px] border !border-greslernorange outline-none rounded-[5px] text-white text-base tracking-wider bg-gresLightOrange placeholder:text-[#dfdfdfe6]"
                    />
                    <button
                        className="absolute top-[7.6rem] right-[4rem] flex justify-center items-center w-[50px] h-[27px] rounded-[10px] border-none outline-none bg-transparent text-greslernorange"
                        type='button'
                        onClick={handleShowPassword}
                    >
                        <FontAwesomeIcon icon={!showPassword ? faEyeLowVision : faEye} />
                    </button>
                    <button
                        className={`relative flex justify-center  mt-4 w-[85%] py-[10px] px-[15px] rounded-[5px] text-base tracking-wider transition-[0.5s] cursor-pointer 
                        ${loading
                                ? 'bg-[#2d2d39] text-white pointer-events-none'
                                : 'bg-white text-[#2d2d39] hover:!bg-[#243946] hover:text-white'}
                        `}
                        type="submit"
                        disabled={loading}
                    >
                        {loading ?
                            (<Spin color="#ffffff" width="30px" height="30px" duration="1s" />)
                            :
                            (t("SignIn"))
                        }
                    </button>
                    <div className='w-[85%] flex justify-end'>
                        <a href='/forgetpassword' className='text-[#ffffffbf] decoreation-0 hover:text-[#B75F2E]'>{t("Forget")}{' '}{t("Password")}?</a>
                    </div>
                </form>
            </div>
            {/* Form End  */}

            {/* Footer Start   */}
            {/* <div className="text-center mt-[15px] text-white text-[1rem] font-sans">
                <p>
                    {t("Donthaveanaccount")}
                    <a className="text-[#243946] text-lg font-bold decoration-0 transition-all duration-200 ease-in-out delay-100 ml-1 hover:text-white hover:decoration-1 cursor-pointer" onClick={handleGoLeftClick}>{t("Signup")}</a>
                </p>
            </div> */}
            {reactive ?
                (
                    <ModalComponent isOpen={reactive} onClose={closeModal} title={false}>
                        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <div className="relative transform overflow-hidden rounded-lg bg-gray-200 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-gray-200 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                    </svg>
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">{t("Deactivate account")}</h3>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-black">{t("Are you sure you want to reacover your account? If yes please contact our support team")}.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">
                                                <a href='/contactus' className='text-black decoreation-0 hover:text-[#B75F2E]'>{t("Go to Contact")}</a>
                                            </button>
                                            <button type="button" onClick={closeModal} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">{t("Ignore")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <h5 className='text-red-400 text-center'>{t("If you want to reacover")}</h5>
                        <br />
                        <h5 className='text-red-400 ml-10'>{t("If you want to reacover please contact our support team")}</h5>
                        <div className="flex-row">
                            <div className="mt-3 flex sm:flex-row-reverse  sm:px-6 row-start-2"> */}
                        {/* <button
                                    type="button"
                                    className=" mx-2 inline-flex w-full justify-center rounded-md bg-blue-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    <a href='/reactivate' className='text-black decoreation-0 hover:text-[#B75F2E]'>{t("Reactivate")}</a>
                                </button>
                                <button
                                    type="button"
                                    className=" inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={closeModal}
                                >
                                    {t("Ignore")}
                                </button> */}
                        {/* </div>
                        </div> */}
                    </ModalComponent>
                )
                :
                undefined
            }
        </>
    )
}

export default LoginForm