import { Apis } from "../../config";
import api from "../../apiConfig";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const getAllCommentlist = async () => {
    try {
        let result = await api.get(Apis.GetAllCommentList)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getcommentlist = async (resourceId: any) => {
    try {
        let result = await api.get(Apis.GetCommentList + resourceId)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getcommentcount = async (resourceId: any) => {
    try {
        let result = await api.get(Apis.CommentCounter + resourceId)
        if (result.status !== 200) {
            return null;
        }
        return result.data
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const createcomment = async (commentData: any) => {
    try {
        const response = await api.post(Apis.CreateComment, commentData);
        console.log('response', response);
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const getallcommentcount = async () => {
    try {
        let response = await api.get(Apis.CountAllComment)
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
const countListSharedWithMe = async () => {
    try {
        const res = await api.get(`${Apis.GetH5P}/listshared`);
        if (res.status === 200) {
            return res.data;
        } else if (res.status === 401) {
            console.log('redirection login called');

        }
        else {
            throw new Error(`${res.status} ${res.statusText}`);
        }
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
}
const createCommentReply = async (commentId, commentData: any) => {
    try {
        const response = await api.patch(Apis.CreateCommentReply + commentId, commentData);
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error) {
        AxiosErrorHandling(error)
        throw error;
    }
};
export default {
    getAllCommentlist,
    getcommentlist,
    getcommentcount,
    createcomment,
    getallcommentcount,
    countListSharedWithMe,
    createCommentReply
}