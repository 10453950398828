import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noRecord from "../assets/data.png";
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const NoRecordFound = () => {
  const { t } = useTranslation();
  return (
    <div className="col-span-full flex flex-col text-[16px] justify-center items-center gap-2 my-2">
      <div className='flex w-full justify-center p-2 flex-row bg-greslernorange/20'>

        <h1 className=' uppercase text-[1000%] font-bold text-white align-bottom flex pt-2  h-full '> no</h1>
        <img src={noRecord} alt="" className=" w-[40%] h-auto pb-4" />
      </div>
      <p className="text-orange flex gap-1 items-center">
        <FontAwesomeIcon
          icon={faFrown}
          className="me-1"
        />
        {t("sorry there is no data")}
      </p>
    </div>
  );
};

export default NoRecordFound;
