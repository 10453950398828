import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NoData() {
  const {t} = useTranslation()
  return (
    <div className="w-full h-auto">
      <h4 className='font-light py-4 px-2 text-center'>{t("Nothing to show")}</h4>
    </div>
  );
}
