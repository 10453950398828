import {
  faBook,
  faClone,
  faClose,
  faDownload,
  faEdit,
  faEllipsisH,
  faMinus,
  faShare,
  faShareAlt,
  faSquareXmark,
  faTrash,
  faTrashRestoreAlt,
  faUserPen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import { selectEmail } from "../../../redux/Reducer/authSlice";
import {
  ContentService, GetResourceShared
} from "../../../services";
import GetUserList from "../../../services/GetUserList";
import SearchUserModal from "../../SearchUserModal";
import SharedUsersList from "../../SharedUsersList";
import ToolTip from "../../tooltip";
import { toastOpt } from "../../../config";
import FolderExplorerService from "../../../services/FolderExplorerService";

const bieApprovedStamp = "https://greslern-bucket-public.s3.eu-west-2.amazonaws.com/images/bie-compliant-stamp.png";

interface ContentCard {
  content: any;
  setCurrentContentId?: any;
  updateFolder?: any;
  displayToggle?: boolean;
  contentType: any;
}

const ContentCard = ({
  content,
  setCurrentContentId,
  updateFolder,
  displayToggle = true,
  contentType,
}: ContentCard) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isPublicChecked, setIsPublicChecked] = useState(false);
  const [isTemplate] = useState(false)
  const [shareduserslist, setShareduserslist] = useState(false);
  const [searchUserModal, setSearchUserModal] = useState(false);
  const [countShare, setCountShare] = useState([]);
  const [data, setData] = useState<any>();


  const [status, setStatus] = useState();

  const [contentPrivilege, setContentPrivilege] = useState({
    edit: false,
    delete: false,
    share: false,
    toggleShare: false,
    download: false,
    clone: false,
    seeSharedUsers: false,
    remove: false,
    restoreDelete: false,
    permanentDelete: false,
  });

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  const handleContentDragStart = (e, itemId) => {
    setCurrentContentId(itemId);
  };
  const handleContentDragEnd = (e) => {
    e.preventDefault();
    setCurrentContentId("");
  };
  const handleContentDragOver = (e) => {
    e.preventDefault();
  };

  // setCountshare // Author muluken Teju
  const contentService = new ContentService();

  const handlePublicChange = async (
    contentId: string,
    isPublicChecked: boolean
  ) => {
    isPublicChecked ?
      navigate("/home/dashboard/BIEassesment", {
        state: { contentId: contentId, isPublicChecked: isPublicChecked }
      })
      : handlePublish(contentId, isPublicChecked);
  };
  const handlePublish = async (contentId: string, isPublicChecked: boolean) => {

    try {
      const isValidEmail = (email === process.env.REACT_APP_BIE_MAIL);
      console.log('isPublicChecked ', isPublicChecked)
      let newStatus;
      if (isValidEmail) {
        newStatus = isPublicChecked ? "bie" : "private";
      } else {
        newStatus = isPublicChecked ? "public" : "private";
      }
      await contentService.updateContentStatus(contentId, newStatus);
      setIsPublicChecked(newStatus === "public" || newStatus === "bie");
      setStatus(newStatus);
      toast.success(
        `Content status successfully changed to '${newStatus}' !`,
        {
          position: "top-right",
          transition: Slide,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } catch (error) {
      console.error("Error updating content status:", error);
    }
  };
  const dataToStore = (data) => {
    setCountShare(data);
  };
  const countNumberofShare = async () => {
    let data = await GetResourceShared.getresourceByResId(content.contentId);

    setData(data ? data[0] : []);
    if (data) {
      dataToStore(data[0].sharedTo);
    } else {
      dataToStore(0);
    }
  };
  const setPrivilege = () => {
    let privileges = {
      edit: false,
      delete: false,
      share: false,
      toggleShare: false,
      download: false,
      clone: false,
      seeSharedUsers: false,
      remove: false,
      restoreDelete: false,
      permanentDelete: false,
    };

    switch (contentType) {
      case "trash":
        privileges = {
          edit: false,
          delete: false,
          share: false,
          toggleShare: false,
          download: false,
          clone: false,
          seeSharedUsers: false,
          remove: false,
          restoreDelete: true,
          permanentDelete: true,
        };
        break;
      case "public":
      case "shared":
        privileges = {
          edit: false,
          delete: false,
          share: false,
          toggleShare: false,
          download: true,
          clone: true,
          seeSharedUsers: false,
          remove: false,
          restoreDelete: false,
          permanentDelete: false,
        };
        break;
      case "bookmark":
        privileges = {
          edit: false,
          delete: false,
          share: false,
          toggleShare: false,
          download: true,
          clone: true,
          seeSharedUsers: false,
          remove: true,
          restoreDelete: false,
          permanentDelete: false,
        };
        break;
      case "template":
        privileges = {
          edit: false,
          delete: false,
          share: false,
          toggleShare: false,
          download: false,
          clone: true,
          seeSharedUsers: false,
          remove: false,
          restoreDelete: false,
          permanentDelete: false,
        };
        break;
      case "owner":
        privileges = {
          edit: true,
          delete: true,
          share: true,
          toggleShare: true,
          download: true,
          clone: true,
          seeSharedUsers: true,
          remove: false,
          restoreDelete: false,
          permanentDelete: false,
        };
        break;
      case "bieresources":
        privileges = {
          edit: false,
          delete: false,
          share: true,
          toggleShare: false,
          download: true,
          clone: true,
          seeSharedUsers: false,
          remove: false,
          restoreDelete: false,
          permanentDelete: false,
        };
        break;
      default:
        privileges = {
          edit: false,
          delete: false,
          share: false,
          toggleShare: false,
          download: false,
          clone: false,
          seeSharedUsers: false,
          remove: false,
          restoreDelete: false,
          permanentDelete: false,
        }; // or handle the default case as needed
        break;
    }

    setContentPrivilege(privileges);
  }
  useEffect(() => {
    componentDidMount();
    countNumberofShare();
    setPrivilege();
  }, []);

  const componentDidMount = async () => {
    countNumberofShare();
    try {
      const response: any = await contentService.getContentStatus(content.contentId);
      if (typeof response === "object" && response.status) {
        const status = response.status.trim();
        if (status === "private" || status === "public" || status === "bie") {
          setStatus(status);

          if (email === process.env.REACT_APP_BIE_MAIL) { setIsPublicChecked(status === "bie"); }
          else { setIsPublicChecked(status === "public"); }
        } else {
          console.error("Invalid content status:", status);
        }
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching content status:", error);
    }
  };

  const toggleshareduserslist = () => {
    setShareduserslist(!shareduserslist);
  };

  const handleshare = () => {
    // for sharing section component management ---
    setSearchUserModal(!searchUserModal);
    componentDidMount();
    countNumberofShare();
    setPrivilege();

  };
  const email = useSelector(selectEmail);

  const handleRemoveBookmark = () => {
    GetUserList.remooveBookmark(email, content.contentId);
    window.location.reload();

  }
  const downloadH5P = () => {
    const url = contentService.generateDownloadLink(content.contentId);

    const aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = `${content.contentId}.h5p`;
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink);

    toast.success(
      `Content in h5p editor format successfully downloaded '${content.contentId}.h5p' !`,
      {
        position: "top-right",
        transition: Slide,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  };

  const downloadHtml = () => {
    const url = contentService.generateHtmlDownloadLink(content.contentId);

    const aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = `${content.contentId}.html`;
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink);

    toast.success(
      `Content in HTML format successfully downloaded '${content.contentId}.html' !`,
      {
        position: "top-right",
        transition: Slide,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  };
  const handleDelete = (contentId) => {
    toast.warning(
      <div>
        <p>
          {t("Are you sure you want to delete")} '{contentId}' {t("content")} ?
        </p>
        <button
          className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 pt-2 px-2 mx-2 border-b-4 border-red-700 hover:border-red-500 rounded"
          onClick={() => deleteContent()}>
          {t("Confirm")}
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 pt-2 px-2 mx-2 border-b-4 border-blue-700 hover:border-blue-500 rounded"
          onClick={handleCancelDelete}>
          {t("Cancel")}
        </button>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        draggable: false,
        closeButton: false,
        closeOnClick: false,
        className: "delete-warning",
        bodyClassName: "delete-warning-body",
        progressClassName: "delete-warning-progress",
        toastId: "delete-warning-toast",
        theme: "colored",
      }
    );
  };
  const handleCancelDelete = () => {
    toast.dismiss("delete-warning-toast");
  };
  const deleteContent = async () => {
    try {
      toast.dismiss("delete-warning-toast");
      const result = await FolderExplorerService.moveToTrash(content.contentId, 'file')
      result?.status == 200 ? toast.success(`Content deleted Successfully !`, toastOpt) : toast.error(`Error ocurred while deleting content !`);
      updateFolder?.(content.contentId);

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };
  const restoreDelete = async () => {
    try {
      toast.dismiss("delete-warning-toast");
      const result = await FolderExplorerService.restoreFromTrash(content.contentId, 'file')
      result?.status === 200 ? toast.success(`Content restored !`, toastOpt) : toast.error(`Error ocurred while restoring content !`);
      updateFolder?.(content.contentId);

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };
  const deletePermanently = async () => {
    try {
      toast.dismiss("delete-warning-toast");
      const result = await FolderExplorerService.deletePermanently(content.contentId, 'file')
      result?.status === 200 ? toast.success(`Content deleted permanently !`, toastOpt) : toast.error(`Error ocurred while deleting content !`);
      updateFolder?.(content.contentId);

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  const StatusBadge = ({ bgColor, text }) => (
    <div className="absolute top-0 right-0">
      <div className="w-32 h-8 absolute top-4 -right-8">
        <div className={`h-full w-full ${bgColor} text-white text-center leading-8 font-semibold transform rotate-45`}>
          {text}
        </div>
      </div>
    </div>
  );

  const StatusBageComponent = ({ isPublicChecked, status }) => {
    let badgeProps;

    if (isPublicChecked) {
      badgeProps = { bgColor: 'bg-green-500', text: t("public") };
    } else if (status === 'bie') {
      badgeProps = { bgColor: 'bg-blue-500', text: t("biestatus") };
    } else {
      badgeProps = { bgColor: 'bg-gray-500', text: t("private") };
    }

    return <StatusBadge {...badgeProps} />;
  };

  return (
    <div key={content.contentId} className=" min-h-full shadow-2xl">
      <li
        key={content.contentId}
        id={JSON.stringify(content.contentId)}
        className="shadow shadow-blue-500/40 md:shadow-indigo-500/40 relative group h-[24em] bg-gray-100 pb-6 sm:pb-20 pt-10 sm:pt-20 px-1 flex flex-col space-y-2 items-center cursor-move rounded-md hover:bg-gray-900/80 hover:smooth-hover  hover:shadow-2xl transition-all duration-300 hover:-translate-y-1 ease-in delay-100"
        draggable
        onDragStart={(e) => handleContentDragStart(e, content.contentId)}
        onDragEnd={(e) => handleContentDragEnd(e)}
        onDragOver={(e) => handleContentDragOver(e)}
        onDrop={(e) => e.preventDefault()}>
        <a href={`/home/dashboard/play/${content.contentId}`}>
          <img
            className="animate-tada w-20 h-20 object-cover object-center rounded-full group-before:collapse group-hover:text-white group-hover:visible group-hover:animate hover:animate-ping bg-gradient-to-r from-cyan-500 to-blue-500"
            src="/GreSLERN-content-play-button.png"
            alt="cuisine"
          />
        </a>
        <ToolTip
          tooltip={content.title}
          bgColor="bg-white"
          textColor="text-greslernorange">
          <p className=" w-full text-greslernorange  line-clamp-1 group-hover:text-white text-xl font-bold capitalize text-center">
            <FontAwesomeIcon icon={faBook} className="me-1" />
            {content.title}
          </p>
        </ToolTip>
        <p className="text-sm text-black/50 group-hover:text-white/50">
          {content.mainLibrary?.slice(4)}
        </p>
        <p className="text-sm text-black/50 group-hover:text-white/50">
          <ToolTip tooltip={data ? data.username : ""}>
            <FontAwesomeIcon icon={faUserPen} className="me-1" />
            <b className="line-clamp-1">{data ? data.username : ""}</b>
          </ToolTip>
        </p>
        {data?.template &&
          <p className="text-sm text-greslernblue group-hover:text-greslernblue/50">
            {/* <FontAwesomeIcon icon={faUserPen} className="me-1" /> */}
            <b className="line-clamp-1">{t('template')}</b>

          </p>
        }
        {!isPublicChecked && contentPrivilege.seeSharedUsers && (

          <p className="flex -space-x-2">
            {countShare?.map((user: any, k: number) => (
              <ToolTip
                key={`${k}userList`}
                tooltip={user.userId.name}
                bgColor="bg-white"
                textColor="bg-gray-800">
                <div className="w-8 h-8 bg-greslernorange border-2 border-white rounded-full dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition">
                  {user.userId.profileImage ? (
                    <img className="" src="/profile.png" alt="" />
                  ) : (
                    <div className=" w-7 h-7 items-center text-white text-2xl text-center">
                      {user.userId.name.charAt(0).toUpperCase()}
                    </div>
                  )}
                </div>
              </ToolTip>
            ))}
            <ToolTip tooltip={t("Show all")}>
              <FontAwesomeIcon
                icon={faEllipsisH}
                onClick={() => toggleshareduserslist()}
                className="flex items-center justify-center w-7 h-7 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800 hover:scale-150 duration-200 ease-in-out transition cursor-pointer "
              />
            </ToolTip>
          </p>
        )}
        <div className="overflow-hidden absolute top-0 m-0 w-full h-25 flex flex-row justify-center md:items-center ld:items-center">
          {displayToggle && contentPrivilege.toggleShare && (
            <p className=" px-26 sm:my-2 text-black/20 group-hover:text-white/20 inline-flex text-xs">
              <ToolTip
                tooltip={
                  (status === 'public')
                    ? t("PublishStatus")
                    : (status === 'bie')
                      ? t("BieStatus")
                      : t("PrivateStatus")
                }
              >
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={content.contentId}
                    className="sr-only peer"
                    checked={isPublicChecked}
                    onChange={(e) =>
                      handlePublicChange(e.target.value, e.target.checked)
                    }
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                </label>
              </ToolTip>
            </p>
          )}
          {isPublicChecked && !isTemplate && (
            <div className="absolute top-2 left-2">
              <img src={bieApprovedStamp} className=" w-14 h-14" alt="BIE Approve" />
            </div>
          )}

          {isTemplate ?
            (
              <div className="absolute top-0 right-0">
                <div className="w-32 h-8 absolute top-4 -right-8">
                  <div className="h-full w-full bg-greslernorange text-white text-center leading-8 font-semibold transform rotate-45">
                    {t("template")}
                  </div>
                </div>
              </div>
            ) :
            <StatusBageComponent isPublicChecked={isPublicChecked} status={status} />
          }
        </div>
        <div className="absolute md:hidden lg:hidden sm:block transition ease-in delay-100 duration-300  group-hover:block bottom-2">
          <div className="flex gap-0 m-2">
            {contentPrivilege.edit && (
              <ToolTip tooltip={t("Edit")}>
                <a
                  href={`/home/dashboard/edit/${content.contentId}`}
                  className="flex justify-center px-1 rounded cursor-pointer !text-yellow-600 group-hover:text-yellow-500 hover:scale-150 duration-200 ease-in-out transition"
                  style={{ color: "yellow" }}>
                  <div className="flex leading-5">
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="feather feather-save w-5 h-5 mr-1"
                    />
                  </div>
                </a>
              </ToolTip>
            )}
            {contentPrivilege.permanentDelete && (
              <ToolTip tooltip={t("delete")}>
                <button
                  onClick={() => deletePermanently()}
                  className="flex leading-5 justify-center px-1  rounded cursor-pointer text-base text-red-600  group-hover:text-red-500 hover:scale-150 duration-200 ease-in-out transition"
                  style={{ color: "red" }}>
                  <div className="flex leading-5">
                    <FontAwesomeIcon
                      icon={faClose}
                      className="feather feather-save w-5 h-5 mr-1"
                    />
                  </div>
                </button>
              </ToolTip>
            )}
            {contentPrivilege.restoreDelete && (
              <ToolTip tooltip={t("restore")}>
                <button
                  onClick={() => restoreDelete()}
                  className="flex leading-5 justify-center px-1  rounded cursor-pointer text-base text-red-600  group-hover:text-red-500 hover:scale-150 duration-200 ease-in-out transition"
                  style={{ color: "green" }}>
                  <div className="flex leading-5">
                    <FontAwesomeIcon
                      icon={faShare}
                      flip="horizontal"
                      className="feather feather-save w-5 h-5 mr-1"
                    />
                  </div>
                </button>
              </ToolTip>
            )}

            {contentPrivilege.delete && (
              <ToolTip tooltip={t("Delete")}>
                <button
                  onClick={() => handleDelete(content.contentId)}
                  className="flex leading-5 justify-center px-1  rounded cursor-pointer text-base text-red-600  group-hover:text-red-500 hover:scale-150 duration-200 ease-in-out transition"
                  style={{ color: "red" }}>
                  <div className="flex leading-5">
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="feather feather-save w-5 h-5 mr-1"
                    />
                  </div>
                </button>
              </ToolTip>
            )}
            {contentPrivilege.download && (
              <ToolTip tooltip={t("Download")}>
                <Menu
                  as="div"
                  className="relative inline-block text-left h-full w-full">
                  <Menu.Button className="w-full h-full">
                    <div className="flex leading-5 justify-center px-1  rounded cursor-pointer text-base text-blue-800  group-hover:text-blue-500 hover:scale-150 duration-200 ease-in-out transition">
                      <FontAwesomeIcon
                        icon={faDownload}
                        className="feather feather-save w-5 h-5 mr-1"
                      />
                    </div>
                  </Menu.Button>
                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items
                      className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      style={{ bottom: "100%", marginTop: "-10px" }} // Add this style
                    >
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active
                                  ? "bg-[#FF6347] text-white"
                                  : "text-gray-700",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                              onClick={() => downloadHtml()}>
                              {t("AsHTML")}
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active
                                  ? "bg-[#FF6347] text-white"
                                  : "text-gray-700",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                              onClick={() => downloadH5P()}>
                              {t("ForEditor")}
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </ToolTip>
            )}
            {contentPrivilege.clone && ( // call search user modal component based on value -- muluken
              <ToolTip tooltip={t("Clone")}>
                <a
                  href={`/home/dashboard/clone/${content.contentId}`} // onClick={() => this.save()}
                  className="flex justify-center px-1  rounded cursor-pointer
                        text-base text-yellow-800  group-hover:text-yellow-500 
                                                  hover:scale-150 duration-200 ease-in-out transition">
                  <div
                    className="flex leading-5 justify-center px-1  rounded cursor-pointer 
                                        text-base text-green-800  group-hover:text-green-500 
                                        hover:scale-150 duration-200 ease-in-out transition">
                    <FontAwesomeIcon
                      icon={faClone}
                      className="feather feather-save w-4 h-4 mr-1"
                    />
                  </div>
                </a>
              </ToolTip>
            )}
            {contentPrivilege.share && (
              <ToolTip tooltip="Share">
                <Menu
                  as="div"
                  className="relative inline-block text-left h-full w-full">
                  <button
                    className="w-full h-full"
                    onClick={() => handleshare()}>
                    <div
                      className="flex leading-5 justify-center px-1  rounded cursor-pointer 
                                        text-base text-green-800  group-hover:text-green-500 
                                        hover:scale-150 duration-200 ease-in-out transition">
                      <FontAwesomeIcon
                        icon={faShareAlt}
                        className="feather feather-save w-4 h-4 mr-1"
                      />
                    </div>
                  </button>
                </Menu>
              </ToolTip>
            )}
            {contentPrivilege.remove && (
              <ToolTip tooltip="Remove bookmark">
                <Menu
                  as="div"
                  className="relative inline-block text-left h-full w-full"
                  style={{ color: "red" }}>
                  <button
                    className="w-full h-full"
                    onClick={() => handleRemoveBookmark()}>
                    <div
                      className="flex leading-5 justify-center px-1  rounded cursor-pointer 
                                        text-base text-red-800  group-hover:text-red-500 
                                        hover:scale-150 duration-200 ease-in-out transition">
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="feather feather-save w-4 h-4 mr-1"
                      />
                    </div>
                  </button>
                </Menu>
              </ToolTip>
            )}
          </div>
        </div>
      </li>

      {
        contentPrivilege.share && shareduserslist && (
          <SharedUsersList resourceId={content.contentId} />
        )
      }
      {
        contentPrivilege.seeSharedUsers && searchUserModal && (
          <SearchUserModal
            closeModal={handleshare}
            resourceId={content.contentId}
          />
        )
      }
    </div>
  );
};
export default ContentCard;
