import React, { useState, useEffect } from 'react';

import { t } from 'i18next';
import TagsAndFilters from '../../services/TagsAndFilters';
import SingleTagComponent from '../singleTagComponent';
function TagSelectorComponent({ resourceId, selectedTags, setSelectedTags }: Readonly<{ resourceId?: any, selectedTags: any, setSelectedTags: any }>) {
    const [tags, setTags] = useState<any[]>([]);
    const [newTagName, setNewTagName] = useState('');
    const [filteredTags, setFilteredTags] = useState<any[]>([]);


    useEffect(() => {
        fetchTags();
        fetchTagsFromResources();
    }, []);
    const fetchTags = async () => {
        try {
            const data = await TagsAndFilters.getAllTags(resourceId);
            if (data) {
                setTags(data);
            } else {
                console.error('Error: Invalid data received from the server');
            }
        } catch (error) {
            console.error('Error fetching tags data', error);
        }
    };
    const fetchTagsFromResources = async () => {
        try {
            const data = await TagsAndFilters.getResourceTags(resourceId);
            if (data) {
                setSelectedTags(data);
            } else {
                console.error('Error: Invalid data received from the server');
            }
        } catch (error) {
            console.error('Error fetching tags data', error);
        }
    };
    const handleNewTagNameChange = (e) => {
        const value = e.target.value;
        setNewTagName(value);
        setFilteredTags(tags.filter(tag => tag.name.toLowerCase().includes(value.toLowerCase())));
    };

    const handleAddNewTag = (tagName) => {
        tagName = tagName.charAt(0).toUpperCase() + tagName.substr(1).toLowerCase()
        if (tagName && !tags.some(tag => tag.name === tagName)) {
            setTags([...tags, { name: tagName }]);
        }
        if (!selectedTags.includes(tagName)) {
            setSelectedTags([...selectedTags, tagName]);
        }
        setNewTagName('');
        setFilteredTags([]);
    };


    const toggleTag = (status, tagName) => {
        setSelectedTags(prev =>
            prev.includes(tagName) ? prev.filter(tag => tag !== tagName) : [...prev, tagName]
        );
    };

    const isChecked = (tagName) => {
        // console.info(JSON.stringify(tagName))
        if (selectedTags.includes(tagName)) {
            return true
        }
        return false
    }

    return (
        <div className="p-1 flex flex-col sm:flex-row w-full justify-center sm:justify-end gap-2">
            {/* <h4 className="text-lg font-bold mb-2">Select or Create Tags</h4> */}
            <div className='sm:order-last flex'>

                {selectedTags.length > 0 ?
                    <button onClick={() => setSelectedTags([])} className='p-1 bg-greslernorange text-white rounded-lg hover:bg-greslernorange/70 ring-1 ring-greslernorange hover:ring-white cursor-pointer w-fit h-fit flex items-center' >
                        <span className=' rounded-full bg-white text-xs text-greslernorange w-4 h-4 mr-1'>{selectedTags.length}</span>{t("clear")}
                    </button> : <></>}
                <div className=" ml-4 relative">
                    <input
                        type="text"
                        value={newTagName}
                        onChange={handleNewTagNameChange}
                        placeholder={t("new tag name")}
                        className="border rounded p-1 min-w-22.5 bg-greslernorange text-white"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && newTagName.trim()) {
                                handleAddNewTag(newTagName.trim());
                            }
                        }}
                    />
                    {newTagName && filteredTags.length > 0 && (
                        <div className="absolute left-0 right-0 bg-white border border-gray-300 rounded mt-1 z-10">
                            {filteredTags.map((tag: { name: string }) => (
                                <button
                                    key={tag.name}
                                    onClick={() => handleAddNewTag(tag.name)}
                                    className="cursor-pointer p-2 hover:bg-gray-100"
                                >
                                    {tag.name}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-wrap gap-2 mb-4 h-16 overflow-y-scroll">
                {tags.map((tag, id) => (
                    SingleTagComponent(isChecked(tag.name), tag.name, tag.name, 'tags', id, toggleTag)
                ))}
            </div>
        </div>
    );
}

export default TagSelectorComponent;
