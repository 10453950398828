import { Avatar } from "@chakra-ui/react";
import { faComment, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { API_URL, socket } from "../apiConfig";
import RedirectPopup from "./RedirectPopup";
import { selectAuthStatus, selectEmail, selectUserName } from "../redux/Reducer/authSlice";
import * as services from "../services";
import NotificationSettings from "../services/NotificationSettings";
import BookmarkButton from "./Buttons/BookmarkButton";
import LikeButton from "./Buttons/LikeButton";
import ShareButton from "./Buttons/ShareButton";
import ModalComponent from "./ModalComponent";
import LoginForm from "./form/login";
import Login from "../pages/Login";

type commentprops = {
  message: string;
  isPublic: string;
  name: string;
  _id: string;
  resourceId: any;
  createdAt: undefined;
  replylist: [username: string];
  users: Object;
  username: any;
  dateofmessage: any;
  reply: string;
};

const CommentSectionUi = (props) => {

  const location = useLocation();
  const { t } = useTranslation();
  const email = useSelector(selectEmail);
  const accessTokenUsername = useSelector(selectUserName);

  const usernameInputRef = useRef<HTMLInputElement | null>(null);
  const passwordInputRef = useRef<HTMLInputElement | null>(null);

  const [databyresource, setDatabyresource] = useState<commentprops[]>([]);
  const [resourceId] = useState(props.resourceId);
  const [resourceStatus, setResourceStatus] = useState();
  const [ChatSetting, setchatSetting] = useState(true);
  const [ownershipStatus, setOwnershipStatus] = useState(false);
  const [showcommentlist] = useState(props.showcommentlist);
  const [reqFromlanding] = useState(!(location.pathname === '/'));
  const [message, setMessage] = useState("");
  const [commentIdDisplayed, setCommentIdDisplayed] = useState();
  const [replypagidisplayed, setReplypagidisplayed] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState<boolean>();

  const [resownerEmail, setResownerEmail] = useState();

  const [countComment, setCountComment] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limitation, setLimitation] = useState(0);

  const [showLogin, setShowLogin] = useState(false)
  const recordsPerPage = 7;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = databyresource.slice(firstIndex, lastIndex);
  const npage = Math.ceil(databyresource.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [showPopup, setShowPopup] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [listTags, setListTags] = useState([]);
  const [totalViews, setTotalViews] = useState(0);
  const [depArray, setDepArray] = useState<any>([]);
  const [shareListStatus, setShareListStatus] = useState(false);
  const useremail = useSelector(selectEmail);
  const [isAuthenticated , setIsAuthenticated ] = useState()
  
  
  const isAuth = useSelector(selectAuthStatus)
  useEffect(() => {
    setIsAuthenticated(isAuth)
  }, [isAuth])
  
  
  useEffect(() => {
    isAuthenticated && handleLoginModal()
  }, [isAuthenticated])
  

  const fetchCommentCounter = async (resourceId) => {
    let data = await services.CommentCounter.getcommentcount(resourceId);
    if (data) {
      setCountComment(data);
    }
  };
  const fetchByResource = async (resourceId) => {
    // update resource function update rule public,private , shared  -- by muluken
    let data = await services.GetResourceById.getresourceById(resourceId);
    if (data) {
      setResownerEmail(data[0].email);
      setResourceStatus(data[0].status);
      setchatSetting(data[0].chatSetting);
      data[0].sharedTo.map((items, i) => {
        depArray.push(items.email);
      });
      findObject(depArray, useremail);
      if (data[0].email === useremail) {
        setOwnershipStatus(true);
      }
      else {
        setOwnershipStatus(false);
      }
    }

  };

  const handleLoginModal = () => {
    setShowLogin(false)
  }

  const handlereplayModal = (itemsId) => {
    setCommentIdDisplayed(itemsId);
    setReplyModal(!replyModal);
  };

  const fetchcommentByResource = async (resourceId) => {
    let data = await services.GetCommentbyResource.getcommentbyresource(resourceId);
    if (data) {
      setDatabyresource(data);
    } else {
      setDatabyresource([]);
    }
  };

  const showMore = (dataId) => {
    setReplypagidisplayed(dataId);
    setLimitation(limitation + 2);
  };

  const showLess = (dataId) => {
    setReplypagidisplayed(dataId);
    setLimitation(limitation >= 3 ? limitation - 2 : 0);
  };

  const submitComment = async (e) => {
    if (accessTokenUsername) {
      e.preventDefault();
      if (message === "") {
        NotificationManager.warning(
          "Please first you have to write something",
          "Feedback"
        );
        return;
      } else {
        const commentData = {
          name: accessTokenUsername,
          resourceId: resourceId,
          message: message,
          isPublic: false,
          resOwnerEmail: resownerEmail,
        };
        const username = accessTokenUsername;
        try {
          let data = await services.CreateComment.createcomment(commentData);

          if (!data) {
            setAlertMessage(false);
            setMessage("");
            setReplyModal(false);
          } else {
            setAlertMessage(true);
            setMessage("");
            setReplyModal(false);
            await services.GetNotification.saveNotification(resourceId, 'comment', null, null, 'Commented ' + commentData.message)
            socket.emit("commentstatus", { resourceId, username }, (error) => {
              if (error) {
                console.error("Error emitting feedback status:", error);
              }
            });
          }
        } catch (error) {
          console.error("Error during feedback:", error);
        }

        refreshPage();
      }
    } else {
      setShowPopup(true);
    }
  };


  const fetchTagsList = async () => {
    let data = await NotificationSettings.getTagsList(resourceId);
    if (data) {
      setListTags(data);
    } else {
      setListTags([]);
    }
  };

  function refreshPage() {
    fetchCommentCounter(resourceId);

  }

  const countViews = async () => {
    let counts = await NotificationSettings.getViews(resourceId);
    if (counts) {
      setTotalViews(counts.totalViews);

    }
  };
  const submitReply = async (commentId) => {
    if (accessTokenUsername) {
      if (replyMessage === "") {
        NotificationManager.warning("Please type your text", "Reply");
        return;
      } else {
        const replyCommentData = {
          replyMessage: replyMessage,
          username: accessTokenUsername,
          resOwnerEmail: resownerEmail,
        };
        try {
          let data = await services.CreateComment.createCommentReply(
            commentId,
            replyCommentData
          );
          if (!data) {
            NotificationManager.warning("Reply not added", "Reply");
            setAlertMessage(true);
            setReplyMessage("");
          } else {
            NotificationManager.success("Reply added", "Reply");
            setAlertMessage(true);
            setReplyMessage("");
            setReplyModal(false);
          }
        } catch (error) {
          // console.error("Error during feedback:", error);
        }
      }
    } else {
      setShowPopup(true);
    }
  };
  function findObject(arr, value) {
    let booleanValue = arr.filter((item) =>
      item === value).length > 0
    setShareListStatus(booleanValue);
  }
  useEffect(() => {
    fetchCommentCounter(resourceId);
    fetchByResource(resourceId);
    fetchcommentByResource(resourceId);
    countViews();
    fetchTagsList();
    NotificationSettings.updateViews(resourceId);
    if (alertMessage === true) {
      const timer = setInterval(() => {
        setAlertMessage(false);
      }, 10000);
      return () => clearInterval(timer);
    }
  }, [replyModal, alertMessage]);
  const handleChange = (event) => {
    const value = event.target.value;
    setMessage(value);
  };
  const handleChangeReply = (event) => {
    const value = event.target.value;
    setReplyMessage(value);
  };
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      submitComment(e);
    }
  };
  const handleGoLeftClick = () => {
  };
  const testResources = () => {
    return (resourceStatus === "public" || shareListStatus || ownershipStatus) && ChatSetting
  }

  return (
    <div className="w-full">
      {testResources() ?
        <div className="bg-white py-1 mb-12 mt-3">
          <div className="w-auto mx-5">
            <div className="flex justify-between items-center mb-6 space-x-2">
              <LikeButton
                resourceId={resourceId}
                username={accessTokenUsername}
                setLogin={setShowLogin}
              />
                {
                  resourceStatus !== "private" && 
                  <div className="flex items-center space-x-2">
                    <ShareButton
                      fetchTagsList={fetchTagsList}
                      resourceId={resourceId}
                      setLogin={setShowLogin}
                    />
                    <h2 className="text-sm   text-gray-900">
                      {listTags} {t("Tags")}
                    </h2>
                  </div>
                }
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon
                  className="text-2xl "
                  icon={faEye}
                />
                <h2 className="text-sm   text-gray-900">
                  {totalViews} {t("views")}
                </h2>
              </div>
              <div className="flex items-center space-x-2">
                <BookmarkButton email={email} resourceId={resourceId} setLogin={setShowLogin} />

              </div>

              <div className="flex items-center space-x-2">
                <FontAwesomeIcon
                  className="text-2xl "
                  icon={faComment}
                />

                {countComment !== undefined &&
                  <h2 className="text-sm  text-gray-900">
                    {countComment} {t("feedbacks")}
                  </h2>
                }
              </div>
            </div>
            {reqFromlanding &&

              <form className="mb-6 w-full"
                onSubmit={submitComment}
              >
                <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200">
                  <label className="sr-only">{t("Your Feedbacks")}</label>
                  <textarea
                    value={message}
                    required
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    rows={6}
                    className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
                    placeholder="Write a feedbacks..."></textarea>
                </div>
                <button
                  type="submit"
                  className="float-right border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mb-2 text-white bg-greslernorange"

                >
                  {t("Post Feedback")}
                </button>
                <br />
                {showPopup && <RedirectPopup onClose={togglePopup} />}
              </form>

            }
            {showcommentlist ? (
              <>
                {databyresource.length === 0 &&
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert">
                    {
                      ownershipStatus ?
                        <strong className="font-bold">
                          {t("Your Resource is Private.Only accept feedback from users you shared")} !
                        </strong>
                        : <strong className="font-bold">{t("No feedback")}  !</strong>
                    }
                  </div>
                }

                {records.map((data, i) =>
                  <section
                    className="p-3 mb-1 text-base  bg-white rounded-lg"
                    key={i}>
                    <footer className="flex justify-between  items-center w-full mb-1  ">
                      <div className="flex items-center w-full">
                        {data.users[0]?.profilephoto ? (
                          <img
                            src={`${API_URL}/public/images/${data.users[0].profilephoto}`}
                            alt="profile"
                            className="mr-2 w-[50px] h-[50px] border  border-3 !border-greslernorange rounded-full"
                          />
                        ) : (
                          <Avatar
                            size="lg"
                            name={data.users[0]?.name}
                            className="mr-2 w-[50px] h-[50px] border border-3 border-solid !border-greslernorange text-2xl rounded-full"
                          />
                        )}
                        <div className="flex w-full justify-between items-center border-b-2 border-b-gray-300 pb-1">
                          {data.users[0]?.name === accessTokenUsername ? (
                            <p className="inline-flex items-center mr-3 text-sm font-bold text-gray-900">
                              {" "}
                              {t("You")}
                            </p>
                          ) : (
                            <p className="inline-flex items-center mr-3 text-sm font-bold text-gray-900">
                              {" "}
                              {data.users[0]?.name}
                            </p>
                          )}

                          <p className="text-sm text-gray-600">
                            <p className=" text-meta-5">
                              {moment(data.createdAt).fromNow()}
                            </p>
                          </p>
                        </div>
                      </div>
                    </footer>
                    <p className="text-gray-600 pl-14">{data.message} </p>
                    {data.replylist
                      .slice(0, limitation)
                      .map((items: any, index) => (
                        <>
                          {replypagidisplayed === data._id &&
                            <article className="py-6 px-3  ml-4 lg:ml-10 text-base bg-white rounded-lg dark:bg-gray-900">
                              <footer className="flex justify-between items-center">
                                <div className="flex items-center w-full">
                                  <p className="flex items-center justify-center  text-sm text-gray-900 dark:text-white ">
                                    {data.name === accessTokenUsername ?
                                      data.users[0]?.profilephoto ?
                                        <img
                                          src={`${API_URL}/public/images/${data.users[0].profilephoto}`}
                                          alt="profile"
                                          className="mr-2 w-[50px] h-[50px] border  border-3 !border-greslernorange rounded-full"
                                        />
                                        :
                                        <Avatar
                                          size="lg"
                                          name={data.users[0]?.name}
                                          className="mr-2 w-[40px] h-[40px] text-gray-700 border border-3 border-solid !border-greslernorange text-xl rounded-full"
                                        />
                                      : (
                                        <img
                                          className="mr-2 w-6 h-6 rounded-full"
                                          src="https://as1.ftcdn.net/v2/jpg/02/59/39/46/1000_F_259394679_GGA8JJAEkukYJL9XXFH2JoC3nMguBPNH.jpg"
                                          alt="Jes"
                                        />
                                      )}

                                  </p>
                                  <div className="w-full flex items-center justify-between border-b border-b-2 border-b-gray-200">
                                    {data.name === accessTokenUsername ? (
                                      <h6 className="text-gray-400 font-bold">{t("You")}</h6>
                                    ) : (
                                      <h6>{items.username ? items.username.toString() : ' '}</h6>
                                    )}

                                    <p className="text-xs text-gray-600 dark:text-gray-400">
                                      <time title="time diaplay">
                                        {moment(items.dateofmessage).fromNow()}
                                      </time>
                                    </p>
                                  </div>
                                </div>
                              </footer>
                              <p className="text-gray-500 dark:text-gray-400 ml-12">
                                {items.reply}
                              </p>
                            </article>
                          }
                        </>
                      ))}
                    <div className="flex items-center justify-between w-full mt-4 space-x-4 ml-14 pr-14">
                      {reqFromlanding &&
                        <button
                          type="button"
                          onClick={() => handlereplayModal(data._id)}
                          className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium">
                          <svg
                            className="mr-1.5 w-3.5 h-3.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 18">
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                            />
                          </svg>
                          {t("Reply")}
                        </button>
                      }
                      {data.replylist.length > 0 && (
                        <div className="flex gap-4">
                          {data.replylist.length > limitation && (
                            <button
                              type="button"
                              onClick={() => showMore(data._id)}
                              className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium gap-1">
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 16 3">
                                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                              </svg>
                              <h6 className="text-sm text-opacity-5 !text-greslernorange">
                                ({data.replylist.length - limitation}){" "}
                              </h6>
                              {t("More reply")}
                            </button>
                          )}
                          {(data.replylist.length <= limitation || limitation > 0) && (
                            <button
                              type="button"
                              onClick={() => showLess(data._id)}
                              className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium">
                              {t("Less reply")}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    {replyModal && (
                      <>
                        {commentIdDisplayed === data._id && (
                          <>
                            <div className="w-full my-2 pl-12">
                              <textarea
                                value={replyMessage}
                                required
                                onChange={handleChangeReply}
                                className="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-10 py-1 px-1 font-sm placeholder-gray-400 focus:outline-none focus:bg-white"
                                name="body"
                                placeholder="Type Reply here ..."></textarea>
                            </div>
                            <div className="w-full flex justify-end ">
                              <button
                                type="submit"
                                onClick={() => submitReply(data._id)}
                                className="flex items-center text-sm border-2 rounded p-1 !border-greslernorange !text-greslernorange hover:bg-greslernorange hover:!text-white dark:text-gray-400 font-medium">
                                {t("Reply")}
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </section>
                )}
              </>
            ) : undefined}
          </div>
          {countComment === undefined && (
            <>
              {reqFromlanding &&
                <nav aria-label="Page navigation example">
                  <ul className="list-style-none flex ml-12">
                    <li className="page-item">
                      <button
                        className="pointer-events-none relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300"
                        onClick={prePage}
                      >
                        {t("Prev")}
                      </button>
                    </li>
                    {numbers.map((n, i) => (
                      <li
                        className={`${currentPage === n
                          ? "whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                          : "page-item"
                          }`}
                        key={i}>
                        <button
                          className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                          onClick={() => changeCPage(n)}>
                          {n}
                        </button>
                      </li>
                    ))}
                    <li>
                      <button
                        className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                        onClick={nextPage}>
                        {t("Next")}
                      </button>
                    </li>
                  </ul>
                </nav>
              }
            </>
          )}
        </div>
        :
        <div
          className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
          role="alert">
          <p className="font-bold">{t("Feedback off")}</p>
          <p className="text-sm font-light">
            {t("You can't accept feedback on this private resource")}
          </p>
        </div>
      }
      <ModalComponent isOpen={showLogin} onClose={handleLoginModal}  >
        <div className="flex justify-center items-center p-4">
          <LoginForm handleGoLeftClick={handleGoLeftClick} usernameInputRef={usernameInputRef} passwordInputRef={passwordInputRef} />
        </div>
      </ModalComponent>
    </div>
  );
  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
  }
};

export default CommentSectionUi;