import { useNavigate } from 'react-router-dom'
import { GetNotification } from '../../../services'

import { API_URL } from '../../../apiConfig';
import Typist from 'react-typist'

export const NotificationItem = ({ notification }) => {
    const navigate = useNavigate()

    const returnTypeCss = (type) => {
        if (type == "like") return " bg-red-100 "
        else if (type == "share") return " bg-green-200 "
        else if (type == "comment") return " bg-blue-200 "
        else if (type == "review") return " bg-yellow-200"
        else if (type == "tag") return " bg-greslernorange"
        else return ""
    }
    const handleRedirect = async (contentId: string) => {
        try {
            await GetNotification.markSeenById(notification.id);
            navigate(`/home/dashboard/play/${contentId}`);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className={`flex items-center p-1 rounded-lg mx-auto max-w-sm relative border border-gray-100 mt-2 pb-4 ${returnTypeCss(notification.type)}`}>
            {!notification.seen && (
                <span className=" border-1 text-green-900 bg-green-500 border border-white  rounded-full absolute top-0 right-0 w-3 h-3 animate-pulse ease-in-out "></span>
            )}
            <span className="text-xs  text-gray-500 absolute bottom-0 right-3">
                {notification.date}
            </span>
            <div className='flex items-center gap-1 font-bold'>
                <div className=' rounded-full border-2 border-greslernorange w-8 h-8 overflow-clip'>

                    {/* <img src={`${API_URL}/public/images/${notification?.userImg}`} /> */}
                    {notification.userImg ? (
                        <img
                            className="h-full w-full flex-none rounded-full bg-gray-50"
                            src={`${API_URL}/public/images/${notification?.userImg}`}
                            alt="user"
                        />
                    ) : (
                        <div className="w-full h-full items-center text-white text-2xl text-center bg-greslernorange">
                            {notification.userName.charAt(0).toUpperCase()}
                        </div>
                    )}

                </div>
                {notification.userName[0].toUpperCase() + notification.userName.substr(1)}</div>
            <div className="ml-1">
                <p className="flex items-center text-sm text-gray-600">
                    {notification.message}
                    <button className=' font-semibold text-gray-900 hover:underline hover:bg-white hover:text-greslernorange rounded-t-md p-1 decoration-indigo-500 truncate cursor-pointer' onClick={() => handleRedirect(notification.resourceId)}>
                        <Typist>{notification.resourceName}</Typist>
                    </button>
                </p>
            </div>
        </div>
    )
}
