import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { faTrashCanArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetUsers } from "../../../../services";
import ContentHeader from "../../../root/dashboard/addon/contentHeader";

type profileprops = {
  profilephoto: string;
  name: string;
  email: string;
  bio: string;
  type: string;
  gender: string;
  country: string;
  username: string;
  phone: string;
  createdAt: Date;
  status: string;
  contactName: string;
};

const InvitationTable = ({ invitations = [], handleDeleteUser }) => {
  const [invitationSentList, setInvitationSentlist] = useState<profileprops[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = invitationSentList.slice(firstIndex, lastIndex);
  const npage = Math.ceil(invitationSentList.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const { t } = useTranslation();


  const fetchInvitationsentlist = async () => {
    let resource = await GetUsers.getinvitationsentlist();
    if (resource) {
      setInvitationSentlist(resource);
    }
  };
  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
    else
      return;
  }
  const handleSearch = (e) => {
    const filteredData = invitationSentList?.filter((content) => {
      return Object.values(content)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    e.target.value === ""
      ? fetchInvitationsentlist()
      : setInvitationSentlist(filteredData);
  };
  useEffect(() => {
    fetchInvitationsentlist();
  }, [invitations]);
  return (
    <div className="w-full">
      <ContentHeader title={t("invitations Sent List")} />
      <div className="">
        <div className="my-2 flex place-content-end sm:flex-row flex-col">
          <div className="block relative">
            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
              </svg>
            </span>
            <input onChange={handleSearch} placeholder={t("Search")} className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-1 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
          </div>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("#No")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Name")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Country")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Contact Name")}
                  </th>
                  <th className="ppx-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Email")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Sent Date")}
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                    {t("Actions")}
                  </th>

                </tr>
              </thead>
              <tbody>
                {invitationSentList.length > 0 ?
                  records.map((invitations, i) =>
                    <tr
                      className=""
                      key={`adminUsertable${invitations.email}`}
                    >
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-center font-bold">
                        {i + 1}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {invitations.name}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm font-semibold">
                        {invitations.country}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {invitations.contactName}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {invitations.email}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {moment(invitations.createdAt).format('LL')}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        <button onClick={() => handleDeleteUser(invitations.email)} ><FontAwesomeIcon icon={faTrashCanArrowUp} color='red' /></button>
                      </td>

                    </tr>
                  )
                  : (
                    <tr>
                      <td>
                        <strong className="font-bold">{t("Sorry! No data")}  !</strong>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            {invitationSentList.length > 0 &&
              <nav className="items-center">
                <ul className="list-style-none flex ml-12">
                  <li className="page-item">
                    <button className="pointer-events-none relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300" onClick={prePage}>
                      {t("Prev")}
                    </button>
                  </li>
                  {numbers.map((n, i) =>
                    <li
                      className={`${currentPage === n
                        ? "whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                        : "page-item"}`}
                      key={`adminUsertable${n.toString()}`}
                    >
                      <button
                        className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </button>
                    </li>
                  )}
                  <li>
                    <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100" onClick={nextPage}>
                      {t("Next")}
                    </button>
                  </li>
                </ul>
              </nav>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default InvitationTable;