import {RefObject, useEffect} from 'react'

export const useOnClickOutside = <T extends HTMLElement = HTMLElement> (
 ref: RefObject<T>,
 handler: (event: Event)=>void
) =>{
  useEffect(() => {
    const listener = (event:Event)=>{
      const el = ref?.current
      if(!el || el.contains((event?.target as Node)|| null)){
        return
      }
      handler(event) // Call the handler only if the click is outside of the element passed
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return ()=>{
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
  
}