import { faCancel, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H5PEditorUI } from "@lumieducation/h5p-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spin } from 'react-cssfx-loading'
import TagSelectorComponent from "../../TagSelectorComponent";

import { ContentService } from "../../../services/ContentService";
import { GetResourceShared } from "../../../services";
import FolderExplorerService from "../../../services/FolderExplorerService";
import FolderSelectElement from "../../folderSelect";
import ContentHeader from "../../root/dashboard/addon/contentHeader";
import EditorMenu from "../addon/EditorMenu";
import ModalComponent from "../../ModalComponent";
import ContentCard from "../ContentCard";
import ContentSettingService from "../../../services/ContentSettingService";
import TagsAndFilters from "../../../services/TagsAndFilters";
import { socket } from "../../../apiConfig";

interface ContentMetadata {
  title: string;
  mainLibrary: string;
}
const ContentEdit = () => {
  const { t } = useTranslation();
  const { contentId } = useParams();
  const { folderId } = useParams();
  // console.log(folderId);
  const navigate = useNavigate();
  const h5pEditor = useRef<H5PEditorUI>(null);
  const contentService = new ContentService();

  const [activityIndicator, setActivityIndicator] = useState(false)
  const [canSave, setCanSave] = useState(true);
  const [folderToSave, setFolderToSave] = useState("");
  const [contentMeta, setContentMeta] = useState<ContentMetadata>();
  const [actvitySaveExit, setActivityIndicatorExit] = useState(false)
  const [newItemTags, setNewItemTags] = useState<string[]>([]);

  const [templates, setTemplates] = useState(false)
  const [templateContents, setTemplateContents] = useState([])
  const [chatMode, setChatMode] = useState(true)
  const [beginner, setBeginner] = useState(true)
  const [items, setItems] = useState([]);


  const setEditorMode = () => {
    const iframeElement = document.querySelector('.h5p-editor-iframe');

    if (iframeElement) {

      const iframeDocument = iframeElement.contentDocument || iframeElement.contentWindow.document;
      // removing basic functionality
      if (beginner) {
        iframeDocument.getElementsByClassName('field-name-override')[0].style.display = "none"
        iframeDocument.getElementsByClassName('common')[0].style.display = "none"
      } else {
        iframeDocument.getElementsByClassName('field-name-override')[0].style.display = "block"
        iframeDocument.getElementsByClassName('common')[0].style.display = "block"
      }

      const ulElement = iframeDocument.getElementsByClassName('h5p-dragnbar-ul')[1];

      if (ulElement) {
        let liElements = Array.from(ulElement.children);
        setItems(liElements)
        liElements.forEach(function (liElement) {
          ulElement.removeChild(liElement);
        });
        let beginnerElements = [
          "Open Ended Question",
          "Text",
          // "Link",
          "Image",
          // "Shapes",
          "Go to slide",
          "Video",
          // "Audio",
          // "Fill in the Blanks",
          // "Single Choice Set",
          // "h5p-flashcards",
          // "Multiple Choice",
          // "True/False Question",
          // "Drag and Drop",
          // "SummaryDrag the WordsMark the WordsDialog CardsContinuous TextExportable Text AreaTableInteractive VideoAudio RecorderImage Choice",
          "Paste"
        ];

        // Filter the liElements based on the idOrder array
        liElements = liElements.filter(function (liElement) {
          let outerText = liElement.outerText;
          return beginnerElements.includes(outerText);
        });

        // Append the filtered list items back to the olElement
        beginner ?
          liElements.forEach(function (liElement) {
            ulElement.appendChild(liElement);
          })

          : items.forEach(function (liElement) {
            ulElement.appendChild(liElement);
          })
      }


    }

    setBeginner(!beginner)
  }
  const handleTemplate = async () => {
    console.log("TS handleTemplate")

    const t = await ContentSettingService.getAllTemplates()
    if (t) {

      setTemplateContents(t)
      setTemplates(!templates)
    }

  }
  const setFolder = (folder) => {
    setFolderToSave(folder);
  };
  const setMeta = async () => {
    const metadata = await GetResourceShared.getresourceByResId(contentId);
    setChatMode(metadata[0]?.chatSetting)
    setContentMeta(metadata[0]);
  };
  const onSaveError = async (event) => {
  };
  const onSaved = async () => {
    setCanSave(true);
    setTimeout(() => {
      setMeta();
    }, 3000);
  };

  const save = async (type = 'normal') => {
    setCanSave(true);
    setActivityIndicator(true);
    try {
      const returnData = await h5pEditor.current?.save();
      if (returnData) {
        let status;
        if (folderToSave != "") {
          status = await FolderExplorerService.moveFileToFolder(
            returnData.contentId,
            folderToSave
          );
          setFolderToSave("");
        }

        // set template flag
        if (type == "template") {

          const saved = await ContentSettingService.setAs('template',returnData.contentId)
          // alert("TS: " + JSON.stringify(saved))
        }
        if (type == "guide") {

          const saved = await ContentSettingService.setAs('guide',returnData.contentId)
          // alert("TS: " + JSON.stringify(saved))
        }
        if (type == "walkthrough") {

          const saved = await ContentSettingService.setAs('walkthrough',returnData.contentId)
          // alert("TS: " + JSON.stringify(saved))
        }

        const saved = await ContentSettingService.setResourceChatSetting(returnData.contentId, chatMode)



        setFolderToSave("");
        setActivityIndicator(false);
        await handleAddNewTag(newItemTags, returnData.contentId);
        toast.success(
          `${t("Content")} ${returnData.metadata.title} ${t("Updated")}`
        );
        // setTimeout(() => { navigate(-2); }, 50);

      } else {
        setActivityIndicator(false)
        toast.warning(`Content not saved!`);
      }
    } catch (error) {
      setActivityIndicator(false)
      toast.error(`Content error while saving`);
    }
  };
  const saveExit = async () => {
    setActivityIndicatorExit(true)
    await save();
    setTimeout(() => {
      setActivityIndicatorExit(false)
      navigate(-1);
    }, 50);
  };
  const onEditorLoaded = async (event) => {
    setCanSave(true);
    setTimeout(() => { }, 3000);
  };
  const handleAddNewTag = async (newTagName: string[], resourceId: string) => {
    try {
      await TagsAndFilters.addTags(newTagName, resourceId);
    } catch (error) {
      console.error('Error adding new tag', error);
    }
  };

  // Function to apply changes to the H5P editor
  function applyEditorChanges(changes) {
    console.info('apply changes to the H5P editor='+JSON.stringify(changes))
    // Implement the logic to apply changes to the H5P editor
  }

  // Function to send editor changes to the server
  function sendEditorChanges(roomId, changes) {
    socket.emit("CollaborationEditorUpdates", { roomId, changes });
  }

  useEffect(() => {
    setMeta();
  }, [newItemTags]);

  useEffect(()=>{
    // Join the collaboration room
    socket.emit("CollaborationJoinRoom", contentId);

    // Listen for editor updates
    socket.on("editorUpdate", (changes) => {
        // Apply the received changes to the H5P editor
        applyEditorChanges(changes);
    });
  },[])

  const MINUTE_MS = 3000; // avery 3 sec 

  useEffect(() => {
    const interval = setInterval(() => {
          h5pEditor.current?.save().then((currentState)=>{
            // console.info(currentState)
            sendEditorChanges(currentState?.contentId, currentState)
        })
        // h5pEditor.current?.props.loadContentCallback(contentId=>{
        //   ...editorModel,
        // library: content.library,
        // metadata: content.params.metadata,
        // params: content.params.params
        // })
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  return (
    <>
      <div className="flex-1 px-2 sm:px-0">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex lg:flex-row md:flex-row md:justify-between lg:justify-between md:items-center lg:items-center flex-col">
            <ContentHeader title={t("Editing Content")} />
            <h3 className="lg:pl-5 md: pl-2 text-3xl  text-yellow-900 font-extrabold">
              {contentMeta?.title}
            </h3>
          </div>
          <EditorMenu
            canSave={canSave}
            folderId={folderId}
            setFolder={setFolder}
            activityIndicator={actvitySaveExit}
            templates={templates}
            handleTemplate={handleTemplate}
            save={save}
            beginner={beginner}
            chatMode={chatMode}
            setChatMode={setChatMode}
            setEditorMode={setEditorMode}
            saveExit={saveExit}
            actvitySaveExit={actvitySaveExit}
          />

          <div className="flex flex-wrap justify-between items-center">
          {canSave ? <TagSelectorComponent resourceId={contentId} selectedTags={newItemTags} setSelectedTags={setNewItemTags} /> : undefined}
          </div>
        </div>

        <div className=" pt-2">
          {contentId && (
            <H5PEditorUI
              ref={h5pEditor}
              contentId={contentId}
              loadContentCallback={contentService.getEdit}
              saveContentCallback={contentService.save}
              onSaved={onSaved}
              onLoaded={onEditorLoaded}
              onSaveError={onSaveError}
            />
          )}
        </div>
      </div>
      <ModalComponent isOpen={templates} title={false} onClose={handleTemplate} >
        <ul className="mb-10 sm:mb-0 mt-10 grid gap-4 grid-cols sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-8 place-content-around">
          {templateContents.length > 0 &&
            templateContents?.map(tm =>

              <ContentCard content={tm} contentType={"template"} />
            )}

        </ul>
      </ModalComponent>
    </>
  );
};

export default ContentEdit;
