import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { GetUserLogin, GetUsers } from "../../../services";
import countries from "countries-list"; // Import the countries-list library
import { Spin } from "react-cssfx-loading";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionsContents from "../../../pages/LandingPage/TermsAndConditions/contents";
interface SignUpCredentials {
  password: string;
  name: string;
  email: string;
  type: string;
  gender?: string;
  country: string;
  institute: string;
  attempt: number;
}

interface UserType {
  label: string;
  value: string;
}
interface GenderType {
  label?: string;
  value?: string;
}
export type InstituteData = {
  name: string;
  country: string;
  contactEmail: string;
  contactName: string;
  contactPosition: string;
  email:string;
}
function SignupForm({ handleGoRightClick }: { handleGoRightClick: () => void }) {

  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [signupCredentials, setSignupCredentials] = useState<SignUpCredentials>(
    {
      password: "",
      name: "",
      email: "",
      type: "",
      gender: " ",
      country: "",
      institute: "",
      attempt: 0,
    }
  );
  const [type, setType] = useState<UserType | null>(null);
  const [gender, setGender] = useState<GenderType | null>(null);
  const countryList = Object.values(countries.countries);
  countryList.sort((a, b) => a.name.localeCompare(b.name));
  const [isValid, setIsValid] = useState(true);
  const [isPassValid, setIsPassValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
  const [individualData, setIndividualData] = useState<InstituteData | null>(null);
  const [showtermcondition, setShowtermcondition] = useState(false);
  const [showPrivacyandCompliance, setShowPrivacyandCompliance] = useState(false);
  const { token } = useParams();
  const [instName, setInstName] = useState('');
  const [instEmail, setInstEmail] = useState('');
  const navigate = useNavigate();

  const minLength = 8;

  const typeOptions: UserType[] = [
    { label: "Teacher", value: "teacher" },
    // { label: "Editor", value: "editor" },
    { label: "Student", value: "student" },
    { label: 'Other', value: 'other' },

  ];

  const genderOptions: GenderType[] = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "I prefer not to say", value: "I prefer not to say" }
  ];
  const openModal = () => {
    setShowtermcondition(!showtermcondition);
    setShowPrivacyandCompliance(!showPrivacyandCompliance);
  };
  function toggleShowPassword() {
    setIsPasswordVisible((prevState) => !prevState);

  }
  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      // Destructure signup credentials
      const { password, type, gender } = signupCredentials;
      setLoading(true);
      if (!password || !individualData?.name || !gender || !individualData?.country) {
        // Some or all fields are empty, display an error message or take appropriate action
        NotificationManager.warning("All filed Required", "SignUp");
        setLoading(false);
        return;
      }
      // Call the signup API
      await GetUserLogin.signup({
        password,
        name: individualData?.name,
        email: individualData?.email,
        type,
        gender,
        country: individualData?.country,
        institute: instEmail,
        attempt: 0,
      }).then((response) => {
        // Display alert and navigate to /login
        if (response) {
          NotificationManager.success("User Register Successfull", "SignUp");
          // Clear form fields and reset selections after successful signup
          setSignupCredentials({
            password: "",
            name: "",
            email: "",
            gender: "",
            type: "",
            country: "",
            institute: "",
            attempt: 0,
          });
          setType(null);
          setGender(null);
          setLoading(false);
          handleGoRightClick()
        } else {
          setLoading(false);
        }
      });
      // Optionally show a success message or perform other actions
      // ...
    } catch (error) {
      // Handle errors that might occur during signup
      console.error("Error during signup:", error);
      NotificationManager.error("Error during signup, Try Again", "SignUp");
      setLoading(false);
      // Optionally display an error message to the user or take other actions
      // ...
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupCredentials({
      ...signupCredentials,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "email") {
      setIsValid(/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(e.target.value));
    } else if (e.target.name === "password") {
      setIsPassValid(e.target.value.length >= minLength);
    }
  };

  const handleToggleCheckbox = () => {
    // Toggle the checkbox state
    setIsChecked(true);
    setShowtermcondition(!showtermcondition);
  };
  const handleToggleCheckboxPrivacy = () => {
    // Toggle the checkbox state
    setIsCheckedPrivacy(true);
    setShowPrivacyandCompliance(!showPrivacyandCompliance);
  };

  const fetchSignUpIndividual = async () => {
    const data = await GetUsers.getInvitedUserbyToken(token ? token : "");
    if (data) {
      checkUser(data[0].sendto);
      fetchInstituteInfo(data[0].senderemail);
      setIndividualData(data[0]);
    }
    else {
      setIndividualData(null);
    }
  }
  const fetchInstituteInfo = async (useremail) => {
    const data = await GetUsers.getinstitutedetailRegistration(useremail);
    if (data) {
      setInstName(data.institutionName);
      setInstEmail(data._id);
    }
    else {
      setInstName('');
    }
  }
  const checkUser=(sendtoUser)=>{
    if(sendtoUser !== 'individual'){
      if (sendtoUser === undefined){
        navigate("/home");
        return;
    }
    navigate(`/registration/${token}`);
    return;
  }
  }
  useEffect(() => {
    fetchSignUpIndividual();
  }, [])

  return (
    <>
      <div className="relative w-[400px] flex justify-center items-center rounded-[20px] bg-box animate-animate3 box-form h-auto py-[20px] before:content before:absolute before:w-full before:h-full before:bg-box-before before:animate-animate3 before:rounded-[20px] before:animation-delay-1000 after:content after:absolute after:inset-[8px] after:bg-greslernorange after:bg-noise after:rounded-[15px] after:border-4 after:border-transparent">
        <form
          onSubmit={handleSignUp}
          className="relative w-full flex justify-center items-center flex-col gap-[15px] py-0 px-[40px] z-[10]">
          <h2 className="text-white font-semibold">{t("Signup")}</h2>
          <input
            className="relative w-[85%] py-[10px] px-[15px] border !border-greslernblue outline-none rounded-[5px] text-[#dfdfdfe6] text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]"
            id="name"
            name="name"
            type="text"
            placeholder={t("EnterName")}
            value={individualData?.name}
            disabled
          // onChange={(e) =>
          //   setSignupCredentials({
          //     ...signupCredentials,
          //     name: e.target.value,
          //   })
          // }
          // required
          />
          <input
            className={`relative w-[85%] py-[10px] px-[15px] border !border-greslernblue outline-none rounded-[5px] text-[#dfdfdfe6] text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6] 
                        ${isValid ? "" : "border-red-500"}`}
            id="email"
            name="email"
            type="email"
            disabled
            placeholder={t("EnterEmail")}
            value={individualData?.email}
          // onChange={(e) => handleChange(e)}
          />
          {/* {!isValid && (
            <div className="text-red-500 text-sm">{t("entervalidemail")}</div>
          )} */}
          <div className="relative" style={{ position: 'relative', width: '85%' }}>
            <input
              className={`relative w-full py-[10px] px-[15px] border !border-greslernblue outline-none rounded-[5px] text-white text-base tracking-wider bg-gersLightBlue placeholder:text-[#ffffffe6]
             ${isPassValid ? "" : "border-red-500"}`}
              id="password"
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              placeholder={t("EnterPassword")}
              value={signupCredentials.password}
              onChange={(e) => handleChange(e)}
              required
            />
            {!isPassValid && (
              <div className="text-red-500 text-sm">
                {t("passwordlength")} {minLength} {t("characterlong")}
              </div>
            )}
            <button
              className="absolute top-[50%] right-0 transform translate-y-[-50%] flex justify-center items-center w-[50px] h-[27px] rounded-[10px] border-none outline-none bg-transparent text-greslernorange"
              type="button"
              onClick={toggleShowPassword}
            >
              {isPasswordVisible ? (
                <FontAwesomeIcon className="text-2xl" icon={faEye} />
              ) : (
                <FontAwesomeIcon className="text-2xl" icon={faEyeSlash} />
              )}
            </button>
          </div>



          <select
            id="type"
            value={type ? type.value : ""}
            onChange={(e) => {
              const selectedOptionValue = e.target.value;

              let selectedOption: UserType | null = null;

              const foundOption = typeOptions.find(
                (option) => option.value === selectedOptionValue
              );

              if (foundOption) {
                selectedOption = foundOption;
              }
              setType(selectedOption);
              setSignupCredentials({
                ...signupCredentials,
                type: selectedOptionValue === "other" ? 'teacher' : selectedOptionValue,
              });
            }}
            className="relative w-[85%] py-[10px] px-[15px] border !border-greslernblue outline-none rounded-[5px] text-white text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]">
            <option className="text-[#292929]" hidden>
              {t("userType")}
            </option>

            {typeOptions.map((option, k) => (
              <option
                key={`$typeOptios${k}${option.value}`}
                className="text-[#292929]"
                value={option.value}>
                {t(option.label)}
              </option>
            ))}
          </select>
          <select
            id="gender"
            value={gender ? gender.value : ""}
            onChange={(e) => {
              const selectedOptionValue = e.target.value;
              let selectedOption: GenderType | null = null;
              const foundOption = genderOptions.find(
                (option) => option.value === selectedOptionValue
              );
              if (foundOption) {
                selectedOption = foundOption;
              }
              setGender(selectedOption);
              setSignupCredentials({
                ...signupCredentials,
                gender: selectedOptionValue,
              });
            }}
            className="relative w-[85%] py-[10px] px-[15px] border !border-greslernblue outline-none rounded-[5px] text-white text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]">
            <option className="text-[#292929]" hidden>
              {t("SelectGender")}
            </option>

            {genderOptions.map((option, k) => (
              <option
                className="text-[#292929]"
                key={`$genderOptions${k}${option.value}`}
                value={option.value}>
                {t(option.label? option.label :'')}
              </option>
            ))}
          </select>
          <select
            id="country"
            value={individualData?.country}
            disabled
            // onChange={(e) => {
            //   const selectedOptionValue = e.target.value;
            //   setSignupCredentials({
            //     ...signupCredentials,
            //     country: selectedOptionValue,
            //   });
            // }}
            className="relative w-[85%] py-[10px] px-[15px] border !border-greslernblue outline-none rounded-[5px] text-[#dfdfdfe6] text-base tracking-wider bg-[#0d77da1a] placeholder:text-[#dfdfdfe6]">
            <option hidden>
              {individualData?.country
              }
            </option>
            {/* {countryList.map((country, k) => (
              <option
                className="text-[#292929]"
                key={`$countryList${k}${country.name}`}
                value={country.name}>
                {country.name}
              </option>
            ))} */}
          </select>
          <input
            disabled
            className="relative w-[85%] py-[10px] px-[15px] border !border-greslernblue outline-none rounded-[5px] text-[#dfdfdfe6] text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]"
            id="institute"
            name="institute"
            type="text"
            placeholder={t("EnterInstitute/School")}
            value={instName}
            onChange={(e) =>
              setSignupCredentials({
                ...signupCredentials,
                institute: e.target.value,
              })
            }
          />
          <div className="flex flex-col justify-center space-y-2">
            <div className="flex items-center">
              <input
                required
                id="link-checkbox"
                type="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="link-checkbox"
                className="ml-2 text-sm font-medium text-white dark:text-gray-300">
                {t("Iagrewith")}{" "}
                <button
                  type="button"
                  onClick={() => setShowtermcondition(true)}
                  // onClick={() => openModal}
                  className="text-g-blue dark:text-blue-500 hover:underline">
                  {t("termsAndConditions")}
                </button>
              </label>
            </div>

            {/* <div className="flex items-center ">
              <input
                required
                id="linkprivacy-checkbox"
                type="checkbox"
                checked={isCheckedPrivacy}
                onChange={() => setIsCheckedPrivacy(!isCheckedPrivacy)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="linkprivacy-checkbox"
                className="ml-2 text-sm font-medium text-white dark:text-gray-300">
                {t("Iagrewith")}{" "}
                <button
                  type="button"
                  onClick={() => setShowPrivacyandCompliance(true)}
                  // onClick={() => openModal}
                  className="text-g-blue dark:text-blue-500 hover:underline">
                  {t("dataPrivacyPolicy")}
                </button>
              </label> 
            </div>*/}
          </div>
          <button
            className={`relative flex justify-center  mt-4 w-[85%] py-[10px] px-[15px] rounded-[5px] text-base tracking-wider transition-[0.5s] cursor-pointer 
                        ${loading
                ? "bg-[#2d2d39] text-white pointer-events-none"
                : "bg-white text-[#2d2d39] hover:!bg-[#243946] hover:text-white"
              }
                        `}
            type="submit"
            disabled={loading}>
            {loading ? (
              <Spin color="#ffffff" width="30px" height="30px" duration="1s" />
            ) : (
              t("Signup")
            )}
          </button>
        </form>
      </div>
      <div className="text-center mt-[15px] text-white text-[1rem] font-sans">
        <p>
          {t("Haveanaccount")}
          <button
            className="text-[#B75F2E] text-lg font-bold decoration-0 transition-all duration-200 ease-in-out delay-100 ml-1 hover:text-white hover:decoration-1 cursor-pointer"
            onClick={handleGoRightClick}>
            {t("Login")}
          </button>
        </p>
      </div>
      {showtermcondition ? (
        <Modal
          show={true}
          onHide={openModal}
          className="w-full h-full bg-transparent opacity-80 mt-12 ">
          <Modal.Body className="max-h-full overflow-y-scroll ">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-greslernorange">
              <h3 className="text-xl font-semibold text-greslernorange text-greslernorange">
                {t("termsofService")}
              </h3>
            </div>
            <div className="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
              <TermsAndConditionsContents />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-greslernorange">
              <button
                onClick={handleToggleCheckbox}
                data-modal-hide="defaultModal"
                type="button"
                className="text-white bg-greslernorange/80 hover:!bg-greslernorange focus:ring-4 focus:outline-none focus:ring-greslernorange font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-greslernorange/80 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Accept
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : undefined}
      {/* {showPrivacyandCompliance ? (
        <Modal
          show={true}
          onHide={openModal}
          className="w-full h-full bg-transparent opacity-80 mt-12">
          <Modal.Body className="max-h-full overflow-y-scroll ">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-greslernorange">
              <h3 className="text-xl font-semibold text-gray-900 text-black">
                Data Privacy Policy
              </h3>
            </div>
            <div className="p-6 space-y-6">
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                The organisation of southern cooperation(OSC) is committed to protecting the privacy of our users and comply with GDPR data privacy guidelines. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our application that integrates other modules and external Services.
              </p>
              <div>
                <h3>Information We Collect</h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  We may collect the following types of information:
                  <br /> <br />
                  Personal Information: Specific personal information collected by GreSLERN includes name, email address, Gender, and country of Origin.
                  <br /> <br />
                  Usage Information: We collect information about how you interact with our application.
                </p>
              </div>
              <div>
                <h3>How We Use Your Information</h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  We use the collected information for:
                  <br /> <br />
                  Providing and improving our services.
                  <br /> <br />
                  Personalizing your experience.
                  <br /> <br />
                  Analyzing usage patterns for optimizing our application.
                  <br /> <br />
                  Complying with legal obligations.
                </p>
              </div>
              <div>
                <h3>Data Sharing and Third-Party Integration</h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  GreSLERN may send anonymized statistical information with third-party service providers for the purpose of analytics. Our application integrates the H5P module, and by using GreSLERN. it may send anonymized statistical information to H5P. This data helps H5P to learn about problems with their module that will in turn help us to improve GreSLERN. It does not contain personal data.
                </p>
              </div>
              <div>
                <h3>Cookies</h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  GresLERN has content types that may use external services, and those may process personal data as demanded by Art. 6 GDPR users are informed that accessing those content.
                  <br /> <br />
                  In some content types, GreSLERN embed videos from YouTube. YouTube will set cookies that can be used to identify/track your users.
                  <br /> <br />
                  In some content types, GreSLERN embed feeds from Twitter. Twitter will set cookies that can be used to identify/track your users.
                </p>
              </div>
              <div>
                <h3>Voice</h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  In some content types, GreSLERN offer speech recognition. This service is currently possible using Chrome, which will use the Google Cloud services to convert voice to text.
                </p>
              </div>
              <div>
                <h3>Results</h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  GreSLERN will store data about users’ interactions on the platform. The results are to give the Student/Learner of a content the level of understanding of the content through the various assessments methodologies in the platform.
                  <br />
                  <br />
                  The above information doesnot entails use of personal information and the content creator doesnot have information about learners who completed the assessment In compliance with Art. 6 GDPR , Users are notified that the information about results is only known to the user.
                  <br />
                  <br />
                  It may be in your interest to process these data to learn something about your users, which may in turn help you to improve their learning outcome.
                  <br />
                  <br />
                  For each interaction that a user completes, GreSLERN may store the user’s account identifier, the start time, the finish time, the score that was achieved and the maximum score possible.
                </p>
              </div>
              <div>
                <h3>Saved content state</h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  GresLERN offers to save the content state of an interaction. GreSLERN will store the state of content type that a user is interacting with in regular intervals and when it is completed. As demanded by Art. 6 GDPR Users are notified that the above action does not Miss Use their personal information except for analytical purposes and progress tracking for the User/ Learner.
                  <br /> <br />
                  It may be in your interest to save the content state. It may be used to provide your users with the opportunity to interrupt their interactions and continue where they took off at a later point in time. It may also be used to provide your users with the option to review their interactions (e.g., their answers given) at a later point in time.
                  <br /> <br />
                  For each content/sub-content that a user interacts with, GreSLERN will store the current state (e.g., answers given, if a tutorial has already been watched, etc.), when the state was saved, and some technical information (should it be preloaded, should the data be reset when the content is changed by the author).
                </p>
              </div>
              <div>
                <h3>Security Measures </h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  We implement security such as encryption of data, and other cybersecurity industry standards for application security and measures to protect your information from unauthorized access, disclosure, alteration, and destruction.
                </p>
              </div>
              <div>
                <h3>Your Choices </h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  You can choose not to provide certain information, but this may limit your ability to use certain features of our application.
                  <br /> <br />
                  Users have the right to Access, rectify, erase, and export data when using the GreSLERN Application., As demanded in the  (Art. 15 GDPR), export (Art. 20 GDPR), rectify (Art. 16 GDPR) or erase (Art. 17 GDPR) personal data through their profile settings.                     </p>
              </div>
              <div>
                <h3>Updates to This Privacy Policy </h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  We may update this Privacy Policy to reflect changes in our practices. We will notify you of any material changes.
                  <br /> <br />
                  Contact Us: If you have any questions or concerns about this Privacy Policy, please contact us at tide@osc-ocs.org
                </p>
              </div>
              <div>
                <h3>References</h3>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  H5P Privacy Policy Guide. Available at: [H5P Privacy Policy Guide]
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={handleToggleCheckboxPrivacy}
                data-modal-hide="defaultModal"
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-greslernorange font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Accept
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : undefined} */}
    </>
  );
}

export default SignupForm;