import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NoData from "../NoData";
import NoRecordFound from "../../../NotRecoredFound";
const ActivityTable = ({ activityList, filterBy }) => {
  const [filteredData, setFilteredData] = useState(activityList);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 20;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredData.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const { t } = useTranslation();

  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
    else
      return;
  }
  useEffect(() => {
    if (filterBy) {
      const filtered = activityList.filter((item) => item.title.toLowerCase().includes(filterBy.toLowerCase())); // Filter based on category (modify based on your data structure)
      setFilteredData(filtered);
    } else {
      setFilteredData(activityList); // Show all data if no filter is applied
    }
  }, [activityList, filterBy]);
  return (
    <>
      {activityList.length <= 0 ?
        <div>
          <NoRecordFound />
        </div>
        :
        <>
          <table className="min-w-full leading-normal">
            <tbody>
              {records?.map((users, i) =>
                <tr
                  className=""
                  key={`${users._id}`}
                >
                  <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-center font-bold">
                    {i + 1}
                  </td>
                  <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm font-semibold">
                    <span className='text-red-500'>{users.title}</span>
                  </td>
                  <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                    {t("this action is taken by you or your members,If not please contact our support team")}
                  </td>
                  <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                    {moment(users.createdAt).format("LL")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <nav className="items-center">
            <ul className="list-style-none flex ml-12">
              <li className="page-item">
                <button className="pointer-events-none relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300" onClick={prePage}>
                  {t("Prev")}
                </button>
              </li>
              {numbers.map((n, i) =>
                <li
                  className={`${currentPage === n
                    ? "whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                    : "page-item"}`}
                  key={`adminUsertable${n.toString()}`}
                >
                  <button
                    className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100"
                    onClick={() => changeCPage(n)}
                  >
                    {n}
                  </button>
                </li>
              )}
              <li>
                <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100" onClick={nextPage}>
                  {t("Next")}
                </button>
              </li>
            </ul>
          </nav>
        </>}
    </>
  )
}
export default ActivityTable;