import React, { useEffect, useState } from 'react'
import Header from './Header'
import Hero from './Hero'
import Features from './Features'
import Platform from './Platforms'
import ShareResource from './ShareResource'
import About from './About'
import Collaborate from './Collaborate'
import Explorer from './Explorer'
import Footer from './Footer'
import ButtonScroll from './buttonScroll'
import SectionWrapper from '../../hoc'
import { Xwrapper, useXarrow } from "react-xarrows";

import { useSelector, useDispatch } from "react-redux";
import { selectAuthStatus, setAuthenticate } from '../../redux/Reducer/authSlice'
import { GetUserLogin } from '../../services'
import LineComponent from '../../components/lineComponent/idex'

function LandingPage() {

    const updateXarrow = useXarrow()

    const [lanTrigger, setLanTrigger] = useState('English')
    // const EnhancedFeatures = SectionWrapper(Features, 'features');
    // const EnhancedHero = SectionWrapper(Hero, '');

    const dispatch = useDispatch()
    const isAuthenticated = useSelector(selectAuthStatus)
    const checkAuth = async () => {
        const response = await GetUserLogin.isAuthenticate()
        if (isAuthenticated !== response) {
            dispatch(setAuthenticate(response))
        }
    }
    checkAuth();

    return (
        <div onScroll={updateXarrow}>
            <Xwrapper>

                <Header setLanTrigger={setLanTrigger} isAuthenticated={isAuthenticated} />
                <Hero id={'targetElementCreateBlue'} />
                <LineComponent
                    startPointId='targetElementCreateBlue'
                    endPointId='targetElementFeaturesCenter'
                />
                <Features id={'targetElementFeaturesCenter'} />
                <LineComponent
                    startPointId='targetElementFeaturesCenter'
                    endPointId='targetElementPlatformWhereverYellow'
                />
                <Platform id={'targetElementPlatformWhereverYellow'} id2={'targetElementPlatformWhereverRed'} />
                <LineComponent
                    startPointId='targetElementPlatformWhereverRed'
                    endPointId='targetElementSharedResourcePurple'
                />
                <ShareResource id={'targetElementSharedResourcePurple'} id2={'targetElementSharedResourceGreen'} />
                <LineComponent
                    startPointId='targetElementSharedResourceGreen'
                    endPointId='targetElementAboutLearnPurple'
                />
                <About id={'targetElementAboutLearnPurple'} id2={'targetElementAboutYellow'} />
                <LineComponent
                    startPointId='targetElementAboutYellow'
                    endPointId='targetElementCollaboratePurple'
                />
                <Collaborate id={'targetElementCollaboratePurple'} id2={'targetElementCollaborateYellow'} />
                <LineComponent
                    startPointId='targetElementCollaborateYellow'
                    endPointId='targetElementExploreBlue'
                />
                <Explorer id={'targetElementExploreBlue'} isAuthenticated={isAuthenticated} />
                <Footer />
                <ButtonScroll />
            </Xwrapper>
        </div>
    )
}

export default LandingPage