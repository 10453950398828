
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NotificationContainer } from 'react-notifications'
import { useSelector, useDispatch } from "react-redux";

import { ToastContainer } from "react-toastify";
import { selectAuthStatus, setAuthenticate, selectRole } from "./redux/Reducer/authSlice";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import RegisterInstitute from "./pages/SignupInstitute";
import { SocketSession } from "./function";

import RootRoutes from "./components/root/rootRoutes";
import { useTranslation } from 'react-i18next';
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Dashboard from "./components/root/dashboard";
import EmailVerification from "./pages/EmailVerification";
import InstituteEmailVerification from "./pages/InstituteEmailVerification";
import ForegetPassword from "./pages/PasswordReset";
import reactivate from "./pages/reactivateSection";
import ResetPassword from './pages/PasswordReset/reset';
import ReactivateReset from './pages/reactivateSection/reactivateaccount'
import LandingPage from "./pages/LandingPage";
import "react-notifications/lib/notifications.css";
import ComingSoon from "./pages/ComingSoon";
import { GetUserLogin } from "./services";
import DefaultLayout from "./components/AdminComponents/DefaultLayout";
import InstitutemainPage from "./components/institutePages/InstitutemainPage";
import AdminDashboard from "./components/AdminComponents/Dashboard";
import UsersTable from "./components/AdminComponents/components/tables/UsesTable"
import ContactUs from "./pages/ContactUs";
import Institutes from "./components/AdminComponents/Institutes";
import InstituteDetail from "./components/AdminComponents/components/InstituteDetail";
import Admin1Dashboard from "./components/institutePages/Dashboard";
import Individuls from "./components/institutePages/Institutes";
import InstituteProfile from "./components/institutePages/instituteProfile";
import NoticeBar from "./components/NoticeBar";
import InstituteDetailIn from './components/institutePages/components/InstituteDetail';
import UserDetailIn from './components/institutePages/components/userDetail';
import UserDetailadmin from './components/AdminComponents/components/userDetail';
import History from "./components/AdminComponents/History";
import ResourcesTable from './components/AdminComponents/components/tables/ResourcesTable';
import CommentsTable from "./components/AdminComponents/components/tables/CommentsTable";
import Profile from "./components/AdminComponents/adminProfile";
import Setting from "./components/root/setting";
import ISetting from "./components/institutePages/components/Setting";
import Report from "./components/institutePages/components/ReportAnalisis/Report";
import IActivity from "./components/institutePages/components/Activity";
import IResource from "./components/institutePages/components/Resource";

import ProfileEdit from "./components/root/dashboard/ProfileEdit";
import TermsAndConditions from "./pages/LandingPage/TermsAndConditions";
import PrivacyPolicy from "./pages/LandingPage/PrivacyPolicy";
import GreslernBrochure from "./pages/LandingPage/GreslernBrochureComponent";
import BulkAnnouncement from "./components/AdminComponents/BulkAnnouncement";
import ContentPlay from "./components/ContentFile/ContentPlay";
import ContentNew from "./components/ContentFile/ContentNew";
import SubscriptionConfirmation from "./pages/SubscriptionConfirmation";
import UnsubscribeConfirmation from "./pages/UnsubscribeConfirmation";
import UserProfile from "./components/root/dashboard/Profile";
import ResourceFiltering from "./pages/ResouceFiltering";

function App() {
    const { i18n } = useTranslation();
    const dispatch = useDispatch()
    const isAuthenticated = useSelector(selectAuthStatus)
    const usertype = useSelector(selectRole)

    const checkAuth = async () => {
        const response = await GetUserLogin.isAuthenticate()
        if (isAuthenticated !== response) {
            dispatch(setAuthenticate(response))
        }
    }


    useEffect(() => {
        const lng: string | null = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
        i18n.changeLanguage(lng!);
    }, []);

    const renderAdminRoute = (path, element) => {
        if (!isAuthenticated) {
            return <Navigate replace to="/" />; // Redirect to login if not authenticated
        }
        if (usertype === 'admin') {
            return element;
        }
        else if (usertype === 'institute') {
            return <Navigate replace to="/institute" />
        }
        else if (usertype === 'teacher' || usertype === 'student' || usertype === 'Other') {
            return <Navigate replace to="/home" />
        }
        else {
            return <Login />
        }
    }

    const renderProtectedRoute = (element) => {
        if (!isAuthenticated) {
            return <Navigate replace to="/" />; // Redirect to login if not authenticated
        }
        if (usertype === 'admin') {
            return <Navigate replace to="/admin" />
        }
        else if (usertype === 'institute') {
            return element
        }
        else if (usertype === 'teacher' || usertype === 'student' || usertype === 'Other') {
            return <Navigate replace to="/home" />
        }
        else {
            return <Login />
        }
    }
    const renderUserRoute = (element) => {
        if (!isAuthenticated) {
            return <Navigate replace to="/" />; // Redirect to login if not authenticated
        }
        if (usertype === 'admin') {
            return <Navigate replace to="/admin" />
        }
        else if (usertype === 'institute') {
            return <Navigate replace to="/institute" />
        }
        else if (usertype === 'teacher' || usertype === 'student' || usertype === 'Other') {
            return element
        }
        else {
            return <Login />
        }
    }

    checkAuth();
    SocketSession();

    return <BrowserRouter>
        <NotificationContainer />
        <ToastContainer /> {/* Notifications component  */}
        <Routes>
            <Route path="/home/*" element={renderUserRoute(<RootRoutes />)} />
            <Route path="/login" element={isAuthenticated ? <Navigate replace to="/home" /> : <Login />} />
            <Route path="/admin/*" element={renderAdminRoute("/admin", <DefaultLayout />)}>
                <Route index element={<AdminDashboard />} />
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="users" element={renderAdminRoute("/admin/users", <UsersTable />)} />
                <Route path="institutes" element={renderAdminRoute("/admin/institutes", <Institutes />)} />
                <Route path="history" element={renderAdminRoute("/admin/history", <History />)} />
                <Route path="detail/:_id" element={renderAdminRoute("/admin/detail/:_id", <InstituteDetail />)} />
                <Route path="userdetail/:email" element={renderAdminRoute("/admin/userdetail/:email", <UserDetailadmin />)} />
                <Route path="resources" element={renderAdminRoute("/admin/resources", <ResourcesTable />)} />
                <Route path="comments" element={renderAdminRoute("/admin/resources", <CommentsTable />)} />
                <Route path="profile" element={renderAdminRoute("/admin/profile", <Profile />)} />
                <Route path="mysetting" element={renderAdminRoute("/admin/mysetting", <Setting />)} />
                <Route path="editprofile" element={renderAdminRoute("/admin/editprofile", <ProfileEdit />)} />
                <Route path="myprofile" element={renderAdminRoute("/admin/myprofile", <UserProfile />)} />
                <Route path="announcement" element={renderAdminRoute("/admin/announcement", <BulkAnnouncement />)} />
                <Route path="play/:contentId" element={<ContentPlay />} />
                <Route path="create/" element={<ContentNew />} />

            </Route>
            <Route path="/institute/*" element={renderProtectedRoute(<InstitutemainPage />)}>
                <Route index element={<Admin1Dashboard />} />
                <Route path="dashboard" element={<Admin1Dashboard />} />
                <Route path="profile" element={<InstituteProfile />} />
                <Route path="report" element={<Report />} />
                <Route path="team" element={<Individuls />} />
                <Route path="detail/:email" element={<InstituteDetailIn />} />
                <Route path="userdetail/:email" element={<UserDetailIn />} />
                <Route path="myprofile" element={<UserProfile />} />
                <Route path="editprofile" element={<ProfileEdit />} />
                <Route path="myprofile" element={<UserProfile />} />
                <Route path="editprofile" element={<ProfileEdit />} />
                <Route path="mysetting" element={<ISetting />} />
                <Route path="activity" element={<IActivity />} />
                <Route path="resource" element={<IResource />} />
                <Route path="play/:contentId" element={<ContentPlay />} />
                <Route path="create/" element={<ContentNew />} />

            </Route>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={isAuthenticated ? <Navigate replace to="/home" /> : <Login />} />
            <Route path="/dashboard/*" element={isAuthenticated ? <Dashboard /> : <Navigate replace to="/" />} />
            <Route path="/signup/:token" element={isAuthenticated ? <Navigate replace to="/home" /> : <SignUp />} />
            <Route path="/registration/:token" element={isAuthenticated ? <Navigate replace to="/home" /> : <RegisterInstitute />} />
            <Route path="/verify" Component={EmailVerification} />
            <Route path="/verifyinstitute" Component={InstituteEmailVerification} />
            <Route path="/forgetpassword" Component={ForegetPassword} />
            <Route path="/reactivate" Component={reactivate} />
            <Route path="/resetPassword/:token" Component={ResetPassword} />
            <Route path="/reactivateReset/:token" Component={ReactivateReset} />
            <Route path="/comingsoon" Component={ComingSoon} />
            <Route path="/contactus" Component={ContactUs} />
            <Route path="/landingPageUserGuide" />
            <Route path="/GreslernBrochure" element={<GreslernBrochure />} />
            <Route path="/GreslernBrochure/fr" element={<GreslernBrochure language="fr" />} />
            <Route path="/GreslernBrochure/ar" element={<GreslernBrochure language="ar" />} />
            <Route path="/GreslernBrochure/es" element={<GreslernBrochure language="es" />} />
            <Route path="/privacy" Component={PrivacyPolicy} />
            <Route path="/terms" Component={TermsAndConditions} />
            <Route path="/confirmSubscription/:token" element={<SubscriptionConfirmation />} />
            <Route path="/unsubscribe/:email" element={<UnsubscribeConfirmation />} />
            <Route path="/resources" element={<ResourceFiltering />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
        <NoticeBar />
    </BrowserRouter>;

}

export default App;