import { useState, useEffect } from "react";

const BIEAssessmentScoring = ({ forwhat = "", onScoreChange, contentId, section, q, handleQuestionResult, result }) => {
  const [score, setScore] = useState(0);
  const [checked0, setChecked0] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);

  const onOptionChange = e => {
    setScore(e.target.value);
    // handleSingleSaveQ(e.target.value)
    onScoreChange(e.target.value - score, q);
    handleQuestionResult(q, e.target.value);
    resetAll()
    switch (e.target.value) {
      case '0':
        setChecked0(!checked0);
        break;
      case '1':
        setChecked1(!checked1);
        break;
      case '2':
        setChecked2(!checked2)
        break;
      case '3':
        setChecked3(!checked3)
        break;
      case '4':
        setChecked4(!checked4)
        break;
      case '5':
        setChecked5(!checked5)
        break;
      case 0:
        setChecked0(!checked0);
        break;
      case 1:
        setChecked1(!checked1);
        break;
      case 2:
        setChecked2(!checked2)
        break;
      case 3:
        setChecked3(!checked3)
        break;
      case 4:
        setChecked4(!checked4)
        break;
      case 5:
        setChecked5(!checked5)
        break;
      // ... add cases for other values if needed
      default:
      // Handle unexpected values (optional)
    }
  };
  const resetAll = () => {
    setChecked0(false)
    setChecked1(false)
    setChecked2(false)
    setChecked3(false)
    setChecked4(false)
    setChecked5(false)

  }
  useEffect(() => {
    switch (result) {
      case "0":
        setChecked0(!checked0);
        break;
      case "1":
        setChecked1(!checked1);
        break;
      case "2":
        setChecked2(!checked2);
        break;
      case "3":
        setChecked3(!checked3);
        break;
      case "4":
        setChecked4(!checked4);
        break;
      case "5":
        setChecked5(!checked5);
        break;
      case 0:
        setChecked0(!checked0);
        break;
      case 1:
        setChecked1(!checked1);
        break;
      case 2:
        setChecked2(!checked2);
        break;
      case 3:
        setChecked3(!checked3);
        break;
      case 4:
        setChecked4(!checked4);
        break;
      case 5:
        setChecked5(!checked5);
        break;
      default:
      // Handle the default case, for example, do nothing
    }

  }, [])

  const checked0ClassName = "bg-red-600 font-bold text-white"
  const checked1ClassName = "bg-red-400 font-bold text-white"
  const checked2ClassName = "bg-yellow-500 font-bold text-white"
  const checked3ClassName = "bg-blue-400 font-bold text-white"
  const checked4ClassName = "bg-green-400 font-bold text-white"
  const checked5ClassName = "bg-green-600 font-bold text-white";

  return <div className="">
    <div className=" md:w-1/4 grid grid-cols-6 gap-1 rounded-xl bg-gray-200 p-1">
      <div>
        <input type="radio" name={`${forwhat}Option`} id={`${forwhat}0`} value="0" className="peer hidden" onChange={onOptionChange} />
        <label htmlFor={`${forwhat}0`} className={`block cursor-pointer select-none rounded-xl p-1 text-center ${checked0 ? checked0ClassName : ""}`}>
          0
        </label>
      </div>
      <div>
        <input type="radio" name={`${forwhat}Option`} id={`${forwhat}1`} value="1" className="peer hidden" onChange={onOptionChange} />
        <label htmlFor={`${forwhat}1`} className={`block cursor-pointer select-none rounded-xl p-1 text-center ${checked1 ? checked1ClassName : ""}`}>
          1
        </label>
      </div>

      <div>
        <input type="radio" name={`${forwhat}Option`} id={`${forwhat}2`} value="2" className="peer hidden" onChange={onOptionChange} />
        <label htmlFor={`${forwhat}2`} className={`block cursor-pointer select-none rounded-xl p-1 text-center ${checked2 ? checked2ClassName : ""}`}>
          2
        </label>
      </div>

      <div>
        <input type="radio" name={`${forwhat}Option`} id={`${forwhat}3`} value="3" className="peer hidden" onChange={onOptionChange} />
        <label htmlFor={`${forwhat}3`} className={`block cursor-pointer select-none rounded-xl p-1 text-center ${checked3 ? checked3ClassName : ""}`}>
          3
        </label>
      </div>

      <div>
        <input type="radio" name={`${forwhat}Option`} id={`${forwhat}4`} value="4" className="peer hidden" onChange={onOptionChange} checked={result == 4} />
        <label htmlFor={`${forwhat}4`} className={`block cursor-pointer select-none rounded-xl p-1 text-center ${checked4 ? checked4ClassName : ""}`}>
          4
        </label>
      </div>
      <div>
        <input type="radio" name={`${forwhat}Option`} id={`${forwhat}5`} value="5" className="peer hidden" onChange={onOptionChange} checked={result == 5} />
        <label htmlFor={`${forwhat}5`} className={`block cursor-pointer select-none rounded-xl p-1 text-center ${checked5 ? checked5ClassName : ""}`}>
          5
        </label>
      </div>
    </div>
  </div>;
};

export default BIEAssessmentScoring;
