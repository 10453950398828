import { useTranslation } from "react-i18next";
import ContentHeader from "../addon/contentHeader";
import ContentAnalysys from "../../../ContentAnalysys";

const AnalysisDashboard = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex-1 px-2 sm:px-0">
                <ContentHeader title={`${t("Check Your analysis")}`}  showIcon={false}/>
                <ContentAnalysys />
            </div>
        </>
    );
};
export default AnalysisDashboard;
