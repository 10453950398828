import InstituresStatisticsCard from './components/cards/InstituresStatisticsCard';
import CommentsStatisticsCard from './components/cards/CommentsStatisticsCard';
import UsersStatisticsCard from './components/cards/UsersStatisticsCard';
import ContentStatisticsCard from './components/cards/ContentStatisticsCard';

import { GetAllCommentList, GetResource, GetUsers } from "../../services";
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import ContentHeader from '../root/dashboard/addon/contentHeader';
import { useSelector } from 'react-redux';
import { selectUserName } from '../../redux/Reducer/authSlice';
import UsersListCard from '../UsersListCard';
import { useNavigate } from 'react-router-dom';
import SubscribersComponent from '../SubsctibersComponent';

const AdminDashboard = () => {
  const [users, setUsers] = useState([])
  const [resources, setResources] = useState([]);
  const [comments, setComments] = useState([]);
  const [institutesList, setInstitutesList] = useState([]);
  const username = useSelector(selectUserName)
  const navigate = useNavigate()

  const fetchUsersList = async () => {
    try {
      const result = await GetUsers.getuserlistAll();
      setUsers(result);
    } catch (error) {
      console.error("Error fetching users list:", error);
    }
  };

  const fetchResourcesList = async () => {
    try {
      const result = await GetResource.getresource();
      setResources(result);
    } catch (error) {
      console.error("Error fetching resources list:", error);
    }
  };
  const fetchCommentList = async () => {
    try {
      const result = await GetAllCommentList.getAllCommentlist();
      setComments(result);
    } catch (error) {
      console.error("Error fetching feedbacks list:", error);
    }
  };

  const fetchInstitutesList = async () => {
    let resource = await GetUsers.getinstitutelist();
    if (resource) {
      setInstitutesList(resource);
    }
  };

  const userAction = (email) => {
    navigate(`/admin/userdetail/${email}`)
  }
  useEffect(() => {
    fetchUsersList()
    fetchResourcesList();
    fetchCommentList();
    fetchInstitutesList();

  }, [])

  return (
    <>
      <ContentHeader title={t("Admin area") + " - " + t("welcome") + " " + username} />
      <div className="flex flex-col md:flex-row gap-1 shrink  w-full">
        <div className=' md:w-1/5 md:m-2'><ContentStatisticsCard resources={resources} /></div>
        <div className=' md:w-2/5 md:m-2'><UsersStatisticsCard users={users} /></div>
        <div className=' md:w-1/5 md:m-2'><CommentsStatisticsCard comments={comments} /></div>
        <div className=' md:w-1/5 md:m-2'><InstituresStatisticsCard institutes={institutesList} /></div>
      </div>

      <div className='flex flex-col md:flex-row '>
        <div className="m-2 md:w-1/4">
          <ContentHeader title={t('users')} />
          <UsersListCard usersList={users} action={userAction} />
        </div>
        <div className="m-2 md:w-3/4">
          <ContentHeader title={t('subscribers')} />
          <SubscribersComponent />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
