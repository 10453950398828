import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { GetUserLogin } from '../../../services';
import { getCookie, getDateValues } from '../../../function';

function VerifyForm() {
    const { t } = useTranslation();
    const [verificationCode, setVerificationCode] = useState('');
    const [instituteVerificationCode, setInstituteVerificationCode] = useState('');

    const [loading, setLoading] = useState(false);
    const [countDown, setCountDown] = useState([0, 0, 0, 0]);

    // Function to send the verification code to the server
    const sendVerificationCode = async (verificationCode) => {
        setLoading(true);
        GetUserLogin.sendVerificationCode(verificationCode).then((verify) => {
            if (!verify) {
                NotificationManager.error('Failed Email verification', 'Verification');
                setLoading(false);
            }
        }).catch((error) => {
            if (error) {
                NotificationManager.error('Failed Email verification', 'Verification');
                setLoading(false);
            }
        });
    };
    // Function to send the institute verification code to the server
    const sendInstituteVerificationCode = async (instituteVerificationCode) => {
        setLoading(true);

        GetUserLogin.sendInstituteVerificationCode(verificationCode).then((verify) => {
            if (!verify) {
                NotificationManager.error('Failed Email verification', 'Verification');
                setLoading(false);
            }
        }).catch((error) => {
            if (error) {
                NotificationManager.error('Failed Email verification', 'Verification');
                setLoading(false);
            }
        });
    };

    const reSendVerificationCode = async () => {
        setLoading(true);
        try {
            const email = getCookie("VerificationEmail");

            if (email !== null && email !== undefined) {
                GetUserLogin.reSendVerificationCode(email).then((respond) => {
                    if (!respond) {
                        NotificationManager.error('Failed Email verification', 'Verification');
                        setLoading(false);
                    }
                }).catch((error) => {
                    if (error) {
                        NotificationManager.error('Failed Email verification', 'Verification');
                        setLoading(false);
                    }
                });
            } else {
                NotificationManager.error("Email couldn't find", 'Verification');
            }
        } catch (error) {
            if (error) {
                NotificationManager.error('Failed Email verification', 'Verification');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const email = getCookie("VerificationEmail");

        if (email !== null && email !== undefined) {
            GetUserLogin.getUserByEmail(email).then((respond) => {
                if (respond) {
                    const countDownDate = new Date(respond.expiration).getTime();
                    const interval = setInterval(() => {
                        const now = new Date().getTime();
                        const distance = countDownDate - now;
                        if (distance < 0) {
                            clearInterval(interval);
                            setCountDown([0, 0, 0, 0]);
                        } else {
                            setCountDown(getDateValues(distance));
                        }
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            console.error('Email is null or undefined.');
        }
    }, []);


    return (
        <div className='relative w-[400px] flex justify-center items-center rounded-[20px] bg-box animate-animate3 box-form h-auto py-[20px] before:content before:absolute before:w-full before:h-full before:bg-box-before before:animate-animate3 before:rounded-[20px] before:animation-delay-1000 after:content after:absolute after:inset-[8px] after:bg-g-blue after:bg-noise  after:rounded-[15px] after:border-4 after:border-transparent'>
            <form
                className='relative w-full flex justify-center items-center flex-col gap-[25px] py-0 px-[40px] z-[10]'
                onSubmit={(e) => {
                    e.preventDefault();
                    sendVerificationCode(verificationCode);
                }}>
                <div className="mb-1">
                    <h2 className='text-white text-[32px] text-center font-semibold'>{t("Verification")}</h2>
                    <label className="mb-2.5 block font-medium text-gray-400 text-center dark:text-white">
                        {t("VerificationP")}
                    </label>
                    <div className="relative">
                        <Form.Control
                            id="code"
                            name='verificationCode'
                            type="text"
                            required
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            placeholder={t("Code")}
                            className="w-full rounded-lg border !border-greslernorange border-stroke bg-gresLightOrange py-2 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        />
                        <div className="absolute right-4 top-2 text-greslernorange">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                    </div>
                    <div className='w-full flex justify-end mt-4'>
                        <button onClick={reSendVerificationCode} className='text-[#ffffffbf] decoreation-0 hover:text-[#B75F2E]'>{t("ResendVerification")}</button>
                    </div>
                </div>
                <div className="mb-2 w-full flex justify-center">
                    <input
                        type="submit"
                        value={t("Verify")}
                        className={` relative flex justify-center  mt-1 w-[85%] py-[10px] px-[15px] rounded-[5px] text-base tracking-wider transition-[0.5s] cursor-pointer
                ${loading
                                ? 'bg-[#2d2d39] text-white pointer-events-none'
                                : 'bg-white text-[#2d2d39] hover:!bg-[#243946] hover:text-white'}
              `}
                    />
                </div>
                <div className="text-center text-white ">
                    <p>{t("TimeRemaining")}<div className='border border-white text-greslernorange py-2 px-3 rounded'> {countDown[1]} : {countDown[2]} : {countDown[3]}</div> </p>
                </div>
            </form>
        </div>
    );
}

export default VerifyForm;
