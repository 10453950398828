import React from "react";
import coming from "../../components/assets/img/comingsoon/pngegg.png";
import { Link, useNavigate } from "react-router-dom";
const ComingSoon = () => {
    const navigate = useNavigate();
    return (
        <div className=" w-full h-screen   bg-right ">
            <div className="w-full h-full bg-triangle-pattern bg-cover flex justify-center items-center">
                <div className="w-4/12 ">
                    <div className="text-white flex flex-col items-center  font-bold uppercase">
                        <h1 className="text-[4rem]">This page is</h1>
                        <h2 className="text-[2rem]">under constraction</h2>
                    </div>
                    <img src={coming} alt="" className="w-full" />
                    <div className="w-full flex justify-center">
                        <button onClick={() => navigate(-1)}
                            className="w-auto text-white border-2 py-2 px-3 rounded hover:!text-greslernorange hover:!border-greslernorange"
                        >Go back</button>
                        {/* <Link to='/' className="w-auto text-white border-2 py-2 px-3 rounded hover:!text-greslernorange hover:!border-greslernorange">Back Home</Link> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
