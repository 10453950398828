import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import CreateGroupInstitute from '../../../../services/CreateGroupInstitute';
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faShare } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../../../ModalComponent';
import { GetNotification, GetResource, GetUsers } from '../../../../services';
import GetUserList from '../../../../services/GetUserList';
import { socket } from '../../../../apiConfig';
import { toast } from 'react-toastify';
import NoData from '../NoData';
import { useSelector } from 'react-redux';
import { selectEmail, selectInstitute } from '../../../../redux/Reducer/authSlice';
type instituteProps = {
    name: string;
    country: string;
    createdAt: any;
    email: string;
    generatedTokenExpiration: Date;
    status: string;
    _id: string;
    id: string;
};
type resourceProps = {
    username: string;
    creationDate: string;
    title: string;
    forcePublic: any;
    contentId: string;
    status: string;
    email: string;
};
export default function ListMembersResource({ usergroupid, groupName, details, handleListMember, updatecomponetu }) {
    const { t } = useTranslation();
    const [sharedTo, setSharedTo] = useState([]);
    const [show, setShow] = useState(true);
    const [adduserlist, setAdduserlist] = useState(false);
    const [islistdisplay, setIslistdisplay] = useState(true);

    const [membersListactive, setMembersListactive] = useState<instituteProps[]>([]);
    const [showAdd, setShowAdd] = useState(false);
    const [updatecomponent, setUpdatecomponent] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any[]>([]);
    const [filteredResources, setFilteredResources] = useState<resourceProps[]>([])
    const [filteredResourcesInInstitute, setFilteredResourcesInInstitute] = useState<resourceProps[]>([])
    const instEmail = useSelector(selectEmail)
    const accessTokenUsername = useSelector(selectEmail);
    const [singleGroupData, setSingleGroupData] = useState([]);
    const instituteId = useSelector(selectInstitute)
    let sharedContentIdList: any = []
    let members: any = []
    let resources: any = []
    let resourcesInInstitute: any = []
    const handleRemoveResource = async (contentid: string) => {
        const resourceDate = {
            usergroupid: usergroupid
        }
        let resource = await CreateGroupInstitute.removeResourceGroup(contentid, resourceDate);
        if (resource) {
            NotificationManager.success("Removed", "Resource");
            setUpdatecomponent(!updatecomponent);
            updatecomponetu();
        }
        else {
            NotificationManager.warning("Not removed", "Resource");
        }
    }
    const filterSingleGroupData = async (details: any, usergroupid: any) => {
        details.forEach(async data => {
            if (data._id === usergroupid) {
                setSingleGroupData(data.resources);
                data.resources.forEach((user) => {
                    sharedContentIdList.push(user.contentid)
                })
            }
        });
    };

    const saveShareNotifications = async (resId, receiver) => {
        await GetNotification.saveNotification(resId, "share", null, receiver, "Shared content");
    }
    const submitSharedResource = async (resourceId) => {
        try {
            const data = await GetUserList.shareResource(
                resourceId,
                sharedTo
            );
            sharedTo.forEach(async (element: any) => {
                saveShareNotifications(resourceId, element.id);
            });
            socket.emit('sharestatus', { resourceId, accessTokenUsername }, (error) => {
                if (error) {
                    console.error('Error emitting feedback status:', error);
                }
                else {

                    data && toast.success("Successfully shared");
                }
            });

        } catch (error) {
            console.error("Error sharing resource:", error);
            toast.error("Error sharing resource");
        }
    };
    const handleAddUsers = () => {
        setAdduserlist(!adduserlist);
        setIslistdisplay(!islistdisplay);
        setShowAdd(!showAdd);
    };
    const addSingleResourceGroup = async (contentid: string, title, email, usergroupid: string) => {
        const listContent = {
            contentid: contentid,
            title: title,
            owner: email
        }
        try {
            let data = await CreateGroupInstitute.addResourceToGroup(usergroupid ? usergroupid : "", listContent)
            submitSharedResource(contentid);
            if (!data.ok) {
                NotificationManager.success("Successfully Added", "Add to group");
                setUpdatecomponent(!updatecomponent);
            }
            else {
                NotificationManager.warning("Resources not Added", "Add to group")
            }
        } catch (error) {
            console.error('Error during add:', error);
            NotificationManager.warning("Resources not Added", "Add to group")
        }
    }
    const handleClose = () => {
        setShow(false);
    };
    const fetchlistofactiveusers = async () => {
        const data = await GetUsers.getmembersList(instituteId);
        if (data) {
            setMembersListactive(data);
        }
        else {
            setMembersListactive([]);
        }
    }
    const handleItemClick = (item: any) => {
        const optionIndex = selectedItem.findIndex(
            selected => selected === item
        );
        if (optionIndex !== -1) {
            setSelectedItem(
                selectedItem.filter((_, index) => index !== optionIndex)
            );
        } else {
            setSelectedItem([...selectedItem, item]);
        }
    };
    const addUserToGroup = async () => {
        try {
            selectedItem.forEach(async element => {
                addSingleResourceGroup(element.contentId, element.title, element.email, usergroupid);
                handleClose();
            });
            updatecomponetu();
        } catch (e) { console.log(e); }
    }
    const fetchResourcesList = async () => {
        try {
            const result = await GetResource.getresource();
            const usersinInstitute = await GetUserList.getmembersList(instituteId)
            const users = await CreateGroupInstitute.getListUsersingroup(usergroupid);
            setSharedTo(users);
            const membersInInstitute: any = []

            users.forEach((user: any) => {
                if (user.institute == instEmail) {
                    members.push(user._id)
                }
            })
            result.forEach((r: any) => {
                if (members.includes(r?.userId?.toString()) && r.status === "public") {
                    resources.push(r)
                }
            })
            setFilteredResources(resources);
            usersinInstitute.forEach((user: any) => {
                if (user.institute == instEmail && user.userId !== members.includes(user?.userId?.toString())) {
                    membersInInstitute.push(user._id)
                }
            })
            result.forEach((r: any) => {
                if (membersInInstitute.includes(r?.userId?.toString()) && r.status === "public" && !members.includes(r?.userId?.toString()) && !sharedContentIdList.includes(r?.contentId?.toString())) {
                    resourcesInInstitute.push(r)
                }
            })
            setFilteredResourcesInInstitute(resourcesInInstitute);
        } catch (error) {
            console.error("Error fetching resources list:", error);
        }
    };
    useEffect(() => {
        fetchlistofactiveusers();
        filterSingleGroupData(details, usergroupid);
        fetchResourcesList();
    }, [updatecomponent]);
    return (
        <ModalComponent isOpen={show} title={false} onClose={handleClose}>
            <div className="bg-white p-1 rounded-lg shadow-lg w-full">
                <div className='flex flex-row justify-between items-center'>
                    <div className='align-middle'>{t("Resource's")} {t("in")} {groupName}</div>
                    {showAdd ?
                        <div>
                            <button onClick={(e) => {
                                e.preventDefault()
                                addUserToGroup()
                            }}
                                className="flex-no-shrink p-1 border-2 rounded text-green"
                            >
                                <FontAwesomeIcon icon={faShare} />{t("Share")}
                            </button>
                        </div>
                        :
                        <div className=''>
                            <button onClick={() => {
                                handleAddUsers();
                            }}
                                className="flex-no-shrink p-1 border-2 rounded text-green">
                                <FontAwesomeIcon icon={faAdd} />{t("Share")}
                            </button>
                        </div>
                    }
                </div>
                {adduserlist &&
                    <div className=" box-content">
                        <label className="block mb-2 font-bold text-white">
                            {membersListactive.length} {t("users selected")}{" "}
                        </label>
                        {filteredResourcesInInstitute?.length <= 0 ?
                            <div className="h-[15.5rem] overflow-y-scroll scroll-py-6  snap-y scroll-smooth focus:scroll-auto hideScrollbar resize-y ">
                                <NoData />
                            </div> :
                            <div className="h-auto overflow-y-scroll scroll-py-6  snap-y scroll-smooth focus:scroll-auto hideScrollbar resize-y ">
                                <div>
                                    <ul className=" w-full ">
                                        {filteredResourcesInInstitute.map((item, index) => (
                                            <li
                                                key={item.contentId}
                                                onClick={() => handleItemClick(item)}
                                                className={`p-3 sm:py-4 cursor-pointer bg-white border border-gray-300 rounded-md shadow-md mt-1 
                          ${selectedItem.some(selected => selected.contentId === item.contentId) &&
                                                    "!bg-blue-200  !text-white"}`
                                                }
                                            >
                                                <div className="flex items-center space-x-4">
                                                    <div className="flex-1 min-w-0">
                                                        <p
                                                            className={`text-sm font-semibold truncate dark:text-white ${selectedItem.some(
                                                                selected => selected === item.email
                                                            )
                                                                ? " text-white-900"
                                                                : " !text-white-200"}`}
                                                        >
                                                            {item.title}
                                                        </p>
                                                        <p className="text-sm text-blue-500 truncate dark:text-gray-400">
                                                            {item.email}
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {item.status}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    {/* {selectedItem && <p>Selected Item: {selectedItem}</p>} */}
                                </div>
                            </div>
                        }

                    </div>
                }
                {islistdisplay &&
                    <div className="w-full flex bg-teal-lightest font-sans">
                        <div className="bg-white rounded shadow p-1 w-full">
                            <div className="mb-4 items-center">
                                <h1 className="text-center text-warning">{t("Shared Resources")}</h1>
                            </div>
                            {singleGroupData.length <= 0 ?
                                <NoData />

                                :
                                <>
                                    {singleGroupData?.map((user: any) =>
                                        <div className="flex flex-row justify-between mb-4 items-center" key={user.contentid}>
                                            <div className=''>
                                                <div><h6 className="font-bold">{user.title}</h6></div>
                                                <div><p className="w-full font-light text-sm">{user.owner}</p></div>
                                            </div>

                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleRemoveResource(user.contentid);
                                                }}
                                                className="flex-no-shrink p-2 ml-2 border-2 rounded text-red border-warning hover:text-yellow-300 hover:bg-warning">{t("Remove")}</button>
                                        </div>
                                    )}
                                </>
                            }
                            <div className="mb-4">
                                <hr className="text-blue-500" />
                                <h1 className="text-center text-warning">{t("Member's resources")}</h1>
                            </div>
                            {
                                filteredResources.length <= 0 ? <NoData />

                                    :
                                    <>

                                        {filteredResources?.map((user) =>
                                            <div key={user.email}>
                                                <div className="flex flex-row justify-between mb-2" >
                                                    <div className=''>
                                                        <div><h6 className="font-bold">{user.title}</h6></div>
                                                        <div><p className="w-full font-light text-sm">{user.email}</p></div>
                                                    </div>
                                                    <button disabled className="flex-no-shrink p-1 ml-2 mr-1 border-2 rounded text-green"><span className='text-sm font-light'>{user.status}</span></button>
                                                </div>
                                            </div>

                                        )}

                                    </>
                            }
                        </div>
                    </div>
                }
            </div>
        </ModalComponent>
    );
}

