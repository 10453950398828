import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';

import logo from '../../components/assets/GreSLERN-logo.svg'
import Pin from '../../components/assets/img/Email/pin.png'
import Email from "../../components/form/email";
import 'leaflet/dist/leaflet.css';
import Header from '../LandingPage/Header';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthStatus, setAuthenticate } from '../../redux/Reducer/authSlice';
import GetUserLogin from '../../services/GetUserLogin';
import DefaultLandingPageLayout from '../LandingPage/DefaultLandingPageLayout';

const ContactUs = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const position: [number, number] = [8.986608703210461, 38.73776979269488];
    const isAuthenticated = useSelector(selectAuthStatus)
    const checkAuth = async () => {
        const response = await GetUserLogin.isAuthenticate()
        if (isAuthenticated !== response) {
            dispatch(setAuthenticate(response))
        }
    }
    checkAuth();

    const customIcon = new L.Icon({
        iconUrl: Pin,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
    });

    return (
        <DefaultLandingPageLayout>
            <div className="max-w-screen-2xl mx-auto lg:py-20 py-10 px-4 sm:px-10 text-gray-800">
                <div className=" text-center px-4 sm:px-6 lg:px-8 pb-6 mx-auto">
                    <h1 id='privacy' className="mb-2 text-xl font-bold sm:text-6xl text-greslernorange">{t("Contactus")}</h1>
                    {/* <div className="flex items-center justify-center text-greslernorange inset-0">
                        <h2 className="text-3xl font-bold"></h2>
                    </div> */}
                </div>
                <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8 ">
                    <div className="border border-greslernorange  p-10 rounded-lg text-center">
                        <span className="flex justify-center text-4xl text-emerald-500 mb-4">
                            <svg className="stroke-greslernorange" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                </path>
                                <polyline points="22,6 12,13 2,6"></polyline>
                            </svg>
                        </span>
                        <h5 className="text-xl mb-2 font-bold">{t("EmailUs")}</h5>
                        <p className="mb-0 text-base opacity-90 leading-7">
                            <a href="mailto:contactus@greslern.org" className="text-greslernorange">contactus@greslern.org</a>
                            {" "} {t("EmailUsP")}
                        </p>
                    </div>
                    <div className="border border-greslernorange  p-10 rounded-lg text-center">
                        <span className="flex justify-center text-4xl text-emerald-500 mb-4">
                            <svg className="stroke-transparent" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                </path>
                            </svg>
                        </span>
                        <button className="flex justify-center items-center w-full" onClick={() => navigate('/')}>
                            <img
                                alt="GreSELERN Logo"
                                src={logo}
                                className="max-w-xs w-[200px] md:w-[300px]"
                            />
                        </button>
                    </div>
                    <div className="border border-greslernorange  p-10 rounded-lg text-center">
                        <span className="flex justify-center text-4xl text-emerald-500 mb-4">
                            <svg className="stroke-greslernorange" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                <circle cx="12" cy="10" r="3">
                                </circle>
                            </svg>
                        </span>
                        <h5 className="text-xl mb-2 font-bold">{t("LOCATION")}</h5>
                        <p className="mb-0 text-base opacity-90 leading-7">
                            {" "}  Egypt St., Lideta Sub-City, Woreda 04,House N° 1986, 1165 Addis Ababa, Ethiopia
                        </p>
                    </div>
                </div>
                {/* Map */}
                <div className="border border-greslernorange p-2 rounded-lg  mt-24 ">
                    <div className="text-left">
                        <h1 id='privacy' className="mb-2 text-xl font-bold sm:text-2xl text-greslernorange">{t("Headquarters")}</h1>
                        {/* <div className="flex items-center justify-center text-greslernorange inset-0">
                            <h2 className="text-3xl font-bold"></h2>
                        </div> */}
                    </div>
                    <p className='p-2 md:w-1/2'>
                        Headquartered in <b className='text-greslernorange'>Addis Ababa, Ethiopia</b>, the membership of the OSC comprises countries from Africa, Asia-Pacific, Latin America and the Caribbean, and the Middle East.
                    </p>
                    <MapContainer center={position} zoom={13} scrollWheelZoom={true} style={{ height: '50vh', width: '100%', zIndex: 0 }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position} icon={customIcon}>
                            <Popup>
                                Egypt St., Lideta Sub-City, <br />Woreda 04,House N° 1986, 1165 <br /> Addis Ababa, Ethiopia
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
                {/* Contact form */}
                <Email />
            </div>
        </DefaultLandingPageLayout>
    );
};

export default ContactUs;
