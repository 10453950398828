import React, { useEffect, useState, useRef } from 'react'
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModalComponent from '../ModalComponent';
import LoginForm from '../form/login';
import { GetUserLogin } from '../../services';
import { selectUserName } from '../../redux/Reducer/authSlice';

function SessionModal() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const userName = useSelector(selectUserName)
    const [open, setOpen] = useState(false)
    const hasMounted = useRef(false);

    useEffect(() => {
        console.log(userName)
        if (hasMounted.current) {
            if (userName !== '') {
                setOpen(false);
            }
        } else {
            setOpen(true)
            hasMounted.current = true;
        }
    }, [userName]);

    const handleGoLeftClick = () => {
        console.log("Handle go left")
    };
    const logout = async () => {
        setOpen(false)
        let response = await GetUserLogin.getUserLogOut()
        if (response) {
            GetUserLogin.Logout(dispatch, () => {
                hasMounted.current = false;
                window.location.href = "/"
            })
        }
    };
    const notification = () => {
        NotificationManager.warning(' login again or Logout');
    };

    return (
        <ModalComponent isOpen={open} onClose={notification} title="Session Expired">
            <div className='w-full flex flex-col justify-center items-center'>
                <LoginForm handleGoLeftClick={handleGoLeftClick} />
                <button
                    onClick={logout}
                    className='relative flex justify-center  mt-4 w-[85%] py-[10px] px-[15px] rounded-[5px] text-base tracking-wider transition-[0.5s] cursor-pointer bg-[#2d2d39] text-white'>
                    {t("Logout")}</button>
            </div>
        </ModalComponent>
    )
}

export default SessionModal