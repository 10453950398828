import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Spin } from 'react-cssfx-loading'
import { NotificationManager } from 'react-notifications'

import { selectEmail } from '../../../redux/Reducer/authSlice';
import NotificationSettings from '../../../services/NotificationSettings';
import ContentHeader from "../dashboard/addon/contentHeader";
import { t } from 'i18next';

function Setting() {
  const [selectedOption, setSelectedOption] = useState('option1');
  const [loading, setLoading] = useState(false)
  let [notificationSettings, setNotificationSettings] = useState({
    like: { email: false, sound: false, popups: false },
    tag: { email: false, sound: false, popups: false },
    comment: { email: false, sound: false, popups: false },
  });
  const email = useSelector(selectEmail);

  const handleCheckboxChange = (type, field) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      [type]: {
        ...prevSettings[type],
        [field]: !prevSettings[type][field],
      },
    }));
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const saveSettings = async () => {
    try {
      setLoading(true)
      NotificationSettings.saveNotificationSettings(email, notificationSettings).then(
        (response) => {
          if (response) {
            NotificationManager.success("Update Sucessfily", "Notification")
            fetchInitialSettings();
            setLoading(false)
          }
          else {
            NotificationManager.error("Error occure, Try Again", "Notification")
            setLoading(false)
          }
        }
      ).catch((error) => {
        NotificationManager.error("Error occure, Try Again", "Notification")
        setLoading(false)
      })

    } catch (error) {
      console.error('Error saving settings:', error);
      NotificationManager.error("Error occure, Try Again", "Notification")
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };
  const fetchInitialSettings = async () => {
    try {
      const initialSettings = await NotificationSettings.getNotificationSettings(email);
      if (initialSettings) {
        setNotificationSettings(initialSettings);
      }
    } catch (error) {
      console.error('Error fetching initial settings:', error);
    }
  };
  // Use this effect to fetch initial settings from the server
  useEffect(() => {
    fetchInitialSettings();
  }, []); // Empty dependency array to run this effect only once when the component mounts

  return <div className="w-full h-auto flex flex-col py-[16px] px-[32px]">
    <ContentHeader title={`${t("Settings")}`} />
    <div className="flex flex-col md:flex-row w-full my-4">
      <div className="col-span-3 p-2">
        <h2 className="text-white text-[18px] mb-2">
          {t("Notifications")}
        </h2>
        <p className="text-[#637381] text-[15px]">
          {t("changeNotification")}
        </p>
      </div>
      <div className="col-span-9 bg-white rounded-[0.375rem] p-3 dark:!bg-gray-600">
        <h2 className="text-[18px] mb-6 font-bold dark:text-white">
          {t("EmailSoundPopups")}{" "}
        </h2>

        <div className="relative overflow-x-auto ">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {t("ActivityAndConversation")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("Email")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("Sound")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("Popups")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 dark:!bg-gray-600" data-type="like">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {t("WhenSomeoneLikes")}
                </th>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.like.email} onChange={() => handleCheckboxChange("like", "email")} />
                </td>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.like.sound} onChange={() => handleCheckboxChange("like", "sound")} />
                </td>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.like.popups} onChange={() => handleCheckboxChange("like", "popups")} />
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 dark:!bg-gray-600" data-type="tag">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {t("WhenSomeoneTags")}
                </th>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.tag.email} onChange={() => handleCheckboxChange("tag", "email")} />{" "}
                </td>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.tag.sound} onChange={() => handleCheckboxChange("tag", "sound")} />{" "}
                </td>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.tag.popups} onChange={() => handleCheckboxChange("tag", "popups")} />{" "}
                </td>
              </tr>
              <tr className="bg-white dark:bg-gray-800 dark:!bg-gray-600" data-type="feedback">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {t("WhenSomeooneFeedbacks")}
                </th>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.comment.email} onChange={() => handleCheckboxChange("comment", "email")} />{" "}
                </td>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.comment.sound} onChange={() => handleCheckboxChange("comment", "sound")} />{" "}
                </td>
                <td className="px-6 py-4">
                  <input type="checkbox" checked={notificationSettings.comment.popups} onChange={() => handleCheckboxChange("comment", "popups")} />{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        {/* <div className="relative overflow-x-auto mt-4">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Project Activity
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Sound
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Popups
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            When someone adds me to a project
                                        </th>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
        <hr />
        {/* <div className="relative overflow-x-auto mt-4">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Team Activity
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Sound
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Popups
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            AWhen my invitees sign up
                                        </th>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            When someone requests access to my team
                                        </th>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                    </tr>
                                    <tr className="bg-white dark:bg-gray-800">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            When someone invites me to a team
                                        </th>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                        <td className="px-6 py-4">
                                            <input type='checkbox' />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
        <div className="grid grid-cols-12 py-2 flex justify-start items-center">
          <div className="col-span-12 flex justify-start">
            <button disabled={loading} onClick={saveSettings} className={`border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 text-white  ${loading ? 'bg-transparent' : 'bg-greslernorange'} `}>
              {loading ? <Spin color="#ffffff" width="30px" height="30px" duration="1s" /> : t("SaveChanges")}
            </button>
            <button onClick={handleGoBack} className="border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 text-white bg-greslernorange">
              {t("goBack")}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col md:flex-row w-full ">
      <div className="col-span-3 p-2">
        <h2 className="text-white text-[18px] mb-2 dark:text-white">
          {t("Preferences")}
        </h2>
        <p className="text-[#637381] text-[15px]">
          {t("configPreferences")}
        </p>
      </div>
      <div className="col-span-9 bg-white rounded-[0.375rem] p-3 dark:!bg-gray-600">
        <h2 className="text-[18px] mb-6 font-bold dark:text-white">
          {t("Preferences")}
        </h2>
        <div className="grid grid-cols-12 py-2 flex items-center">
          <h2 className="col-span-3 text-[16px] mb-2 dark:text-white">
            {t("Languages")}{" "}
          </h2>
          <select className="col-span-9 border border-solid border-2 py-2 px-3 rounded-[0.375rem]">
            <option hidden>
              {t("SelectLanguages")}
            </option>
            <option>
              {t("English")}
            </option>
            <option>
              {t("French")}
            </option>
            <option>
              {t("Arabic")}
            </option>
            <option>
              {t("Spanish")}
            </option>
          </select>
        </div>
        <div className="grid grid-cols-12 py-2 flex items-center">
          <h2 className="col-span-3 text-[16px] mb-2 dark:text-white">
            {t("TimeZone")}{" "}
          </h2>
          <select className="col-span-9 border border-solid border-2 py-2 px-3 rounded-[0.375rem]">
            <option hidden>
              {t("selectTimeZone")}
            </option>
            <option>GMT +1:00</option>
            <option>GMT +2:00</option>
            <option>GMT +3:00</option>
            <option>GMT +4:00</option>
          </select>
        </div>
        <div className="grid grid-cols-12 py-2 flex items-center">
          <h2 className="col-span-3 text-[16px] mb-2 dark:text-white">
            {t("DateFormat")}{" "}
          </h2>
          <select className="col-span-9 border border-solid border-2 py-2 px-3 rounded-[0.375rem]" placeholder="Confirm new password">
            <option hidden>
              {t("selectDateFormat")}
            </option>
            <option>
              {t("NoPreferance")}
            </option>
            <option>
              {t("Preferance")}
            </option>
          </select>
        </div>
        <div className="grid grid-cols-12 py-2 flex items-center">
          <h2 className="col-span-3 text-[16px] mb-2 dark:text-white">
            {t("Default")}{" "}
          </h2>
          <div className="flex">
            <input className="mr-2 text-greslernorange h-5 w-5" type="radio" id="option1" value="option1" checked={selectedOption === "option1"} onChange={handleOptionChange} />
            <label className="text-[#637381]" htmlFor="option1">
              {t("on")}
            </label>
          </div>

          <div className="flex">
            <input className="mr-2 text-greslernorange h-5 w-5" type="radio" id="option2" value="option2" checked={selectedOption === "option2"} onChange={handleOptionChange} />
            <label className="text-[#637381]" htmlFor="option2">
              {t("off")}
            </label>
          </div>
        </div>
        <div className="grid grid-cols-12 py-2 flex items-center">
          <div className="col-span-3 text-[16px] mb-2"> </div>
          <div className="col-span-9 flex justify-start">
            <button className=" border border-1 border-solid    rounded-[0.375rem] px-3 py-2 mr-5 text-white bg-gray-300 rounded focus:outline-none" disabled>
              {t("SaveChanges")}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default withTranslation()(Setting)