import React, { useState } from 'react'
import { selectAuthStatus, setAuthenticate } from '../../../redux/Reducer/authSlice'
import { GetUserLogin } from '../../../services'
import Header from '../Header'
import { useDispatch, useSelector } from 'react-redux'

import Left_Corner from '../../../components/assets/img/hero/Capa_L.svg'
import Right_Corner from '../../../components/assets/img/hero/Capa_R.svg'

import Left_B_Corner from '../../../components/assets/img/hero/Capa_L_gray.png'
import Right_B_Corner from '../../../components/assets/img/hero/Capa_R_gray.png'
import Footer from '../Footer'
import ButtonScroll from '../buttonScroll'

const DefaultLandingPageLayout = ({children}) => {

    const dispatch = useDispatch()
    
    const [lanTrigger, setLanTrigger] = useState('English')
    const isAuthenticated = useSelector(selectAuthStatus)
    
    const checkAuth = async () => {
        const response = await GetUserLogin.isAuthenticate()
        if (isAuthenticated !== response) {
            dispatch(setAuthenticate(response))
        }
    }
    checkAuth();
  return (
    <>
        <div className=''>
            <Header isAuthenticated={isAuthenticated} setLanTrigger={setLanTrigger} />
        </div>
        <div className='bg-white  md:h-auto  w-full mt-[5rem] min-h-screen'
        style={{
            backgroundImage: `url(${Left_Corner}), url(${Right_Corner}), url(${Left_B_Corner}), url(${Right_B_Corner})`,
            backgroundRepeat: 'no-repeat, no-repeat, no-repeat, no-repeat',
            backgroundPosition: 'left top, right top, left bottom, right bottom',
        }}
        >
        
        {children}    
        </div>
        <Footer />
        <ButtonScroll />
        </>
  )
}

export default DefaultLandingPageLayout