import { Apis } from "../../config";
import api, { axiosFile } from "../../apiConfig";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { NotificationManager } from "react-notifications";

import AxiosErrorHandling from "../../utils/AxiosErrorHandling";

const createinstituteinvitation = async (userdata: any) => {
  try {
    const response: AxiosResponse = await api.post(Apis.CreateInstituteInvitation, userdata);
    if (response.status === 200) {
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Generate")
        return null;
      } else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const createinstituteinvitationexistuser = async (userdata: any) => {
  try {
    const response: AxiosResponse = await api.post(Apis.CreateInstituteInvitationexistuser, userdata);
    if (response.status == 200) {
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Generate")
        return null;
      }
      else if (axiosError.response?.status === 401) {
        NotificationManager.warning("Email could not be sent", "Change admin")
        return null;
      }
      else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const createexistinstituteinvitationexistuser = async (userdata: any) => {
  try {
    const response: AxiosResponse = await api.post(Apis.CreateExistInstituteInvitationexistuser, userdata);
    if (response.status == 200) {
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Change admin")
        return null;
      }
      else if (axiosError.response?.status === 401) {
        NotificationManager.warning("Email could not be sent", "Change admin")
        return null;
      }
      else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const createexistinstituteinvitationnewuser = async (userdata: any) => {
  try {
    const response: AxiosResponse = await api.post(Apis.CreateExistInstituteInvitationnewuser, userdata);
    if (response.status == 200) {
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Change admin")
        return null;
      }
      else if (axiosError.response?.status === 401) {
        NotificationManager.warning("Email could not be sent", "Change admin")
        return null;
      }
      else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const createindividualsinvitation = async (userdata: any) => {
  try {
    let response = await api.post(Apis.CreateIndividualsInvitation, userdata)
    console.log(response, 'response');
    if (response.status == 200) {
      return response.data
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Invitation")
        return null;
      }
      else if (axiosError.response?.status === 401) {
        NotificationManager.warning("Email could not be sent", "Invitation")
        return null;
      }
      else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const createindividualsinvitationCvs = async (userdata: any) => {
  try {
    let response = await axiosFile.post(Apis.CreateIndividualsInvitationCsv, userdata)
    if (response.status == 200) {
      return response.data
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Invitation")
        return null;
      }
      else if (axiosError.response?.status === 401) {
        NotificationManager.warning("Email could not be sent", "Invitation")
        return null;
      }
      else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
const createinstitutesinvitationCvs = async (userdata: any) => {
  try {
    let response = await axiosFile.post(Apis.CreateInstituteInvitationCsv, userdata)
    if (response.status == 200) {
      NotificationManager.success("link sent to you email", "Generate")
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 404) {
        NotificationManager.warning("Email is Occupaied", "Invitation")
        return null;
      }
      else if (axiosError.response?.status === 401) {
        NotificationManager.warning("Email could not be sent", "Invitation")
        return null;
      }
      else {
        AxiosErrorHandling(error)
        throw error;
      }
    }
  }
};
export default {
  createinstituteinvitation,
  createindividualsinvitation,
  createindividualsinvitationCvs,
  createinstitutesinvitationCvs,
  createinstituteinvitationexistuser,
  createexistinstituteinvitationexistuser,
  createexistinstituteinvitationnewuser

}