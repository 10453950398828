import { useEffect, useState } from 'react';
import ModalComponent from '../../ModalComponent';
import ContentNew from '../../ContentFile/ContentNew';

export default function CreateRosource() {

    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false);
    };
    useEffect(() => {
    }, []);
    return (
        <ModalComponent isOpen={show} title={false} onClose={handleClose}>
            <ContentNew/>
        </ModalComponent>
    );
}
