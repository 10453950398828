import { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { faAdd, faArrowDown, faBackward, faBan, faCheck,faEdit, faTrashCanArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationManager } from "react-notifications";
import countries from 'countries-list';
import { API_URL } from '../../../apiConfig';
import { GetUsers } from '../../../services';
import CreateGroupInstitute from '../../../services/CreateGroupInstitute';
import { Spin } from 'react-cssfx-loading';
import ModalComponent from '../../ModalComponent';
import UserAnalisis from './ReportAnalisis/UserAnalisis';
import { useSelector } from 'react-redux';
import { selectInstitute } from '../../../redux/Reducer/authSlice';
type profileprops = {
    id: any;
    profilephoto: string;
    name: string;
    email: String;
    bio: string;
    type: string;
    gender: string;
    country: string;
    username: string;
    phone: string;
    createdAt: Date;
    status: String;
    institute: string;
};
const UserDetail = () => {

    const {email } = useParams()
    const [instituteDetails, setInstituteDetails] = useState<profileprops[]>([]);
    const { t } = useTranslation();
    const [bio, setBio] = useState<any>();
    const [gender, setGender] = useState<any>();
    const [country, setSelectedCountry] = useState('');
    const countryList = Object.values(countries.countries);
    const [name, setName] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [deleteComfirmation, setDeleteComfirmation] = useState(false);
    const [banComfirmation, setBanComfirmation] = useState(false);
    const [resetNormal, setResetNormal] = useState(false);
    const [instName, setInstName] = useState('');
    const [addTogroup, setAddTogroup] = useState(false);
    const [result, setResult] = useState<profileprops[]>([]);
    const [groupId, setGroupId] = useState();
    const [groupIdUser, setGroupIdUser] = useState();
    const [showAnalisis, setShowAnalisis] = useState(false);
    const [loading, setLoading] = useState(false);
    const [groupResult, setGroupResult] = useState<profileprops[]>([]);
    const [subgroupId, setSubGroupId] = useState('');
    const instituteId = useSelector(selectInstitute)

    const getInstituteDetail = async () => {
        // const instuteIdDetail={instituteId : instituteId }
        const data = await GetUsers.getuserdataforinst( email ? email : "" );
        // console.log(instuteIdDetail,"getuserdataforinst");
        if (data) {
            setInstituteDetails(data);
            setName(data[0].name);
            setGender(data[0].gender);
            setSelectedCountry(data[0].country);
            setBio(data[0].bio);
            setGroupIdUser(data[0].groupId);
        }
        else {
            setInstituteDetails([]);
        }
    }
    const fetchInstituteInfo = async () => {
        const data = await GetUsers.getinstitutedetailRegistration(instituteId);
        if (data) {
            setInstName(data.institutionName);
        }
        else {
            setInstName('');
        }
    }
    const deleteInstitute = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.deleteUser(email ? email : '');
            if (data) {
                NotificationManager.success("Successfull deleted", "User");
                window.location.reload();
            }
            else {
                NotificationManager.warning("Not deleted", "User")
            }
            // window.location.reload();
        } catch (error) {
            NotificationManager.warning("Not deleted", "User")
        }
        setDeleteComfirmation(false);
    }

    const openModal = () => {
        setShowForm(!showForm);
    };

    const openDelete = () => {
        setDeleteComfirmation(!deleteComfirmation);
        setShowForm(false);
        setDeleteComfirmation(false);
        setBanComfirmation(false);
        setResetNormal(false);
        setAddTogroup(false);
    };

    const openBan = () => {
        setBanComfirmation(!banComfirmation);
    };
    const handleResetNormal = async () => {
        setResetNormal(true);

    }
    const handleDeleteUser = async () => {
        setDeleteComfirmation(true);
    }
    const handleuserAnalisis = async () => {
        setShowAnalisis(!showAnalisis);
    }
    const openResetNormal = () => {
        setResetNormal(!resetNormal);
    }
    const handleBanUser = async () => {
        setBanComfirmation(true);
    }
    const handleClose = async () => {
        setAddTogroup(!addTogroup);
    }
    const ResetAgain = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.resetAgain(email ? email : "null");
            if (data) {
                NotificationManager.success("Successfull deleted", "User");
                window.location.reload();
            }
            else {
                NotificationManager.warning("Not deleted", "User")
            }
            window.location.reload();
        } catch (error) {
            NotificationManager.warning("Not deleted", "User")
        }
        setResetNormal(false);
    }
    const banInstitute = async (e) => {
        e.preventDefault();
        try {
            const data = await GetUsers.banUser(email ? email : '');
            if (data) {
                NotificationManager.success("Successfull Banned", "user");
                window.location.reload();
            }
            else {
                NotificationManager.warning("Not Banned", "user")
            }
            window.location.reload();
        } catch (error) {
            NotificationManager.warning("Not Banned", "user")
        }
        setBanComfirmation(false);
    }

    const updateInstitute = async (email) => {
        setShowForm(true);
    }

    const handlebio = (e) => {
        e.preventDefault();
        setBio(e.target.value);
    };

    const handlename = (e) => {
        e.preventDefault();
        setName(e.target.value);
    };

    const handleGender = (e) => {
        setGender(e.target.value);
    };

    const handleCountry = (e) => {
        setSelectedCountry(e.target.value);
    }

    const userdataupdate = async (e) => {
        e.preventDefault();

        const updatedata = {
            // username: usernameData,
            bio: bio,
            name: name,
            gender: gender,
            country: country,
        }
        try {
            let data = await GetUsers.userdataupdate(email ? email : "", updatedata)
            if (!data.ok) {
                NotificationManager.success("Successfully updated", "User profile");
            }
            else {
                NotificationManager.warning("Profile not updated", "User profile")
            }
        } catch (error) {
            console.error('Error during feedback:', error);
        }
    }
    const updateGroup = async (e) => {
        e.preventDefault();

        const updatedata = {
            subgroupId: subgroupId,
        }
        try {
            setLoading(true);
            let data = await CreateGroupInstitute.updateUserGroup(email ? email : "", updatedata)
            if (!data.ok) {
                NotificationManager.success("Successfully Added", "Add to group");
                setAddTogroup(false);
                setLoading(false);
            }
            else {
                NotificationManager.warning("User not Added", "Add to group")
                setAddTogroup(false);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error during add:', error);
            NotificationManager.warning("User not Added", "Add to group")
            setAddTogroup(false);
            setLoading(false);
        }
    }
    const fetchGroupList = async (groupidforsearch) => {
        let resource = await CreateGroupInstitute.getGroupListSsub(groupidforsearch);
        if (resource) {
            const options = resource.map((detail) => ({
                id: detail._id,
                name: detail.name,
            }));
            setGroupResult(options);
        }
    };
    const fetchSubGroupList = async () => {
        let resource = await CreateGroupInstitute.getMainGroupList(instituteId);
        if (resource) {
            const options = resource.map((user) => ({
                id: user._id,
                name: user.groupname,
                description: user.description,
            }));
            setResult(options);
        }
    };
    useEffect(() => {
        getInstituteDetail();
        fetchSubGroupList();
        fetchInstituteInfo();
    }, []);

    return (
        <div className="bg-white">
            {instituteDetails?.length <= 0 ? <div> {t("Not allowed")}</div> :
                <>
                    {instituteDetails?.map((data, index) => (
                        <>
                            <Row className="align-items-center">
                                <Col xl={12} lg={12} md={12} xs={12}>
                                    <div className="bg-white rounded-bottom smooth-shadow-sm ">
                                        <div className="d-flex align-items-center justify-content-between pt-4 pb-6 px-4">
                                            <div className="d-flex align-items-center">
                                                {/* avatar */}
                                                <div className="avatar-xxl avatar-indicators avatar-online me-2 position-relative d-flex justify-content-end align-items-end mt-n10">
                                                    {data.profilephoto ? (
                                                        <img
                                                            src={`${API_URL}/public/images/${data.profilephoto}`}
                                                            className="avatar-xxl rounded-circle border border-4 border-white-color-40"
                                                        />
                                                    ) : (
                                                        <div className="avatar-xxl rounded-circle border border-4 border-white-color-40">
                                                            {data.username.charAt(0).toUpperCase()}
                                                        </div>
                                                    )}
                                                </div>
                                                {/* text */}
                                                <div className="lh-1">
                                                    <h2 className="mb-0">{data.name}
                                                        <Link to="/" className="text-decoration-none" data-bs-toggle="tooltip" data-placement="top" title="" data-original-title="Beginner">
                                                        </Link>
                                                    </h2>
                                                    <p className="mb-0 d-block">{data.email}</p>
                                                </div>
                                            </div>
                                            <div className='align-items-end justify-evenly flex flex-row space-x-8'>
                                                <div>
                                                    <button onClick={(() => { window.history.back() })}>
                                                        <FontAwesomeIcon icon={faBackward} color='blue' />
                                                        &nbsp; {t("goBack")}
                                                    </button>
                                                </div>
                                                <div>
                                                    <button onClick={() => { setAddTogroup(!addTogroup) }}>
                                                        <FontAwesomeIcon icon={faAdd} color='blue' />
                                                        &nbsp; {t("Add to group")}
                                                    </button>
                                                </div>
                                                <div>
                                                    <button onClick={(() => { updateInstitute(email) })}>
                                                        <FontAwesomeIcon icon={faEdit} color='orange' />
                                                    </button>
                                                </div>
                                                {data.status == '0' || data.status === null ? (
                                                    <>
                                                        < div >
                                                            <button
                                                                onClick={handleBanUser}
                                                            >
                                                                <FontAwesomeIcon icon={faBan} color='yellow' />
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                onClick={handleDeleteUser}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCanArrowUp} color='red' />
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : undefined
                                                }
                                                {data.status == '1' ? (
                                                    <>
                                                        < div >
                                                            <button
                                                                onClick={handleBanUser}
                                                            >
                                                                <FontAwesomeIcon icon={faBan} color='yellow' />
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                onClick={handleResetNormal}
                                                            >
                                                                <FontAwesomeIcon icon={faCheck} color='green' />
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : undefined
                                                }
                                                {data.status === '2' ? (
                                                    <>
                                                        < div >
                                                            <button
                                                                onClick={handleDeleteUser}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCanArrowUp} color='red' />
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                onClick={handleResetNormal}
                                                            >
                                                                <FontAwesomeIcon icon={faCheck} color='green' />
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : undefined
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Col >
                            </Row >
                            <Col xl={6} lg={12} md={12} xs={12} className="mb-12 w-full">
                                {/* card */}
                                <Card>
                                    {/* card body */}
                                    <Card.Body>
                                        {/* card title */}
                                        <Card.Title as="h4">{t("Information")}</Card.Title>
                                        <span className="text-uppercase fw-medium text-dark fs-5 ls-2">{t("Bio")}</span>
                                        <p className="mt-2 mb-6">{data.bio}.
                                        </p>
                                        <Row>
                                            <Col xs={4} className="mb-5">
                                                <h6 className="text-uppercase fs-5 ls-2">{t("Position")}</h6>
                                                <p className="mb-0">{t("Creator")}</p>
                                            </Col>
                                            <Col xs={4} className="mb-5">
                                                <h6 className="text-uppercase fs-5 ls-2">{t("Reg.Date")} </h6>
                                                <p className="mb-0">{moment(data.createdAt).format('LL')}</p>
                                            </Col>
                                            <Col xs={4}>
                                                <h6 className="text-uppercase fs-5 ls-2">{t("Sex")} </h6>
                                                <p className="mb-0">{data.gender}</p>
                                            </Col>
                                            <Col xs={4}>
                                                <h6 className="text-uppercase fs-5 ls-2">{t("Location")}</h6>
                                                <p className="mb-0">{data.country}</p>
                                            </Col>
                                            {data.status === '1' &&
                                                <Col xs={4} className="mb-5">
                                                    <h6 className="text-uppercase fs-5 ls-2">{t("Status")} </h6>
                                                    <p className="mb-0 text-warning">{t("Banned")}</p>
                                                </Col>
                                            }
                                            {data.status === '2' &&
                                                <Col xs={4} className="mb-5">
                                                    <h6 className="text-uppercase fs-5 ls-2">{t("Status")} </h6>
                                                    <p className="mb-0 text-danger">{t("Deactivate")}</p>
                                                </Col>
                                            }
                                            {data.status === null &&
                                                <Col xs={4} className="mb-5">
                                                    <h6 className="text-uppercase fs-5 ls-2">{t("Status")} </h6>
                                                    <p className="mb-0 text-success">{t("Active")}</p>
                                                </Col>
                                            }
                                            {data.status === '0' &&
                                                <Col xs={4} className="mb-5">
                                                    <h6 className="text-uppercase fs-5 ls-2">{t("Status")} </h6>
                                                    <p className="mb-0 text-success">{t("Active")}</p>
                                                </Col>
                                            }
                                            <Col xs={4}>
                                                <h6 className="text-uppercase fs-5 ls-2">{t("Reference")}</h6>
                                                <p className="mb-0">{instName}</p>
                                            </Col>
                                            {/* <Col xs={4}>
                                                <h6 className="text-uppercase fs-5 ls-2">{t("Group")}</h6>
                                                <p className="mb-0">{groupName}</p>
                                            </Col> */}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <div className=''>
                                <div className="flex flex-row justify-between">
                                    <div className="ml-5 mt-1">
                                        <h3 className='text-sm pl-2'>{t("View user basic analisis")}</h3>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faArrowDown}
                                            className="relative w-3 h-3 rounded-full transform hover:scale-150 p-1"
                                            onClick={handleuserAnalisis} />
                                    </div>
                                </div>
                                {showAnalisis && <UserAnalisis userEmail={email} />
                                }
                            </div>
                            {showForm ? (
                                <ModalComponent isOpen={showForm} title={false} onClose={handleClose}>
                                    <div className="bg-white p-2 rounded-lg shadow-lg w-full">
                                        <div className="flex justify-center mb-6">
                                            <span className="inline-block bg-gray-200 rounded-full p-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4" /></svg>
                                            </span>
                                        </div>
                                        <h2 className="text-2xl font-semibold text-center mb-4">{t("Update User Info")}</h2>
                                        <form encType="multipart/form-data">
                                            <div className="mb-1">
                                                <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Bio")} *</label>
                                                <textarea
                                                    rows={2}
                                                    cols={4}
                                                    onChange={handlebio}
                                                    value={bio}
                                                    name="bio"
                                                    className="col-span-9 border-solid border-2 py-2 px-3 rounded-[0.375rem] form-input w-full text-gray-700 focus:ring-blue-500"
                                                    placeholder={t("Enter bio")}
                                                ></textarea>
                                                <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Name")} *</label>
                                                <input
                                                    type="text"
                                                    onChange={handlename}
                                                    value={name}
                                                    className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                                                    placeholder={t("Enter name")}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Gender")} *</label>
                                                <select
                                                    required
                                                    onChange={handleGender}
                                                    value={gender}
                                                    className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                                                >
                                                    <option
                                                        className="text-[#292929]"
                                                        key={1}
                                                        value="female">
                                                        {t("Female")}
                                                    </option>
                                                    <option
                                                        className="text-[#292929]"
                                                        key={2}
                                                        value="male">
                                                        {t("Male")}
                                                    </option>
                                                    <option
                                                        className="text-[#292929]"
                                                        key={2}
                                                        value="I rather not to say">
                                                        {t("I rather not to say")}
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="mb-1">
                                                <label className="block text-gray-700 text-sm font-semibold mb-2">{t("Country")} *</label>
                                                <select
                                                    required
                                                    id="country"
                                                    name="country"
                                                    value={country}
                                                    onChange={handleCountry}
                                                    className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                                                >
                                                    {countryList.map((country) => (
                                                        <option
                                                            className="text-[#292929]"
                                                            key={country.name}
                                                            value={country.name}>
                                                            {country.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <button type="submit"
                                                onClick={userdataupdate}
                                                className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                                                {t("Update")}</button>
                                        </form>
                                    </div>
                                </ModalComponent>
                            ) : undefined}
                            {
                                deleteComfirmation &&
                                <ModalComponent isOpen={deleteComfirmation} title={false} onClose={handleClose}>
                                    <label>{t("Delete user")}</label>
                                    <label>{t("Do you want to delete?")}</label>
                                    <button className="btn btn-default" onClick={openDelete}>{t("Cancel")}</button>
                                    <button className="btn btn-primary" onClick={deleteInstitute}>{t("OK")}</button>
                                </ModalComponent>
                            }
                            {
                                banComfirmation &&
                                <ModalComponent isOpen={banComfirmation} title={false} onClose={handleClose}>
                                    <label>{t("Ban user")}</label>
                                    <label>{t("Do you want to ban?")}</label>
                                    <button className="btn btn-default" onClick={openBan}>{t("Cancel")}</button>
                                    <button className="btn btn-primary" onClick={banInstitute}>{t("OK")}</button>
                                </ModalComponent>
                            }
                            {
                                resetNormal &&
                                <ModalComponent isOpen={resetNormal} title={false} onClose={openResetNormal}>
                                    <label>{t("Change to Active")}</label>
                                    <label>{t("Do you want to activate?")}</label>
                                    <button className="btn btn-default" onClick={openResetNormal}>{t("Cancel")}</button>
                                    <button className="btn btn-primary" onClick={ResetAgain}>{t("OK")}</button>
                                </ModalComponent>
                            }
                            {addTogroup &&
                                <ModalComponent isOpen={addTogroup} title={false} onClose={handleClose}>
                                    <label className='text-center'>{t("Add user")}</label>
                                    <form
                                        onSubmit={updateGroup}
                                    >
                                        <div className="mb-1">
                                            <label
                                                htmlFor="country"
                                                className="block text-gray-700 text-sm font-semibold mb-2"
                                            >{t("Sub group")}
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <select
                                                id="groupId"
                                                name="groupId"
                                                value={groupId}
                                                onChange={(e) => {
                                                    const selectedOptionValue = e.target.value;
                                                    setGroupId(selectedOptionValue)
                                                    fetchGroupList(selectedOptionValue);
                                                }}
                                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                            >
                                                <option hidden>
                                                    {t("Select Main Group")}
                                                </option>
                                                {result.map((group) => (
                                                    <option
                                                        className="text-[#292929]"
                                                        key={`m${group.id}`}
                                                        value={group.id}>
                                                        {group.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-20">
                                            <label
                                                htmlFor="country"
                                                className="block text-gray-700 text-sm font-semibold mb-2"
                                            >{t("Group")}
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <select
                                                id="subgroupId"
                                                name="subgroupId"
                                                value={subgroupId}
                                                onChange={(e) => {
                                                    const selectedOptionValue = e.target.value;
                                                    setSubGroupId(selectedOptionValue)
                                                }}
                                                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500 placeholder:text-[#a7a5a5e6]"
                                            >
                                                <option hidden>
                                                    {t("Select group")}
                                                </option>
                                                {groupResult.map((group) => (
                                                    <option
                                                        className="text-[#292929]"
                                                        key={group.id}
                                                        value={group.id}>
                                                        {group.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button
                                            className={
                                                `w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                      ${loading
                                                    ? "bg-[#2d2d39] text-white pointer-events-none"
                                                    : "w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                                }
                      `}
                                            type="submit"
                                            disabled={loading}>
                                            {loading ? (
                                                <Spin color="#ffffff" width="30px" height="30px" duration="1s" />
                                            ) : (
                                                t("Add")
                                            )}
                                        </button>
                                    </form>
                                </ModalComponent>
                            }
                        </>

                    ))}
                </>
            }
        </div>
    );
}
export default UserDetail;
