import { Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faFolderPlus, faHistory, faHome,  faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Sidebar from '../../components/AdminComponents/components/Sidebar';
import NavbarAnimation from '../root/header';

const InstitutemainPage = () => {
const {t} = useTranslation()
  return <NavbarAnimation>
      <div className="bg-[#8f9dc4] py-2 ">
        <div className=" bg-cover bg-dashboard-pattern flex flex-col md:flex-row space-y-5 lg:space-y-0  lg:space-x-10 sm:p-6 sm:my-2 sm:mx-4 sm:rounded-2xl">
          <Sidebar menus={[{ name: t("Dashboard"), link: "/institute/dashboard", icon: <FontAwesomeIcon icon={faHome} /> }, 
          { name: t("View Team"), link: "/institute/team", icon: <FontAwesomeIcon icon={faUsers} /> }, 
          { name: t("Resource"), link: "/institute/resource", icon: <FontAwesomeIcon icon={faFolderPlus} /> }, 
          { name: t("Report"), link: "/institute/report", icon: <FontAwesomeIcon icon={faDatabase} /> }, 
          { name: t("Activity"), link: "/institute/activity", icon: <FontAwesomeIcon icon={faHistory} /> }, 
          { name: t("Profile"), link: "/institute/profile", icon: <FontAwesomeIcon icon={faUser} /> }]} />
          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
              <div className="mx-auto  p-4 md:p-6 2xl:p-10 bg-white/60 rounded-lg">
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </NavbarAnimation>;
};

export default InstitutemainPage;
