import BackgroundR from '../../components/background/backgroundR';
import VerifyFormInstitute from '../../components/form/VerifyInstitute';

function InstituteEmailVerification() {

  return (
    <div id="wrapper">
      <div className="flex min-h-screen">
        <div className="w-full md:w-1/2   bg-left  animate-gradient ">
          <div className='w-full h-full bg-shape-pattern bg-cover flex items-center justify-center'>
            <VerifyFormInstitute />
          </div>
        </div>
        <div className="hidden md:block md:w-1/2 bg-art bg-cover bg-center relative overflow-hidden justify-center">
          <BackgroundR />
        </div>
      </div>
    </div>
  );
}

export default InstituteEmailVerification;
