import { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeLowVision, faEye } from '@fortawesome/free-solid-svg-icons'
import { useParams, useNavigate } from 'react-router-dom';
import { Spin } from 'react-cssfx-loading'

import { GetUserLogin } from '../../../services';
import { getDateValues } from '../../../function';

function ResetForm() {
    const { token } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [resetPassword, setResetPassword] = useState({
        password: "",
        confirmPassword: ""
    });
    const [countDown, setCountDown] = useState([0, 0, 0, 0]);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isPassValid, setIsPassValid] = useState(true);

    const minLength = 8


    useEffect(() => {

        if (token !== null && token !== undefined) {
            GetUserLogin.getUserByToken(token).then((respond) => {
                if (respond) {
                    const countDownDate = new Date(respond.expiration).getTime();
                    const interval = setInterval(() => {
                        const now = new Date().getTime();
                        const distance = countDownDate - now;
                        if (distance < 0) {
                            clearInterval(interval);
                            setCountDown([0, 0, 0, 0]);
                        } else {
                            setCountDown(getDateValues(distance));
                        }
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            console.error('Email is null or undefined.');
        }
    }, []);


    const handleChange = (e) => {
        setResetPassword({ ...resetPassword, [e.target.name]: e.target.value })
        setIsPassValid(e.target.value.length >= minLength);
    }
    // Function to send the verification code to the server
    const sendPasswordResert = async () => {
        if (token) {
            if (resetPassword.password !== resetPassword.confirmPassword) {
                NotificationManager.error("Password didn't match", "password Reset")
            }
            else {
                setLoading(true);

                const passwordReset = {
                    newPassword: resetPassword.password,
                    token: token
                }
                GetUserLogin.passwordReset(passwordReset).then((verify) => {
                    if (!verify) {
                        NotificationManager.error('Invalid or expired token', 'password Reset');
                        setLoading(false);
                    } else {
                        navigate('/login')
                    }
                }).catch((error) => {
                    NotificationManager.error('Invalid or expired token', 'password Reset');
                    setLoading(false);
                });
            }
        } else {
            NotificationManager.error('Use Correct token', "password Reser")
        }
    };


    return (
        <div className='relative w-[400px] flex justify-center items-center rounded-[20px] bg-box animate-animate3 box-form h-auto py-[20px] before:content before:absolute before:w-full before:h-full before:bg-box-before before:animate-animate3 before:rounded-[20px] before:animation-delay-1000 after:content after:absolute after:inset-[8px] after:bg-greslernorange after:bg-noise  after:rounded-[15px] after:border-4 after:border-transparent'>
            <form
                className='relative w-full flex justify-center items-center flex-col gap-[25px] py-0 px-[40px] z-[10]'
                onSubmit={(e) => {
                    e.preventDefault();
                    sendPasswordResert();
                }}>
                <div className="mb-1">
                    <h2 className='text-white text-[32px] text-center font-semibold'>{t("PasswordReset")}</h2>
                    <label className="mb-2.5 block font-medium text-gray-400 text-center dark:text-white">
                        {t("resetPasswordP")}
                    </label>
                    <div className="relative">
                        <input
                            id="password"
                            name='password'
                            type={showPassword ? "text" : "password"}
                            placeholder={t("password")}
                            onChange={(e) => handleChange(e)}
                            className={`password relative w-full py-[10px] px-[15px] border outline-none rounded-[5px] text-white text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]   ${isPassValid ? '!border-g-blue' : '!border-red-500'}`}
                        />
                        <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowPassword(!showPassword) }}>
                            <FontAwesomeIcon icon={!showPassword ? faEyeLowVision : faEye} />
                        </button>
                    </div>
                    {!isPassValid && (
                        <div className='text-red-500 text-sm'>
                            {t("passwordlength")} {minLength} {t("characterlong")}
                        </div>
                    )}
                    <div className="relative mt-4">
                        <input
                            id="confirmPassword"
                            name='confirmPassword'
                            type={showConfirmPassword ? "text" : 'password'}
                            placeholder={t("confirmPassword")}
                            onChange={(e) => handleChange(e)}
                            className="password relative w-full py-[10px] px-[15px] border !border-g-blue outline-none rounded-[5px] text-white text-base tracking-wider bg-gersLightBlue placeholder:text-[#dfdfdfe6]"
                        />
                        <button className="absolute right-4 top-3 text-g-blue" onClick={(e) => { e.preventDefault(); setShowConfirmPassword(!showConfirmPassword) }}>
                            <FontAwesomeIcon icon={!showConfirmPassword ? faEyeLowVision : faEye} />
                        </button>
                    </div>
                    {/* <div className='w-full flex justify-end mt-4'>
                        <p>
                            {t("Donthaveanaccount")}
                            <a className="text-[#243946] text-lg font-bold decoration-0 transition-all duration-200 ease-in-out delay-100 ml-1 hover:text-white hover:decoration-1 cursor-pointer" href='/signup'>{t("Signup")}</a>
                        </p>
                    </div> */}
                </div>
                <div className="mb-2 w-full flex justify-center">
                    <button
                        disabled={loading}
                        type="submit"
                        className={` relative flex justify-center  mt-1 w-[85%] py-[10px] px-[15px] rounded-[5px] text-base tracking-wider transition-[0.5s] cursor-pointer
                ${loading
                                ? 'bg-[#2d2d39] text-white pointer-events-none'
                                : 'bg-white text-[#2d2d39] hover:!bg-[#243946] hover:text-white'}
              `}
                    >
                        {loading ? (<Spin color="#ffffff" width="30px" height="30px" duration="1s" />) : t("ResetPassword")}
                    </button>
                    <div className="text-center text-white ">
                        <p>{t("TimeRemaining")}<div className='border border-white text-greslernorange py-2 px-3 rounded'> {countDown[1]} : {countDown[2]} : {countDown[3]}</div> </p>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ResetForm;
