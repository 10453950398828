import { useEffect, useState } from 'react'
import { IContentListEntry } from '../../../../../services/ContentService'
import ContentSettingService from '../../../../../services/ContentSettingService'
import NoRecordFound from '../../../../NotRecoredFound'
import ContainerComponent from '../ContainerComponent'

const BieContentTemplates = () => {
    const [resources, setResources] = useState<IContentListEntry[]>([])

    const fetchData = async () => {
        const list = await ContentSettingService.getAllTemplates();
        setResources(list);
    }
    
    useEffect(() => {
        fetchData()
    }, [])

    return resources.length > 0 ? <ContainerComponent resources={resources} /> : <NoRecordFound />
}

export default BieContentTemplates