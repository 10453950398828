import { Link } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next';

import green_person from '../../../components/assets/img/Collaborate/green_person.png'
import orange_person from '../../../components/assets/img/Collaborate/orange_person.png'
import group from '../../../components/assets/img/Collaborate/group.png'
import { fadeIn, textVariant } from '../../../utils/motion';
import SectionWrapper from '../../../hoc';

import LineComponent from '../../../components/lineComponent/idex';

function Collaborate({ id, id2 }) {
    const { t } = useTranslation()
    return (
        <div id='collaborateSection' className="bg-amber-50   md:h-auto lg:h-[calc(100vh-5rem)] w-full">
            <div className="flex justify-between items-center h-full max-w-screen-xl mx-auto">
                <div className='grid grid-cols-12'>
                    <div className='col-span-12 lg:col-span-7 flex flex-col justify-between items-center px-16'>
                        <div className='flex flex-col md:flex-row justify-between w-full'>
                            <motion.div
                                variants={fadeIn("right", "spring", 0.5 * 1, 0.75)}
                                className='flex mt-4'
                            >
                                <img className='pr-8 w-auto h-10 md:h-16' alt='orange person' src={orange_person} />
                                <div className=' flex flex-col w-48'>
                                    <h1 className='font-bold'>{t("IndividualCreating")} </h1>
                                    <small className='mt-2 mb-4'>{t("individualP")}</small>
                                    <div className="flex relative hover:!border-greslernorange">
                                        <Link to="/comingsoon"
                                            className="text-center align-middle  border-2 border-greslernorange text-greslernorange px-1 py-2 rounded-lg hover:text-white hover:bg-greslernorange transition-all duration-350 ease-in-out w-28 hover:w-36">{t("ReadMore")}</Link>
                                        <div className="icon_secondary w-12 h-12 border-2 border-white bg-greslernorange rotate-45 relative top-1 z-[-1] transition-all duration-350 ease-in-out ">
                                            <svg className=" w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] fill-white transition-all duration-350 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                                <path stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 16.5c0-1-8-2.7-9-2V1.8c1-1 9 .707 9 1.706M10 16.5V3.506M10 16.5c0-1 8-2.7 9-2V1.8c-1-1-9 .707-9 1.706" />
                                            </svg>
                                        </div>
                                    </div>
                                    {/* <button className="border-2 rounded inline-block border-black p-1 w-28 hover:bg-black hover:text-white">Read More</button> */}
                                </div>
                            </motion.div>
                            <motion.div
                                variants={fadeIn("right", "spring", 0.5 * 2, 0.75)}
                                className='flex mt-4'
                            >
                                <img className='pr-4 md:pr-8 w-auto h-10 md:h-16' alt='group' src={group} />
                                <div className=' flex flex-col w-48'>
                                    <h1 className='font-bold'>{t("GroupCreating")} </h1>
                                    <small className='mt-2 mb-4'>{t("GroupP")}</small>
                                    <div className="flex relative hover:!border-greslernorange">
                                        <Link to="/comingsoon"
                                            className="text-center align-middle  border-2 border-greslernorange text-greslernorange px-1 py-2 rounded-lg hover:text-white hover:bg-greslernorange transition-all duration-350 ease-in-out w-28 hover:w-36">{t("ReadMore")}</Link>
                                        <div className="icon_secondary w-12 h-12 border-2 border-white bg-greslernorange rotate-45 relative top-1 z-[-1] transition-all duration-350 ease-in-out ">
                                            <svg className=" w-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] fill-white transition-all duration-350 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                                <path stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 16.5c0-1-8-2.7-9-2V1.8c1-1 9 .707 9 1.706M10 16.5V3.506M10 16.5c0-1 8-2.7 9-2V1.8c-1-1-9 .707-9 1.706" />
                                            </svg>
                                        </div>
                                    </div>
                                    {/* <button className="border-2 rounded inline-block border-black p-1 w-28 hover:bg-black hover:text-white">Read More</button> */}
                                </div>
                            </motion.div>
                        </div>

                        <motion.div
                            variants={fadeIn("right", "spring", 0.5 * 3, 0.75)}
                            className='flex  mt-4'
                        >
                            <img className='pr-8 w-auto h-10 md:h-16' alt='green person' src={green_person} />
                            <div className=' flex flex-col w-48'>
                                <h1 className='font-bold'>{t("PairCreating")} </h1>
                                <small className='mt-2 mb-4'>{t("pairP")}</small>
                                <div className="flex relative hover:!border-greslernorange">
                                    <Link to="/comingsoon"
                                        className="text-center align-middle  border-2 border-greslernorange text-greslernorange px-1 py-2 rounded-lg hover:text-white hover:bg-greslernorange transition-all duration-350 ease-in-out w-28 hover:w-36">
                                        {t("ReadMore")}
                                    </Link>
                                    <div className="icon_secondary w-12 h-12 border-2 border-white bg-greslernorange rotate-45 relative top-1 z-[-1] transition-all duration-350 ease-in-out ">
                                        <svg className=" w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] fill-white transition-all duration-350 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                            <path stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 16.5c0-1-8-2.7-9-2V1.8c1-1 9 .707 9 1.706M10 16.5V3.506M10 16.5c0-1 8-2.7 9-2V1.8c-1-1-9 .707-9 1.706" />
                                        </svg>
                                    </div>
                                </div>
                                {/* <button className="border-2 rounded inline-block border-black p-1 w-28 hover:bg-black hover:text-white">Read More</button> */}
                            </div>
                        </motion.div>
                    </div>
                    <div className='col-span-10 lg:col-span-5 lg:mt-0 mt-4 mx-4 lg:mx-0'>
                        {/* <div
                            className='absolute top-[453.5%] left-[45.5%] z-0 rotate-[310deg] 2xl:rotate-[320deg] hidden lg:block'
                        >
                            <Parallax
                                strength={200}
                                style={{ overflow: 'inherit' }}
                                renderLayer={percentage => (
                                    <div className="relative">
                                        <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `8px`,
                                                height: `${percentage / 2 <= 1 ? percentage / 2 * 100 : 100}vh`,
                                            }}
                                        ></div>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </div> */}
                        <motion.div variants={textVariant()}
                            className='flex items-center z-[1]'
                        >
                            <div className='z-[2]'>
                                <span className='p-0 text-6xl text-black  border-0'>{t("wo")}</span>
                            </div>
                            <Parallax
                                strength={200}
                                renderLayer={percentage => (
                                    <div className="relative h-[8rem] w-[8rem]">
                                        <div className={` border-[#E30613]  bg-[#E30613] rounded-full text-black absolute  animate-grow-bg`}
                                            style={{
                                                width: `${percentage <= 0.5 ? percentage * 16 : 8}rem`,
                                                height: `${percentage <= 0.5 ? percentage * 16 : 8}rem`,
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        ></div>
                                        <span className='relative top-5 text-6xl leading-normal border-0 text-white p-0 ml-auto'>{t("rk")}</span>
                                    </div>
                                )}
                            >
                            </Parallax>
                        </motion.div>
                        <div className='relative top-[-1.5rem] px-4 lg:px-14 z-[1]'>
                            <motion.h1 variants={textVariant()} className='text-6xl font-bold'>{t("together")}</motion.h1>
                            <motion.p variants={fadeIn("", "", 0.1, 1)} className=' px-0 lg:px-2 mt-6'>
                                <span className='p-0 border-0 text-black font-bold'> {t("collaborateP1")}</span> {t("collaborateP3")}
                            </motion.p>
                        </div>
                    </div>
                    <div
                        className='absolute top-[495%] left-[58%] z-[1] w-24 hidden lg:block'
                    >
                        <Parallax
                            strength={200}
                            style={{ overflow: 'inherit' }}
                            renderLayer={percentage => (
                                <div className="relative  h-[30px] w-[30px] ">
                                    <div id={id} className={` border-[#9C15DB]  bg-[#9C15DB] rounded-full text-black absolute  animate-grow-bg`}
                                        style={{
                                            width: `${percentage <= 1 ? percentage * 30 : 30}px`,
                                            height: `${percentage <= 1 ? percentage * 30 : 30}px`,
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    ></div>
                                </div>
                            )}
                        >
                        </Parallax>
                    </div>
                    {/* <div
                        className='absolute top-[496%] left-[59%] z-0 rotate-[15deg] hidden lg:block'
                    >
                        <Parallax
                            strength={200}
                            style={{ overflow: 'inherit' }}
                            renderLayer={percentage => (
                                <div className="relative">
                                    <div className={` border-[#B75F2E]  bg-[#B75F2E] rounded-full text-black absolute  animate-grow-bg`}
                                        style={{
                                            width: `8px`,
                                            height: `${percentage <= 1 ? percentage * 50 : 50}vh`,
                                        }}
                                    ></div>
                                </div>
                            )}
                        >
                        </Parallax>
                    </div> */}
                    <LineComponent startPointId='targetElementCollaboratePurple' endPointId='targetElementCollaborateYellow' />
                    <div
                        className='absolute top-[538%] left-[50.5%] z-[1] w-24 hidden lg:block'
                    >
                        <Parallax
                            strength={200}
                            style={{ overflow: 'inherit' }}
                            renderLayer={percentage => (
                                <div className="relative  h-[70px] w-[70px] ">
                                    <div id={id2} className={` border-[#F9B233]  bg-[#F9B233] rounded-full text-black absolute  animate-grow-bg`}
                                        style={{
                                            width: `${percentage <= 1 ? percentage * 70 : 70}px`,
                                            height: `${percentage <= 1 ? percentage * 70 : 70}px`,
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    ></div>
                                </div>
                            )}
                        >
                        </Parallax>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Collaborate